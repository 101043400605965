<template>
  <div @click="emitClick">
    <v-icon medium class="pr-1 v-icon--link">fa-plus-square</v-icon>
    <span class="text">{{ $t(copy) }}</span>
  </div>
</template>

<script>
export default {
  props: {
    copy: {
      type: String,
      default: '',
    },
  },
  methods: {
    emitClick() {
      this.$emit('clickFunction');
    },
  },
};
</script>

<style lang="scss" scoped>
.text {
  cursor: pointer;
  font-size: 1.2rem;
}
.icon {
  font-size: 1.1rem;
}
</style>
