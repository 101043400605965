var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing-json-editor" },
    [
      _c("v-jsoneditor", {
        ref: "response-editor",
        staticClass: "pricing-json-editor__editor",
        attrs: {
          value: _vm.jsonValue,
          plus: false,
          options: _vm.editorOptions,
        },
        on: { error: _vm.onError },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }