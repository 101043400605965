<template>
  <div class="dropdown-list-item__container">
    <v-divider v-if="divider" />
    <v-list-item :disabled="disabled" @click="emitSelectOption">
      <v-list-item-avatar class="dropdown-list-item__avatar">
        <i
          class="mdi dropdown-list-item__icon clickable"
          :class="[`mdi-${iconClass}`, { 'dropdown-list-item__icon--disabled': disabled }]"
        />
      </v-list-item-avatar>

      <v-list-item-content>
        <v-list-item-title>
          {{ title }}
        </v-list-item-title>
      </v-list-item-content>
    </v-list-item>
  </div>
</template>

<script>
export default {
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },

    divider: {
      type: Boolean,
      default: false,
    },

    iconClass: {
      type: String,
      default: '',
    },

    title: {
      type: String,
      required: true,
    },
  },

  methods: {
    emitSelectOption() {
      this.$emit('selectOption');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';

.dropdown-list-item {
  &__container {
    position: sticky;
    bottom: 0;
    background: $pricing-white;
  }

  &__avatar {
    // !important is required to override style
    height: 2rem !important;
    min-width: 2rem !important;
    width: 2rem !important;

    &:first-child {
      margin-right: 1rem;
    }
  }

  &__icon {
    font-size: 2rem;
    color: $icon-colour;

    &--disabled {
      opacity: 0.6;
    }
  }
}
</style>
