import _ from 'lodash';
import moment from 'moment';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import { yearMonthDayFormat } from '@enums/date-formats';

const getOverridePrice = (overridePrice, goLiveDate) => {
  const { effectiveDate, expiryDate, price } = overridePrice;
  if (!effectiveDate || !expiryDate) {
    return price;
  }
  const momentGoLiveDate = moment(goLiveDate, yearMonthDayFormat, true);
  const momentExpiryDate = moment(expiryDate, yearMonthDayFormat, true);
  // smart override expires in the future is valid
  const isExpiredOverride = isSameOrAfter(momentGoLiveDate, momentExpiryDate);
  return isExpiredOverride ? null : price;
};

const computeScenarioPrice = ({
  item,
  goLiveDate,
  intentionPrice,
  usePromoPriceOnSmartOverride,
}) => {
  // if usePromoPriceOnSmartOverride is true, use intentionPrice as scenarioPrice,
  // if item is either onPromotion or hasUpcomingPromotion, priority is overridePrice > intentionPrice
  // Otherwise, follow the priority of overridePrice > optimizedPrice > intentionPrice
  const promotionCheck = _.get(item, 'hasUpcomingPromotion') || _.get(item, 'isOnPromotion');
  if (usePromoPriceOnSmartOverride) {
    return intentionPrice;
  }
  const overridePrice = getOverridePrice(_.get(item, 'overridePrice', {}), goLiveDate);
  return promotionCheck
    ? overridePrice || intentionPrice
    : overridePrice || item.optimizedPrice || intentionPrice;
};

export default computeScenarioPrice;
