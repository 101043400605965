import axios from 'axios';
import to from 'await-to-js';
import { forEach, isEmpty, merge } from 'lodash';

import { ascending } from '@enums/sort-direction';

const getInitialState = () => {
  return {
    architectureDrivers: [],
    loading: false,
    isCalculatingImpactFactor: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    setArchitectureDrivers(state, architectureDrivers) {
      state.architectureDrivers = architectureDrivers;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setCalculatingImpactFactor(state, isCalculating) {
      state.isCalculatingImpactFactor = isCalculating;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },

  actions: {
    async fetchArchitectureDrivers({ rootState, commit }, { params = {} } = {}) {
      commit('setLoading', true);

      // If Scenario is set in the filters, use that to filter the data
      if (rootState.filters.scenario) {
        params = merge(params, {
          where: { scenarioKey: rootState.filters.scenario },
        });
      }

      // If architectureGroupId is set in the filters, use that to filter the data
      if (rootState.filters.architectureGroup) {
        params = merge(params, {
          where: { architectureGroupId: rootState.filters.architectureGroup },
        });
      }

      params.sortBy = 'priority';
      params.sortDirection = ascending;

      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data: architectureDrivers }] = await to(
        axios.get(`/api/architecture-drivers/workpackage/${workpackageId}`, {
          params,
        })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      commit('setArchitectureDrivers', architectureDrivers);
      commit('setLoading', false);
    },

    // TODO: remove it if not used
    async createArchitectureDriver({ dispatch, rootState }, architectureDrivers) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data: result }] = await to(
        axios.post(`/api/architecture-drivers/workpackage/${workpackageId}`, architectureDrivers)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchArchitectureDrivers');
      return result;
    },

    async updateArchitectureDrivers(
      { commit, dispatch, rootState },
      { updates = {}, deletions = [] } = {}
    ) {
      const scenarioKey = rootState.filters.scenario;
      const architectureGroupId = rootState.filters.architectureGroup;
      forEach(updates, update => {
        update.scenarioKey = scenarioKey;
        update.architectureGroupId = architectureGroupId;
      });
      deletions = deletions.map(deletion => ({ filter: { ...deletion, scenarioKey } }));

      // return when both empty to avoid mongo 'no operations specified' error
      if (isEmpty(updates) && isEmpty(deletions)) {
        // When the architecture drivers are initially opened the save button is enabled so that we can calculate the impact factors.
        await dispatch('calculateImpactFactors');
        await dispatch('architectureGroup/fetchArchitectureProducts', {}, { root: true });
        await dispatch('fetchArchitectureDrivers');
        return;
      }

      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(`/api/architecture-drivers/workpackage/${workpackageId}`, {
          updates,
          deletions,
        })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      await dispatch('calculateImpactFactors');
      await dispatch('architectureGroup/fetchArchitectureProducts', {}, { root: true });
      await dispatch('fetchArchitectureDrivers');

      commit('setLoading', false);
    },

    async calculateImpactFactors({ rootState, commit }) {
      commit('setCalculatingImpactFactor', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.patch(
          `/api/architecture-drivers/workpackage/${workpackageId}/calculate-impact-factors/${
            rootState.filters.architectureGroup
          }/${rootState.filters.scenario}`
        )
      );
      if (err) {
        commit('setCalculatingImpactFactor', false);
        throw new Error(err.message);
      }

      commit('setCalculatingImpactFactor', false);
      return response.data;
    },

    resetState({ commit }) {
      commit('resetState');
    },

    async updateArchitectureDriversFromAttributeEditorChanges({ rootState }, { updates }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(`/api/architecture-drivers/workpackage/${workpackageId}/attributeUpdates`, {
          updates,
        })
      );
      if (err) {
        throw new Error(err.message);
      }
      return true;
    },

    async updateArchitectureDriversWithHierarchyChanges({ rootState }, { updates }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(`/api/architecture-drivers/workpackage/${workpackageId}/hierarchyUpdates`, {
          updates,
        })
      );
      if (err) {
        throw new Error(err.message);
      }
      return true;
    },
  },
};

export default store;
