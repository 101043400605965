import axios from 'axios';
import to from 'await-to-js';

const store = {
  namespaced: true,

  state: {
    userProfiles: [],
    userProfilesCount: 0,
    loading: false,
  },

  mutations: {
    setUserProfiles(state, userProfiles) {
      state.userProfiles = userProfiles;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setUserProfilesCount(state, { count }) {
      state.userProfilesCount = count;
    },
  },

  actions: {
    async fetchUserProfiles({ commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const [err, res] = await to(axios.get('/api/user-profiles', { params }));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      commit('setUserProfiles', res.data);
      commit('setUserProfilesCount', { count: res.data.length });
      commit('setLoading', false);
    },

    async updateUserProfile({ commit, dispatch }, { _id, updatedItem = {} } = {}) {
      commit('setLoading', true);
      const mongoIdFields = ['access.Category[]levelEntryKey', 'access.Unit[]levelEntryKey'];

      const [err] = await to(
        axios.put(`/api/user-profiles/${_id}`, { updatedItem, mongoIdFields })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      dispatch('fetchUserProfiles');
      dispatch('context/refreshUserContext', {}, { root: true });
    },
  },
};

export default store;
