'use strict';

const { identity, get } = require('lodash');
const moment = require('moment');
const {
  formatProduct,
  addIndexToProductInfo,
  formatFieldsForExport,
  addNormWeightToExport,
  addStoreGroupToExport,
  useTimeFlexibleDataForExport,
} = require('../helpers/workpackage-product.helpers');
const {
  getAttributeMetadata,
  removeToolStoreGroup,
} = require('../helpers/attribute-metadata.helpers');
const { formatCompetitorPriceHistoryLabel, addCompetitorIndex } = require('./helpers');
const { competitorSources } = require('../data/enums/source-types');

function createFeatureAwareFactory(featureDecisions, toolStoreGroupConfig) {
  /**
   * Create functions for handling feature toggling based on the loaded configuration.
   * See https://owlabs.atlassian.net/wiki/spaces/RTLS/pages/1100742755/Feature+Toggle+Approach
   */
  return {
    formatProductInfo(product) {
      if (featureDecisions.addIndexToProductInfo) {
        return formatProduct(product, addIndexToProductInfo);
      }
      return formatProduct(product, identity);
    },

    priceHistoryCompetitorLabel(competitor, numberFormatter) {
      // Generate the checkbox label for competitors in the price history chart
      if (featureDecisions.addIndexToProductInfo) {
        return formatCompetitorPriceHistoryLabel(competitor, numberFormatter, addCompetitorIndex);
      }
      return formatCompetitorPriceHistoryLabel(competitor, numberFormatter, identity);
    },

    productFieldsForExport(product, selectedHistoricalPeriod) {
      let exportFields = formatFieldsForExport(product);

      if (featureDecisions.includeNormWeight) {
        exportFields = addNormWeightToExport(exportFields, product);
      }
      if (featureDecisions.useZones) {
        exportFields = addStoreGroupToExport(exportFields, product);
      }
      if (featureDecisions.displayHistoricalDropdown) {
        exportFields = useTimeFlexibleDataForExport(
          exportFields,
          product,
          selectedHistoricalPeriod
        );
      }

      return exportFields;
    },

    attributeMetadataToDisplay(attributeMetadata) {
      if (!featureDecisions.useZones) {
        return getAttributeMetadata(attributeMetadata, removeToolStoreGroup, toolStoreGroupConfig);
      }
      return getAttributeMetadata(attributeMetadata, identity);
    },

    maxFutureTimeStampToDisplay() {
      const currentTimestamp = moment().unix();
      const weeks = featureDecisions.numberWeeksHistoryLookForward;
      if (weeks) {
        return moment.unix(currentTimestamp).add(weeks, 'weeks');
      }
      return null;
    },

    mergeCompetitorTypes(competitor) {
      // This method worksout what competitor types need to be merged to get data from for the export
      // If the feature toggle is true then we want to include storeGroup competitors to the export

      const objectsToMerge = [];

      if (competitor) {
        objectsToMerge.push(
          get(competitor, competitorSources.feed, {}),
          get(competitor, competitorSources.manual, {})
        );

        if (featureDecisions.displayStoreGroupCompetitors) {
          objectsToMerge.push(get(competitor, competitorSources.storeGroup, {}));
        }
      }
      return objectsToMerge;
    },
  };
}

module.exports = createFeatureAwareFactory;
