<template>
  <v-layout row justify-center>
    <v-dialog :value="isOpen" persistent max-width="40rem">
      <v-card>
        <v-form ref="form" v-model="valid">
          <v-card-text class="header-text">
            {{ dialogTitle }}
          </v-card-text>
          <v-card-text class="body">
            <v-autocomplete
              v-if="multipleHierarchyLevels"
              dense
              return-object
              flat
              :items="existingGrandparentHierarchies"
              item-text="levelEntryDescription"
              item-value="levelEntryDescription"
              class="ma-0 pt-0 mb-2"
              @input="setSelectedGrandparentHierarchy"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>

              <template v-slot:label>
                <span class="pg-dropdown-label-text">{{ selectHierarchyText(level - 2) }}</span>
              </template>
            </v-autocomplete>

            <v-autocomplete
              dense
              return-object
              flat
              :items="existingParentHierarchies"
              item-text="levelEntryDescription"
              item-value="levelEntryDescription"
              :disabled="!selectedGrandparentHierarchy && multipleHierarchyLevels"
              class="ma-0 pt-0 mb-2"
              @input="setSelectedParentHierarchy"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>

              <template v-slot:label>
                <span class="pg-dropdown-label-text">{{ selectHierarchyText(level - 1) }}</span>
              </template>
            </v-autocomplete>

            <v-autocomplete
              v-if="isEditMode"
              dense
              return-object
              flat
              :items="existingChildHierarchies"
              item-text="levelEntryDescription"
              item-value="levelEntryDescription"
              :disabled="!selectedParentHierarchy && multipleHierarchyLevels"
              class="ma-0 pt-0 mb-2"
              @input="setSelectedChildHierarchy"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>

              <template v-slot:label>
                <span class="pg-dropdown-label-text">{{ selectHierarchyText(level) }}</span>
              </template>
            </v-autocomplete>

            <v-text-field
              v-model="childHierarchyName"
              :label="$tc(`hierarchy.hierarchyLevels.${level}`, 1)"
              :disabled="isEditMode ? !selectedChildHierarchy : !selectedParentHierarchy"
              :rules="rules"
              dense
            />
          </v-card-text>
          <v-card-actions class="btn-group">
            <v-spacer />
            <v-btn class="cancel-btn" text outlined depressed @click="cancel">Cancel</v-btn>
            <v-btn class="ok-btn" :disabled="!valid" depressed @click="submit">Save</v-btn>
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { map, toLower, sortBy } from 'lodash';
import {
  pricingGroupLevel,
  architectureGroupLevel,
  pricingGroupUndefined,
  defaultMarginTarget,
} from '@enums/hierarchy';
import dialogModes from '@enums/dialog-modes';

export default {
  props: {
    isOpen: {
      required: true,
    },
    level: {
      required: true,
      validator(level) {
        return [pricingGroupLevel, architectureGroupLevel, null].includes(level);
      },
    },
    mode: {
      type: String,
      default: () => dialogModes.create,
    },
    params: {
      type: Object,
      default: () => {},
    },
    fetchHierarchy: {
      type: Function,
      required: true,
      default: () => console.error('Prop failed to bind for fetchHierarchy'),
    },
    // TODO: remove once fully moved to v2 inputs
    // check for all instances of this
    ignoreAttributeFetch: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      selectedGrandparentHierarchy: null,
      selectedParentHierarchy: null,
      selectedChildHierarchy: null,
      childHierarchyName: '',
      rules: [this.valueRequired, this.isValidName],
      valid: null,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('context', ['profile']),
    ...mapState('competitorMetadata', ['competitors']),
    ...mapGetters('hierarchy', ['getHierarchy', 'getHierarchiesByParentId', 'getHierarchies']),

    dialogTitle() {
      const hierarchyLevel = this.$tc(`hierarchy.hierarchyLevels.${this.level}`, 1);
      return this.isEditMode
        ? `${this.$t('attributes.editor.titles.edit')} ${hierarchyLevel}`
        : `${this.$t('attributes.editor.titles.new')} ${hierarchyLevel}`;
    },

    isEditMode() {
      return this.mode === dialogModes.edit;
    },

    existingGrandparentHierarchies() {
      // Used to allow the user to select a category before selecting a pricing group when creating a new Architecture Group.
      // Minus 2 as Catgeory is 2 levels above Architecture Group in Hierarchy.
      return sortBy(this.getHierarchy({ level: this.level - 2 }), 'levelEntryDescription');
    },

    existingParentHierarchies() {
      if (this.selectedGrandparentHierarchy)
        return sortBy(
          this.getHierarchy({
            level: this.level - 1,
            parentId: this.selectedGrandparentHierarchy.levelEntryKey,
          }),
          'levelEntryDescription'
        );
      return sortBy(this.getHierarchy({ level: this.level - 1 }), 'levelEntryDescription');
    },

    existingChildHierarchies() {
      if (this.selectedParentHierarchy)
        return this.getHierarchy({
          level: this.level,
          parentId: this.selectedParentHierarchy.levelEntryKey,
        });
      return sortBy(this.getHierarchy({ level: this.level }), 'levelEntryDescription');
    },

    multipleHierarchyLevels() {
      return this.level === architectureGroupLevel;
    },
  },

  methods: {
    ...mapActions('hierarchy', ['createHierarchy', 'updateHierarchy']),
    ...mapActions('workpackageProducts', ['fetchWorkpackageProducts']),
    ...mapActions('attributes', ['updateHierarchyDesc']),

    selectHierarchyText(level) {
      const hierarchyName = this.$tc(`hierarchy.hierarchyLevels.${level}`, 1);
      return this.$t('attributes.editor.selectHierarchy', { hierarchyName });
    },

    setSelectedParentHierarchy(category) {
      this.selectedParentHierarchy = category;
    },

    setSelectedGrandparentHierarchy(category) {
      this.selectedGrandparentHierarchy = category;
    },

    setSelectedChildHierarchy(hierarchy) {
      this.selectedChildHierarchy = hierarchy;
      this.childHierarchyName = this.selectedChildHierarchy.levelEntryDescription;
    },

    async createNewChildHierarchy() {
      const marginTarget = defaultMarginTarget;
      const { type } = this.selectedWorkpackage;
      const parentId = this.selectedParentHierarchy.levelEntryKey;
      const levelEntryDescription = this.childHierarchyName;

      await this.createHierarchy({
        levelEntryDescription,
        parentId,
        type,
        marginTarget,
        level: this.level,
        levelDescription: this.$tc(`hierarchy.hierarchyLevels.${this.level}`, 1),
        mainCompetitors: this.competitors.map(c => c.competitorDescription),
      });

      this.$emit('hierarchyCreated');
    },

    getProductKeyDisplays() {
      return this.fetchWorkpackageProducts({
        params: {
          where: { [`hierarchy.${this.level}`]: this.selectedChildHierarchy.levelEntryKey },
          pick: ['article.productKeyDisplay', 'hierarchy'],
        },
      });
    },

    async formatHierarchyUpdates() {
      const products = await this.getProductKeyDisplays();
      return products.reduce((formattedUpdates, product) => {
        formattedUpdates.push({
          productKeyDisplay: product.article.productKeyDisplay,
          hierarchyId: this.selectedChildHierarchy.levelEntryKey,
          hierarchyLevel: this.level,
          hierarchyDesc: this.childHierarchyName,
        });
        return formattedUpdates;
      }, []);
    },

    async submit() {
      this.closeModal();
      if (this.isEditMode) {
        // update hierarchy description in hierarchy collection
        await this.updateHierarchy({
          levelEntryKey: this.selectedChildHierarchy.levelEntryKey,
          updates: { levelEntryDescription: this.childHierarchyName },
        });
        // update hierarchy description in product-attributes
        const updates = await this.formatHierarchyUpdates();
        await this.updateHierarchyDesc({
          updates,
          params: this.params,
          ignoreAttributeFetch: this.ignoreAttributeFetch,
        });
        this.$emit('hierarchyDescriptionUpdated');
      } else {
        await this.createNewChildHierarchy();
      }
      this.reset();
      // Fetch the hierarchy in the parent to refresh hierarchies
      await this.fetchHierarchy();
    },

    reset() {
      this.childHierarchyName = '';
      this.selectedGrandparentHierarchy = null;
      this.selectedParentHierarchy = null;
      this.selectedChildHierarchy = null;
    },

    isValidName(childHierarchyName) {
      const nameAlreadyTakenError = this.$t(
        'attributes.createNewChildHierarchy.childHierarchyNameTakenError'
      );

      // Get all names and disallowed terms, lowercased
      const existingHierarchyNames = [
        ...map(this.getHierarchies, 'levelEntryDescription'),
        pricingGroupUndefined,
      ].map(toLower);

      return !existingHierarchyNames.includes(toLower(childHierarchyName)) || nameAlreadyTakenError;
    },

    valueRequired(v) {
      return (
        !!v || v === 0 || this.$t('attributes.createNewChildHierarchy.childHierarchyNameRequired')
      );
    },

    cancel() {
      this.reset();
      this.closeModal();
    },

    closeModal() {
      this.$emit('closeHierarchyDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.v-card {
  .body.v-card__text {
    margin-bottom: 7rem;
  }

  .header-text {
    padding-top: 2rem;
    font-size: 2rem;
    color: black;
  }

  .btn-group.v-card__actions {
    .cancel-btn {
      color: $btn-cancel-color;
      border-color: $btn-cancel-color;
    }

    .ok-btn {
      color: $pricing-white;
      background-color: $btn-ok-color;
      border-color: $btn-ok-color;
    }
  }
}

.pg-dropdown-item-text {
  font-size: 1.5rem;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}

.v-btn {
  font-size: 1.5rem;
}
</style>
