import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-screen-page-wrapper",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c("attribute-filter-panel", {
            attrs: {
              "title-localisation": "attributes.filters.filterByAttributes",
              "filter-count-localisation": "attributes.filters.numApplied",
              "enable-hierarchy-filters": "",
              "enable-non-attribute-filters": true,
              "enable-attribute-filters": true,
              "filter-rules": _vm.retailAttributesFilter,
            },
            on: { attributeFilterChange: _vm.setFilterRules },
          }),
          _vm._v(" "),
          _c(
            VAlert,
            {
              attrs: {
                value: _vm.invalidFilters,
                color: "error",
                icon: "warning",
                outlined: "",
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t(`inputs.invalidFilterWarning`)) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VAlert,
            {
              attrs: {
                value: _vm.mainBannerFilterWarning,
                color: "info",
                icon: "info",
                outlined: "",
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t(`inputs.mainBannerFilterWarning`)) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "inputs-actions" },
        [
          _c(VTextField, {
            staticClass: "inputs-actions__search",
            attrs: {
              outlined: "",
              dense: "",
              disabled: _vm.dataLoading,
              label: _vm.$t("actions.search"),
              "single-line": "",
              "append-icon": "mdi-magnify",
            },
            on: { input: _vm.debouncedFilterChange },
            model: {
              value: _vm.searchString,
              callback: function ($$v) {
                _vm.searchString = $$v
              },
              expression: "searchString",
            },
          }),
          _vm._v(" "),
          _c(VAutocomplete, {
            staticClass: "inputs-actions__filter",
            attrs: {
              items: _vm.externalFilterOptions,
              outlined: "",
              dense: "",
              chips: "",
              "deletable-chips": "",
              "small-chips": "",
              label: _vm.$t("inputs.filterBarText"),
              "item-text": _vm.translateLabels,
            },
            on: { input: _vm.externalFilterChanged },
            model: {
              value: _vm.externalFilterSelection,
              callback: function ($$v) {
                _vm.externalFilterSelection = $$v
              },
              expression: "externalFilterSelection",
            },
          }),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "inputs-actions__btn-container" },
            [
              _vm._t("buttons"),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "inputs-actions__download grid-btn",
                  attrs: {
                    color: "primary",
                    small: "",
                    loading: _vm.isExporting,
                    depressed: "",
                  },
                  on: { click: _vm.downloadInputs },
                },
                [
                  _c(VIcon, { attrs: { small: "" } }, [_vm._v("$export")]),
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("actions.download")) +
                      "\n      "
                  ),
                ],
                1
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "inputs-actions__columns grid-btn",
              attrs: { secondary: "", small: "", depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.toggleSideBar("columns")
                },
              },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("inputs.columns")) + "\n      "
              ),
              _c(
                VIcon,
                { staticClass: "material-icons-outlined ml-1 sidebar-toggle" },
                [_vm._v("view_column")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("ag-grid-vue", {
        staticClass: "ag-theme-inputsgrid",
        style: { width: "100%", height: `${_vm.dataTableHeight}px` },
        attrs: {
          "side-bar": _vm.sideBar,
          "column-defs": _vm.allColumns,
          "grid-options": _vm.allGridOptions,
          "stop-editing-when-grid-loses-focus": true,
          "enable-range-selection": true,
          "is-external-filter-present": _vm.isExternalFilterPresent,
          "does-external-filter-pass": _vm.doesExternalFilterPass,
        },
        on: {
          "cell-value-changed": _vm.trackDiff,
          "grid-ready": _vm.onGridReady,
          "first-data-rendered": _vm.onFirstDataRendered,
          "sort-changed": _vm.collapseStoregroups,
          modelUpdated: _vm.onModelUpdated,
        },
      }),
      _vm._v(" "),
      _c("div", { staticClass: "inputs-footer" }, [
        _c("div", { staticClass: "inputs-footer__row-count" }, [
          _c("span", { staticClass: "message" }, [
            _vm._v("Rows: " + _vm._s(_vm.displayedRowCount)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "inputs-footer__msg-container" }, [
          _vm.notAllDataOnDisplay
            ? _c("span", { staticClass: "message" }, [
                _vm._v(_vm._s(_vm.$t("inputs.maxResultsShown"))),
              ])
            : _vm._e(),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "inputs-footer__btn-container" },
          [
            _c(
              VBtn,
              {
                staticClass: "grid-btn",
                attrs: {
                  secondary: "",
                  small: "",
                  disabled: _vm.dataLoading || !_vm.editsExist,
                  depressed: "",
                },
                on: { click: _vm.discardChanges },
              },
              [_vm._v(_vm._s(_vm.$t("inputs.cancel")))]
            ),
            _vm._v(" "),
            _c(
              VBtn,
              {
                attrs: {
                  disabled: !_vm.saveAllowed,
                  small: "",
                  color: "primary",
                  depressed: "",
                },
                on: { click: _vm.handleSave },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("actions.save")) + "\n        "
                ),
                _c(VIcon, { attrs: { small: "" } }, [_vm._v("$import")]),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _vm.showUnsavedChangesDialog
        ? _c("unsaved-changes-dialog", {
            attrs: {
              "show-dialog": _vm.showUnsavedChangesDialog,
              "save-allowed": _vm.saveAllowed,
            },
            on: { closedUnsavedChangesDialog: _vm.handleUnsavedModalDecision },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("delete-hierarchy-level-dialog", {
        key: _vm.deleteDialogToggle,
        attrs: {
          "is-open": _vm.isHierarchyDeleteDialogOpen,
          level: _vm.level,
          "fetch-hierarchy": _vm.setupHierarchy,
        },
        on: { childHierarchyDeleted: _vm.closeHierarchyDeleteDialog },
      }),
      _vm._v(" "),
      !_vm.loadingHierarchy && _vm.isHierarchyDialogOpen
        ? _c("add-new-hierarchy-level-dialog", {
            attrs: {
              "is-open": _vm.isHierarchyDialogOpen,
              level: _vm.level,
              mode: _vm.mode,
              "ignore-attribute-fetch": true,
              "fetch-hierarchy": _vm.setupHierarchy,
            },
            on: { closeHierarchyDialog: _vm.closeHierarchyDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }