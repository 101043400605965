'use strict';

module.exports = {
  percent: 'percent',
  percentWithExtraDecimalPlaces: 'percentWithExtraDecimalPlaces',
  percentWithoutUnitOfMeasurement: 'percentWithoutUnitOfMeasurement',
  integer: 'integer',
  priceFormat: 'priceFormat',
  priceFormatNegative: 'priceFormatNegative',
  currency: 'currency',
  oneDecimalPlace: 'oneDecimalPlace',
  weight: 'weight',
  impactFactor: 'impactFactor',
  decimal: 'decimal',
};
