<template>
  <v-container pa-2>
    <v-layout>
      <v-flex xs4>
        <p class="font-weight-bold">
          {{ item.article.productDescription }}
        </p>
      </v-flex>
    </v-layout>
    <v-layout>
      <v-flex xs4>
        <p class="mb-2">{{ $t('pricing.attributes.vatRate') }}:</p>
        <p class="mb-2">{{ $t('pricing.attributes.distribution') }}:</p>
        <p class="mb-2">{{ $t('pricing.attributes.yearlyVolume') }}:</p>
        <p class="mb-2">{{ $t('pricing.attributes.yearlySales') }}:</p>
        <p class="mb-2">{{ $t('pricing.attributes.contentValue') }}:</p>
        <!-- FEATURE_FLAG: the toggle for switching between price types can be removed -->
        <feature-toggle :toggle="includeNormWeight">
          <p class="mb-2">{{ $t('pricing.attributes.normWeight') }}:</p>
        </feature-toggle>
        <p class="mb-2">{{ $t('pricing.attributes.packageType') }}:</p>
      </v-flex>
      <v-flex xs8>
        <p class="mb-2">
          {{ formatNumber({ number: item.attributes.vatRate, format: numberFormats.percent }) }}
        </p>
        <p class="mb-2">
          {{
            formatNumber({ number: item.attributes.distribution, format: numberFormats.integer })
          }}
        </p>
        <p
          v-number="{
            value: item.yearlyVolume,
            format: $t('number.abbreviationFormat'),
          }"
          class="mb-2"
        />
        <p
          v-number="{
            value: item.yearlySales,
            format: $t('number.abbreviationFormat'),
          }"
          class="mb-2"
        />
        <p class="mb-2">
          {{ formatWeight(item.attributes.contentValue, item.attributes.contentUnitOfMeasure) }}
        </p>
        <feature-toggle :toggle="includeNormWeight">
          <p class="mb-2">
            {{ normWeight }}
          </p>
        </feature-toggle>
        <p class="mb-2">{{ item.attributes.packageTypeDescription }}</p>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { get } from 'lodash';
import { includeNormWeight } from '@enums/feature-flags';
import numberFormats from '@enums/number-formats';

export default {
  props: {
    item: {
      type: Object,
      required: true,
      default: () => ({}),
    },
  },
  data() {
    return {
      includeNormWeight,
      numberFormats,
    };
  },
  computed: {
    normWeight() {
      const normWeight = get(this.item.attributes, 'normWeight');
      const normWeightUoM = get(this.item.attributes, 'normWeightUnitOfMeasure');
      return normWeight ? this.formatWeight(normWeight, normWeightUoM) : '-';
    },
  },
  methods: {
    formatWeight(value, unitOfMeasurement) {
      return `${this.formatNumber({
        number: value,
        format: numberFormats.weight,
      })} ${unitOfMeasurement}`;
    },
  },
};
</script>
