import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { "pa-2": "" } },
    [
      _c(
        VLayout,
        [
          _c(VFlex, { attrs: { xs4: "" } }, [
            _c("p", { staticClass: "font-weight-bold" }, [
              _vm._v(
                "\n        " +
                  _vm._s(_vm.item.article.productDescription) +
                  "\n      "
              ),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VLayout,
        [
          _c(
            VFlex,
            { attrs: { xs4: "" } },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.vatRate")) + ":"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.distribution")) + ":"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.yearlyVolume")) + ":"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.yearlySales")) + ":"),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.contentValue")) + ":"),
              ]),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.includeNormWeight } },
                [
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      _vm._s(_vm.$t("pricing.attributes.normWeight")) + ":"
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.$t("pricing.attributes.packageType")) + ":"),
              ]),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VFlex,
            { attrs: { xs8: "" } },
            [
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.formatNumber({
                        number: _vm.item.attributes.vatRate,
                        format: _vm.numberFormats.percent,
                      })
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.formatNumber({
                        number: _vm.item.attributes.distribution,
                        format: _vm.numberFormats.integer,
                      })
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "number",
                    rawName: "v-number",
                    value: {
                      value: _vm.item.yearlyVolume,
                      format: _vm.$t("number.abbreviationFormat"),
                    },
                    expression:
                      "{\n          value: item.yearlyVolume,\n          format: $t('number.abbreviationFormat'),\n        }",
                  },
                ],
                staticClass: "mb-2",
              }),
              _vm._v(" "),
              _c("p", {
                directives: [
                  {
                    name: "number",
                    rawName: "v-number",
                    value: {
                      value: _vm.item.yearlySales,
                      format: _vm.$t("number.abbreviationFormat"),
                    },
                    expression:
                      "{\n          value: item.yearlySales,\n          format: $t('number.abbreviationFormat'),\n        }",
                  },
                ],
                staticClass: "mb-2",
              }),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.formatWeight(
                        _vm.item.attributes.contentValue,
                        _vm.item.attributes.contentUnitOfMeasure
                      )
                    ) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.includeNormWeight } },
                [
                  _c("p", { staticClass: "mb-2" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.normWeight) + "\n        "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c("p", { staticClass: "mb-2" }, [
                _vm._v(_vm._s(_vm.item.attributes.packageTypeDescription)),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }