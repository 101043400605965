import axios from 'axios';
import Vue from 'vue';
import to from 'await-to-js';
import {
  toLower,
  isEmpty,
  size,
  filter,
  find,
  includes,
  get,
  orderBy,
  sortBy,
  startsWith,
  map,
} from 'lodash';
import downloadXlsxFile from '../utils/download-xlsx-file';
import { formatFilters } from '../utils/filters';
import { getCompareProductsFunc } from '../../utils/sort-store-groups-util';
import { pricingGroupLevel } from '@enums/hierarchy';
import attributeScope from '@enums/attribute-scope';
import { useZones, useManualProductGoLiveDate } from '@enums/feature-flags';
import { storegroupOption } from '@enums/filter-options';
import { errorMapping } from '@enums/attribute-filters';

const getInitialState = () => {
  return {
    attributes: [],
    numFiltersApplied: 0,
    attributeMetadata: [],
    loading: false,
    loadingAvailableValues: false,
    count: 0,
    counts: {},
    uploadError: '',
    availableAttributeValues: {},
    downloadingItems: false,
    busyImportingAttributes: false,
    busyImportingCompetitor: false,
    downloadingUnassignedItems: false,
    productExistsWithUnassignedPricingGroupAttribute: false,
    downloadingProductsWithUnassignedPricingGroupAttributes: false,
    attributesFilterRules: [],
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    globalAttributes: state => {
      const filteredAttributes = filter(state.attributeMetadata, { scope: attributeScope.global });
      return orderBy(filteredAttributes, [attribute => toLower(attribute.displayDescription)]);
    },
    toolStoreGroupGlobalAttributeKey: (state, getters, rootState) => {
      const tsgAttr = find(
        getters.globalAttributes,
        attr => attr.displayDescription === rootState.clientConfig.toolStoreGroupConfig.name
      );
      return get(tsgAttr, 'attributeKey', '');
    },
    pricingGroupAttributes: state => {
      const filteredAttributes = filter(state.attributeMetadata, {
        scope: attributeScope.pricingGroup,
      });
      return orderBy(filteredAttributes, [attribute => toLower(attribute.displayDescription)]);
    },

    // Get global attributes and PG attributes scoped to the AG's parent PG
    attributesByArchitectureGroup: (
      state,
      getters,
      rootState,
      rootGetters
    ) => architectureGroupKey => {
      // get PG from AG parent
      const pricingGroupKey = find(
        rootGetters['hierarchy/getHierarchy'](),
        h => h.levelEntryKey === architectureGroupKey
      ).parentId;

      const filteredAttributes = filter(
        state.attributeMetadata,
        am => am.scope === attributeScope.global || includes(am.pricingGroups, pricingGroupKey)
      );
      return orderBy(filteredAttributes, [attribute => toLower(attribute.displayDescription)]);
    },
  },

  mutations: {
    setAttributes(state, attributes) {
      state.attributes = attributes;
    },

    setNumFiltersApplied(state, numFiltersApplied) {
      state.numFiltersApplied = numFiltersApplied;
    },

    setAttributeMetadata(state, headers) {
      state.attributeMetadata = sortBy(headers, header => toLower(header.displayDescription));
    },

    setAvailableAttributeValues(state, { selectedAttributeKey, availableAttributeValues }) {
      Vue.set(
        state.availableAttributeValues,
        selectedAttributeKey,
        sortBy(availableAttributeValues, attributeValues => toLower(attributeValues))
      );
    },
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setLoadingAvailableValues(state, isLoading) {
      state.loadingAvailableValues = isLoading;
    },

    setDownloadingItems(state, downloading) {
      state.downloadingItems = downloading;
    },

    setBusyImportingAttributes(state, importing) {
      state.busyImportingAttributes = importing;
    },

    setBusyImportingCompetitor(state, importing) {
      state.busyImportingCompetitor = importing;
    },

    setProductAttributeCount(state, count) {
      state.count = count;
    },

    setAffectedCounts(state, counts) {
      state.counts = counts;
    },

    setErrorState(state, error) {
      state.uploadError = error;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setDownloadingUnassignedItems(state, downloading) {
      state.downloadingUnassignedItems = downloading;
    },

    setProductExistsWithUnassignedPricingGroupAttribute(
      state,
      productHasUnassignedPricingGroupAttribute
    ) {
      state.productExistsWithUnassignedPricingGroupAttribute = productHasUnassignedPricingGroupAttribute;
    },

    setDownloadingProductsWithUnassignedPricingGroupAttributes(state, downloading) {
      state.downloadingProductsWithUnassignedPricingGroupAttributes = downloading;
    },

    setFilterRules(state, filterRules) {
      state.attributesFilterRules = filterRules;
    },
  },

  actions: {
    async fetchAttributes({ rootState, commit }, { params = {} } = {}) {
      commit('setLoading', true);

      const { where } = params;

      params.where = formatFilters({ where, rootState });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data: attributes }] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}`, { params })
      );
      if (err) throw new Error(err.message);

      commit('setAttributes', attributes);
      commit('setLoading', false);
    },

    async postHierarchyUpload({ rootState, dispatch }) {
      // eslint-disable-next-line
      this._vm.$eventBus.$emit('inputsUploaded');
      const promises = [
        dispatch('hierarchy/postHierarchyUpload', {}, { root: true }),
        dispatch('hierarchy/fetchHierarchy', {}, { root: true }),
        dispatch(
          'architectureDrivers/updateArchitectureDriversWithHierarchyChanges',
          {},
          { root: true }
        ),
      ];
      // inputs v2 don't use inputScreensFetchParams for filtering.
      // they will be updated with $emit('inputsUploaded')
      // TODO: deprecate fetchAttributesAggregated after inputs v1 is removed
      if (!isEmpty(rootState.filters.inputScreensFetchParams)) {
        promises.push(
          dispatch('fetchAttributesAggregated', {
            params: rootState.filters.inputScreensFetchParams,
          })
        );
      }
      await Promise.all(promises);
    },

    async fetchAttributeEditorData({ rootState, commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/attribute-editor`, {
          params: {
            where: params,
          },
        })
      );

      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const { data: attributes = [] } = response;

      commit('setLoading', false);

      return attributes;
    },

    async fetchAttributesAggregated(
      { rootState, commit, dispatch, getters },
      { params = {}, sortingOnly = false } = {}
    ) {
      commit('setLoading', true);
      const useZonesFlag = rootState.clientConfig.toggleLogic[useZones];
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const storeGroupsNumber =
        rootState.workpackages.selectedWorkpackageToolStoreGroups.length || 1;
      const storeGroupOrderConfig = rootState.clientConfig.storeGroupOrderConfig;
      const isAdditionalSortingByToolStoreGroupRequired = useZonesFlag && storeGroupsNumber > 1;
      const numberOfAdditionalItemsToFetch = isAdditionalSortingByToolStoreGroupRequired
        ? storeGroupsNumber - 1
        : 0;

      const { where } = params;
      dispatch('filters/setInputScreensFilterRules', where, { root: true });

      // where could be either array (attribute filter values) or object (part of the mongo query)
      // array is filter data, object probably comes from page reload request
      if (Array.isArray(where)) {
        params.where = formatFilters({ where, rootState });
      } else {
        params.where = where;
      }

      let countAction;
      if (params.search) {
        countAction = 'getSearchAttributeCount';
      } else {
        countAction = 'getAttributeCount';
      }

      const sortByAttribute = startsWith(params.sortBy, 'attributes');
      // don't use for count requests, don't save to filters/inputScreensFetchParams
      const modifiedParams =
        params && params.limit
          ? {
              ...params,
              limit: params.limit + numberOfAdditionalItemsToFetch,
            }
          : params;

      const queries = [
        to(
          axios.get(
            `/api/attributes/workpackage/${workpackageId}/${
              sortByAttribute ? 'aggregated' : 'inputs'
            }`,
            { params: modifiedParams }
          )
        ),
      ];
      if (!sortingOnly) {
        // if fetching after a sort change, don't query the count
        queries.push(dispatch(countAction, { params }));
      }

      const [[err, response]] = await Promise.all(queries);
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const { data: attributes = [] } = response;
      const toolStoreGroupGlobalAttributeKey = getters.toolStoreGroupGlobalAttributeKey;
      attributes.forEach(row => {
        row.toolStoreGroupDescription = get(
          find(row.attributes, { attributeKey: toolStoreGroupGlobalAttributeKey }),
          'attributeValue',
          ''
        );
      });
      const compareFunc = getCompareProductsFunc({
        sortByField: params.sortBy,
        sortDirection: params.sortDirection,
        dataType: params.sortDataType,
        storeGroupOrderConfig,
        compareByToolStoreGroup: true,
      });
      const sortedAttributes = isAdditionalSortingByToolStoreGroupRequired
        ? attributes.sort(compareFunc).slice(0, params.limit)
        : attributes;
      dispatch('filters/setInputScreensFetchParams', params, { root: true });
      commit('setAttributes', sortedAttributes);
      commit('setLoading', false);

      return sortedAttributes;
    },

    async updateAttributes({ commit, dispatch, rootState }, { updates = {}, params = {} } = {}) {
      if (isEmpty(updates)) return;
      commit('setLoading', true);

      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      await axios.patch(`/api/attributes/workpackage/${workpackageId}`, updates);

      dispatch('fetchAttributesAggregated', { params }); // update attributes displayed, with applied filters
      // eslint-disable-next-line
      this._vm.$emit('successful-attribute-update'); // TODO: remove event emission and return status
    },

    async fetchAttributeMetadata({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!workpackageId) return;
      const [err, { data: headers }] = await to(
        axios.get(`/api/attribute-metadata/workpackage/${workpackageId}`)
      );
      if (err) throw new Error(err.message);

      commit('setAttributeMetadata', headers);
      return headers;
    },

    async fetchAvailableAttributeValues(
      { rootState, commit },
      {
        selectedAttributeMetadata,
        selectedAttributeKey,
        selectedAttributeFilterType,
        selectedAttributeValue,
      }
    ) {
      commit('setLoadingAvailableValues', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const existingStoregroupFilter =
        selectedAttributeMetadata.find(metadata => {
          return metadata.attributeFilterType === storegroupOption;
        }) || {};

      const toolStoreGroupKeys = get(existingStoregroupFilter, 'attributeValue', []);

      const availableAttributeValues = [];
      if (selectedAttributeFilterType === storegroupOption) {
        // If Tool Store Group is selected as a filter value, then we don't fetch values from the backend
        // pull data from workpackages store instead to avoid extra requests
        const storegroupKeyNameMap = rootState.storeGroupRelationships.storegroupKeyNameMap;
        const toolStoreGroupValues = map(storegroupKeyNameMap, (v, k) => ({
          attributeKey: k,
          attributeValue: v,
        }));
        availableAttributeValues.push(...toolStoreGroupValues);

        // TODO: PRICE-2796 remove once store groups removed from feed are handled properly and don't break filters / engine
        const missingToolStoreGroups = (selectedAttributeValue || []).filter(
          v => !Object.keys(storegroupKeyNameMap).includes(v)
        );

        availableAttributeValues.push(
          ...missingToolStoreGroups.map(v => ({
            attributeKey: v,
            attributeValue: `${errorMapping.missingStoreGroup} ${v}`,
          }))
        );
      } else {
        // If anything else is selected as a filter value - fetch values from the backend
        const params = {
          selectedAttributeMetadata,
          selectedAttributeKey,
          selectedAttributeFilterType,
          toolStoreGroupKeys,
        };

        const [err, result] = await to(
          axios.get(`/api/attributes/workpackage/${workpackageId}/available-attribute-values`, {
            params,
          })
        );
        if (err) {
          commit('setLoadingAvailableValues', false);
          throw new Error(err.message);
        }

        availableAttributeValues.push(...result.data);
      }

      commit('setAvailableAttributeValues', {
        selectedAttributeKey,
        availableAttributeValues,
      });
      commit('setLoadingAvailableValues', false);

      return availableAttributeValues;
    },

    async fetchAttributeValuesForProducts({ rootState }, { attributeKey }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const architectureGroupId = rootState.filters.architectureGroup;
      const scenarioKey = rootState.filters.scenario;
      const params = { attributeKey, architectureGroupId, scenarioKey };

      const [err, result] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/product-attribute-values`, {
          params,
        })
      );
      if (err) throw new Error(err.message);
      return result.data;
    },

    async getAttributeCount({ rootState, commit }, { params = {} } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data }] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/count`, { params })
      );
      if (err) throw new Error(err.message);
      commit('setProductAttributeCount', data.count);
    },

    async getSearchAttributeCount({ rootState, commit }, { params = {} } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/aggregated/count`, { params })
      );
      if (err) throw new Error(err.message);
      commit('setProductAttributeCount', response.data.count);
    },

    async deleteAttributeMetadata(
      { commit, dispatch, rootState },
      { attributeKey, params, ignoreAttributeFetch = false }
    ) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.delete(
          `/api/attribute-metadata/workpackage/${workpackageId}/attribute/${attributeKey}`
        )
      );
      if (err) throw new Error(err.message);
      Promise.all([
        dispatch('fetchAttributeMetadata'),
        ...(!ignoreAttributeFetch ? [dispatch('fetchAttributesAggregated', { params })] : []),
      ]);
    },

    async updateAttributeMetadata(
      { commit, dispatch },
      { id, params, ignoreAttributeFetch = false, ...updates }
    ) {
      commit('setLoading', true);
      const [err] = await to(axios.patch(`/api/attribute-metadata/${id}`, updates));
      if (err) throw new Error(err.message);
      Promise.all([
        dispatch('fetchAttributeMetadata'),
        ...(!ignoreAttributeFetch ? [dispatch('fetchAttributesAggregated', { params })] : []),
      ]);
    },

    async createAttribute({ rootState, commit, dispatch, state }, attribute) {
      const { params, ignoreAttributeFetch = false, ...attrs } = attribute;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: formattedAttribute }] = await to(
        axios.post(`/api/attribute-metadata/workpackage/${workpackageId}`, attrs)
      );
      if (err) throw new Error(err.message);
      commit('setAttributeMetadata', [formattedAttribute, ...state.attributeMetadata]);
      if (!ignoreAttributeFetch) dispatch('fetchAttributesAggregated', { params });
    },

    async uploadDataFile({ rootState, commit }, dataFile) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const params = {
        useManualProductGoLiveDate: rootState.clientConfig.toggleLogic[useManualProductGoLiveDate],
      };
      const [err, res] = await to(
        axios.post(`/api/attributes-import/workpackage/${workpackageId}`, dataFile, {
          params,
          headers: {
            // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
            // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
            'Content-Type': undefined,
          },
        })
      );
      if (err) throw err.response.data;
      if (res.data.error) {
        commit('setErrorState', res.data.error);
      } else {
        commit('setAffectedCounts', res.data.counts);
      }
      return res.data;
    },

    async uploadCompetitors({ rootState, commit }, { dataFile }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.post(`/api/attributes-import/competitors/workpackage/${workpackageId}`, dataFile, {
          headers: {
            // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
            // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
            'Content-Type': undefined,
          },
        })
      );
      if (err) throw err.response.data;
      const { data } = res;
      if (data.error) {
        commit('setErrorState', data.error);
        throw new Error(data.error);
      } else {
        commit('setAffectedCounts', data.counts);
      }
      return data;
    },

    async confirmCompetitorUpload({ rootState, dispatch, commit }, { params }) {
      commit('setBusyImportingCompetitor', true);

      // store params for re-fetching after upload is complete
      dispatch('filters/setInputScreensFetchParams', params, { root: true });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.put(`/api/attributes-import/competitors/workpackage/${workpackageId}`)
      );
      if (err) throw new Error(err.message);
    },

    async postAttributeUpload({ dispatch, rootState, commit }) {
      // eslint-disable-next-line
      this._vm.$eventBus.$emit('inputsUploaded');
      // inputs v2 don't use inputScreensFetchParams for filtering.
      // they will be updated with $emit('inputsUploaded')
      // TODO: deprecate fetchAttributesAggregated after inputs v1 is removed
      if (!isEmpty(rootState.filters.inputScreensFetchParams)) {
        dispatch('fetchAttributesAggregated', {
          params: rootState.filters.inputScreensFetchParams,
        });
      }
      dispatch('fetchAttributeMetadata'); // get updated distinctValue for filters
      await dispatch(
        'architectureDrivers/updateArchitectureDriversFromAttributeEditorChanges',
        {},
        { root: true }
      );
      if (rootState.clientConfig.toggleLogic.runProductExistWithUnassignedPGAttribute) {
        dispatch('doesProductExistWithUnassignedPGAttribute');
      }
      commit('setBusyImportingAttributes', false);
    },

    async postCompetitorUpload({ dispatch, rootState, commit }) {
      commit('setBusyImportingCompetitor', false);
      // eslint-disable-next-line
      this._vm.$eventBus.$emit('inputsUploaded');
      // inputs v2 don't use inputScreensFetchParams for filtering.
      // they will be updated with $emit('inputsUploaded')
      // TODO: deprecate fetchAttributesAggregated after inputs v1 is removed
      if (!isEmpty(rootState.filters.inputScreensFetchParams)) {
        dispatch('fetchAttributesAggregated', {
          params: rootState.filters.inputScreensFetchParams,
        });
      }
    },

    async postEngineInputsUpload({ dispatch, rootState }) {
      dispatch('workpackageProducts/postEngineInputsUpload', {}, { root: true });
      // eslint-disable-next-line
      this._vm.$eventBus.$emit('inputsUploaded');
      // inputs v2 don't use inputScreensFetchParams for filtering.
      // they will be updated with $emit('inputsUploaded')
      // TODO: deprecate fetchAttributesAggregated after inputs v1 is removed
      if (!isEmpty(rootState.filters.inputScreensFetchParams)) {
        dispatch('fetchAttributesAggregated', {
          params: rootState.filters.inputScreensFetchParams,
        });
      }
    },

    async confirmUpload({ rootState, dispatch, commit }, { params }) {
      commit('setBusyImportingAttributes', true);

      // store params for re-fetching after upload is complete
      dispatch('filters/setInputScreensFetchParams', params, { root: true });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(axios.put(`/api/attributes-import/workpackage/${workpackageId}`));
      if (err) throw new Error(err.message);
    },

    async downloadItems(
      { rootState, commit },
      { where, translationMap = {}, dateAttributes = [], columnFormatters = {} }
    ) {
      commit('setDownloadingItems', true);
      const params = {
        export: true,
        formatForExport: true,
        translationMap,
        dateAttributeKeys: map(dateAttributes, 'attributeKey'),
      };
      params.where = formatFilters({ where, rootState });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/exportProductData`, { params })
      );
      if (err) {
        commit('setDownloadingItems', false);
        throw new Error(err.message);
      }
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'attributes_data.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
      commit('setDownloadingItems', false);
    },

    async downloadUnassignedItems(
      { rootState, commit },
      { translationMap = {}, columnFormatters = {} }
    ) {
      commit('setDownloadingUnassignedItems', true);
      const params = {
        export: true,
        formatForExport: true,
        translationMap,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/exportUnassignedProductData`, {
          params,
        })
      );
      if (err) {
        commit('setDownloadingUnassignedItems', false);
        throw new Error(err.message);
      }
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'unassigned_hierarchy_products_data.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
      commit('setDownloadingUnassignedItems', false);
    },

    async downloadProductsWithUnassignedPricingGroupAttributes(
      { rootState, commit },
      { translationMap = {}, columnFormatters = {} }
    ) {
      commit('setDownloadingProductsWithUnassignedPricingGroupAttributes', true);
      const params = {
        translationMap,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(
          `/api/attributes/workpackage/${workpackageId}/productsWithUnassignedPricingGroupAttributes`,
          { params }
        )
      );
      if (err) throw new Error(err.message);

      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'products_with_unassigned_pricing_group_attributes.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
      commit('setDownloadingProductsWithUnassignedPricingGroupAttributes', false);
    },

    async updateHierarchyDesc(
      { rootState, dispatch },
      { updates, params, ignoreAttributeFetch = false }
    ) {
      if (isEmpty(updates)) return;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [updateError] = await to(
        axios.patch(`/api/attributes/workpackage/${workpackageId}/hierarchy`, updates)
      );
      if (updateError) throw new Error(updateError.message);

      if (updates[0].hierarchyLevel === pricingGroupLevel) {
        // find default scenario associated with the Pricing group
        const smParams = {
          where: {
            hierarchyId: updates[0].hierarchyId,
            scenarioDescription: { $regex: '^default' },
          },
        };
        const [smFetchErr, defaultScenario] = await to(
          axios.get(`/api/scenario-metadata/workpackage/${workpackageId}`, { params: smParams })
        );
        if (smFetchErr) throw new Error(smFetchErr.message);

        // update scenarioDescription of the default scenario
        if (size(defaultScenario.data)) {
          const defaultScenarioId = defaultScenario.data[0]._id;
          const smUpdates = { scenarioDescription: `default_${updates[0].hierarchyDesc}` };
          const [smUpdateErr] = await to(
            axios.patch(`/api/scenario-metadata/${defaultScenarioId}`, smUpdates)
          );
          if (smUpdateErr) throw new Error(smUpdateErr.message);
        }
      }
      if (!ignoreAttributeFetch) {
        await dispatch('fetchAttributesAggregated', { params }); // update attributes displayed, with applied filters
      }
    },

    resetState({ commit }) {
      commit('resetState');
    },

    async checkIfAttributeUsedInSettingsRules({ rootState }, attribute) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/rules/workpackage/${workpackageId}/attribute/${attribute.value}`)
      );
      if (err) throw new Error(err.message);
      return response.data;
    },

    async checkIfAttributeUsedInArchitectureDrivers({ rootState }, attribute) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(
          `/api/architecture-drivers/workpackage/${workpackageId}/attribute/${attribute.value}`
        )
      );
      if (err) throw new Error(err.message);
      return response.data;
    },

    async checkIfAttributeUsedInArchitectureSubGroups({ rootState }, attribute) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(
          `/api/scenario-metadata/workpackage/${workpackageId}/attribute/${attribute.value}`
        )
      );
      if (err) throw new Error(err.message);
      return response.data;
    },

    async getPricingGroupFromScenarioKey({ rootGetters }, { scenarioKey, workpackageId }) {
      const [err, res] = await to(
        axios.get(`/api/scenario-metadata/${scenarioKey}/workpackage/${workpackageId}`)
      );
      // If something goes wrong, still need to stop the user deleting the attribute, so don't throw error here.
      if (err) return {};

      return {
        pricingGroup: rootGetters['hierarchy/getHierarchy']({
          levelEntryKey: res.data.hierarchyId,
        })[0],
        scenarioDescription: res.data.scenarioDescription,
      };
    },

    async doesProductExistWithUnassignedPGAttribute({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const params = { workpackageId };
      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/unassignedPricingGroupAttributes`, {
          params,
        })
      );
      if (err) {
        throw new Error(err.message);
      }
      commit('setProductExistsWithUnassignedPricingGroupAttribute', response.data);
    },

    async checkForCompetitorUses({ rootState, dispatch }, { competitor }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/rules/workpackage/${workpackageId}/competitor/${competitor.competitorKey}`)
      );
      if (err) throw new Error(err.message);
      const rulesWithCompetitor = await Promise.all(
        map(response.data, async rule => {
          const ruleName = rule.description;
          const scenarioDetails = !isEmpty(rule)
            ? await dispatch('getPricingGroupFromScenarioKey', rule)
            : {};
          const pricingGroupName = get(
            scenarioDetails,
            ['pricingGroup', 'levelEntryDescription'],
            ''
          );
          const scenarioDescription = get(scenarioDetails, ['scenarioDescription'], '');
          return {
            translationKey: 'competitorInSettingsRules',
            translationParams: { ruleName, pricingGroupName, scenarioDescription },
          };
        })
      );
      return rulesWithCompetitor;
    },

    async checkForAttributeUses({ dispatch, rootGetters, rootState }, attribute) {
      const [usedInSR, usedInAD, usedInASG] = await Promise.all([
        dispatch('checkIfAttributeUsedInSettingsRules', attribute),
        dispatch('checkIfAttributeUsedInArchitectureDrivers', attribute),
        dispatch('checkIfAttributeUsedInArchitectureSubGroups', attribute),
      ]);

      const ruleName = usedInSR.description;

      const usedInArchitectureGroup = rootGetters['hierarchy/getHierarchy']({
        levelEntryKey: usedInAD.architectureGroupId,
      })[0];
      const architectureGroupName = get(usedInArchitectureGroup, 'levelEntryDescription');

      const scenarioDetails = !isEmpty(usedInSR)
        ? await dispatch('getPricingGroupFromScenarioKey', usedInSR)
        : {};
      const pricingGroupName = get(scenarioDetails, ['pricingGroup', 'levelEntryDescription'], '');
      const scenarioDescription = get(scenarioDetails, ['scenarioDescription'], '');

      const architectureGroupNameForSubGroup = get(
        find(rootState.hierarchy.hierarchy[rootState.workpackages.selectedWorkpackage._id], {
          levelEntryKey: get(usedInASG, ['subGroups', 0, 'k']),
        }),
        'levelEntryDescription'
      );

      return [
        {
          translationKey: 'attributeInSettingsRules',
          translationParams: { ruleName, pricingGroupName, scenarioDescription },
          exists: !isEmpty(usedInSR),
        },
        {
          translationKey: 'attributeInArchitectureDrivers',
          translationParams: { architectureGroupName },
          exists: !isEmpty(usedInAD),
        },
        {
          translationKey: 'attributeInArchitectureSubGroups',
          translationParams: {
            architectureGroupName: architectureGroupNameForSubGroup,
            scenarioName: usedInASG.scenarioDescription,
          },
          exists: !isEmpty(usedInASG),
        },
      ];
    },
  },
};

export default store;
