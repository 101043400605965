import {
  unitLevel,
  unitLevelDescription,
  pricingGroupLevel,
  architectureGroupLevel,
  rootHierarchyLevel,
  categoryLevel,
  pricingGroupLevelDescription,
  architectureGroupLevelDescription,
  categoryLevelDescription,
} from '@enums/hierarchy';
import { hierarchyOption, wholesaleHierarchyOption } from '@enums/filter-options';

const mixin = {
  methods: {
    getArchitectureGroupFilterDefaults() {
      return {
        // note attributeKey can also store hierarchyLevel. See availableAttributeMetadata computed for another example.
        attributeKey: architectureGroupLevel,
        attributeDescription: architectureGroupLevelDescription,
        displayDescription: this.$t('pricing.architectureGroup'),
        attributeFilterType: hierarchyOption,
      };
    },
    getHierarchyFilters({ level = rootHierarchyLevel }) {
      // get filters for a given level and below. by default return filters for all available levels.
      return [
        {
          attributeKey: unitLevel,
          attributeDescription: unitLevelDescription,
          displayDescription: this.$t('pricing.unit'),
          attributeFilterType: hierarchyOption,
        },
        {
          attributeKey: categoryLevel,
          attributeDescription: categoryLevelDescription,
          displayDescription: this.$t('pricing.category'),
          attributeFilterType: hierarchyOption,
        },
        {
          attributeKey: pricingGroupLevel,
          attributeDescription: pricingGroupLevelDescription,
          displayDescription: this.$t('pricing.pricingGroup'),
          attributeFilterType: hierarchyOption,
        },
        this.getArchitectureGroupFilterDefaults(),
      ].filter(v => v.attributeKey >= level);
    },
    getWholesaleHierarchyFilters() {
      return [
        {
          attributeKey: categoryLevel,
          attributeDescription: categoryLevelDescription,
          displayDescription: this.$t('pricing.category'),
          attributeFilterType: wholesaleHierarchyOption,
        },
        {
          attributeKey: pricingGroupLevel,
          attributeDescription: pricingGroupLevelDescription,
          displayDescription: this.$t('pricing.pricingGroup'),
          attributeFilterType: wholesaleHierarchyOption,
        },
      ];
    },
  },
};

export default mixin;
