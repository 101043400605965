'use strict';

module.exports = {
  missingKey: 'missingKey',
  unknownKey: 'unknownKey',
  wrongLengthRowForKey: 'wrongLengthRowForKey',
  nanOrNegativeInRowForKey: 'nanOrNegativeInRowForKey',
  noGridsSheet: 'noGridsSheet',
  noHeaders: 'noHeaders',
  badHeaders: 'badHeaders',
  missingHeaders: 'missingHeaders',
  unknownOwnBrand: 'unknownOwnBrand',
  unknownTSG: 'unknownTSG',
  unknownGrid: 'unknownGrid',
};
