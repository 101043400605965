<template>
  <div>
    <basic-spinner v-if="initialLoading" class="spinner-wrapper" />
    <div class="d-flex justify-start mx-1">
      <div class="d-flex">
        <span class="root"> {{ $t('wholesale.historicPeriodsLabel') }}:</span>
        <v-select
          :value="selectedHistoricalPeriod || defaultHistoricalPeriod"
          :items="historicPeriodsOptions"
          class="label-part grid-dropdown"
          :menu-props="{
            contentClass: 'margin-splits-grids-menu',
          }"
          :loading="loading"
          :disabled="loading"
          @input="setSelectedHistoricPeriod"
        />
      </div>
    </div>
    <wholesale-hierarchy
      class="wholesale-hierarchy"
      :row-data="tableRows"
      :is-loading="loading"
      @toggleLevel="onToggleExpansion"
      @toggleStoreGroup="toggleStoreGroupExpansion"
      @gridReady="initialLoading = false"
    />
  </div>
</template>

<script>
import to from 'await-to-js';
import { forEach, reduce, get, sortBy, findIndex, fill, map } from 'lodash';
import { mapActions, mapState, mapGetters } from 'vuex';
import { wholesaleHierarchyLevels } from '@enums/hierarchy';
import { yearTimePeriod } from '@enums/historical-periods';
import { makeHierarchyKey } from '../../../utils/wholesale-product-results-utils';
import sortStoreGroups from '../../../utils/sort-store-groups-util';

const {
  unitLevel,
  categoryLevel,
  subcategoryLevel,
  segmentLevel,
  subsegmentLevel,
  microsegmentLevel,
} = wholesaleHierarchyLevels;

export default {
  data() {
    return {
      isLoading: false,
      initialLoading: true,
      defaultHistoricalPeriod: null,
    };
  },

  computed: {
    ...mapGetters('wholesaleProductResults', ['anyAggregationsLoading']),
    ...mapGetters('clientConfig', ['getHardcodedStoreGroupOrder']),
    ...mapState('wholesaleProductResults', [
      'expandedHierarchyLevelItems',
      'expandedStoreGroupAggregations',
      'hierarchy',
      'selectedHistoricalPeriod',
      'loadingStoreGroupAggregations',
    ]),
    ...mapState('clientConfig', {
      storeGroupOrderConfig: 'storeGroupOrderConfig',
      hierarchyConfig: 'hierarchy',
    }),

    loading() {
      return this.isLoading || this.anyAggregationsLoading;
    },

    orderedHierarchyLevelItems() {
      return reduce(
        this.expandedHierarchyLevelItems,
        (orderedItems, parentId, level) => {
          const key = makeHierarchyKey(level, parentId);
          const items = this.hierarchy[key];
          const sortedItems = sortBy(items, [item => item.name.toLowerCase()]);

          if (!sortedItems) {
            return orderedItems;
          }
          if (level === this.hierarchyConfig.rootHierarchyLevel) {
            return [...sortedItems];
          }

          const parentIx = findIndex(orderedItems, { _id: parentId });

          // if we don't have a parent for the selection ignore.
          if (parentIx === -1) {
            return orderedItems;
          }

          orderedItems.splice(parentIx + 1, 0, ...sortedItems);
          return orderedItems;
        },
        []
      );
    },

    tableRows() {
      // https://github.com/ag-grid/ag-grid/issues/1665
      // ag-grid treats [] as no rows, null as still loading.
      // if we always do this on loading we break row-expansion. only do this when first loading the component.
      if (this.initialLoading) return null;
      return reduce(
        this.orderedHierarchyLevelItems,
        (flatHierarchy, item) => {
          const { level } = item;
          item.toolStoreGroupDescription = get(item, ['_id', 'toolStoreGroupDescription']);
          item._id = get(item, ['_id', 'levelGroupId'], item._id);
          item.isLevelExpanded =
            !!this.expandedHierarchyLevelItems[level + 1] &&
            this.expandedHierarchyLevelItems[level + 1] === item._id;
          item.isStoreGroupExpanded =
            !!item.storeGroupAggregations &&
            this.expandedStoreGroupAggregations.includes(item.parentId);
          flatHierarchy.push({ ...item });
          if (item.isStoreGroupExpanded) {
            const sortedStoreGroupAggregations = sortStoreGroups(
              item.storeGroupAggregations,
              this.getHardcodedStoreGroupOrder, // TODO: PRICE-2315 remove this hack
              'name'
            );
            flatHierarchy.push(...sortedStoreGroupAggregations);
            // narrow blank row after store group aggregations
            flatHierarchy.push({ isStoreGroupEnd: true });
          }
          return flatHierarchy;
        },
        []
      );
    },

    historicPeriodsOptions() {
      const defaultOption = { text: this.$t('wholesale.historicPeriods.fullYear'), value: null };
      const historicPeriods = map(yearTimePeriod, (historicPeriod, key) => ({
        text: this.$t(`wholesale.historicPeriods.${key}`),
        value: historicPeriod,
      }));
      return [defaultOption, ...historicPeriods];
    },
  },

  async created() {
    // This should not block the grid initialisation. The result is only accessed when user expands one of the categories.
    // By the time the user expands a category, fetchHierarchyKeyDisplays should have already completed.
    this.fetchHierarchyKeyDisplays();
    await this.fetchWholesaleAggregations();
  },

  methods: {
    ...mapActions('wholesaleProductResults', [
      'setExpandedHierarchyLevelItems',
      'fetchRootHierarchyLevelItemId',
      'fetchAggregatedHierarchyLevelItems',
      'toggleStoreGroupAggregations',
      'setSelectedHistoricalPeriod',
      'fetchHierarchyKeyDisplays',
    ]),

    async onToggleExpansion({ parentId, level, beingExpanded }) {
      this.isLoading = true;
      try {
        // remove all items below the expanded level
        this.setExpandedHierarchyLevelItems({
          newExpandedHierarchy: fill([...this.expandedHierarchyLevelItems], null, level + 1),
        });
        // this means they are collapsing an expanded row
        if (!beingExpanded) return;

        // trigger a dispatch for the new aggregations
        await this.fetchAggregatedHierarchyLevelItems({
          aggregationLevel: level + 1,
          parentId,
          historicalPeriod: this.selectedHistoricalPeriod,
        });

        const newExpandedHierarchy = [...this.expandedHierarchyLevelItems];
        newExpandedHierarchy[level + 1] = parentId;
        this.setExpandedHierarchyLevelItems({ newExpandedHierarchy });
      } finally {
        this.isLoading = false;
      }
    },

    toggleStoreGroupExpansion({ parentId, level, beingExpanded, levelEntryKey }) {
      this.toggleStoreGroupAggregations({ parentId, level, beingExpanded, levelEntryKey });
    },

    setSelectedHistoricPeriod(selectedPeriod) {
      if (selectedPeriod === this.selectedHistoricalPeriod) return;
      this.setSelectedHistoricalPeriod(selectedPeriod);
      this.fetchWholesaleAggregations(this.selectedHistoricalPeriod);
    },

    async fetchWholesaleAggregations(historicalPeriod = null) {
      this.isLoading = true;
      try {
        await this.fetchRootHierarchyLevelItemId();
        // store the parentId of each hierarchyLevelItem where the array ix is the aggregation level
        await this.setExpandedHierarchyLevelItems({ newExpandedHierarchy: null });
        const fetchWholesaleAggregations = [
          this.fetchAggregatedHierarchyLevelItems({ historicalPeriod }),
        ];
        const hierarchyLevels = [
          unitLevel,
          categoryLevel,
          subcategoryLevel,
          segmentLevel,
          subsegmentLevel,
          microsegmentLevel,
        ];

        forEach(hierarchyLevels, level => {
          fetchWholesaleAggregations.push(
            this.fetchAggregatedHierarchyLevelItems({
              historicalPeriod,
              aggregationLevel: level,
              parentId: this.expandedHierarchyLevelItems[level],
            })
          );
        });

        // don't remove "to", it's required to prevent printing of "unhandled promise rejection" errors
        await to(Promise.all(fetchWholesaleAggregations));
      } finally {
        this.isLoading = false;
      }
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.wholesale-hierarchy {
  padding: 1rem;
}

.root {
  font-weight: bold;
  font-size: 1.2rem;
  margin-right: 1.2rem;
  padding: 0.5rem 1rem 0.5rem 1rem;
  align-self: center;

  &.highlighted-node {
    background-color: $margin-split-highlight-color;
  }
}

.label-part + .label-part {
  margin-left: 1.2rem;
}

.grid-dropdown {
  min-width: 20rem;
  max-width: 20rem;
}
</style>
