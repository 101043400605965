<template>
  <v-menu
    v-model="alertsOpen"
    offset-y
    :close-on-content-click="false"
    :disabled="!totalAlertCount"
  >
    <template v-slot:activator="{ on }">
      <div class="alert-header d-flex pl-3" v-on="on" @click="getOnDemandAlerts">
        <div class="flex-grow-1">
          <v-icon v-once color="white" class="alert-icon">
            warning
          </v-icon>
          <span class="pl-1">
            {{
              formatNumber({
                number: daysToCompletion,
                format: numberFormats.integer,
                zeroAsDash: true,
              })
            }}
            {{ $tc('workPackages.daysToCompletion', daysToCompletion) }} /
            {{ formatNumber({ number: totalAlertCount, format: numberFormats.integer }) }}
            {{ $tc('alerts.alerts', totalAlertCount) }}
          </span>
        </div>
        <div class="pr-3">
          <v-icon
            v-if="alertFilter && onGridViewPages"
            v-once
            color="white"
            size="2rem"
            class="pr-4"
            @click.stop="clearFilter"
          >
            mdi-filter-variant-remove
          </v-icon>
          <v-icon
            v-if="!alertsOpen && totalAlertCount"
            v-once
            color="white"
            class="font-weight-bold"
          >
            expand_more
          </v-icon>
          <v-icon
            v-if="alertsOpen && totalAlertCount"
            v-once
            color="white"
            class="font-weight-bold"
          >
            expand_less
          </v-icon>
        </div>
      </div>
    </template>
    <v-list dense class="alerts-list">
      <template v-for="(alertCount, alertType) in alertCountsAll">
        <div :key="alertType">
          <v-list-item>
            <v-list-item-title>
              <v-row>
                <v-col class="pb-0">{{ getAlertText(alertType) }}</v-col>
                <v-col class="text-right pb-0 d-flex justify-end">
                  <span :class="alertType !== 'all' && onGridViewPages ? 'my-1 mx-5' : ''">{{
                    alertCount
                  }}</span>
                  <v-icon
                    v-if="alertType !== 'all' && onGridViewPages"
                    :color="getFilterColour(alertType)"
                    class="my-0"
                    size="2rem"
                    @click="setAlertFilterSelection(alertType)"
                  >
                    filter_list
                  </v-icon>
                </v-col>
              </v-row>
            </v-list-item-title>
          </v-list-item>
          <v-divider class="alerts-divider" />
        </div>
      </template>
      <div v-if="productExistsWithIncompleteHierarchy && canSeeOnDemandAlerts">
        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>{{ $t('alerts.pleaseReviewHierarchyInformation') }}</v-col>
              <v-col class="text-right pb-0 pl-0 pt-2">
                <v-btn
                  height="2rem"
                  :loading="downloadingUnassignedItems"
                  :color="buttonColor"
                  class="download-button"
                  depressed
                  @click="downloadProductsWithMissingPgsOrAgs"
                >
                  <span class="button-text">{{ $t('actions.download') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="alerts-divider" />
      </div>
      <div v-if="productExistsWithUnassignedPricingGroupAttribute && canSeeOnDemandAlerts">
        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>{{ $t('alerts.productsExistWithMissingPricingGroupAttributesValues') }}</v-col>
              <v-col class="text-right pb-0 pl-0 pt-2">
                <v-btn
                  height="2rem"
                  :loading="downloadingProductsWithUnassignedPricingGroupAttributes"
                  :color="buttonColor"
                  class="download-button"
                  depressed
                  @click="downloadProductsWithUnassignedPGAttributes"
                >
                  <span class="button-text">{{ $t('actions.download') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="alerts-divider" />
      </div>

      <div v-if="productExistsWithMissingEngineInputs && canSeeOnDemandAlerts">
        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>{{ $t('alerts.productMissingEngineInputs') }}</v-col>
              <v-col class="text-right pb-0 pl-0 pt-2">
                <v-btn
                  height="2rem"
                  :loading="downloadingProductsMissingInputs"
                  :color="buttonColor"
                  class="download-button"
                  depressed
                  @click="downloadProductsMissingEngineInputs"
                >
                  <span class="button-text">{{ $t('actions.download') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="alerts-divider" />
      </div>

      <div v-if="productExistsWithInvalidEngineInputs && canSeeOnDemandAlerts">
        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>{{ $t('alerts.productInvalidEngineInputs') }}</v-col>
              <v-col class="text-right pb-0 pl-0 pt-2">
                <v-btn
                  height="2rem"
                  :loading="downloadingProductsInvalidEngineInputs"
                  :color="buttonColor"
                  class="download-button"
                  depressed
                  @click="downloadProductsInvalidEngineInputs"
                >
                  <span class="button-text">{{ $t('actions.download') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="alerts-divider" />
      </div>

      <div v-if="size(failedRuns) && canSeeOnDemandAlerts">
        <v-list-item>
          <v-list-item-title>
            <v-row>
              <v-col>{{ $t('alerts.failedEngineRunsExist') }}</v-col>
              <v-col class="text-right pb-0 pl-0 pt-2">
                <v-btn
                  height="2rem"
                  :color="buttonColor"
                  class="download-button"
                  depressed
                  @click="openEngineRunModal"
                >
                  <span class="button-text">{{ $t('alerts.seeFailedRunInfo') }}</span>
                </v-btn>
              </v-col>
            </v-row>
          </v-list-item-title>
        </v-list-item>
        <v-divider class="alerts-divider" />
      </div>
    </v-list>
    <failed-runs-modal
      :show-dialog="showEngineRunModal"
      :runs="failedRuns"
      @closeDialog="closeEngineRunModal"
    />
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { get, pick, pickBy, sum, values, size, omit, keys, mapValues } from 'lodash';
import colors from '../ow-colors';
import mandatoryEngineInputs from '@enums/mandatory-engine-inputs';
import { categoryManagerPermissions } from '@enums/alert-permissions';
import numberFormats from '@enums/number-formats';
import timeFlexibleEndingsMap from '@enums/time-flexible-endings';

export default {
  data() {
    return {
      size,
      alertsOpen: false,
      showEngineRunModal: false,
      buttonColor: colors.alertsIconColor,
      numberFormats,
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('hierarchy', ['productExistsWithIncompleteHierarchy']),
    ...mapState('attributes', [
      'downloadingUnassignedItems',
      'downloadingProductsWithUnassignedPricingGroupAttributes',
      'productExistsWithUnassignedPricingGroupAttribute',
    ]),
    ...mapState('engineInputs', [
      'productExistsWithMissingEngineInputs',
      'productExistsWithInvalidEngineInputs',
      'downloadingProductsMissingInputs',
      'downloadingProductsInvalidEngineInputs',
    ]),
    ...mapState('gridView', [
      'failedRuns',
      'alertFilter',
      'alertCounts',
      'selectedHistoricalPeriod',
    ]),
    ...mapGetters('context', ['isPricingSpecialist', 'isCategoryManager']),
    ...mapState('clientConfig', ['exportConfigs', 'toggleLogic']),

    alerts() {
      const historicalAlertsToOmit = keys(
        omit(timeFlexibleEndingsMap, [this.selectedHistoricalPeriod])
      ).map(ending => `missingHistoricalData${ending}`);
      if (this.isPricingSpecialist)
        return {
          ...pickBy(omit(this.alertCounts, historicalAlertsToOmit), alertCount => alertCount > 0),
        };

      return {
        ...pickBy(
          pick(omit(this.alertCounts, historicalAlertsToOmit), categoryManagerPermissions),
          alertCount => alertCount > 0
        ),
      };
    },

    totalAlertCount() {
      let totalAlerts = sum(values(this.alerts));
      if (this.canSeeOnDemandAlerts) {
        if (size(this.failedRuns)) totalAlerts += 1;
        if (this.productExistsWithIncompleteHierarchy) totalAlerts += 1;
        if (this.productExistsWithMissingEngineInputs) totalAlerts += 1;
        if (this.productExistsWithInvalidEngineInputs) totalAlerts += 1;
        if (this.productExistsWithUnassignedPricingGroupAttribute) totalAlerts += 1;
      }

      return totalAlerts;
    },

    alertCountsAll() {
      const all = this.totalAlertCount;

      return all > 0
        ? {
            all,
            ...this.alerts,
          }
        : {};
    },

    daysToCompletion() {
      const today = moment();
      const completionDate = moment(this.selectedWorkpackage.completionDate);
      return completionDate.diff(today, 'days');
    },

    canSeeOnDemandAlerts() {
      return this.isPricingSpecialist;
    },

    onGridViewPages() {
      return this.$route.path.includes('/pricing/');
    },
  },

  async created() {
    await Promise.all([this.getAlertCounts(), this.getOnDemandAlerts()]);
  },

  methods: {
    ...mapActions('gridView', ['getFailedRuns', 'setAlertFilter', 'getAlertCounts']),
    ...mapActions('hierarchy', ['doesProductExistWithIncompleteHierarchy']),
    ...mapActions('attributes', [
      'downloadUnassignedItems',
      'doesProductExistWithUnassignedPGAttribute',
      'downloadProductsWithUnassignedPricingGroupAttributes',
    ]),
    ...mapActions('engineInputs', [
      'doesProductExistWithMissingEngineInputs',
      'downloadProductsWithMissingEngineInputs',
      'doesProductExistWithInvalidEngineInputs',
      'downloadProductsWithInvalidEngineInputs',
    ]),

    openEngineRunModal() {
      this.showEngineRunModal = true;
    },

    closeEngineRunModal() {
      this.showEngineRunModal = false;
    },

    getFilterColour(alertType) {
      if (!this.alertFilter) return 'white';
      if (this.alertFilter === alertType) return this.buttonColor;
      return 'grey';
    },

    setAlertFilterSelection(alertType) {
      if (this.alertFilter === alertType) this.setAlertFilter(null);
      else this.setAlertFilter(alertType);
    },

    clearFilter() {
      this.setAlertFilter(null);
    },

    async getOnDemandAlerts() {
      if (this.alertsOpen || !this.canSeeOnDemandAlerts) return;
      const promises = [
        this.getFailedRuns(),
        this.doesProductExistWithIncompleteHierarchy(),
        this.doesProductExistWithMissingEngineInputs(),
        this.doesProductExistWithInvalidEngineInputs(),
      ];

      if (this.toggleLogic.runProductExistWithUnassignedPGAttribute) {
        promises.push(this.doesProductExistWithUnassignedPGAttribute());
      }
      await Promise.all(promises);
    },

    downloadProductsWithMissingPgsOrAgs() {
      this.downloadUnassignedItems({
        translationMap: {
          productKeyDisplay: this.$t('pricing.productKey'),
          productName: this.$t('pricing.productDescription'),
          category: this.$t('pricing.category'),
          pricingGroup: this.$t('pricing.pricingGroup'),
          architectureGroup: this.$t('pricing.architectureGroup'),
        },
        columnFormatters: {
          [this.$t('pricing.productKey')]: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
      });
    },

    downloadProductsWithUnassignedPGAttributes() {
      this.downloadProductsWithUnassignedPricingGroupAttributes({
        translationMap: {
          productKeyDisplay: this.$t('pricing.productKey'),
          productName: this.$t('pricing.productDescription'),
          category: this.$t('pricing.category'),
          pricingGroup: this.$t('pricing.pricingGroup'),
          architectureGroup: this.$t('pricing.architectureGroup'),
        },
        columnFormatters: {
          [this.$t('pricing.productKey')]: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
      });
    },

    downloadProductsMissingEngineInputs() {
      this.downloadProductsWithMissingEngineInputs({
        translationMap: {
          productKeyDisplay: this.$t('engineInputs.editor.productKey'),
          productDescription: this.$t('engineInputs.editor.productDescription'),
          toolStoreGroupKey: this.$t('engineInputs.editor.toolStoreGroup'),
          ...mapValues(mandatoryEngineInputs, path => this.$t(path)),
        },
        columnFormatters: {
          [this.$t('engineInputs.editor.productKey')]: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
      });
    },

    downloadProductsInvalidEngineInputs() {
      this.downloadProductsWithInvalidEngineInputs({
        translationMap: {
          productKeyDisplay: this.$t('engineInputs.editor.productKey'),
          productDescription: this.$t('engineInputs.editor.productDescription'),
          toolStoreGroupKey: this.$t('engineInputs.editor.toolStoreGroup'),
          ...mapValues(mandatoryEngineInputs, path => this.$t(path)),
          // attributes
          vatRate: this.$t('pricing.attributes.vatRate'),
        },
        columnFormatters: {
          [this.$t('engineInputs.editor.productKey')]: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
      });
    },

    getAlertText(alertType) {
      if (alertType === `missingHistoricalData${this.selectedHistoricalPeriod}`) {
        const direction = this.selectedHistoricalPeriod.toLowerCase().includes('forward')
          ? 'forward'
          : 'back';
        const number = parseInt(this.selectedHistoricalPeriod.replace(/[^0-9]/g, ''), 10);
        return `${this.$tc(`alerts.missingHistoricalData`)}: ${this.$tc(
          `gridView.historicPeriods.${direction}`,
          number,
          { number }
        )}`;
      }
      return this.$tc(`alerts.${alertType}`);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.alert-header {
  background-color: $alertsIconColor;
  color: white;
  font-size: 1.5rem;
  margin-top: -2rem;
  padding-top: 2rem;
  margin-bottom: -1rem;
  padding-bottom: 1rem;
}

.button-text {
  color: white;
  font-size: 1.2rem;
}

.download-button {
  width: 1rem;
}

.alerts-list {
  background-color: $alertsBackground;
  border-radius: 0px !important;
}

.v-list--dense .v-list-item .v-list-item__title {
  font-size: 1.4rem;
  color: white;
  padding-bottom: 0.3rem;
}

.alerts-divider {
  border-color: $alertsIconColor;
}

.v-menu__content {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}
</style>

<style lang="scss">
.download-button {
  .v-btn__loader {
    color: white;
    .v-progress-circular.v-progress-circular--indeterminate {
      height: 1.8rem !important;
      width: 1.8rem !important;
    }
  }
}
</style>
