import { reduce, includes } from 'lodash';

function hasRolesCallback(state, roles) {
  return (
    roles &&
    state.profile &&
    state.profile.roles &&
    reduce(roles, (res, role) => res || includes(state.profile.roles, role), false)
  );
}

export default hasRolesCallback;
