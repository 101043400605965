import { differenceWith, filter, isEmpty, invert, sortBy } from 'lodash';

function sortCompetitors({ competitorMetadata, competitorOrder }) {
  // have to account for case where any or all competitorOrder could be missing from competitorMetadata
  const customSortedCompetitors = [];
  const invertedCompetitorOrder = invert(competitorOrder);
  const indexes = Object.keys(invertedCompetitorOrder).map(k => parseInt(k, 10));
  // sort numerically! default js sort is alphabetical

  indexes
    .sort((a, b) => a - b)
    .forEach(i => {
      // handle case where there may be multiple competitors with the same name and type.
      const localCompetitor = filter(competitorMetadata, {
        competitorDisplayDescription: invertedCompetitorOrder[`${i}`],
      });

      if (!isEmpty(localCompetitor)) {
        customSortedCompetitors.push(...localCompetitor);
      }
    });

  // get all other competitors, and sort them alphabetically, after the explicitly ordered competitors in config.
  const theRest = differenceWith(
    competitorMetadata,
    customSortedCompetitors,
    (a, b) => a.competitorDisplayDescription === b.competitorDisplayDescription
  );

  return [...customSortedCompetitors, ...sortBy(theRest, ['competitorDisplayDescription'])];
}

export default sortCompetitors;
