'use strict';

const numberFormats = require('../data/enums/number-formats');

const formatCompetitorPriceHistoryLabel = (competitor, numberFormatter, addToLabel) => {
  const formattedPrice = numberFormatter({
    number: competitor.competitorPrice,
    format: numberFormats.priceFormat,
  });

  return addToLabel(`${formattedPrice}`, competitor, numberFormatter);
};

const addCompetitorIndex = (baseLabel, competitor, numberFormatter) => {
  const formattedPercent = numberFormatter({
    number: competitor.livePriceIndex,
    format: numberFormats.percent,
  });
  return `${baseLabel} / ${formattedPercent}`;
};

module.exports = {
  formatCompetitorPriceHistoryLabel,
  addCompetitorIndex,
};
