import { mapGetters } from 'vuex';
import { isNaN } from 'lodash';
import { calculateHeadersAndFootersHeight } from '../utils/data-table-utils';
import DataTypes from '@enums/data-types';

const attributes = 'attributes';

const mixin = {
  data() {
    return {
      dataTableHeight: 700, // default value, requires explicit height to get fixed table header
      rootTableSelector: 'div.v-data-table__wrapper', // default value, overridden for different table implementations. e.g. ag-grid 'div.ag-theme-inputsgrid' vs vuetify 'div.v-data-table__wrapper'
    };
  },
  mounted() {
    this.$nextTick(() => {
      // reset table height when window is resized
      // can't use computed, need to wait for component to render to calculate height of dom elements.
      this.calculateDataTableHeight();
      window.addEventListener('resize', this.calculateDataTableHeight, true);
    });
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.calculateDataTableHeight, true);
  },
  computed: {
    ...mapGetters('attributes', ['toolStoreGroupGlobalAttributeKey']),
    toolStoreGroupColumnDefaultProperties() {
      return {
        value: this.toolStoreGroupGlobalAttributeKey,
        sortable: true,
        align: 'start',
        isAttribute: true,
        dataType: DataTypes.str,
      };
    },
  },
  methods: {
    calculateDataTableHeight() {
      // removing extra pixels ensures that scrolling doesn't show up for the entire page
      this.dataTableHeight =
        window.innerHeight -
        calculateHeadersAndFootersHeight(this.rootTableSelector, this.dataTableHeight) -
        1;
    },
    getColumnValue(column) {
      if (!column) return null;
      return column.isAttribute ? `${attributes}.${column.value}` : column.value;
    },

    getColumnExportTranslation(column) {
      return column && column.value ? { [this.getColumnValue(column)]: column.text } : {};
    },

    getColumnExportPickOptions(column) {
      return column && column.isAttribute && column.value ? [attributes] : [];
    },

    getDefaultSelectedPage(pagination, pagesNumber) {
      const page =
        pagination && pagination.offset && pagination.limit
          ? pagination.offset / pagination.limit + 1
          : 1;
      return page > pagesNumber && !isNaN(pagesNumber) ? 1 : page;
    },
  },
};

export default mixin;
