<template>
  <div>
    <div class="py-2">
      <span class="headline">
        {{ $t('settings.setArchitectureRules.overview') }}
      </span>
    </div>
    <div class="border-left-blue">
      <v-tabs
        height="4rem"
        background-color="transparent"
        color="white"
        class="pricing-tabs"
        active-class="pricing-tabs__tab--active"
        hide-slider
      >
        <v-tab
          class="pricing-tabs__tab pricing-tabs__tab--first"
          @change="changeTab({ isGraphTab: false })"
        >
          {{ $t('settings.setArchitectureRules.tabs.list') }}
        </v-tab>

        <v-tab class="pricing-tabs__tab" @change="changeTab({ isGraphTab: true })">
          {{ $t('settings.setArchitectureRules.tabs.graph') }}
        </v-tab>

        <div class="pricing-tabs__filler">
          <norm-weight-toggle class="float-right" />
        </div>

        <v-tab-item>
          <product-impact-list
            :architecture-products="filteredArchitectureProductList"
            :disabled="disabled"
          >
            <store-group-filter
              :selected-tool-store-groups="selectedToolStoreGroups"
              @selection-changed="setSelectedStoreGroups"
            />
          </product-impact-list>
        </v-tab-item>

        <v-tab-item>
          <basic-spinner v-if="isGraphDataLoading" :style="spinnerWrapperStyles" />
          <div v-if="!isGraphDataLoading && architectureDriversReady" class="d-flex flex-column">
            <store-group-filter
              :selected-tool-store-groups="selectedToolStoreGroups"
              @selection-changed="setSelectedStoreGroups"
            />
            <architecture-drivers-graph
              :height="graphHeight"
              :architecture-products="filteredArchitectureProductList"
            />
          </div>
        </v-tab-item>
      </v-tabs>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      graphHeight: 500,
      selectedToolStoreGroups: [],
    };
  },

  events: {
    async onArchitectureRulesFilterUpdated() {
      this.selectedToolStoreGroups = [...this.mainToolStoreGroupKeys];
    },
  },

  computed: {
    ...mapState('architectureDrivers', {
      architectureDrivers: 'architectureDrivers',
      architectureDriversLoading: 'loading',
    }),
    ...mapState('workpackages', ['selectedWorkpackageToolStoreGroups']),
    ...mapState('architectureGroup', ['architectureProducts', 'architectureProductsLoading']),
    ...mapGetters('storeGroupRelationships', ['mainToolStoreGroupKeys']),

    isGraphDataLoading() {
      return this.architectureDriversLoading || this.architectureProductsLoading;
    },

    spinnerWrapperStyles() {
      return {
        height: `${this.graphHeight}px`,
      };
    },

    architectureDriversReady() {
      return !!this.architectureDrivers;
    },

    filteredArchitectureProductList() {
      const shouldDisplayAllProducts =
        isEmpty(this.selectedToolStoreGroups) ||
        isEmpty(this.selectedWorkpackageToolStoreGroups) ||
        this.selectedToolStoreGroups.length === this.selectedWorkpackageToolStoreGroups.length;
      if (shouldDisplayAllProducts) {
        return this.architectureProducts;
      }
      return this.architectureProducts.filter(product =>
        this.selectedToolStoreGroups.includes(product.toolStoreGroupKey)
      );
    },
  },

  created() {
    this.isGraphTabActive = false;
    this.selectedToolStoreGroups = [...this.mainToolStoreGroupKeys];
  },

  methods: {
    setSelectedStoreGroups(items) {
      this.selectedToolStoreGroups = items;
      if (this.isGraphTabActive) {
        this.globalEmit('redraw-architecture-drivers-graph');
      }
    },

    changeTab({ isGraphTab }) {
      this.isGraphTabActive = isGraphTab;
      if (isGraphTab) {
        this.globalEmit('graph-tab-active');
        return;
      }
      this.globalEmit('list-tab-active');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.border-left-blue {
  border-left: 6px solid $pricing-filter-bar-color !important;
}

.float-right {
  float: right;
}
</style>
