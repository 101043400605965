'use strict';

module.exports = {
  timeFlexibleAggregations: [
    'salesMAT',
    'promoShare',
    'salesImpactMAT',
    'regularSalesImpact',
    'totalCostImpact',
    'regularCostImpact',
    'marginProposed',
    'marginDelta',
    'economicTension',
    'competitorTension',
    'architectureTension',
    'storeGroupTension',
    'competitor1LiveDistance',
    'competitor1ProposedDistance',
    'competitor1DeltaVsPrev',
    'competitor2LiveDistance',
    'competitor2ProposedDistance',
    'competitor2DeltaVsPrev',
    'competitor1LiveDistanceVolume',
    'competitor1ProposedDistanceVolume',
    'competitor1DeltaVsPrevVolume',
    'competitor2LiveDistanceVolume',
    'competitor2ProposedDistanceVolume',
    'competitor2DeltaVsPrevVolume',
  ],
  timeFlexibleIndependentAggregations: [
    'numPriceChanges',
    'totalImpactInYear',
    'regularImpactInYear',
    'costImpactInYear',
    'regularCostImpactInYear',
    'competitor1LiveDistancePrice',
    'competitor1ProposedDistancePrice',
    'competitor1DeltaVsPrevPrice',
    'competitor2LiveDistancePrice',
    'competitor2ProposedDistancePrice',
    'competitor2DeltaVsPrevPrice',
  ],
  timeFlexibleGridViewFields: [
    'yearlySales',
    'yearlyVolume',
    'scenarioCostImpact',
    'regularCostImpact',
    'scenarioSalesImpact',
    'regularSalesImpact',
  ],
  timeFlexibleIndependentGridViewFields: [
    '_id',
    'workpackageId',
    'toolStoreGroupKey',
    'hierarchy',
    'intentionMargin',
    'currentPromotion',
    'hasUpcomingPromotion',
    'upcomingPromotion',
    'childPromotions',
    'costDelta',
    'costDeltaPerContentUnit',
    'costDeltaPerNormWeight',
    'livePrice',
    'intentionPrice',
    'productKey',
    'productKeyDisplay',
    'scenarioKey',
    'alerts',
    'architectureReferencePrice',
    'competitorPrices',
    'competitorReferencePrice',
    'economicReferencePrice',
    'intentionCost',
    'isOnPromotion',
    'liveCost',
    'priceHistoryWeek',
    'productDescription',
    'productSizeType',
    'scenarioPrice',
    'scenarioPriceChange',
    'scenarioPriceChangeRatio',
    'vatRate',
    'weightValue',
    'brandName',
    'contentValue',
    'toolStoreGroupDescription',
    'competitors',
    'optimizedPrice',
    'overridePrice',
    'scenarioMargin',
    'scenarioPriceChangePerContentUnit',
    'scenarioPriceChangeRatioPerContentUnit',
    'scenarioPricePerContentUnit',
    'storeGroupReferencePrice',
    'vatCalc',
    'alertCounts',
    'mandatoryEngineInputs',
    'nonPromoNetCost',
    'nonPromoNetCostPerContentUnit',
    'nonPromoNetCostPerNormWeight',
    'intentionCostPerNormWeight',
    'intentionPricePerNormWeight',
    'liveCostPerNormWeight',
    'livePricePerNormWeight',
    'intentionCostPerContentUnit',
    'intentionPricePerContentUnit',
    'liveCostPerContentUnit',
    'livePricePerContentUnit',
    'scenarioPricePerNormWeight',
    'scenarioPriceChangePerNormWeight',
    'scenarioPriceChangeRatioPerNormWeight',
    'competitor1',
    'competitor2',
    'architectureGroupPriority',
    'filteredAlerts',
    'normWeight',
    'normWeightUnitOfMeasure',
    'inferredGoLiveDate',
    'inferredGoLivePrice',
    // Required for Architecture Grouping (sortBySubGroupAndLPG)
    'subGroupDescription',
    'linePricingGroupDescription',
  ],
};
