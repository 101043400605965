<template>
  <div class="pricing-json-editor">
    <v-jsoneditor
      ref="response-editor"
      :value="jsonValue"
      :plus="false"
      class="pricing-json-editor__editor"
      :options="editorOptions"
      @error="onError"
    />
  </div>
</template>

<script>
import { isEmpty } from 'lodash';

export default {
  props: {
    response: {
      type: Object,
      default: () => {},
    },
    error: {
      type: Object,
      default: () => {},
    },
    emitChange: {
      type: Boolean,
      default: false,
    },
    emitError: {
      type: Boolean,
      default: false,
    },
    editorOptions: {
      type: Object,
      default: () => ({
        // other mode is 'view'
        mode: 'code',
        modes: ['code', 'view'],
        name: 'response',
        enableTransform: false,
      }),
    },
  },

  computed: {
    jsonValue() {
      return !isEmpty(this.error) ? this.error : this.response;
    },
  },

  created() {
    if (this.emitChange) {
      this.editorOptions.onChangeText = jsonString => {
        try {
          this.$emit('change', { json: JSON.parse(jsonString) });
          if (this.emitError) this.$emit('error', false);
        } catch (err) {
          console.error('Not valid json');
        }
      };
    }
  },

  methods: {
    isEmpty,
    onError() {
      if (this.emitError) this.$emit('error', true);
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.pricing-json-editor {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;

  ::v-deep {
    .jsoneditor-menu {
      background-color: $pricing-primary;
      border: unset;
    }
    .jsoneditor {
      border: unset;
      height: 100%;
      background-color: white;
    }
    .jsoneditor-contextmenu li {
      background-color: white;
    }
    .jsoneditor-box {
      height: 60rem;
    }
    .jsoneditor-mode-view {
      border-left: 2px $pricing-primary solid;
      height: 100%;
      background-color: white;
    }
    .jsoneditor-navigation-bar,
    .jsoneditor-poweredBy,
    .jsoneditor-repair,
    .jsoneditor-sort,
    .jsoneditor-statusbar {
      display: none;
    }
    .max-btn {
      display: unset;
    }
    .jsoneditor-value,
    .jsoneditor-field,
    .jsoneditor-separator {
      line-height: 2rem;
    }
  }
}
</style>
