<template>
  <input
    class="ag-input-field-input ag-checkbox-input"
    type="checkbox"
    :checked="params.selected"
    @click="params.changeHandler"
  />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({});
</script>
