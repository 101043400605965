import pricingPage from './pricing.vue';
import pricingDeskView from './desk-view/pricing-desk-view.vue';
import pricingImpactView from './impact-view/pricing-impact-view.vue';
import { pricing as pricingPageIndex } from '@enums/page-index';

const routes = [
  {
    path: '/pricing',
    name: 'pricing',
    id: 'pricing',
    component: pricingPage,
    linkText: 'linkText.pricing',
    index: pricingPageIndex,
    children: [
      {
        path: 'desk-view',
        name: 'pricingDeskView',
        id: 'pricingDeskView',
        component: pricingDeskView,
        linkText: 'linkText.deskView',
        index: 0,
        meta: {
          title: 'tabText.deskView',
        },
      },
      {
        path: 'impact-view',
        name: 'pricingImpactView',
        id: 'pricingImpactView',
        component: pricingImpactView,
        linkText: 'linkText.impactView',
        index: 1,
        meta: {
          title: 'tabText.impactView',
        },
      },
    ],
  },
];

export default routes;
