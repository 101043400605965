'use strict';

module.exports = {
  initial: 0,
  saving: 1,
  failed: 2,
  validated: 3,
  uploading: 4,
  brokenValidations: 5,
};
