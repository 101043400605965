import axios from 'axios';
import to from 'await-to-js';
import { filter, isEmpty } from 'lodash';
import { ascending } from '@enums/sort-direction';
import { competitorSources } from '@enums/source-types';
import sortCompetitors from '../utils/sort-competitors';

const getInitialState = () => {
  return {
    competitors: [],
    loading: false,
    competitorKeyToTypeMap: {},
  };
};

const store = {
  namespaced: true,

  state: {
    ...getInitialState(),
    competitorOrderConfig: {},
  },

  getters: {
    competitorDescriptionToDisplayDescriptionMap: state => {
      if (isEmpty(state.competitors)) return {};
      return state.competitors.reduce((result, competitor) => {
        result[competitor.competitorDescription] = competitor.competitorDisplayDescription;
        return result;
      }, {});
    },

    nonStoreGroupCompetitors: state => {
      return filter(state.competitors, c => c.competitorType !== competitorSources.storeGroup);
    },
  },

  mutations: {
    setCompetitorMetadata(state, competitorMetadata) {
      state.competitors = competitorMetadata;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setCompetitorKeyToTypeMap(state, competitorMetadata) {
      state.competitorKeyToTypeMap = competitorMetadata.reduce((acc, comp) => {
        acc[comp.competitorKey] = comp.competitorType;
        return acc;
      }, {});
    },

    setCompetitorOrderConfig(state, competitorOrderConfig) {
      state.competitorOrderConfig = competitorOrderConfig;
    },
  },

  actions: {
    async fetchCompetitorMetadata({ commit, dispatch, state, getters, rootState }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!workpackageId) return;
      commit('setLoading', true);
      const params = {
        sortDirection: ascending,
        sortBy: 'competitorDisplayDescription',
      };
      const [err, { data: competitorMetadata }] = await to(
        axios.get(`/api/competitor-metadata/workpackage/${workpackageId}`, { params })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      const sortedCompetitors = sortCompetitors({
        competitorMetadata,
        competitorOrder: state.competitorOrderConfig,
      });
      commit('setCompetitorMetadata', sortedCompetitors);
      commit('setCompetitorKeyToTypeMap', competitorMetadata);
      const selectedCompetitorKeys = !rootState.gridView.selectedCompetitors
        ? []
        : rootState.gridView.selectedCompetitors.map(competitor => competitor.competitorKey);
      const selectedCompetitorsAfterUpdate = isEmpty(selectedCompetitorKeys)
        ? []
        : getters.nonStoreGroupCompetitors.filter(competitor =>
            selectedCompetitorKeys.includes(competitor.competitorKey)
          );
      const newSelectedCompetitors =
        selectedCompetitorsAfterUpdate.length === 2
          ? selectedCompetitorsAfterUpdate
          : getters.nonStoreGroupCompetitors.slice(0, 2);
      dispatch('gridView/setSelectedCompetitors', newSelectedCompetitors, { root: true });
      commit('setLoading', false);
    },

    async createCompetitorMetadata({ dispatch, rootState }, competitorMetadata) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.post(`/api/competitor-metadata/workpackage/${workpackageId}`, competitorMetadata)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchCompetitorMetadata');
      return response.data;
    },

    async updateCompetitorMetadata({ commit, dispatch }, { updates = {} } = {}) {
      commit('setLoading', true);
      const [err] = await to(axios.patch('/api/competitor-metadata', updates));
      if (err) throw new Error(err.message);
      dispatch('fetchCompetitorMetadata');
    },

    async editCompetitor(
      { commit, dispatch, rootState },
      { competitorKey, competitorDisplayDescription } = {}
    ) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(
          `/api/competitor-metadata/workpackage/${workpackageId}/competitor/${competitorKey}`,
          { competitorDisplayDescription }
        )
      );
      if (err) throw new Error(err.message);
      dispatch('fetchCompetitorMetadata');
    },

    async deleteCompetitor(
      { commit, dispatch, rootState },
      { competitorKey, competitorName, params }
    ) {
      commit('setLoading', true);
      const deleteParams = {
        competitorName,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.delete(
          `/api/competitor-metadata/workpackage/${workpackageId}/competitor/${competitorKey}`,
          { params: deleteParams }
        )
      );
      if (err) throw new Error(err.message);
      dispatch('fetchCompetitorMetadata');
      dispatch('attributes/fetchAttributesAggregated', { params }, { root: true });
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default store;
