'use strict';

const { displayStoreGroupPenalty } = require('../enums/feature-flags');

const truncationLength = {
  scopeText: 110,
  selectedStoreGroupsText: 80,
};

const ruleHeaders = [
  {
    field: 'economicPenalty',
    class: 'economic-penalty-traffic-light',
    headline: 'settings.penaltyRules.economicPenaltyInitial',
  },
  {
    field: 'competitorPenalty',
    class: 'competitor-penalty-traffic-light',
    headline: 'settings.penaltyRules.competitorPenaltyInitial',
  },
  {
    field: 'storeGroupPenalty',
    class: 'store-group-penalty-traffic-light',
    headline: 'settings.penaltyRules.storeGroupPenaltyInitial',
    featureFlag: displayStoreGroupPenalty,
  },
  {
    field: 'architecturePenalty',
    class: 'architecture-penalty-traffic-light',
    headline: 'settings.penaltyRules.architecturePenaltyInitial',
  },
  {
    field: 'pricePointing',
    class: 'price-pointing-traffic-light',
    headline: 'settings.penaltyRules.pricePointingPenaltyInitial',
  },
];

module.exports = {
  ruleHeaders,
  truncationLength,
};
