<template>
  <!-- FEATURE_FLAG: display norm weight toggle -->
  <feature-toggle :toggle="includeNormWeight">
    <v-btn-toggle mandatory dense :value="selectedPriceType" depressed>
      <v-btn
        small
        :value="priceTypes.regular"
        depressed
        class="regular-price-button"
        @click="changePriceType(priceTypes.regular)"
      >
        {{ $t('gridView.priceToggles.regularPrice') }}
      </v-btn>
      <v-btn
        small
        :value="priceTypes.uom"
        depressed
        class="uom-price-button"
        @click="changePriceType(priceTypes.uom)"
      >
        {{ $t('gridView.priceToggles.pricePerContentUnit') }}
      </v-btn>
      <v-btn
        small
        :value="priceTypes.normWeight"
        depressed
        @click="changePriceType(priceTypes.normWeight)"
      >
        {{ $t('gridView.priceToggles.pricePerNormWeight') }}
      </v-btn>
    </v-btn-toggle>
  </feature-toggle>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import priceTypes from '@enums/price-types';
import { includeNormWeight } from '@enums/feature-flags';

export default {
  data() {
    return {
      priceTypes,
      includeNormWeight,
    };
  },

  computed: {
    ...mapState('filters', ['selectedPriceType']),
  },

  methods: {
    ...mapActions('filters', ['setSelectedFilter']),

    changePriceType(selectedPriceType) {
      this.setSelectedFilter({
        filterName: 'selectedPriceType',
        filterValue: selectedPriceType,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.v-btn-toggle {
  vertical-align: bottom;
  float: right;
  .v-btn {
    background-color: $pricing-white !important;
    color: $icon-colour;
    border-color: $icon-colour !important;
    border-width: thin;
    width: 8.7rem;
    font-weight: 300;
  }

  .regular-price-button {
    width: 6rem;
  }

  .uom-price-button {
    width: 4rem;
  }

  .v-btn--active {
    background-color: $icon-colour !important;
    color: $pricing-white !important;
  }
}
</style>
