<template>
  <div class="input-screen-page-wrapper" style="width: 100%; height: 100%">
    <!-- Menu options -->

    <inputs-grid
      ref="inputsGrid"
      :additional-columns="additionalColumns"
      :additional-grid-options="additionalGridOptions"
      :save-changes="saveChanges"
      :export-action="downloadAttributes"
      :is-exporting="downloadingItems"
      :editable-hierarchies="true"
      route-suffix="attributes"
    >
      <!-- Slots for inputs-grid, -->
      <!-- Import/ Add buttons etc. -->
      <template #buttons>
        <v-menu v-if="canEditAttributes" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              class="inputs-actions__add grid-btn"
              :disabled="dataLoading"
              depressed
              small
              v-on="on"
            >
              <v-icon>mdi-plus</v-icon>
              {{ $t('actions.add') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item
              @click="openAttributeCreateUpdateDialog(dialogModes.create, attributeScope.global)"
            >
              <v-list-item-title>
                {{ startCase($t('attributes.actions.createGlobalAttribute')) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>

        <v-menu v-if="canEditAttributes" offset-y>
          <template v-slot:activator="{ on }">
            <v-btn
              color="primary"
              small
              :disabled="dataLoading"
              depressed
              class="inputs-actions__import grid-btn"
              :loading="busyImportingAttributes || busyImportingGroup"
              v-on="on"
            >
              <v-icon small>$import</v-icon>
              {{ $t('actions.upload') }}
              <v-icon>mdi-chevron-down</v-icon>
            </v-btn>
          </template>

          <v-list>
            <v-list-item @click="showHierarchyUpload = true">
              <v-list-item-title>
                {{ startCase($t('attributes.uploadHierarchy.uploadButton')) }}
              </v-list-item-title>
            </v-list-item>
            <v-list-item @click="showAttributeUpload = true">
              <v-list-item-title>
                {{ startCase($t('attributes.upload.uploadButton')) }}
              </v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </template>
    </inputs-grid>

    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      :disable-ok-button="
        checkingIfAttributeCanBeDeleted || !attributeCanBeDeleted || deletingAttribute
      "
      @onOk="deleteAttributeHandler(true)"
      @onCancel="deleteAttributeHandler(false)"
    >
      <template v-slot:header>{{ $t('dialogs.deleteDialog.header') }}</template>
      <template v-slot:body>
        <div v-if="!checkingIfAttributeCanBeDeleted && attributeCanBeDeleted">
          <div v-if="attributeHasUses">
            <strong class="strong-warning-text">{{
              $t('attributes.editor.deleteMessages.deleteUsedAttributeWarning')
            }}</strong>
            <div v-for="(attributeUse, index) in attributeUses" :key="index" class="pt-3">
              <div v-if="attributeUse.exists" class="pl-2">
                <v-icon class="pb-1" size="1.7rem" :color="alertsIconColor">warning</v-icon>
                {{
                  $t(
                    `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                    attributeUse.translationParams
                  )
                }}
              </div>
            </div>
          </div>
          <div v-else>
            {{ $t('dialogs.deleteDialog.body') }}
            <strong>{{ attributeToDelete ? attributeToDelete.attributeDescription : '' }}</strong>
          </div>
        </div>

        <div v-else-if="checkingIfAttributeCanBeDeleted && attributeUses.length === 0">
          {{ $t('attributes.editor.deleteMessages.checkingIfTheAttribute') }}
          <strong>{{ attributeToDelete ? attributeToDelete.text : '' }}</strong>
          {{ $t('attributes.editor.deleteMessages.canBeDeleted') }}
          <v-progress-circular indeterminate color="primary" size="20" />
        </div>

        <div v-else>
          <div class="pb-1">
            <strong>{{ attributeToDelete ? attributeToDelete.text : '' }}</strong>
            {{ $t('attributes.editor.deleteMessages.attributeCannotBeDeleted') }}:
          </div>
          <div v-for="(attributeUse, index) in attributeUses" :key="index">
            <div v-if="attributeUse.exists" class="pl-2">
              <v-icon class="pb-1" size="1.7rem" :color="alertsIconColor">warning</v-icon>
              {{
                $t(
                  `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                  attributeUse.translationParams
                )
              }}
            </div>
          </div>
        </div>
      </template>
    </alert-dialog>

    <attribute-create-update-dialog
      :key="get(attributeToEdit, 'attributeKey', dialogModes.create)"
      :is-open="isAttributeCreateUpdateDialogOpen"
      :scope="attributeCreateUpdateScope"
      :mode="mode"
      :attribute="attributeToEdit"
      :ignore-attribute-fetch="true"
    />

    <hierarchy-upload-data
      :show-dialog="showHierarchyUpload"
      @closeDialog="showHierarchyUpload = false"
    />

    <attribute-upload-data
      :show-dialog="showAttributeUpload"
      @closeDialog="showAttributeUpload = false"
    />
  </div>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import {
  cloneDeep,
  get,
  keyBy,
  isEmpty,
  startCase,
  find,
  sortBy,
  filter,
  map,
  intersection,
  size,
  some,
} from 'lodash';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import { useZones } from '@enums/feature-flags';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import dialogModes from '@enums/dialog-modes';
import attributeScope from '@enums/attribute-scope';
import { yearMonthDayFormat } from '@enums/date-formats';
import DataTypes from '@enums/data-types';
import numberFormats from '@enums/number-formats';
import { inputTableCssClasses } from '@enums/tables';
import datePickerEditor from '../price-overrides/components/date-picker-editor.vue';
import { localisedNumericCol } from '../../../utils/inputs-grid-utils';
import inputsGrid from './inputs-grid.vue';

import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';

export default {
  name: 'AttributeEditor',
  components: {
    // Need to import here so that it's available in tests
    inputsGrid,
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    datePickerEditor,
  },
  mixins: [featureFlagsMixin, tablesMixin, inputScreensTableMixin],

  async beforeRouteLeave(destination, from, next) {
    const preventNavigation = await this.$refs.inputsGrid.resolveEditsBeforeNavigation();

    if (preventNavigation) return;

    next();
  },

  data() {
    return {
      additionalColumns: [],
      isHierarchyDialogOpen: false,
      isAttributeCreateUpdateDialogOpen: false,
      isHierarchyDeleteDialogOpen: false,
      attributesByKey: {},
      dialogModes,
      get,
      attributeCreateUpdateScope: attributeScope.global,
      attributeScope,
      mode: dialogModes.create,
      dataLoading: true,
      attributeUses: [],
      checkingIfAttributeCanBeDeleted: false,
      deletingAttribute: false,
      isDeleteDialogOpen: false,
      attributeToDelete: null,

      showHierarchyUpload: false,
      showAttributeUpload: false,
      attributeToEdit: null,
      startCase,
      attributeKeys: new Set(),

      additionalGridOptions: {
        getMainMenuItems: this.getColumnMenuItems,
      },
    };
  },

  events: {
    onSuccessfulAttributeUpdate() {
      this.clearUpdates();
    },

    onSaveNewAttribute() {
      this.closeAttributeCreateUpdateDialog();
    },

    async onAttributeCreated() {
      // TODO: Make this faster - currently reloads columns.
      // Review in PRICE-2200
      // could use state here ?
      await this.setupAttributeData();
      await this.$refs.inputsGrid.fetchGridData();
    },

    onCancelAddAttribute() {
      this.closeAttributeCreateUpdateDialog();
    },

    onSaveUpdatedAttribute({ attribute, updatedName }) {
      const attributeKey = attribute.attributeKey;
      this.updateAttributeHeaderInGrid({ attributeKey, updatedName });
      this.closeAttributeCreateUpdateDialog();
    },
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('attributes', ['attributeMetadata', 'busyImportingAttributes', 'downloadingItems']),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapState('clientConfig', ['i18nconfig']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapState('filters', ['retailAttributesFilter']),
    ...mapGetters('attributes', ['globalAttributes', 'pricingGroupAttributes']),

    canEditAttributes() {
      return this.isPricingSpecialist;
    },

    zonesEnabled() {
      return this.isFeatureFlagEnabled(useZones);
    },

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('pricing.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
          }
        : null;
    },

    exportTranslationMap() {
      const toolStoreGroupColumn = this.zonesEnabled
        ? { toolStoreGroupKey: this.$t('pricing.toolStoreGroup') }
        : {};

      return {
        productKeyDisplay: this.$t('pricing.productKey'),
        ...toolStoreGroupColumn,
        productName: this.$t('pricing.productDescription'),
        category: this.$t('pricing.category'),
        pricingGroup: this.$t('pricing.pricingGroup'),
        architectureGroup: this.$t('pricing.architectureGroup'),
      };
    },

    headers() {
      // If updating a header be sure to check other places in the code where this may
      // cause issues like attributeMenuOptions or staticHeaders
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];

      return [
        {
          text: this.$t('pricing.productKey'),
          align: 'start',
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('pricing.productDescription'),
          align: 'start',
          value: 'productName',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.category'),
          align: 'start',
          value: `hierarchy.${categoryLevel}.levelEntryDescription`,
          class: 'm-width',
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.pricingGroup'),
          align: 'start',
          value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
          hierarchyLevel: pricingGroupLevel,
          class: 'm-width',
          dataType: DataTypes.str,
        },
        {
          text: this.$t('pricing.architectureGroup'),
          align: 'start',
          value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
          hierarchyLevel: architectureGroupLevel,
          class: 'border-right m-width',
          dataType: DataTypes.str,
        },
        ...this.formattedGlobalAttributeHeaders,
        ...this.formattedPricingGroupAttributeHeaders,
      ];
    },

    attributeCanBeDeleted() {
      // Attributes are only blocked from being deleted if they are used in rules
      // Architecture drivers & sub-group attributes display a warning.

      const res = !find(this.attributeUses, {
        exists: true,
        translationKey: 'attributeInSettingsRules',
      });

      return res;
    },

    attributeHasUses() {
      // Attributes are only blocked from being deleted if they are used in rules
      // Architecture drivers & sub-group attributes display a warning.

      const res = some(this.attributeUses, { exists: true });

      return res;
    },

    formattedGlobalAttributeHeaders() {
      return map(
        filter(
          this.globalAttributes,
          attr => attr.attributeKey !== this.toolStoreGroupGlobalAttributeKey
        ),
        header => ({
          text: header.displayDescription,
          align: 'start',
          value: header.attributeKey,
          isAttribute: true,
          id: header._id,
          source: header.sourceFieldDescription,
          width: '200',
          scope: header.scope,
          ...this.getHeaderFormatter(header),
        })
      );
    },

    availablePricingGroupAttributes() {
      return filter(this.pricingGroupAttributes, pgAttribute =>
        size(intersection(this.selectedPricingGroupsForPgAttributes, pgAttribute.pricingGroups))
      );
    },

    formattedPricingGroupAttributeHeaders() {
      return map(this.availablePricingGroupAttributes, (header, index) => ({
        text: header.displayDescription,
        align: 'start',
        value: header.attributeKey,
        isAttribute: true,
        id: header._id,
        source: header.sourceFieldDescription,
        width: '200',
        scope: header.scope,
        class: index === 0 ? 'border-left' : '',
        pricingGroups: header.pricingGroups,
        ...this.getHeaderFormatter(header),
      }));
    },
  },

  async created() {
    await this.init();
  },

  methods: {
    ...mapActions('attributes', [
      'checkForAttributeUses',
      'deleteAttributeMetadata',
      'fetchAttributeMetadata',
      'downloadItems',
    ]),
    ...mapActions('scenarioMetadata', ['fetchScenarioMetadata']),

    tooltipValueGetter(params) {
      return this.$refs.inputsGrid.tooltipValueGetter(params);
    },

    isMainbanner(toolStoreGroupKey) {
      // If not using zones, you only have mainbanner
      if (!this.zonesEnabled) return true;

      return toolStoreGroupKey === this.mainTsgKey;
    },

    async openAttributeCreateUpdateDialog(mode, scope, attribute = null) {
      if (mode === 'create') {
        const gridHasEdits = await this.$refs.inputsGrid.resolveEditsBeforeNavigation();
        if (gridHasEdits) return;
      }
      this.mode = mode;
      this.attributeCreateUpdateScope = scope;
      if (attribute) {
        this.attributeToEdit = attribute;
      }
      this.isAttributeCreateUpdateDialogOpen = true;
    },

    closeAttributeCreateUpdateDialog() {
      this.attributeToEdit = null;
      this.isAttributeCreateUpdateDialogOpen = false;
    },

    downloadAttributes() {
      const dateAttributes = filter(
        this.attributeMetadata,
        attr => attr.dataType === DataTypes.date
      );
      this.downloadItems({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        dateAttributes,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    getHeaderFormatter(header) {
      const headerFormatter = {};
      if (header.dataType === DataTypes.date) {
        headerFormatter.formatter = {
          type: header.dataType,
          format: this.dateFormats.clientDateFormatForMoment,
        };
      }
      return headerFormatter;
    },

    async setupAttributeData() {
      const attributeMetadata = await this.fetchAttributeMetadata();

      this.attributesByKey = keyBy(attributeMetadata, 'attributeKey');

      const defaultColDefClone = cloneDeep(this.defaultColDef);

      const attributeHeaders = map(
        sortBy(
          filter(
            attributeMetadata,
            attr => attr.attributeKey !== this.toolStoreGroupGlobalAttributeKey
          ),
          'displayDescription'
        ),
        x => {
          // Track attribute keys so that we can use them to split the different save types
          this.attributeKeys.add(x.attributeKey);

          return {
            ...defaultColDefClone,
            headerName: x.displayDescription,
            field: x.attributeKey,
            colId: x.attributeKey,
            scope: x.scope,
            datatype: x.dataType,
            minWidth: 110,
            tooltipValueGetter: this.tooltipValueGetter,
            editable: true,
            ...(x.dataType === 'number' && {
              ...localisedNumericCol,
            }),
            ...(x.dataType === 'date' && {
              valueFormatter: params => this.formatLocalisedDate(params.value),
              cellEditor: 'datePickerEditor',
              cellEditorPopup: true,
              cellEditorPopupPosition: 'under',
              cellEditorParams: {
                firstDayOfWeek: this.i18nconfig.firstDayOfTheWeek,
                minDate: () => moment('2000-01-01').format(yearMonthDayFormat),
                locale: this.i18nconfig.fallbackLocale,
                dateValue: date => date,
              },
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  if (cellValue == null) return -1;
                  const cellDate = moment(cellValue, yearMonthDayFormat);
                  const filterDate = moment(
                    filterLocalDateAtMidnight.toISOString().split('T')[0],
                    yearMonthDayFormat
                  ).add({ days: 1 });
                  if (filterDate.isSame(cellDate)) {
                    return 0;
                  }
                  if (cellDate.isBefore(filterDate)) {
                    return -1;
                  }
                  if (cellDate.isAfter(filterDate)) {
                    return 1;
                  }
                },
                browserDatePicker: true,
                minValidYear: 2000,
              },
            }),
          };
        }
      );

      const attributeColumnGroup = {
        headerName: this.$t('linkText.attributes'),
        groupId: 'global-attributes',
        children: attributeHeaders,
      };

      this.additionalColumns = [attributeColumnGroup];
    },

    updateAttributeHeaderInGrid({ attributeKey, updatedName }) {
      this.additionalColumns = this.additionalColumns.map(col => {
        if (col.children) {
          col.children = col.children.map(child => {
            if (child.colId === attributeKey) {
              child.headerName = updatedName;
            }
            return child;
          });
        }
        return col;
      });
      this.$refs.inputsGrid.gridApi.refreshHeader();
    },

    removeAttributeHeaderFromGrid({ attributeKey }) {
      this.additionalColumns = this.additionalColumns.map(col => {
        if (col.children) {
          col.children = col.children.filter(child => {
            return child.colId !== attributeKey;
          });
        }
        return col;
      });
      this.$refs.inputsGrid.gridApi.refreshHeader();
    },

    async init() {
      this.dataLoading = true;
      await this.setupAttributeData();
      this.dataLoading = false;
    },

    getGlobalAttributeMenuActions(params) {
      // This structure keeps it consistent with other calls from
      const attribute = this.attributesByKey[params.column.colId];
      const attributeMenuActions = [
        {
          name: this.$t('attributes.editor.actions.edit'),
          action: () =>
            this.openAttributeCreateUpdateDialog(
              dialogModes.edit,
              attributeScope.global,
              attribute
            ),
        },
        {
          name: this.$t('attributes.editor.actions.delete'),
          action: () => this.openDeleteDialog(attribute),
        },
      ];

      return attributeMenuActions;
    },

    getColumnMenuItems(params) {
      if (params.column.parent.groupId === 'global-attributes') {
        return [...params.defaultItems, 'separator', ...this.getGlobalAttributeMenuActions(params)];
      }

      return [];
    },

    async openDeleteDialog(attribute) {
      this.checkingIfAttributeCanBeDeleted = true;
      this.attributeToDelete = attribute;
      this.isDeleteDialogOpen = true;
      this.attributeUses = await this.checkForAttributeUses({ value: attribute.attributeKey });
      this.checkingIfAttributeCanBeDeleted = false;
    },

    async deleteAttributeHandler(shouldDelete = false) {
      this.deletingAttribute = true;
      if (shouldDelete) {
        await this.deleteAttributeMetadata({
          attributeKey: this.attributeToDelete.attributeKey,
          ignoreAttributeFetch: true,
        });
        await this.fetchScenarioMetadata();
        this.removeAttributeHeaderFromGrid({ attributeKey: this.attributeToDelete.attributeKey });
      }
      this.deletingAttribute = false;
      this.attributeToDelete = null;
      this.isDeleteDialogOpen = false;
      this.attributeUses = [];

      // Scenarios contain the subgroup attribute data. Refresh to ensure that column is up to date.
      this.$refs.inputsGrid.gridApi.refreshCells({
        columns: ['architectureSubGroupSplittingAttributes'],
        force: true,
      });
    },

    async saveChanges({ updates, mainTsgKey }) {
      if (isEmpty(updates)) return;

      const { data: results } = await axios.post(
        `/api/inputs/workpackage/${this.selectedWorkpackage._id}/attributes`,
        { updates, mainTsgKey }
      );
      return results;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.invalid-blocking {
  border-bottom: 0.2rem solid #c13939 !important;
}

.pale {
  opacity: 0.7;
}

.strong-warning-text {
  color: $vuetify-error-red;
}
</style>
