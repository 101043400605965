<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Outlier</title>
    <g id="outlier-container" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="outlier-transform" transform="translate(-375.000000, -559.000000)">
        <g id="outlier" transform="translate(375.000000, 559.000000)">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            id="Combined-Shape"
            d="M4,18 C5.10457,18 6,18.8954 6,20 L6,20 L6,22 C6,23.1046 5.10457,24 4,24 L4,24 L2,24 C0.89543,24 0,23.1046 0,22 L0,22 L0,20 C0,18.8954 0.895431,18 2,18 L2,18 Z M15,21 C15.5522847,21 16,21.4477153 16,22 C16,22.5522847 15.5522847,23 15,23 C14.4477153,23 14,22.5522847 14,22 C14,21.4477153 14.4477153,21 15,21 Z M4,20 L2,20 L2,22 L4,22 L4,20 Z M11,15 C12.1046,15 13,15.8954 13,17 L13,17 L13,19 C13,20.1046 12.1046,21 11,21 L11,21 L9,21 C7.89543,21 7,20.1046 7,19 L7,19 L7,17 C7,15.8954 7.89543,15 9,15 L9,15 Z M11,17 L9,17 L9,19 L11,19 L11,17 Z M15,17 C15.5522847,17 16,17.4477153 16,18 C16,18.5522847 15.5522847,19 15,19 C14.4477153,19 14,18.5522847 14,18 C14,17.4477153 14.4477153,17 15,17 Z M4,10 C5.10457,10 6,10.8954 6,12 L6,12 L6,14 C6,15.1046 5.10457,16 4,16 L4,16 L2,16 C0.89543,16 0,15.1046 0,14 L0,14 L0,12 C0,10.8954 0.895431,10 2,10 L2,10 Z M15,13 C15.5522847,13 16,13.4477153 16,14 C16,14.5522847 15.5522847,15 15,15 C14.4477153,15 14,14.5522847 14,14 C14,13.4477153 14.4477153,13 15,13 Z M4,12 L2,12 L2,14 L4,14 L4,12 Z M15,9 C15.5522847,9 16,9.44771525 16,10 C16,10.5522847 15.5522847,11 15,11 C14.4477153,11 14,10.5522847 14,10 C14,9.44771525 14.4477153,9 15,9 Z M11,5 C12.1046,5 13,5.89543 13,7 L13,7 L13,9 C13,10.1046 12.1046,11 11,11 L11,11 L9,11 C7.89543,11 7,10.1046 7,9 L7,9 L7,7 C7,5.89543 7.89543,5 9,5 L9,5 Z M11,7 L9,7 L9,9 L11,9 L11,7 Z M23,3 C23.5522847,3 24,3.44771525 24,4 L24,8 C24,8.55228475 23.5522847,9 23,9 L19,9 C18.4477153,9 18,8.55228475 18,8 L18,4 C18,3.44771525 18.4477153,3 19,3 L23,3 Z M15,5 C15.5522847,5 16,5.44771525 16,6 C16,6.55228475 15.5522847,7 15,7 C14.4477153,7 14,6.55228475 14,6 C14,5.44771525 14.4477153,5 15,5 Z M15,1 C15.5522847,1 16,1.44771525 16,2 C16,2.55228475 15.5522847,3 15,3 C14.4477153,3 14,2.55228475 14,2 C14,1.44771525 14.4477153,1 15,1 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Outlier',
};
</script>
