import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "price-pointing pb-2" },
        [
          _c(VCol, { staticClass: "pa-0 ml-5 mr-4 flex-grow-0" }, [
            !_vm.indexInArray
              ? _c("span", { staticClass: "header-text mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t("settings.penaltyRules.pricePointing.range")
                      )
                    ),
                  ]),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("settings.penaltyRules.pricePointing.max")
                      ) +
                      "\n      "
                  ),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "text-field pr-1" },
              [
                _c(VTextField, {
                  staticClass: "range-field",
                  attrs: {
                    "background-color": "white",
                    dense: "",
                    height: "2.2rem",
                    type: "number",
                    value: _vm.pricePointing.rangeMaximum,
                    disabled: _vm.disabled,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.updateRangeMaximum($event)
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "prepend",
                      fn: function () {
                        return [
                          _c("span", { staticClass: "symbol px-2" }, [
                            _vm._v(_vm._s(_vm.$t("clientCurrencySymbol"))),
                          ]),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                }),
              ],
              1
            ),
          ]),
          _vm._v(" "),
          _c(VCol, { staticClass: "pa-0", attrs: { cols: "9" } }, [
            !_vm.indexInArray
              ? _c("span", { staticClass: "header-text mb-2" }, [
                  _c("strong", [
                    _vm._v(
                      _vm._s(
                        _vm.$t(
                          "settings.penaltyRules.pricePointing.allowedTokens"
                        )
                      )
                    ),
                  ]),
                ])
              : _vm._e(),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "offset-fields py-0 px-2" },
              [
                _vm._l(_vm.pricePointing.offsets, function (offset, index) {
                  return _c(
                    "div",
                    { key: index, staticClass: "py-1 offset-container" },
                    [
                      _vm.isNewlyCreatedOrBaseTen
                        ? _c(
                            "div",
                            [
                              _c(VTextField, {
                                attrs: {
                                  "background-color": _vm.textFieldColour,
                                  dense: "",
                                  height: "2.2rem",
                                  value: _vm.formatBaseTenOffset(offset),
                                  rules: _vm.rules.offsetInput,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateOffset(
                                      $event,
                                      index,
                                      false
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pr-0 correct-prefix",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getCorrectPrefixForOffset(
                                                    _vm.pricePointing.base
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.isNewlyCreatedOrBaseTen
                        ? _c("span", { staticClass: "pt-2 pl-1 pr-1" }, [
                            _vm._v(","),
                          ])
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "div",
                        { staticClass: "pr-0" },
                        [
                          _c(VTextField, {
                            staticClass: "pt-0 pb-0",
                            attrs: {
                              "background-color": _vm.textFieldColour,
                              dense: "",
                              height: "2.2rem",
                              value: _vm.formatOffset(offset),
                              rules: _vm.rules.offsetInput,
                              disabled: _vm.disabled,
                            },
                            on: {
                              change: function ($event) {
                                return _vm.updateOffset($event, index, true)
                              },
                            },
                            scopedSlots: _vm._u(
                              [
                                !_vm.isNewlyCreatedOrBaseTen
                                  ? {
                                      key: "prepend",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pr-0 correct-prefix",
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.getCorrectPrefixForOffset(
                                                    _vm.pricePointing.base
                                                  )
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    }
                                  : null,
                                {
                                  key: "append-outer",
                                  fn: function () {
                                    return [
                                      index !==
                                      _vm.pricePointing.offsets.length - 1
                                        ? _c(
                                            "span",
                                            {
                                              staticClass:
                                                "pt-0 pb-0 pl-0 pr-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                  /\n                "
                                              ),
                                            ]
                                          )
                                        : _c("span", {
                                            staticClass: "pt-0 pb-0 pl-3 pr-0",
                                          }),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              true
                            ),
                          }),
                        ],
                        1
                      ),
                    ]
                  )
                }),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "pl-0 add-icon-container" },
                  [
                    _c(
                      VIcon,
                      {
                        staticClass: "add-icon",
                        attrs: {
                          disabled:
                            _vm.disabled || _vm.isMaxNumberOfTokensAdded,
                          small: "",
                        },
                        on: { click: _vm.addOffset },
                      },
                      [_vm._v("fa-plus-square")]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "delete-reset pl-3" },
                  [
                    _c("span", { on: { click: _vm.resetOffsets } }, [
                      _vm._v(
                        _vm._s(
                          _vm.$t("settings.penaltyRules.pricePointing.reset")
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c(
                      VIcon,
                      {
                        staticClass: "pl-2",
                        attrs: { disabled: _vm.disabled },
                        on: { click: _vm.deletePricePointing },
                      },
                      [_vm._v("delete")]
                    ),
                  ],
                  1
                ),
              ],
              2
            ),
          ]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }