'use strict';

const { isNaN } = require('lodash');

// taken from https://github.com/angular/angular/blob/4.3.x/packages/common/src/pipes/number_pipe.ts#L172
const isNumeric = value => {
  return !isNaN(value - parseFloat(value));
};

module.exports = {
  isNumeric,
};
