import Vue from 'vue';
import VueRouter from 'vue-router';
import loadRoutes from '@labskit/client/src/loadRoutes';
import { forEach } from 'lodash';
import store from './store/index';

const publicRoutes = ['/login', '/ow-auth/login', '/ah-auth/login'];

export const navigationGuards = {
  ensureAuthenticated: (to, from, next) => {
    // If not authenticated, navigate to the login page
    const { profile, expireAt } = store.state.context;
    const isTokenExpired = expireAt < Date.now();

    if (profile && isTokenExpired) {
      // if auth token is expired a refresh should be requested
      store.dispatch('context/refreshUserContext');
    }

    if (!profile && !publicRoutes.includes(to.path)) {
      console.log('No valid user session, client forced redirect to /login page.');
      return next({ path: '/login', query: { returnPath: to.path } });
    }

    return next();
  },

  ensureAuthorized: (to, from, next) => {
    // Here we ensure a user has the required access role to access the "to" page
    if (to.meta.requiresRole) {
      let requiresRole = to.meta.requiresRole;

      if (to.meta.requiresRoleFromState) {
        requiresRole = store.state.clientConfig.userRoles[to.meta.requiresRole];
      }

      const hasAccess =
        requiresRole instanceof Array
          ? store.getters['context/hasRoles'](requiresRole)
          : store.getters['context/hasRole'](requiresRole);

      if (!hasAccess) {
        console.error(`Tried accessing page "${to.path}" without required permissions`);
        return next({ path: '/error/403' });
      }
    }
    return next();
  },

  ensureFeatureFlagEnabled: (to, from, next) => {
    if (to.meta.featureFlag) {
      const featureFlagValue = store.getters['clientConfig/isFeatureFlagEnabled'](
        to.meta.featureFlag
      );
      return featureFlagValue ? next() : next({ path: 'home' });
    }
    return next();
  },

  ensureSelectedWorkpackageExists: (to, from, next) => {
    if (to.meta.selectedWorkpackageRequired) {
      const hasSelectedWorkpackage = store.getters['workpackages/hasSelectedWorkpackage'];
      return hasSelectedWorkpackage ? next() : next({ path: 'home' });
    }
    return next();
  },
};

// Import routes and build router
const routes = loadRoutes(Vue, require.context('./pages/', true, /\.router.js$/));
const router = new VueRouter({
  routes,
  linkActiveClass: 'active',
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  to.meta.previousRoute = from.path;
  store.commit('setRoute', to);
  return next();
});
forEach(navigationGuards, guard => router.beforeEach(guard));

export default router;
