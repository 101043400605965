import { VApp } from 'vuetify/lib/components/VApp';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VMain } from 'vuetify/lib/components/VMain';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VApp,
    [
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://fonts.googleapis.com/css?family=Material+Icons",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      _c("link", {
        attrs: {
          href: "https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css",
          rel: "stylesheet",
        },
      }),
      _vm._v(" "),
      !_vm.hideNavbar ? _c("main-navbar") : _vm._e(),
      _vm._v(" "),
      _c(
        VMain,
        [
          _c(
            VContainer,
            { attrs: { fluid: "" } },
            [
              _c(
                "transition",
                { attrs: { name: "fade", mode: "out-in" } },
                [
                  _c("router-view", {
                    staticClass: "main-page",
                    attrs: { id: "main-page" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.notifications, function (notification) {
        return _c(
          "div",
          { key: `${notification.id}::${notification.jobStatus}` },
          [
            _vm.getNotificationPayload(notification).length
              ? _c(
                  VSnackbar,
                  {
                    attrs: {
                      value: true,
                      timeout: _vm.popupTimeout,
                      color: _vm.get(
                        _vm.notificationColours,
                        notification.jobStatus,
                        "accent"
                      ),
                      bottom: "",
                    },
                  },
                  [
                    _c("div", { staticClass: "notification-wrapper" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.getNotificationPayload(notification)) +
                          "\n      "
                      ),
                    ]),
                  ]
                )
              : _vm._e(),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("main-footer"),
      _vm._v(" "),
      _c("div", { attrs: { id: "consent_blackbar" } }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }