'use strict';

module.exports = {
  targetMargin: 'engineInputs.editor.targetMargin',
  creationCost: 'engineInputs.editor.creationCost',
  nonPromoNetCost: 'engineInputs.editor.goLiveCost',
  promoParticipationSales: 'engineInputs.editor.promoParticipationSales',
  promoDiscount: 'engineInputs.editor.promoDiscount',
  promoFunding: 'engineInputs.editor.promoFunding',
  intentionCost: 'engineInputs.editor.intentionCost',
};
