'use strict';

module.exports = {
  manualEntry: 'MANUAL_ENTRY',
  feed: 'FEED',
  competitorSources: {
    manual: 'manual',
    feed: 'feed',
    storeGroup: 'storeGroup',
  },
  competitorToggles: ['feed', 'manual', 'storeGroup'],
};
