import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        "close-on-content-click": false,
        "max-width": 400,
        "min-width": 400,
        "offset-x": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "span",
                _vm._g({}, on),
                [
                  _c(
                    VIcon,
                    { attrs: { size: "1.4rem", color: _vm.filterIconColor } },
                    [_vm._v("\n        filter_list\n      ")]
                  ),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            [
              _c(
                VRow,
                { staticClass: "mr-1" },
                [
                  _c(VCol, { attrs: { cols: "11" } }, [
                    _c("span", [
                      _vm._v(
                        _vm._s(
                          `${_vm.$t("filters.filter")} ${_vm.$t(
                            _vm.header.text
                          )}`
                        )
                      ),
                    ]),
                  ]),
                  _vm._v(" "),
                  _c(VCol, { attrs: { cols: "1" } }, [_vm._m(0)], 1),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(VDivider),
          _vm._v(" "),
          _vm.open
            ? _c("grid-view-filters-creator", {
                attrs: {
                  column: _vm.header.text,
                  datatype: _vm.header.datatype,
                  path: _vm.header.valuePath,
                  "unique-value-options": _vm.header.uniqueValueOptions,
                },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { attrs: { color: "grey" }, on: { click: _vm.closeMenu } },
      [_vm._v("close")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }