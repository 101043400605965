import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
      staticClass: "input-screen-page-wrapper",
    },
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            [
              _vm.canEditAttributes
                ? _c("engine-input-upload", {
                    staticClass: "pb-2",
                    attrs: { params: _vm.getBaseParams() },
                  })
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(VTextField, {
                staticClass: "search-field py-0",
                attrs: {
                  value: _vm.search,
                  "append-icon": "search",
                  label: _vm.$t("actions.search"),
                  "single-line": "",
                },
                on: { input: _vm.debounceSearchUpdate },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.displayHierarchyAndFilter } },
        [
          _c("attribute-filter-panel", {
            attrs: {
              "title-localisation": "attributes.filters.filterByAttributes",
              "filter-count-localisation": "attributes.filters.numApplied",
              "enable-hierarchy-filters": "",
              "filter-rules": _vm.retailAttributesFilter,
            },
            on: { attributeFilterChange: _vm.setFilterRules },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VDataTable,
        {
          staticClass: "pricing-table elevation-1 striped-table",
          attrs: {
            items: _vm.workpackageProductEngineInputs,
            headers: _vm.headers,
            loading: _vm.loading,
            "custom-filter": _vm.customFilter,
            height: _vm.dataTableHeight,
            "loading-text": _vm.$t("general.loadingMessage"),
            "disable-sort": "",
            "disable-pagination": "",
            dense: "",
            "item-key": "productKey",
            "fixed-header": "",
            "hide-default-header": "",
            "hide-default-footer": "",
          },
          nativeOn: {
            mousewheel: function ($event) {
              return _vm.preventPageNavigationOnHorizontalScroll(
                $event,
                ".v-data-table__wrapper"
              )
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function ({ props }) {
                return [
                  _c("thead", { staticClass: "v-data-table-header" }, [
                    _c(
                      "tr",
                      _vm._l(props.headers, function (header) {
                        return _c(
                          "th",
                          {
                            key: header.value,
                            class: [
                              ..._vm.getHeaderClasses(header, _vm.pagination),
                              header.class,
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.changeSort(header)
                              },
                            },
                          },
                          [
                            _c("span", [_vm._v(_vm._s(header.text))]),
                            _vm._v(" "),
                            header.unit
                              ? _c("span", [
                                  _vm._v("(" + _vm._s(header.unit) + ")"),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            header.deprecated
                              ? _c("span", [
                                  _vm._v(
                                    "(" +
                                      _vm._s(_vm.$t("general.deprecated")) +
                                      ")"
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "div",
                              [
                                header.sortable
                                  ? _c(
                                      VIcon,
                                      {
                                        staticClass:
                                          "v-data-table-header__icon",
                                        attrs: { size: "1.4rem" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                arrow_upward\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "tr",
                    { key: props.index },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.item.productKeyDisplay) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.toolStoreGroupColumn
                        ? _c(
                            "td",
                            {
                              staticClass: "text-xs table-cell",
                              class: _vm.fixedColumnsClass,
                            },
                            [
                              _c("tooltipped-truncated-field", {
                                staticClass: "tooltipped-truncated-field",
                                attrs: {
                                  text: props.item.toolStoreGroupDescription,
                                  "truncation-length":
                                    _vm.truncationLengthMedium,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell border-right",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.item.productName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "feature-toggle",
                        { attrs: { toggle: _vm.displayHierarchyAndFilter } },
                        _vm._l(
                          [
                            _vm.categoryLevel,
                            _vm.pricingGroupLevel,
                            _vm.architectureGroupLevel,
                          ],
                          function (level) {
                            return _c(
                              "td",
                              {
                                key: level,
                                staticClass: "text-xs table-cell",
                                class:
                                  level === _vm.architectureGroupLevel
                                    ? "border-right"
                                    : "",
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      value: _vm.getHierarchyName(
                                        props.item,
                                        level
                                      ),
                                      disabled: _vm.isTooltipDisabled(
                                        _vm.getHierarchyName(props.item, level),
                                        _vm.truncationLengthMedium
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("truncate")(
                                            _vm.getHierarchyName(
                                              props.item,
                                              level
                                            ),
                                            _vm.truncationLengthMedium
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.mandatoryEngineInputs,
                        function (value, input) {
                          return _c(
                            "td",
                            { key: input, staticClass: "text-xs table-cell" },
                            [
                              value === _vm.mandatoryEngineInputs.intentionCost
                                ? [
                                    _c(
                                      "div",
                                      { staticClass: "intention-cost-cell" },
                                      [
                                        _c("pricing-edit-text-field", {
                                          staticClass: "float-left",
                                          attrs: {
                                            disabled:
                                              _vm.futureCostEditDisabled,
                                            "data-type": _vm.dataTypes.number,
                                            "number-format":
                                              _vm.numberFormats.priceFormat,
                                            value: _vm.getInputValue(
                                              props.item,
                                              input,
                                              _vm.intentionCostFields.price
                                            ),
                                            "previous-value":
                                              _vm.getPreviousValue(
                                                props.item,
                                                input,
                                                _vm.intentionCostFields.price
                                              ),
                                            error:
                                              _vm.updateErrors[
                                                props.item.keys[input]
                                              ],
                                            "cell-class":
                                              _vm.getInputEditFieldClass(
                                                props.item,
                                                input
                                              ),
                                            "previous-value-translation-key":
                                              "engineInputs.editor",
                                            "tooltip-position":
                                              props.index >
                                              _vm.size(
                                                _vm.workpackageProductEngineInputs
                                              ) /
                                                2
                                                ? "top-left"
                                                : "bottom-left",
                                            "tooltip-value":
                                              _vm.getIntentionCostTooltip(
                                                props.item
                                              ),
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.recordChanges(
                                                $event.target.value,
                                                input,
                                                props.item,
                                                _vm.intentionCostFields.price
                                              )
                                            },
                                            focus: function ($event) {
                                              return _vm.debounceShowDatesEditor(
                                                props.item
                                              )
                                            },
                                            blur: function ($event) {
                                              return _vm.debounceRemoveIcon(
                                                props.item
                                              )
                                            },
                                          },
                                        }),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass:
                                              "date-pickers-container",
                                          },
                                          [
                                            _vm.shouldDisplayDatesEditor(
                                              props.item
                                            )
                                              ? _c(
                                                  VMenu,
                                                  {
                                                    attrs: {
                                                      disabled:
                                                        !_vm.canEditAttributes,
                                                      "close-on-content-click": false,
                                                      "nudge-right": 5,
                                                      top:
                                                        props.index >
                                                        _vm.size(
                                                          _vm.workpackageProductEngineInputs
                                                        ) /
                                                          2,
                                                      "offset-y": "",
                                                      transition:
                                                        "scale-transition",
                                                    },
                                                    on: {
                                                      input: function ($event) {
                                                        return _vm.closeDatesEditor(
                                                          $event
                                                        )
                                                      },
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticClass:
                                                                      "calendar-button",
                                                                    attrs: {
                                                                      color:
                                                                        "primary",
                                                                      depressed:
                                                                        "",
                                                                      "x-small":
                                                                        "",
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(
                                                                    VIcon,
                                                                    {
                                                                      attrs: {
                                                                        small:
                                                                          "",
                                                                      },
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        " event "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(VDatePicker, {
                                                      key: `${props.item.productKey}-${input}-effectiveDate-expiryDate`,
                                                      attrs: {
                                                        "first-day-of-week":
                                                          _vm.i18nconfig
                                                            .firstDayOfTheWeek,
                                                        min:
                                                          _vm.size(
                                                            _vm.dateRange
                                                          ) === 1
                                                            ? _vm.dateRange[0]
                                                            : "",
                                                        locale:
                                                          _vm.i18nconfig
                                                            .fallbackLocale,
                                                        range: "",
                                                        "show-current": false,
                                                        flat: "",
                                                        "no-title": "",
                                                        "picker-date":
                                                          _vm.pickerDate,
                                                      },
                                                      on: {
                                                        "update:pickerDate":
                                                          function ($event) {
                                                            _vm.pickerDate =
                                                              $event
                                                          },
                                                        "update:picker-date":
                                                          function ($event) {
                                                            _vm.pickerDate =
                                                              $event
                                                          },
                                                        input: function (
                                                          $event
                                                        ) {
                                                          return _vm.updateIntentionCostDates(
                                                            props.item,
                                                            $event,
                                                            input
                                                          )
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.dateRange,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.dateRange = $$v
                                                        },
                                                        expression: "dateRange",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                )
                                              : _vm._e(),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c("pricing-edit-text-field", {
                                      key: `${props.item.productKey}-${input}`,
                                      staticClass: "float-left",
                                      attrs: {
                                        disabled: !_vm.canEditAttributes,
                                        "data-type": _vm.dataTypes.number,
                                        "number-format":
                                          _vm.numberFormats.decimal,
                                        value: _vm.getInputValue(
                                          props.item,
                                          input
                                        ),
                                        "previous-value": _vm.getPreviousValue(
                                          props.item,
                                          input
                                        ),
                                        error:
                                          _vm.updateErrors[
                                            props.item.keys[input]
                                          ],
                                        "cell-class":
                                          _vm.getInputEditFieldClass(
                                            props.item,
                                            input
                                          ),
                                        "previous-value-translation-key":
                                          "engineInputs.editor",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.recordChanges(
                                            $event.target.value,
                                            input,
                                            props.item
                                          )
                                        },
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                VFlex,
                {
                  class: {
                    "input-screen-sticky-table-footer": _vm.showHierarchy,
                  },
                },
                [
                  _c(
                    VRow,
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        VCol,
                        { attrs: { md: "3", "offset-md": "8" } },
                        [
                          _c(VPagination, {
                            attrs: { length: _vm.pages },
                            on: {
                              input: _vm.loadMandatoryInputsFromAttributes,
                            },
                            model: {
                              value: _vm.pagination.page,
                              callback: function ($$v) {
                                _vm.$set(_vm.pagination, "page", $$v)
                              },
                              expression: "pagination.page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    {
                      staticClass: "pr-6 pb-2",
                      attrs: { justify: "end", align: "center" },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-3",
                          attrs: {
                            color: "primary",
                            small: "",
                            loading: _vm.downloading,
                            depressed: "",
                          },
                          on: { click: _vm.downloadEngineInputs },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.download")) +
                              "\n            "
                          ),
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("$export"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr-3 btn-divider" }),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "save",
                          attrs: {
                            disabled: _vm.isSaveDisabled,
                            loading: _vm.loading,
                            color: "success",
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.persistUpdates },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }