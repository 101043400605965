<template>
  <highcharts ref="highcharts" class="highchart" :options="chartOptions" />
</template>

<script>
import { get, toLower, merge, round } from 'lodash';
import { createNamespacedHelpers } from 'vuex';
import defaultOptions from '../../../../default-chart-options';
import colours from '../../../../ow-colors';
import penaltyCategories from '@enums/penalty-categories';
import numberFormats from '@enums/number-formats';

const { mapActions } = createNamespacedHelpers('penaltyFunctions');

export default {
  props: {
    penaltyCurvePoints: {
      type: Object,
      required: true,
    },
    penaltyCategory: {
      type: String,
      required: true,
    },
  },

  data() {
    return {
      chartOptions: {},
      series: [],
      maxPenalty: 0,
      penaltyCurveSeries: [],
      currentPenaltyOffset: 0,
    };
  },

  watch: {
    penaltyCurvePoints: {
      handler() {
        this.series = [];
        return this.setSeriesData();
      },
      deep: true,
    },
  },

  async created() {
    await this.setSeriesData();
  },

  methods: {
    ...mapActions(['fetchPenaltyCurve']),

    async setSeriesData() {
      const { lower, upper, penaltyOffset } = this.penaltyCurvePoints;

      if (penaltyOffset <= -100 || penaltyOffset >= 100) return; // don't redraw chart if invalid

      const params = {
        lower: get(lower, 'penaltyDeviation')
          ? this.createPenaltyFunctionRequestPayload('lower', this.penaltyCurvePoints)
          : undefined,
        upper: get(upper, 'penaltyDeviation')
          ? this.createPenaltyFunctionRequestPayload('upper', this.penaltyCurvePoints)
          : undefined,
        offset: penaltyOffset,
      };

      const { penaltyCurve, maxValue } = await this.fetchPenaltyCurve({ params });
      this.penaltyCurveSeries = penaltyCurve;
      this.maxValue = maxValue;

      // Penalty Curve Data Series
      this.series.push({
        type: 'areaspline',
        color: this.setChartColour(),
        data: this.penaltyCurveSeries.map((element, index) => {
          if (index === 0) {
            const rounded = 20 * round((element[0] + penaltyOffset) / 20);
            return [rounded > element[0] + penaltyOffset ? rounded - 20 : rounded, element[1]];
          }
          return [element[0] + penaltyOffset, element[1]];
        }),
      });

      // Offset Line
      this.series.push({
        dashStyle: 'shortdot',
        data: [[penaltyOffset, 0]],
        lineWidth: 2,
        color: colours.pricingChartScatter,
        marker: {
          symbol: 'circle',
          radius: 6,
        },
      });

      return this.createChartOptions();
    },

    createPenaltyFunctionRequestPayload(type, rule) {
      const scatterPointsMap = {
        lower: {
          scatterPoints: ['A', 'B'],
          order: [1, 0],
        },
        upper: {
          scatterPoints: ['D', 'C'],
          order: [0, 1],
        },
      };

      const { penaltyType = 'standard', penaltyDeviation, penaltyPower = 0 } = get(rule, type, {});
      const { scatterPoints = [], order = [] } = get(scatterPointsMap, type, {});

      const w1 = get(penaltyDeviation, `[${order[0]}].penaltyDeviationPercent`, 0);
      const w2 = get(penaltyDeviation, `[${order[1]}].penaltyDeviationPercent`, 0);

      const result = {
        penalty_type: toLower(penaltyType),
        w1: Math.abs(w1),
        h1: get(penaltyDeviation, `[${order[0]}].penaltyAbsolute`, 0),
        w2: Math.abs(w2),
        h2: get(penaltyDeviation, `[${order[1]}].penaltyAbsolute`, 0),
        n: penaltyPower,
      };

      this.addScatterPointToSeries(
        scatterPoints[1],
        w1 * 100 + this.penaltyCurvePoints.penaltyOffset,
        result.h1
      );
      this.addScatterPointToSeries(
        scatterPoints[0],
        w2 * 100 + this.penaltyCurvePoints.penaltyOffset,
        result.h2
      );

      return result;
    },

    setChartColour() {
      switch (this.penaltyCategory) {
        case penaltyCategories.competitor:
          return colours.competitorTrafficLightColor;
        case penaltyCategories.storeGroup:
          return colours.storeGroupTrafficLightColor;
        case penaltyCategories.architecture:
          return colours.architectureTrafficLightColor;
        default:
          return colours.economicTrafficLightColor;
      }
    },

    addScatterPointToSeries(name, xAxis, yAxis) {
      this.series.push({
        type: 'scatter',
        name,
        data: [[xAxis, yAxis]],
        color: this.setChartColour(),
        marker: {
          symbol: 'circle',
          radius: 6,
        },
        dataLabels: {
          enabled: true,
          formatter() {
            return name;
          },
          x: 5,
          align: 'bottom',
          style: {
            fontSize: '1.4rem',
            color: colours.penaltiesScatterTextColour,
          },
        },
      });
    },

    createChartOptions() {
      const vm = this;
      this.chartOptions = merge({}, defaultOptions, {
        series: this.series,
        chart: {
          height: 400,
          width: 480,
        },
        xAxis: [
          {
            index: 0,
            min: this.penaltyCurvePoints.penaltyOffset - 100,
            max: this.penaltyCurvePoints.penaltyOffset + 100,
            endOnTick: false,
            tickPositions: null,
            tickLength: 6,
            gridLineWidth: 0,
            lineColor: colours.pricingGreyDark,
            startOnTick: true,
            tickInterval: 10,
            plotLines: [
              {
                color: colours.pricingGreyDark,
                dashStyle: 'shortdot',
                value: 0,
                width: 2,
              },
              {
                color: colours.pricingChartScatter,
                dashStyle: 'shortdot',
                value: this.penaltyCurvePoints.penaltyOffset,
                width: 2,
              },
            ],
          },
          {
            index: 1,
            min: this.penaltyCurvePoints.penaltyOffset - 100,
            max: this.penaltyCurvePoints.penaltyOffset + 100,
            endOnTick: false,
            tickPositions: null,
            gridLineWidth: 0,
            lineColor: colours.pricingGreyDark,
            startOnTick: true,
            tickInterval: 10,
            lineWidth: 0,
            tickWidth: 0,
            labels: {
              enabled: true,
              style: {
                color: this.setChartColour(),
              },
              formatter() {
                const cal = 100 / (1 + this.value / 100);
                if (cal === Infinity) {
                  return '-';
                }
                const rounded = round(cal);
                return vm.formatNumber({ number: rounded, format: numberFormats.integer });
              },
            },
            title: {
              text: '%',
              align: 'high',
              x: 20,
              y: -43,
              style: {
                fontSize: '1.5rem',
              },
            },
          },
        ],
        yAxis: {
          gridLineColor: colours.pricingGreyDark,
          min: 0,
          max: this.maxValue + this.maxValue * 0.1,
          lineColor: colours.pricingGreyDark,
          tickPositions: null,
          gridLineWidth: 1,
          lineWidth: 0,
          startOnTick: true,
          endOnTick: true,
          labels: {
            enabled: true,
            formatter() {
              return vm.formatNumber({ number: this.value, format: numberFormats.integer });
            },
            style: {
              fontSize: '1.2rem',
            },
          },
        },
        plotOptions: {
          series: {
            marker: {
              enabled: true,
            },
            fillOpacity: 0.3,
          },
          areaspline: {
            marker: {
              radius: 0,
            },
            fillOpacity: 0.1,
          },
        },
      });
    },
  },
};
</script>

<style scoped lang="scss">
.highchart {
  margin-right: auto;
  margin-left: auto;
}
</style>
