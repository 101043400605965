import axios from 'axios';
import to from 'await-to-js';
import { merge, sortBy } from 'lodash';
import { active, inactive } from '@enums/rule-status';
import {
  priceGroupOverrideRule,
  globalRule,
  defaultRule,
  pricingGroupRule,
} from '@enums/rule-type';

const getInitialState = () => {
  return {
    rules: [],
    loading: false,
    productCounts: {},
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    priceGroupOverrideRules: state =>
      sortBy(
        state.rules.filter(
          ({ status, ruleType }) => ruleType === priceGroupOverrideRule && status === active
        ),
        'priority'
      ),
    globalRules: state =>
      sortBy(
        state.rules.filter(({ status, ruleType }) => ruleType === globalRule && status === active),
        'priority'
      ),
    pricingGroupRules: state =>
      sortBy(
        state.rules.filter(
          ({ status, ruleType }) => ruleType === pricingGroupRule && status === active
        ),
        'priority'
      ),
    defaultRules: state =>
      sortBy(
        state.rules.filter(({ status, ruleType }) => ruleType === defaultRule && status === active),
        'priority'
      ),

    inactiveRules: state => state.rules.filter(({ status }) => status === inactive),
  },

  mutations: {
    setRules(state, rules) {
      state.rules = rules;
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setProductCounts(state, productCounts) {
      state.productCounts = productCounts;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },

  actions: {
    async fetchRules({ commit, rootState }, { params = {} } = {}) {
      // If Scenario is set in the filters, use that to filter the data
      if (rootState.filters.scenario) {
        params = merge(params, {
          where: { scenarioKey: rootState.filters.scenario },
        });
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setLoading', true);
      const [err, { data: rules }] = await to(
        axios.get(`/api/rules/workpackage/${workpackageId}`, { params })
      );
      if (err) throw new Error(err.message);
      commit('setRules', rules);
      commit('setLoading', false);
      return rules;
    },

    async createRule({ rootState, dispatch }, rule) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: result }] = await to(
        axios.post(`/api/rules/workpackage/${workpackageId}`, rule)
      );
      if (err) throw new Error(err.message);
      dispatch('fetchRules');
      return result;
    },

    async updateRules({ commit, dispatch }, { updates = {} } = {}) {
      commit('setLoading', true);
      const [err] = await to(axios.patch('/api/rules', updates));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      return dispatch('fetchRules');
    },

    async updateRule({ commit, dispatch }, { updates = {}, id }) {
      commit('setLoading', true);
      const [err] = await to(axios.patch(`/api/rules/${id}`, updates));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      return dispatch('fetchRules');
    },

    async getDefaultRule() {
      const [err, { data: defaultPenalties }] = await to(axios.get('/api/penalty-defaults'));
      if (err) throw new Error(err.message);

      const { _id, ...defaultPenalty } = defaultPenalties[0] || {};

      const architecturePenalty = {
        ...defaultPenalty.architecturePenalty,
        status: inactive,
      };

      const economicPenalty = {
        ...defaultPenalty.economicPenalty,
        status: inactive,
      };

      const competitorPenalty = {
        penalties: [],
        defaultPenalty: { ...defaultPenalty.competitorPenalty },
        status: inactive,
      };

      const storeGroupPenalty = {
        penalties: [],
        defaultPenalty: { ...defaultPenalty.storeGroupPenalty },
        status: inactive,
      };

      const pricePointing = {
        ...defaultPenalty.pricePointing,
        status: inactive,
      };

      return {
        description: '',
        scope: [],
        priority: 0,
        status: active,
        architecturePenalty,
        economicPenalty,
        competitorPenalty,
        storeGroupPenalty,
        pricePointing,
      };
    },

    async runSettingsAllocation({ commit, rootState, dispatch }, { scenarioKey }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: productCounts }] = await to(
        axios.post(`/api/settings-allocation/workpackage/${workpackageId}`, {
          scenario_key: scenarioKey,
        })
      );
      if (err) throw new Error(err.message);
      commit('setProductCounts', productCounts);
      return dispatch('fetchRules');
    },

    async deleteRule({ dispatch }, { id }) {
      const [err] = await to(axios.delete(`/api/rules/${id}`));
      if (err) throw new Error(err.message);
      return dispatch('fetchRules');
    },

    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default store;
