<template>
  <div class="spinner-wrapper">
    <v-progress-linear indeterminate />
    <span>{{ $t('general.loadingMessage') }}</span>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.spinner-wrapper {
  align-items: center;
  display: flex;
  flex-direction: column;
  font-size: 1.2rem;
}
</style>
