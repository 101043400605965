import { mapState } from 'vuex';
import { generalCssClasses } from '@enums/tables';
import numberFormats from '@enums/number-formats';

const _ = require('lodash');

const mixin = {
  computed: {
    ...mapState('clientConfig', ['dateFormats']),
  },
  methods: {
    percentFormatter(params) {
      return this.formatNumber({
        number: Number(params.value / 100),
        format: numberFormats.percent,
        zeroAsDash: true,
        nullAsDash: true,
      });
    },
    currencyFormatter(params) {
      return this.formatNumber({
        number: Number(params.value),
        format: numberFormats.currency,
        zeroAsDash: true,
        nullAsDash: true,
      });
    },
    getHeaderClasses(header, pagination) {
      if (!header || !pagination) {
        return [];
      }

      // Adding as work around. The .attributes comes from the BE and is causing the comparison to fail
      // e.g FE value - uuid0001  BE value - attributes.uuid0001
      // Looked at changing on the FE but would require a lot of refactoring and high risk

      let sortBy = '';
      sortBy = _.isString(pagination.sortBy)
        ? _.get(pagination, 'sortBy', '')
        : _.get(pagination, 'sortBy', [])[0];
      sortBy = sortBy && sortBy.length ? sortBy.replace('attributes.', '') : sortBy;

      return [
        header.sortable ? generalCssClasses.sortable : '',
        pagination.descending
          ? generalCssClasses.descendingSorting
          : generalCssClasses.ascendingSorting,
        header.value === sortBy ? generalCssClasses.active : '',
      ];
    },

    getColumnFormatters(headers) {
      return headers.reduce((acc, header) => {
        if (header.formatter) {
          const { type, format } = header.formatter;
          acc[header.text] = { type, ...(format && { format }) };
        }
        return acc;
      }, {});
    },

    getFlattenColumns(firstRow, secondRow) {
      if (!firstRow || !firstRow.length) {
        return [];
      }

      if (!secondRow || !secondRow.length) {
        return firstRow;
      }

      let index = 0;
      return firstRow.reduce((acc, row) => {
        const colspan = row.colspan;

        if (colspan === 1 || !colspan) {
          acc.push(row);
          return acc;
        }

        acc.push(...secondRow.slice(index, index + colspan));
        index += colspan;

        return acc;
      }, []);
    },
  },
};

export default mixin;
