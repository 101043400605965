var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Manual Override")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "manual-override-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "manual-override-transform",
                transform: "translate(-375.000000, -759.000000)",
                fill: "#6D7278",
              },
            },
            [
              _c("path", {
                attrs: {
                  id: "manual-override",
                  d: "M383.52,764 C384.072285,764 384.52,764.447715 384.52,765 C384.52,765.552285 384.072285,766 383.52,766 L383.52,766 L380,766 C378.343146,766 377,767.343146 377,769 L377,769 L377,778 C377,779.656854 378.343146,781 380,781 L380,781 L389,781 C390.656854,781 392,779.656854 392,778 L392,778 L392,774.48 C392,773.927715 392.447715,773.48 393,773.48 C393.552285,773.48 394,773.927715 394,774.48 L394,774.48 L394,778 C393.996694,780.760053 391.760053,782.996694 389,783 L389,783 L380,783 C377.239947,782.996694 375.003306,780.760053 375,778 L375,778 L375,769 C375.003306,766.239947 377.239947,764.003306 380,764 L380,764 Z M393.642216,759.95494 C394.86516,758.711788 396.858984,758.678071 398.123276,759.879162 C399.321099,761.144189 399.287419,763.134616 398.047484,764.35841 L398.047484,764.35841 L389.553613,772.851853 C389.41447,772.99067 389.238773,773.087143 389.04695,773.130054 L389.04695,773.130054 L385.261514,773.978152 C384.915646,774.049844 384.556919,773.942623 384.307153,773.6929 C384.057387,773.443177 383.950147,773.084512 384.021851,772.738703 L384.021851,772.738703 L384.870096,768.953921 C384.91294,768.761273 385.009821,768.584829 385.149384,768.445269 L385.149384,768.445269 Z M395.925314,761.064631 C395.617888,761.074329 395.326162,761.202681 395.111331,761.422763 L395.111331,761.422763 L386.8303,769.702365 L386.403582,771.591642 L388.295262,771.167074 L396.576293,762.887472 C396.795581,762.6722 396.923491,762.380735 396.933449,762.07363 C396.943279,761.803088 396.841557,761.540381 396.652085,761.346985 C396.456259,761.161181 396.195238,761.059774 395.925314,761.064631 Z",
                },
              }),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }