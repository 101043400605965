<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Competitor match lost</title>
    <g
      id="competitor-match-lost-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="competitor-match-lost-transform"
        transform="translate(-138.000000, -559.000000)"
        fill-rule="nonzero"
      >
        <g id="competitor-match-lost" transform="translate(138.000000, 559.000000)">
          <rect id="Rectangle" x="0" y="0" width="24" height="24" />
          <path
            id="Combined-Shape"
            d="M16,6 L23.72,14.0095 C23.8996,14.1959 24,14.4446 24,14.7035 L24,14.7035 L24,23 C24,23.5523 23.5523,24 23,24 L23,24 L18,24 C17.4477,24 17,23.5523 17,23 L17,23 L17,19 L15,19 L15,23 C15,23.5523 14.5523,24 14,24 L14,24 L9,24 C8.44772,24 8,23.5523 8,23 L8,23 L8,14.7035 C8,14.4446 8.10037,14.1959 8.28,14.0095 L8.28,14.0095 L16,6 Z M16,8.88195 L10,15.1069 L10,22 L13,22 L13,17 L19,17 L19,22 L22,22 L22,15.1069 L16,8.88195 Z M1,10 C1.55228475,10 2,10.4477153 2,11 C2,11.5522847 1.55228475,12 1,12 C0.44771525,12 6.76353751e-17,11.5522847 0,11 C-6.76353751e-17,10.4477153 0.44771525,10 1,10 Z M4,10 C4.55228475,10 5,10.4477153 5,11 C5,11.5522847 4.55228475,12 4,12 C3.44771525,12 3,11.5522847 3,11 C3,10.4477153 3.44771525,10 4,10 Z M7,10 C7.55228475,10 8,10.4477153 8,11 C8,11.5522847 7.55228475,12 7,12 C6.44771525,12 6,11.5522847 6,11 C6,10.4477153 6.44771525,10 7,10 Z M9,7 C9.55228475,7 10,7.44771525 10,8 C10,8.55228475 9.55228475,9 9,9 C8.44771525,9 8,8.55228475 8,8 C8,7.44771525 8.44771525,7 9,7 Z M1,7 C1.55228475,7 2,7.44771525 2,8 C2,8.55228475 1.55228475,9 1,9 C0.44771525,9 6.76353751e-17,8.55228475 0,8 C-6.76353751e-17,7.44771525 0.44771525,7 1,7 Z M9,4 C9.55228475,4 10,4.44771525 10,5 C10,5.55228475 9.55228475,6 9,6 C8.44771525,6 8,5.55228475 8,5 C8,4.44771525 8.44771525,4 9,4 Z M1,4 C1.55228475,4 2,4.44771525 2,5 C2,5.55228475 1.55228475,6 1,6 C0.44771525,6 6.76353751e-17,5.55228475 0,5 C-6.76353751e-17,4.44771525 0.44771525,4 1,4 Z M7,2 C7.55228475,2 8,2.44771525 8,3 C8,3.55228475 7.55228475,4 7,4 C6.44771525,4 6,3.55228475 6,3 C6,2.44771525 6.44771525,2 7,2 Z M3,2 C3.55228475,2 4,2.44771525 4,3 C4,3.55228475 3.55228475,4 3,4 C2.44771525,4 2,3.55228475 2,3 C2,2.44771525 2.44771525,2 3,2 Z M5,0 C5.55228475,-1.01453063e-16 6,0.44771525 6,1 C6,1.55228475 5.55228475,2 5,2 C4.44771525,2 4,1.55228475 4,1 C4,0.44771525 4.44771525,1.01453063e-16 5,0 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'CompetitorMatchLost',
};
</script>
