import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: { "offset-y": "" },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                VBtn,
                _vm._g(
                  {
                    staticClass: "ml-2",
                    attrs: {
                      small: "",
                      color: "primary",
                      loading: _vm.downloadingLinePricing,
                      depressed: "",
                    },
                  },
                  on
                ),
                [
                  _vm._v(
                    "\n      " +
                      _vm._s(_vm.$t("actions.downloadLinePricing.main")) +
                      "\n    "
                  ),
                ]
              ),
            ]
          },
        },
      ]),
    },
    [
      _vm._v(" "),
      _c(
        VList,
        [
          _vm.isPricingManager || _vm.isPricingSpecialist
            ? _c(
                VListItem,
                {
                  on: {
                    click: function ($event) {
                      return _vm.downloadLinePricingHandler({ exportAG: false })
                    },
                  },
                },
                [
                  _c(VListItemTitle, [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("actions.downloadLinePricing.workpackage")
                        ) +
                        "\n      "
                    ),
                  ]),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VListItem,
            {
              on: {
                click: function ($event) {
                  return _vm.downloadLinePricingHandler({ exportAG: true })
                },
              },
            },
            [
              _c(VListItemTitle, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("actions.downloadLinePricing.ag")) +
                    "\n      "
                ),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }