<template>
  <div class="form-container">
    <span class="form-header mb-3">{{ penaltyName }}</span>
    <v-form v-model="isFormValid">
      <v-layout justify-space-between>
        <v-flex xs7>
          <v-select
            v-model="mutableRule.penaltyType"
            flat
            :background-color="owWhite"
            :items="penaltyTypes"
            :label="$t('settings.parameters.penaltyType')"
            :disabled="disabled"
          >
            <template v-slot:item="props">
              <span class="item-text">{{ props.item.text }}</span>
            </template>
          </v-select>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            v-model.number="penaltyPower"
            :background-color="owWhite"
            :label="$t('settings.parameters.penaltyPower')"
            step="0.1"
            type="number"
            :rules="rules.penaltyPower"
            :disabled="disabled"
          />
        </v-flex>
      </v-layout>

      <v-layout justify-space-between align-baseline>
        <v-flex xs1 align-center>
          <span class="text-strong">{{ scatterPoints[0] }}</span>
        </v-flex>

        <v-flex xs6 align-baseline d-flex>
          <v-text-field
            v-model.number="firstDeviationPercent"
            :background-color="owWhite"
            step="0.1"
            :label="$t('settings.parameters.penaltyDeviation')"
            type="number"
            :rules="rules.firstDeviationPercent"
            :disabled="disabled"
          />
          <span class="suffix"> % </span>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            v-model.number="firstDeviationPenalty"
            flat
            :background-color="owWhite"
            :label="$t('settings.parameters.penalty')"
            type="number"
            :rules="rules.firstDeviationPenalty"
            :disabled="disabled"
          />
        </v-flex>
      </v-layout>

      <v-layout justify-space-between align-baseline>
        <v-flex xs1 align-center>
          <span class="text-strong">{{ scatterPoints[1] }}</span>
        </v-flex>

        <v-flex xs6 d-flex align-baseline>
          <v-text-field
            v-model.number="secondDeviationPercent"
            flat
            :background-color="owWhite"
            step="0.1"
            :label="$t('settings.parameters.penaltyDeviation')"
            type="number"
            :rules="rules.secondDeviationPercent"
            :disabled="disabled"
          />
          <span class="suffix"> % </span>
        </v-flex>

        <v-flex xs4>
          <v-text-field
            v-model.number="secondDeviationPenalty"
            flat
            :background-color="owWhite"
            :label="$t('settings.parameters.penalty')"
            type="number"
            :rules="rules.secondDeviationPenalty"
            :disabled="disabled"
          />
        </v-flex>
      </v-layout>

      <v-layout v-if="metaErrors.length" class="meta-errors">
        <v-layout v-for="(error, index) in metaErrors" :key="`error-${index}`">
          {{ error }}
        </v-layout>
      </v-layout>
    </v-form>
  </div>
</template>

<script>
import { debounce, cloneDeep, isEqual } from 'lodash';
import colours from '../../../../ow-colors';
import clientConfig from '@sharedModules/config/client';

const penaltySettings = {
  lower: {
    pointNames: ['A', 'B'],
    deviationPercentBounds: {
      min: -100,
      max: -1,
    },
  },
  upper: {
    pointNames: ['C', 'D'],
    deviationPercentBounds: {
      min: 1,
      max: 100,
    },
  },
};

export default {
  props: {
    penaltyName: {
      type: String,
      required: true,
    },
    penaltyRule: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      owWhite: colours.owWhite,
      isFormValid: false,
      mutableRule: cloneDeep(this.penaltyRule),
      penaltyTypes: [
        { value: 'standard', text: this.$t('settings.parameters.curveType.standard') },
        { value: 'dissipate', text: this.$t('settings.parameters.curveType.dissipate') },
        { value: 'kick', text: this.$t('settings.parameters.curveType.kick') },
      ],
      scatterPoints: penaltySettings[this.penaltyName].pointNames,
      deviationPenaltyBounds: penaltySettings[this.penaltyName].deviationPercentBounds,
      rules: {
        penaltyPower: [this.valueRequired, this.valueIsPositive],
        firstDeviationPercent: [this.valueRequired, this.valueWithinDeviationPercentBounds],
        secondDeviationPercent: [this.valueRequired, this.valueWithinDeviationPercentBounds],
        firstDeviationPenalty: [this.valueRequired, this.valueIsPositiveOrZero],
        secondDeviationPenalty: [this.valueRequired, this.valueIsPositiveOrZero],
      },
    };
  },

  computed: {
    metaErrors() {
      const deviationPercentageOfALessThanB =
        this.firstDeviationPercent <= this.secondDeviationPercent
          ? ''
          : this.$t('settings.parameters.errors.lessOrEqual', {
              smallerValue: this.scatterPoints[0],
              biggerValue: this.scatterPoints[1],
              property: 'deviation',
            });

      const deviationPenaltyOfAGreaterThanB =
        this.firstDeviationPenalty >= this.secondDeviationPenalty
          ? ''
          : this.$t('settings.parameters.errors.moreOrEqual', {
              biggerValue: this.scatterPoints[0],
              smallerValue: this.scatterPoints[1],
              property: 'penalty',
            });

      const deviationPenaltyOfBGreaterThanA =
        this.secondDeviationPenalty >= this.firstDeviationPenalty
          ? ''
          : this.$t('settings.parameters.errors.moreOrEqual', {
              biggerValue: this.scatterPoints[1],
              smallerValue: this.scatterPoints[0],
              property: 'penalty',
            });

      const deviationPenaltyCondition = {
        lower: deviationPenaltyOfAGreaterThanB,
        upper: deviationPenaltyOfBGreaterThanA,
      }[this.penaltyName];

      return [deviationPercentageOfALessThanB, deviationPenaltyCondition].filter(x => x.length);
    },

    allParamsValid() {
      // isFormValid checks all the individual rules on each input. metaErrors give us relationships like A <= B
      return this.isFormValid && !this.metaErrors.length;
    },

    // TODO: look into vuex-map-fields to avoid the get / set pattern
    // https://github.com/maoberlehner/vuex-map-fields
    penaltyPower: {
      get() {
        return this.mutableRule.penaltyPower;
      },
      set(newValue) {
        this.mutableRule.penaltyPower = newValue;
      },
    },

    firstDeviationPercent: {
      get() {
        return this.toFixedFloat(
          this.mutableRule.penaltyDeviation[0].penaltyDeviationPercent * 100,
          1
        );
      },
      set(newValue) {
        // prevent empty values being displayed as 0
        if (newValue !== '') {
          this.mutableRule.penaltyDeviation[0].penaltyDeviationPercent = this.toFixedFloat(
            newValue / 100,
            3
          );
        }
      },
    },

    secondDeviationPercent: {
      get() {
        return this.toFixedFloat(
          this.mutableRule.penaltyDeviation[1].penaltyDeviationPercent * 100,
          1
        );
      },
      set(newValue) {
        if (newValue !== '') {
          // prevent empty values being displayed as 0
          this.mutableRule.penaltyDeviation[1].penaltyDeviationPercent = this.toFixedFloat(
            newValue / 100,
            3
          );
        }
      },
    },

    firstDeviationPenalty: {
      get() {
        return this.mutableRule.penaltyDeviation[0].penaltyAbsolute;
      },
      set(newValue) {
        this.mutableRule.penaltyDeviation[0].penaltyAbsolute = newValue;
      },
    },

    secondDeviationPenalty: {
      get() {
        return this.mutableRule.penaltyDeviation[1].penaltyAbsolute;
      },
      set(newValue) {
        this.mutableRule.penaltyDeviation[1].penaltyAbsolute = newValue;
      },
    },
  },

  watch: {
    mutableRule: {
      deep: true,
      handler: debounce(function(updatedParameters) {
        if (this.allParamsValid && this.penaltyPower !== 0) {
          this.$emit('updatePenaltyRule', { penaltyName: this.penaltyName, updatedParameters });
        } else {
          this.$emit('penaltyRuleInvalid');
        }
      }, clientConfig.inputDebounceValue),
    },

    penaltyRule: {
      handler(newPenaltyRule) {
        if (!isEqual(this.mutableRule, newPenaltyRule)) {
          this.mutableRule = cloneDeep(newPenaltyRule);
        }
      },
    },
  },

  methods: {
    toFixedFloat(value, decimalDigits = 0) {
      if (Number.isNaN(+value)) {
        return 0;
      }

      return +Number.parseFloat(value).toFixed(decimalDigits);
    },

    valueRequired(v) {
      return !!v || v === 0 || this.$t('settings.parameters.errors.fieldRequired');
    },

    valueWithinDeviationPercentBounds(v) {
      const { min, max } = this.deviationPenaltyBounds;

      return (
        (v >= min && v <= max) ||
        this.$t('settings.parameters.errors.minMaxValidation', { min, max })
      );
    },

    valueIsPositive(v) {
      return v > 0 || this.$t('settings.parameters.errors.positiveValidation');
    },

    valueIsPositiveOrZero(v) {
      return v >= 0 || this.$t('settings.parameters.errors.positiveOrZeroValidation');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.suffix {
  padding: 0 0.5rem;
}

.form-container {
  .form-header {
    padding: 0.2rem 1.2rem;
    border-radius: 0.5rem;
    font-size: 1.2rem;
    background-color: $paramsFormHeaderBackgroundColor;
    color: $pricing-white;
    display: inline-block;
  }

  .text-strong {
    font-weight: bold;
  }

  .meta-errors {
    color: $vuetify-error-red;
  }
}
</style>
