<template>
  <v-layout v-show="hasSelectedWorkpackage" column>
    <div class="sticky-header-group">
      <div class="pa-2">
        <div class="page-rule-heading pr-10 d-inline-flex">
          {{ $t('settings.architecture.pageHeading') }}
        </div>
        <scenario-filter class="d-inline-flex" />
      </div>
      <architecture-group-filter />
    </div>
    <v-flex mt-3>
      <architecture-drivers-rulesets
        :disabled="!isRulesEnabled"
        :editable="canEditRules"
        class="architecture-expansion"
        @panelChange="setArchitectureOpen($event.value === 0)"
      />
    </v-flex>
    <v-flex mt-3>
      <line-pricing
        :disabled="!isRulesEnabled"
        :editable="canEditRules"
        class="architecture-expansion"
        @panelChange="setLinePricingOpen($event.value === 0)"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';

export default {
  computed: {
    ...mapGetters('workpackages', ['hasSelectedWorkpackage']),
    ...mapState('filters', ['scenario', 'architectureGroup', 'pricingGroup']),
    ...mapGetters('context', ['isPricingSpecialist']),

    isRulesEnabled() {
      return !!this.pricingGroup && !!this.scenario && !!this.architectureGroup;
    },
    canEditRules() {
      return this.isPricingSpecialist;
    },
  },

  created() {
    // fetch products if architectureGroup is pre-selected on page load
    if (this.architectureGroup && this.hasSelectedWorkpackage) {
      this.fetchArchitectureProducts({ architectureGroupId: this.architectureGroup });
    }
  },

  methods: {
    ...mapActions('architectureGroup', ['fetchArchitectureProducts']),
  },
};
</script>

<style lang="scss" scoped>
.sticky-header-group {
  z-index: 10;
  padding-bottom: 0rem;
  border-radius: 4px;
}
</style>

<style lang="scss">
.architecture-expansion {
  .v-expansion-panel-header,
  .v-card__text {
    font-size: 1.5rem;
  }
}
</style>
