import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import { to } from 'await-to-js';
import gridView from './modules/grid-view';
import context from './modules/context';
import competitorMetadata from './modules/competitor-metadata';
import attributes from './modules/attributes';
import filters from './modules/filters';
import hierarchy from './modules/hierarchy';
import scenarioMetadata from './modules/scenario-metadata';
import rules from './modules/rules';
import penaltyFunctions from './modules/penalty-functions';
import architectureGroup from './modules/architecture-group';
import architectureDrivers from './modules/architecture-drivers';
import workpackages from './modules/work-packages';
import workpackageProducts from './modules/workpackage-products';
import engineInputs from './modules/engine-inputs';
import notifications from './modules/notifications';
import userProfiles from './modules/user-profiles';
import clientConfig from './modules/client-config';
import scenarioProducts from './modules/scenario-products';
import storeGroupRelationships from './modules/store-group-relationships';
import wholesale from './modules/wholesale';
import wholesaleGrid from './modules/wholesale-grid';
import wholesaleProductResults from './modules/wholesale-product-results';

Vue.use(Vuex);

const vuexLocalStorage = new VuexPersist({
  key: 'store',
  storage: window.localStorage,
  reducer: state => ({
    context: state.context,
    workpackages: {
      selectedWorkpackage: state.workpackages.selectedWorkpackage,
    },
    filters: {
      newTabScenario: state.filters.newTabScenario,
      newTabPricingGroup: state.filters.newTabPricingGroup,
      newTabArchitectureGroup: state.filters.newTabArchitectureGroup,
      newTabPriceType: state.filters.newTabPriceType,
      // this is different because it doesn't go into vuex, it gets consumed in the attribute-filter-panel.vue component.
      newTabArchitectureGroupAttributeFilter: state.filters.newTabArchitectureGroupAttributeFilter,
    },
    competitorMetadata: state.competitorMetadata, // for list of available competitors
    gridView: {
      selectedCompetitors: state.gridView.selectedCompetitors,
    },
    clientConfig: {
      i18nconfig: state.i18nconfig,
      toggleLogic: state.toggleLogic,
    },
    architectureGroup: {
      newTabOpenLinePricingRules: state.architectureGroup.newTabOpenLinePricingRules,
      newTabOpenArchitectureRules: state.architectureGroup.newTabOpenArchitectureRules,
      newTabActiveLinePricingGroupIndex: state.architectureGroup.newTabActiveLinePricingGroupIndex,
      newTabOpenArchitectureSubGroupSplittingAttributes:
        state.architectureGroup.newTabOpenArchitectureSubGroupSplittingAttributes,
    },
  }),
});

const getInitialState = () => ({
  route: null,
});

const store = new Vuex.Store({
  state: getInitialState(),
  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setRoute(state, route) {
      state.route = route;
    },
  },
  // we've agreed that unless there's a strong case for it, there should be no other actions here
  actions: {
    // this is where you can dispatch actions that need to happen as soon as the app loads
    async initialiseState({ rootState, dispatch, commit }) {
      commit('resetState');
      const { absentInStore, exists } = await dispatch('workpackages/checkForWorkpackageExistence');
      if (!absentInStore && !exists) {
        dispatch('resetWorkpackageState');
      }
      dispatch('notifications/openNotificationStream');
      // failure will be fatal for the app (rejection is not handled)
      await dispatch('clientConfig/loadClientConfig');
      // failures won't be fatal (rejection is handled by to)
      await to(
        Promise.all([
          dispatch('workpackages/getToolStoreGroups'),
          dispatch('storeGroupRelationships/fetchStoreGroupRelationships'),
          dispatch('storeGroupRelationships/fetchWorkpackageStoreGroups'),
          dispatch('storeGroupRelationships/fetchStoreGroups'),
          dispatch('storeGroupRelationships/fetchStoregroupKeyNameMap'),
        ])
      );
      await dispatch('attributes/fetchAttributeMetadata');
      // Add default filter as soon as metadata is loaded (if feature is supported)
      if (rootState.clientConfig.toggleLogic.useDefaultFilter) {
        await dispatch('filters/setDefaultFilter', null, { root: true });
      }
      const defaultCategoryFilter = rootState.clientConfig.toggleLogic.defaultCategoryFilter;
      if (defaultCategoryFilter) {
        await dispatch(
          'filters/setDefaultCategoryFilter',
          { defaultCategoryFilter },
          { root: true }
        );
      }
      await dispatch('competitorMetadata/fetchCompetitorMetadata');
    },
    resetWorkpackageState({ dispatch }) {
      const workpackageModules = {
        gridView,
        attributes,
        competitorMetadata,
        filters,
        hierarchy,
        scenarioMetadata,
        rules,
        architectureGroup,
        architectureDrivers,
        workpackages,
        workpackageProducts,
        engineInputs,
        scenarioProducts,
        storeGroupRelationships,
        wholesale,
        wholesaleGrid,
        wholesaleProductResults,
      };
      Object.keys(workpackageModules).forEach(moduleName => {
        dispatch(`${moduleName}/resetState`);
      });
    },
  },

  modules: {
    gridView,
    attributes,
    context,
    competitorMetadata,
    filters,
    hierarchy,
    scenarioMetadata,
    rules,
    penaltyFunctions,
    architectureGroup,
    architectureDrivers,
    workpackages,
    workpackageProducts,
    engineInputs,
    notifications,
    userProfiles,
    clientConfig,
    scenarioProducts,
    storeGroupRelationships,
    wholesale,
    wholesaleGrid,
    wholesaleProductResults,
  },

  plugins: [vuexLocalStorage.plugin],
});

export default store;
