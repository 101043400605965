<template>
  <span>
    <v-btn
      color="primary"
      small
      depressed
      class="pa-0"
      :disabled="wholesaleGridLoading || saveWholesaleGridLoading || isNewGrid"
      @click.stop="initiateModal()"
    >
      {{ $t('actions.upload') }}
      <v-icon small>$import</v-icon>
    </v-btn>
    <v-dialog v-model="uploadDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('wholesale.upload.dialogHeading') }}
        </v-card-title>
        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          :tool-store-group-independent="true"
          source="wholesale"
          @save="validateAndSave"
        />
        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    {{ $t('attributes.upload.confirmUpload') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" outlined @click="closeModal()">{{ $t('actions.cancel') }}</v-btn>
            <v-btn class="primary" :disabled="isUploading" @click="confirmUpload()">
              {{ $t('actions.upload') }}
            </v-btn>
          </v-card-actions>
        </div>
        <div v-if="isUploading">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">
                    {{ $t('attributes.upload.uploadingState') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
        <div v-if="isFailed">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <upload-error-card
                    v-if="uploadError.wrongHeaderFormat"
                    :error-message="$t('wholesale.upload.wrongHeaderFormat')"
                    unique-key-prefix="wrongHeaderFormat"
                  />
                  <upload-error-card
                    v-if="uploadError.incorrectStoreGroups.length"
                    :error-message="$t('wholesale.upload.incorrectStoreGroups')"
                    :details="uploadError.incorrectStoreGroups"
                    unique-key-prefix="incorrectStoreGroups"
                  />
                  <upload-error-card
                    v-if="uploadError.incorrectOwnBrandValues.length"
                    :error-message="$t('wholesale.upload.incorrectOwnBrandValues')"
                    :details="uploadError.incorrectOwnBrandValues"
                    unique-key-prefix="incorrectOwnBrandValues"
                  />
                  <upload-error-card
                    v-if="uploadError.invalidOffset.length"
                    :error-message="$t('wholesale.upload.invalidOffset')"
                    :details="uploadError.invalidOffset"
                    unique-key-prefix="invalidOffset"
                  />
                  <upload-error-card
                    v-if="uploadError.invalidGridValues.length"
                    :error-message="$t('wholesale.upload.invalidGridValues')"
                    :details="invalidGridValues"
                    unique-key-prefix="invalidGridValues"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import states from '@enums/upload-states';

export default {
  props: {
    isNewGrid: {
      type: Boolean,
      default: false,
    },
    gridId: {
      type: String,
    },
  },
  data() {
    return {
      uploadDialog: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
    };
  },
  computed: {
    ...mapState('wholesaleGrid', ['wholesaleGridLoading', 'saveWholesaleGridLoading']),
    isInitial() {
      return this.currentStatus === states.initial;
    },
    isSaving() {
      return this.currentStatus === states.saving;
    },
    isValidated() {
      return this.currentStatus === states.validated;
    },
    isFailed() {
      return this.currentStatus === states.failed;
    },
    isUploading() {
      return this.currentStatus === states.uploading;
    },
    invalidGridValues() {
      const invalidGridValues = this.uploadError.invalidGridValues;
      const formattedText = [];
      if (invalidGridValues.length) {
        invalidGridValues.forEach(combo => {
          const [header, value] = combo.split('::');
          formattedText.push(this.$t('wholesale.upload.invalidGridValueDetail', { header, value }));
        }, this);
      }
      return formattedText;
    },
  },
  methods: {
    ...mapActions('wholesaleGrid', ['validateWholesaleGrid', 'uploadWholesaleGridConfirm']),

    initiateModal() {
      this.uploadDialog = true;
      this.reset();
    },
    closeModal() {
      this.uploadDialog = false;
      this.reset();
    },
    validateAndSave(formData) {
      this.currentStatus = states.saving;
      // ensures we can upload regardless of language
      const translationMap = {
        storeGroupColumn: this.$t('wholesale.gridTable.toolStoreGroup'),
        offsetColumn: this.$t('wholesale.gridTable.offset'),
      };
      return this.validateWholesaleGrid({
        dataFile: formData,
        translationMap,
        gridId: this.gridId,
      })
        .then(() => {
          this.currentStatus = states.validated;
        })
        .catch(err => {
          this.uploadError = err;
          this.currentStatus = states.failed;
        });
    },
    confirmUpload() {
      this.currentStatus = states.uploading;
      return this.uploadWholesaleGridConfirm({ gridId: this.gridId })
        .then(() => {
          this.$emit('onUploaded');
          this.closeModal();
          this.reset();
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = states.failed;
        });
    },
    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}
</style>
