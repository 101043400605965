import { productKeyDisplayOption } from '@enums/filter-options';
import { filterOptionFieldMapping } from '@enums/attribute-filters';

const mixin = {
  methods: {
    getProductAttributesFilters() {
      return [
        {
          attributeKey: filterOptionFieldMapping[productKeyDisplayOption],
          attributeDescription: this.$t('pricing.productKey'),
          displayDescription: this.$t('pricing.productKey'),
          attributeFilterType: productKeyDisplayOption,
        },
      ];
    },
  },
};

export default mixin;
