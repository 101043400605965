import adminPage from './admin.vue';
import { canAccessDevAdminTabs } from '@enums/feature-flags';

const routes = [
  {
    path: '/admin',
    id: 'admin',
    omitFromNav: true,
    component: adminPage,
    linkText: 'navBar.admin',
    meta: { requiresRole: 'adminRoles', requiresRoleFromState: true },
    children: [
      // Default route to ensure that all '/admin' requests are redirected to the '/admin/user-roles'.
      {
        path: '',
        name: 'admin',
        redirect: () => {
          return {
            name: 'user-roles',
          };
        },
      },
      {
        path: 'user-roles',
        name: 'user-roles',
        meta: { requiresRole: 'adminRoles', requiresRoleFromState: true },
      },
      {
        path: 'feature-toggler',
        meta: {
          requiresRole: 'adminRoles',
          requiresRoleFromState: true,
          featureFlag: canAccessDevAdminTabs,
        },
        name: 'feature-toggler',
      },
      {
        path: 'diagnostics',
        meta: {
          requiresRole: 'adminRoles',
          requiresRoleFromState: true,
          featureFlag: canAccessDevAdminTabs,
        },
        name: 'diagnostics',
      },
      {
        path: 'translation-editor',
        meta: {
          requiresRole: 'adminRoles',
          requiresRoleFromState: true,
          featureFlag: canAccessDevAdminTabs,
        },
        name: 'translation-editor',
      },
      {
        path: 'audit',
        meta: {
          requiresRole: 'adminRoles',
          requiresRoleFromState: true,
          featureFlag: canAccessDevAdminTabs,
        },
        name: 'audit',
      },
    ],
  },
];

export default routes;
