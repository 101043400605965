import StatusPage from './status.vue';

const routes = [
  {
    path: '/status',
    name: 'status',
    id: 'status',
    component: StatusPage,
    omitFromNav: true,
  },
];

export default routes;
