<template>
  <v-menu v-model="open" :close-on-content-click="false" :max-width="400" :min-width="400" offset-x>
    <template v-slot:activator="{ on }">
      <span v-on="on">
        <v-icon size="1.4rem" :color="filterIconColor">
          filter_list
        </v-icon>
      </span>
    </template>
    <v-card>
      <v-card-title>
        <v-row class="mr-1">
          <v-col cols="11">
            <span>{{ `${$t('filters.filter')} ${$t(header.text)}` }}</span>
          </v-col>
          <v-col cols="1">
            <v-icon v-once color="grey" @click="closeMenu">close</v-icon>
          </v-col>
        </v-row>
      </v-card-title>
      <v-divider />
      <grid-view-filters-creator
        v-if="open"
        :column="header.text"
        :datatype="header.datatype"
        :path="header.valuePath"
        :unique-value-options="header.uniqueValueOptions"
      />
    </v-card>
  </v-menu>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState } = createNamespacedHelpers('gridView');

export default {
  props: {
    header: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      open: false,
    };
  },
  computed: {
    ...mapState(['activeTableFilters']),
    currentFilters() {
      return this.activeTableFilters.filter(filter => filter.path === this.header.valuePath);
    },
    filterIconColor() {
      return this.currentFilters.length > 0 ? 'blue' : 'grey';
    },
  },

  methods: {
    closeMenu() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.v-card {
  .header-text {
    padding-top: 2rem;
    font-size: 2rem;
    color: black;
  }
  font-size: 1.5rem;
}
.v-btn {
  font-size: 1.5rem;
}
</style>
