import LoginPage from './login.vue';

const routes = [
  {
    path: '/login',
    name: 'login',
    id: 'login',
    component: LoginPage,
    meta: { hideNavbar: true, title: 'Login' },
    omitFromNav: true,
  },
];

export default routes;
