/*
These are the default Oliver Wyman palette colours (taken from Powerpoint)
Please do not over-write these colours when skinning tools for clients.
Instead create new colour names and add them to the bottom.
You can use the client's logo and the following resources to derive colour values:
    - http://www.palettefx.com/ (image to colours)
    - http://www.colorhexa.com/ (colour variations)
*/
const darkSapphire = '#002C77';
const owSapphire = '#008AB3';
const lightSapphire = '#9DE0ED';
const owOnyx = '#5F5F5F';
const lightOnyx = '#BEBEBE';
const veryLightOnyx = '#DFDFDF';
const owTopaz = '#E29815';
const lightTopaz = '#FFCF89';
const owEmerald = '#41A441';
const lightEmerald = '#BDDDA3';
const owIolite = '#646EAC';
const lightIolite = '#C5CAE7';
const owCitrine = '#DD712C';
const lightCitrine = '#FDCFAC';
const owTurquoise = '#079B84';
const lightTurquoise = '#A8DAC9';
const owRuby = '#CB225B';
const lightRuby = '#F8B8BC';
const owWhite = '#FFFFFF';

// pricing specific colours
const pricingGreyLight = '#F3F3F3';
const pricingGreyDark = '#808080';
const pricingBlueLight = '#DDF2FB';
const pricingBluePrimary = '#2F477C';
const pricingRedPrimary = '#D0021B';
const pricingYellowPrimary = '#f3cc92';
const pricingGreenPrimary = '#6da54d';
const pricingTealPrimary = '#339087';

// matching colours in webtool/client/style/base/_variable.scss
const pricingEconomyColour = '#BCC72F';
const pricingCompetitionColour = '#2CB498';
const pricingArchitectureColour = '#9C79C8';
const pricingStoreGroupColour = '#3F7696';
const pricingChartScatter = '#00A0E2';

const pricingGridLineColor = '#9B9B9B';
const tensionChartPositiveColor = '#6ECADB';
const tensionChartNegativeColor = '#6B8C9F';
const tensionPlotLineColor = '#000';

// colours in settings pages
const competitorTrafficLightColor = '#00AC92';
const architectureTrafficLightColor = '#9E79C5';
const pricePointingTrafficLightColor = '#BE2A71';
const economicTrafficLightColor = '#BCC743';
const storeGroupTrafficLightColor = '#3F7696';
const otherTrafficLightColor = '#C2C2C2';
const inactiveTrafficLightColor = '#F0F0F0';

// colours for alerts
const alertsBackground = '#723431';
const alertsIconColor = '#D7534E';

const rulesetDarkBlue = '#29477A';
const scopeHighlightBlue = '#006aa7';
const scopeHighlightRed = '#bf4c4f';
const paramsFormBackgroundColor = '#e7e7e7';

const rulesetBackgroundGrey = '#d2dee6';
const rulesetLightBlue = '#2F477C';

const penaltiesLightGreen = '#68BE4F';
const penaltiesScatterTextColour = '#000';

const pricingPrimary = '#2F477C';

const buttonSpacerGrey = '#D8D8D8';

const successGreen = '#4CAF50';
const catManGreen = '#8BC34A';

const wholesaleChartBlue = '#080AAE';
const wholesaleChartBrown = '#7E5D00';
const wholesaleChartBlack = '#000000';
const wholesaleChartLightBlue = '#9C9DDF';
const wholesaleChartLightBrown = '#BEAE7E';
const wholesaleChartLightBlack = '#949494';
const wholesaleChartTitleColor = '#37424A';
const wholesaleChartLegendTitleColor = '#4A4A4A';
const wholesaleChartRed = '#b90d00';
const wholesaleChartLightRed = '#ea9999';
const wholesaleChartGreen = '#6aa84f';
const wholesaleChartLightGreen = '#bde9aa';

export default {
  darkSapphire,
  owSapphire,
  lightSapphire,
  owOnyx,
  lightOnyx,
  veryLightOnyx,
  owTopaz,
  lightTopaz,
  owEmerald,
  lightEmerald,
  owIolite,
  lightIolite,
  owCitrine,
  lightCitrine,
  owTurquoise,
  lightTurquoise,
  owRuby,
  lightRuby,
  owWhite,
  pricingGreyDark,
  pricingGreyLight,
  pricingBlueLight,
  pricingBluePrimary,
  pricingRedPrimary,
  pricingYellowPrimary,
  pricingGreenPrimary,
  pricingTealPrimary,

  pricingEconomyColour,
  pricingCompetitionColour,
  pricingArchitectureColour,
  pricingStoreGroupColour,
  pricingGridLineColor,
  pricingChartScatter,

  tensionChartPositiveColor,
  tensionChartNegativeColor,
  tensionPlotLineColor,

  competitorTrafficLightColor,
  architectureTrafficLightColor,
  pricePointingTrafficLightColor,
  economicTrafficLightColor,
  storeGroupTrafficLightColor,
  otherTrafficLightColor,
  inactiveTrafficLightColor,
  paramsFormBackgroundColor,

  rulesetDarkBlue,
  scopeHighlightBlue,
  scopeHighlightRed,

  rulesetBackgroundGrey,
  rulesetLightBlue,

  alertsBackground,
  alertsIconColor,

  penaltiesLightGreen,
  penaltiesScatterTextColour,

  orderedChartColors: [
    owSapphire,
    lightSapphire,
    owOnyx,
    lightOnyx,
    owTopaz,
    lightTopaz,
    owEmerald,
    lightEmerald,
    owIolite,
    lightIolite,
    owCitrine,
    lightCitrine,
    owTurquoise,
    lightTurquoise,
    owRuby,
    lightRuby,
  ],

  pricingPrimary,
  buttonSpacerGrey,
  successGreen,
  catManGreen,

  wholesaleChartBlue,
  wholesaleChartBrown,
  wholesaleChartBlack,
  wholesaleChartLightBlue,
  wholesaleChartLightBrown,
  wholesaleChartLightBlack,
  wholesaleChartTitleColor,
  wholesaleChartLegendTitleColor,
  wholesaleChartRed,
  wholesaleChartLightRed,
  wholesaleChartGreen,
  wholesaleChartLightGreen,
};
