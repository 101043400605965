<template>
  <div>
    <basic-spinner v-if="loadingMonthlyAggregations" class="spinner-wrapper" />

    <div class="d-flex">
      <span class="root"> {{ $t('wholesale.graphTypeTitle') }}:</span>
      <v-select
        :value="selectedGraph"
        :items="graphType"
        :loading="loadingMonthlyAggregations"
        :disabled="loadingMonthlyAggregations"
        class="label-part grid-dropdown"
        @change="setSelectedGraph($event)"
      />
      <v-select
        :value="selectedDataRepresentation"
        :items="graphDataRepresentation"
        :loading="loadingMonthlyAggregations"
        :disabled="loadingMonthlyAggregations"
        class="label-part grid-dropdown"
        @change="setSelectedGraphDataRepresentation($event)"
      />
    </div>

    <div v-if="dataAvailable">
      <v-flex xs9>
        <highcharts ref="highcharts" class="highchart" :options="selectedOptions" />
      </v-flex>
    </div>
    <div v-if="!dataAvailable && !loadingMonthlyAggregations" class="no-data">
      {{ $t('product.noData') }}
    </div>
    <div class="d-flex justify-end pa-3">
      <v-btn
        color="primary"
        small
        :disabled="!dataAvailable"
        :loading="downloadingItems"
        depressed
        @click="downloadExtract"
      >
        {{ $t('actions.download') }}
        <v-icon small>$export</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { isEmpty, merge, sum, range, each, toUpper, reduce } from 'lodash';
import moment from 'moment';
import colors from '../../../ow-colors';
import numberFormats from '@enums/number-formats';
import { categoryLevel } from '@enums/hierarchy';

const thisYear = moment().format('Y');
const numOfMonthsInPast = parseInt(moment().format('M'), 10) - 1;
const zonesColors = {
  retailMargin: {
    zoneAxis: 'x',
    zones: [
      {
        value: numOfMonthsInPast,
        color: colors.wholesaleChartBlack,
      },
      {
        color: colors.wholesaleChartLightBlack,
      },
    ],
  },
  wholesaleMargin: {
    zoneAxis: 'x',
    zones: [
      {
        value: numOfMonthsInPast,
        color: colors.wholesaleChartRed,
      },
      {
        color: colors.wholesaleChartLightRed,
      },
    ],
  },
  affiliateMargin: {
    zoneAxis: 'x',
    zones: [
      {
        value: numOfMonthsInPast,
        color: colors.wholesaleChartGreen,
      },
      {
        color: colors.wholesaleChartLightGreen,
      },
    ],
  },
};

export default {
  data() {
    return {
      graphType: [
        { value: 'month-by-month', text: this.$t('wholesale.wholesaleChart.monthByMonth') },
        { value: 'cumulative', text: this.$t('wholesale.wholesaleChart.cumulative') },
      ],
      graphDataRepresentation: [
        { value: 'percentage', text: this.$t('wholesale.wholesaleChart.percentage') },
        { value: 'absolute', text: this.$t('wholesale.wholesaleChart.absolute') },
      ],
      selectedOptions: {},
      selectedGraph: 'month-by-month',
      selectedDataRepresentation: 'percentage',
    };
  },

  computed: {
    ...mapState('wholesaleProductResults', [
      'loadingMonthlyAggregations',
      'monthlyAggregations',
      'downloadingItems',
    ]),
    dataAvailable() {
      return !this.loadingMonthlyAggregations && !isEmpty(this.monthlyAggregations);
    },

    // GRAPH 1 : Line chart of the month by month margin percentage (%)
    monthlyChartSeries() {
      if (!isEmpty(this.monthlyAggregations)) {
        const monthlyData = this.monthlyAggregations[0];
        return [
          {
            name: this.$t('wholesale.wholesaleChart.monthlyRetailMarginPercent'),
            data: monthlyData.monthlyRetailMarginPercent.map(x => 100 * x),
            ...zonesColors.retailMargin,
            yAxis: 1,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyWholesaleMarginPercent'),
            data: monthlyData.monthlyWholesaleMarginPercent.map(x => 100 * x),
            ...zonesColors.wholesaleMargin,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyAffiliateMarginPercent'),
            data: monthlyData.monthlyAffiliateMarginPercent.map(x => 100 * x),
            ...zonesColors.affiliateMargin,
          },
        ];
      }
      return [];
    },

    // GRAPH 2 : cumulative sum of the month by month margin absolute values (k)
    monthlyCumulativeChartSeries() {
      if (!isEmpty(this.monthlyAggregations)) {
        const monthlyData = this.monthlyAggregations[0];
        return [
          {
            name: this.$t('wholesale.wholesaleChart.monthlyRetailMargin'),
            data: this.getCumulativeValues(monthlyData.monthlyRetailMargin).map(x => x / 1000),
            ...zonesColors.retailMargin,
            yAxis: 1,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyWholesaleMargin'),
            data: this.getCumulativeValues(monthlyData.monthlyWholesaleMargin).map(x => x / 1000),
            ...zonesColors.wholesaleMargin,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyAffiliateMargin'),
            data: this.getCumulativeValues(monthlyData.monthlyAffiliateMargin).map(x => x / 1000),
            ...zonesColors.affiliateMargin,
          },
        ];
      }
      return [];
    },

    // GRAPH 3 : Line chart of the month by month margin absolute (k)
    monthlyChartAbsoluteSeries() {
      if (!isEmpty(this.monthlyAggregations)) {
        const monthlyData = this.monthlyAggregations[0];
        return [
          {
            name: this.$t('wholesale.wholesaleChart.monthlyRetailMargin'),
            data: monthlyData.monthlyRetailMargin.map(x => x / 1000),
            ...zonesColors.retailMargin,
            yAxis: 1,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyWholesaleMargin'),
            data: monthlyData.monthlyWholesaleMargin.map(x => x / 1000),
            ...zonesColors.wholesaleMargin,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyAffiliateMargin'),
            data: monthlyData.monthlyAffiliateMargin.map(x => x / 1000),
            ...zonesColors.affiliateMargin,
          },
        ];
      }
      return [];
    },

    // Graph 4: cumulative sum of the month by month margin percentage (%)
    monthlyCumulativeChartPercentSeries() {
      if (!isEmpty(this.monthlyAggregations)) {
        const monthlyData = this.monthlyAggregations[0];
        return [
          {
            name: this.$t('wholesale.wholesaleChart.monthlyRetailMargin'),
            data: this.getCumulativeValuesPercentage(
              monthlyData.monthlyRetailMargin,
              monthlyData.monthlyAffiliateSales
            ).map(x => 100 * x),
            ...zonesColors.retailMargin,
            yAxis: 1,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyWholesaleMargin'),
            data: this.getCumulativeValuesPercentage(
              monthlyData.monthlyWholesaleMargin,
              monthlyData.monthlyWholesaleSales
            ).map(x => 100 * x),
            ...zonesColors.wholesaleMargin,
          },
          {
            name: this.$t('wholesale.wholesaleChart.monthlyAffiliateMargin'),
            data: this.getCumulativeValuesPercentage(
              monthlyData.monthlyAffiliateMargin,
              monthlyData.monthlyAffiliateSales
            ).map(x => 100 * x),
            ...zonesColors.affiliateMargin,
          },
        ];
      }
      return [];
    },

    wholesaleChartDefaultOptions() {
      return {
        chart: {
          height: 400,
          width: 1200,
        },
        legend: {
          enabled: true,
          title: {
            text: `<span style="height: 16px; width: 43px; color: ${
              colors.wholesaleChartLegendTitleColor
            }; font-size: 12px; letter-spacing: 0; line-height: 16px;"> Margins </span>`,
          },
          align: 'right',
          verticalAlign: 'top',
          layout: 'vertical',
          x: 0,
          y: 100,
        },
        // for colors in the legend
        colors: [colors.wholesaleChartBlack, colors.wholesaleChartRed, colors.wholesaleChartGreen],
        title: {
          style: {
            height: '17px',
            width: '131px',
            color: colors.wholesaleChartTitleColor,
            'font-size': '13px',
            'letter-spacing': 0,
            'line-height': '17px',
          },
          align: 'left',
        },
        xAxis: {
          categories: this.$t('monthAbbreviations'),
          lineWidth: 1,
        },
        yAxis: [
          {
            min: 0,
            gridLineWidth: 1,
            gridLineColor: colors.paramsFormBackgroundColor,
          },
          {
            min: 0,
            gridLineWidth: 1,
            gridLineColor: colors.paramsFormBackgroundColor,
            opposite: true,
          },
        ],
        plotOptions: {
          series: {
            animation: false,
            lineWidth: 2,
            shadow: false,
            states: {
              hover: {
                enabled: false,
                lineWidthPlus: 0,
              },
              inactive: {
                // inactive lines should have the same opacity as active
                opacity: 1,
              },
            },
            marker: {
              enabled: false,
            },
            fillOpacity: 0,
          },
        },
      };
    },

    // Graph 1
    monthlyChartOptions() {
      const vm = this;
      return merge({}, vm.wholesaleChartDefaultOptions, {
        series: this.monthlyChartSeries,
        title: {
          text: this.$t('wholesale.wholesaleChart.monthlyChartTitle', { thisYear }),
        },
        yAxis: [
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleLeftSidePercentage'),
            },
          },
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleRightSidePercentage'),
            },
          },
        ],
        tooltip: {
          enabled: true,
          formatter() {
            return `<b>${this.series.name}</b> - ${this.x} ${thisYear}<br/><b>${vm.formatNumber({
              // the value was multiplied by 100 for yAxis labels, convert it back
              number: this.y / 100,
              format: numberFormats.percent,
            })}</b>`;
          },
        },
      });
    },

    // Graph 2
    monthlyCumulativeAbsoluteChartOptions() {
      const vm = this;
      return merge({}, vm.wholesaleChartDefaultOptions, {
        series: this.monthlyCumulativeChartSeries,
        title: {
          text: this.$t('wholesale.wholesaleChart.monthlyCumulativeChartTitleAbsolute', {
            thisYear,
          }),
        },
        yAxis: [
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleLeftSideThousand'),
            },
          },
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleRightSideThousand'),
            },
          },
        ],
        tooltip: {
          enabled: true,
          formatter() {
            return `<b>${this.series.name}</b> - ${this.x} ${thisYear}<br/><b>${vm.formatNumber({
              number: this.y,
              format: numberFormats.priceFormat,
            })}</b>`;
          },
        },
      });
    },

    // Graph 3
    monthlyChartOptionsAbsolute() {
      const vm = this;
      return merge({}, vm.wholesaleChartDefaultOptions, {
        series: this.monthlyChartAbsoluteSeries,
        title: {
          text: this.$t('wholesale.wholesaleChart.monthlyChartTitleAbsolute', { thisYear }),
        },
        yAxis: [
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleLeftSideThousand'),
            },
          },
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleRightSideThousand'),
            },
          },
        ],
        tooltip: {
          enabled: true,
          formatter() {
            return `<b>${this.series.name}</b> - ${this.x} ${thisYear}<br/><b>${vm.formatNumber({
              number: this.y,
              format: numberFormats.priceFormat,
            })}</b>`;
          },
        },
      });
    },

    // Graph 4
    monthlyCumulativeChartPercentageOptions() {
      const vm = this;
      return merge({}, vm.wholesaleChartDefaultOptions, {
        series: this.monthlyCumulativeChartPercentSeries,
        title: {
          text: this.$t('wholesale.wholesaleChart.monthlyCumulativeChartTitle', {
            thisYear,
          }),
        },
        yAxis: [
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleLeftSidePercentage'),
            },
          },
          {
            title: {
              text: this.$t('wholesale.wholesaleChart.AxisTitleRightSidePercentage'),
            },
          },
        ],
        tooltip: {
          enabled: true,
          formatter() {
            return `<b>${this.series.name}</b> - ${this.x} ${thisYear}<br/><b>${vm.formatNumber({
              // the value was multiplied by 100 for yAxis labels, convert it back
              number: this.y / 100,
              format: numberFormats.percent,
            })}</b>`;
          },
        },
      });
    },
  },

  async created() {
    await this.fetchMonthlyAggregations();
    // set default options
    this.selectedOptions = this.monthlyChartOptions;
  },

  methods: {
    ...mapActions('wholesaleProductResults', [
      'fetchMonthlyAggregations',
      'downloadMonthlyAggregations',
    ]),

    updateGraph() {
      if (this.selectedGraph === 'month-by-month') {
        // Graph 1: Line chart of the month by month margin percentage (%) or
        // Graph 3: Line chart of the month by month margin absolute (k)
        this.selectedOptions =
          this.selectedDataRepresentation === 'percentage'
            ? this.monthlyChartOptions
            : this.monthlyChartOptionsAbsolute;
      } else {
        // Graph 4: Cumulative sum of the month by month margin percentage (%) or
        // Graph 2: Cumulative sum of the month by month margin absolute values (k)
        this.selectedOptions =
          this.selectedDataRepresentation === 'percentage'
            ? this.monthlyCumulativeChartPercentageOptions
            : this.monthlyCumulativeAbsoluteChartOptions;
      }
    },

    setSelectedGraph(selected) {
      this.selectedGraph = selected;
      this.updateGraph();
    },
    setSelectedGraphDataRepresentation(selected) {
      this.selectedDataRepresentation = selected;
      this.updateGraph();
    },
    getCumulativeValues(dataArray) {
      return range(dataArray.length).map(ix => sum(dataArray.slice(0, ix + 1)));
    },
    getCumulativeValuesPercentage(dataArrayA, dataArrayB) {
      const cumulativeA = this.getCumulativeValues(dataArrayA);
      const cumulativeB = this.getCumulativeValues(dataArrayB);

      // divide each element in arrayA by arrayB element
      return cumulativeA.map(function(n, i) {
        return n / cumulativeB[i];
      });
    },

    downloadExtract() {
      const translationKeys = ['categoryName', 'year', 'month'];
      const currencyTranslationKeys = [
        'expeditionsAtCostPrice',
        'expeditionsAtWholesalePrice',
        'expeditionsAtRetailPrice',
      ];
      const currencyTranslations = this.getTranslations(currencyTranslationKeys);
      this.downloadMonthlyAggregations({
        translationMap: { ...this.getTranslations(translationKeys), ...currencyTranslations },
        aggregationLevel: categoryLevel,
        columnFormatters: this.getFormatters(currencyTranslations),
      });
    },

    getTranslations(keys) {
      const translationMap = {};
      each(keys, k => {
        translationMap[k] = toUpper(this.$t(`wholesale.wholesaleChart.exportHeaders.${k}`));
      });
      return translationMap;
    },

    getFormatters(columns) {
      return reduce(
        columns,
        (acc, c) => {
          acc[c] = { type: numberFormats.currency, format: '#,##0.00' };
          return acc;
        },
        {}
      );
    },
  },
};
</script>

<style scoped lang="scss">
.no-data {
  font-size: 12px;
  margin-left: 1.5rem;
}
</style>
