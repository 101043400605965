<template>
  <v-progress-circular
    :size="40"
    :width="4"
    color="primary"
    :value="progressValue"
    :indeterminate="indeterminate"
  >
    {{ progressText }}
  </v-progress-circular>
</template>

<script>
import { get, last } from 'lodash';
import { mapGetters } from 'vuex';

export default {
  props: {
    jobId: {
      required: true,
      type: String,
    },
    jobType: {
      required: true,
      type: String,
    },
  },
  computed: {
    ...mapGetters('notifications', ['progressNotifications']),
    progressValue() {
      const lastProgressNotification = last(this.progressNotifications);
      const lastProgressValue = get(lastProgressNotification, 'payload', null);
      if (lastProgressValue !== null) {
        const lastProgressId = get(lastProgressNotification, 'jobId', '');
        const lastProgressType = get(lastProgressNotification, 'jobType', '');
        if (lastProgressId === this.jobId && lastProgressType === this.jobType)
          return Math.floor(lastProgressValue);
      }

      return 0;
    },
    indeterminate() {
      return [null, 0].includes(this.progressValue);
    },
    progressText() {
      if (this.indeterminate) return '';

      return `${this.progressValue}%`;
    },
  },
};
</script>
