import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { "pa-2": "", "pb-0": "" } },
    [
      _c(
        VLayout,
        { attrs: { "justify-space-between": "" } },
        [
          _c("h3", [
            _vm._v(_vm._s(_vm.$t("pricing.penaltyFunctions.heading"))),
          ]),
          _vm._v(" "),
          _c(
            "feature-toggle",
            { attrs: { toggle: _vm.displayRulesListView } },
            [
              _c(
                VItemGroup,
                { staticClass: "pb-2" },
                [
                  _c(
                    VBtn,
                    {
                      class: _vm.isGraphView
                        ? "toggle-btn-active"
                        : "toggle-btn-inactive",
                      attrs: { "x-small": "", tile: "", depressed: "" },
                      on: { click: _vm.toggleView },
                    },
                    [_c(VIcon, [_vm._v("show_chart")])],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      class: !_vm.isGraphView
                        ? "toggle-btn-active"
                        : "toggle-btn-inactive",
                      attrs: { "x-small": "", tile: "", depressed: "" },
                      on: { click: _vm.toggleView },
                    },
                    [_c(VIcon, [_vm._v("list")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm.emptyScenarioResults && _vm.isGraphView
        ? _c(VLayout, [
            _c("i", [
              _vm._v(_vm._s(_vm.$t("pricing.penaltyFunctions.noData"))),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.emptyScenarioResults && _vm.isGraphView
        ? _c(
            VLayout,
            { attrs: { row: "" } },
            [
              _c(
                VFlex,
                { attrs: { xs9: "" } },
                [_c("highcharts", { attrs: { options: _vm.chartOptions } })],
                1
              ),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs3: "", "category-container": "" } },
                [
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCategory,
                        expression: "selectedCategory",
                      },
                    ],
                    attrs: {
                      id: "economic",
                      type: "radio",
                      name: "penalty-toggle",
                    },
                    domProps: {
                      value: _vm.categories.economic,
                      checked: _vm._q(
                        _vm.selectedCategory,
                        _vm.categories.economic
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedCategory = _vm.categories.economic
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "economic", attrs: { for: "economic" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("pricing.penaltyFunctions.type.economic")
                          ) +
                          ":\n        "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number:
                                  _vm.scenarioResults.economicReferencePrice,
                                format: _vm.numberFormats.priceFormat,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCategory,
                        expression: "selectedCategory",
                      },
                    ],
                    attrs: {
                      id: "competitor",
                      type: "radio",
                      name: "penalty-toggle",
                    },
                    domProps: {
                      value: _vm.categories.competitor,
                      checked: _vm._q(
                        _vm.selectedCategory,
                        _vm.categories.competitor
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedCategory = _vm.categories.competitor
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "competition",
                      attrs: { for: "competitor" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("pricing.penaltyFunctions.type.competition")
                          ) +
                          ":\n        "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number:
                                  _vm.scenarioResults.competitorReferencePrice,
                                format: _vm.numberFormats.priceFormat,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCategory,
                        expression: "selectedCategory",
                      },
                    ],
                    attrs: {
                      id: "architecture",
                      type: "radio",
                      name: "penalty-toggle",
                    },
                    domProps: {
                      value: _vm.categories.architecture,
                      checked: _vm._q(
                        _vm.selectedCategory,
                        _vm.categories.architecture
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedCategory = _vm.categories.architecture
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    {
                      staticClass: "architecture",
                      attrs: { for: "architecture" },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("pricing.penaltyFunctions.type.architecture")
                          ) +
                          ":\n        "
                      ),
                      _c("span", [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number:
                                  _vm.scenarioResults
                                    .architectureReferencePrice,
                                format: _vm.numberFormats.priceFormat,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n        "
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c("br"),
                  _vm._v(" "),
                  _c(
                    "feature-toggle",
                    { attrs: { toggle: _vm.displayStoreGroupPenalty } },
                    [
                      _c("input", {
                        directives: [
                          {
                            name: "model",
                            rawName: "v-model",
                            value: _vm.selectedCategory,
                            expression: "selectedCategory",
                          },
                        ],
                        attrs: {
                          id: "storeGroup",
                          type: "radio",
                          name: "penalty-toggle",
                        },
                        domProps: {
                          value: _vm.categories.storeGroup,
                          checked: _vm._q(
                            _vm.selectedCategory,
                            _vm.categories.storeGroup
                          ),
                        },
                        on: {
                          change: function ($event) {
                            _vm.selectedCategory = _vm.categories.storeGroup
                          },
                        },
                      }),
                      _vm._v(" "),
                      _c(
                        "label",
                        {
                          staticClass: "store-group",
                          attrs: { for: "storeGroup" },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "pricing.penaltyFunctions.type.storeGroup"
                                )
                              ) +
                              ":\n          "
                          ),
                          _c("span", [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.formatNumber({
                                    number:
                                      _vm.scenarioResults
                                        .storeGroupReferencePrice,
                                    format: _vm.numberFormats.priceFormat,
                                    zeroAsDash: true,
                                  })
                                ) +
                                "\n          "
                            ),
                          ]),
                        ]
                      ),
                      _vm._v(" "),
                      _c("br"),
                    ]
                  ),
                  _vm._v(" "),
                  _c("input", {
                    directives: [
                      {
                        name: "model",
                        rawName: "v-model",
                        value: _vm.selectedCategory,
                        expression: "selectedCategory",
                      },
                    ],
                    attrs: {
                      id: "combined",
                      type: "radio",
                      name: "penalty-toggle",
                    },
                    domProps: {
                      value: _vm.categories.combined,
                      checked: _vm._q(
                        _vm.selectedCategory,
                        _vm.categories.combined
                      ),
                    },
                    on: {
                      change: function ($event) {
                        _vm.selectedCategory = _vm.categories.combined
                      },
                    },
                  }),
                  _vm._v(" "),
                  _c(
                    "label",
                    { staticClass: "combined", attrs: { for: "combined" } },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("pricing.penaltyFunctions.type.combined")
                          ) +
                          ":\n        "
                      ),
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.getCombinedPrice(_vm.scenarioResults))
                        ),
                      ]),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.isGraphView
        ? _c("rules-list-view", {
            style: _vm.rulesListStyles,
            attrs: {
              "scenario-key": _vm.scenarioKey,
              "tool-store-group-key": _vm.toolStoreGroupKey,
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }