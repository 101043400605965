import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VSnackbar,
        {
          staticClass: "snackbar-text",
          attrs: { top: "", timeout: _vm.ruleSetPopupTimeout },
          model: {
            value: _vm.snackbar,
            callback: function ($$v) {
              _vm.snackbar = $$v
            },
            expression: "snackbar",
          },
        },
        [
          _vm._v(
            "\n    " +
              _vm._s(
                _vm.$t("settings.penaltyRules.settingAllocationSuccessText")
              ) +
              "\n    "
          ),
          _c(
            VBtn,
            {
              attrs: { dark: "", text: "", depressed: "" },
              on: {
                click: function ($event) {
                  _vm.snackbar = false
                },
              },
            },
            [_vm._v("\n      " + _vm._s(_vm.$t("actions.close")) + "\n    ")]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _vm._l(_vm.rulesets, function (ruleset, rulesetType) {
        return _c(
          "div",
          { key: `ruleset-${rulesetType}`, staticClass: "ruleset-container" },
          [
            ruleset.meta.showActiveHeader
              ? _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      {
                        staticClass: "heading-bar mb-4",
                        attrs: { cols: "12" },
                      },
                      [
                        _c(
                          "div",
                          {
                            staticClass:
                              "heading-bar-heading d-inline-flex pr-3",
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(
                                  _vm.$t("settings.penaltyRules.activeRulesets")
                                ) +
                                "\n        "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c(
                          "feature-toggle",
                          {
                            staticClass:
                              "d-inline-flex pr-3 store-group-filter",
                            attrs: { toggle: _vm.useZones },
                          },
                          [
                            _c(VAutocomplete, {
                              attrs: {
                                multiple: "",
                                dense: "",
                                solo: "",
                                flat: "",
                                "small-chips": "",
                                "hide-details": "",
                                items: _vm.selectedWorkpackageToolStoreGroups,
                                "item-value": "key",
                                "item-text": "description",
                                disabled:
                                  !_vm.selectedWorkpackageToolStoreGroups,
                                label: _vm.$t(
                                  "settings.penaltyRules.selectStoreGroup"
                                ),
                                color: "indigo darken-2",
                                "search-input": _vm.searchInput,
                                loading:
                                  _vm.loadingSelectedWorkpackageToolStoreGroups,
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.searchInput = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.searchInput = $event
                                },
                                change: _vm.reset,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "selection",
                                    fn: function ({ item, selected }) {
                                      return [
                                        _c(
                                          VChip,
                                          {
                                            staticClass: "chip--select",
                                            attrs: {
                                              "input-value": selected,
                                              close: "",
                                            },
                                            on: {
                                              "click:close": function ($event) {
                                                return _vm.deselect(item)
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(item.description) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "no-data",
                                    fn: function () {
                                      return [
                                        !_vm.loadingSelectedWorkpackageToolStoreGroups
                                          ? _c(
                                              "div",
                                              [
                                                _c(
                                                  VAlert,
                                                  {
                                                    attrs: {
                                                      value: true,
                                                      color: "error",
                                                      icon: "warning",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "attributes.filters.noData"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            )
                                          : _c(
                                              "div",
                                              [
                                                _c(
                                                  VAlert,
                                                  {
                                                    attrs: {
                                                      value: true,
                                                      color: "info",
                                                      icon: "info",
                                                      outlined: "",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                  " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "general.loadingMessage"
                                                          )
                                                        ) +
                                                        "\n                "
                                                    ),
                                                  ]
                                                ),
                                              ],
                                              1
                                            ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                  {
                                    key: "item",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "span",
                                          { staticClass: "item-text" },
                                          [
                                            _vm._v(
                                              "\n                " +
                                                _vm._s(props.item.description) +
                                                "\n              "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                ],
                                null,
                                true
                              ),
                              model: {
                                value: _vm.selectedToolStoreGroups,
                                callback: function ($$v) {
                                  _vm.selectedToolStoreGroups = $$v
                                },
                                expression: "selectedToolStoreGroups",
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c("div", { staticClass: "d-inline-flex" }, [
                          _vm._v(
                            _vm._s(_vm.$t("settings.penaltyRules.addRuleText"))
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            ruleset.meta.showInactiveHeader
              ? _c(
                  VRow,
                  [
                    _c(
                      VCol,
                      { staticClass: "heading-bar", attrs: { cols: "12" } },
                      [
                        _c("div", { staticClass: "heading-bar-heading" }, [
                          _vm._v(
                            _vm._s(
                              _vm.$t("settings.penaltyRules.inactiveRulesets")
                            )
                          ),
                        ]),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            !ruleset.meta.showInactiveHeader
              ? _c(
                  VRow,
                  { key: `header-${rulesetType}` },
                  [
                    _c(
                      VCol,
                      { staticClass: "rule-heading", attrs: { cols: "12" } },
                      [
                        _c(
                          "div",
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t(ruleset.displayName)) +
                                "\n          "
                            ),
                            _vm.scenarioKey &&
                            _vm.canEditRules &&
                            ruleset.meta.isEditable
                              ? _c("plus-button", {
                                  staticClass: "d-inline-flex pl-2",
                                  on: {
                                    clickFunction: function ($event) {
                                      return _vm.startRuleCreation(rulesetType)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
            _vm._v(" "),
            _c(
              "draggable",
              {
                key: rulesetType,
                class: [
                  "draggable-element",
                  { opaque: _vm.isDraggableDisabled(rulesetType) },
                ],
                attrs: {
                  group: "draggable-lists",
                  list: ruleset.rules,
                  handle: ".rule-draggable-icon",
                  disabled:
                    !_vm.canEditRules ||
                    !ruleset.meta.isEditable ||
                    _vm.isDraggableDisabled(rulesetType),
                },
                on: {
                  start: function ($event) {
                    return _vm.setDragAndDropServiceData($event, ruleset)
                  },
                  change: _vm.handleDragChange,
                  choose: _vm.turnOffExpanded,
                  end: _vm.resetDragAndDropServiceData,
                },
              },
              [
                _vm._l(ruleset.rules, function (rule, ruleIndex) {
                  return _c(
                    VLayout,
                    {
                      key: rule._id,
                      staticClass: "ruleset",
                      attrs: { "data-ruleType": rule.ruleType },
                    },
                    [
                      ruleset.meta.showIndex
                        ? _c(VFlex, { staticClass: "rule-index" }, [
                            _c("span", [
                              _vm._v(
                                _vm._s(
                                  _vm.computePriority(rule.ruleType, ruleIndex)
                                )
                              ),
                            ]),
                          ])
                        : _c(VFlex, { staticClass: "no-index" }),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        {
                          staticClass: "rule-container",
                          class: ruleset.classes,
                        },
                        [
                          _c("rule-content", {
                            attrs: {
                              "ruleset-type": rulesetType,
                              "rule-index": ruleIndex,
                              rule: rule,
                              "product-counts":
                                _vm.productCounts[rule._id] || [],
                              "expanded-key": _vm.expandedKey,
                              disabled:
                                !_vm.canEditRules || !ruleset.meta.isEditable,
                            },
                            on: {
                              updateRule: _vm.updateSingleRule,
                              setExpandedKey: _vm.setExpandedKey,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        {
                          directives: [
                            {
                              name: "show",
                              rawName: "v-show",
                              value: ruleset.meta.isEditable,
                              expression: "ruleset.meta.isEditable",
                            },
                          ],
                          staticClass: "rule-draggable-icon",
                        },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "drag-icon text-xs-right",
                              attrs: { disabled: !_vm.canEditRules },
                            },
                            [_vm._v(" open_with ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                }),
                _vm._v(" "),
                _vm.showDragRuleInfo(ruleset)
                  ? _c(
                      VLayout,
                      { staticClass: "ruleset" },
                      [
                        _c(VFlex, { staticClass: "rule-drag-here" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("settings.penaltyRules.dragRuleInfo")
                              ) +
                              "\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _c(VFlex),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.shouldDisplayNewRule(rulesetType)
                  ? _c(
                      VLayout,
                      { staticClass: "ruleset" },
                      [
                        _c(VFlex, { staticClass: "rule-index" }, [
                          _c("span", [
                            _vm._v(_vm._s(_vm.newRuleIndex(rulesetType))),
                          ]),
                        ]),
                        _vm._v(" "),
                        _c(
                          VFlex,
                          {
                            staticClass: "rule-container",
                            class: ruleset.classes,
                          },
                          [
                            _vm.isNewRuleCreation
                              ? _c("rule-content", {
                                  staticClass: "expanded-top",
                                  attrs: {
                                    mode: _vm.creationMode,
                                    "ruleset-type": rulesetType,
                                    "rule-index": _vm.newRuleIndex(rulesetType),
                                    rule: _vm.mutableDefaultRule,
                                    "expanded-key": _vm.expandedKey,
                                  },
                                  on: {
                                    saveNewRule: _vm.saveNewRuleHandler,
                                    cancelNewRule: function ($event) {
                                      return _vm.cancelNewRuleCreation()
                                    },
                                    setExpandedKey: _vm.setExpandedKey,
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            ruleset.meta.showControlButtons
              ? _c(
                  VLayout,
                  {
                    key: `control${rulesetType}`,
                    staticClass: "control-buttons",
                    attrs: { "justify-end": "" },
                  },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: {
                          id: "apply-button",
                          small: "",
                          disabled: !_vm.canEditRules,
                          depressed: "",
                        },
                        on: {
                          click: _vm.updateStatusAndPriorityAndAllocateSettings,
                        },
                      },
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("actions.Apply")) +
                            "\n      "
                        ),
                      ]
                    ),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }