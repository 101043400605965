<template>
  <v-flex class="upload-error">
    <p class="upload-error-name">
      {{ errorMessage }}
    </p>

    <template v-if="details && details.length">
      <p v-for="(item, index) in details.slice(0, 5)" :key="`${uniqueKeyPrefix}-${index}`">
        <slot name="row" :item="item">
          <!-- Fallback content -->
          {{ item }}
        </slot>
      </p>
      <p v-if="details.length > 5">
        {{ $tc('attributes.upload.plusXOthers', details.length - 5) }}
      </p>
    </template>
  </v-flex>
</template>

<script>
export default {
  props: {
    errorMessage: {
      type: String,
      required: true,
    },
    uniqueKeyPrefix: {
      type: String,
      required: true,
    },
    details: {
      type: Array,
    },
  },
};
</script>

<style scoped>
.upload-error {
  font-size: 1.2rem;
}

.upload-error-name {
  font-weight: bold;
}
</style>
