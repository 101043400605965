import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    {
      staticClass: "rule-card",
      attrs: { outlined: "", elevated: "", elevation: "6" },
    },
    [
      _c(
        VLayout,
        { staticClass: "card-text" },
        [
          _vm.isEditMode && !_vm.expanded
            ? _c(VFlex, { staticClass: "description description-text" }, [
                _c("b", [_vm._v(_vm._s(_vm.rule.description))]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.isCreateMode || (_vm.isEditMode && _vm.expanded)
            ? _c(
                VFlex,
                { staticClass: "description description-text" },
                [
                  _c(VTextField, {
                    staticClass: "description-text-input",
                    attrs: {
                      placeholder: _vm.$t(
                        "settings.penaltyRules.enterRuleName"
                      ),
                      disabled: _vm.disabled,
                    },
                    on: {
                      input: (value) => _vm.updateDescriptionChange(value),
                    },
                    model: {
                      value: _vm.rule.description,
                      callback: function ($$v) {
                        _vm.$set(_vm.rule, "description", $$v)
                      },
                      expression: "rule.description",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _c(
            VFlex,
            { staticClass: "px-3 description store-group-text text-truncate" },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.selectedStoreGroupsText) + "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VFlex,
            { staticClass: "px-3 description scope-text text-truncate" },
            _vm._l(_vm.scopeText, function (word, ix) {
              return _c("span", { key: ix, class: _vm.scopeClass(word) }, [
                _vm._v(
                  "\n        " + _vm._s(_vm.splitScopeText(word)) + "\n      "
                ),
              ])
            }),
            0
          ),
          _vm._v(" "),
          _c(
            "div",
            {
              staticClass: "product-proportion px-3",
              attrs: { "text-xs-right": "" },
            },
            [
              _c("b", [_vm._v(_vm._s(_vm.productScopeCount))]),
              _vm._v(" / " + _vm._s(_vm.productAppliedCount) + "\n    "),
            ]
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "d-flex px-3", attrs: { "text-xs-right": "" } },
            [
              _vm._l(_vm.headers, function (penalty) {
                return _c(
                  VAvatar,
                  {
                    key: penalty.field,
                    staticClass: "traffic-light",
                    class: [
                      penalty.class,
                      {
                        "inactive-traffic-light":
                          !_vm.rule[penalty.field] ||
                          _vm.rule[penalty.field].status === _vm.inactive,
                      },
                    ],
                    attrs: { size: _vm.trafficLightIconSize },
                  },
                  [
                    _c("span", { staticClass: "headline" }, [
                      _vm._v(_vm._s(_vm.$t(penalty.headline))),
                    ]),
                  ]
                )
              }),
              _vm._v(" "),
              _c(
                VAvatar,
                {
                  staticClass: "traffic-light other-penalty-traffic-light",
                  attrs: { size: _vm.trafficLightIconSize },
                },
                [
                  _c("span", { staticClass: "white--text headline" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$t("settings.penaltyRules.otherPenaltyInitial")
                        ) +
                        "\n        "
                    ),
                  ]),
                ]
              ),
            ],
            2
          ),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "px-2" },
            [
              _vm.rule._id
                ? _c(
                    VIcon,
                    {
                      attrs: {
                        size: _vm.trafficLightIconSize,
                        disabled: _vm.disabled,
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDeleteDialog()
                        },
                      },
                    },
                    [_vm._v("\n        delete\n      ")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "edit-text pa-0",
              attrs: {
                disabled: !_vm.isExpandable,
                ripple: false,
                small: "",
                plain: "",
                text: "",
                "text-xs-right": "",
              },
              on: { click: _vm.expand },
            },
            [
              _vm.isEditMode
                ? _c("span", [_vm._v(_vm._s(_vm.$t("actions.edit")))])
                : _vm._e(),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "edit-dropdown-icon subheading",
                  class: { expanded: _vm.expanded },
                },
                [_vm._v(" expand_more ")]
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
        },
        on: {
          onOk: _vm.deletePenaltyRuleHandler,
          onCancel: _vm.closeDeleteDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(_vm._s(_vm.$t("dialogs.deletePenaltyRule.header"))),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("p", [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("dialogs.deletePenaltyRule.deleteRule")) +
                      "\n        "
                  ),
                  _c("strong", [_vm._v(_vm._s(_vm.rule.description))]),
                ]),
                _vm._v(" "),
                _c("p", [
                  _vm._v(
                    _vm._s(_vm.$t("dialogs.deletePenaltyRule.clickApply"))
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }