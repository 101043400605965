import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    { attrs: { value: _vm.isOpen, persistent: "", width: "40rem" } },
    [
      _c(
        VCard,
        [
          _c(
            VContainer,
            [
              _c(
                VForm,
                {
                  ref: "competitorForm",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardText, { staticClass: "header-text" }, [
                    _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    {
                      staticClass: "close-button",
                      attrs: { color: "grey" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("close")]
                  ),
                  _vm._v(" "),
                  _c(
                    VCardText,
                    { staticClass: "body" },
                    [
                      _c(VTextField, {
                        attrs: {
                          value: _vm.competitorName,
                          label: _vm.$t("general.labels.competitorName"),
                          rules: _vm.competitorNameRules,
                          required: "",
                        },
                        model: {
                          value: _vm.competitorName,
                          callback: function ($$v) {
                            _vm.competitorName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "competitorName",
                        },
                      }),
                      _vm._v(" "),
                      _c(VCheckbox, {
                        attrs: {
                          disabled: !_vm.isCreateMode,
                          label: _vm.$t("general.labels.incVAT"),
                        },
                        model: {
                          value: _vm.incVAT,
                          callback: function ($$v) {
                            _vm.incVAT = $$v
                          },
                          expression: "incVAT",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCardActions,
                    { staticClass: "btn-group" },
                    [
                      _c(VSpacer),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: { color: "primary", outlined: "", small: "" },
                          on: { click: _vm.cancel },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.cancel")) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: {
                            disabled: !_vm.isValid,
                            color: "primary",
                            small: "",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.submitBtnCaption) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }