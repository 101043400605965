/* eslint import/prefer-default-export: 0 */ // can be removed once there is more than one function in this file

// These formatters are intended to be used achieve client-specific number formatting which is not part of i18n.
// They should be called after i18n formatting is complete.

function encaseNegativesInParentheses(str) {
  // Input: -5,71, -5,71%
  // Output: (5,71), (5,71)%
  return str.slice(-1) === '%' ? `(${str.slice(1, -1)})%` : `(${str.slice(1)})`;
}

export { encaseNegativesInParentheses };
