import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { on: { click: _vm.emitClick } },
    [
      _c(
        VIcon,
        { staticClass: "pr-1 v-icon--link", attrs: { medium: "" } },
        [_vm._v("fa-plus-square")]
      ),
      _vm._v(" "),
      _c("span", { staticClass: "text" }, [_vm._v(_vm._s(_vm.$t(_vm.copy)))]),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }