<template>
  <!-- FEATURE_FLAG: display a zones-related filter -->
  <feature-toggle :toggle="useZones">
    <div class="ma-2 store-group-filter">
      <v-autocomplete
        dense
        chips
        small-chips
        multiple
        solo
        hide-details
        :value="selectedToolStoreGroups"
        :items="selectedWorkpackageToolStoreGroups"
        item-value="key"
        item-text="description"
        :disabled="!selectedWorkpackageToolStoreGroups"
        :label="$t('settings.penaltyRules.selectStoreGroup')"
        color="indigo darken-2"
        :search-input.sync="storeGroupSearchInput"
        :loading="loadingStoreGroupRelationships || loadingSelectedWorkpackageToolStoreGroups"
        @change="selectStoreGroup"
      >
        <template v-slot:selection="{ item, selected }">
          <v-chip
            :input-value="selected"
            close
            small
            class="chip--select"
            @click:close="deselectStoreGroup(item)"
          >
            {{ item.description }}
          </v-chip>
        </template>
        <template v-slot:no-data>
          <div v-if="!loadingStoreGroupRelationships && !loadingSelectedWorkpackageToolStoreGroups">
            <v-alert :value="true" color="error" icon="warning" outlined>
              {{ $t('attributes.filters.noData') }}
            </v-alert>
          </div>
          <div v-else>
            <v-alert :value="true" color="info" icon="info" outlined>
              {{ $t('general.loadingMessage') }}
            </v-alert>
          </div>
        </template>
        <template v-slot:item="props">
          <span class="item-text">
            {{ props.item.description }}
          </span>
        </template>
      </v-autocomplete>
    </div>
  </feature-toggle>
</template>

<script>
import { mapState } from 'vuex';
import { useZones } from '@enums/feature-flags';

export default {
  props: {
    selectedToolStoreGroups: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      useZones,
      storeGroupSearchInput: '',
    };
  },
  computed: {
    ...mapState('workpackages', [
      'loadingSelectedWorkpackageToolStoreGroups',
      'selectedWorkpackageToolStoreGroups',
    ]),
    ...mapState('storeGroupRelationships', ['loadingStoreGroupRelationships']),
  },

  events: {
    async onArchitectureRulesFilterUpdated() {
      this.storeGroupSearchInput = '';
    },
  },

  methods: {
    deselectStoreGroup(item) {
      const { key } = item;
      const newItems = this.selectedToolStoreGroups.filter(storeGroup => storeGroup !== key);
      this.$emit('selection-changed', [...newItems]);
    },

    selectStoreGroup(items) {
      this.$emit('selection-changed', [...items]);
    },
  },
};
</script>

<style lang="scss" scoped>
.store-group-filter {
  width: 50rem;

  .chip--select,
  &::v-deep .v-label,
  &::v-deep .v-chip__content {
    font-size: 1.2rem;
  }
}
</style>
