<template>
  <div class="feature-toggler">
    <div class="feature-toggler__toggles">
      <div
        v-for="(value, key) in { ...releaseFlags, ...toggleLogic }"
        :key="key"
        class="feature-toggler__toggles--toggle"
      >
        <b>{{ key }}:</b>
        <v-switch
          v-if="isBoolean(value)"
          :input-value="value"
          @change="debouncedUpdateToggle({ key, value: $event })"
        />
        <v-text-field
          v-else-if="isString(value)"
          :value="String(value)"
          @input="debouncedUpdateToggle({ key, value: $event })"
        />
        <v-text-field
          v-else-if="isObject(value) || isNumber(value)"
          :value="JSON.stringify(value)"
          @input="debouncedUpdateToggle({ key, value: $event, parse: true })"
        />
        <span>{{ value }}</span>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isBoolean, isString, isNumber, isObject, debounce, has } from 'lodash';

export default {
  data() {
    return {};
  },
  computed: {
    ...mapState('clientConfig', ['toggleLogic', 'releaseFlags']),
  },
  methods: {
    ...mapActions('clientConfig', ['setToggleLogicValue', 'setReleaseFlagsValue']),
    isBoolean,
    isString,
    isObject,
    isNumber,
    debouncedUpdateToggle: debounce(function({ key: toggle, value, parse = false }) {
      if (parse) value = JSON.parse(value);
      if (has(this.releaseFlags, toggle)) {
        this.setReleaseFlagsValue({ toggle, value });
      } else this.setToggleLogicValue({ toggle, value });
    }, 800),
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.feature-toggler {
  height: 100%;
  width: 100%;
  display: flex;
  flex-grow: 1;
  flex-direction: column;
  background-color: white;
  font-size: 1.4rem;

  &__toggles {
    padding-left: 5rem;
    padding-top: 2rem;
    &--toggle {
      display: flex;
      column-gap: 1rem;
      padding-bottom: 0;
      height: 4rem;
      .v-input--selection-controls {
        padding-top: 0;
        margin-top: 0;
      }
      ::v-deep {
        .v-text-field {
          max-width: 40%;
          padding-top: 0;
          margin-top: 0;
        }
        .v-input__slot {
          min-height: 1.8rem;
        }
        .v-text-field__slot {
          height: 1.8rem;
        }
        .v-text-field__details {
          display: none;
        }
      }
    }
  }
}
</style>
