<template>
  <v-container pa-2 pb-0>
    <v-layout justify-space-between>
      <h3>{{ $t('pricing.penaltyFunctions.heading') }}</h3>
      <!-- FEATURE_FLAG: display list of penalty rules -->
      <feature-toggle :toggle="displayRulesListView">
        <v-item-group class="pb-2">
          <v-btn
            :class="isGraphView ? 'toggle-btn-active' : 'toggle-btn-inactive'"
            x-small
            tile
            depressed
            @click="toggleView"
          >
            <v-icon>show_chart</v-icon>
          </v-btn>
          <v-btn
            :class="!isGraphView ? 'toggle-btn-active' : 'toggle-btn-inactive'"
            x-small
            tile
            depressed
            @click="toggleView"
          >
            <v-icon>list</v-icon>
          </v-btn>
        </v-item-group>
      </feature-toggle>
    </v-layout>
    <v-layout v-if="emptyScenarioResults && isGraphView">
      <i>{{ $t('pricing.penaltyFunctions.noData') }}</i>
    </v-layout>
    <v-layout v-if="!emptyScenarioResults && isGraphView" row>
      <v-flex xs9>
        <highcharts :options="chartOptions" />
      </v-flex>
      <v-flex xs3 category-container>
        <input
          id="economic"
          v-model="selectedCategory"
          type="radio"
          name="penalty-toggle"
          :value="categories.economic"
        />
        <label class="economic" for="economic">
          {{ $t('pricing.penaltyFunctions.type.economic') }}:
          <span>
            {{
              formatNumber({
                number: scenarioResults.economicReferencePrice,
                format: numberFormats.priceFormat,
                zeroAsDash: true,
              })
            }}
          </span>
        </label>
        <br />
        <input
          id="competitor"
          v-model="selectedCategory"
          type="radio"
          name="penalty-toggle"
          :value="categories.competitor"
        />
        <label class="competition" for="competitor">
          {{ $t('pricing.penaltyFunctions.type.competition') }}:
          <span>
            {{
              formatNumber({
                number: scenarioResults.competitorReferencePrice,
                format: numberFormats.priceFormat,
                zeroAsDash: true,
              })
            }}
          </span>
        </label>
        <br />
        <input
          id="architecture"
          v-model="selectedCategory"
          type="radio"
          name="penalty-toggle"
          :value="categories.architecture"
        />
        <label class="architecture" for="architecture">
          {{ $t('pricing.penaltyFunctions.type.architecture') }}:
          <span>
            {{
              formatNumber({
                number: scenarioResults.architectureReferencePrice,
                format: numberFormats.priceFormat,
                zeroAsDash: true,
              })
            }}
          </span>
        </label>
        <br />
        <!-- FEATURE TOGGLE: only display store group penalty if displayStoreGroupPenalty turned on -->
        <feature-toggle :toggle="displayStoreGroupPenalty">
          <input
            id="storeGroup"
            v-model="selectedCategory"
            type="radio"
            name="penalty-toggle"
            :value="categories.storeGroup"
          />
          <label class="store-group" for="storeGroup">
            {{ $t('pricing.penaltyFunctions.type.storeGroup') }}:
            <span>
              {{
                formatNumber({
                  number: scenarioResults.storeGroupReferencePrice,
                  format: numberFormats.priceFormat,
                  zeroAsDash: true,
                })
              }}
            </span>
          </label>
          <br />
        </feature-toggle>
        <input
          id="combined"
          v-model="selectedCategory"
          type="radio"
          name="penalty-toggle"
          :value="categories.combined"
        />
        <label class="combined" for="combined">
          {{ $t('pricing.penaltyFunctions.type.combined') }}:
          <span>{{ getCombinedPrice(scenarioResults) }}</span>
        </label>
      </v-flex>
    </v-layout>
    <rules-list-view
      v-if="!isGraphView"
      :style="rulesListStyles"
      :scenario-key="scenarioKey"
      :tool-store-group-key="toolStoreGroupKey"
    />
  </v-container>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { get, merge, min, max, sum, map, size, isEqual } from 'lodash';
import pSBC from 'shade-blend-color';
import defaultOptions from '../default-chart-options';
import expandedProductViewChartOptions from '../expanded-product-view-chart-options';
import colours from '../ow-colors';
import penaltyCategories from '@enums/penalty-categories';
import numberFormats from '@enums/number-formats';
import featureFlagsMixin from '../mixins/featureFlags';
import { displayRulesListView, displayStoreGroupPenalty } from '@enums/feature-flags';

const chartHeight = 200;

export default {
  mixins: [featureFlagsMixin],
  props: {
    scenarioResults: {
      // We need to expect `undefined` when results are not generated yet
      validator: type => typeof type === 'object' || type === undefined,
      required: true,
    },
    scenarioKey: {
      required: true,
    },
    productKey: {
      required: true,
    },
    toolStoreGroupKey: {
      required: true,
    },
    competitorNamesMap: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    const categories = penaltyCategories;
    return {
      selectedCategory: categories.combined,
      categories: penaltyCategories,
      plotLines: [],
      isGraphView: true,
      displayRulesListView,
      displayStoreGroupPenalty,
      numberFormats,
      chartHeight,
      rulesListStyles: {
        height: `${chartHeight}px`,
      },
    };
  },
  computed: {
    ...mapState('gridView', ['expandedProductSettings']),
    isStoreGroupPenaltyVisible() {
      return this.isFeatureFlagEnabled(displayStoreGroupPenalty);
    },
    getChartData() {
      const chartData = [];
      this.clearPlotLines();
      const penaltyLength = size(this.scenarioResults.priceRange);

      // architecure
      if (this.selectedCategory === this.categories.architecture) {
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.architecture'),
          data: this.mergePenaltyPriceArrays(
            this.scenarioResults.architecturePenalty,
            penaltyLength
          ),
          color: colours.pricingArchitectureColour,
        });

        chartData.push(
          this.addShortDotToSeries({ x: this.scenarioResults.architectureReferencePrice, y: 0 })
        );
      }

      // competitor
      if (this.selectedCategory === this.categories.competitor) {
        const activeCompetitorsInPenalty =
          get(this.expandedProductSettings, 'competitorPenalty.status') === 'active'
            ? get(this.expandedProductSettings, 'competitorPenalty.penalties', [])
                .filter(c => c.isActive)
                .map(c => c.competitorName)
            : [];
        const relevantCompetitorScenarioResults = this.scenarioResults.competitors.filter(c =>
          activeCompetitorsInPenalty.includes(c.competitorName)
        );
        const colourChangePercent = 1 / relevantCompetitorScenarioResults.length;

        relevantCompetitorScenarioResults.forEach((competitor, i) => {
          const shade = colourChangePercent * i;
          const mergedData = this.mergePenaltyPriceArrays(competitor.penalty, penaltyLength);
          const noTooltip = mergedData.every(d => d[1] === 0);
          chartData.push({
            name: this.competitorNamesMap[competitor.competitorName] || competitor.competitorName,
            type: 'areaspline',
            data: mergedData,
            color: pSBC(shade, colours.pricingCompetitionColour),
            noTooltip,
          });
        });

        chartData.push(
          this.addShortDotToSeries({ x: this.scenarioResults.competitorReferencePrice, y: 0 })
        );
      }

      // store group
      if (this.selectedCategory === this.categories.storeGroup) {
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.storeGroup'),
          data: this.mergePenaltyPriceArrays(
            get(this.scenarioResults, 'storeGroupPenalty', []),
            penaltyLength
          ),
          color: colours.pricingStoreGroupColour,
        });

        chartData.push(
          this.addShortDotToSeries({ x: this.scenarioResults.architectureReferencePrice, y: 0 })
        );
      }

      // economic
      if (this.selectedCategory === this.categories.economic) {
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.economic'),
          data: this.mergePenaltyPriceArrays(this.scenarioResults.economicPenalty, penaltyLength),
          color: colours.pricingEconomyColour,
        });

        chartData.push(
          this.addShortDotToSeries({ x: this.scenarioResults.economicReferencePrice, y: 0 })
        );
      }

      // combined chart
      if (this.selectedCategory === this.categories.combined) {
        // push economic data into combined chart
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.economic'),
          data: this.mergePenaltyPriceArrays(this.scenarioResults.economicPenalty, penaltyLength),
          color: colours.pricingEconomyColour,
        });

        // push competitor data into combined chart
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.competition'),
          data: this.mergePenaltyPriceArrays(this.scenarioResults.competitorPenalty, penaltyLength),
          color: colours.pricingCompetitionColour,
        });

        // TODO: Add when scenarioPrice contains correct pricePenalty field:
        // chartData.push(this.addShortDotToSeries({
        //   x: this.scenarioResults.scenarioPrice.price,
        //   y: this.scenarioResults.scenarioPrice.pricePenalty
        // }));

        // temporary solution: show dotted line with no point
        this.addShortDotCombined();
        // push architecture data into combined chart
        chartData.push({
          type: 'areaspline',
          name: this.$t('pricing.penaltyFunctions.type.architecture'),
          data: this.mergePenaltyPriceArrays(
            this.scenarioResults.architecturePenalty,
            penaltyLength
          ),
          color: colours.pricingArchitectureColour,
        });

        // push store group data into combined chart
        if (this.isStoreGroupPenaltyVisible) {
          chartData.push({
            type: 'areaspline',
            name: this.$t('pricing.penaltyFunctions.type.storeGroup'),
            data: this.mergePenaltyPriceArrays(
              get(this.scenarioResults, 'storeGroupPenalty', []),
              penaltyLength
            ),
            color: colours.pricingStoreGroupColour,
          });
        }
      }

      return chartData;
    },
    emptyScenarioResults() {
      return this.scenarioResults === undefined || this.scenarioResults === null;
    },
    chartOptions() {
      const vue = this; // vue instance for access in the axis labels formatter
      return merge({}, defaultOptions, expandedProductViewChartOptions, {
        series: this.getChartData,
        chart: {
          height: this.chartHeight,
        },
        xAxis: {
          min: min(this.scenarioResults.priceRange),
          max: max(this.scenarioResults.priceRange),
          tickPositions: null,
          labels: {
            enabled: true,
            formatter() {
              return vue.formatNumber({
                number: this.value,
                format: vue.numberFormats.currency,
              });
            },
          },
          lineColor: colours.pricingGridLineColor,
          lineWidth: 2,
          plotLines: this.plotLines,
        },
        yAxis: {
          gridLineColor: colours.pricingGridLineColor,
          lineColor: colours.pricingGreyDark,
          tickPositions: null,
          gridLineWidth: 1,
          lineWidth: 0,
          labels: {
            enabled: true,
            formatter() {
              return vue.formatNumber({
                number: this.value,
                format: vue.numberFormats.integer,
              });
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter() {
            if (this.points[0].series.options.noTooltip && this.points.length === 1) return false;
            // Combined chart or competition chart with multi comperitors data
            if (this.points.length > 1) {
              const seriesTooltip = map(
                this.points.filter(p => p.series.options.noTooltip !== true),
                point => {
                  return `<br/>${point.series.name}: ${vue.formatNumber({
                    number: point.y,
                    format: vue.numberFormats.integer,
                  })}<br/>`;
                }
              );
              const combined = vue.$t('pricing.penaltyFunctions.type.combined');
              const combinedPenalty = [this.points[0].x, sum(map(this.points, point => point.y))];
              return `<b>${combined}: ${vue.formatNumber({
                number: combinedPenalty[1],
                format: vue.numberFormats.integer,
              })}</b>
                ${seriesTooltip.join('')}
                ${vue.formatNumber({
                  number: combinedPenalty[0],
                  format: vue.numberFormats.currency,
                })}`;
            }
            return `<b>${this.points[0].series.name}</b>
              <br/>${vue.$t('pricing.penaltyFunctions.penalty')} ${vue.formatNumber({
              number: this.points[0].y,
              format: vue.numberFormats.integer,
            })}<br/>
              ${vue.formatNumber({
                number: this.points[0].x,
                format: vue.numberFormats.currency,
              })}`;
          },
          shared: true,
        },
        plotOptions: {
          scatter: {
            marker: {
              enabled: true,
              radius: 10,
              symbol: 'circle',
            },
          },
          series: {
            marker: {
              enabled: true,
            },
            stacking: 'normal',
            fillOpacity: 0.3,
          },
          areaspline: {
            stacking: 'normal',
            marker: {
              radius: 0,
            },
            fillOpacity: 0.1,
          },
        },
      });
    },
  },

  async created() {
    await this.fetchProductSettings({
      productKey: this.productKey,
      scenarioKey: this.scenarioKey,
      toolStoreGroupKey: this.toolStoreGroupKey,
    });
  },
  methods: {
    ...mapActions('gridView', ['fetchProductSettings']),
    setCategory(category) {
      this.selectedCategory = this.categories[category];
      this.plotLines = [];
    },
    isCategoryActive(category) {
      return this.selectedCategory === category;
    },
    mergePenaltyPriceArrays(penalties, penaltyLength) {
      if (!penalties) {
        return [];
      }

      const penaltyArray = this.decompressPenaltyArray(penalties, penaltyLength);
      return penaltyArray.map((point, i) => {
        return [this.scenarioResults.priceRange[i], point];
      });
    },

    decompressPenaltyArray(penalties, penaltyLength) {
      if (isEqual(penalties, [0])) {
        return Array(penaltyLength).fill(0);
      }
      return penalties;
    },

    addShortDotToSeries({ x, y }) {
      this.plotLines.push({
        color: colours.pricingGreyDark,
        dashStyle: 'shortdot',
        value: x,
        width: 2,
      });
      return {
        dashStyle: 'shortdot',
        data: [[x, y]],
        lineWidth: 2,
        color: colours.pricingChartScatter,
        marker: {
          symbol: 'circle',
          radius: 6,
        },
        noTooltip: true,
      };
    },
    addShortDotCombined() {
      // TODO: remove when scenarioPrice contains correct pricePenalty field:
      this.plotLines.push({
        color: colours.pricingGreyDark,
        dashStyle: 'shortdot',
        value: this.scenarioResults.scenarioPrice.price,
        width: 2,
      });
    },
    clearPlotLines() {
      this.plotLines = [];
    },
    toggleView() {
      this.isGraphView = !this.isGraphView;
    },
    getCombinedPrice(scenarioResults) {
      const hasPricePointing = !!get(this.expandedProductSettings, 'pricePointing');
      const optimizedPriceWithoutPP = this.formatNumber({
        number: get(scenarioResults, 'optimizedPriceWithoutPP'),
        format: numberFormats.priceFormat,
        zeroAsDash: true,
      });
      const optimizedPrice = this.formatNumber({
        number: get(scenarioResults, 'optimizedPrice'),
        format: numberFormats.priceFormat,
        zeroAsDash: true,
      });
      const optimizedPriceText = hasPricePointing ? ` (${optimizedPrice})` : '';
      return `${optimizedPriceWithoutPP}${optimizedPriceText}`;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.category-container {
  label {
    font-size: 1.2rem;
    font-weight: 600;
    line-height: 2.3rem;
    cursor: pointer;

    span {
      font-size: 1.7rem;
    }
  }

  input[type='radio'] {
    margin: 0 0.2rem;
  }

  .economic {
    color: $pricing-economy-colour;
  }
  .competition {
    color: $pricing-competition-colour;
  }
  .architecture {
    color: $pricing-architecture-colour;
  }
  .store-group {
    color: $pricing-store-group-colour;
  }
  .combined {
    color: $pricing-combined-colour;
  }
}

.toggle-btn-active {
  color: $pricing-white !important;
  background-color: $btn-ok-color !important;
  border-color: $btn-ok-color !important;
}
.toggle-btn-inactive {
  color: $btn-ok-color !important;
  border-color: $btn-ok-color !important;
}
</style>
