var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "custom-tooltip" }, [
    _vm.isColumnHeader
      ? _c("div", [
          _c("div", { staticClass: "entry" }, [
            _c("span", [_vm._v(_vm._s(_vm.params.value))]),
          ]),
        ])
      : _c("div", [
          _vm.params.value.previousValueText
            ? _c("div", { staticClass: "entry" }, [
                _c("span", [
                  _vm._v(_vm._s(_vm.params.value.previousValueText)),
                ]),
              ])
            : _vm._e(),
          _vm._v(" "),
          _vm.params.value.additionalText
            ? _c(
                "div",
                { staticClass: "entry" },
                [
                  _vm._l(_vm.params.value.additionalText, function (text, ix) {
                    return [
                      _c("div", { key: ix }, [
                        _c("span", [_vm._v(_vm._s(text))]),
                      ]),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.params.value.errors
            ? _c(
                "div",
                { staticClass: "entry error-message" },
                [
                  _c("div", [
                    _vm._v(
                      _vm._s(_vm.$t("inputs.validationErrorHeader")) + ":"
                    ),
                  ]),
                  _vm._v(" "),
                  _vm._l(_vm.params.value.errors, function (errorCode, ix) {
                    return [
                      _c("div", { key: ix }, [
                        _c("span", [
                          _vm._v(
                            _vm._s(_vm.$t(`validationErrors.${errorCode}`))
                          ),
                        ]),
                      ]),
                    ]
                  }),
                ],
                2
              )
            : _vm._e(),
        ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }