<template>
  <div v-if="isExpanded" style="display: contents;">
    <tr v-if="storeGroupAggregations.loading" class="hierarchy__row hierarchy-level-item__main">
      <td />
      <td class="spinner-cell" colspan="16">
        <!-- spinner is displayed in columns displaying aggregations -->
        <basic-spinner />
      </td>
      <td class="alert-cell" />
    </tr>
    <template v-else>
      <tr
        v-if="!storeGroupAggregations.data.length"
        class="hierarchy__row hierarchy-level-item__main"
      >
        <td colspan="16" class="store-group-heading hierarchy-level-item__group-end">
          {{ $t('gridView.noStoreGroupAggregations') }}
        </td>
        <td />
        <td class="alert-cell" />
      </tr>
      <tr
        v-for="storeGroup in storeGroupAggregations.data"
        :key="storeGroup._id"
        class="hierarchy__row hierarchy-level-item__main"
      >
        <td
          class="store-group-heading hierarchy-level-item__heading hierarchy-level-item__group-end border-right"
        >
          {{ storegroupKeyNameMap[storeGroup.toolStoreGroupKey] }}
        </td>
        <aggregations-row
          is-store-group-aggregation
          :level="0"
          :sales-m-a-t="storeGroup.salesMAT"
          :promo-share="storeGroup.promoShare"
          :sales-impact-m-a-t="storeGroup.salesImpactMAT"
          :regular-sales-impact="storeGroup.regularSalesImpact"
          :total-impact-in-year="storeGroup.totalImpactInYear"
          :regular-impact-in-year="storeGroup.regularImpactInYear"
          :total-cost-impact="storeGroup.totalCostImpact"
          :cost-impact-in-year="storeGroup.costImpactInYear"
          :regular-cost-impact-in-year="storeGroup.regularCostImpactInYear"
          :regular-cost-impact="storeGroup.regularCostImpact"
          :margin-proposed="storeGroup.marginProposed"
          :margin-delta="storeGroup.marginDelta"
          :economic-tension="storeGroup.economicTension"
          :competitor-tension="storeGroup.competitorTension"
          :architecture-tension="storeGroup.architectureTension"
          :store-group-tension="storeGroup.storeGroupTension"
          :competitor1-live-distance="storeGroup.competitor1LiveDistance"
          :competitor1-proposed-distance="storeGroup.competitor1ProposedDistance"
          :competitor1-delta-vs-prev="storeGroup.competitor1DeltaVsPrev"
          :competitor2-live-distance="storeGroup.competitor2LiveDistance"
          :competitor2-proposed-distance="storeGroup.competitor2ProposedDistance"
          :competitor2-delta-vs-prev="storeGroup.competitor2DeltaVsPrev"
          :competitor1-live-distance-price="storeGroup.competitor1LiveDistancePrice"
          :competitor1-proposed-distance-price="storeGroup.competitor1ProposedDistancePrice"
          :competitor1-delta-vs-prev-price="storeGroup.competitor1DeltaVsPrevPrice"
          :competitor2-live-distance-price="storeGroup.competitor2LiveDistancePrice"
          :competitor2-proposed-distance-price="storeGroup.competitor2ProposedDistancePrice"
          :competitor2-delta-vs-prev-price="storeGroup.competitor2DeltaVsPrevPrice"
          :competitor1-live-distance-volume="storeGroup.competitor1LiveDistanceVolume"
          :competitor1-proposed-distance-volume="storeGroup.competitor1ProposedDistanceVolume"
          :competitor1-delta-vs-prev-volume="storeGroup.competitor1DeltaVsPrevVolume"
          :competitor2-live-distance-volume="storeGroup.competitor2LiveDistanceVolume"
          :competitor2-proposed-distance-volume="storeGroup.competitor2ProposedDistanceVolume"
          :competitor2-delta-vs-prev-volume="storeGroup.competitor2DeltaVsPrevVolume"
          :num-price-changes="storeGroup.numPriceChanges"
        />
      </tr>
    </template>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {
  props: {
    parentId: String,

    level: {
      type: Number,
      default: () => 0,
    },

    scenarioKey: {
      type: [String, null],
      default: null,
    },
  },

  computed: {
    ...mapState('gridView', ['loadingStoreGroupAggregations']),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),
    ...mapGetters('gridView', ['expandedStoreGroupAggregation']),

    storeGroupAggregations() {
      return this.expandedStoreGroupAggregation({
        parentId: this.parentId,
        level: this.level,
        scenarioKey: this.scenarioKey,
      });
    },

    isExpanded() {
      return !!this.storeGroupAggregations;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';
@import '@style/components/_hierarchy-item-shared.scss';

.store-group-expand {
  font-size: 1.25rem;
  text-align: right;
}

.store-group-heading {
  text-align: right;
}

.hierarchy__row {
  tr {
    border-bottom: 0.1rem solid $pricing-grey-dark;
  }
}

.spinner-cell {
  padding: 0;
}
</style>
