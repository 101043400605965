'use strict';

import { isObject, reduce } from 'lodash';
import { LINE_BREAK_SYMBOL } from '@sharedModules/data/constants/special-symbols';

const mixin = {
  methods: {
    formatTooltipMessage(tooltipMsg) {
      if (isObject(tooltipMsg)) {
        return reduce(
          tooltipMsg,
          (result, value, key) =>
            result ? `${result}${LINE_BREAK_SYMBOL}${key}: ${value}` : `${key}: ${value}`,
          ''
        );
      }
      return tooltipMsg;
    },
  },
};

export default mixin;
