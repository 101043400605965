'use strict';

const _ = require('lodash');
const {
  timeFlexibleAggregations,
  timeFlexibleIndependentAggregations,
  timeFlexibleGridViewFields,
  timeFlexibleIndependentGridViewFields,
} = require('../enums/time-flexible-fields');
const { yearly } = require('../enums/historical-periods');

const mapTimeFlexibleData = (
  selectedHistoricalPeriod,
  timeFlexibleFields,
  timeFlexibleIndependentFields,
  forExport = false
) => {
  const fieldSuffix = selectedHistoricalPeriod === yearly ? '' : selectedHistoricalPeriod;
  return item => {
    const mappedItem = forExport ? [] : _.pick(item, timeFlexibleIndependentFields);
    timeFlexibleFields.forEach(field => {
      // deal with yearlySales/ yearlyVolumes
      if (_.includes(field, yearly) && fieldSuffix) {
        const timeFlexibleField = field.replace(yearly, '').toLowerCase();
        mappedItem[field] = item[timeFlexibleField.concat(fieldSuffix)];
      } else {
        mappedItem[field] = item[field.concat(fieldSuffix)];
      }
    });
    return mappedItem;
  };
};

const mapAggregations = selectedHistoricalPeriod => {
  return mapTimeFlexibleData(
    selectedHistoricalPeriod,
    timeFlexibleAggregations,
    timeFlexibleIndependentAggregations
  );
};

const mapGridView = (selectedHistoricalPeriod, forExport = false) => {
  return mapTimeFlexibleData(
    selectedHistoricalPeriod,
    timeFlexibleGridViewFields,
    timeFlexibleIndependentGridViewFields,
    forExport
  );
};

module.exports = {
  mapAggregations,
  mapGridView,
};
