import { mapState } from 'vuex';

const mixin = {
  methods: {
    isFeatureFlagEnabled(flag) {
      /**
       * Return the (boolean cast) value of a feature decision.
       */
      return !!this.toggleLogic[flag];
    },
    filterArrayByFeatureFlag(arr) {
      return arr.filter(item => {
        if (!item.featureFlag) {
          return true;
        }

        return this.isFeatureFlagEnabled(item.featureFlag);
      });
    },
  },
  computed: {
    ...mapState('clientConfig', ['toggleLogic']),
  },
};

export default mixin;
