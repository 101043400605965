<!--Wrapper component for functional SVG and material icons,
it uses 'v-icon' css class to extend base v-icon functionality-->
<template functional>
  <span
    :ref="data.ref"
    :class="[
      'v-icon',
      { 'v-icon--small': props.small },
      { 'v-icon--large': props.large },
      { 'v-icon--right': props.right },
      { 'v-icon--left': props.left },
      { 'v-icon--disabled': props.disabled },
      props.iconColourType ? 'v-icon--' + props.iconColourType : '',
      props.color ? props.color + '--text' : '',
      data.class,
      data.staticClass,
    ]"
    :style="[data.style, data.staticStyle]"
    v-bind="data.attrs"
    v-on="listeners"
  >
    <component :is="props.iconName" v-if="$options.methods.getComponent(props.iconName)" />
    <v-icon
      v-else
      :class="[
        { 'v-icon--small': props.small },
        { 'v-icon--large': props.large },
        props.materialIconClass,
        'notranslate',
        'material-icons',
        'theme--light',
      ]"
    >
      {{ props.iconName }}
    </v-icon>
  </span>
</template>

<script>
import Vue from 'vue';

export default {
  props: {
    small: Boolean,
    large: Boolean,
    right: Boolean,
    left: Boolean,
    color: String,
    iconColourType: {
      type: String,
      validator: value => {
        return [
          'primary-rect',
          'primary-path',
          'secondary-rect',
          'secondary-path',
          'positive-rect',
          'positive-path',
        ].includes(value);
      },
    },
    iconName: String,
    disabled: Boolean,
    materialIconClass: String,
  },
  methods: {
    getComponent(name) {
      return Vue.options.components[name];
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.v-icon {
  &--small {
    font-size: 1.6rem;
    height: 1.6rem;
  }
  &--large {
    font-size: 3.6rem;
    height: 3.6rem;
  }
  svg {
    pointer-events: none;
  }
  &--primary-rect::v-deep {
    svg rect {
      fill: $pricing-primary;
    }
    &:hover {
      svg rect {
        fill: $rtls-secondary-button-hover-colour;
      }
    }
  }
  &--primary-path::v-deep {
    svg path {
      fill: $pricing-primary;
    }
    &:hover {
      svg path {
        fill: $rtls-secondary-button-hover-colour;
      }
    }
  }
}
</style>
