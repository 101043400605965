import { get } from 'lodash';

export function calculateHeadersAndFootersHeight(
  tableSelector = 'div.v-data-table__wrapper',
  defaultHeight = 700
) {
  // Note this function should be run after component is mounted, otherwise elements may not have been rendered, so may have incorrect or missing heights.
  const originalDataTableHeight = get(
    document.querySelector(tableSelector),
    'offsetHeight',
    defaultHeight
  );
  const documentHeight = document.body.offsetHeight;

  // Remove the original height of the table from the height of the body to get the total height the headers, footers, etc take.
  return documentHeight - originalDataTableHeight;
  // Then remove the value returned here from the window height, to fit the table to the window.
  // Need to do this every time the window is resized.
}

export function preventPageNavigationOnHorizontalScroll(e, elId = null) {
  // inspired by https://stackoverflow.com/a/27023848
  // elId is a class of scrollable element.
  // elId isn't required but good to have because sometimes scrollable elemens can differ

  const table = elId ? this.$el.querySelector(elId) : this.$el;
  const maxX = table.scrollWidth - table.offsetWidth;
  const maxY = table.scrollHeight - table.offsetHeight;

  if (
    table.scrollLeft + e.deltaX < 0 ||
    table.scrollLeft + e.deltaX > maxX ||
    table.scrollTop + e.deltaY < 0 ||
    table.scrollTop + e.deltaY > maxY
  ) {
    e.preventDefault();
    table.scrollLeft = Math.max(0, Math.min(maxX, table.scrollLeft + e.deltaX));
    table.scrollTop = Math.max(0, Math.min(maxY, table.scrollTop + e.deltaY));
  }
}
