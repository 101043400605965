import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    [
      _c(
        VCol,
        { staticClass: "pr-3", attrs: { md: "4" } },
        [
          _c("div", { staticClass: "py-2" }, [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("settings.setArchitectureRules.globalAttributes")
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.architectureDataLoading
            ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "draggable",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.architectureDataLoading,
                  expression: "!architectureDataLoading",
                },
              ],
              staticClass: "draggable-element scroll",
              attrs: {
                group: { name: "draggable-attribute-lists", put: false },
                list: _vm.globalAttributes,
                disabled: _vm.disabled,
                handle: ".draggable-attribute-icon",
              },
            },
            _vm._l(_vm.globalAttributes, function (attribute) {
              return _c(
                VCard,
                {
                  key: attribute._id,
                  staticClass: "panel my-1",
                  attrs: { flat: "", height: "2.5rem" },
                },
                [
                  _c(
                    VCardText,
                    { staticClass: "py-1 px-1 attribute-list" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(
                            VFlex,
                            {
                              staticClass: "text-truncate",
                              attrs: { xs11: "" },
                            },
                            [
                              _c(
                                "span",
                                { class: _vm.disabled ? "" : "black--text" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(attribute.displayDescription) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            VFlex,
                            {
                              staticClass: "draggable-attribute-icon",
                              attrs: { xs1: "" },
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "drag-icon",
                                  class: _vm.disabled
                                    ? "disabled-drag-icon"
                                    : "",
                                  attrs: { "text-xs-right": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                open_with\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
          _vm._v(" "),
          _c("div", { staticClass: "py-2" }, [
            _c("span", { staticClass: "headline" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.setArchitectureRules.pricingGroupAttributes"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _vm.architectureDataLoading
            ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
            : _vm._e(),
          _vm._v(" "),
          _c(
            "draggable",
            {
              directives: [
                {
                  name: "show",
                  rawName: "v-show",
                  value: !_vm.architectureDataLoading,
                  expression: "!architectureDataLoading",
                },
              ],
              staticClass: "draggable-element scroll",
              attrs: {
                group: { name: "draggable-attribute-lists", put: false },
                list: _vm.pricingGroupAttributes,
                disabled: _vm.disabled,
                handle: ".draggable-attribute-icon",
              },
            },
            _vm._l(_vm.pricingGroupAttributes, function (attribute) {
              return _c(
                VCard,
                {
                  key: attribute._id,
                  staticClass: "panel my-1",
                  attrs: { flat: "", height: "2.5rem" },
                },
                [
                  _c(
                    VCardText,
                    { staticClass: "py-1 px-1 attribute-list" },
                    [
                      _c(
                        VLayout,
                        [
                          _c(
                            VFlex,
                            {
                              staticClass: "text-truncate",
                              attrs: { xs11: "" },
                            },
                            [
                              _c(
                                "span",
                                { class: _vm.disabled ? "" : "black--text" },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(attribute.displayDescription) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            VFlex,
                            {
                              staticClass: "draggable-attribute-icon",
                              attrs: { xs1: "" },
                            },
                            [
                              _c(
                                VIcon,
                                {
                                  staticClass: "drag-icon",
                                  class: _vm.disabled
                                    ? "disabled-drag-icon"
                                    : "",
                                  attrs: { "text-xs-right": "" },
                                },
                                [
                                  _vm._v(
                                    "\n                open_with\n              "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VCol,
        { attrs: { md: "8" } },
        [
          _c("div", { staticClass: "py-2" }, [
            _c("span", { staticClass: "item-text font-weight-bold" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.setArchitectureRules.subGroupSplittingAttributes"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "architecture-drivers",
              attrs: {
                group: "draggable-attribute-lists",
                list: _vm.subGroupSplittingAttributes,
                handle: ".draggable-attribute-icon",
              },
              on: { change: _vm.handleMoveToSubGroup },
            },
            [
              _vm.architectureDataLoading
                ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
                : [
                    _vm._l(
                      _vm.subGroupSplittingAttributes,
                      function (attribute) {
                        return _c("architecture-sub-group", {
                          key: attribute.attributeKey,
                          attrs: {
                            attribute: attribute,
                            disabled: _vm.disabled,
                          },
                          on: {
                            removeFromSubGroupSplittingAttributes:
                              _vm.removeFromSubGroupSplittingAttributes,
                          },
                        })
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      VLayout,
                      { staticClass: "drag-zone" },
                      [
                        _c(
                          VFlex,
                          {
                            staticClass: "pr-1",
                            attrs: { xs11: "", grow: "" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "item-text font-weight-bold" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.setArchitectureRules.draggableSubGroupMessage"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
            ],
            2
          ),
          _vm._v(" "),
          _c("div", { staticClass: "item-text py-2" }, [
            _c("span", { staticClass: "font-weight-bold" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t("settings.setArchitectureRules.architectureDrivers")
                  ) +
                  "\n      "
              ),
            ]),
            _vm._v(" "),
            _c("span", { staticClass: "font-weight-light" }, [
              _vm._v(
                "\n        " +
                  _vm._s(
                    _vm.$t(
                      "settings.setArchitectureRules.appliedToAllSubGroups"
                    )
                  ) +
                  "\n      "
              ),
            ]),
          ]),
          _vm._v(" "),
          _c(
            "draggable",
            {
              staticClass: "draggable-element architecture-drivers",
              attrs: {
                group: "draggable-attribute-lists",
                list: _vm.currentArchitectureDrivers,
                disabled: _vm.disabled,
                handle: ".draggable-attribute-icon",
              },
              on: { change: _vm.handleDragDriverChange },
            },
            [
              _vm.architectureDataLoading
                ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
                : [
                    _vm._l(
                      _vm.currentArchitectureDrivers,
                      function (architectureDriverAttribute) {
                        return _c("architecture-driver", {
                          key: architectureDriverAttribute.attributeKey,
                          attrs: {
                            "driver-attribute": architectureDriverAttribute,
                            disabled: _vm.disabled,
                          },
                          on: {
                            removeFromAttributesDrivers:
                              _vm.removeFromAttributesDrivers,
                            updateArchitectureDriver: _vm.createUpdate,
                          },
                        })
                      }
                    ),
                    _vm._v(" "),
                    _c(
                      VLayout,
                      { staticClass: "drag-zone" },
                      [
                        _c(
                          VFlex,
                          {
                            staticClass: "pr-1",
                            attrs: { xs11: "", grow: "" },
                          },
                          [
                            _c(
                              "span",
                              { staticClass: "item-text font-weight-bold" },
                              [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "settings.setArchitectureRules.draggableDriverMessage"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]
                            ),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
              _vm._v(" "),
              _c(
                VLayout,
                { staticClass: "mt-2", attrs: { "justify-end": "" } },
                [
                  _c(
                    VFlex,
                    { attrs: { xs2: "", grow: "" } },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "save",
                          attrs: {
                            disabled:
                              _vm.isSaveDisabled || _vm.architectureDataLoading,
                            color: "success",
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.saveUpdates },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }