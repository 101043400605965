<template>
  <div>
    <work-packages
      heading="home.templateHeading"
      :filtered-workpackages="masterWorkpackages"
      :sort-desc="false"
      :allow-new="false"
      :search-workpackages="false"
      e2e-slug="panelTemplateWP"
    />

    <work-packages
      heading="home.activeHeading"
      :filtered-workpackages="activeWorkpackages"
      :sort-desc="false"
      :allow-new="true"
      e2e-slug="panelActiveWP"
    />
    <work-packages
      heading="home.archivedHeading"
      :filtered-workpackages="archivedWorkpackages"
      :sort-desc="true"
      :allow-new="false"
      :expanded="false"
      e2e-slug="panelArchivedWP"
      @expand="getInactiveWorkpackages()"
    />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import { isEmpty, filter, has } from 'lodash';

export default {
  computed: {
    ...mapState('workpackages', ['workpackages']),
    ...mapGetters('workpackages', [
      'masterWorkpackages',
      'activeWorkpackages',
      'archivedWorkpackages',
      'hasSelectedWorkpackage',
    ]),
  },

  async created() {
    const workpackageIds = await this.getActiveWorkpackages();
    const params = {
      workpackageIds,
    };
    await Promise.all([
      this.fetchHierarchiesForWorkpackages({ params }),
      // Disabled as inefficient, will revisit in future PR
      // this.getPriceChangeData({ params }),
    ]);
  },

  methods: {
    ...mapActions('workpackages', ['fetchWorkpackages', 'getPriceChangeData']),
    ...mapActions('hierarchy', ['fetchHierarchiesForWorkpackages']),

    async getActiveWorkpackages() {
      return this.fetchWorkpackages({ params: { wpActive: true } });
    },

    async getInactiveWorkpackages() {
      if (isEmpty(filter(this.archivedWorkpackages, wp => !has(wp, 'mocked')))) {
        const workpackageIds = await this.fetchWorkpackages({ params: { wpActive: false } });
        const params = {
          workpackageIds,
        };
        await Promise.all([
          this.fetchHierarchiesForWorkpackages({ params }),
          // Disabled as inefficient, will revisit in future PR
          // this.getPriceChangeData({ params }),
        ]);
      }
    },
  },
};
</script>
