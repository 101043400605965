import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        "close-on-content-click": false,
        transition: "scale-transition",
        "offset-y": "",
        "min-width": "auto",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on, attrs }) {
            return [
              _c(
                VTextField,
                _vm._g(
                  _vm._b(
                    {
                      attrs: {
                        label: _vm.label,
                        "prepend-icon": "mdi-calendar",
                        readonly: "",
                      },
                      model: {
                        value: _vm.internalValue,
                        callback: function ($$v) {
                          _vm.internalValue = $$v
                        },
                        expression: "internalValue",
                      },
                    },
                    "v-text-field",
                    attrs,
                    false
                  ),
                  on
                )
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.menu,
        callback: function ($$v) {
          _vm.menu = $$v
        },
        expression: "menu",
      },
    },
    [
      _vm._v(" "),
      _c(VDatePicker, {
        attrs: { "no-title": "", scrollable: "" },
        on: { input: _vm.closeMenu },
        model: {
          value: _vm.internalValue,
          callback: function ($$v) {
            _vm.internalValue = $$v
          },
          expression: "internalValue",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }