import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "workpackage-unit" },
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            {
              staticClass:
                "pb-0 pt-0 pl-4 d-flex align-center justify-space-between",
              attrs: { cols: "12" },
            },
            [
              _c(
                "div",
                { staticClass: "d-flex", on: { click: _vm.selectUnit } },
                [
                  _c("tooltipped-truncated-field", {
                    staticClass: "link-arrow-text",
                    attrs: {
                      text: _vm.unitData.levelEntryDescription,
                      "truncation-length": _vm.truncationLength,
                    },
                  }),
                  _vm._v(" "),
                  !_vm.preventUnitSelection
                    ? _c("div", { staticClass: "d-flex link-arrow" })
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _vm.isSelectable
                ? _c(VCheckbox, {
                    staticClass: "checkbox my-0 mr-1 pa-0",
                    attrs: { value: _vm.hasSelectedAll },
                    on: {
                      change: function ($event) {
                        return _vm.selectAllCategories()
                      },
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      !_vm.isSelectable
        ? _c(
            "div",
            { staticClass: "d-flex" },
            [
              _vm._l(_vm.kpiData, function (icon, index) {
                return _c(
                  "div",
                  { key: index, staticClass: "mr-1" },
                  [
                    _c("work-packages-unit-kpi-icon", {
                      attrs: {
                        number: icon.number,
                        "tooltip-message": icon.tooltipMessage,
                        rejected: icon.rejected,
                        "user-role": icon.userRole,
                      },
                    }),
                  ],
                  1
                )
              }),
              _vm._v(" "),
              _c(
                "div",
                { staticClass: "mr-1" },
                [
                  _c("approval-icon", {
                    attrs: {
                      approved: _vm.unitComplete,
                      "unit-level": true,
                      "tooltip-position": "top-left",
                      "tooltip-message": _vm.$t("workPackages.unitComplete"),
                    },
                  }),
                ],
                1
              ),
            ],
            2
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.isExpanded
        ? _c(
            VContainer,
            {
              class: _vm.isSelectable ? "border-creation" : "border",
              attrs: { fluid: "", "pr-0": "", "pl-0": "", "pb-0": "" },
            },
            [
              _c(VRow, { staticClass: "header mr-0" }),
              _vm._v(" "),
              _vm._l(_vm.unitCategories, function (category, index) {
                return _c(
                  VRow,
                  {
                    key: index,
                    class: [index % 2 ? "even-rows" : ""],
                    attrs: { "no-gutters": "" },
                  },
                  [
                    _c(
                      "div",
                      { staticClass: "category d-flex justify-space-between" },
                      [
                        _c(
                          "div",
                          { staticClass: "px-3" },
                          [
                            _c("tooltipped-truncated-field", {
                              attrs: {
                                text: category.levelEntryDescription,
                                "truncation-length": _vm.truncationLength,
                              },
                            }),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          "div",
                          { staticClass: "px-1 d-flex" },
                          [
                            _vm.isSelectable
                              ? _c(VCheckbox, {
                                  staticClass: "checkbox ma-0 pa-0",
                                  attrs: { value: category.levelEntryKey },
                                  on: {
                                    change: function ($event) {
                                      return _vm.updateCategories($event)
                                    },
                                  },
                                  model: {
                                    value: _vm.categories,
                                    callback: function ($$v) {
                                      _vm.categories = $$v
                                    },
                                    expression: "categories",
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isSelectable
                              ? _c("approval-icon", {
                                  staticClass: "pr-1",
                                  attrs: {
                                    "tooltip-message": _vm.$t(
                                      "gridView.tooltips.categoryManagerApproval"
                                    ),
                                    "tooltip-position": "top-left",
                                    approved: _vm.isCategoryApproved(
                                      category,
                                      "categoryManagerApproval"
                                    ),
                                  },
                                })
                              : _vm._e(),
                            _vm._v(" "),
                            !_vm.isSelectable
                              ? _c("approval-icon", {
                                  attrs: {
                                    "tooltip-message": _vm.$t(
                                      "gridView.tooltips.pricingSpecialistApproval"
                                    ),
                                    "tooltip-position": "top-left",
                                    approved: _vm.isCategoryApproved(
                                      category,
                                      "pricingSpecialistApproval"
                                    ),
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]
                    ),
                  ]
                )
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }