import { VFlex } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "form-container" },
    [
      _c("span", { staticClass: "form-header mb-3" }, [
        _vm._v(_vm._s(_vm.penaltyName)),
      ]),
      _vm._v(" "),
      _c(
        VForm,
        {
          model: {
            value: _vm.isFormValid,
            callback: function ($$v) {
              _vm.isFormValid = $$v
            },
            expression: "isFormValid",
          },
        },
        [
          _c(
            VLayout,
            { attrs: { "justify-space-between": "" } },
            [
              _c(
                VFlex,
                { attrs: { xs7: "" } },
                [
                  _c(VSelect, {
                    attrs: {
                      flat: "",
                      "background-color": _vm.owWhite,
                      items: _vm.penaltyTypes,
                      label: _vm.$t("settings.parameters.penaltyType"),
                      disabled: _vm.disabled,
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c("span", { staticClass: "item-text" }, [
                              _vm._v(_vm._s(props.item.text)),
                            ]),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.mutableRule.penaltyType,
                      callback: function ($$v) {
                        _vm.$set(_vm.mutableRule, "penaltyType", $$v)
                      },
                      expression: "mutableRule.penaltyType",
                    },
                  }),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      "background-color": _vm.owWhite,
                      label: _vm.$t("settings.parameters.penaltyPower"),
                      step: "0.1",
                      type: "number",
                      rules: _vm.rules.penaltyPower,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.penaltyPower,
                      callback: function ($$v) {
                        _vm.penaltyPower = _vm._n($$v)
                      },
                      expression: "penaltyPower",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VLayout,
            { attrs: { "justify-space-between": "", "align-baseline": "" } },
            [
              _c(VFlex, { attrs: { xs1: "", "align-center": "" } }, [
                _c("span", { staticClass: "text-strong" }, [
                  _vm._v(_vm._s(_vm.scatterPoints[0])),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs6: "", "align-baseline": "", "d-flex": "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      "background-color": _vm.owWhite,
                      step: "0.1",
                      label: _vm.$t("settings.parameters.penaltyDeviation"),
                      type: "number",
                      rules: _vm.rules.firstDeviationPercent,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.firstDeviationPercent,
                      callback: function ($$v) {
                        _vm.firstDeviationPercent = _vm._n($$v)
                      },
                      expression: "firstDeviationPercent",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v(" % ")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      flat: "",
                      "background-color": _vm.owWhite,
                      label: _vm.$t("settings.parameters.penalty"),
                      type: "number",
                      rules: _vm.rules.firstDeviationPenalty,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.firstDeviationPenalty,
                      callback: function ($$v) {
                        _vm.firstDeviationPenalty = _vm._n($$v)
                      },
                      expression: "firstDeviationPenalty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VLayout,
            { attrs: { "justify-space-between": "", "align-baseline": "" } },
            [
              _c(VFlex, { attrs: { xs1: "", "align-center": "" } }, [
                _c("span", { staticClass: "text-strong" }, [
                  _vm._v(_vm._s(_vm.scatterPoints[1])),
                ]),
              ]),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs6: "", "d-flex": "", "align-baseline": "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      flat: "",
                      "background-color": _vm.owWhite,
                      step: "0.1",
                      label: _vm.$t("settings.parameters.penaltyDeviation"),
                      type: "number",
                      rules: _vm.rules.secondDeviationPercent,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.secondDeviationPercent,
                      callback: function ($$v) {
                        _vm.secondDeviationPercent = _vm._n($$v)
                      },
                      expression: "secondDeviationPercent",
                    },
                  }),
                  _vm._v(" "),
                  _c("span", { staticClass: "suffix" }, [_vm._v(" % ")]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs4: "" } },
                [
                  _c(VTextField, {
                    attrs: {
                      flat: "",
                      "background-color": _vm.owWhite,
                      label: _vm.$t("settings.parameters.penalty"),
                      type: "number",
                      rules: _vm.rules.secondDeviationPenalty,
                      disabled: _vm.disabled,
                    },
                    model: {
                      value: _vm.secondDeviationPenalty,
                      callback: function ($$v) {
                        _vm.secondDeviationPenalty = _vm._n($$v)
                      },
                      expression: "secondDeviationPenalty",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _vm.metaErrors.length
            ? _c(
                VLayout,
                { staticClass: "meta-errors" },
                _vm._l(_vm.metaErrors, function (error, index) {
                  return _c(VLayout, { key: `error-${index}` }, [
                    _vm._v("\n        " + _vm._s(error) + "\n      "),
                  ])
                }),
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }