import colors from './ow-colors';

export default {
  chart: {
    type: 'column',
    margin: null,
  },
  yAxis: {
    endOnTick: true,
    startOnTick: true,
    tickPositioner() {
      const maxDeviation = Math.ceil(Math.max(Math.abs(this.dataMax), Math.abs(this.dataMin)));
      const halfMaxDeviation = Math.ceil(maxDeviation / 2);

      return [-maxDeviation, -halfMaxDeviation, 0, halfMaxDeviation, maxDeviation];
    },
    plotLines: [
      {
        value: 0,
        color: colors.tensionPlotLineColor,
        width: 1,
      },
    ],
  },
};
