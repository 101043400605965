import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDataTable,
    {
      staticClass: "products-table",
      attrs: {
        dense: "",
        loading:
          _vm.isCalculatingImpactFactor ||
          !_vm.architectureProductsLoaded ||
          _vm.architectureProductsLoading,
        options: _vm.pagination,
        "sort-desc": _vm.pagination.descending,
        headers: _vm.flattenHeaders,
        items: _vm.architectureProductList,
        "item-key": "_id",
        "hide-default-header": "",
        "hide-default-footer": "",
        "custom-sort": _vm.customProductSort,
      },
      on: {
        "update:options": function ($event) {
          _vm.pagination = $event
        },
        "update:sortDesc": function ($event) {
          return _vm.$set(_vm.pagination, "descending", $event)
        },
        "update:sort-desc": function ($event) {
          return _vm.$set(_vm.pagination, "descending", $event)
        },
      },
      scopedSlots: _vm._u(
        [
          {
            key: "top",
            fn: function () {
              return [_vm._t("default")]
            },
            proxy: true,
          },
          {
            key: "header",
            fn: function ({ props }) {
              return [
                _c(
                  "thead",
                  _vm._l(_vm.allHeaders, function (row, index) {
                    return _c(
                      "tr",
                      {
                        key: `header-${index}`,
                        staticClass: "v-data-table-header",
                      },
                      _vm._l(row, function (header) {
                        return _c(
                          "th",
                          {
                            key: `header-${index}-${
                              header.key || header.value
                            }`,
                            class: [
                              ..._vm.getHeaderClasses(header, _vm.pagination),
                              header.class,
                            ],
                            attrs: {
                              colspan: header.colspan,
                              rowspan: header.rowspan,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.changeSort({ header })
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n          " +
                                _vm._s(header.text) +
                                "\n          "
                            ),
                            header.sortable
                              ? _c(
                                  VIcon,
                                  {
                                    staticClass: "v-data-table-header__icon",
                                    attrs: { size: "1.4rem" },
                                  },
                                  [
                                    _vm._v(
                                      "\n            arrow_upward\n          "
                                    ),
                                  ]
                                )
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      0
                    )
                  }),
                  0
                ),
              ]
            },
          },
          {
            key: "item",
            fn: function (props) {
              return [
                _c(
                  "tr",
                  {
                    class: {
                      [_vm.generalCssClasses.evenRows]: props.index % 2,
                    },
                  },
                  _vm._l(_vm.flattenHeaders, function (column) {
                    return _c(
                      "td",
                      { key: column.key, class: column.class },
                      [
                        column.key === _vm.columnKeys.productKey
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(props.item.article.productKeyDisplay) +
                                  "\n        "
                              ),
                            ]
                          : column.key === _vm.columnKeys.toolStoreGroup
                          ? [
                              _c("tooltipped-truncated-field", {
                                attrs: {
                                  text: _vm.storegroupKeyNameMap[
                                    props.item.toolStoreGroupKey
                                  ],
                                  "truncation-length":
                                    _vm.truncationLength
                                      .toolStoreGroupDescription,
                                },
                              }),
                            ]
                          : column.key === _vm.columnKeys.article
                          ? [
                              _c("tooltipped-truncated-field", {
                                attrs: {
                                  text: props.item.article.productDescription,
                                  "truncation-length":
                                    _vm.getProductDescriptionWidth,
                                },
                              }),
                            ]
                          : column.key === _vm.columnKeys.size
                          ? _c("norm-weight-tooltip", {
                              attrs: {
                                value: props.item.article.productSizeType,
                                "norm-weight": props.item.attributes.normWeight,
                                "norm-weight-uo-m":
                                  props.item.attributes.normWeightUnitOfMeasure,
                              },
                            })
                          : column.key === _vm.columnKeys.packageType
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    props.item.attributes.packageTypeDescription
                                  ) +
                                  "\n        "
                              ),
                            ]
                          : column.key === _vm.columnKeys.costPrice
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.getIntentionCost(props.item),
                                      format: _vm.numberFormats.priceFormat,
                                      nullAsDash: true,
                                    })
                                  ) +
                                  "\n        "
                              ),
                            ]
                          : column.key === _vm.columnKeys.livePrice
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.formatNumber({
                                      number: _vm.getLivePrice(props.item),
                                      format: _vm.numberFormats.priceFormat,
                                      nullAsDash: true,
                                    })
                                  ) +
                                  "\n        "
                              ),
                            ]
                          : column.key === _vm.columnKeys.subGroup
                          ? [
                              _vm._v(
                                "\n          " +
                                  _vm._s(props.item.subGroup) +
                                  "\n        "
                              ),
                            ]
                          : column.key === _vm.columnKeys.impactDrivers
                          ? _c(
                              "div",
                              {
                                class: [
                                  props.item.productImpact
                                    .impactFactorCustomOffset !== 1
                                    ? "grey--text"
                                    : "",
                                  ..._vm.getImpactFactorClass(
                                    props.item.productImpact
                                  ),
                                ],
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.getImpactFactor(
                                        props.item.productImpact.impactFactor
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : column.key === _vm.columnKeys.impactOffset
                          ? _c(
                              "form",
                              {
                                class: _vm.getImpactFactorClass(
                                  props.item.productImpact
                                ),
                                attrs: { novalidate: "" },
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      position: "bottom-left",
                                      value: _vm.$t(
                                        "settings.setArchitectureRules.architectureDriversRules.productTable.offsetError"
                                      ),
                                      disabled: _vm.validOffsetCheck(
                                        props.item.productImpact
                                          .impactFactorCustomOffset
                                      ),
                                      "is-error": _vm.hasValidationError,
                                    },
                                  },
                                  [
                                    _c(VTextField, {
                                      staticClass: "pt-0",
                                      attrs: {
                                        "background-color": _vm.owWhite,
                                        rules: _vm.rules,
                                        step: "0.1",
                                        type: "number",
                                        "hide-details": "",
                                        "single-line": "",
                                        height: "2rem",
                                        disabled:
                                          !!_vm.architectureDriverUpdatesAvailable ||
                                          _vm.disabled,
                                      },
                                      on: {
                                        "update:error": _vm.hasValidationError,
                                        input: function ($event) {
                                          return _vm.calculateFinalImpact({
                                            productKey:
                                              props.item.article.productKey,
                                            toolStoreGroupKey:
                                              props.item.toolStoreGroupKey,
                                            impactFactor:
                                              props.item.productImpact
                                                .impactFactor,
                                            offset:
                                              props.item.productImpact
                                                .impactFactorCustomOffset,
                                          })
                                        },
                                      },
                                      model: {
                                        value:
                                          props.item.productImpact
                                            .impactFactorCustomOffset,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.item.productImpact,
                                            "impactFactorCustomOffset",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "props.item.productImpact.impactFactorCustomOffset",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            )
                          : column.key === _vm.columnKeys.impactFinal
                          ? _c(
                              "tooltip",
                              {
                                attrs: {
                                  position: "bottom-left",
                                  value: _vm.$t(
                                    "settings.setArchitectureRules.finalImpactCalculation"
                                  ),
                                },
                              },
                              [
                                _vm._v(
                                  "\n          " +
                                    _vm._s(
                                      _vm.getImpactFactor(
                                        props.item.productImpact
                                          .impactFactorFinal
                                      )
                                    ) +
                                    "\n        "
                                ),
                              ]
                            )
                          : _vm._e(),
                      ],
                      2
                    )
                  }),
                  0
                ),
              ]
            },
          },
        ],
        null,
        true
      ),
    },
    [
      _vm._v(" "),
      _vm._v(" "),
      _vm._v(" "),
      _c(
        "template",
        { slot: "footer" },
        [
          _c(
            VRow,
            { staticClass: "ma-0" },
            [
              _c(
                VCol,
                { attrs: { md: "2" } },
                [
                  _c(
                    VBtn,
                    {
                      directives: [
                        {
                          name: "show",
                          rawName: "v-show",
                          value:
                            _vm.finalImpactUpdateAvailable &&
                            !_vm.architectureProductsLoading,
                          expression:
                            "finalImpactUpdateAvailable && !architectureProductsLoading",
                        },
                      ],
                      staticClass: "save",
                      attrs: {
                        disabled: _vm.disabled,
                        color: "success ml-2 mt-1",
                        small: "",
                        depressed: "",
                      },
                      on: { click: _vm.updateFinalImpacts },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCol,
                { attrs: { md: "6", "offset-md": "4" } },
                [
                  _c(VPagination, {
                    attrs: { length: _vm.noOfPages },
                    model: {
                      value: _vm.pagination.page,
                      callback: function ($$v) {
                        _vm.$set(_vm.pagination, "page", $$v)
                      },
                      expression: "pagination.page",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }