'use strict';

module.exports = {
  contains: 'contains',
  equals: 'equals',
  notEqual: 'notEqual',
  greaterThan: 'greaterThan',
  lessThan: 'lessThan',
  greaterThanOrEqual: 'greaterThanOrEqual',
  lessThanOrEqual: 'lessThanOrEqual',
  isOneOf: 'isOneOf',
};
