<template>
  <div v-show="hasSelectedWorkpackage" class="input-screen-page-wrapper">
    <v-row no-gutters>
      <v-col>
        <price-overrides-upload
          v-if="canEditPriceOverrides"
          class="pb-2"
          :params="getBaseParams()"
        />
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="search-field py-0"
          :value="search"
          append-icon="search"
          :label="$t('actions.search')"
          single-line
          @input="debounceSearchUpdate"
        />
      </v-col>
    </v-row>

    <!-- FEATURE_FLAG: temporary until attribute filter and hierarchy columns are released -->
    <feature-toggle :toggle="displayHierarchyAndFilter">
      <attribute-filter-panel
        title-localisation="attributes.filters.filterByAttributes"
        filter-count-localisation="attributes.filters.numApplied"
        enable-hierarchy-filters
        :filter-rules="retailAttributesFilter"
        @attributeFilterChange="setFilterRules"
      />
    </feature-toggle>

    <v-data-table
      :items="priceOverrideItems"
      :headers="headers"
      :loading="loading"
      :custom-filter="customFilter"
      :height="dataTableHeight"
      :loading-text="$t('general.loadingMessage')"
      disable-sort
      disable-pagination
      dense
      item-key="productKeyDisplay"
      class="pricing-table elevation-1 striped-table"
      fixed-header
      hide-default-header
      hide-default-footer
      @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table__wrapper')"
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.value"
              :class="[...getHeaderClasses(header, pagination), header.class]"
              @click="changeSort(header)"
            >
              <span>
                {{ header.text }}
                <v-icon v-if="header.sortable" size="1.4rem" class="v-data-table-header__icon">
                  arrow_upward
                </v-icon>
              </span>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="props">
        <tr :key="props.index">
          <td class="text-xs table-cell" :class="fixedColumnsClass">
            {{ props.item.productKeyDisplay }}
          </td>

          <!-- FEATURE_FLAG: display a tool store group cell -->
          <td v-if="toolStoreGroupColumn" class="text-xs table-cell" :class="fixedColumnsClass">
            <tooltipped-truncated-field
              :text="props.item.toolStoreGroupDescription"
              :truncation-length="truncationLengthMedium"
              class="tooltipped-truncated-field"
            />
          </td>

          <td class="text-xs table-cell border-right" :class="fixedColumnsClass">
            {{ props.item.productDescription }}
          </td>

          <!-- Hierarchy columns -->
          <feature-toggle :toggle="displayHierarchyAndFilter">
            <td
              v-for="level in [categoryLevel, pricingGroupLevel, architectureGroupLevel]"
              :key="level"
              class="text-xs table-cell"
              :class="{ 'border-right': level === architectureGroupLevel }"
            >
              <tooltipped-truncated-field
                :text="getHierarchyName(props.item, level)"
                :truncation-length="truncationLengthMedium"
              />
            </td>
          </feature-toggle>

          <!-- Price columns -->
          <td class="text-xs table-cell">
            <pricing-edit-text-field
              :disabled="!canEditPriceOverrides"
              class="float-left"
              :data-type="dataTypes.number"
              :number-format="numberFormats.priceFormat"
              :value="getValue(props.item, 'price')"
              :previous-value="getPreviousValue(props.item, 'price')"
              previous-value-translation-key="priceOverrides.editor"
              :cell-class="getInputEditFieldClass(props.item, 'price')"
              :error="updateErrors[props.item.keys['price']]"
              @change="recordChanges($event.target.value, props.item)"
            />
          </td>
          <td class="text-xs table-cell">
            <pricing-edit-text-field
              disabled
              class="float-left"
              :data-type="dataTypes.number"
              :number-format="numberFormats.priceFormat"
              :value="getValue(props.item, 'referencePriceAtOverride')"
              :previous-value="getPreviousValue(props.item, 'referencePriceAtOverride')"
              :cell-class="getInputEditFieldClass(props.item, 'referencePriceAtOverride')"
              previous-value-translation-key="priceOverrides.editor"
            />
          </td>
          <!-- Date columns -->
          <td class="text-xs table-cell">
            <v-menu
              :disabled="!canEditPriceOverrides"
              :close-on-content-click="false"
              :nudge-left="5"
              offset-x
              left
              :top="props.index > pagination.itemsPerPage / 2 - 1"
              min-width="50"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <pricing-edit-text-field
                    :disabled="false"
                    class="float-left"
                    :data-type="dataTypes.str"
                    :value="getValue(props.item, 'effectiveDate')"
                    :previous-value="getPreviousValue(props.item, 'effectiveDate')"
                    :prevent-formatting="true"
                    previous-value-translation-key="priceOverrides.editor"
                    :cell-class="getInputEditFieldClass(props.item, 'effectiveDate')"
                    :error="updateErrors[props.item.keys['effectiveDate']]"
                  />
                </div>
              </template>
              <v-date-picker
                :value="getDateValue(props.item, 'effectiveDate', true)"
                :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                :min="getTodaysDate"
                :locale="i18nconfig.fallbackLocale"
                @input="selectDate(props.item, $event, 'effectiveDate')"
              />
            </v-menu>
          </td>
          <td class="text-xs table-cell">
            <v-menu
              :disabled="!canEditPriceOverrides"
              :close-on-content-click="false"
              :nudge-left="5"
              offset-x
              left
              :top="props.index > pagination.itemsPerPage / 2 - 1"
              min-width="50"
              transition="scale-transition"
            >
              <template v-slot:activator="{ on }">
                <div v-on="on">
                  <pricing-edit-text-field
                    :disabled="false"
                    class="float-left"
                    :data-type="dataTypes.str"
                    :value="getValue(props.item, 'expiryDate')"
                    :previous-value="getPreviousValue(props.item, 'expiryDate')"
                    :prevent-formatting="true"
                    previous-value-translation-key="priceOverrides.editor"
                    :cell-class="getInputEditFieldClass(props.item, 'expiryDate')"
                    :error="updateErrors[props.item.keys['expiryDate']]"
                    tooltip-position="bottom-left"
                  />
                </div>
              </template>
              <v-date-picker
                :value="getDateValue(props.item, 'expiryDate', true)"
                :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                :min="getDateValue(props.item, 'effectiveDate', true) || getTodaysDate"
                :locale="i18nconfig.fallbackLocale"
                :show-current="false"
                @input="selectDate(props.item, $event, 'expiryDate')"
              />
            </v-menu>
          </td>
        </tr>
      </template>

      <template slot="footer">
        <v-flex :class="{ 'input-screen-sticky-table-footer': showHierarchy }">
          <v-row justify="end">
            <v-col md="3" offset-md="8">
              <v-pagination v-model="pagination.page" :length="pages" @input="loadPriceOverrides" />
            </v-col>
          </v-row>
          <v-row justify="end" class="pr-6 pb-2" align="center">
            <v-btn
              color="primary"
              small
              :loading="downloading"
              class="mr-3"
              depressed
              @click="downloadOverrides"
            >
              {{ $t('actions.download') }}
              <v-icon small>$export</v-icon>
            </v-btn>
            <span class="mr-3 btn-divider" />
            <v-btn
              class="save"
              :disabled="isSaveDisabled"
              :loading="loading"
              color="success"
              small
              depressed
              @click="persistUpdates"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-row>
        </v-flex>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import { ceil, debounce, get, isEmpty, isNil, isNaN, some, keys, values, reduce } from 'lodash';
import sortDirectionEnums from '@enums/sort-direction';
import { displayHierarchyAndFilter, useZones } from '@enums/feature-flags';
import mandatoryEngineInputs from '@enums/mandatory-engine-inputs';
import costFields from '@enums/cost-fields';
import { yearMonthDayFormat } from '@enums/date-formats';
import customFilter from '../../../utils/filter-util';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import { maxDateTimestamp } from '@enums/dates';
import DataTypes from '@enums/data-types';
import numberFormats from '@enums/number-formats';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import inputUpdateKey from '../../../mixins/inputUpdateKeys';
import inputScreens from '../../../mixins/inputScreensTable';
import { inputTableCssClasses } from '@enums/tables';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import { preventPageNavigationOnHorizontalScroll } from '../../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [featureFlagsMixin, tablesMixin, inputScreens, inputUpdateKey],
  data() {
    return {
      search: '',
      priceOverrideFields: [
        { field: 'price', type: DataTypes.number, text: this.$t('priceOverrides.editor.price') },
        {
          field: 'referencePriceAtOverride',
          type: DataTypes.number,
          text: this.$t('priceOverrides.editor.referencePriceAtOverride'),
        },
        {
          field: 'effectiveDate',
          type: DataTypes.str,
          text: this.$t('priceOverrides.editor.effectiveDate'),
        },
        {
          field: 'expiryDate',
          type: DataTypes.str,
          text: this.$t('priceOverrides.editor.expiryDate'),
        },
      ],
      categoryLevel,
      pricingGroupLevel,
      architectureGroupLevel,
      truncationLengthMedium: 28,
      displayHierarchyAndFilter,
      pagination: {
        // used by v-data-table for sorting and pagination
        descending: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'productKeyDisplay',
        sortDataType: DataTypes.str,
      },
      dataTableHeight: 700, // default value, requires explicit height to get fixed table header
      useZones,
      mandatoryEngineInputs,
      dataTypes: DataTypes,
      numberFormats,
      updates: {},
      updateErrors: {},
      updatedRowsDetailsMap: {},
      editableFields: [
        ...values(costFields),
        'overrideDate',
        'overrideTimestamp',
        'referencePriceAtOverride',
        'isSmart',
      ],
      maxMomentDate: moment.utc(maxDateTimestamp),
      maxDateChecksMap: new Map(),
    };
  },

  computed: {
    ...mapState('scenarioProducts', ['downloading', 'loading', 'priceOverrides', 'count']),
    ...mapGetters('workpackages', ['hasSelectedWorkpackage']),
    ...mapState('clientConfig', ['toggleLogic', 'i18nconfig', 'exportConfigs', 'dateFormats']),
    ...mapState('filters', [
      'inputScreensFetchParams',
      'inputScreensFilterRules',
      'retailAttributesFilter',
    ]),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapGetters('attributes', ['attributeMetadata']),

    priceOverrideItems() {
      if (this.loading) {
        return [];
      }
      return this.priceOverrides.map(product => {
        product.keys = {};
        this.editableFields.forEach(field => {
          product.keys[field] = this.getInputUpdateKey(product._id, field);
        });
        return product;
      });
    },

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            text: this.$t('priceOverrides.editor.toolStoreGroup'),
            value: 'toolStoreGroupDescription',
            sortable: true,
            align: 'start',
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
            dataType: DataTypes.str,
          }
        : null;
    },

    fixedColumnsClass() {
      return this.toolStoreGroupColumn
        ? inputTableCssClasses.threeFixedColumns
        : inputTableCssClasses.twoFixedColumns;
    },

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    exportTranslationMap() {
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};
      const toolStoreGroupTranslation = this.toolStoreGroupColumn
        ? { toolStoreGroupDescription: this.toolStoreGroupColumn.text }
        : {};
      const priceOverrideFields = this.priceOverrideFields.reduce((acc, fieldObj) => {
        acc[fieldObj.field] = fieldObj.text;
        return acc;
      }, {});
      return {
        productKeyDisplay: this.$t('priceOverrides.editor.productKey'),
        ...toolStoreGroupTranslation,
        productDescription: this.$t('priceOverrides.editor.productDescription'),
        ...hierarchyColumnTranslations,
        ...priceOverrideFields,
      };
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    headers() {
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('priceOverrides.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('priceOverrides.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productDescription',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        ...this.hierarchyHeaders,
        {
          text: this.$t('priceOverrides.editor.price'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.price',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('priceOverrides.editor.referencePriceAtOverride'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.referencePriceAtOverride',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('priceOverrides.editor.effectiveDate'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.effectiveTimestamp',
          isDate: true,
          dataType: DataTypes.number,
          formatter: {
            type: DataTypes.date,
            format: this.dateFormats.clientDateFormatForMoment,
          },
        },
        {
          text: this.$t('priceOverrides.editor.expiryDate'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.expiryTimestamp',
          isDate: true,
          dataType: DataTypes.number,
          formatter: {
            type: DataTypes.date,
            format: this.dateFormats.clientDateFormatForMoment,
          },
        },
      ];
    },

    canEditPriceOverrides() {
      return this.isPricingSpecialist;
    },

    pages() {
      return ceil(this.count / this.pagination.itemsPerPage);
    },

    getTodaysDate() {
      return moment().format(yearMonthDayFormat);
    },

    hasInvalidUpdates() {
      return some(this.updateErrors);
    },

    hasUnsavedUpdates() {
      return keys(this.updates).length > 0;
    },

    isSaveDisabled() {
      return !this.hasUnsavedUpdates || !this.canEditPriceOverrides || this.hasInvalidUpdates;
    },
  },

  async created() {
    if (this.hasSelectedWorkpackage) {
      if (!isEmpty(this.inputScreensFetchParams)) {
        this.pagination = this.inputScreensFetchParams;
        this.pagination.sortBy = this.pagination.sortBy.replace('competitor.', 'competitorPrices.');
        this.pagination.itemsPerPage = 25;
        this.pagination.page = this.getDefaultSelectedPage(this.pagination, this.pages);
        this.search = this.pagination.search;
      }
      await this.loadPriceOverrides();
    }
  },

  destroyed() {
    this.maxDateChecksMap.clear();
  },

  methods: {
    ...mapActions('scenarioProducts', [
      'fetchPriceOverrides',
      'downloadPriceOverrides',
      'updatePriceOverrides',
    ]),
    ...mapActions('filters', ['setSelectedFilter']),
    customFilter,

    recordChanges(rawValue, product) {
      this.validateEntry(rawValue, product);
      const formattedValue = rawValue ? this.formatValue(rawValue) : rawValue;
      this.saveUpdates(product, product.keys.price, formattedValue);
      this.saveUpdates(product, product.keys.overrideDate, moment().format(yearMonthDayFormat));
      this.saveUpdates(product, product.keys.overrideTimestamp, moment().unix());
      this.saveUpdates(
        product,
        product.keys.referencePriceAtOverride,
        product.optimizedPrice || product.scenarioPrice || 0.0
      );
      this.saveUpdates(
        product,
        product.keys.isSmart,
        !!this.getDateValue(product, 'effectiveDate')
      );
    },

    formatValue(rawValue) {
      const numericValue = this.formatStringToNumber(rawValue);
      return isNaN(numericValue) ? rawValue : numericValue;
    },

    validateEntry(value, product) {
      if (value === null) return true;
      const formattedNumber = this.formatStringToNumber(value);
      const validNumber = formattedNumber === 0 || formattedNumber; // 0 would be invalid without explicit check

      if (validNumber) {
        const invalidRange = this.invalidRange(formattedNumber);
        if (invalidRange) {
          this.$set(this.updateErrors, product.keys.price, invalidRange);
        } else {
          // Remove this value from updateErrors, so the page can be saved
          this.$delete(this.updateErrors, product.keys.price);
        }
      } else {
        // Add this value to updateErrors
        this.$set(
          this.updateErrors,
          product.keys.price,
          this.$t('validationErrors.incorrectDataType', {
            dataType: this.dataTypes.number,
          })
        );
      }
      return !!validNumber;
    },

    validateDates(value, product, field) {
      if (value === null) return true;
      if (field === 'effectiveDate') {
        const expiryDate = this.getDateValue(product, 'expiryDate');
        if (!expiryDate) {
          this.$set(
            this.updateErrors,
            product.keys.expiryDate,
            this.$t('priceOverrides.editor.noExpiry')
          );
        }
        if (
          expiryDate &&
          moment(value, yearMonthDayFormat) > moment(expiryDate, yearMonthDayFormat)
        ) {
          this.$set(
            this.updateErrors,
            product.keys.effectiveDate,
            this.$t('priceOverrides.editor.effectiveGreater')
          );
        } else {
          this.$delete(this.updateErrors, product.keys.effectiveDate);
        }
      }
      if (field === 'expiryDate') {
        this.$delete(this.updateErrors, product.keys.expiryDate);
        const effectiveDate = this.getDateValue(product, 'effectiveDate');
        if (
          effectiveDate &&
          moment(value, yearMonthDayFormat) > moment(effectiveDate, yearMonthDayFormat)
        ) {
          this.$delete(this.updateErrors, product.keys.effectiveDate);
        }
      }
      const price = this.getValue(product, 'price');
      if (!price) {
        this.$set(
          this.updateErrors,
          product.keys.price,
          this.$t('priceOverrides.editor.needPrice')
        );
      } else {
        this.$delete(this.updateErrors, product.keys.price);
      }
    },

    invalidRange(value) {
      if (value < 0) {
        return this.$t('engineInputs.editor.validationErrors.mustBePositive');
      }
    },

    async persistUpdates() {
      const params = {
        ...this.getBaseParams(),
        where: this.retailAttributesFilter,
      };
      await this.updatePriceOverrides({
        updates: this.formatUpdatesForSave(),
        params,
      });
      this.clearUpdates();
    },

    clearUpdates() {
      this.updates = {};
      this.updatedRowsDetailsMap = {};
      this.updateErrors = {};
    },

    getInputEditFieldClass(product, field) {
      // invalid value entered
      if (this.updateErrors[product.keys[field]]) {
        return 'attribute-cell-error';
      }
      // value edited and valid (not shown if the updated value is the same as the original)
      if (
        !isNil(this.updates[product.keys[field]]) &&
        this.updates[product.keys[field]] !== get(product, ['overridePrice', field])
      ) {
        return 'attribute-cell-updated';
      }

      return '';
    },

    selectDate(product, event, field) {
      this.validateDates(event, product, field);
      this.saveUpdates(product, product.keys[field], event);
      this.saveUpdates(
        product,
        product.keys[field.replace('Date', 'Timestamp')],
        moment(event, yearMonthDayFormat).unix()
      );
      if (field === 'expiryDate') {
        const effectiveDate = this.getDateValue(product, 'effectiveDate');
        if (!effectiveDate) {
          this.saveUpdates(
            product,
            product.keys.effectiveDate,
            moment().format(yearMonthDayFormat)
          );
          this.saveUpdates(product, product.keys.effectiveTimestamp, moment().unix());
        }
      }
      this.saveUpdates(product, product.keys.isSmart, true);
    },

    getBaseParams() {
      let params = {
        limit: this.pagination.itemsPerPage,
        offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
        search: this.search,
        where: this.retailAttributesFilter,
      };

      if (this.pagination.sortBy) {
        params = {
          ...params,
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.descending
            ? sortDirectionEnums.descending
            : sortDirectionEnums.ascending,
          sortDataType: this.pagination.sortDataType,
        };
      }
      return params;
    },

    async setFilterRules(filterRules) {
      // Reset the pagination as there might be no results on page X
      this.pagination.page = 1;

      this.setSelectedFilter({ filterName: 'retailAttributesFilter', filterValue: filterRules });
      await this.loadPriceOverrides();
    },

    loadPriceOverrides() {
      const params = this.getBaseParams();
      return this.fetchPriceOverrides({ params });
    },

    changeSort(column) {
      if (this.pagination.sortBy === column.value) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = column.value;
        this.pagination.descending = false;
        this.pagination.sortDataType = column.dataType;
      }
      const params = this.getBaseParams();
      return this.fetchPriceOverrides({ params, sortingOnly: true });
    },

    debounceSearchUpdate: debounce(async function(value) {
      this.search = value;
      this.pagination.page = 1;
      await this.loadPriceOverrides();
    }, clientConfig.inputDebounceValue),

    downloadOverrides() {
      this.downloadPriceOverrides({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    getHierarchyName({ hierarchy }, level) {
      return hierarchy[level].levelEntryDescription;
    },

    formatDateValue(date) {
      return this.maxDateExceeded(date) ? '-' : this.formatLocalisedDate(date);
    },

    getValue(product, field) {
      const value = !isNil(this.updates[product.keys[field]])
        ? this.updates[product.keys[field]]
        : get(product, ['overridePrice', field], null);
      const notEmptyDate = ['effectiveDate', 'expiryDate'].includes(field) && value;
      return notEmptyDate ? this.formatDateValue(value) : value;
    },

    getPreviousValue(product, field) {
      const value = get(product, ['overridePrice', field], null);
      const notEmptyDate = ['effectiveDate', 'expiryDate'].includes(field) && value;
      return notEmptyDate ? this.formatDateValue(value) : value;
    },

    getDateValue(product, field, checkOnMaxDate) {
      const date = !isNil(this.updates[product.keys[field]])
        ? this.updates[product.keys[field]]
        : get(product, ['overridePrice', field], null);
      if (!checkOnMaxDate || !date) {
        return date;
      }
      return this.maxDateExceeded(date) ? null : date;
    },

    saveUpdates(product, uniqueKey, update) {
      this.$set(this.updates, uniqueKey, update);
      this.markRowAsUpdated(product);
    },

    markRowAsUpdated(row) {
      if (this.updatedRowsDetailsMap[row._id]) {
        return;
      }

      this.updatedRowsDetailsMap[row._id] = {
        productKeyDisplay: row.productKeyDisplay,
        toolStoreGroupKey: row.toolStoreGroupKey,
      };
    },

    formatUpdatesForSave() {
      return reduce(
        this.updates,
        (updatePayload, newValue, updateKey) => {
          const [productId, field] = this.parseInputUpdateKey(updateKey);
          const updatedRow = this.updatedRowsDetailsMap[productId];
          if (!updatedRow) return updatePayload;
          return [
            ...updatePayload,
            {
              filter: {
                productKeyDisplay: updatedRow.productKeyDisplay,
                toolStoreGroupKey: updatedRow.toolStoreGroupKey,
              },
              update: { [`overridePrice.${field}`]: newValue === '' ? null : newValue },
            },
          ];
        },
        []
      );
    },

    maxDateExceeded(date) {
      if (this.maxDateChecksMap.has(date)) {
        return this.maxDateChecksMap.get(date);
      }
      const momentDate = moment.utc(date);
      if (!momentDate.isValid()) {
        this.maxDateChecksMap.set(date, false);
        return false;
      }
      const maxDateExceeded = isSameOrAfter(momentDate, this.maxMomentDate);
      this.maxDateChecksMap.set(date, maxDateExceeded);
      return maxDateExceeded;
    },

    preventPageNavigationOnHorizontalScroll,
  },
};
</script>

<style lang="scss" scoped>
.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}
</style>
