<template>
  <highcharts :options="chartOptions" />
</template>

<script>
import { merge } from 'lodash';
import defaultChartOptions from '../default-chart-options';
import tensionChartOptions from '../tension-chart-options';
import colors from '../ow-colors';
import { useZones } from '@enums/feature-flags';
import featureFlagsMixin from '../mixins/featureFlags';

export default {
  mixins: [featureFlagsMixin],
  props: {
    options: {
      type: Object,
      default: () => ({}),
    },
    economicReferencePrice: {
      type: Number,
    },
    competitorReferencePrice: {
      type: Number,
    },
    architectureReferencePrice: {
      type: Number,
    },
    storeGroupReferencePrice: {
      type: Number,
    },
    scenarioPrice: {
      type: Number,
    },
  },
  computed: {
    chartOptions() {
      return merge({}, defaultChartOptions, tensionChartOptions, this.options, {
        series: this.series,
      });
    },
    series() {
      const rawValues = [
        this.economicReferencePrice,
        this.competitorReferencePrice,
        this.architectureReferencePrice,
      ];
      if (this.isFeatureFlagEnabled(useZones)) rawValues.push(this.storeGroupReferencePrice);

      return [
        {
          type: 'column',
          color: colors.tensionChartPositiveColor,
          negativeColor: colors.tensionChartNegativeColor,
          borderColor: 'transparent',
          data: rawValues.map(value => (value ? value - this.scenarioPrice : 0)),
        },
      ];
    },
  },
};
</script>
