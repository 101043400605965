<template>
  <v-dialog v-model="open" width="40rem" @click:outside="closeDialog">
    <v-card>
      <v-card-title class="headline lighten-2" primary-title>
        {{ $t('gridView.addScenario.modalTitle') }}
      </v-card-title>
      <v-form lazy-validation @submit.prevent="submit">
        <v-card-text>
          <v-text-field
            v-model="form.scenarioName"
            :rules="rules"
            :label="$t('gridView.addScenario.scenarioName')"
          />
          <v-select
            v-model="form.selectedCopyScenario"
            :items="scenarioOptions"
            :rules="rules"
            :label="$t('gridView.addScenario.basedOn')"
          />
          <div class="info-text mt-2 mx-2">
            <small>{{ $t('gridView.addScenario.modalWarning') }}</small>
          </div>
        </v-card-text>
        <v-card-actions>
          <v-spacer />
          <v-btn small text outlined class="cancel-btn" @click="closeDialog">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn :disabled="!canSave" small class="save" color="success" @click="addScenario">
            {{ $t('actions.save') }}
          </v-btn>
        </v-card-actions>
      </v-form>
    </v-card>
  </v-dialog>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapActions } = createNamespacedHelpers('scenarioMetadata');

export default {
  data() {
    return {
      open: false,
      scenarios: [],
      form: {
        scenarioName: null,
        selectedCopyScenario: null,
      },
      rules: [v => !!v || this.$t('validationErrors.required')],
    };
  },
  events: {
    showAddScenarioDialog() {
      this.open = true;
    },
  },
  computed: {
    ...mapState(['scenarioMetadata']),
    scenarioOptions() {
      return this.scenarioMetadata.map(scenario => ({
        text: scenario.scenarioDescription,
        value: scenario.scenarioKey,
      }));
    },
    canSave() {
      return this.form.scenarioName && this.form.selectedCopyScenario;
    },
  },

  methods: {
    ...mapActions(['addNewScenario']),
    closeDialog() {
      this.open = false;
      this.form.scenarioName = null;
      this.form.selectedCopyScenario = null;
    },
    async addScenario() {
      const details = {
        copiedScenarioKey: this.form.selectedCopyScenario,
        scenarioName: this.form.scenarioName,
      };
      this.addNewScenario(details);
      this.closeDialog();
    },
  },
};
</script>

<style lang="scss" scoped>
.info-text {
  font-size: 1.5rem;
}
</style>
