<template>
  <tbody>
    <template v-if="!isUnitManagerView">
      <tr>
        <td class="border-bottom">
          <div class="d-flex flex-row-reverse">{{ $t('gridView.marginLever') }}</div>
        </td>
        <td colspan="16" class="border-bottom" />
        <td class="alert-cell" />
      </tr>
      <grid-view-scenario-selector
        v-for="scenarioAggregation in scenarioAggregations"
        :key="scenarioAggregation._id"
        :scenario="scenarioAggregation"
        :level-entry-key="levelEntryKey"
        @updateDefaultScenario="updateDefaultScenario"
      />
      <tr class="add-scenario-row">
        <td class="pl-0">
          <v-btn
            v-if="canAddScenario"
            color="primary"
            small
            text
            class="pl-0"
            @click="showAddScenarioDialog"
          >
            <v-icon v-once class="pl-0">add_box</v-icon>
            <span class="add-button ml-1 pl-0">
              {{ $t('gridView.addScenario.addScenarioButton') }}
            </span>
          </v-btn>
        </td>
        <td colspan="16" class="blank-cell" />
        <td class="alert-cell" />
      </tr>
    </template>

    <tr>
      <td colspan="19">
        <grid-view-container
          :is-unit-manager-view="isUnitManagerView"
          :level-entry-key="levelEntryKey"
          @toggleFilteringByArchitectureGroup="onToggleFilteringByArchitectureGroup"
        />
      </td>
    </tr>
  </tbody>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { find } from 'lodash';
import { candidate } from '@enums/scenario-version';

export default {
  props: {
    levelEntryKey: String,
    isUnitManagerView: Boolean,
  },

  data() {
    return {};
  },

  computed: {
    ...mapState('scenarioMetadata', {
      scenarioMetadata: state => state.scenarioMetadata,
      aggregatedScenarios: state => state.aggregatedScenarios,
    }),
    ...mapGetters('context', ['isWholesaleManager', 'isWholesaleAnalyst', 'isAdminLoggedIn']),

    candidateScenario() {
      return this.scenarioMetadata.find(sc => sc.version === candidate);
    },

    scenarioAggregations() {
      return this.scenarioMetadata.map(sc => {
        const matchedScenario = find(this.aggregatedScenarios, { scenarioKey: sc.scenarioKey });
        return { ...matchedScenario, ...sc };
      });
    },

    canAddScenario() {
      return this.isAdminLoggedIn || (!this.isWholesaleManager && !this.isWholesaleAnalyst);
    },
  },

  async created() {
    if (!this.isUnitManagerView) {
      await this.refetchScenarioMetadata();
      this.getAggregatedScenarios();
      this.checkPricingEngineStatus(this.candidateScenario.scenarioKey);
    }
  },

  methods: {
    ...mapActions('scenarioMetadata', [
      'fetchScenarioMetadata',
      'electNewCandidateScenario',
      'getAggregatedScenarios',
    ]),
    ...mapActions('gridView', ['fetchItems', 'checkPricingEngineStatus']),
    ...mapActions('filters', ['setSelectedFilter']),

    async updateDefaultScenario(newCandidateScenarioKey) {
      const oldCandidateScenarioKey = this.candidateScenario.scenarioKey;
      const pricingGroupId = this.candidateScenario.hierarchyId;
      await this.electNewCandidateScenario({
        newCandidateScenarioKey,
        pricingGroupId,
        oldCandidateScenarioKey,
      });
      await this.refetchScenarioMetadata();
      await this.setSelectedFilter({
        filterName: 'scenario',
        filterValue: newCandidateScenarioKey,
      });
      this.checkPricingEngineStatus();
      await this.fetchItems({ levelEntryKey: this.levelEntryKey });
      this.globalEmit('new-candidate-scenario-elected');
    },

    async refetchScenarioMetadata() {
      const params = {
        where: { hierarchyId: this.levelEntryKey },
      };
      await this.fetchScenarioMetadata({ params });
    },

    showAddScenarioDialog() {
      this.globalEmit('show-add-scenario-dialog');
    },

    onToggleFilteringByArchitectureGroup(eventData) {
      this.$emit('toggleFilteringByArchitectureGroup', eventData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

tr {
  background-color: white;
  border-bottom: solid 1px $grid-view-borders;
}
.add-scenario-row {
  background-color: white;
  font-size: 1.3rem;
  border-bottom: unset;

  td {
    padding: 0.4rem 0.7rem;
    vertical-align: middle;
    line-height: 1.7rem;
  }
}
.add-button {
  font-size: 1.3rem;
}

.alert-cell {
  background-color: $pricing-background !important;
  border-bottom: 0.1rem solid $pricing-background !important;
  min-width: $grid-view-alert-column-width !important;
}
</style>
