<template>
  <span>
    <v-dialog v-model="showDialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('attributes.upload.dialogHeading') }}
        </v-card-title>

        <v-icon class="close-button" color="grey" @click="closeDialog">close</v-icon>

        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          source="inputs"
          @save="save"
        />

        <div v-if="isSaving">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">{{ $t('attributes.upload.uploadingState') }}</div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>

        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    {{ $t('attributes.upload.articlesReceived') }}:
                    <b>{{ counts.articlesReceived }}</b>
                    <br />
                    {{ $t('attributes.upload.articlesMatched') }}:
                    <b>{{ counts.articlesMatched }}</b>
                    <br />
                    <br />
                    {{ $t('attributes.upload.attributesReceived') }}:
                    <b>{{ counts.attributesReceived }}</b>
                    <br />
                    {{ $t('attributes.upload.attributesMatched') }}:
                    <b>{{ counts.attributesMatched }}</b>
                    <br />
                    <br />
                    {{ $t('attributes.upload.confirmUpload') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" class="dialog-button" outlined depressed @click="reset()">{{
              $t('actions.cancel')
            }}</v-btn>
            <v-btn
              class="primary dialog-button"
              depressed
              :loading="busyImportingAttributes"
              @click="confirm()"
              >{{ $t('actions.upload') }}
            </v-btn>
          </v-card-actions>
        </div>

        <div v-if="isFailed || isNotValidated">
          <div v-if="!(duplicateKeysError || incorrectTSGsError || productsGoLiveBeforeTodayError)">
            <v-card-text>
              <div class="container grid-list-md">
                <div class="layout wrap">
                  <div v-if="uploadError && uploadError.tooManyRows" class="flex xs12">
                    <div class="v-input">
                      {{ $t('attributes.uploadHierarchy.tooManyRows', { maxRows }) }}
                    </div>
                  </div>
                  <div v-else class="flex xs12">
                    <div class="v-input">
                      {{ getErrorMessage() }}
                    </div>
                  </div>
                </div>
              </div>
            </v-card-text>
          </div>
          <!-- FEATURE_FLAG: display zone-related validation errors -->
          <feature-toggle v-if="duplicateKeysError || incorrectTSGsError" :toggle="useZones">
            <v-card-text>
              <div class="container grid-list-md">
                <div class="layout wrap">
                  <upload-error-card
                    v-if="duplicateKeysError"
                    :error-message="$t('attributes.upload.conflictedToolStoreGroupKeys')"
                    :details="uploadError.duplicateProductKeys"
                    unique-key-prefix="conflictToolStoreGroupKeys"
                  />
                  <upload-error-card
                    v-if="incorrectTSGsError"
                    :error-message="$t('attributes.upload.incorrectToolStoreGroups')"
                    :details="uploadError.productKeysWithIncorrectTSGs"
                    unique-key-prefix="incorrectToolStoreGroups"
                  />
                </div>
              </div>
            </v-card-text>
          </feature-toggle>
          <!-- FEATURE_FLAG: display alt go live validation errors -->
          <feature-toggle
            v-if="productsGoLiveBeforeTodayError"
            :toggle="useManualProductGoLiveDate"
          >
            <v-card-text>
              <div class="container grid-list-md">
                <div class="layout wrap">
                  <upload-error-card
                    v-if="productsGoLiveBeforeTodayError"
                    :error-message="$t('attributes.upload.productsGoLiveBeforeToday')"
                    :details="uploadError.productKeysGoLiveBeforeToday"
                    unique-key-prefix="productsGoLiveBeforeToday"
                  />
                </div>
              </div>
            </v-card-text>
          </feature-toggle>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeDialog()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { isEmpty, get } from 'lodash';
import states from '@enums/upload-states';
import { useZones, useManualProductGoLiveDate } from '@enums/feature-flags';

export default {
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    showDialog: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
      useZones,
      useManualProductGoLiveDate,
    };
  },

  computed: {
    ...mapState('attributes', ['counts', 'uploadData', 'busyImportingAttributes']),
    ...mapState('clientConfig', ['uploadConfig']),
    ...mapGetters('workpackages', ['getSelectedWorkpackage']),

    maxRows() {
      return this.uploadConfig.attributeUpload.maxRows;
    },

    isInitial() {
      return this.currentStatus === states.initial;
    },

    isSaving() {
      return this.currentStatus === states.saving;
    },

    isValidated() {
      return this.currentStatus === states.validated;
    },

    isFailed() {
      return this.currentStatus === states.failed;
    },

    isNotValidated() {
      return this.currentStatus === states.brokenValidations;
    },

    duplicateKeysError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'duplicateProductKeys', []))
      );
    },
    incorrectTSGsError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithIncorrectTSGs', []))
      );
    },
    productsGoLiveBeforeTodayError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysGoLiveBeforeToday', []))
      );
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    ...mapActions('attributes', ['uploadDataFile', 'confirmUpload']),

    closeDialog() {
      this.$emit('closeDialog');
      this.reset();
    },

    save(formData) {
      this.currentStatus = states.saving;
      return this.uploadDataFile(formData)
        .then(res => {
          this.currentStatus = res.error ? states.brokenValidations : states.validated;
        })
        .catch(err => {
          this.uploadError = err;
          this.currentStatus = states.failed;
        });
    },

    confirm() {
      return this.confirmUpload({ params: this.params })
        .then(() => {
          this.globalEmit('successful-attribute-update');
          this.closeDialog();
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = states.failed;
        });
    },

    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },

    getErrorMessage() {
      return this.isFailed
        ? this.$t('attributes.upload.genericError')
        : this.$t('attributes.upload.validationError');
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}
</style>
