<template>
  <div>
    <v-layout v-if="noProductSettings && !loadingProductSettings">
      <i>{{ $t('pricing.penaltyFunctions.noProductSettings') }}</i>
    </v-layout>
    <v-simple-table
      v-if="!loadingProductSettings && !noProductSettings"
      dense
      class="penalty-table"
    >
      <template v-slot:default>
        <thead>
          <tr class="header">
            <th class="text-left">
              <h4>{{ $t('pricing.penaltyFunctions.ruleName') }}</h4>
            </th>
            <th class="text-left">
              <h4>{{ $t('pricing.penaltyFunctions.penaltyType') }}</h4>
            </th>
          </tr>
        </thead>
        <tbody>
          <tr
            v-for="(penalty, ix) in ruleNamesWithPenaltyTypes"
            :key="`${penalty.ruleName}-${ix}`"
            class="penalty-row"
          >
            <td class="pt-1 pb-1">
              <span>{{
                showIfDifferent(penalty.ruleName, 'ruleName', ix, ruleNamesWithPenaltyTypes)
              }}</span>
            </td>
            <td class="pt-1 pb-1">
              <span :class="getClassForPenaltyType(penalty.penaltyType)">{{
                $t(
                  `pricing.penaltyFunctions.${showIfDifferent(
                    penalty.penaltyType,
                    'penaltyType',
                    ix,
                    ruleNamesWithPenaltyTypes
                  )}`
                )
              }}</span>
            </td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { get, sortBy, isEmpty } from 'lodash';
import penaltyTypes from '@enums/penalty-types';
import { displayStoreGroupPenalty } from '@enums/feature-flags';
import showIfDifferentMixin from '../mixins/showIfDifferent';
import featureFlagsMixin from '../mixins/featureFlags';

export default {
  mixins: [showIfDifferentMixin, featureFlagsMixin],

  props: {
    scenarioKey: {
      required: true,
    },
    toolStoreGroupKey: {
      required: true,
    },
  },

  computed: {
    ...mapState('gridView', ['loadingProductSettings', 'expandedProductSettings']),
    isStoreGroupPenaltyVisible() {
      return this.isFeatureFlagEnabled(displayStoreGroupPenalty);
    },
    ruleNamesWithPenaltyTypes() {
      const ruleNamesWithPenaltyTypesMap = [];
      Object.values(penaltyTypes).forEach(type => {
        let name;
        if (type === penaltyTypes.storeGroupPenalty && !this.isStoreGroupPenaltyVisible) return;
        if (type === penaltyTypes.competitorPenalty || type === penaltyTypes.storeGroupPenalty) {
          name = get(this.expandedProductSettings, [type, 'penalties', '0', 'ruleDescription']);
        } else {
          name = get(this.expandedProductSettings, [type, 'ruleDescription']);
        }
        if (name || name === '')
          ruleNamesWithPenaltyTypesMap.push({ ruleName: name, penaltyType: type });
      });
      return sortBy(ruleNamesWithPenaltyTypesMap, ['ruleName', 'penaltyType']);
    },

    noProductSettings() {
      return isEmpty(this.expandedProductSettings) || isEmpty(this.ruleNamesWithPenaltyTypes);
    },
  },

  methods: {
    getClassForPenaltyType(type) {
      if (type === penaltyTypes.competitorPenalty) return 'competitor';
      if (type === penaltyTypes.economicPenalty) return 'economic';
      if (type === penaltyTypes.architecturePenalty) return 'architecture';
      if (type === penaltyTypes.storeGroupPenalty) return 'store-group';
      return 'price-pointing';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.penalty-table {
  overflow: scroll !important;
}

.header {
  > th {
    color: black !important;
    font-size: 1.2rem !important;
  }
}

.penalty-row {
  font-size: 1.2rem !important;
  > td {
    border-bottom: 0.1rem solid $table-border-color !important;
    > span {
      font-size: 1.2rem !important;
    }
  }
}

.economic {
  color: $pricing-economy-colour;
}

.architecture {
  color: $pricing-architecture-colour;
}

.competitor {
  color: $pricing-competition-colour;
}

.store-group {
  color: $storeGroupTrafficLightColor;
}

.price-pointing {
  color: $pricePointingTrafficLightColor;
}
</style>
