import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { staticClass: "heading-bar" },
    [
      _c(
        VCol,
        { staticClass: "heading-bar-filter" },
        [
          _c(
            VFlex,
            { attrs: { lg5: "" } },
            [
              _c("pricing-filter", {
                attrs: {
                  filter: _vm.architectureGroupFilter,
                  loading: _vm.hierarchyLoading,
                },
                on: { filterSelected: _vm.onSelectFilter },
              }),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }