'use strict';

const dataTypes = require('./data-types');

module.exports = {
  // order is important
  storeFormat: {
    type: dataTypes.number,
    maximumFractionDigits: 0, // should be an integer
    targetValueLength: 4,
    padString: '0',
  },
  productKeyDisplay: {
    type: dataTypes.str,
    targetValueLength: 18, // override with exportConfigs.exportToSAP.targetValueLength.productKeyDisplay
    padString: '0',
  },
  date: {
    type: dataTypes.date,
    format: null, // override with exportConfigs.exportToSAP.dateFormat
  },
  price: {
    type: dataTypes.number,
  },
  priceExclVAT: {
    type: dataTypes.number,
  },
  hasUpcomingPromotion: {
    type: dataTypes.boolean,
    valuesMapping: {
      true: 'True',
      false: 'False',
    },
  },
};
