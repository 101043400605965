const mixin = {
  methods: {
    showIfDifferent(currentValue, prop, ix, array) {
      // Returns empty string if successive rows have the same value in the table
      if (ix === 0) return currentValue;
      const previousValue = array[ix - 1][prop];

      return currentValue !== previousValue ? currentValue : '';
    },
  },
};

export default mixin;
