var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _vm.visible
    ? _c("div", { staticClass: "container" }, [
        _c("span", { staticClass: "message" }, [
          _vm._v(_vm._s(_vm.$t("inputs.maxResultsShown"))),
        ]),
      ])
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }