<template>
  <v-dialog :value="isOpen" persistent width="40rem">
    <v-card>
      <v-container>
        <v-form ref="attributeForm" lazy-validation @submit.prevent="submit">
          <v-card-text class="header-text">
            {{ title }}
          </v-card-text>
          <v-icon class="close-button" color="grey" @click="cancel">close</v-icon>
          <v-card-text class="body">
            <!-- PRICE-3007 - pricingGroup scope to be deprecated -->
            <template v-if="isCreateMode && scope === attributeScope.pricingGroup">
              <v-autocomplete
                v-model="selectedGrandparentHierarchy"
                dense
                return-object
                flat
                :items="existingGrandparentHierarchies"
                item-text="levelEntryDescription"
                item-value="levelEntryDescription"
                class="ma-0 pt-0 mb-2"
                @input="setSelectedGrandparentHierarchy"
              >
                <template v-slot:item="props">
                  <span class="pg-dropdown-item-text">
                    {{ props.item.levelEntryDescription }}
                  </span>
                </template>

                <template v-slot:label>
                  <span>{{ selectHierarchyText(level - 2) }}</span>
                </template>
              </v-autocomplete>

              <v-autocomplete
                v-model="selectedParentHierarchy"
                dense
                return-object
                flat
                small-chips
                multiple
                deletable-chips
                clearable
                :items="existingParentHierarchies"
                :disabled="!selectedGrandparentHierarchy"
                item-text="levelEntryDescription"
                item-value="levelEntryDescription"
                class="ma-0 pt-0 mb-2"
                :search-input.sync="parentSearchInput"
                @input="parentSearchInput = null"
              >
                <template v-slot:item="props">
                  <span class="pg-dropdown-item-text">
                    {{ props.item.levelEntryDescription }}
                  </span>
                </template>

                <template v-slot:label>
                  <span>{{ selectHierarchyText(level - 1) }}</span>
                </template>
              </v-autocomplete>
            </template>
            <v-text-field
              v-model.trim="attributeName"
              :value="attributeName"
              :label="$t('general.labels.attributeName')"
              :rules="attributeNameRules"
              required
            />

            <v-select
              v-if="isCreateMode"
              v-model="selectedDataType"
              :items="dataTypes"
              item-text="value"
              item-value="key"
              single-line
              dense
              :label="$t('general.labels.attributeDataType')"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">
                  {{ props.item.value }}
                </span>
              </template>
            </v-select>

            <template v-if="!isCreateMode">
              <div class="attribute-info d-flex flex-column">
                <span class="pb-2">
                  <strong>{{ $t('general.labels.source') }}:</strong> {{ sourceField }}
                </span>
                <span>
                  <strong>{{ $t('general.labels.dataType') }}:</strong> {{ dataTypeField }}
                </span>
              </div>
            </template>
          </v-card-text>

          <v-card-actions class="btn-group">
            <v-spacer />
            <v-btn class="mr-2" color="primary" outlined small depressed @click="cancel">{{
              $t('actions.cancel')
            }}</v-btn>
            <v-btn
              :disabled="!isValid"
              class="mr-2"
              color="primary"
              small
              depressed
              @click="submit"
            >
              {{ submitBtnCaption }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { startCase, sortBy, map, upperFirst, size, get } from 'lodash';

import { architectureGroupLevel } from '@enums/hierarchy';
import sourceEnums from '@enums/source-types';
import DataTypes from '@enums/data-types';
import attributeScope from '@enums/attribute-scope';
import dialogModes from '@enums/dialog-modes';

export default {
  props: {
    mode: {
      type: String,
      default: () => dialogModes.create,
    },
    attribute: {
      type: Object,
      default: () => {},
    },
    params: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      required: true,
    },
    scope: {
      type: String,
      default: () => attributeScope.global,
    },
    ignoreAttributeFetch: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      attributeName: this.attribute ? this.attribute.displayDescription : null,
      attributeNameRules: [
        v => !!v || this.$t('attributes.editor.errors.emptyAttributeName'),
        () =>
          !this.attributeNameAlreadyExists ||
          this.$t('attributes.editor.errors.duplicateAttributeName'),
      ],
      attributeSource: sourceEnums.manualEntry,
      sourceEnums,
      level: architectureGroupLevel,
      selectedGrandparentHierarchy: null,
      selectedParentHierarchy: [],
      selectedDataType: this.attribute ? this.attribute.dataType : null,
      dataTypes: [
        { key: DataTypes.str, value: this.$t('attributes.dataTypes.text') },
        { key: DataTypes.number, value: this.$t('attributes.dataTypes.number') },
        { key: DataTypes.date, value: this.$t('attributes.dataTypes.date') },
      ],
      attributeScope,
      parentSearchInput: null,
    };
  },

  computed: {
    ...mapState('attributes', ['attributeMetadata']),
    ...mapGetters('workpackages', ['getSelectedWorkpackage']),
    ...mapGetters('hierarchy', ['getHierarchy']),

    title() {
      return startCase(
        this.$t(`attributes.actions.${this.mode}${upperFirst(this.scope)}Attribute`)
      );
    },

    isValid() {
      // PRICE-3007 - pricingGroup scope to be deprecated
      if (this.isCreateMode && this.scope === attributeScope.pricingGroup)
        return (
          !!this.selectedGrandparentHierarchy &&
          !!size(this.selectedParentHierarchy) &&
          !!this.attributeName &&
          !this.attributeNameAlreadyExists &&
          !!this.attributeSource &&
          !!this.selectedDataType
        );

      return (
        !!this.attributeName &&
        !!this.attributeSource &&
        !this.attributeNameAlreadyExists &&
        !!this.selectedDataType
      );
    },

    submitBtnCaption() {
      return this.isCreateMode ? this.$t('actions.add') : this.$t('actions.save');
    },

    sourceField() {
      return get(this.attribute, 'sourceFieldDescription') === sourceEnums.manualEntry
        ? this.$t('attributes.editor.manualEntry')
        : this.$t('attributes.editor.feedEntry');
    },

    dataTypeField() {
      const dataTypes = {
        [DataTypes.str]: this.$t('attributes.dataTypes.text'),
        [DataTypes.number]: this.$t('attributes.dataTypes.number'),
        [DataTypes.date]: this.$t('attributes.dataTypes.date'),
      };
      const type = this.attribute ? this.attribute.dataType : null;
      return dataTypes[type];
    },

    attributeNameAlreadyExists() {
      if (!this.attributeName) return false;
      const attributeName = this.attributeName.toLowerCase();

      const disallowedNames = [
        this.$t('pricing.productKey'),
        this.$t('pricing.toolStoreGroup'),
        this.$t('pricing.productDescription'),
        this.$t('pricing.category'),
        this.$t('pricing.pricingGroup'),
        this.$t('pricing.architectureGroup'),
      ];

      return (
        disallowedNames.some(item => attributeName === item.toLowerCase()) ||
        this.attributeMetadata.some(
          item =>
            // Attribute description needs to be unique for logic, however it would look weird
            // to see attributes of the the same display description in the UI
            attributeName === item.attributeDescription.toLowerCase() ||
            attributeName === item.displayDescription.toLowerCase()
        )
      );
    },

    isCreateMode() {
      return this.mode === dialogModes.create;
    },

    existingGrandparentHierarchies() {
      // Used to allow the user to select a category before selecting a pricing group when creating a new Architecture Group.
      // Minus 2 as Category is 2 levels above Architecture Group in Hierarchy.
      return sortBy(this.getHierarchy({ level: this.level - 2 }), 'levelEntryDescription');
    },

    existingParentHierarchies() {
      if (this.selectedGrandparentHierarchy)
        return sortBy(
          this.getHierarchy({
            level: this.level - 1,
            parentId: this.selectedGrandparentHierarchy.levelEntryKey,
          }),
          'levelEntryDescription'
        );
      return sortBy(this.getHierarchy({ level: this.level - 1 }), 'levelEntryDescription');
    },
  },

  methods: {
    ...mapActions('attributes', [
      'createAttribute',
      'updateAttributeMetadata',
      'doesProductExistWithUnassignedPGAttribute',
    ]),

    resetSelections() {
      this.attributeName = null;
      this.selectedDataType = null;
      this.selectedGrandparentHierarchy = null;
      this.selectedParentHierarchy = [];
      this.$refs.attributeForm.resetValidation();
    },

    setSelectedGrandparentHierarchy(category) {
      this.resetSelections();
      this.selectedGrandparentHierarchy = category;
    },

    setSelectedParentHierarchy(category) {
      this.selectedParentHierarchy = category;
    },

    selectHierarchyText(level) {
      const hierarchyName = this.$tc(`hierarchy.hierarchyLevels.${level}`, 1);
      return this.$t('attributes.editor.selectHierarchy', { hierarchyName });
    },

    async submit() {
      if (!this.isValid) return false;

      if (this.isCreateMode) {
        this.globalEmit('save-new-attribute');
        await this.createAttribute({
          name: this.attributeName,
          scope: this.scope,
          source: this.attributeSource,
          dataType: this.selectedDataType,
          params: this.params,
          pricingGroups: map(this.selectedParentHierarchy, 'levelEntryKey'),
          ignoreAttributeFetch: this.ignoreAttributeFetch,
        });
        this.globalEmit('attribute-created');
        if (this.toggleLogic.runProductExistWithUnassignedPGAttribute) {
          this.doesProductExistWithUnassignedPGAttribute();
        }
      } else {
        this.globalEmit('save-updated-attribute', {
          attribute: this.attribute,
          updatedName: this.attributeName,
        });
        this.updateAttributeMetadata({
          id: this.attribute._id,
          name: this.attributeName,
          params: this.params,
          ignoreAttributeFetch: this.ignoreAttributeFetch,
        });
      }
      this.resetSelections();
    },

    cancel() {
      this.globalEmit('cancel-add-attribute');
      this.resetSelections();
    },
  },
};
</script>

<style lang="scss" scoped>
.attribute-info > span {
  font-size: 1.2rem;
}

.pg-dropdown-item-text {
  font-size: 1.5rem !important;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}
</style>
