var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "icon-container",
      attrs: {
        "custom-title-msg": _vm.tooltipMessage,
        "custom-title-position": _vm.tooltipPosition,
        "custom-title-hidden": _vm.disableTooltip,
      },
    },
    [
      _vm.approved === true ? _vm._m(0) : _vm._e(),
      _vm._v(" "),
      _vm.approved === false ? _vm._m(1) : _vm._e(),
      _vm._v(" "),
      _vm.unitLevelAndNull ? _vm._m(2) : _vm._e(),
    ]
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", {
      staticClass: "fa fa-check-circle icon",
      style: { color: _vm.success },
    })
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", {
      staticClass: "fa fa-times-circle icon",
      style: { color: "red" },
    })
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("span", {
      staticClass: "fa fa-circle icon",
      style: { color: _vm.nullIconColour },
    })
  },
]
render._withStripped = true

export { render, staticRenderFns }