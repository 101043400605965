import XLSX from 'xlsx';
import { get, round } from 'lodash';
import moment from 'moment';
import { monthDayYearSlashesFormat, dayMonthYearFormat } from '@enums/date-formats';
import numberFormats from '@enums/number-formats';

// TODO: remove this completely and rely on webtool/client/js/store/utils/download-xlsx-file.js
// Would need to update the data format returned by getWorkpackagesToExport and ensure the SAP export still works.
// TODO: will need to be controlled by feature flag if formatting/column order is different per client
function downloadXlsxWorkpackage(data, filename, { columnFormatters = {} }) {
  // parse raw data from csv format to array of arrays
  const dataArray = data.split('\n').map(row => row.replace(/"/g, '').split(','));

  dataArray.forEach((row, index, arr) => {
    if (index < 1) return; // Skip first row as this contains the headers
    // arr: [storeFormat, region, productKey, date, price]
    arr[index][0] = parseInt(row[0], 10); // export storeFormat as int
    // export productKeyDisplay as int. If future clients have non-numeric
    // display keys we will need to alter this
    arr[index][2] =
      get(columnFormatters, 'productKeyDisplay.type') === numberFormats.integer
        ? parseInt(row[2], 10)
        : row[2];
    // month is in first element of array, day in second position
    const originalDate = moment(row[3], monthDayYearSlashesFormat);
    arr[index][3] = originalDate.format(dayMonthYearFormat); // DD tells it to pad days < 10 with a 0
    arr[index][4] = round(row[4], 4); // round the price to 4 digits
  });
  const ws = XLSX.utils.aoa_to_sheet(dataArray);
  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws, 'Pricing');
  XLSX.writeFile(wb, filename);
}

export default downloadXlsxWorkpackage;
