import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      _c("tr", [
        _vm.isUnitLevel
          ? _c("td", { attrs: { colspan: "35" } }, [_c("br"), _c("br")])
          : _vm._e(),
      ]),
      _vm._v(" "),
      _c(
        "tr",
        {
          staticClass: "hierarchy__row hierarchy-level-item__main",
          class: [_vm.hierarchyLevelClass],
        },
        [
          _c(
            "td",
            {
              staticClass:
                "hierarchy-level-item__heading hierarchy-level-item__group-end border-right",
              class: { disabled: _vm.rowDisabled },
            },
            [
              _c("div", { staticClass: "d-flex justify-space-between" }, [
                _c(
                  "div",
                  {
                    staticClass: "clickable my-auto",
                    style: [{ fontSize: _vm.headingSizeInRem + "rem" }],
                    on: { click: _vm.toggleExpansion },
                  },
                  [
                    _vm.isPricingGroupLevel || _vm.isCategoryLevel
                      ? _c("i", {
                          class: [
                            _vm.expanded
                              ? "mdi mdi-chevron-down"
                              : "mdi mdi-chevron-right",
                          ],
                        })
                      : _vm._e(),
                    _vm._v("\n          " + _vm._s(_vm.name) + "\n        "),
                  ]
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "actions" },
                  [
                    _vm.level === _vm.hierarchyConfig.rootHierarchyLevel
                      ? _c(
                          VBtn,
                          {
                            staticClass:
                              "mr-2 btn-refresh white--text hierarchy-level-item__approve-btn",
                            attrs: {
                              color: "primary",
                              small: "",
                              depressed: "",
                              loading: _vm.recalculatingAll,
                              disabled:
                                _vm.calculatingScenarioResults ||
                                _vm.recalculatingAll ||
                                _vm.isPermissionDenied,
                            },
                            on: { click: _vm.runEngineForAllScenarios },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.recalculateAll")) +
                                "\n            "
                            ),
                            _vm._m(0),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isUnitLevel && _vm.canApproveUnit && !_vm.unitApproved
                      ? _c(
                          VBtn,
                          {
                            staticClass:
                              "hierarchy-level-item__approve-btn approval-workflow",
                            class: {
                              "disabled-button":
                                _vm.isSelectedWorkpackageMaster,
                            },
                            attrs: {
                              color: "success",
                              small: "",
                              depressed: "",
                              disabled:
                                _vm.unitApproved ||
                                _vm.isSelectedWorkpackageMaster,
                            },
                            on: {
                              click: function ($event) {
                                return _vm.approveUnit()
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("actions.approve")) +
                                "\n          "
                            ),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.unitApproved && _vm.isUnitLevel
                      ? _c(
                          "span",
                          { staticClass: "approval-text success--text" },
                          [
                            _c("strong", [
                              _vm._v(
                                _vm._s(_vm.$t("actions.approved")) +
                                  ": " +
                                  _vm._s(_vm._f("delimiter")(_vm.approvalDate))
                              ),
                            ]),
                          ]
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isPricingGroupLevel
                      ? _c(
                          "div",
                          {
                            attrs: {
                              "custom-title-msg": _vm.$t(
                                "gridView.tooltips.pricingSpecialistRelease"
                              ),
                              "custom-title-position": "top-right",
                            },
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "ma-0 checkbox",
                              attrs: {
                                "input-value": _vm.selectedOrReleased,
                                readonly:
                                  _vm.pgReleased ||
                                  !_vm.pricingSpecialistAndManagerPricingGroupLevel ||
                                  _vm.isSelectedWorkpackageMaster,
                                dense: "",
                                color: "success",
                                disabled:
                                  _vm.pgReleased ||
                                  !_vm.pricingSpecialistAndManagerPricingGroupLevel ||
                                  _vm.isSelectedWorkpackageMaster,
                              },
                              on: {
                                change: function ($event) {
                                  return _vm.togglePricingGroup()
                                },
                              },
                            }),
                          ],
                          1
                        )
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.isPricingGroupLevel
                      ? _c("hierarchy-level-item-approvals", {
                          attrs: { approvals: _vm.approvals },
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]),
            ]
          ),
          _vm._v(" "),
          _c("aggregations-row", {
            attrs: {
              level: _vm.level,
              "sales-m-a-t": _vm.salesMAT,
              "promo-share": _vm.promoShare,
              "sales-impact-m-a-t": _vm.salesImpactMAT,
              "regular-sales-impact": _vm.regularSalesImpact,
              "total-impact-in-year": _vm.totalImpactInYear,
              "regular-impact-in-year": _vm.regularImpactInYear,
              "total-cost-impact": _vm.totalCostImpact,
              "cost-impact-in-year": _vm.costImpactInYear,
              "regular-cost-impact-in-year": _vm.regularCostImpactInYear,
              "regular-cost-impact": _vm.regularCostImpact,
              "margin-proposed": _vm.marginProposed,
              "margin-delta": _vm.marginDelta,
              "economic-tension": _vm.economicTension,
              "competitor-tension": _vm.competitorTension,
              "architecture-tension": _vm.architectureTension,
              "store-group-tension": _vm.storeGroupTension,
              "competitor1-live-distance": _vm.competitor1LiveDistance,
              "competitor1-proposed-distance": _vm.competitor1ProposedDistance,
              "competitor1-delta-vs-prev": _vm.competitor1DeltaVsPrev,
              "competitor2-live-distance": _vm.competitor2LiveDistance,
              "competitor2-proposed-distance": _vm.competitor2ProposedDistance,
              "competitor2-delta-vs-prev": _vm.competitor2DeltaVsPrev,
              "competitor1-live-distance-price":
                _vm.competitor1LiveDistancePrice,
              "competitor1-proposed-distance-price":
                _vm.competitor1ProposedDistancePrice,
              "competitor1-delta-vs-prev-price":
                _vm.competitor1DeltaVsPrevPrice,
              "competitor2-live-distance-price":
                _vm.competitor2LiveDistancePrice,
              "competitor2-proposed-distance-price":
                _vm.competitor2ProposedDistancePrice,
              "competitor2-delta-vs-prev-price":
                _vm.competitor2DeltaVsPrevPrice,
              "competitor1-live-distance-volume":
                _vm.competitor1LiveDistanceVolume,
              "competitor1-proposed-distance-volume":
                _vm.competitor1ProposedDistanceVolume,
              "competitor1-delta-vs-prev-volume":
                _vm.competitor1DeltaVsPrevVolume,
              "competitor2-live-distance-volume":
                _vm.competitor2LiveDistanceVolume,
              "competitor2-proposed-distance-volume":
                _vm.competitor2ProposedDistanceVolume,
              "competitor2-delta-vs-prev-volume":
                _vm.competitor2DeltaVsPrevVolume,
              "num-price-changes": _vm.numPriceChanges,
              "competitor-target-distances": _vm.competitorTargetDistances,
            },
            on: {
              updateCompetitorTargetDistances:
                _vm.updateCompetitorTargetDistances,
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.displayStoreGroupPenalty } },
        [
          _c("store-group-aggregations", {
            key: _vm._id,
            attrs: { "parent-id": _vm._id, level: _vm.level },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.showCheckboxRow
        ? _c(
            "tr",
            { staticClass: "hierarchy__row checkbox-row" },
            [
              _vm.showExpandChildrenActionRow
                ? _c("hierarchy-level-item-expand-children", {
                    attrs: {
                      expanded: _vm.expanded,
                      level: _vm.level,
                      "level-entry-key": _vm.levelEntryKey,
                      name: _vm.name,
                      "parent-id": _vm.parentId,
                      "workpackage-id": _vm.workpackageId,
                    },
                    on: {
                      categoryReleased: function ($event) {
                        return _vm.toggleCategory()
                      },
                      pricingSpecialistCategoryApproval:
                        _vm.pricingSpecialistCategoryApproval,
                      categoryManagerCategoryApproval:
                        _vm.categoryManagerCategoryApproval,
                      toggle: _vm.toggleExpansion,
                    },
                  })
                : _c("td", { attrs: { colspan: "15" } }),
              _vm._v(" "),
              _c(
                "td",
                {
                  class: {
                    "border-bottom":
                      _vm.areStoreGroupAggregationsVisible &&
                      _vm.showExpandChildrenActionRow,
                  },
                  attrs: { colspan: "2" },
                },
                [
                  _c(
                    "feature-toggle",
                    { attrs: { toggle: _vm.displayStoreGroupPenalty } },
                    [
                      _vm.areStoreGroupAggregationsVisible
                        ? _c(
                            "div",
                            {
                              staticClass: "clickable my-auto",
                              on: {
                                click: _vm.toggleDisplayStoreGroupAggregations,
                              },
                            },
                            [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.storeGroupExpanded
                                      ? _vm.$t("actions.close")
                                      : _vm.$t("actions.details")
                                  ) +
                                  "\n          "
                              ),
                              _c("i", {
                                class: [
                                  _vm.storeGroupExpanded
                                    ? "mdi mdi-chevron-up"
                                    : "mdi mdi-chevron-down",
                                ],
                              }),
                            ]
                          )
                        : _vm._e(),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "alert-cell" }),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("mdi-refresh")])
  },
]
render._withStripped = true

export { render, staticRenderFns }