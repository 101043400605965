<template>
  <svg
    width="19px"
    height="18px"
    viewBox="0 0 19 18"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <!-- <title>Group 32</title> -->
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-32">
        <rect id="Rectangle" fill="#EA9B66" x="0" y="0" width="19" height="16.8" rx="2" />
        <text
          id="PR"
          font-family="SourceSansPro-Semibold, Source Sans Pro"
          font-size="12"
          font-weight="500"
          line-spacing="14"
          fill="#FFFFFF"
        >
          <tspan x="2.7" y="12.8">PR</tspan>
        </text>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PromotionPR',
};
</script>
