<template>
  <div v-if="visible" class="container">
    <span class="message">{{ $t('inputs.maxResultsShown') }}</span>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      visible: true,
    };
  },
  methods: {
    setVisible(visible) {
      this.visible = visible;
    },
    isVisible() {
      return this.visible;
    },
  },
});
</script>

<style lang="sass" scoped>
.message {
    color: #CA7C00;
}
</style>
