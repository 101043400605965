'use strict';

const truncationLength = Object.freeze({
  architectureProducts: 33,
  linePricedProducts: 50,
  toolStoreGroupDescription: 20,
  normWeight: 10,
});

module.exports = {
  truncationLength,
};
