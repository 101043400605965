var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-screen-page-wrapper",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("inputs-grid", {
        ref: "inputsGrid",
        attrs: {
          "additional-columns": _vm.additionalColumns,
          "save-changes": _vm.saveChanges,
          "column-validations": _vm.columnValidations,
          "additional-dependent-columns": _vm.dependentColumns,
          "is-saveable": _vm.isSaveable,
          "export-action": _vm.downloadOverrides,
          "is-exporting": _vm.downloading,
          "route-suffix": "overrides",
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.canEditPriceOverrides
                  ? _c("price-overrides-upload")
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }