import axios from 'axios';
import to from 'await-to-js';
import _ from 'lodash';

const getInitialState = () => {
  return {
    i18nconfig: {},
    i18nTranslations: {},
    toggleLogic: {},
    featureFlagEnums: {},
    dateFormats: {},
    numberFormats: {},
    showCookieBanner: {},
    exportConfigs: {},
    toolStoreGroupConfig: {},
    storeGroupOrderConfig: {},
    wholesaleConfig: {},
    uploadConfig: {},
    alertTypes: {},
    alertOrder: {},
    alertsWithoutIcons: {},
    notUsingLocalDatabase: false,
    inputsPages: {},
    releaseFlags: {},
    userRoles: {},
    hierarchy: {},
    tooldata: {},
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    setI18nconfig(state, i18nconfig) {
      state.i18nconfig = i18nconfig;
    },

    setI18nTranslations(state, i18nTranslations) {
      state.i18nTranslations = i18nTranslations;
    },

    setToggleLogic(state, toggleLogic) {
      state.toggleLogic = toggleLogic;
    },

    setDateFormats(state, dateFormats) {
      state.dateFormats = dateFormats;
    },

    setNumberFormats(state, numberFormats) {
      state.numberFormats = numberFormats;
    },

    setShowCookieBanner(state, showCookieBanner) {
      state.showCookieBanner = showCookieBanner;
    },

    setExportConfigs(state, exportConfigs) {
      state.exportConfigs = exportConfigs;
    },

    setToolStoreGroupConfig(state, toolStoreGroupConfig) {
      state.toolStoreGroupConfig = toolStoreGroupConfig;
    },

    setStoreGroupOrderConfig(state, storeGroupOrderConfig) {
      state.storeGroupOrderConfig = storeGroupOrderConfig;
    },

    setWholesaleConfig(state, wholesaleConfig) {
      state.wholesaleConfig = wholesaleConfig;
    },

    setUploadConfig(state, uploadConfig) {
      state.uploadConfig = uploadConfig;
    },

    setAlertTypes(state, alertTypes) {
      state.alertTypes = alertTypes;
    },

    setAlertOrder(state, alertOrder) {
      state.alertOrder = alertOrder;
    },

    setAlertsWithoutIcons(state, alertsWithoutIcons) {
      state.alertsWithoutIcons = alertsWithoutIcons;
    },

    setNotUsingLocalDatabase(state, notUsingLocalDatabase) {
      state.notUsingLocalDatabase = notUsingLocalDatabase;
    },

    setInputsPages(state, inputsPages) {
      state.inputsPages = inputsPages;
    },

    setToggleLogicValue(state, { toggle, value }) {
      state.toggleLogic[toggle] = value;
    },

    setReleaseFlagsValue(state, { toggle, value }) {
      state.releaseFlags[toggle] = value;
    },
    setReleaseFlags(state, releaseFlags) {
      state.releaseFlags = releaseFlags;
    },

    setUserRoles(state, userRoles) {
      state.userRoles = userRoles;
    },

    setHierarchy(state, hierarchy) {
      state.hierarchy = hierarchy;
    },

    setTooldata(state, tooldata) {
      state.tooldata = tooldata;
    },
  },

  getters: {
    isFeatureFlagEnabled: state => featureFlag => _.get(state, ['toggleLogic', featureFlag], false),
    // TODO: PRICE-2315 remove this hack
    getHardcodedStoreGroupOrder: state => {
      const hardcodedStoreGroups = _.get(state, 'wholesaleConfig.hardcodedStoreGroups');
      if (_.isEmpty(hardcodedStoreGroups)) return state.storeGroupOrderConfig;

      let counter = 0;
      const hardcodedStoreGroupOrder = {};
      const flatStoreGroupList = _.flatten(Object.values(hardcodedStoreGroups));
      flatStoreGroupList.forEach(storeGroup => {
        counter += 1;
        hardcodedStoreGroupOrder[storeGroup.toolStoreGroupDescription] = counter;
      });
      return hardcodedStoreGroupOrder;
    },

    getTranslation: state => translationKey => {
      // This hardcodes the en translation. That's true everywhere now but may change in future.
      // This should be changed so that it always points to the correct client language then.
      // This lets you use the same strings in vuex as in the client.
      return _.get(state.i18nTranslations.en, translationKey);
    },
  },

  actions: {
    async loadVueConfig({ commit }) {
      const [err, response] = await to(axios.get('/api/client-config/vue-config'));
      if (err) {
        throw new Error(err.message);
      }
      commit('setI18nconfig', response.data.i18n.i18nconfig);
      commit('setDateFormats', response.data.i18n.dateFormats);
      commit('setNumberFormats', response.data.i18n.numberFormats);
      commit('setShowCookieBanner', response.data.showCookieBanner);

      return response.data;
    },

    async loadClientConfig({ commit }) {
      const [err, response] = await to(axios.get('/api/client-config/'));
      if (err) {
        throw new Error(err.message);
      }
      commit('setToggleLogic', response.data.toggleLogic);
      commit('setToolStoreGroupConfig', response.data.toolStoreGroupConfig);
      // on loading toggle logic, set the default competitor weighting
      commit(
        'gridView/setSelectedWeighting',
        { selectedWeighting: response.data.toggleLogic.competitorIndexWeightings[0] },
        { root: true }
      );
      commit('setI18nTranslations', response.data.i18n.translations);
      commit('setExportConfigs', response.data.exportConfigs);
      commit('competitorMetadata/setCompetitorOrderConfig', response.data.competitorOrderConfig, {
        root: true,
      });
      commit('setUploadConfig', response.data.uploadConfig);
      commit('setStoreGroupOrderConfig', response.data.storeGroupOrderConfig);
      commit('setWholesaleConfig', response.data.wholesaleConfig);
      commit('setAlertTypes', response.data.alertTypes);
      commit('setAlertOrder', response.data.alertOrder);
      commit('setAlertsWithoutIcons', response.data.alertsWithoutIcons);
      commit('setNotUsingLocalDatabase', response.data.notUsingLocalDatabase);
      commit('setInputsPages', response.data.inputsPages);
      commit('setReleaseFlags', response.data.releaseFlags);
      commit('setUserRoles', response.data.userRoles);
      commit('setHierarchy', response.data.hierarchy);
      commit('setTooldata', response.data.tooldata);

      return response.data;
    },

    setToggleLogicValue({ commit }, { toggle, value }) {
      commit('setToggleLogicValue', { toggle, value });
      const jobId = 'update_toggle_logic';
      const baseTranslation = `notifications.${jobId}`;
      const jobStatus = 'finished';
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: toggle,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },

    setReleaseFlagsValue({ commit }, { toggle, value }) {
      commit('setReleaseFlagsValue', { toggle, value });
      const jobId = 'update_toggle_logic';
      const baseTranslation = `notifications.${jobId}`;
      const jobStatus = 'finished';
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: toggle,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },
  },
};

export default store;
