<template functional>
  <svg width="15" height="18" viewBox="0 0 15 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="0.335609" y="1.28098" width="14.0956" height="15.438" rx="2.34927" fill="white" />
    <path
      d="M7.38339 6.65066C8.02944 6.65066 8.55802 6.12207 8.55802 5.47602C8.55802 4.82998 8.02944 4.30139 7.38339 4.30139C6.73734 4.30139 6.20876 4.82998 6.20876 5.47602C6.20876 6.12207 6.73734 6.65066 7.38339 6.65066ZM7.38339 7.82529C6.73734 7.82529 6.20876 8.35388 6.20876 8.99992C6.20876 9.64597 6.73734 10.1746 7.38339 10.1746C8.02944 10.1746 8.55802 9.64597 8.55802 8.99992C8.55802 8.35388 8.02944 7.82529 7.38339 7.82529ZM7.38339 11.3492C6.73734 11.3492 6.20876 11.8778 6.20876 12.5238C6.20876 13.1699 6.73734 13.6985 7.38339 13.6985C8.02944 13.6985 8.55802 13.1699 8.55802 12.5238C8.55802 11.8778 8.02944 11.3492 7.38339 11.3492Z"
      fill="#39477B"
    />
    <rect
      x="0.335609"
      y="1.28098"
      width="14.0956"
      height="15.438"
      rx="2.34927"
      stroke="#39477B"
      stroke-width="0.671219"
    />
  </svg>
</template>

<script>
export default {
  name: 'MenuButtonIcon',
};
</script>
