import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    [
      _c(
        VFlex,
        [
          _c(
            VExpansionPanels,
            { attrs: { value: _vm.value } },
            [
              _c(
                VExpansionPanel,
                {
                  staticClass: "borderless-panel",
                  attrs: { disabled: _vm.disabled },
                  on: { change: _vm.panelOpenedOrClosed },
                },
                [
                  _c(
                    VExpansionPanelHeader,
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "actions",
                          fn: function () {
                            return [_c(VIcon, [_vm._v("expand_more")])]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(
                          _vm._s(_vm.$t("settings.setArchitectureRules.title"))
                        ),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VExpansionPanelContent,
                    { staticClass: "expansion-body px-0 py-0" },
                    [
                      _c(
                        VRow,
                        [
                          _c(
                            VCol,
                            { staticClass: "py-0 pr-0", attrs: { md: "6" } },
                            [
                              _c("architecture-drivers-ruleset", {
                                attrs: { disabled: _vm.isDisabled },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VCol,
                            { attrs: { md: "6" } },
                            [
                              _c("architecture-drivers-overview", {
                                attrs: { disabled: _vm.isDisabled },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }