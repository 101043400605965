<template>
  <v-container ref="marginSplitsContainer" fluid class="margin-splits-wrapper px-0 py-0">
    <wholesale-sidebar />
    <basic-spinner v-if="wholesaleMarginSplitsLoading" class="spinner-wrapper" />
    <template v-else>
      <!-- Default split -->
      <div class="d-flex py-2">
        <span class="root" :class="{ 'highlighted-node': isDefaultSelected() }">
          {{ $t('wholesale.defaultSplit') }}
        </span>
        <!-- Drop down for root grid -->
        <v-select
          v-model="selectedAllocations[wholesaleHierarchyTree[0].levelEntryKey]"
          :items="availableGridDescriptions"
          class="label-part grid-dropdown"
          :menu-props="{
            contentClass: 'margin-splits-grids-menu',
          }"
          :disabled="isExportModalOpen"
          @change="setSelectedHierarchyGrid($event, wholesaleHierarchyTree[0])"
        >
          <template v-slot:append-item>
            <dropdown-list-item
              divider
              :title="$t('wholesale.createNewGrid')"
              icon-class="plus-box"
              @selectOption="openEditWholesaleGridDialog(wholesaleHierarchyTree[0], true)"
            />
          </template>
        </v-select>
        <!-- Export grids button -->
        <template v-if="canDisplayWholesaleExportAll">
          <wholesale-grids-export-button />
        </template>
        <template v-if="canDisplayWholesaleImportGrids">
          <wholesale-grids-import-button :disabled="isExportModalOpen" />
        </template>
      </div>
      <div class="hierarchy-tree-wrapper" :class="{ disabled: isExportModalOpen }">
        <!-- headers -->
        <div class="header d-flex">
          <div
            v-for="(header, ix) in hierarchyHeaders"
            :key="ix"
            :class="header.class"
            class="pa-3"
          >
            {{ header.text }}
          </div>
        </div>
        <!-- hierarchy tree -->
        <div class="treeview-container">
          <v-treeview
            v-if="sortedWholesaleHierarchyTreeWithoutRoot"
            item-key="levelEntryKey"
            :items="sortedWholesaleHierarchyTreeWithoutRoot"
            :open="openedNodes"
          >
            <template v-slot:label="{ item: node }">
              <div
                class="label-part hierarchy-name"
                :class="{ 'highlighted-node': isNodeHighlighted(node) }"
              >
                <tooltip
                  :disabled="isTooltipDisabled(node.levelEntryDescription, truncationLength)"
                  position="top"
                  :value="node.levelEntryDescription"
                >
                  <span class="hierarchy-name__label">{{ hierarchyNodeDisplay(node) }}</span>
                </tooltip>
              </div>
              <!-- Drop down for grids -->
              <v-select
                v-if="node.level !== 1"
                v-model="selectedAllocations[node.levelEntryKey]"
                attach
                :items="gridDescriptionItems(node.levelEntryKey)"
                class="label-part grid-dropdown"
                :menu-props="{
                  closeOnClick: true,
                  closeOnContentClick: true,
                  contentClass: 'margin-splits-grids-menu',
                  top: hierarchyKeyToPositionMap[node.levelEntryKey] > 5,
                }"
                :disabled="isExportModalOpen"
                @change="setSelectedHierarchyGrid($event, node)"
              >
                <template v-slot:selection="{ item }">
                  <div class="v-select__selection v-select__selection--comma">
                    {{ item.inherited ? $t('wholesale.inherited') : item.value }}
                  </div>
                </template>
                <template v-slot:append-item>
                  <dropdown-list-item
                    divider
                    :title="$t('wholesale.removeGridAssignment')"
                    icon-class="close-circle"
                    @selectOption="removeGridAssignment(node)"
                  />
                  <dropdown-list-item
                    :title="$t('wholesale.createNewGrid')"
                    icon-class="plus-box"
                    @selectOption="openEditWholesaleGridDialog(node, true)"
                  />
                </template>
              </v-select>
              <i
                v-if="node.level !== 1"
                aria-hidden="true"
                class="label-part mdi open-details-icon"
                :class="{
                  'mdi-eye-off-outline': isViewDisabled(node),
                  'mdi-eye-outline': !isViewDisabled(node),
                  clickable: !isViewDisabled(node),
                }"
                @click="openEditWholesaleGridDialog(node)"
              />
              <div v-if="node.children" class="label-part icon-wrapper d-flex align-center">
                <i
                  aria-hidden="true"
                  :class="isNodeOpen(node.levelEntryKey) ? 'mdi-chevron-left' : 'mdi-chevron-right'"
                  class="mdi expand-icon clickable"
                  @click="toggleNodes(node.levelEntryKey)"
                />
              </div>

              <!-- Small horizontal line -->
              <div class="node-line" />
              <div v-if="node.children" class="node-group-line" />
            </template>
          </v-treeview>
        </div>
      </div>
      <div class="d-flex justify-end align-center py-0 px-0">
        <v-btn
          color="success"
          class="save ml-2"
          small
          depressed
          :disabled="disableSave"
          :loading="savingGridAllocations"
          @click="save"
        >
          {{ $t('actions.save') }}
        </v-btn>
        <template v-if="allowSAPExport">
          <span class="mr-3 ml-3 btn-divider" />
          <v-btn
            color="success"
            class="save mr-2"
            small
            depressed
            :loading="savingGridAllocations"
            :disabled="disableSaveAndExportDialog"
            @click="saveAndOpenExportDialog"
          >
            {{ $t('wholesale.export.button') }}
          </v-btn>
          <div class="buffer-div px-2 py-3" />
        </template>
      </div>
      <div v-if="!hasGridAllocatedToRoot" class="d-flex justify-end save-warning pt-1">
        {{ $t('wholesale.saveWarning') }}
      </div>
    </template>
    <wholesale-grid-dialog
      v-if="selectedGrid"
      :grid-details="selectedGrid"
      :is-open="isEditWholesaleGridDialogOpened"
      @closeDialog="closeWholesaleGridDialog"
    />
    <wholesale-export-dialog
      v-if="isExportModalOpen && !wholesaleMarginSplitsLoading"
      :attach-to="$refs.marginSplitsContainer"
      :is-open="isExportModalOpen"
    />
  </v-container>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import {
  find,
  flatMap,
  includes,
  has,
  reduce,
  keyBy,
  cloneDeep,
  get,
  isEmpty,
  invert,
  isEqual,
  pull,
  forEach,
  values,
  mapValues,
  keys,
  indexOf,
  sortBy,
} from 'lodash';
import { wholesaleHierarchyLevels } from '@enums/hierarchy';
import isTooltipDisabled from '../../../utils/tooltip-util';
import exportType from '@enums/export-type';
import exportStatus from '@enums/wholesale-export-status';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { displayWholesaleExportAll, displayWholesaleImportGrids } from '@enums/feature-flags';

const { rootLevel, unitLevel } = wholesaleHierarchyLevels;

export default {
  mixins: [featureFlagsMixin],
  data() {
    return {
      openedNodes: [],
      isEditWholesaleGridDialogOpened: false,
      selectedGrid: null,
      mutableHierarchyIdGridMap: {},
      hierarchyGridUpdates: {},
      gridAllocationToCreate: {},
      selectedAllocations: {},
      hasAllocationChanges: false,
      hierarchiesToHighlight: [],
      truncationLength: 28,
      hierarchyTreeFamilyLines: [],
      hierarchyParentChildMap: {},
      exportType,
    };
  },

  computed: {
    ...mapState('wholesale', [
      'wholesaleMarginSplitsLoading',
      'wholesaleHierarchyTree',
      'wholesaleHierarchyIdGridMap',
      'gridAllocationsWithName',
      'gridIdDescriptionMap',
      'savingGridAllocations',
      'generatingWholesaleResults',
      'gridIdToHighlight',
      'latestWholesaleExport',
      'highlightLoading',
    ]),
    ...mapGetters('context', ['isWholesaleManager']),

    canDisplayWholesaleExportAll() {
      return this.isFeatureFlagEnabled(displayWholesaleExportAll);
    },

    canDisplayWholesaleImportGrids() {
      return this.isFeatureFlagEnabled(displayWholesaleImportGrids);
    },

    hierarchyHeaders() {
      const splitHeader = {
        text: this.$tc('wholesale.split', 1),
        class: 'header-split',
      };
      const hierarchyHeaders = [
        {
          text: this.$tc('wholesale.hierarchies.unit', 1),
          class: 'header-hierarchy-unit',
        },
        {
          text: this.$tc('wholesale.hierarchies.category', 1),
          class: 'header-hierarchy',
        },
        {
          text: this.$tc('wholesale.hierarchies.subcategory', 1),
          class: 'header-hierarchy',
        },
        {
          text: this.$t('wholesale.hierarchies.segment'),
          class: 'header-hierarchy',
        },
        {
          text: this.$t('wholesale.hierarchies.subsegment'),
          class: 'header-hierarchy',
        },
        {
          text: this.$t('wholesale.hierarchies.microsegment'),
          class: 'header-hierarchy',
        },
      ];
      return reduce(
        hierarchyHeaders,
        (acc, header) => {
          if (header.text === this.$tc('wholesale.hierarchies.unit', 1)) {
            return [...acc, header];
          }
          return [...acc, header, splitHeader];
        },
        []
      );
    },

    sortedWholesaleHierarchyTreeWithoutRoot() {
      const tree = flatMap(this.wholesaleHierarchyTree, v => v.children);
      return sortBy(tree, ['levelEntryDescription']).map(node =>
        this.recursiveSortTreeNodeChildren(node)
      );
    },

    availableGridDescriptions() {
      return values(this.gridIdDescriptionMap).sort();
    },

    allocatedGridByIdAndHierarchyLevel() {
      return keyBy(
        this.gridAllocationsWithName,
        allocation => `${allocation.gridId}::${allocation.hierarchyLevel}`
      );
    },

    hierarchyKeyToPositionMap() {
      const map = {};
      const sortedRootChildren = sortBy(get(this.wholesaleHierarchyTree, [0, 'children'], []), [
        'levelEntryDescription',
      ]);
      this.generateHierarchyKeyToPositionMap(
        map,
        { ...this.wholesaleHierarchyTree[0], children: sortedRootChildren },
        [...this.openedNodes]
      );
      return map;
    },

    hasGridAllocatedToRoot() {
      // The user must assign a grid to the root level hierarchy before saving the allocations for the first time
      const rootHierarchyId = this.wholesaleHierarchyTree[0].levelEntryKey;
      const rootHierarchyGridId = get(this.mutableHierarchyIdGridMap, [rootHierarchyId, 'gridId']);
      return !!rootHierarchyGridId;
    },

    gridIdWholesaleHierarchyIdsMap() {
      return reduce(
        this.mutableHierarchyIdGridMap,
        (acc, value, key) => {
          const { gridId } = value;
          if (!acc[gridId]) acc[gridId] = [];
          acc[gridId].push(key);
          return acc;
        },
        {}
      );
    },

    // Only managers can export, so only show the export button to them.
    allowSAPExport() {
      return this.isWholesaleManager;
    },

    disableSaveAndExportDialog() {
      return (
        !this.hasGridAllocatedToRoot ||
        this.isExportModalOpen ||
        this.savingGridAllocations ||
        this.generatingWholesaleResults
      );
    },

    disableSave() {
      return !this.hasAllocationChanges || this.disableSaveAndExportDialog;
    },

    isExportModalOpen() {
      if (this.latestWholesaleExport) {
        const { status, exportedToFTP } = this.latestWholesaleExport;
        return (
          status !== exportStatus.success || (status === exportStatus.success && !exportedToFTP)
        );
      }
      return false;
    },
  },

  watch: {
    wholesaleHierarchyIdGridMap: {
      handler(newValue) {
        if (!isEqual(newValue, this.mutableHierarchyIdGridMap)) {
          this.mutableHierarchyIdGridMap = cloneDeep(newValue);
          this.updateSelectedAllocation();
        }
      },
    },

    gridIdToHighlight: {
      async handler(gridId) {
        await this.getHierarchiesToHighlight(gridId);
      },
    },
  },

  async created() {
    await this.fetchWholesaleExports();
    await this.fetchMarginSplits();
    const { hierarchyTreeFamilyLines, hierarchyParentChildMap } = this.setHierarchyTreeData(
      this.wholesaleHierarchyTree[0]
    );
    this.hierarchyTreeFamilyLines = hierarchyTreeFamilyLines;
    this.hierarchyParentChildMap = hierarchyParentChildMap;
  },

  methods: {
    ...mapActions('wholesale', [
      'fetchMarginSplits',
      'saveGridAllocations',
      'addNewGridToGridIdDescriptionMap',
      'generateWholesaleResultsExport',
      'fetchWholesaleExports',
      'wholesaleExportsFetching',
      'setGridIdToHighlight',
      'computeHierarchiesToHighlight',
    ]),
    ...mapActions('wholesaleGrid', ['setSelectedWholesaleGrid']),

    isTooltipDisabled,

    recursiveSortTreeNodeChildren(node) {
      if (isEmpty(get(node, 'children'))) {
        return node;
      }
      node.children = sortBy(node.children, ['levelEntryDescription']).map(child =>
        this.recursiveSortTreeNodeChildren(child)
      );
      return node;
    },

    gridDescriptionItems(levelEntryKey) {
      const hierarchyGrid = get(this.mutableHierarchyIdGridMap, levelEntryKey);
      const inherited = get(hierarchyGrid, 'inherited', false);
      return this.availableGridDescriptions.map(description => {
        return {
          text: description,
          value: description,
          inherited: get(hierarchyGrid, 'gridDescription') === description && inherited,
        };
      });
    },

    getGridDescriptionById(hierarchyId) {
      const grid = this.wholesaleHierarchyIdGridMap[hierarchyId];
      return grid.inherited ? this.$t('wholesale.inherited') : grid.gridDescription;
    },

    isNodeOpen(key) {
      return this.openedNodes.includes(key);
    },

    toggleNodes(levelEntryKey) {
      const indexOfNode = indexOf(this.openedNodes, levelEntryKey);
      if (indexOfNode !== -1) {
        this.openedNodes.splice(indexOfNode, 1);
        return;
      }
      this.openedNodes.push(levelEntryKey);
    },

    openEditWholesaleGridDialog(node, createNewGrid) {
      if (createNewGrid) {
        this.selectedGrid = { node };
      } else {
        if (!this.mutableHierarchyIdGridMap[node.levelEntryKey]) return;
        this.selectedGrid = {
          node,
          ...this.mutableHierarchyIdGridMap[node.levelEntryKey],
        };
      }
      this.setSelectedWholesaleGrid({
        selectedWholesaleGrid: {
          gridId: this.selectedGrid.gridId,
          gridDescription: this.selectedGrid.gridDescription,
        },
      });
      this.isEditWholesaleGridDialogOpened = true;
    },

    closeWholesaleGridDialog({ newGridId, newGridDescription }) {
      const { node } = this.selectedGrid;
      this.selectedGrid = null;
      this.isEditWholesaleGridDialogOpened = false;

      if (!newGridId) {
        return;
      }

      this.addNewGridToGridIdDescriptionMap({
        gridId: newGridId,
        gridDescription: newGridDescription,
      });

      this.selectedAllocations[node.levelEntryKey] = newGridDescription;
      this.setSelectedHierarchyGrid(newGridDescription, node);
    },

    setHierarchyTreeData(tree, parentChildMap = {}, familyLines = [], path = '') {
      const { children, levelEntryKey } = tree;
      const currentPath = !path ? levelEntryKey.toString() : `${path}::${levelEntryKey}`;

      if (isEmpty(children)) {
        parentChildMap[levelEntryKey] = [];
        familyLines.push(new Set(currentPath.split('::')));
      } else {
        parentChildMap[levelEntryKey] = children.map(child => child.levelEntryKey);
        forEach(children, child => {
          this.setHierarchyTreeData(child, parentChildMap, familyLines, currentPath);
        });
      }
      return {
        hierarchyTreeFamilyLines: familyLines,
        hierarchyParentChildMap: parentChildMap,
      };
    },

    formatHierarchyGridUpdates() {
      // get updated hierarchyIds for existing grid allocation
      const updates = reduce(
        this.hierarchyGridUpdates,
        (acc, value, key) => {
          const pathToAddHierarchyId = get(value, 'add');
          const pathToRemoveHierarchyId = get(value, 'remove');
          if (pathToAddHierarchyId) {
            if (!acc[pathToAddHierarchyId]) {
              const {
                hierarchyIds,
                lastExportedHierarchyIds,
              } = acc.allocatedGridByIdAndHierarchyLevel[pathToAddHierarchyId];
              acc[pathToAddHierarchyId] = { hierarchyIds, lastExportedHierarchyIds };
            }
            acc[pathToAddHierarchyId].hierarchyIds.push(key);
          }
          if (pathToRemoveHierarchyId) {
            if (!acc[pathToRemoveHierarchyId]) {
              const {
                hierarchyIds,
                lastExportedHierarchyIds,
              } = acc.allocatedGridByIdAndHierarchyLevel[pathToRemoveHierarchyId];
              acc[pathToRemoveHierarchyId] = { hierarchyIds, lastExportedHierarchyIds };
            }
            acc[pathToRemoveHierarchyId].hierarchyIds = pull(
              acc[pathToRemoveHierarchyId].hierarchyIds,
              key
            );
          }
          return acc;
        },
        { allocatedGridByIdAndHierarchyLevel: this.allocatedGridByIdAndHierarchyLevel }
      );
      delete updates.allocatedGridByIdAndHierarchyLevel;
      return updates;
    },

    async save() {
      const updates = {
        gridAllocationToUpdate: this.formatHierarchyGridUpdates(),
        gridAllocationToCreate: this.gridAllocationToCreate,
      };
      await this.saveGridAllocations(updates);
      this.reset();
    },

    async saveAndOpenExportDialog() {
      const updates = {
        gridAllocationToUpdate: this.formatHierarchyGridUpdates(),
        gridAllocationToCreate: this.gridAllocationToCreate,
      };
      await this.saveGridAllocations(updates);

      this.generateWholesaleResultsExport();
    },

    reset() {
      this.hierarchyGridUpdates = {};
      this.gridAllocationToCreate = {};
      this.hasAllocationChanges = false;
      this.setGridIdToHighlight(null);
    },

    createHierarchyAllocation(hierarchyGrid, hierarchyId) {
      const gridId = hierarchyGrid.gridId;
      const hierarchyLevel = hierarchyGrid.hierarchyLevel;
      const path = `${gridId}::${hierarchyLevel}`;
      const newGridAllocation = {
        gridDescription: hierarchyGrid.gridDescription,
        gridId,
        hierarchyIds: [hierarchyId],
        hierarchyLevel,
      };
      this.gridAllocationToCreate[path] = newGridAllocation;
    },

    updateChildHierarchyGrid(hierarchyId) {
      /** Updates inherited descendants in mutableHierarchyIdGridMap if it has a different grid allocation
       * Using recursion to update the child/grandchild/... that directly inherites from the given hierarchy.
       */
      const grid = this.mutableHierarchyIdGridMap[hierarchyId];
      const children = this.hierarchyParentChildMap[hierarchyId];
      forEach(children, childId => {
        const childGrid = this.mutableHierarchyIdGridMap[childId];
        // if the child already have the correct grid allocated, no need to update it
        // e.g. Removing grid assignment from the same grid
        const needsUpdate = get(childGrid, 'inherited', false) && childGrid.gridId !== grid.gridId;
        if (needsUpdate) {
          const updatedGrid = {
            ...childGrid,
            gridId: grid.gridId,
            gridDescription: grid.gridDescription,
          };
          this.$set(this.mutableHierarchyIdGridMap, childId, updatedGrid);
          if (!isEmpty(this.hierarchyParentChildMap[childId])) {
            return this.updateChildHierarchyGrid(childId);
          }
        }
      });
    },

    removeEmptyCreatedAllocation() {
      const emptyAllocations = reduce(
        this.gridAllocationToCreate,
        (acc, value, key) => {
          if (isEmpty(value.hierarchyIds)) {
            acc.push(key);
          }
          return acc;
        },
        []
      );
      forEach(emptyAllocations, key => {
        this.$delete(this.gridAllocationToCreate, key);
      });
    },

    changeHierarchyAllocation(hierarchyId, hierarchyGrid, action) {
      const gridId = hierarchyGrid.gridId;
      const hierarchyLevel = hierarchyGrid.hierarchyLevel;
      const existedValue = this.hierarchyGridUpdates[hierarchyId];
      const newValue = existedValue
        ? Object.assign(existedValue, { [action]: `${gridId}::${hierarchyLevel}` })
        : { [action]: `${gridId}::${hierarchyLevel}` };
      this.$set(this.hierarchyGridUpdates, hierarchyId, newValue);
    },

    allocationExistInDB(gridId, level) {
      return has(this.allocatedGridByIdAndHierarchyLevel, `${gridId}::${level}`);
    },

    updateHierarchyGridUpdates() {
      forEach(this.mutableHierarchyIdGridMap, (currentHierarchyGrid, hierarchyId) => {
        const previousHierarchyGrid = this.wholesaleHierarchyIdGridMap[hierarchyId];
        const currentGridId = currentHierarchyGrid.gridId;
        const currentLevel = currentHierarchyGrid.hierarchyLevel;
        if (!isEqual(currentHierarchyGrid, previousHierarchyGrid)) {
          if (previousHierarchyGrid && !previousHierarchyGrid.inherited) {
            this.changeHierarchyAllocation(hierarchyId, previousHierarchyGrid, 'remove');
          }
          if (
            !currentHierarchyGrid.inherited &&
            this.allocationExistInDB(currentGridId, currentLevel)
          ) {
            this.changeHierarchyAllocation(hierarchyId, currentHierarchyGrid, 'add');
          }
        } else {
          this.$delete(this.hierarchyGridUpdates, hierarchyId);
        }
      });
    },

    checkAllocationChanges() {
      this.updateHierarchyGridUpdates();
      this.hasAllocationChanges =
        !isEmpty(this.gridAllocationToCreate) || !isEmpty(this.hierarchyGridUpdates);
    },

    updateSelectedAllocation() {
      this.selectedAllocations = mapValues(this.mutableHierarchyIdGridMap, 'gridDescription');
    },

    removeHierarchyIdFromCreated(hierarchyId) {
      const previousCreatedAllocation = find(this.gridAllocationToCreate, value =>
        includes(value.hierarchyIds, hierarchyId)
      );
      if (previousCreatedAllocation) {
        this.$set(
          this.gridAllocationToCreate[
            `${previousCreatedAllocation.gridId}::${previousCreatedAllocation.hierarchyLevel}`
          ],
          'hierarchyIds',
          pull(previousCreatedAllocation.hierarchyIds, hierarchyId)
        );
        this.removeEmptyCreatedAllocation();
      }
    },

    async removeGridAssignment(node) {
      const hierarchyId = node.levelEntryKey;
      if (this.mutableHierarchyIdGridMap[hierarchyId].inherited) return;
      const parentId = node.parentId;
      const parentGrid = this.mutableHierarchyIdGridMap[parentId];
      const previousHierarchyGrid = this.wholesaleHierarchyIdGridMap[hierarchyId];
      if (!previousHierarchyGrid.inherited) {
        // Remove hierarchyId from existing grid alloction
        this.changeHierarchyAllocation(hierarchyId, previousHierarchyGrid, 'remove');
      } else {
        this.removeHierarchyIdFromCreated(hierarchyId);
      }
      const newGrid = {
        ...previousHierarchyGrid,
        inherited: true,
        gridId: parentGrid.gridId,
        gridDescription: parentGrid.gridDescription,
      };
      this.$set(this.mutableHierarchyIdGridMap, hierarchyId, newGrid);
      this.updateChildHierarchyGrid(hierarchyId);
      this.updateSelectedAllocation();
      this.checkAllocationChanges();
      await this.getHierarchiesToHighlight(this.gridIdToHighlight);
    },

    async setSelectedHierarchyGrid(gridDescription, node) {
      /**
       * Update mutableHierarchyIdGridMap for display and modifies gridAllocationToCreate.
       * 1. Remove the hierarchy assignment from previously created grid allocation if exists
       * 2. Update mutableHierarchyIdGridMap with selected grid allocation
       *    If the selected allocation exists in mongo, assign it directly
       *    If the selected allocation has been created, assign the created allocation
       *    If the selected allocation doesn't exist, create one and then assign.
       * 3. Update dependant hierarchies.
       * 4. Check allocation changes for save.
       */
      const hierarchyId = node.levelEntryKey;
      const selectedGridId = invert(this.gridIdDescriptionMap)[gridDescription];
      const selectedHierarchyGrid = {
        gridId: selectedGridId,
        gridDescription,
        hierarchyLevel: node.level,
      };
      const path = `${selectedGridId}::${node.level}`;

      this.removeHierarchyIdFromCreated(hierarchyId);
      const selectedGridAllocation = get(this.allocatedGridByIdAndHierarchyLevel, path);
      if (isEmpty(selectedGridAllocation)) {
        if (has(this.gridAllocationToCreate, path)) {
          const hierarchyIds = this.gridAllocationToCreate[path].hierarchyIds;
          this.$set(this.gridAllocationToCreate[path], 'hierarchyIds', [
            ...hierarchyIds,
            hierarchyId,
          ]);
        } else {
          this.createHierarchyAllocation(selectedHierarchyGrid, hierarchyId);
        }
      }
      this.$set(this.mutableHierarchyIdGridMap, hierarchyId, selectedHierarchyGrid);
      this.updateChildHierarchyGrid(hierarchyId);
      this.updateSelectedAllocation();
      this.checkAllocationChanges();
      await this.getHierarchiesToHighlight(this.gridIdToHighlight);
    },

    generateHierarchyKeyToPositionMap(hierarchyKeyToPositionMap, hierarchyNode, openedNodes) {
      const { levelEntryKey, children, level } = hierarchyNode;
      const isNodeOpened = openedNodes.includes(levelEntryKey);

      if (level !== rootLevel) {
        hierarchyKeyToPositionMap[levelEntryKey] = keys(hierarchyKeyToPositionMap).length + 1;
      }

      const shouldHandleChildren = (isNodeOpened || level === rootLevel) && !isEmpty(children);

      if (!shouldHandleChildren) {
        return;
      }

      children.forEach(childNode => {
        this.generateHierarchyKeyToPositionMap(hierarchyKeyToPositionMap, childNode, openedNodes);
      });
    },

    isViewDisabled(node) {
      return isEmpty(get(this.mutableHierarchyIdGridMap, node.levelEntryKey));
    },

    isNodeHighlighted(node) {
      return includes(this.hierarchiesToHighlight, node.levelEntryKey);
    },

    isDefaultSelected() {
      const rootHierarchyId = get(this.wholesaleHierarchyTree, '0.levelEntryKey');
      const rootHierarchyGridId = get(this.mutableHierarchyIdGridMap, [rootHierarchyId, 'gridId']);
      return isEqual(this.gridIdToHighlight, rootHierarchyGridId);
    },

    async getHierarchiesToHighlight(gridId) {
      if (!gridId) {
        this.hierarchiesToHighlight = [];
        return;
      }
      this.hierarchiesToHighlight = await this.computeHierarchiesToHighlight({
        gridId,
        gridIdWholesaleHierarchyIdsMap: this.gridIdWholesaleHierarchyIdsMap,
        hierarchyTreeFamilyLines: this.hierarchyTreeFamilyLines.map(h => Array.from(h)),
      });
    },
    hierarchyNodeDisplay(node) {
      const name = node.levelEntryDescription;
      const idDisplay = node.level > unitLevel ? node.clientKey : null;
      return isEmpty(idDisplay) ? name : `${idDisplay} - ${name}`;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.margin-splits-grids-menu {
  $row-height: 2rem;
  min-width: 19rem !important;

  // to override style
  max-height: 27rem !important;

  .v-list {
    padding: 0;
  }

  .v-list-item {
    min-height: $row-height;
  }
}

.margin-splits-wrapper {
  $line-height: 3rem;
  $root-node-padding-left: 2rem;
  $expand-icon-size: 2rem;
  $open-details-icon-size: 2rem;
  $hierarchy-level-width: 16rem;
  $node-label-margin: 1.2rem;
  $node-line-width: 1.7rem;
  $hierarchy-level-header-width: $hierarchy-level-width + $node-label-margin;
  $unit-level-header-width: $hierarchy-level-header-width + $expand-icon-size;
  $split-width: 16rem;
  $split-header-width: $split-width + $node-label-margin + $expand-icon-size + $node-label-margin +
    $open-details-icon-size;
  .open-details-icon {
    font-size: $expand-icon-size;
    color: $icon-colour;
  }

  .expand-icon {
    color: $pricing-white;
    font-size: $expand-icon-size;
  }

  .icon-wrapper {
    background-color: $icon-colour;
    border-radius: 4px;
    height: $expand-icon-size;
  }

  .root {
    font-weight: bold;
    font-size: 1.2rem;
    margin-right: 1.2rem;
    padding: 0.5rem 1rem 0.5rem 1rem;
    align-self: center;
  }
  .root.highlighted-node {
    background-color: $margin-split-highlight-color;
  }

  .header {
    border: 0.1rem solid $btn-spacer-grey;
    border-width: 0.1rem 0 0.1rem 0;
    text-align: start;
    font-size: 1.2rem;
    width: max-content;

    .header-hierarchy {
      font-weight: bold;
      min-width: $hierarchy-level-header-width + $node-line-width;
      max-width: $hierarchy-level-header-width + $node-line-width;
    }
    .header-hierarchy-unit {
      font-weight: bold;
      min-width: $unit-level-header-width;
      max-width: $unit-level-header-width;
    }
    .header-split {
      min-width: $split-header-width;
      max-width: $split-header-width;
    }
  }

  .label-part + .label-part {
    margin-left: $node-label-margin;
  }

  .grid-dropdown {
    min-width: $split-width;
    max-width: $split-width;
  }

  .hierarchy-name {
    background-color: $settingsTableRowColor;
    border-radius: 4px;
    box-sizing: content-box;
    font-size: 1.2rem;
    font-weight: bold;
    height: 18px;
    max-width: $hierarchy-level-width;
    min-width: $hierarchy-level-width;
    padding: 5px 4px 7px 1rem;

    &__label {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }

  .hierarchy-name.highlighted-node {
    background-color: $margin-split-highlight-color;
  }

  .buffer-div {
    height: 4rem;
    background-color: $pricing-background;
  }

  .hierarchy-tree-wrapper {
    $tree-disabled-bg-color: rgba(218, 218, 218, 0.432);

    overflow: auto;
    max-height: 50rem;

    &.disabled {
      pointer-events: none;
      position: relative;
      user-select: none;

      &:after {
        content: '';
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        background-color: $tree-disabled-bg-color;
      }
    }
  }

  .treeview-container {
    display: flex;
    height: 100%;

    .node-line {
      width: $node-line-width;
      height: 50%;
      top: 50%;
      left: -1px;
      border-top: 1px solid $btn-spacer-grey;
      position: absolute;
    }

    .v-treeview-node__label {
      align-items: center;
      display: flex;
      margin-left: 0;
      overflow: visible;
    }

    .v-treeview-node__root {
      i.v-treeview-node__toggle {
        margin-bottom: 0px !important;
        visibility: hidden;
      }
    }

    .v-treeview-node__root .v-treeview-node__label:first-child {
      padding-left: 1rem;
    }

    .v-treeview-node__children {
      border-left: 1px solid $btn-spacer-grey;
      padding-left: $unit-level-header-width;

      .v-treeview-node__root {
        border-left: 1px solid $btn-spacer-grey;
        i.v-treeview-node__toggle {
          margin-bottom: 5px !important;
          visibility: hidden;
        }
      }
      .v-treeview-node__children {
        padding-left: $hierarchy-level-header-width + $split-header-width + $node-line-width;
      }

      /* Overrides to stop trailing lines */
      .v-treeview-node:last-child > .v-treeview-node__children {
        border-left: 1px solid $pricing-white;
      }
      .v-treeview-node:last-child
        > .v-treeview-node__root
        > .v-treeview-node__content
        > .v-treeview-node__label
        > .node-line {
        border-left: 1px solid $pricing-white;
      }
    }

    .v-treeview {
      .v-treeview-node__label {
        .v-btn {
          margin-top: 2px;
          margin-left: 2px;
        }
      }
    }

    /* Specifically remove the first border, to stop the whole treeview having a left border */
    .v-treeview > .v-treeview-node > .v-treeview-node__children {
      border: none;
    }

    .v-treeview > .v-treeview-node > .v-treeview-node__root {
      border-top: 1px solid $btn-spacer-grey;
      width: 100%;
    }
    .v-treeview > .v-treeview-node:first-child > .v-treeview-node__root {
      border: none;
    }

    .v-treeview
      > .v-treeview-node
      > .v-treeview-node__root
      > .v-treeview-node__content
      > .v-treeview-node__label
      > .node-line {
      display: none;
    }

    .v-treeview > .v-treeview-node > .v-treeview-node__root > .v-treeview-node__toggle {
      display: none;
    }

    .v-treeview-node--leaf > .v-treeview-node__root {
      padding-left: $root-node-padding-left;
      padding-right: 0;
    }

    .v-treeview-node,
    .v-treeview-node--leaf {
      margin-left: 0;
    }
  }
  .v-input {
    padding-top: 0.5rem;
    max-width: $split-width;
  }

  .v-input__slot,
  .v-select__slot {
    background: $pricing-background;
  }

  .v-select__selections input {
    padding-left: 5px;
    height: $line-height;
  }

  .v-input__slot {
    box-shadow: none;
    padding: 0;
  }

  .v-icon {
    padding-right: 0;
  }

  .v-label {
    position: unset;
    padding-left: 5px;
    font-size: 1.2rem;
  }

  .v-select__selection.v-select__selection--comma {
    padding-left: 5px;
  }
  .v-text-field__details {
    display: none;
  }

  .save-warning {
    color: $pricing-red;
    font-size: 1.2rem;
  }
}
</style>
