<template>
  <div
    class="icon-container"
    :custom-title-msg="tooltipMessage"
    :custom-title-position="tooltipPosition"
    :custom-title-hidden="disableTooltip"
  >
    <!-- In the case where no approval has been given. The approval status is null and  so a blank grey icon is shown
        There has to  be three tooltips as the tooltip does not re-render if the inner component changes and then we lose the tooltip message -->
    <span
      v-if="approved === true"
      v-once
      class="fa fa-check-circle icon"
      :style="{ color: success }"
    />
    <span
      v-if="approved === false"
      v-once
      class="fa fa-times-circle icon"
      :style="{ color: 'red' }"
    />
    <span
      v-if="unitLevelAndNull"
      v-once
      class="fa fa-circle icon"
      :style="{ color: nullIconColour }"
    />
  </div>
</template>

<script>
import colours from '../../../ow-colors';

export default {
  props: {
    approved: {
      required: true,
    },
    tooltipMessage: {
      type: String,
      default: () => null,
    },
    tooltipPosition: {
      type: String,
    },
    unitLevel: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      nullIconColour: colours.paramsFormBackgroundColor,
      success: colours.successGreen,
      catManGreen: colours.catManGreen,
    };
  },
  computed: {
    disableTooltip() {
      return this.tooltipMessage === null;
    },

    unitLevelAndNull() {
      return this.approved === null && this.unitLevel;
    },
  },
};
</script>

<style lang="scss" scoped>
.icon {
  font-size: 2.2rem;
  padding-top: 0.3rem;
}

.icon-container {
  align-items: center;
  display: flex;
  padding-left: 0.2rem;
}
</style>
