<template>
  <v-row class="architecture-tile pl-2">
    <v-flex xs11 grow class="pr-1">
      <v-card class="my-1">
        <v-card-title class="px-2 py-0">
          <v-layout>
            <v-flex xs6>
              <v-input background-color="grey lighten-3" class="mt-2" :disabled="disabled">
                <span class="px-1 text-truncate">{{ attribute.displayDescription }}</span>
              </v-input>
            </v-flex>
            <v-flex xs5 />
            <v-flex xs1 class="pt-2">
              <v-icon
                class="delete-icon float-right"
                :disabled="disabled"
                @click="emitRemoveFromSubGroupSplittingAttributes(attribute.attributeKey)"
              >
                delete
              </v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>
      </v-card>
    </v-flex>
    <v-flex xs1 class="draggable-attribute-icon">
      <v-icon class="drag-icon pt-3" :disabled="disabled" text-xs-right>open_with</v-icon>
    </v-flex>
  </v-row>
</template>

<script>
export default {
  props: {
    attribute: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {};
  },
  methods: {
    emitRemoveFromSubGroupSplittingAttributes(attributeKey) {
      this.$emit('removeFromSubGroupSplittingAttributes', { attributeKey });
    },
  },
};
</script>

<style lang="scss">
.architecture-tile {
  .v-messages__message {
    padding-top: 0.5rem;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-input input {
    height: 1.7rem;
    padding: 0.1rem 0.5rem;
  }
}
</style>
