import axios from 'axios';
import to from 'await-to-js';
import { isEmpty } from 'lodash';
import { jobStatuses } from '@enums/jobapi';
import exportStatus from '@enums/wholesale-export-status';

const getInitialState = () => {
  return {
    wholesaleEngineLoading: false,
    highlightLoading: false,
    wholesaleMarginSplitsLoading: false,
    wholesaleHierarchyTree: [],
    wholesaleHierarchyIdGridMap: {},
    gridAllocationsWithName: [],
    gridIdDescriptionMap: {},
    savingGridAllocations: false,
    generatingWholesaleResults: false,
    gridIdToHighlight: null,
    wholesaleExports: [],
    latestWholesaleExport: null,
    exportInProgress: false,
    downloadingFile: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setWholesaleEngineLoading(state, wholesaleEngineLoading) {
      state.wholesaleEngineLoading = wholesaleEngineLoading;
    },
    setWholesaleMarginSplitsLoading(state, marginSplitsLoading) {
      state.wholesaleMarginSplitsLoading = marginSplitsLoading;
    },
    setHighlightLoading(state, highlightLoading) {
      state.highlightLoading = highlightLoading;
    },
    setGridIdToHighlight(state, gridIdToHighlight) {
      state.gridIdToHighlight = gridIdToHighlight;
    },
    setWholesaleHierarchyTree(state, hierarchyTree) {
      state.wholesaleHierarchyTree = hierarchyTree;
    },
    setWholesaleHierarchyIdGridMap(state, hierarchyIdGridMap) {
      state.wholesaleHierarchyIdGridMap = hierarchyIdGridMap;
    },
    setGridAllocationsWithName(state, gridAllocationsWithName) {
      state.gridAllocationsWithName = gridAllocationsWithName;
    },
    setGridIdDescriptionMap(state, gridIdDescriptionMap) {
      state.gridIdDescriptionMap = gridIdDescriptionMap;
    },
    setSavingGridAllocations(state, savingGridAllocations) {
      state.savingGridAllocations = savingGridAllocations;
    },
    setGeneratingWholesaleResults(state, generatingWholesaleResults) {
      state.generatingWholesaleResults = generatingWholesaleResults;
    },
    setWholesaleExports(state, wholesaleExports) {
      state.wholesaleExports = wholesaleExports;
    },
    setLatestWholesaleExport(state, latestWholesaleExport) {
      state.latestWholesaleExport = latestWholesaleExport;
    },
    setExportInProgress(state, exportInProgress) {
      state.exportInProgress = exportInProgress;
    },
    setDownloadingFile(state, downloading) {
      state.downloadingFile = downloading;
    },
  },

  actions: {
    resetState({ commit }) {
      commit('resetState');
    },
    setHighlightLoading({ commit }, highlightLoading) {
      commit('setHighlightLoading', highlightLoading);
    },
    setGridIdToHighlight({ commit }, gridIdToHighlight) {
      commit('setGridIdToHighlight', gridIdToHighlight);
    },
    setLatestWholesaleExport({ commit }, latestWholesaleExport) {
      commit('setLatestWholesaleExport', latestWholesaleExport);
    },
    resetLatestWholesaleExport({ commit }) {
      commit('setLatestWholesaleExport', null);
      commit('setExportInProgress', false);
    },
    async computeHierarchiesToHighlight(
      { commit },
      { gridId, gridIdWholesaleHierarchyIdsMap, hierarchyTreeFamilyLines }
    ) {
      commit('setHighlightLoading', true);
      const [err, response] = await to(
        axios.post(`/api/wholesale/computeHierarchiesToHighlight`, {
          gridId,
          gridIdWholesaleHierarchyIdsMap,
          hierarchyTreeFamilyLines,
        })
      );
      commit('setHighlightLoading', false);
      if (err) throw Error(err);
      return response.data;
    },
    async checkSelectedWorkpackageWholesaleEngineStatus({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/wholesale-engine/workpackage/${workpackageId}/status`)
      );
      if (err) {
        commit('setWholesaleEngineLoading', false);
        throw Error(err);
      }
      commit('setWholesaleEngineLoading', response.data.inProgress);
    },
    async runWholesaleEngine({ rootState, commit }, { chosenWorkpackageId }) {
      const workpackageId = chosenWorkpackageId || rootState.workpackages.selectedWorkpackage._id;
      const kwargs = {
        workpackage_id: workpackageId,
      };
      commit('setWholesaleEngineLoading', true);
      const [err] = await to(
        axios.post('/api/wholesale-engine/dag', {
          params: kwargs,
        })
      );
      if (err) {
        commit('setWholesaleEngineLoading', false);
      }
    },
    reloadWholesaleDataAfterEngineRun({ commit, dispatch, rootState }, { payload }) {
      const selectedWorkpackage = rootState.workpackages.selectedWorkpackage;
      if (isEmpty(selectedWorkpackage) || payload !== selectedWorkpackage._id) {
        return;
      }
      commit('setWholesaleEngineLoading', false);
      dispatch('wholesaleProductResults/fetchAggregations', null, { root: true });
      dispatch('wholesaleProductResults/fetchMonthlyAggregations', null, { root: true });
    },
    async fetchMarginSplits({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setWholesaleMarginSplitsLoading', true);
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/marginSplits`)
      );
      if (err) {
        commit('setWholesaleMarginSplitsLoading', false);
        throw Error(err);
      }
      const {
        hierarchyTree,
        hierarchyIdGridMap,
        gridAllocationsWithName,
        gridIdDescriptionMap,
      } = response.data;
      commit('setWholesaleHierarchyTree', hierarchyTree);
      commit('setWholesaleHierarchyIdGridMap', hierarchyIdGridMap);
      commit('setGridAllocationsWithName', gridAllocationsWithName);
      commit('setGridIdDescriptionMap', gridIdDescriptionMap);
      commit('setWholesaleMarginSplitsLoading', false);
    },
    async saveGridAllocations({ rootState, commit, dispatch }, updates) {
      commit('setSavingGridAllocations', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.post(`/api/wholesale/workpackage/${workpackageId}/gridAllocation`, {
          updates,
        })
      );
      commit('setSavingGridAllocations', false);
      if (err) {
        throw Error(err);
      }
      dispatch('fetchMarginSplits');
    },
    addNewGridToGridIdDescriptionMap({ commit, state }, { gridId, gridDescription }) {
      commit('setGridIdDescriptionMap', {
        ...state.gridIdDescriptionMap,
        [gridId]: gridDescription,
      });
    },
    addExportWholesaleNotification({ rootState, commit, dispatch }, { jobStatus }) {
      const description = rootState.workpackages.selectedWorkpackage.description;
      const jobId = 'export_wholesale_results';
      const baseTranslation = `notifications.${jobId}`;
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: description,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
      dispatch('fetchMarginSplits'); // need to re-fetch to ensure lastExported Props are available on FE
    },
    // eslint-disable-next-line no-unused-vars
    addPushToFTPWholesaleNotification({ rootState, commit }, { jobStatus }) {
      const description = rootState.workpackages.selectedWorkpackage.description;
      const jobId = 'export_wholesale_push_to_ftp';
      const baseTranslation = `notifications.${jobId}`;
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: description,
        baseTranslation,
      };
      // eslint-disable-next-line no-console
      console.log(notification);
      // PRICE-2292 TODO: re-enable this or remove entirely
      // commit('notifications/addNotification', notification, { root: true });
    },
    async generateWholesaleResultsExport({ commit, dispatch, rootState }) {
      commit('setGeneratingWholesaleResults', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const url = `/api/wholesale/workpackage/${workpackageId}/marginSplits/results`;

      const [err] = await to(axios.post(url, {}));

      if (err) {
        dispatch('addExportWholesaleNotification', { jobStatus: jobStatuses.failed });
        commit('setGeneratingWholesaleResults', false);
        throw new Error(err.message);
      }

      // Refetch wholesale exports as soon as new export is generated
      dispatch('fetchWholesaleExports');
    },
    async handleWholesaleExportFailed({ state, rootState, dispatch }, { id: workpackageId }) {
      let latestExport;
      if (
        (workpackageId !== 'undefined' && !state.wholesaleExports.length) ||
        rootState.workpackages.selectedWorkpackage._id !== workpackageId
      ) {
        const wholesaleExports = await dispatch('fetchWholesaleExports', {
          workpackageId,
          returnDocuments: true,
        });
        latestExport = wholesaleExports.latestExport;
      } else {
        latestExport = state.latestWholesaleExport;
      }
      const url = `/api/wholesale-exports/${latestExport._id}`;
      const updates = { status: 'failed' };
      const [err] = await to(axios.patch(url, updates));
      if (err) {
        throw new Error(err.message);
      }
      dispatch('fetchWholesaleExports');
    },
    async fetchWholesaleExports(
      { commit, rootState, state },
      { workpackageId = null, returnDocuments = false } = {}
    ) {
      const wpId = workpackageId || rootState.workpackages.selectedWorkpackage._id;
      const url = `/api/wholesale-exports/workpackage/${wpId}`;
      const [err, response] = await to(axios.get(url));
      if (err) {
        commit('setGeneratingWholesaleResults', false);
        throw new Error(err.message);
      }

      const { data } = response;

      // Find latest export by timestamp
      const latestExport = data.reduce((prev, curr) => {
        if (curr === null || !curr.timestamp) return prev;
        if (prev === null || !prev.timestamp) return curr;

        return curr.timestamp > prev.timestamp ? curr : prev;
      }, null);

      if (returnDocuments) {
        return {
          wholesaleExports: response.data,
          latestExport,
        };
      }

      // Check if there are any exports available
      if (latestExport !== null) {
        const { status, exportedToFTP } = latestExport;
        const { latestWholesaleExport } = state;

        if (status === exportStatus.success) {
          // Export was generated successfully:
          // - and was exported to FTP - hide modal
          // - and was not exported to FTP - show modal
          const payload = exportedToFTP ? null : latestExport;
          commit('setLatestWholesaleExport', payload);
          commit('setGeneratingWholesaleResults', false);
        }

        if (
          (status === exportStatus.failed || status === exportStatus.noResults) &&
          latestWholesaleExport !== null
        ) {
          // Export failed:
          // - fresh page load (store is empty) - hide modal (latestWholesaleExport is null)
          // - some alert was shown (store is not empty) - show modal with updated export value
          commit('setLatestWholesaleExport', latestExport);
          commit('setGeneratingWholesaleResults', false);
        }

        if (status === exportStatus.pending) {
          // Export is still pending:
          // - straight up update the store
          commit('setLatestWholesaleExport', latestExport);
        }
      } else {
        commit('setGeneratingWholesaleResults', false);
      }

      commit('setWholesaleExports', data);
    },
    async pushToFTP({ state, commit, dispatch }) {
      commit('setExportInProgress', true);

      if (!state.latestWholesaleExport || !state.latestWholesaleExport._id) {
        commit('setExportInProgress', false);
        return Promise.resolve();
      }

      const { _id: exportId } = state.latestWholesaleExport;
      const url = `/api/wholesale-exports/${exportId}/export-to-ftp`;
      const [err] = await to(axios.post(url));
      commit('setExportInProgress', false);

      if (err) {
        dispatch('addPushToFTPWholesaleNotification', { jobStatus: jobStatuses.failed });
        throw new Error(err.message);
      } else {
        dispatch('addPushToFTPWholesaleNotification', { jobStatus: jobStatuses.finished });
        dispatch('resetLatestWholesaleExport');
      }
    },
    async downloadLastExport({ state, commit }) {
      const url = `/api/wholesale-exports/${state.latestWholesaleExport._id}/download`;
      commit('setDownloadingFile', true);
      const [err, response] = await to(axios.get(url, { responseType: 'blob' }));
      commit('setDownloadingFile', false);
      if (err) {
        throw new Error(err.message);
      }
      const buffer = response.data;
      if (!buffer) return;
      const fileName = response.headers['content-disposition']
        .split('filename=')[1]
        .replace(/['"]+/g, '');
      const blob = new Blob([buffer], { type: 'application/zip' });
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = fileName;
      link.click();
      link.remove();
    },
  },
};

export default store;
