import settingsPage from './settings.vue';
import penaltiesPage from './penalties/penalties.vue';
import architecturePage from './architecture/architecture.vue';
import { settings as settingsPageIndex } from '@enums/page-index';

const routes = [
  {
    path: '/settings',
    name: 'settings',
    id: 'settings',
    component: settingsPage,
    linkText: 'linkText.settings',
    index: settingsPageIndex,
    children: [
      {
        path: 'penalties',
        name: 'penalties',
        id: 'penalties',
        component: penaltiesPage,
        linkText: 'linkText.penaltyRules',
        index: 0,
        meta: {
          title: 'tabText.penaltyRules',
        },
      },
      {
        path: 'architecture',
        name: 'architecture',
        id: 'architecture',
        component: architecturePage,
        linkText: 'linkText.architectureRules',
        index: 1,
        meta: {
          title: 'tabText.architectureRules',
        },
      },
    ],
  },
];

export default routes;
