<template>
  <div>
    <v-container v-if="workpackage" fluid pr-0 pl-0 pb-0 pt-6>
      <v-row no-gutters class="header text-row pl-4">
        <v-col class="pb-0 pt-2">
          {{ $t('workPackages.owner') }}: <strong>{{ workpackage.owner }}</strong>
        </v-col>
      </v-row>
      <!-- FEATURE_FLAG: the last updated text can be removed -->
      <feature-toggle :toggle="displayLastUpdated">
        <v-row v-if="workpackage.lastUpdated" no-gutters class="header text-row pl-4">
          <v-col class="pb-0 pt-2">
            <span>
              {{ $t('workPackages.lastUpdated') }}:
              <br />
              <strong>{{ getFormattedDateTime(workpackage.lastUpdated) }}</strong>
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="workpackage.competitorLastUpdated && isMasterWorkpackage"
          no-gutters
          class="header text-row pl-4"
        >
          <v-col class="pb-0 pt-2">
            <span>
              {{ $t('workPackages.competitorLastUpdated') }}:
              <br />
              <strong>{{ getFormattedDateTime(workpackage.competitorLastUpdated) }}</strong>
            </span>
          </v-col>
        </v-row>
        <v-row
          v-if="workpackage.cumulativeProductCount && isMasterWorkpackage"
          no-gutters
          class="header text-row pl-4"
        >
          <v-col class="pb-0 pt-2">
            <span>
              {{ $t('workPackages.documents') }}
              <br />
              <strong>{{
                $t('workPackages.documentsCount', {
                  previousProductCount: formatNumber({
                    number: workpackage.previousProductCount,
                    format: numberFormats.integer,
                  }),
                  cumulativeProductCount: formatNumber({
                    number: workpackage.cumulativeProductCount,
                    format: numberFormats.integer,
                  }),
                })
              }}</strong>
            </span>
          </v-col>
        </v-row>
      </feature-toggle>
      <v-row no-gutters class="text-row pl-4">
        <v-col v-if="workpackage.releaseDate" class="pb-0 pt-2">
          {{ $t('workPackages.releaseDate') }}: <strong>{{ workpackage.releaseDate }}</strong>
        </v-col>
      </v-row>
    </v-container>
    <v-container v-else fluid pr-0 pl-0 pb-0 class="empty-reveal">
      <v-row no-gutters class="header text-row" />
    </v-container>
    <incorrect-mappings-modal
      :show-dialog="showIncorrectMappingsModal"
      @closeDialog="closeIncorrectMappingsModal"
    />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { get } from 'lodash';
import moment from 'moment';

import workpackageTypes from '@enums/workpackage-types';
import { displayLastUpdated } from '@enums/feature-flags';
import { yearMonthDayHoursMinutesSecondsFormat } from '@enums/date-formats';
import numberFormats from '@enums/number-formats';

export default {
  props: {
    workpackage: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      displayLastUpdated,
      showIncorrectMappingsModal: false,
      numberFormats,
    };
  },
  computed: {
    ...mapState('workpackages', ['deletingIds', 'exportingIds', 'postingIds', 'updatingIds']),
    deleting() {
      return this.deletingIds.includes(this.workpackage._id);
    },
    exporting() {
      return this.exportingIds.includes(this.workpackage._id);
    },
    posting() {
      return this.postingIds.includes(this.workpackage._id);
    },
    updating() {
      return this.updatingIds.includes(this.workpackage._id);
    },
    isMasterWorkpackage() {
      return get(this.workpackage, 'type') === workpackageTypes.master;
    },
  },
  methods: {
    closeIncorrectMappingsModal() {
      this.showIncorrectMappingsModal = false;
    },

    getFormattedDateTime(datetime) {
      const localtime = moment
        .utc(datetime)
        .local()
        .format(yearMonthDayHoursMinutesSecondsFormat);
      const [date, time] = localtime.split(' ');
      const [hours, minutes] = time.split(':');
      return `${this.formatLocalisedDate(date)} ${[hours, minutes].join(':')}`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.text-row {
  font-weight: normal;
  margin-left: -1.7rem;
}

.empty-reveal {
  padding-top: 0.2rem;
}
</style>
