import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    {
      ref: "marginSplitsContainer",
      staticClass: "margin-splits-wrapper px-0 py-0",
      attrs: { fluid: "" },
    },
    [
      _c("wholesale-sidebar"),
      _vm._v(" "),
      _vm.wholesaleMarginSplitsLoading
        ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
        : [
            _c(
              "div",
              { staticClass: "d-flex py-2" },
              [
                _c(
                  "span",
                  {
                    staticClass: "root",
                    class: { "highlighted-node": _vm.isDefaultSelected() },
                  },
                  [
                    _vm._v(
                      "\n        " +
                        _vm._s(_vm.$t("wholesale.defaultSplit")) +
                        "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _c(VSelect, {
                  staticClass: "label-part grid-dropdown",
                  attrs: {
                    items: _vm.availableGridDescriptions,
                    "menu-props": {
                      contentClass: "margin-splits-grids-menu",
                    },
                    disabled: _vm.isExportModalOpen,
                  },
                  on: {
                    change: function ($event) {
                      return _vm.setSelectedHierarchyGrid(
                        $event,
                        _vm.wholesaleHierarchyTree[0]
                      )
                    },
                  },
                  scopedSlots: _vm._u([
                    {
                      key: "append-item",
                      fn: function () {
                        return [
                          _c("dropdown-list-item", {
                            attrs: {
                              divider: "",
                              title: _vm.$t("wholesale.createNewGrid"),
                              "icon-class": "plus-box",
                            },
                            on: {
                              selectOption: function ($event) {
                                return _vm.openEditWholesaleGridDialog(
                                  _vm.wholesaleHierarchyTree[0],
                                  true
                                )
                              },
                            },
                          }),
                        ]
                      },
                      proxy: true,
                    },
                  ]),
                  model: {
                    value:
                      _vm.selectedAllocations[
                        _vm.wholesaleHierarchyTree[0].levelEntryKey
                      ],
                    callback: function ($$v) {
                      _vm.$set(
                        _vm.selectedAllocations,
                        _vm.wholesaleHierarchyTree[0].levelEntryKey,
                        $$v
                      )
                    },
                    expression:
                      "selectedAllocations[wholesaleHierarchyTree[0].levelEntryKey]",
                  },
                }),
                _vm._v(" "),
                _vm.canDisplayWholesaleExportAll
                  ? [_c("wholesale-grids-export-button")]
                  : _vm._e(),
                _vm._v(" "),
                _vm.canDisplayWholesaleImportGrids
                  ? [
                      _c("wholesale-grids-import-button", {
                        attrs: { disabled: _vm.isExportModalOpen },
                      }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            _c(
              "div",
              {
                staticClass: "hierarchy-tree-wrapper",
                class: { disabled: _vm.isExportModalOpen },
              },
              [
                _c(
                  "div",
                  { staticClass: "header d-flex" },
                  _vm._l(_vm.hierarchyHeaders, function (header, ix) {
                    return _c(
                      "div",
                      { key: ix, staticClass: "pa-3", class: header.class },
                      [
                        _vm._v(
                          "\n          " + _vm._s(header.text) + "\n        "
                        ),
                      ]
                    )
                  }),
                  0
                ),
                _vm._v(" "),
                _c(
                  "div",
                  { staticClass: "treeview-container" },
                  [
                    _vm.sortedWholesaleHierarchyTreeWithoutRoot
                      ? _c(VTreeview, {
                          attrs: {
                            "item-key": "levelEntryKey",
                            items: _vm.sortedWholesaleHierarchyTreeWithoutRoot,
                            open: _vm.openedNodes,
                          },
                          scopedSlots: _vm._u(
                            [
                              {
                                key: "label",
                                fn: function ({ item: node }) {
                                  return [
                                    _c(
                                      "div",
                                      {
                                        staticClass:
                                          "label-part hierarchy-name",
                                        class: {
                                          "highlighted-node":
                                            _vm.isNodeHighlighted(node),
                                        },
                                      },
                                      [
                                        _c(
                                          "tooltip",
                                          {
                                            attrs: {
                                              disabled: _vm.isTooltipDisabled(
                                                node.levelEntryDescription,
                                                _vm.truncationLength
                                              ),
                                              position: "top",
                                              value: node.levelEntryDescription,
                                            },
                                          },
                                          [
                                            _c(
                                              "span",
                                              {
                                                staticClass:
                                                  "hierarchy-name__label",
                                              },
                                              [
                                                _vm._v(
                                                  _vm._s(
                                                    _vm.hierarchyNodeDisplay(
                                                      node
                                                    )
                                                  )
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                      ],
                                      1
                                    ),
                                    _vm._v(" "),
                                    node.level !== 1
                                      ? _c(VSelect, {
                                          staticClass:
                                            "label-part grid-dropdown",
                                          attrs: {
                                            attach: "",
                                            items: _vm.gridDescriptionItems(
                                              node.levelEntryKey
                                            ),
                                            "menu-props": {
                                              closeOnClick: true,
                                              closeOnContentClick: true,
                                              contentClass:
                                                "margin-splits-grids-menu",
                                              top:
                                                _vm.hierarchyKeyToPositionMap[
                                                  node.levelEntryKey
                                                ] > 5,
                                            },
                                            disabled: _vm.isExportModalOpen,
                                          },
                                          on: {
                                            change: function ($event) {
                                              return _vm.setSelectedHierarchyGrid(
                                                $event,
                                                node
                                              )
                                            },
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "selection",
                                                fn: function ({ item }) {
                                                  return [
                                                    _c(
                                                      "div",
                                                      {
                                                        staticClass:
                                                          "v-select__selection v-select__selection--comma",
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                  " +
                                                            _vm._s(
                                                              item.inherited
                                                                ? _vm.$t(
                                                                    "wholesale.inherited"
                                                                  )
                                                                : item.value
                                                            ) +
                                                            "\n                "
                                                        ),
                                                      ]
                                                    ),
                                                  ]
                                                },
                                              },
                                              {
                                                key: "append-item",
                                                fn: function () {
                                                  return [
                                                    _c("dropdown-list-item", {
                                                      attrs: {
                                                        divider: "",
                                                        title: _vm.$t(
                                                          "wholesale.removeGridAssignment"
                                                        ),
                                                        "icon-class":
                                                          "close-circle",
                                                      },
                                                      on: {
                                                        selectOption: function (
                                                          $event
                                                        ) {
                                                          return _vm.removeGridAssignment(
                                                            node
                                                          )
                                                        },
                                                      },
                                                    }),
                                                    _vm._v(" "),
                                                    _c("dropdown-list-item", {
                                                      attrs: {
                                                        title: _vm.$t(
                                                          "wholesale.createNewGrid"
                                                        ),
                                                        "icon-class":
                                                          "plus-box",
                                                      },
                                                      on: {
                                                        selectOption: function (
                                                          $event
                                                        ) {
                                                          return _vm.openEditWholesaleGridDialog(
                                                            node,
                                                            true
                                                          )
                                                        },
                                                      },
                                                    }),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                          model: {
                                            value:
                                              _vm.selectedAllocations[
                                                node.levelEntryKey
                                              ],
                                            callback: function ($$v) {
                                              _vm.$set(
                                                _vm.selectedAllocations,
                                                node.levelEntryKey,
                                                $$v
                                              )
                                            },
                                            expression:
                                              "selectedAllocations[node.levelEntryKey]",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    node.level !== 1
                                      ? _c("i", {
                                          staticClass:
                                            "label-part mdi open-details-icon",
                                          class: {
                                            "mdi-eye-off-outline":
                                              _vm.isViewDisabled(node),
                                            "mdi-eye-outline":
                                              !_vm.isViewDisabled(node),
                                            clickable:
                                              !_vm.isViewDisabled(node),
                                          },
                                          attrs: { "aria-hidden": "true" },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openEditWholesaleGridDialog(
                                                node
                                              )
                                            },
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    node.children
                                      ? _c(
                                          "div",
                                          {
                                            staticClass:
                                              "label-part icon-wrapper d-flex align-center",
                                          },
                                          [
                                            _c("i", {
                                              staticClass:
                                                "mdi expand-icon clickable",
                                              class: _vm.isNodeOpen(
                                                node.levelEntryKey
                                              )
                                                ? "mdi-chevron-left"
                                                : "mdi-chevron-right",
                                              attrs: { "aria-hidden": "true" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.toggleNodes(
                                                    node.levelEntryKey
                                                  )
                                                },
                                              },
                                            }),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c("div", { staticClass: "node-line" }),
                                    _vm._v(" "),
                                    node.children
                                      ? _c("div", {
                                          staticClass: "node-group-line",
                                        })
                                      : _vm._e(),
                                  ]
                                },
                              },
                            ],
                            null,
                            false,
                            1265136836
                          ),
                        })
                      : _vm._e(),
                  ],
                  1
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "d-flex justify-end align-center py-0 px-0" },
              [
                _c(
                  VBtn,
                  {
                    staticClass: "save ml-2",
                    attrs: {
                      color: "success",
                      small: "",
                      depressed: "",
                      disabled: _vm.disableSave,
                      loading: _vm.savingGridAllocations,
                    },
                    on: { click: _vm.save },
                  },
                  [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("actions.save")) + "\n      "
                    ),
                  ]
                ),
                _vm._v(" "),
                _vm.allowSAPExport
                  ? [
                      _c("span", { staticClass: "mr-3 ml-3 btn-divider" }),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "save mr-2",
                          attrs: {
                            color: "success",
                            small: "",
                            depressed: "",
                            loading: _vm.savingGridAllocations,
                            disabled: _vm.disableSaveAndExportDialog,
                          },
                          on: { click: _vm.saveAndOpenExportDialog },
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("wholesale.export.button")) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("div", { staticClass: "buffer-div px-2 py-3" }),
                    ]
                  : _vm._e(),
              ],
              2
            ),
            _vm._v(" "),
            !_vm.hasGridAllocatedToRoot
              ? _c(
                  "div",
                  { staticClass: "d-flex justify-end save-warning pt-1" },
                  [
                    _vm._v(
                      "\n      " +
                        _vm._s(_vm.$t("wholesale.saveWarning")) +
                        "\n    "
                    ),
                  ]
                )
              : _vm._e(),
          ],
      _vm._v(" "),
      _vm.selectedGrid
        ? _c("wholesale-grid-dialog", {
            attrs: {
              "grid-details": _vm.selectedGrid,
              "is-open": _vm.isEditWholesaleGridDialogOpened,
            },
            on: { closeDialog: _vm.closeWholesaleGridDialog },
          })
        : _vm._e(),
      _vm._v(" "),
      _vm.isExportModalOpen && !_vm.wholesaleMarginSplitsLoading
        ? _c("wholesale-export-dialog", {
            attrs: {
              "attach-to": _vm.$refs.marginSplitsContainer,
              "is-open": _vm.isExportModalOpen,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }