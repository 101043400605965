<template>
  <div :custom-title-msg="tooltipMessage" custom-title-position="top-left">
    <span v-if="isNumberOfReleasesIcon" class="fa-stack">
      <span class="fa fa-square-o fa-stack-2x success--text square-icon" />
      <span class="fa-stack-1x success--text square-text">
        <strong>
          {{ formattedNumber }}
        </strong>
      </span>
    </span>
    <div v-else-if="isNumberOfPriceChanges" class="pill-shape">
      <strong class="pill-text">{{ formattedNumber }}</strong>
    </div>
    <span v-else class="fa-stack">
      <span class="fa fa-circle fa-stack-2x circle-icon" :style="{ color: iconColour }" />
      <strong class="fa-stack-1x icon-text">
        {{ formattedNumber }}
      </strong>
    </span>
  </div>
</template>

<script>
import colours from '../../../ow-colors';
import numberFormats from '@enums/number-formats';
import { mapState } from 'vuex';

export default {
  props: {
    number: {
      type: Number,
      default: () => 0,
    },
    tooltipMessage: {
      type: String,
      default: () => null,
    },
    rejected: {
      type: Boolean,
      default: () => false,
    },
    userRole: {
      type: String,
    },
  },
  data() {
    return {
      nullIconColour: colours.paramsFormBackgroundColor,
      success: colours.successGreen,
      catManGreen: colours.catManGreen,
    };
  },
  computed: {
    ...mapState('clientConfig', ['userRoles']),

    iconColour() {
      if (this.number === 0) return this.nullIconColour;
      if (this.rejected) return 'red';
      if (this.userRole === this.userRoles.categoryManager) return this.catManGreen;
      return this.success;
    },
    isNumberOfReleasesIcon() {
      return this.tooltipMessage === this.$t('workPackages.numberOfCategoriesReleased');
    },

    isNumberOfPriceChanges() {
      return (
        this.tooltipMessage === this.$t('workPackages.numberOfPriceChangesFromApprovedCategories')
      );
    },

    formattedNumber() {
      return this.formatNumber({ number: this.number, format: numberFormats.integer });
    },
  },
};
</script>

<style lang="scss" scoped>
.icon-text {
  color: white;
  padding-top: 0.15rem;
}

.square-icon {
  font-size: 2.5rem;
  padding-top: 0.1rem;
}

.square-text {
  padding-top: 0.15rem;
}

.circle-icon {
  font-size: 2.5rem;
  padding-top: 0.3rem;
}

.fa-stack {
  cursor: pointer;
}

.pill-shape {
  height: 1.8rem;
  width: 3rem;
  background-color: white;
  border-style: solid;
  border-width: 1px;
  border-color: black;
  color: black;
  text-align: center;
  line-height: 1.2rem;
  cursor: pointer;
  border-radius: 1rem;

  .pill-text {
    font-size: 1.2rem;
  }
}
</style>
