var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("On promotion")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "product-on-promotion-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "product-on-promotion-transform",
                transform: "translate(-138.000000, -639.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "product-on-promotion",
                    transform: "translate(138.000000, 639.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      "fill-rule": "nonzero",
                      x: "2.1609801e-06",
                      y: "1.39161e-06",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M11.1716022,0 C11.7020022,0 12.2107022,0.210713392 12.5858022,0.585786392 L12.5858022,0.585786392 L22.5858022,10.5858014 C23.3668022,11.3668014 23.3668022,12.6332014 22.5858022,13.4142014 L22.5858022,13.4142014 L13.4142022,22.5858014 C12.6332022,23.3668014 11.3668022,23.3668014 10.5858022,22.5858014 L10.5858022,22.5858014 L0.585786161,12.5858014 C0.210713161,12.2107014 0,11.7020014 0,11.1716014 L0,11.1716014 L0,2 C0,0.895431392 0.895431161,0 2,0 L2,0 Z M11.1716022,2.00000139 L2.00000216,2.00000139 L2.00000216,11.1716014 L12.0000022,21.1716014 L21.1716022,12.0000014 L11.1716022,2.00000139 Z M6.00000216,4.00000139 C7.10457166,4.00000139 8.00000216,4.89543189 8.00000216,6.00000139 C8.00000216,7.10457089 7.10457166,8.00000139 6.00000216,8.00000139 C4.89543266,8.00000139 4.00000216,7.10457089 4.00000216,6.00000139 C4.00000216,4.89543189 4.89543266,4.00000139 6.00000216,4.00000139 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }