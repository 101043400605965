<template>
  <div>
    <v-snackbar v-model="snackbar" class="snackbar-text" top :timeout="ruleSetPopupTimeout">
      {{ $t('settings.penaltyRules.settingAllocationSuccessText') }}
      <v-btn dark text depressed @click="snackbar = false">
        {{ $t('actions.close') }}
      </v-btn>
    </v-snackbar>

    <div
      v-for="(ruleset, rulesetType) in rulesets"
      :key="`ruleset-${rulesetType}`"
      class="ruleset-container"
    >
      <v-row v-if="ruleset.meta.showActiveHeader">
        <v-col cols="12" class="heading-bar mb-4">
          <div class="heading-bar-heading d-inline-flex pr-3">
            {{ $t('settings.penaltyRules.activeRulesets') }}
          </div>
          <feature-toggle :toggle="useZones" class="d-inline-flex pr-3 store-group-filter">
            <v-autocomplete
              v-model="selectedToolStoreGroups"
              multiple
              dense
              solo
              flat
              small-chips
              hide-details
              :items="selectedWorkpackageToolStoreGroups"
              item-value="key"
              item-text="description"
              :disabled="!selectedWorkpackageToolStoreGroups"
              :label="$t('settings.penaltyRules.selectStoreGroup')"
              color="indigo darken-2"
              :search-input.sync="searchInput"
              :loading="loadingSelectedWorkpackageToolStoreGroups"
              @change="reset"
            >
              <template v-slot:selection="{ item, selected }">
                <v-chip
                  :input-value="selected"
                  close
                  class="chip--select"
                  @click:close="deselect(item)"
                >
                  {{ item.description }}
                </v-chip>
              </template>
              <template v-slot:no-data>
                <div v-if="!loadingSelectedWorkpackageToolStoreGroups">
                  <v-alert :value="true" color="error" icon="warning" outlined>
                    {{ $t('attributes.filters.noData') }}
                  </v-alert>
                </div>
                <div v-else>
                  <v-alert :value="true" color="info" icon="info" outlined>
                    {{ $t('general.loadingMessage') }}
                  </v-alert>
                </div>
              </template>
              <template v-slot:item="props">
                <span class="item-text">
                  {{ props.item.description }}
                </span>
              </template>
            </v-autocomplete>
          </feature-toggle>
          <div class="d-inline-flex">{{ $t('settings.penaltyRules.addRuleText') }}</div>
        </v-col>
      </v-row>
      <v-row v-if="ruleset.meta.showInactiveHeader">
        <v-col cols="12" class="heading-bar">
          <div class="heading-bar-heading">{{ $t('settings.penaltyRules.inactiveRulesets') }}</div>
        </v-col>
      </v-row>

      <v-row v-if="!ruleset.meta.showInactiveHeader" :key="`header-${rulesetType}`">
        <v-col cols="12" class="rule-heading">
          <div>
            {{ $t(ruleset.displayName) }}
            <plus-button
              v-if="scenarioKey && canEditRules && ruleset.meta.isEditable"
              class="d-inline-flex pl-2"
              @clickFunction="startRuleCreation(rulesetType)"
            />
          </div>
        </v-col>
      </v-row>
      <draggable
        :key="rulesetType"
        group="draggable-lists"
        :list="ruleset.rules"
        :class="['draggable-element', { opaque: isDraggableDisabled(rulesetType) }]"
        handle=".rule-draggable-icon"
        :disabled="!canEditRules || !ruleset.meta.isEditable || isDraggableDisabled(rulesetType)"
        @start="setDragAndDropServiceData($event, ruleset)"
        @change="handleDragChange"
        @choose="turnOffExpanded"
        @end="resetDragAndDropServiceData"
      >
        <v-layout
          v-for="(rule, ruleIndex) in ruleset.rules"
          :key="rule._id"
          :data-ruleType="rule.ruleType"
          class="ruleset"
        >
          <!-- Index -->
          <v-flex v-if="ruleset.meta.showIndex" class="rule-index">
            <span>{{ computePriority(rule.ruleType, ruleIndex) }}</span>
          </v-flex>
          <v-flex v-else class="no-index" />

          <!-- Main rule content -->
          <v-flex :class="ruleset.classes" class="rule-container">
            <rule-content
              :ruleset-type="rulesetType"
              :rule-index="ruleIndex"
              :rule="rule"
              :product-counts="productCounts[rule._id] || []"
              :expanded-key="expandedKey"
              :disabled="!canEditRules || !ruleset.meta.isEditable"
              @updateRule="updateSingleRule"
              @setExpandedKey="setExpandedKey"
            />
          </v-flex>
          <v-flex v-show="ruleset.meta.isEditable" class="rule-draggable-icon">
            <v-icon class="drag-icon text-xs-right" :disabled="!canEditRules"> open_with </v-icon>
          </v-flex>
        </v-layout>

        <v-layout v-if="showDragRuleInfo(ruleset)" class="ruleset">
          <v-flex class="rule-drag-here">
            {{ $t('settings.penaltyRules.dragRuleInfo') }}
          </v-flex>
          <v-flex />
        </v-layout>
        <v-layout v-if="shouldDisplayNewRule(rulesetType)" class="ruleset">
          <!-- Index -->
          <v-flex class="rule-index">
            <span>{{ newRuleIndex(rulesetType) }}</span>
          </v-flex>

          <!-- Main rule content -->
          <v-flex :class="ruleset.classes" class="rule-container">
            <rule-content
              v-if="isNewRuleCreation"
              :mode="creationMode"
              :ruleset-type="rulesetType"
              :rule-index="newRuleIndex(rulesetType)"
              :rule="mutableDefaultRule"
              :expanded-key="expandedKey"
              class="expanded-top"
              @saveNewRule="saveNewRuleHandler"
              @cancelNewRule="cancelNewRuleCreation()"
              @setExpandedKey="setExpandedKey"
            />
          </v-flex>
        </v-layout>
      </draggable>

      <v-layout
        v-if="ruleset.meta.showControlButtons"
        :key="`control${rulesetType}`"
        justify-end
        class="control-buttons"
      >
        <v-btn
          id="apply-button"
          small
          :disabled="!canEditRules"
          depressed
          @click="updateStatusAndPriorityAndAllocateSettings"
        >
          {{ $t('actions.Apply') }}
        </v-btn>
      </v-layout>
    </div>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import {
  cloneDeep,
  get,
  includes,
  filter,
  isEmpty,
  some,
  intersection,
  forEach,
  keys,
} from 'lodash';
import { create } from '@enums/dialog-modes';
import { ascending } from '@enums/sort-direction';
import { active, inactive } from '@enums/rule-status';
import { storegroupOption } from '@enums/filter-options';
import { useZones, enableGlobalRuleEdit } from '@enums/feature-flags';
import {
  priceGroupOverrideRule,
  globalRule,
  defaultRule,
  pricingGroupRule,
} from '@enums/rule-type';
import {
  priceGroupOverridePriorityStart,
  globalRulePriorityStart,
  defaultRulePriorityStart,
  pricingGroupPriorityStart,
} from '@enums/rule-type-priority';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [featureFlagsMixin],
  data() {
    return {
      creationMode: create,
      rulesets: {},
      defaultRule: {},
      mutableDefaultRule: {},
      snackbar: false,
      expandedKey: '',
      isNewRuleCreation: false,
      selectedRuleType: null,
      newRuleType: null,
      selectedToolStoreGroups: [],
      searchInput: null,
      useZones,
      ruleSetPopupTimeout: clientConfig.ruleSetPopupTimeout,
    };
  },

  computed: {
    ...mapState('workpackages', [
      'loadingSelectedWorkpackageToolStoreGroups',
      'selectedWorkpackageToolStoreGroups',
    ]),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),
    ...mapGetters('attributes', ['toolStoreGroupGlobalAttributeKey']),
    ...mapState('rules', ['rules', 'productCounts']),
    ...mapGetters('rules', [
      'priceGroupOverrideRules',
      'globalRules',
      'pricingGroupRules',
      'defaultRules',
      'inactiveRules',
    ]),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapGetters('filters', ['getSelectedFilter']),
    ...mapState('clientConfig', ['storeGroupOrderConfig']),

    scenarioKey() {
      return this.getSelectedFilter('scenario');
    },

    canEditRules() {
      return this.isPricingSpecialist;
    },

    hasToolStoreGroupSelected() {
      return !isEmpty(this.selectedToolStoreGroups);
    },

    canEditGlobalDefaultRules() {
      return this.isSelectedWorkpackageMaster || this.isFeatureFlagEnabled(enableGlobalRuleEdit);
    },
  },

  watch: {
    // Update rulesets whenever rules changes in vuex
    rules() {
      this.reset();
    },
  },

  async created() {
    await this.fetchHierarchy({
      params: {
        sortBy: 'levelEntryDescription',
        sortDirection: ascending,
      },
    });
    this.setRulesets();
    await this.fetchRules();
    this.reset();
    this.defaultRule = await this.getDefaultRule();
    this.mutableDefaultRule = cloneDeep(this.defaultRule);
  },

  methods: {
    ...mapActions('rules', [
      'fetchRules',
      'updateRules',
      'updateRule',
      'createRule',
      'getDefaultRule',
      'runSettingsAllocation',
      'deleteRule',
    ]),
    ...mapActions('hierarchy', ['fetchHierarchy']),

    turnOffExpanded(ruleType) {
      this.expandedKey = '';
      this.selectedRuleType = ruleType;
    },

    setDragAndDropServiceData(event) {
      this.selectedRuleType = event.item.dataset.ruletype;
    },

    resetDragAndDropServiceData() {
      this.selectedRuleType = null;
    },

    showDragRuleInfo(ruleset) {
      const noRules = ruleset.rules.length === 0;
      const isInactive = get(ruleset, 'meta.showInactiveHeader');
      return this.hasToolStoreGroupSelected ? noRules && !isInactive : noRules;
    },

    filterRulesScopedByToolStoreGroup(rules) {
      return filter(rules, rule => {
        return some(rule.scope, att => {
          return (
            att.attributeFilterType === storegroupOption &&
            !isEmpty(intersection(att.attributeValue, this.selectedToolStoreGroups))
          );
        });
      });
    },

    reset() {
      forEach(keys(this.rulesets), ruleset => {
        const clonedRuleset = cloneDeep(this[`${ruleset}s`]);
        this.rulesets[ruleset].rules = this.hasToolStoreGroupSelected
          ? this.filterRulesScopedByToolStoreGroup(clonedRuleset)
          : clonedRuleset;
      });
    },

    setRulesets() {
      this.rulesets = {
        priceGroupOverrideRule: {
          displayName: this.$t('settings.penaltyRules.priceGroupOverrideRules'),
          meta: {
            showIndex: true,
            showActiveHeader: true,
            isEditable: true,
          },
          classes: {},
          rules: [],
        },
        globalRule: {
          displayName: this.$t('settings.penaltyRules.globalRules'),
          meta: {
            showIndex: true,
            isEditable: this.canEditGlobalDefaultRules,
          },
          classes: {
            opaque: !this.canEditGlobalDefaultRules,
          },
          rules: [],
        },
        pricingGroupRule: {
          displayName: this.$t('settings.penaltyRules.pricingGroupRules'),
          meta: {
            showIndex: true,
            isEditable: true,
          },
          classes: {},
          rules: [],
        },
        defaultRule: {
          displayName: this.$t('settings.penaltyRules.defaultRules'),
          meta: {
            showIndex: true,
            showControlButtons: true,
            isEditable: this.canEditGlobalDefaultRules,
          },
          classes: {
            opaque: !this.canEditGlobalDefaultRules,
          },
          rules: [],
        },
        inactiveRule: {
          displayName: this.$t('settings.penaltyRules.inactiveRules'),
          meta: {
            showInactiveHeader: true,
            isEditable: true,
          },
          classes: {
            opaque: true,
          },
          rules: [],
        },
      };
    },

    newRuleIndex(rulesetType) {
      return this.computePriority(
        rulesetType,
        get(this.rulesets, [rulesetType, 'rules', 'length'])
      );
    },

    formatDataForSave() {
      // why the magic numbers? Padding is added around the rules to avoid overlap when saving in different scenarios where we could have more rules.
      return [
        ...this.rulesets.priceGroupOverrideRule.rules.map((rule, ix) => ({
          _id: rule._id,
          priority: ix + priceGroupOverridePriorityStart,
          status: active,
          ruleType: priceGroupOverrideRule,
        })),
        ...this.rulesets.globalRule.rules.map((rule, ix) => ({
          _id: rule._id,
          priority: ix + globalRulePriorityStart,
          status: active,
          ruleType: globalRule,
          scenarioKey: null,
        })),
        ...this.rulesets.pricingGroupRule.rules.map((rule, ix) => ({
          _id: rule._id,
          priority: ix + pricingGroupPriorityStart,
          status: active,
          ruleType: pricingGroupRule,
        })),
        ...this.rulesets.defaultRule.rules.map((rule, ix) => ({
          _id: rule._id,
          priority: ix + defaultRulePriorityStart,
          status: active,
          ruleType: defaultRule,
          scenarioKey: null,
        })),
        ...this.rulesets.inactiveRule.rules.map(rule => ({
          _id: rule._id,
          priority: null,
          status: inactive,
        })),
      ].reduce((obj, rule) => {
        const { _id, ...updates } = rule;
        return {
          ...obj,
          [_id]: updates,
        };
      }, {});
    },

    async updateStatusAndPriorityAndAllocateSettings() {
      this.updateRules({ updates: this.formatDataForSave() });
      await this.runSettingsAllocation({ scenarioKey: this.scenarioKey });
      this.snackbar = true;
    },

    async handleDragChange(event) {
      if (!event.added) return;
      await this.updateRules({ updates: this.formatDataForSave() });
    },

    async updateSingleRule(event) {
      await this.updateRule(event);
    },

    shouldDisplayNewRule(rulesetType) {
      return this.isNewRuleCreation && rulesetType === this.newRuleType;
    },

    startRuleCreation(rulesetType) {
      this.turnOffExpanded();
      this.isNewRuleCreation = true;
      this.expandedKey = `${rulesetType}-${this.newRuleIndex(rulesetType)}`;
      this.mutableDefaultRule = cloneDeep(this.defaultRule);
      this.newRuleType = rulesetType;
    },

    async saveNewRuleHandler(rule, rulesetType) {
      // do not set a scenarioKey for global and default rules
      rule.scenarioKey =
        rulesetType === globalRule || rulesetType === defaultRule ? null : this.scenarioKey;
      rule.priority = this.newRuleIndex(rulesetType);
      rule.ruleType = rulesetType;
      this.isNewRuleCreation = false;
      await this.createRule(rule);
    },

    cancelNewRuleCreation() {
      this.isNewRuleCreation = false;
    },

    setExpandedKey(expandedKey) {
      this.expandedKey = expandedKey;
    },

    computePriority(ruleType, index) {
      // depending on the ruleType add the length of the previous groups
      let priority = index + 1;

      if (ruleType === globalRule) {
        priority += this.rulesets.priceGroupOverrideRule.rules.length;
      } else if (ruleType === pricingGroupRule) {
        priority +=
          this.rulesets.priceGroupOverrideRule.rules.length + this.rulesets.globalRule.rules.length;
      } else if (ruleType === defaultRule) {
        priority +=
          this.rulesets.priceGroupOverrideRule.rules.length +
          this.rulesets.globalRule.rules.length +
          this.rulesets.pricingGroupRule.rules.length;
      }
      return priority;
    },

    isDraggableDisabled(ruleType) {
      // lock dragging into draggable area based upon the selected rule type.

      // for selected rule type, disable dragging for these rule types
      const ristrictRules = {
        [priceGroupOverrideRule]: [defaultRule, globalRule],
        [globalRule]: [priceGroupOverrideRule, pricingGroupRule],
        [pricingGroupRule]: [globalRule, defaultRule],
        [defaultRule]: [pricingGroupRule, priceGroupOverrideRule],
      };

      // if the rule type is in the restricted array return true to disable
      return includes(ristrictRules[this.selectedRuleType], ruleType);
    },

    deselect(item) {
      this.selectedToolStoreGroups = filter(this.selectedToolStoreGroups, tsg => tsg !== item.key);
      this.reset();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.rule-heading {
  color: $text-color;
  font-size: 1.3rem;
  font-weight: 600;
  background-color: $heading-background;
  padding: 0.7rem;
  margin: 0 0 1rem 0;
  border-top: solid 0.1rem $rulesetBorder;
}

.rule-index {
  min-width: 3rem;
  max-width: fit-content;
  min-height: 3.5rem;
  max-height: 3.5rem;
  margin: 0 1.5rem 0.5rem 0.5rem;
  font-size: 1.25rem;
  background-color: $rulesetBackgroundGrey;
  padding: 1rem 1rem 0 1rem;
}

.rule-draggable-icon {
  max-width: fit-content;
  margin: 0 0.5rem 0.5rem 0.5rem;
  font-size: 1.25rem;
  padding: 0.3rem 0 0 0.3rem;
}

.rule-drag-here {
  font-size: 1.25rem;
  border: dashed 0.1rem $rulesetBackgroundGrey;
  padding: 1rem;
  width: 100%;
}

.no-index {
  min-width: 5rem;
  max-width: 5rem;
}

.ruleset {
  margin: 0.5rem 0.5rem 0 0.5rem;
}

.opaque {
  opacity: 0.5;
}

#apply-button {
  color: white;
  margin-right: 7rem;
  margin-top: 4px;
  background-color: $penaltiesLightGreen;
}

.control-buttons {
  border-top: solid 1px $rulesetBorder;
  padding: 1rem;
  margin-top: 1rem;
}

.add-rule-text {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.ruleset-container {
  margin-bottom: 1rem;
}
.ruleset-container:last-of-type {
  margin-bottom: 1rem;
}

.add-rule-icon {
  color: $rulesetLightBlue;
}

.add-rule-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
  min-width: 0;
}

.v-btn {
  font-size: 1.25rem;
}

.row {
  // override edfault margin left and right of -12px, so content stops overflowing div.
  // v-container solved this, but causes issues with fixed header.
  margin: 0;
}

// by default min-width: auto, that causes issues with width if flex is used
.rule-container {
  min-width: 0;
}

.store-group-filter {
  width: 30%;
}

.snackbar-text {
  font-size: 1.4rem;
}
</style>
