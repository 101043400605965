<template>
  <div v-show="hasSelectedWorkpackage" class="input-screen-page-wrapper">
    <v-row no-gutters>
      <v-col>
        <!-- FEATURE_FLAG: display competitor add button -->
        <feature-toggle :toggle="displayAddCompetitorButton">
          <v-btn
            color="primary"
            small
            depressed
            @click="openCompetitorCreateUpdateDialog(dialogModes.create)"
          >
            {{ $t('actions.add') }}
          </v-btn>
        </feature-toggle>
        <!-- FEATURE_FLAG: display competitor match upload -->
        <feature-toggle :toggle="displayCompetitorUpload">
          <competitor-upload
            class="pb-2"
            :params="getBaseParams()"
            :enabled-value-translations="enabledValueTranslations"
            :is-master-workpackage="isSelectedWorkpackageMaster"
          />
        </feature-toggle>
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="search-field py-0"
          :value="competitorMatchesSearch"
          append-icon="search"
          :label="$t('actions.search')"
          :loading-text="$t('general.loadingMessage')"
          single-line
          @input="debounceSearchUpdate"
        />
      </v-col>
    </v-row>

    <!-- FEATURE_FLAG: temporary until attribute filter and hierarchy columns are released -->
    <feature-toggle :toggle="displayHierarchyAndFilter">
      <attribute-filter-panel
        title-localisation="attributes.filters.filterByAttributes"
        filter-count-localisation="attributes.filters.numApplied"
        enable-hierarchy-filters
        :filter-rules="retailAttributesFilter"
        @attributeFilterChange="setFilterRules"
      />
    </feature-toggle>

    <v-data-table
      class="pricing-table elevation-1 competitor-matches-table striped-table"
      :items="competitorMatches"
      :loading="loading"
      :headers="headers"
      :height="dataTableHeight"
      :loading-text="$t('general.loadingMessage')"
      disable-pagination
      disable-sort
      dense
      fixed-header
      hide-default-header
      hide-default-footer
      item-key="productKey"
      @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table__wrapper')"
    >
      <!-- Headers -->
      <template v-slot:header>
        <thead class="v-data-table-header">
          <tr v-if="isUseZones" class="competitor-row">
            <th :colspan="stickyColumnsNumberBeforeCompetitorTabs" class="one-fixed border-right" />
            <th
              v-if="!!hierarchyHeaders.length"
              :colspan="hierarchyHeaders.length"
              class="border-right"
            />
            <th :colspan="getCompetitors.length * 2" class="box-shadow">
              <div class="competitor-wrapper">
                <v-btn
                  v-for="type in toggleTypes"
                  :key="type"
                  :class="['competitor-btn', isActiveCompetitorToggle(type) ? 'active' : '']"
                  :disabled="isCompetitorToggleDisabled(type)"
                  @click="setActiveCompetitorToggle(type)"
                  >{{ $t(`competitorMatches.tabs.${type}`) }}</v-btn
                >
              </div>
            </th>
          </tr>
          <tr>
            <th colspan="1" :class="fixedColumnsClass" />
            <!-- FEATURE_FLAG: display a tool store group cell -->
            <th v-if="toolStoreGroupColumn" colspan="1" :class="fixedColumnsClass" />
            <th colspan="1" :class="fixedColumnsClass" />
            <th colspan="1" class="border-right" :class="fixedColumnsClass" />
            <th colspan="3" :class="[showHierarchy ? '' : 'hidden-header', 'border-right']">
              {{ $t('attributes.editor.headers.hierarchy') }}
            </th>
            <th
              v-for="header in getCompetitors"
              :key="header.competitorKey"
              colspan="2"
              :class="[
                'border-right',
                !isActiveCompetitorToggle(header.competitorType) ? 'hidden-header' : '',
              ]"
            >
              <span>
                {{ header.competitorDisplayDescription }}
                <!-- FEATURE_FLAG: display competitor delete button -->
                <feature-toggle :toggle="displayCompetitorActionsMenu">
                  <v-menu bottom offset-y>
                    <template v-slot:activator="{ on }">
                      <v-icon
                        v-if="header.competitorType === competitorSources.manual"
                        class="actions-btn"
                        v-on="on"
                      >
                        more_horiz
                      </v-icon>
                    </template>
                    <v-list>
                      <v-list-item
                        @click="openCompetitorCreateUpdateDialog(dialogModes.edit, header)"
                      >
                        <v-list-item-title>
                          {{ $t('actions.edit') }}
                        </v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="openDeleteDialog(header)">
                        <v-list-item-title>
                          {{ $t('actions.delete') }}
                        </v-list-item-title>
                      </v-list-item>
                    </v-list>
                  </v-menu>
                </feature-toggle>
              </span>
            </th>
          </tr>
          <tr>
            <th
              v-for="header in getHeaders"
              :key="header.value"
              :class="[
                ...getHeaderClasses(header, pagination),
                header.class,
                !header.competitor ||
                (header.competitor && isActiveCompetitorToggle(header.competitorType))
                  ? ''
                  : 'hidden-header',
              ]"
              @click="changeSort(header)"
            >
              {{ $t(header.text) }}
              <div>
                <v-icon v-if="header.sortable" size="1.4rem" class="v-data-table-header__icon">
                  arrow_upward
                </v-icon>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="props">
        <tr :key="props.index">
          <!-- Product details -->

          <td class="text-xs table-cell" :class="fixedColumnsClass">
            {{ props.item.productKeyDisplay }}
          </td>
          <!-- FEATURE_FLAG: display a tool store group cell -->
          <td v-if="toolStoreGroupColumn" class="text-xs table-cell" :class="fixedColumnsClass">
            <tooltipped-truncated-field
              :text="props.item.toolStoreGroupDescription"
              :truncation-length="truncationLengthMedium"
              class="tooltipped-truncated-field"
            />
          </td>
          <td class="text-xs table-cell" :class="fixedColumnsClass">
            {{ props.item.productName }}
          </td>
          <td class="text-xs table-cell border-right" :class="fixedColumnsClass">
            {{
              formatMetricContainedUnitOfMeasurement(
                props.item.productSizeType,
                numberFormats.weight
              )
            }}
          </td>

          <!-- FEATURE_FLAG: Hierarchy columns -->
          <feature-toggle :toggle="displayHierarchyAndFilter">
            <td
              v-for="level in [categoryLevel, pricingGroupLevel, architectureGroupLevel]"
              :key="level"
              class="text-xs table-cell"
              :class="level === architectureGroupLevel ? 'border-right' : ''"
            >
              <tooltip
                :value="getHierarchyName(props.item, level)"
                :disabled="
                  isTooltipDisabled(getHierarchyName(props.item, level), truncationLengthMedium)
                "
              >
                {{ getHierarchyName(props.item, level) | truncate(truncationLengthMedium) }}
              </tooltip>
            </td>
          </feature-toggle>

          <!-- Competitors -->
          <template v-for="(c, cIndex) in getCompetitors">
            <td
              :key="c.competitorDescription + '-price'"
              :class="[
                'text-xs',
                'table-cell',
                !isActiveCompetitorToggle(c.competitorType) ? 'hidden-cell' : '',
              ]"
              class="input-cell"
            >
              <pricing-edit-text-field
                :value="getCompetitorPrice(props.item, props.item.competitorMatches[cIndex])"
                :previous-value="getPreviousValue(props.item.competitorMatches[cIndex])"
                :disabled="!canEditCompetitorMatches"
                :data-type="dataTypes.number"
                :number-format="numberFormats.priceFormat"
                :error="
                  updateErrors[
                    getProductCompetitorKey(props.item, props.item.competitorMatches[cIndex])
                  ]
                "
                :cell-class="
                  getInputEditFieldClass(props.item, props.item.competitorMatches[cIndex])
                "
                previous-value-translation-key="competitorMatches.editor"
                :tooltip-position="
                  props.index > pagination.itemsPerPage / 2 ? 'top-left' : 'bottom-left'
                "
                :tooltip-value="{
                  [$t('tooltip.competitorDescription')]:
                    props.item.competitorMatches[cIndex].competitorProductDescription || '-',
                  [$t('tooltip.competitorSize')]: formatNumber({
                    number: props.item.competitorMatches[cIndex].contentValue,
                    format: numberFormats.priceFormat,
                    nullAsDash: true,
                  }),
                  [$t('tooltip.competitorUnitOfMeasure')]:
                    props.item.competitorMatches[cIndex].contentUnitOfMeasure || '-',
                }"
                @change="
                  recordChanges(
                    $event.target.value,
                    props.item,
                    props.item.competitorMatches[cIndex]
                  )
                "
              />
            </td>
            <td
              :key="c.competitorDescription + '-disabled'"
              :class="[
                'table-cell',
                'border-right',
                !isActiveCompetitorToggle(c.competitorType) ||
                hideStoreGroupCompetitors(c.competitorType)
                  ? 'hidden-cell'
                  : '',
              ]"
            >
              <input
                type="checkbox"
                :disabled="!canEditCompetitorMatches"
                :checked="getCompetitorDisabled(props.item, props.item.competitorMatches[cIndex])"
                @input="
                  recordCheckboxChange(
                    $event.target.checked,
                    props.item,
                    props.item.competitorMatches[cIndex]
                  )
                "
              />
            </td>
          </template>
        </tr>
      </template>

      <template slot="footer">
        <v-flex :class="{ 'input-screen-sticky-table-footer': showHierarchy }">
          <v-row justify="end">
            <v-col md="3" offset-md="9">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                @input="loadCompetitorMatchData"
              />
            </v-col>
          </v-row>
          <v-row justify="end" class="pr-6 pb-2" align="center">
            <!-- FEATURE_FLAG: display competitor match download -->
            <feature-toggle :toggle="displayCompetitorDownload" class="feature-toggle-btn">
              <v-btn
                color="primary"
                small
                :loading="downloadingItems"
                depressed
                class="mr-3"
                @click="downloadItems"
              >
                {{ $t('actions.download') }}
                <v-icon small>$export</v-icon>
              </v-btn>
              <span class="mr-3 btn-divider" />
            </feature-toggle>
            <v-btn
              :disabled="!canEditCompetitorMatches || !hasUnsavedUpdates || hasInvalidUpdates"
              color="success"
              :loading="loading"
              small
              class="save"
              depressed
              @click="persistUpdates"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-row>
        </v-flex>
      </template>
    </v-data-table>

    <competitor-create-update-dialog
      :key="competitorCreateDialogKey"
      :is-open="isCompetitorCreateUpdateDialogOpen"
      :mode="mode"
      :competitor="competitorToEdit"
      @closeDialog="closeCompetitorCreateUpdateDialog"
    />

    <!-- Delete dialog -->
    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      :disable-ok-button="checkingIfCompetitorCanBeDeleted || !competitorCanBeDeleted"
      @onOk="deleteCompetitorHandler(true)"
      @onCancel="deleteCompetitorHandler(false)"
    >
      <template v-slot:header>{{ $t('dialogs.deleteCompetitorDialog.header') }}</template>
      <template v-slot:body>
        <div v-if="!checkingIfCompetitorCanBeDeleted && competitorCanBeDeleted">
          {{ $t('dialogs.deleteCompetitorDialog.body') }}
          <strong>{{
            competitorToDelete ? competitorToDelete.competitorDisplayDescription : ''
          }}</strong>
        </div>
        <div v-else-if="checkingIfCompetitorCanBeDeleted && competitorUses.length === 0">
          {{ $t('competitorMatches.deleteMessages.checkingIfTheCompetitor') }}
          <strong>{{
            competitorToDelete ? competitorToDelete.competitorDisplayDescription : ''
          }}</strong>
          {{ $t('competitorMatches.deleteMessages.canBeDeleted') }}
          <v-progress-circular indeterminate color="primary" size="20" />
        </div>
        <div v-else>
          <div class="pb-1">
            <strong>{{
              competitorToDelete ? competitorToDelete.competitorDisplayDescription : ''
            }}</strong>
            {{ $t('competitorMatches.deleteMessages.competitorCannotBeDeleted') }}:
          </div>
          <div v-for="(competitorUse, index) in competitorUses" :key="index">
            <div class="pl-2">
              <v-icon class="pb-1" size="1.7rem" :color="alertsIconColor">warning</v-icon>
              {{
                $t(
                  `competitorMatches.deleteMessages.${competitorUse.translationKey}`,
                  competitorUse.translationParams
                )
              }}
            </div>
          </div>
        </div>
      </template>
    </alert-dialog>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import {
  ceil,
  debounce,
  flatMap,
  omit,
  isNil,
  isNaN,
  get,
  some,
  keys,
  map,
  isEmpty,
  has,
} from 'lodash';
import sortDirectionEnums from '@enums/sort-direction';
import {
  displayHierarchyAndFilter,
  displayCompetitorDownload,
  displayCompetitorUpload,
  displayAddCompetitorButton,
  displayCompetitorActionsMenu,
  useZones,
  displayStoreGroupCompetitors,
} from '@enums/feature-flags';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import dialogModes from '@enums/dialog-modes';
import isTooltipDisabled from '../../../utils/tooltip-util';
import DataTypes from '@enums/data-types';
import { competitorSources, competitorToggles } from '@enums/source-types';
import { inputTableCssClasses } from '@enums/tables';
import numberFormats from '@enums/number-formats';
import colours from '../../../ow-colors';
import inputUpdateKey from '../../../mixins/inputUpdateKeys';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import { preventPageNavigationOnHorizontalScroll } from '../../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [inputUpdateKey, featureFlagsMixin, tablesMixin, inputScreensTableMixin],
  data() {
    return {
      displayHierarchyAndFilter,
      displayCompetitorDownload,
      displayCompetitorUpload,
      displayAddCompetitorButton,
      displayCompetitorActionsMenu,
      competitorMatchesSearch: '',
      pagination: {
        // used by v-data-table for sorting and pagination
        descending: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'productKeyDisplay',
        sortDataType: DataTypes.str,
      },
      dataTypes: DataTypes,
      categoryLevel,
      pricingGroupLevel,
      architectureGroupLevel,
      truncationLengthMedium: 28,
      updates: {},
      updateErrors: {},
      updatedRowsDetailsMap: {},
      mode: dialogModes.create,
      dialogModes,
      isCompetitorCreateUpdateDialogOpen: false,
      competitorToEdit: null,
      competitorSources,
      isDeleteDialogOpen: false,
      competitorToDelete: null,
      checkingIfCompetitorCanBeDeleted: false,
      competitorUses: [],
      alertsIconColor: colours.alertsIconColor,
      numberFormats,
      activeCompetitorToggle: competitorSources.feed,
    };
  },

  computed: {
    ...mapState('workpackageProducts', ['downloadingItems', 'loading']),
    ...mapState('competitorMetadata', ['competitors', 'competitorKeyToTypeMap']),
    ...mapState('filters', [
      'inputScreensFetchParams',
      'inputScreensFilterRules',
      'retailAttributesFilter',
    ]),
    ...mapGetters('workpackages', ['hasSelectedWorkpackage', 'isSelectedWorkpackageMaster']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapState('clientConfig', ['exportConfigs']),
    ...mapState('attributes', [
      'attributes',
      'count',
      'loading',
      'attributeFetchParams',
      'attributesFilterRules',
      'attributeMetadata',
    ]),

    stickyColumnsNumberBeforeCompetitorTabs() {
      // Adds space for Product Key, Article and Size columns
      return this.isUseZones ? 4 : 3;
    },

    isUseZones() {
      return this.isFeatureFlagEnabled(useZones);
    },

    getCompetitors() {
      if (!this.showStoreGroupCompetitors) {
        return this.competitors.filter(x => x.competitorType !== competitorSources.storeGroup);
      }
      return this.competitors;
    },

    getHeaders() {
      if (!this.showStoreGroupCompetitors) {
        return this.headers.filter(x => x.competitorType !== competitorSources.storeGroup);
      }

      return this.headers;
    },

    showStoreGroupCompetitors() {
      return this.isFeatureFlagEnabled(displayStoreGroupCompetitors);
    },

    toggleTypes() {
      if (!this.showStoreGroupCompetitors) {
        return competitorToggles.filter(x => x !== competitorSources.storeGroup);
      }
      return competitorToggles || [];
    },

    pages() {
      return ceil(this.count / this.pagination.itemsPerPage);
    },

    toolStoreGroupColumn() {
      return this.isUseZones
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('competitorMatches.editor.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.fourFixedColumns}`,
          }
        : null;
    },

    fixedColumnsClass() {
      return this.toolStoreGroupColumn
        ? inputTableCssClasses.fourFixedColumns
        : inputTableCssClasses.threeFixedColumns;
    },

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    exportTranslationMap() {
      const toolStoreGroupTranslation = this.getColumnExportTranslation(this.toolStoreGroupColumn);
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};
      return {
        productKeyDisplay: this.$t('competitorMatches.editor.productKey'),
        ...toolStoreGroupTranslation,
        productName: this.$t('competitorMatches.editor.productDescription'),
        productSizeType: this.$t('competitorMatches.editor.productSize'),
        competitorName: this.$t('competitorMatches.editor.competitorName'),
        competitorPrice: this.$t('competitorMatches.editor.price'),
        competitorEnabled: this.$t('competitorMatches.editor.enabled'),
        ...hierarchyColumnTranslations,
      };
    },

    competitorMatches() {
      if (this.loading) {
        return [];
      }
      let competitorsToMap;
      if (!this.showStoreGroupCompetitors) {
        competitorsToMap = this.competitors.filter(
          comp => comp.competitorType !== competitorSources.storeGroup
        );
      } else {
        competitorsToMap = this.competitors;
      }
      return this.attributes.map(product => {
        product.competitorMatches = competitorsToMap.map(c => {
          return get(product.competitor, [c.competitorType, c.competitorKey], {
            competitorDescription: c.competitorDescription,
            disabled: true,
            competitorKey: c.competitorKey,
            grossReferenceCompetitorPrice: get(c, 'grossReferenceCompetitorPrice', null),
          });
        });
        return product;
      });
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    staticHeaders() {
      const tsgHeaders = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('competitorMatches.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `sortable s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeaders,
        {
          text: this.$t('competitorMatches.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productName',
          class: `sortable l-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        {
          text: this.$t('competitorMatches.editor.productSize'),
          align: 'start',
          sortable: true,
          value: 'productSizeType',
          class: `sortable border-right s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
      ];
    },

    headers() {
      const competitorHeaders = flatMap(this.competitors, (c, i) => {
        return [
          {
            text: this.$t('competitorMatches.editor.price'),
            sortable: true,
            align: 'start',
            key: 'competitorPrice',
            competitorKey: c.competitorKey,
            value: `competitor.${c.competitorType}.${c.competitorKey}.competitorPrice`,
            competitor: true,
            class: 'sortable',
            competitorType: c.competitorType,
            dataType: DataTypes.number,
          },
          {
            text: this.$t('competitorMatches.editor.enabled'),
            align: 'start',
            key: `competitor[${c.competitorDescription}].disabled`,
            value: `competitor.${i}.disabled`,
            competitor: true,
            class: 'border-right',
            competitorType: c.competitorType,
            dataType: DataTypes.boolean,
          },
        ];
      });

      return [...this.staticHeaders, ...this.hierarchyHeaders, ...competitorHeaders];
    },

    canEditCompetitorMatches() {
      return this.isPricingSpecialist;
    },

    hasUnsavedUpdates() {
      return keys(this.updates).length > 0;
    },

    hasInvalidUpdates() {
      return some(this.updateErrors);
    },

    competitorCanBeDeleted() {
      return isEmpty(this.competitorUses);
    },

    competitorCreateDialogKey() {
      const competitorDisplayDescription = get(
        this.competitorToEdit,
        'competitorDisplayDescription',
        ''
      );
      return `competitorCreateDialogKey-${competitorDisplayDescription}`;
    },
  },

  async created() {
    this.enabledValueTranslations = {
      1: this.$t('competitorMatches.editor.enabledTrue'),
      0: this.$t('competitorMatches.editor.enabledFalse'),
    };
    if (this.hasSelectedWorkpackage) {
      const fromPricingView = this.$route.meta.previousRoute.includes('/pricing/');
      if (isEmpty(this.attributes) || !this.showHierarchy || fromPricingView)
        await this.loadCompetitorMatchData();
      else {
        this.pagination = this.inputScreensFetchParams;
        this.pagination.itemsPerPage = 25;
        this.pagination.page = this.getDefaultSelectedPage(this.pagination, this.pages);
        this.competitorMatchesSearch = this.pagination.search;
        if (has(this.inputScreensFetchParams, 'pick')) {
          await this.loadCompetitorMatchData();
        }
      }
      await this.fetchHierarchy();
    }
  },

  methods: {
    ...mapActions('workpackageProducts', ['updateCompetitorPrices', 'downloadCompetitorMatches']),
    ...mapActions('attributes', ['fetchAttributesAggregated', 'checkForCompetitorUses']),
    ...mapActions('competitorMetadata', ['deleteCompetitor']),
    ...mapActions('hierarchy', ['fetchHierarchy']),
    ...mapActions('filters', ['setSelectedFilter']),

    isActiveCompetitorToggle(value) {
      if (!this.isUseZones) return true;
      return this.activeCompetitorToggle && this.activeCompetitorToggle === value;
    },

    setActiveCompetitorToggle(value) {
      this.activeCompetitorToggle = value;
    },

    hideStoreGroupCompetitors(value) {
      return value === competitorSources.storeGroup && !this.showStoreGroupCompetitors;
    },

    isCompetitorToggleDisabled(value) {
      const competitorTypeMatch = this.competitors.filter(x => x.competitorType === value);
      return competitorTypeMatch.length === 0;
    },

    getBaseParams() {
      let params = {
        limit: this.pagination.itemsPerPage,
        offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
        search: this.competitorMatchesSearch,
        where: this.retailAttributesFilter,
      };

      if (this.pagination.sortBy) {
        params = {
          ...params,
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.descending
            ? sortDirectionEnums.descending
            : sortDirectionEnums.ascending,
          sortDataType: this.pagination.sortDataType,
        };
      }

      return params;
    },

    async deleteCompetitorHandler(shouldDelete = false) {
      if (shouldDelete) {
        await this.deleteCompetitor({
          competitorKey: this.competitorToDelete.competitorKey,
          competitorName: this.competitorToDelete.competitorDescription,
          params: this.getBaseParams(),
        });
        this.activeCompetitorToggle = competitorSources.feed;
      }
      this.competitorToDelete = null;
      this.isDeleteDialogOpen = false;
      this.competitorUses = [];
    },

    async openDeleteDialog(competitor) {
      this.checkingIfCompetitorCanBeDeleted = true;
      this.competitorToDelete = competitor;
      this.isDeleteDialogOpen = true;
      this.competitorUses = await this.checkForCompetitorUses({ competitor });
      this.checkingIfCompetitorCanBeDeleted = false;
    },

    async downloadItems() {
      this.downloadCompetitorMatches({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        pick: this.getColumnExportPickOptions(this.toolStoreGroupColumn),
        enabledValueTranslations: this.enabledValueTranslations,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    async loadCompetitorMatchData() {
      const params = this.getBaseParams();
      return this.fetchAttributesAggregated({ params });
    },

    debounceSearchUpdate: debounce(function(value) {
      this.competitorMatchesSearch = value;
      this.pagination.page = 1;
      this.loadCompetitorMatchData();
    }, clientConfig.inputDebounceValue),

    async changeSort(column) {
      if (!column.sortable) return;
      const columnValue = this.getColumnValue(column);
      if (!columnValue) return;
      if (this.pagination.sortBy === columnValue) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = columnValue;
        this.pagination.descending = false;
        this.pagination.sortDataType = column.dataType;
      }
      await this.loadCompetitorMatchData();
    },

    async setFilterRules(filterRules) {
      // Reset the pagination as there might be no results on page X
      this.pagination.page = 1;

      this.setSelectedFilter({ filterName: 'retailAttributesFilter', filterValue: filterRules });
      await this.loadCompetitorMatchData();
    },

    getProductCompetitorKey(product, competitor) {
      return this.getInputUpdateKey(product._id, competitor.competitorKey);
    },

    getCompetitorPrice(product, competitor) {
      const key = this.getProductCompetitorKey(product, competitor);
      return !isNil(get(this.updates, [key, 'competitorPrice']))
        ? this.updates[key].competitorPrice
        : competitor.competitorPrice;
    },

    getPreviousValue(competitor) {
      return get(competitor, 'competitorPrice');
    },

    getCompetitorDisabled(product, competitor) {
      // <disabled> displayed as <enabled> so inverted here
      const key = this.getProductCompetitorKey(product, competitor);
      return !isNil(get(this.updates, [key, 'disabled']))
        ? !this.updates[key].disabled
        : !competitor.disabled;
    },

    getInputEditFieldClass(product, competitor) {
      const key = this.getProductCompetitorKey(product, competitor);
      // invalid value entered
      if (this.updateErrors[key]) {
        return 'attribute-cell-error';
      }
      // value edited and valid (not shown if the updated value is the same as the original)
      if (
        !isNil(this.updates[key]) &&
        this.getCompetitorPrice(product, competitor) !== this.getPreviousValue(competitor)
      ) {
        return 'attribute-cell-updated';
      }

      return '';
    },

    validateEntry(value, competitor, product) {
      if (value === null) return true;
      const formattedNumber = this.formatStringToNumber(value);
      const validNumber = formattedNumber === 0 || formattedNumber; // 0 would be invalid without explicit check

      if (validNumber) {
        const negativeNumber = this.valueIsMoreThanZero(formattedNumber);
        if (negativeNumber) {
          this.$set(
            this.updateErrors,
            this.getProductCompetitorKey(product, competitor),
            negativeNumber
          );
        } else {
          // Remove this value from updateErrors, so the page can be saved
          this.$delete(this.updateErrors, this.getProductCompetitorKey(product, competitor));
        }
      } else {
        // Add this value to updateErrors
        this.$set(
          this.updateErrors,
          this.getProductCompetitorKey(product, competitor),
          this.$t('validationErrors.incorrectDataType', {
            dataType: this.dataTypes.number,
          })
        );
      }
      return !!validNumber;
    },

    formatValue(rawValue) {
      const numericValue = this.formatStringToNumber(rawValue);
      return isNaN(numericValue) ? rawValue : numericValue;
    },

    clearUpdates() {
      this.updates = {};
      this.updatedRowsDetailsMap = {};
    },

    saveUpdates(product, uniqueKey, update) {
      this.$set(this.updates, uniqueKey, update);
      this.markRowAsUpdated(product);
    },

    markRowAsUpdated(row) {
      if (this.updatedRowsDetailsMap[row._id]) {
        return;
      }

      this.updatedRowsDetailsMap[row._id] = {
        productKeyDisplay: row.productKeyDisplay,
        toolStoreGroupKey: row.toolStoreGroupKey,
      };
    },

    recordChanges(rawValue, product, competitor) {
      const uniqueKey = this.getProductCompetitorKey(product, competitor);
      const formattedValue = rawValue ? this.formatValue(rawValue) : rawValue;
      let update = this.updates[uniqueKey];
      if (update) update.competitorPrice = formattedValue;
      else
        update = {
          competitorPrice: formattedValue,
          ...omit(competitor, 'competitorPrice'),
        };
      this.saveUpdates(product, uniqueKey, update);
      this.validateEntry(rawValue, competitor, product);
    },

    recordCheckboxChange(newValue, product, competitor) {
      const uniqueKey = this.getProductCompetitorKey(product, competitor);
      let update = this.updates[uniqueKey];
      if (update) update.disabled = !newValue;
      // <disabled> displayed as <enabled> so inverted here
      else
        update = {
          disabled: !newValue,
          competitorPrice: competitor.competitorPrice,
          ...omit(competitor, 'disabled'),
        };
      this.saveUpdates(product, uniqueKey, update);
    },

    formatUpdatesForSave() {
      return map(this.updates, (editedCompetitor, updateKey) => {
        const [productId, competitorKey] = this.parseInputUpdateKey(updateKey);
        const updatedRow = this.updatedRowsDetailsMap[productId];
        if (!updatedRow) return null;
        return {
          filter: {
            productKeyDisplay: updatedRow.productKeyDisplay,
            toolStoreGroupKey: updatedRow.toolStoreGroupKey,
          },
          update: {
            [`competitor.${
              this.competitorKeyToTypeMap[competitorKey]
            }.${competitorKey}`]: editedCompetitor,
          },
        };
      }).filter(item => !!item);
    },

    async persistUpdates() {
      await this.updateCompetitorPrices({
        updates: this.formatUpdatesForSave(),
        params: this.getBaseParams(),
      });
      this.clearUpdates();
    },

    valueIsMoreThanZero(v) {
      return v <= 0 ? this.$t('settings.parameters.errors.positiveValidation') : null;
    },

    isTooltipDisabled,
    preventPageNavigationOnHorizontalScroll,

    getHierarchyName({ hierarchy }, level) {
      return hierarchy[level].levelEntryDescription;
    },

    openCompetitorCreateUpdateDialog(mode, competitor = null) {
      this.mode = mode;
      this.competitorToEdit = competitor;
      this.isCompetitorCreateUpdateDialogOpen = true;
    },

    closeCompetitorCreateUpdateDialog() {
      this.competitorToEdit = null;
      this.isCompetitorCreateUpdateDialogOpen = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';

.hidden-header,
.hidden-cell {
  display: none;
}

.main-header th {
  position: relative;
  vertical-align: bottom;
}

.input-cell {
  width: 10%;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.v-data-table--fixed-header {
  thead tr {
    &:nth-child(1) th {
      box-shadow: none;
      font-weight: 600;
      height: 2rem;
    }
    &:nth-child(2) th {
      top: 2rem;
      height: 2rem;
    }
  }

  thead th,
  tbody td {
    // overrides tables.scss styles
    &.three-fixed:nth-child(3) {
      left: $s-width + $l-width;
    }
  }
}

.feature-toggle-btn {
  display: flex;
  align-items: center;
}

.competitor {
  &-row {
    th {
      box-shadow: none !important;

      &.box-shadow {
        box-shadow: inset 0 -0.1rem 0 rgba(0, 0, 0, 0.12) !important;
      }
    }
  }
  &-wrapper {
    padding-top: 0.2rem;
  }

  &-btn {
    background-color: unset !important;
    height: 2.1rem !important;
    border-radius: unset !important;
    box-shadow: unset !important;
    margin-left: 0.3rem !important;
    padding: 0 1rem 0 0 !important;
    border-left: $tab-border-blue solid 0.2rem !important;
    border-top: $tab-border-grey solid 0.1rem !important;
    border-right: $tab-border-grey solid 0.1rem !important;
    color: $tab-text-inactive !important;
    background-color: $pricing-background !important;
    letter-spacing: unset !important;
    cursor: pointer;
    margin-bottom: 0.1rem;

    &.v-size--default {
      font-size: 1.2rem !important;
    }

    &.active {
      border-bottom: white solid 0.2rem !important;
      border-left: $tab-border-blue-active solid 0.2rem !important;
      color: $pricing-primary !important;
      background-color: initial !important;
      cursor: unset;
      margin-bottom: -0.1rem;
    }

    &.theme--light.v-btn.v-btn--disabled:not(.v-btn--flat):not(.v-btn--text):not(.v-btn--outlined) {
      color: $tab-text-inactive !important;
      background-color: $pricing-background !important;
      opacity: 0.5 !important;
    }
  }
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}
</style>
