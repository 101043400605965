import axios from 'axios';
import to from 'await-to-js';
import Vue from 'vue';

import { find, isEmpty, findIndex, sortBy, last, cloneDeep, filter, get } from 'lodash';
import moment from 'moment';
import { jobStatuses } from '@enums/jobapi';
import downloadXlsxWorkpackage from '../utils/download-xlsx-workpackage';
import { getExportFileName } from '@sharedModules/data/utils/export-utils';
import sortStoreGroups from '../../utils/sort-store-groups-util';
import downloadCsvUtils from '../utils/download-csv';
import dateHasPassed from '../utils/date-checking';
import workpackageTypes from '@enums/workpackage-types';
import { yearMonthDayFormat, yearMonthDayHoursMinutesFormat } from '@enums/date-formats';

// Used for tasks where we need to wait for argo to complete its job
const argoStatusMap = {
  finished: jobStatuses.argoFinished,
  failed: jobStatuses.argoFailed,
};
const getInitialWorkpackageSpecificState = () => {
  return {
    selectedWorkpackage: {},
    selectedWorkpackageToolStoreGroups: [],
    loadingSelectedWorkpackageToolStoreGroups: false,
    selectedUnitLevelEntryKey: null,
    loading: false,
    inactiveLoading: false,
    exportingIds: [],
    postingIds: [],
    exportingToSAPIds: [],
    updatingIds: [],
    deletingIds: [],
    workpackagesInCreation: [],
    /**
     * @property {WorkpackageTransferPair[]} workpackagesInTransfer - Pairs of workpackages involved in a transfer operation.
     * @typedef {Object} WorkpackageTransferPair
     * @property {string} dest - The destination workpackage ID.
     * @property {string} src - The source workpackage ID.
     */
    workpackagesInTransfer: [],
    creating: false,
    numberOfPriceChangesByWpIdAndCategoryId: {},
    userWorkpackageStoreGroup: {},
    masterWorkpackageStoreGroup: {},
    productsGoLiveBeforeToday: [],
    productsGoLiveBeforeWorkpackage: [],
    productsGoLiveAfterWorkpackage: [],
  };
};
const store = {
  namespaced: true,

  state: {
    ...getInitialWorkpackageSpecificState(),
    workpackages: [],
  },

  getters: {
    masterWorkpackages: state =>
      state.workpackages.filter(({ type }) => type === workpackageTypes.master),

    activeWorkpackages: state => {
      const active = state.workpackages.filter(
        ({ goLiveDate, type, description }) =>
          goLiveDate !== null &&
          !dateHasPassed(goLiveDate) &&
          type !== workpackageTypes.master &&
          !find(state.workpackagesInCreation, { description, goLiveDate })
      );
      const mockedActive = state.workpackagesInCreation.filter(
        ({ goLiveDate }) => goLiveDate !== null && !dateHasPassed(goLiveDate)
      );
      return [...mockedActive, ...active];
    },

    archivedWorkpackages: state => {
      const archived = state.workpackages.filter(
        ({ goLiveDate, type, description }) =>
          goLiveDate !== null &&
          dateHasPassed(goLiveDate) &&
          type !== workpackageTypes.master &&
          !find(state.workpackagesInCreation, { description, goLiveDate })
      );
      const mockedArchived = state.workpackagesInCreation.filter(
        ({ goLiveDate }) => goLiveDate !== null && dateHasPassed(goLiveDate)
      );
      return [...mockedArchived, ...archived];
    },

    getSelectedWorkpackage: state => state.selectedWorkpackage,

    hasSelectedWorkpackage: state => !isEmpty(state.selectedWorkpackage),

    isSelectedWorkpackageMaster: state =>
      state.selectedWorkpackage && state.selectedWorkpackage.type === workpackageTypes.master,

    getToolStoreGroupPairs: state => {
      const { selectedWorkpackageToolStoreGroups } = state;
      return selectedWorkpackageToolStoreGroups.reduce((prev, curr) => {
        return {
          ...prev,
          [curr.description]: curr.key,
        };
      }, {});
    },
    // Returns true if any provided workpackage ID is in the transfer list
    isAnyWorkpackageInTransferList: state => workpackageIds => {
      return state.workpackagesInTransfer.some(
        wpPair => workpackageIds.includes(wpPair.dest) || workpackageIds.includes(wpPair.src)
      );
    },

    getToolStoreGroupValues: state => {
      // Fake back-end response and return an array of all possible toolStoreGroup values
      const { selectedWorkpackageToolStoreGroups } = state;
      return selectedWorkpackageToolStoreGroups.map(relationship => {
        return {
          attributeKey: relationship.description,
          attributeValue: relationship.description,
        };
      });
    },
  },

  mutations: {
    setWorkpackages(state, workpackages) {
      state.workpackages = workpackages;
    },

    setSelectedWorkpackageToolStoreGroups(state, toolStoreGroups) {
      state.selectedWorkpackageToolStoreGroups = toolStoreGroups;
    },

    setLoadingSelectedWorkpackageToolStoreGroups(state, loadingSelectedWorkpackageToolStoreGroups) {
      state.loadingSelectedWorkpackageToolStoreGroups = loadingSelectedWorkpackageToolStoreGroups;
    },

    setInactiveWorkpackages(state, workpackages) {
      const currentWps = cloneDeep(state.workpackages);
      state.workpackages = [...currentWps, ...workpackages];
    },

    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setInactiveLoading(state, isLoading) {
      state.inactiveLoading = isLoading;
    },

    setCreatingStatus(state, isLoading) {
      state.creating = isLoading;
    },

    addWorkpackageToDeletingList(state, workpackageId) {
      state.deletingIds = [...state.deletingIds, workpackageId];
    },

    removeWorkpackageFromDeletingList(state, workpackageId) {
      state.deletingIds = state.deletingIds.filter(id => id !== workpackageId);
    },

    addWorkpackageToExportingList(state, workpackageId) {
      state.exportingIds = [...state.exportingIds, workpackageId];
    },

    addWorkpackageToPostingList(state, workpackageId) {
      state.postingIds = [...state.postingIds, workpackageId];
    },

    addWorkpackageToExportingToSAPList(state, workpackageId) {
      state.exportingToSAPIds = [...state.exportingToSAPIds, workpackageId];
    },

    addWorkpackageToCreationList(state, mockWorkpackage) {
      state.workpackagesInCreation = [...state.workpackagesInCreation, mockWorkpackage];
    },

    setWorkpackageToCreationList(state, mockWorkpackages) {
      state.workpackagesInCreation = mockWorkpackages;
    },

    addWorkpackagesToTransferList(state, wps) {
      state.workpackagesInTransfer = [...state.workpackagesInTransfer, { ...wps }];
    },

    /**
     * Remove workpackages from the transfer list by destination workpackage ID.
     * @param {Object} state - The Vuex state object.
     * @param {string} dest - The destination workpackage ID.
     * @returns {void}
     */
    removeWorkpackagesFromToTransferList(state, { dest }) {
      // using only destination Id to find item to remove as source Id is not always available
      // and we can't have multiple transfers to the same destination
      state.workpackagesInTransfer = state.workpackagesInTransfer.filter(
        wpPair => wpPair.dest !== dest
      );
    },

    setWorkpackagesInTransferList(state, wps) {
      state.workpackagesInTransfer = wps;
    },

    removeWorkpackageFromExportingList(state, workpackageId) {
      state.exportingIds = state.exportingIds.filter(id => id !== workpackageId);
    },

    removeWorkpackageFromPostingList(state, workpackageId) {
      state.postingIds = state.postingIds.filter(id => id !== workpackageId);
    },

    removeWorkpackageFromExportingToSAPList(state, workpackageId) {
      state.exportingToSAPIds = state.exportingToSAPIds.filter(id => id !== workpackageId);
    },

    addWorkpackageToUpdatingList(state, workpackageId) {
      state.updatingIds = [...state.updatingIds, workpackageId];
    },

    removeWorkpackageFromUpdatingList(state, workpackageId) {
      state.updatingIds = state.updatingIds.filter(id => id !== workpackageId);
    },

    removeWorkpackageFromCreationList(state, { descriptions }) {
      const filteredCreationList = state.workpackagesInCreation.filter(
        wp => !descriptions.includes(wp.description)
      );
      state.workpackagesInCreation = filteredCreationList;
    },

    setSelectedWorkPackage(state, workpackage) {
      state.selectedWorkpackage = workpackage;
    },

    setSelectedUnitLevelEntryKey(state, unitLevelEntryKey) {
      state.selectedUnitLevelEntryKey = unitLevelEntryKey;
    },

    setNumberOfPriceChangesByWpIdAndCategoryId(state, priceChanges) {
      state.numberOfPriceChangesByWpIdAndCategoryId = {
        ...state.numberOfPriceChangesByWpIdAndCategoryId,
        ...priceChanges,
      };
    },

    resetWorkpackageSpecificState(state) {
      Object.assign(state, getInitialWorkpackageSpecificState());
    },

    updateWorkpackageMetaData(state, updatedWorkpackage) {
      const index = findIndex(state.workpackages, { _id: updatedWorkpackage._id });
      Vue.set(state.workpackages[index], 'lastUpdated', updatedWorkpackage.lastUpdated);
      Vue.set(
        state.workpackages[index],
        'competitorLastUpdated',
        updatedWorkpackage.competitorLastUpdated
      );
    },

    setUserWorkPackageStoreGroup(state, wpsg) {
      state.userWorkpackageStoreGroup = wpsg;
    },

    setMasterWorkPackageStoreGroup(state, wpsg) {
      state.masterWorkpackageStoreGroup = wpsg;
    },

    setProductsGoLiveBeforeToday(state, productsGoLiveBeforeToday) {
      state.productsGoLiveBeforeToday = productsGoLiveBeforeToday;
    },

    setProductsGoLiveBeforeWorkpackage(state, productsGoLiveBeforeWorkpackage) {
      state.productsGoLiveBeforeWorkpackage = productsGoLiveBeforeWorkpackage;
    },

    setProductsGoLiveAfterWorkpackage(state, productsGoLiveAfterWorkpackage) {
      state.productsGoLiveAfterWorkpackage = productsGoLiveAfterWorkpackage;
    },
  },

  actions: {
    async fetchWorkpackages({ commit, dispatch }, { params = {} } = {}) {
      if (params.wpActive === false) {
        commit('setInactiveLoading', true);
      } else {
        commit('setLoading', true);
        await dispatch('getRunningCreationJobs');
      }
      dispatch('getRunningTransferJobs');
      const [err, { data: workpackages }] = await to(
        axios.get('/api/work-packages/units-aggregate', { params })
      );
      if (err) throw new Error(err.message);
      if (params.wpActive === false) {
        commit('setInactiveWorkpackages', workpackages);
        commit('setInactiveLoading', false);
      } else {
        commit('setWorkpackages', workpackages);
        commit('setLoading', false);
      }
      return workpackages.map(wp => wp._id);
    },

    async createWorkpackage({ commit, dispatch }, params = {}) {
      // return true if successful and false if not
      commit('setCreatingStatus', true);
      const mockWorkpackage = {
        ...params,
        mocked: true,
      };
      const payload = {
        store_format: params.storeFormat,
        category_ids: params.categoryIds,
        go_live_date: params.goLiveDate,
        completion_date: params.completionDate,
        owner: params.owner,
        description: params.description,
        jobApiTask: 'run_create_workpackage',
      };
      const [err] = await to(
        axios.post(`/api/work-packages/workpackage/${params.workpackageId}`, { params: payload })
      );
      commit('setCreatingStatus', false);
      if (err) {
        dispatch('addFailedCreateWorkpackageNotification', { description: params.description });
        return false;
      }
      // Only create mock workpackage place holder if successfully triggered
      commit('addWorkpackageToCreationList', mockWorkpackage);
      return err === null;
    },

    async afterCreateWorkpackage(
      { commit, dispatch, state, getters },
      { payload: newWpDescription }
    ) {
      const archivedWpsExist = !isEmpty(getters.archivedWorkpackages);
      await dispatch('notifications/resetAllNotifications', {}, { root: true });
      await dispatch('fetchWorkpackages', { params: { wpActive: true } });
      if (archivedWpsExist) await dispatch('fetchWorkpackages', { params: { wpActive: false } });
      const latestWp = last(
        sortBy(
          filter(
            state.workpackages,
            wp => wp.type === workpackageTypes.workpackage && newWpDescription === wp.description
          ),
          wp => moment.min(moment(wp.lastUpdated))
        )
      );
      commit('removeWorkpackageFromCreationList', { descriptions: [newWpDescription] });
      await dispatch(
        'hierarchy/fetchHierarchyForWorkpackage',
        { params: { where: { workpackageId: latestWp._id, wholesale: false } } },
        { root: true }
      );
    },

    async afterFailedCreateWorkpackage({ commit }, { payload: newWpDescription }) {
      commit('removeWorkpackageFromCreationList', { descriptions: [newWpDescription] });
    },

    async transferWorkpackage(
      { commit },
      { destinationWorkpackageId, sourceWorkpackageId, fields, selectedCategories }
    ) {
      const updates = fields.map(field => field.id);
      commit('addWorkpackagesToTransferList', {
        dest: destinationWorkpackageId,
        src: sourceWorkpackageId,
      });
      try {
        await axios.post(
          `/api/work-packages/workpackage/${destinationWorkpackageId}/transfer-workpackage`,
          {
            jobApiTask: 'transfer_workpackage',
            sourceWorkpackageId,
            updates,
            selectedCategories,
          }
        );
      } catch (err) {
        // let job api notifications handle removeWorkpackageFromTransferList if no error was thrown, use afterTransferWorkpackage.
        commit('removeWorkpackagesFromToTransferList', {
          dest: destinationWorkpackageId,
        });
        console.info(err);
      }
    },

    async validateDataBeforeTransferring(
      _,
      { destinationWorkpackageId, sourceWorkpackageId, categories, fields }
    ) {
      const [err, result] = await to(
        axios.get(
          `/api/work-packages/workpackage/${destinationWorkpackageId}/transfer-workpackage/${sourceWorkpackageId}/validation`,
          {
            params: { categories, fields },
          }
        )
      );
      return err ? {} : result.data;
    },

    // Note: Argo does not update the job status immediately; it takes a few seconds to reflect the current state.
    // Therefore, if you query the status immediately after creating a work package, it may show the job as running, even if it has completed.
    async getRunningCreationJobs({ commit }) {
      const [err, res] = await to(axios.get('/api/work-packages/creationJobs'));
      if (err) {
        throw new Error(err.message);
      }
      const { data: creationJobs } = res;

      const creationJobsWithMockedFlag = creationJobs.map(job => {
        return {
          goLiveDate: job.params.go_live_date,
          description: job.params.description,
          categoryIds: job.params.category_ids,
          completionDate: job.params.completion_date,
          mocked: true,
          owner: job.params.owner,
          storeFormat: job.params.store_format,
          workpackageId: job.params.workpackage_id,
        };
      });

      commit('setWorkpackageToCreationList', creationJobsWithMockedFlag);
    },

    // Note: Argo does not update the job status immediately; it takes a few seconds to reflect the current state.
    // Therefore, if you query the status immediately after transferring a work package, it may show the job as running, even if it has completed.
    async getRunningTransferJobs({ commit }) {
      const [err, res] = await to(axios.get('/api/work-packages/transfer-jobs'));
      if (err) {
        throw new Error(err.message);
      }
      const { data: transferJobs } = res;
      const pairInvolvedInTransfer = transferJobs.reduce((arr, obj) => {
        arr.push({
          dest: obj.params.destination_workpackage_id,
          src: obj.params.source_workpackage_id,
        });
        return arr;
      }, []);
      commit('setWorkpackagesInTransferList', pairInvolvedInTransfer);
    },

    // Try to poll argo job status every second until it's done.
    // If for 20 seconds the job is still running(unlikely), stop polling and update the scenario results.
    async waitForArgoTransferWorkpackageJobCompletion(
      { dispatch, state, rootState, commit },
      { payload, id: destinationWorkpackageId, jobStatus }
    ) {
      const workpackage = find(rootState.workpackages.workpackages, { _id: payload });
      const notificationPayload = workpackage
        ? workpackage.description
        : rootState.workpackages.selectedWorkpackage.description;
      let attempts = 0;
      const maxAttempts = 20;
      const intervalId = setInterval(() => {
        (async () => {
          // check on condition to exit intervall: argo job is complete or max attempts reached
          if (isEmpty(state.workpackagesInTransfer) || attempts >= maxAttempts) {
            clearInterval(intervalId);
            await dispatch('afterTransferWorkpackage', { id: destinationWorkpackageId });
            const jobId = 'transfer_workpackage';
            const notificationJobStatus = argoStatusMap[jobStatus] || jobStatus;
            commit(
              'notifications/addNotification',
              {
                id: `${jobId}:${notificationJobStatus}:${new Date().toISOString()}`,
                jobStatus: notificationJobStatus,
                notificationPayload,
                baseTranslation: `notifications.${jobId}`,
              },
              { root: true }
            );
          } else {
            dispatch('getRunningTransferJobs');
            attempts += 1;
          }
        })();
      }, 1000);
    },

    async afterTransferWorkpackage({ commit, dispatch }, { id: destinationWorkpackageId }) {
      await dispatch('fetchWorkpackageData', {
        id: destinationWorkpackageId,
      });
      // removing workpackages pairs from transfer by destId
      commit('removeWorkpackagesFromToTransferList', {
        dest: destinationWorkpackageId,
      });
    },

    async downloadPenaltyExportData(context, { workpackage }) {
      // NOTE: This export uses quite a lot of dynamic fields for competitors and alerts
      // As this feature is only intended for a single banner, the column name mapping is done on the server.
      // As a result, I didn't use the existing middleware.
      const { _id } = workpackage;

      const [err, response] = await to(
        axios.get(`/api/scenario-results/workpackage/${_id}/export-penalty-data`)
      );
      if (err) throw new Error(err.message);

      const dateString = moment().format(yearMonthDayHoursMinutesFormat);
      const fileName = `Penalty_Export_${dateString}.csv`;

      downloadCsvUtils.downloadCsvFile(response.data, fileName);
    },

    async fetchWorkpackageData({ commit }, { id }) {
      const [err, { data: updatedWorkpackageData }] = await to(
        axios.get(`/api/work-packages/${id}`)
      );
      if (err) throw new Error(err.message);
      commit('updateWorkpackageMetaData', updatedWorkpackageData);
    },

    async deleteWorkpackage({ commit }, workpackage) {
      commit('addWorkpackageToDeletingList', workpackage._id);

      const payload = {
        description: workpackage.description,
        jobApiTask: 'run_delete_workpackage',
      };

      const [err] = await to(
        axios.delete(`/api/work-packages/workpackage/${workpackage._id}`, {
          data: { params: payload },
        })
      );
      // setDeletingStatus in afterDeleteWorkpackage if no error was thrown, so that duplicate delete jobs aren't triggered.
      if (err) commit('removeWorkpackageFromDeletingList', workpackage._id);

      return err === null; // return true if successful and false if not
    },

    async afterDeleteWorkpackage({ commit, dispatch, getters }, { id }) {
      commit('removeWorkpackageFromDeletingList', id);
      const archivedWpsExist = !isEmpty(getters.archivedWorkpackages);
      await dispatch('fetchWorkpackages', { params: { wpActive: true } });
      if (archivedWpsExist) dispatch('fetchWorkpackages', { params: { wpActive: false } });
    },

    async updateWorkPackages({ commit, getters }, { dispatch }, { updates = {} } = {}) {
      commit('setLoading', true);
      const [err] = await to(axios.patch('/api/work-packages', updates));
      if (err) throw new Error(err.message);
      const archivedWpsExist = !isEmpty(getters.archivedWorkpackages);
      await dispatch('fetchWorkpackages', { params: { wpActive: true } });
      if (archivedWpsExist) dispatch('fetchWorkpackages', { params: { wpActive: false } });
    },

    setWorkpackages({ commit }, workpackages) {
      commit('setWorkpackages', workpackages);
    },

    addExportWorkpackageNotification({ commit }, { selectedWorkpackage, jobStatus }) {
      const jobId = 'export_workpackage';
      const baseTranslation = `notifications.${jobId}`;
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: selectedWorkpackage.description,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },

    addPostWorkpackageNotification({ commit }, { selectedWorkpackage, jobStatus }) {
      const jobId = 'post_workpackage';
      const baseTranslation = `notifications.${jobId}`;
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: selectedWorkpackage.description,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },

    addFailedCreateWorkpackageNotification({ commit }, { description }) {
      const jobId = 'run_create_workpackage';
      const baseTranslation = `notifications.${jobId}`;
      const jobStatus = 'failed_to_send';
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: description,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },

    async previewWorkpackageResults({ commit }, { selectedWorkpackage, fields, exportType }) {
      if (!selectedWorkpackage) {
        return Promise.resolve([]);
      }
      commit('addWorkpackageToExportingList', selectedWorkpackage._id);
      const params = { export: true, fields, exportType };

      const [err, response] = await to(
        axios.get(`/api/work-packages/workpackage/${selectedWorkpackage._id}/preview-results`, {
          params,
        })
      );
      commit('removeWorkpackageFromExportingList', selectedWorkpackage._id);
      if (err) {
        throw new Error(err.message);
      }
      commit('setProductsGoLiveBeforeToday', get(response, 'data.productsGoLiveBeforeToday', []));
      commit(
        'setProductsGoLiveBeforeWorkpackage',
        get(response, 'data.productsGoLiveBeforeWP', [])
      );
      commit('setProductsGoLiveAfterWorkpackage', get(response, 'data.productsGoLiveAfterWP', []));
      commit('removeWorkpackageFromExportingList', selectedWorkpackage._id);
    },

    async downloadWorkpackageResults(
      { commit, dispatch },
      { selectedWorkpackage, fields, exportType, columnFormatters = {} }
    ) {
      if (!selectedWorkpackage) {
        return Promise.resolve([]);
      }
      dispatch('addExportWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.starting,
      });
      commit('addWorkpackageToExportingList', selectedWorkpackage._id);
      const params = { export: true, fields, exportType };
      const { storeFormat } = selectedWorkpackage;
      const executionDate = moment(selectedWorkpackage.goLiveDate).format(yearMonthDayFormat);
      const exportTime = moment().format(yearMonthDayHoursMinutesFormat);
      const [err, response] = await to(
        axios.get(`/api/work-packages/workpackage/${selectedWorkpackage._id}/results`, { params })
      );
      if (err) {
        dispatch('addExportWorkpackageNotification', { selectedWorkpackage, jobStatus: 'failed' });
        commit('removeWorkpackageFromExportingList', selectedWorkpackage._id);
        throw new Error(err.message);
      }
      // response.data may be only header row. 2 or more rows mean at least one data row in export.
      if (isEmpty(response.data) || `${response.data}`.split('\n').length < 2) {
        dispatch('addExportWorkpackageNotification', {
          selectedWorkpackage,
          jobStatus: 'no_results',
        });
        commit('removeWorkpackageFromExportingList', selectedWorkpackage._id);
        return;
      }
      const filename = `${storeFormat} ${executionDate} - exported ${exportTime}.xlsx`;
      downloadXlsxWorkpackage(response.data, filename, { columnFormatters });
      commit('removeWorkpackageFromExportingList', selectedWorkpackage._id);
      dispatch('addExportWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.finished,
      });
    },

    async exportWorkpackageResultsToAPI({ commit, dispatch }, { selectedWorkpackage, fields }) {
      if (!selectedWorkpackage) {
        return Promise.resolve([]);
      }
      dispatch('addPostWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.starting,
      });
      commit('addWorkpackageToPostingList', selectedWorkpackage._id);
      const params = { export: true, fields };
      const [err, response] = await to(
        axios.post(`/api/work-packages/workpackage/${selectedWorkpackage._id}/results-to-api`, {
          params,
        })
      );
      if (err) {
        dispatch('addPostWorkpackageNotification', { selectedWorkpackage, jobStatus: 'failed' });
        commit('removeWorkpackageFromPostingList', selectedWorkpackage._id);
        throw new Error(err.message);
      }
      if (response.status === 204) {
        dispatch('addPostWorkpackageNotification', {
          selectedWorkpackage,
          jobStatus: 'no_results',
        });
        commit('removeWorkpackageFromPostingList', selectedWorkpackage._id);
        return;
      }
      // if no error and no 204 then we succeeded
      commit('removeWorkpackageFromPostingList', selectedWorkpackage._id);
      dispatch('addPostWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.finished,
      });
    },

    async downloadExportToSAP(
      { commit, dispatch },
      { selectedWorkpackage, fieldConfigs, exportConfig, exportType, toFTP }
    ) {
      if (!selectedWorkpackage) {
        return Promise.resolve([]);
      }
      dispatch('addExportWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.starting,
      });
      commit('addWorkpackageToExportingToSAPList', selectedWorkpackage._id);
      const params = {
        export: true,
        fieldConfigs,
        parseConfig: {
          header: exportConfig.csvHeader,
          delimiter: exportConfig.csvDelimiter,
          quote: exportConfig.csvQuote,
        },
        exportType,
        toFTP,
      };
      const url = `/api/work-packages/workpackage/${selectedWorkpackage._id}/results`;
      const [err, response] = await to(
        toFTP ? axios.post(url, {}, { params }) : axios.get(url, { params })
      );
      if (err) {
        dispatch('addExportWorkpackageNotification', { selectedWorkpackage, jobStatus: 'failed' });
        commit('removeWorkpackageFromExportingToSAPList', selectedWorkpackage._id);
        throw new Error(err.message);
      }
      // response.data may be only header row. 2 or more rows mean at least one data row in export.
      if (isEmpty(response.data) || `${response.data}`.split('\n').length < 2) {
        dispatch('addExportWorkpackageNotification', {
          selectedWorkpackage,
          jobStatus: 'no_results',
        });
        commit('removeWorkpackageFromExportingToSAPList', selectedWorkpackage._id);
        return;
      }
      if (!toFTP) {
        const fileName = getExportFileName(exportConfig);
        downloadCsvUtils.downloadCsvFile(response.data, fileName);
      }
      commit('removeWorkpackageFromExportingToSAPList', selectedWorkpackage._id);
      dispatch('addExportWorkpackageNotification', {
        selectedWorkpackage,
        jobStatus: jobStatuses.finished,
      });
    },

    resetState({ commit }) {
      commit('resetWorkpackageSpecificState');
    },

    async setUserSelectionWorkpackage(
      { commit, dispatch, state, rootState },
      { selectedWorkpackageId = {} } = {}
    ) {
      const selectedWorkpackage = find(state.workpackages, wp => wp._id === selectedWorkpackageId);
      await dispatch('resetWorkpackageState', null, { root: true });
      commit('setSelectedWorkPackage', selectedWorkpackage);
      // re-fetch attribute metadata after attributes state being reset
      await Promise.all([
        dispatch('storeGroupRelationships/fetchStoreGroupRelationships', null, {
          root: true,
        }),
        dispatch('storeGroupRelationships/fetchWorkpackageStoreGroups', null, {
          root: true,
        }),
        dispatch('storeGroupRelationships/fetchStoreGroups', null, {
          root: true,
        }),
        dispatch('storeGroupRelationships/fetchStoregroupKeyNameMap', null, {
          root: true,
        }),
      ]);
      await dispatch('attributes/fetchAttributeMetadata', null, { root: true });

      let defaultFilterSet = false;
      // Add default filter as soon as metadata is loaded (if feature is supported)
      if (rootState.clientConfig.toggleLogic.useDefaultFilter) {
        defaultFilterSet = true;
        await dispatch('filters/setDefaultFilter', null, { root: true });
      }

      const defaultCategoryFilter = rootState.clientConfig.toggleLogic.defaultCategoryFilter;
      if (defaultCategoryFilter && get(defaultCategoryFilter, '0.attributeValue')) {
        defaultFilterSet = true;
        await dispatch(
          'filters/setDefaultCategoryFilter',
          { defaultCategoryFilter },
          { root: true }
        );
      }

      if (!defaultFilterSet) {
        // If feature is not supported - reset filters
        await dispatch(
          'filters/setSelectedFilter',
          {
            filterName: 'retailAttributesFilter',
            filterValue: [],
          },
          { root: true }
        );
      }

      dispatch('getToolStoreGroups');
      // dispatch actions which have been called at created() in home.vue
      dispatch('competitorMetadata/fetchCompetitorMetadata', null, { root: true });
      dispatch(
        'hierarchy/fetchHierarchyForWorkpackage',
        {
          params: {
            sortBy: 'levelEntryDescription',
            sortDirection: 1,
            where: {
              workpackageId: selectedWorkpackageId,
              wholesale: false,
            },
          },
        },
        { root: true }
      );

      // reset selected unit
      commit('setSelectedUnitLevelEntryKey', null);
    },

    async setUserSelectionUnit(
      { dispatch, commit, rootGetters },
      { selectedWorkpackageId, unitLevelEntryKey, parentLevelEntryKey } = {}
    ) {
      await dispatch('setUserSelectionWorkpackage', { selectedWorkpackageId });
      await dispatch(
        'gridView/setExpandedHierarchyLevelItems',
        {
          newExpandedHierarchy: [
            parentLevelEntryKey,
            parentLevelEntryKey,
            unitLevelEntryKey,
            null,
            null,
          ],
        },
        { root: true }
      );

      commit('setSelectedUnitLevelEntryKey', unitLevelEntryKey);

      // for unit manager view
      if (rootGetters['context/isUnitManager']) {
        dispatch(
          'filters/setSelectedFilter',
          { filterName: 'selectedLevelEntryKey', filterValue: unitLevelEntryKey },
          { root: true }
        );
      }
    },

    async getPriceChangeData({ commit }, { params = {} } = {}) {
      // use post instead of get, as the workpackageId list in the params can be very long and hit GET request length restriction
      const [err, response] = await to(
        axios.post(`/api/work-packages/priceChangesByWorkpackage`, params)
      );
      if (err) {
        throw new Error(err.message);
      }
      const numberOfPriceChangesArray = response.data;
      const numberOfPriceChangesByWpIdAndCategoryId = numberOfPriceChangesArray.reduce(
        (acc, priceObject) => {
          const key = `${priceObject._id.workpackageId}::${priceObject._id.categoryId}`;
          acc[key] = priceObject.numPriceChanges;
          return acc;
        },
        {}
      );
      commit('setNumberOfPriceChangesByWpIdAndCategoryId', numberOfPriceChangesByWpIdAndCategoryId);
    },

    async getToolStoreGroups({ state, commit, rootState }) {
      const workpackageId = state.selectedWorkpackage._id;
      if (!workpackageId) return;
      commit('setLoadingSelectedWorkpackageToolStoreGroups', true);
      const [err, res] = await to(
        axios.get(`/api/workpackage-store-groups/workpackage/${workpackageId}/list`)
      );
      if (err) {
        commit('setSelectedWorkpackageToolStoreGroups', []);
        commit('setLoadingSelectedWorkpackageToolStoreGroups', false);
        throw new Error(err.message);
      }
      const mappedData = res.data
        ? res.data.map(pricingGroup => ({
            key: pricingGroup.toolStoreGroupKey,
            description: pricingGroup.toolStoreGroupDescription,
            pricingStoreGroupKey: pricingGroup.pricingStoreGroupKey,
          }))
        : [];
      const sortedToolStoreGroups = sortStoreGroups(
        mappedData,
        rootState.clientConfig.storeGroupOrderConfig,
        'description'
      );
      commit('setSelectedWorkpackageToolStoreGroups', sortedToolStoreGroups);
      commit('setLoadingSelectedWorkpackageToolStoreGroups', false);
    },

    async checkForWorkpackageExistence({ state }) {
      const workpackageId = get(state, ['selectedWorkpackage', '_id'], null);
      if (!workpackageId) return { absentInStore: true };
      const [err, response] = await to(axios.get(`/api/work-packages/${workpackageId}/exists`));
      if (err) throw new Error(err.message);
      return response.data;
    },
  },
};

export default store;
