<template>
  <v-expansion-panels>
    <v-expansion-panel focusable class="mb-4">
      <v-expansion-panel-header class="panel-header">
        <div class="body-2">
          <span class="filter-text-title">{{ $t(titleLocalisation) }}:</span>
          <v-icon v-once class="filter-text" size="2.75rem">filter_list</v-icon>
          <span class="font-weight-bold filter-text">
            {{ $tc(filterCountLocalisation, numFiltersApplied) }}
          </span>
        </div>
        <template v-slot:actions>
          <v-icon v-once color="indigo darken-2">expand_more</v-icon>
        </template>
      </v-expansion-panel-header>
      <v-expansion-panel-content class="panel-header">
        <v-card-text class="px-6 pt-0">
          <!-- Filter boxes for each existing filter rule. -->
          <!-- independent filters -->
          <v-flex v-for="(rule, index) in mutableFilterRules" :key="rule.key">
            <v-divider
              v-if="mutableFilterRules.length > 1 && index > 0"
              :key="index"
              class="my-2"
            />
            <attribute-filter
              :key="rule.key"
              :ruleset-type="rulesetType"
              :filter-rule="rule.rule"
              :selected-attribute-metadata="nonEmptyRules"
              :available-attribute-metadata="availableAttributeMetadata"
              @update-filter-attributes="updateAttributeFilter($event, rule.key)"
              @remove-filter="removeAttributeFilter(rule.key)"
              @filter-blurred="emitAttributeFilterChange"
            />
          </v-flex>

          <!-- Button to add an additional filter rule -->
          <v-layout row>
            <v-btn right icon text depressed @click="addAttributeFilter">
              <v-icon v-once color="indigo darken-2" size="2.75rem">add_box</v-icon>
            </v-btn>
          </v-layout>
        </v-card-text>
      </v-expansion-panel-content>
    </v-expansion-panel>
  </v-expansion-panels>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import {
  cloneDeep,
  filter,
  pick,
  random,
  includes,
  find,
  size,
  isEmpty,
  slice,
  forEach,
  intersection,
  map,
} from 'lodash';
import { attributeOption, storegroupOption } from '@enums/filter-options';
import hierarchyFiltersMixin from '../mixins/hierarchyFilters';
import productAttributesFiltersMixin from '../mixins/productAttributesFilters';
import { architectureGroupLevel, pricingGroupLevel, rootHierarchyLevel } from '@enums/hierarchy';
import { useZones } from '@enums/feature-flags';
import featureFlagsMixin from '../mixins/featureFlags';

export default {
  mixins: [hierarchyFiltersMixin, productAttributesFiltersMixin, featureFlagsMixin],

  props: {
    rulesetType: {
      type: String,
      required: false,
      default: '',
    },
    filterRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    // e.g. for line pricing, products are always filtered by AG
    fixedFilterRules: {
      type: Array,
      required: false,
      default: () => [],
    },
    // name of filter title in localisation file
    titleLocalisation: {
      type: String,
      required: true,
    },
    // name of filter count in localisation file
    filterCountLocalisation: {
      type: String,
      required: true,
    },
    enableHierarchyFilters: {
      type: Boolean,
      default: false,
    },
    hierarchyFilterLevel: {
      type: Number,
      default: rootHierarchyLevel,
      validator(value) {
        // must be one of these levels
        return [rootHierarchyLevel, architectureGroupLevel, pricingGroupLevel].includes(value);
      },
    },
    enableWholesaleHierarchyFilters: {
      type: Boolean,
      default: false,
    },
    enableNonAttributeFilters: {
      type: Boolean,
      default: true,
    },
    enableAttributeFilters: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      mutableFilterRules: this.filterRules.length
        ? cloneDeep(this.filterRules).map(rule => ({
            key: random(0, 1, true), // note this may have collisions with large number of rules. see https://ahtdao.atlassian.net/browse/AHTDPR-1189
            rule,
          }))
        : [this.filterRule()],
      nonEmptyRules: [],
      isNewTab: false,
    };
  },

  events: {
    onArchitectureRulesFilterUpdated(newFilterValue) {
      // don't carry selected attribute filters to changed architecture group
      // only do this on LPG, i.e. where hierarchy filters not enabled
      if (!this.enableHierarchyFilters && !newFilterValue) {
        this.mutableFilterRules = [this.filterRule()];
      }

      // If new filter value is explicitly passed (from architecture-group-filter.vue), then use it
      if (newFilterValue)
        this.mutableFilterRules = [{ ...this.filterRule(), rule: newFilterValue }];
    },
  },

  computed: {
    ...mapState('attributes', ['attributeMetadata', 'availableAttributeValues']),
    ...mapGetters('filters', ['getSelectedFilter']),

    numFiltersApplied() {
      return isEmpty(this.filterRules[0]) ? 0 : this.filterRules.length;
    },

    appliedRules() {
      return filter(this.nonEmptyRules, rule => size(rule.attributeValue));
    },

    availableAttributeMetadata() {
      // in the UI, when a user selects an attribute filter it's removed from the other dropdowns.
      // here we need to keep the newTabRule, so that architectureGroup is selected in the first dropdown.
      let attributeFilters = [];
      if (this.enableAttributeFilters) {
        attributeFilters = this.attributeMetadata.map(attribute => {
          attribute.attributeFilterType = attributeOption;
          return attribute;
        });
      }

      if (this.enableNonAttributeFilters) {
        const productAttributesFilters = this.getProductAttributesFilters();
        attributeFilters = [...attributeFilters, ...productAttributesFilters];
      }

      if (this.enableHierarchyFilters) {
        attributeFilters = [
          ...attributeFilters,
          ...this.getHierarchyFilters({
            level: this.hierarchyFilterLevel,
          }),
        ];
      } else if (this.enableWholesaleHierarchyFilters) {
        const hierarchyFilters = this.getWholesaleHierarchyFilters();
        attributeFilters = [...attributeFilters, ...hierarchyFilters];
      }

      if (this.isFeatureFlagEnabled(useZones)) {
        attributeFilters.push({
          attributeKey: 'toolStoreGroupKey',
          attributeDescription: this.$t('pricing.toolStoreGroup'),
          displayDescription: this.$t('pricing.toolStoreGroup'),
          attributeFilterType: storegroupOption,
        });
      }

      return attributeFilters;
    },
  },

  async created() {
    const filterName = 'newTabArchitectureGroupAttributeFilter';
    const newTabArchitectureGroup = this.getSelectedFilter(filterName);
    this.setNonEmptyRules();

    if (!isEmpty(newTabArchitectureGroup)) {
      this.isNewTab = true;
      const key = random(0, 1, true);
      const rule = {
        ...this.getArchitectureGroupFilterDefaults(),
        attributeValue: [newTabArchitectureGroup],
        newTabRule: true,
      };
      // overwrite default empty filter.
      this.mutableFilterRules = [{ key, rule }];
      // in UI, must pick attribute before picking value. Here we set both in advance, so we need to fetch the data and set nonEmptyRules.
      this.setNonEmptyRules();
      await Promise.all([
        this.updateAttributeFilter({ attributeFilter: rule, fetchData: true }, key),
        // the value from local store is used, so it should be removed now.
        this.resetFilter({ filterName }),
      ]);
      this.isNewTab = false;
    }
  },

  methods: {
    ...mapActions('attributes', ['fetchAvailableAttributeValues']),
    ...mapActions('filters', ['resetFilter']),

    setNonEmptyRules() {
      this.nonEmptyRules = this.mutableFilterRules
        .filter(r => !isEmpty(r.rule))
        .map(r => {
          return pick(r.rule, [
            'attributeKey',
            'attributeFilterType',
            'predicate',
            'attributeValue',
            'displayValue',
            'attributeDescription',
            'displayDescription',
            'newTabRule',
          ]);
        });
    },

    filterRule() {
      return {
        rule: {},
        key: random(0, 1, true),
      };
    },

    addAttributeFilter() {
      this.mutableFilterRules.push(this.filterRule());
    },

    async emitAttributeFilterChange() {
      this.$emit('attributeFilterChange', this.appliedRules);
    },

    async updateRulesWRTAvailableAttributeValue({
      attributeKey = null,
      attributeFilterType = null,
      shouldFetchAvailableAttributes = false,
      attributeDescription = null,
    } = {}) {
      // special case for architecture group filters handled in attribute-filter.vue #formatAndFilterAttributes.
      if (attributeKey !== architectureGroupLevel) {
        forEach(this.mutableFilterRules, ({ rule }) => {
          if (size(rule.attributeValue)) {
            this.$set(
              rule,
              'attributeValue',
              intersection(
                rule.attributeValue,
                map(
                  this.availableAttributeValues[rule.attributeKey],
                  attribute => attribute.attributeKey
                )
              )
            );
          }
        });
      }
      this.setNonEmptyRules();
      if (shouldFetchAvailableAttributes) {
        await this.getAvailableAttributeValues({
          attributeKey,
          attributeFilterType,
          attributeDescription,
        });
      }
    },

    async updateAttributeFilter({ attributeFilter, fetchData }, key) {
      const { attributeKey, attributeFilterType, attributeDescription } = attributeFilter;
      const filterRule = find(this.mutableFilterRules, { key });
      // after changing attribute, if the attribute filter is not currently active, reset its value
      const activeFilterAttributes = this.filterRules.map(attr => attr.attributeKey);
      if (!fetchData && !includes(activeFilterAttributes, attributeFilter.attributeKey)) {
        attributeFilter.attributeValue = [];
      }
      this.$set(filterRule, 'rule', attributeFilter);

      // We should only fetch available values when we select an attribute to filter on.
      // fetchData is set when we have chosen which particular attribute value we want to filter on
      // therefore we already have the available values so we should prevent fetching them again.
      const shouldFetchAvailableAttributes = !fetchData;
      // for new tab created from expanded-product-view, nonEmptyRules has already set in created()
      if (!this.isNewTab) {
        this.updateRulesWRTAvailableAttributeValue({
          shouldFetchAvailableAttributes,
          attributeKey,
          attributeFilterType,
          attributeDescription,
        });
      } else {
        // only run large aggregations and alert queries when autocomplete for filter values is blurred or if a new tab is created from product-reveal
        this.emitAttributeFilterChange();
      }
    },

    async removeAttributeFilter(key) {
      this.mutableFilterRules = filter(this.mutableFilterRules, rule => rule.key !== key);
      if (isEmpty(this.mutableFilterRules)) {
        this.mutableFilterRules = [this.filterRule()];
      }
      this.updateRulesWRTAvailableAttributeValue();
      this.emitAttributeFilterChange();
    },

    // fetch the available attribute values for the newly-selected attribute
    async getAvailableAttributeValues({ attributeKey, attributeFilterType, attributeDescription }) {
      const selectedAttributeMetadata = [
        ...slice(this.nonEmptyRules, 0, -1),
        ...this.fixedFilterRules,
      ];

      return this.fetchAvailableAttributeValues({
        selectedAttributeMetadata,
        selectedAttributeKey: attributeKey,
        selectedAttributeFilterType: attributeFilterType,
        selectedAttributeDescription: attributeDescription,
        selectedAttributeValue: this.filterRule.attributeValue,
      });
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.panel-header {
  background-color: $pricing-filter-bar-color !important;

  &::v-deep .v-label,
  &::v-deep .v-chip__content {
    font-size: 1.2rem;
  }
}

.filter-text {
  color: $pricing-filter-selection-color;
  font-size: 1.25rem;
}

.filter-text-title {
  font-size: 1.25rem;
}
</style>
