<template>
  <v-container fluid>
    <!-- Delete dialog -->
    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      @onOk="runDeleteWorkpackage"
      @onCancel="closeDeleteDialog"
    >
      <template v-slot:header>{{ $t('dialogs.deleteWorkpackage.header') }}</template>
      <template v-slot:body>
        {{ $t('dialogs.deleteWorkpackage.body') }}
        <strong>{{ workpackageToDelete ? workpackageToDelete.description : '' }}</strong>
      </template>
    </alert-dialog>

    <!-- Transfer dialog -->
    <transfer-dialog
      :is-open="isTransferDialogOpen"
      :workpackage-to-transfer="workpackageToTransfer"
      @onCancel="closeTransferDialog"
      @onOk="runTransferWorkpackage"
    />

    <!-- ExportPricing dialog -->
    <alert-dialog
      :is-open="isExportPricingDialogOpen"
      :is-max-height-fixed="true"
      :ok-btn-text="
        useSapExport
          ? $t(
              `workPackages.exportToSAP.${
                sapExportParams.toFTP ? 'exportToSystem' : 'downloadFile'
              }`
            )
          : $t('actions.downloadPricing')
      "
      @onOk="runExport"
      @onCancel="closeExportPricingDialog"
    >
      <template v-slot:header>
        {{
          useSapExport ? $t('workPackages.exportToSAP.title') : $t('dialogs.downloadPricing.header')
        }}
      </template>
      <template v-slot:body>
        <div v-if="!workpackageToExportPricing || exporting(workpackageToExportPricing)">
          {{ $t('general.loadingMessage') }}
        </div>
        <div v-else>
          <div v-if="noProductsGoLiveAfterWorkpackage">
            {{ $t('dialogs.downloadPricing.noProductsGoLiveAfterWP') }}
          </div>
          <div v-else>
            <v-expansion-panels>
              <v-expansion-panel>
                <v-expansion-panel-header>
                  <span class="panel-header">
                    {{ $t('dialogs.downloadPricing.hasProductsGoLiveAfterWP') }}
                  </span>
                  <template v-slot:actions>
                    <v-icon>expand_more</v-icon>
                  </template>
                </v-expansion-panel-header>
                <v-expansion-panel-content>
                  <p
                    v-for="(productKey, index) in sortedProductsGoLiveAfterWorkpackage"
                    :key="`productKey-${index}`"
                    class="mb-1"
                  >
                    {{ productKey }}
                  </p>
                </v-expansion-panel-content>
              </v-expansion-panel>
            </v-expansion-panels>
          </div>
          <br />

          <feature-toggle :toggle="useManualProductGoLiveDate">
            <div v-if="noProductsGoLiveBeforeWorkpackage">
              {{ $t('dialogs.downloadPricing.noProductsGoLiveBeforeWP') }}
            </div>
            <div v-else>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="panel-header">
                      {{ $t('dialogs.downloadPricing.hasProductsGoLiveBeforeWP') }}
                    </span>
                    <template v-slot:actions>
                      <v-icon>expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p
                      v-for="(productKey, index) in sortedProductsGoLiveBeforeWorkpackage"
                      :key="`productKey-${index}`"
                      class="mb-1"
                    >
                      {{ productKey }}
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
            <br />

            <div v-if="noProductsGoLiveBeforeToday">
              {{ $t('dialogs.downloadPricing.noProductsGoLiveBeforeToday') }}
            </div>
            <div v-else>
              <v-expansion-panels>
                <v-expansion-panel>
                  <v-expansion-panel-header>
                    <span class="panel-header">
                      {{ $t('dialogs.downloadPricing.hasProductsGoLiveBeforeToday') }}
                    </span>
                    <template v-slot:actions>
                      <v-icon>expand_more</v-icon>
                    </template>
                  </v-expansion-panel-header>
                  <v-expansion-panel-content>
                    <p
                      v-for="(productKey, index) in sortedProductsGoLiveBeforeToday"
                      :key="`productKey-${index}`"
                      class="mb-1"
                    >
                      {{ productKey }}
                    </p>
                  </v-expansion-panel-content>
                </v-expansion-panel>
              </v-expansion-panels>
            </div>
          </feature-toggle>
        </div>
      </template>
    </alert-dialog>

    <!-- Export API dialog -->
    <alert-dialog
      :is-open="isExportAPIDialogOpen"
      :ok-btn-text="$t('actions.postPricing')"
      @onOk="
        exportWorkpackageResultsToAPI({
          selectedWorkpackage: workpackageToExportAPI,
          apiExportFields,
        })
      "
      @onCancel="closeExportAPIDialog"
    >
      <template v-slot:header>{{ $t('dialogs.exportAPI.header') }}</template>
      <template v-slot:body>
        <p id="export-api-warning">
          <strong>{{ $t('dialogs.exportAPI.warning') }}</strong>
        </p>
        {{ $t('dialogs.exportAPI.body') }}
        <strong>{{ workpackageToExportAPI ? workpackageToExportAPI.description : '' }}</strong>
      </template>
    </alert-dialog>

    <v-row>
      <v-col cols="12" class="heading-bar mb-5">
        <div class="d-flex justify-space-between heading-bar-heading">
          <span>{{ $t(heading) }}</span>
          <span>
            <v-icon size="3rem" @click="toggleWorkpackagesExpansion()">
              {{ getExpansionIcon }}
            </v-icon>
          </span>
        </div>
      </v-col>
    </v-row>
    <v-row>
      <div class="flex scroll">
        <v-data-table
          v-if="workpackagesExpanded"
          class="work-package-table"
          :headers="headers"
          :items="allWorkpackages"
          :search="search"
          :loading-text="$t('general.loadingMessage')"
          :loading="inactiveWps ? inactiveLoading : loading"
          :sort-by.sync="sorting.sortBy"
          :sort-desc.sync="sorting.sortDesc"
          max-height="60rem"
          hide-default-footer
          disable-pagination
          item-key="_id"
          @scroll.native="handleScroll"
          @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table')"
        >
          <template v-slot:no-data>
            <div class="no-data-text">
              <span v-if="!loading" :value="true">
                {{ $t('workPackages.noData') }}
              </span>
            </div>
          </template>
          <template v-slot:item="props">
            <tr
              :class="[isSelected(props.item), isActive(props.item.goLiveDate, props.item.mocked)]"
              class="workpackage white-row"
            >
              <td class="text-xs-left text-bold">
                <!-- mocked is derived prop of workpackagesInCreation -->
                <progress-spinner
                  v-if="props.item.mocked"
                  :job-id="props.item.description"
                  job-type="run_create_workpackage"
                />
                <a class="link-arrow pb-2" e2e-slug="linkWP" @click="selectWorkpackage(props.item)">
                  {{ props.item.description }}
                </a>
                <work-package-reveal v-if="isExpanded(props.index)" :workpackage="props.item" />
              </td>
              <td colspan="2" class="pa-0 col-border-right content-end">
                <table class="inner-table">
                  <tbody>
                    <tr>
                      <td class="pa-1 text-small col-fixed-width col-no-border">
                        {{ getCompletionDate(props.item.completionDate) }}
                      </td>
                      <td class="pa-1 text-small col-fixed-width col-no-border">
                        {{ formatLocalisedDate(props.item.goLiveDate) }}
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pl-1 pb-0 text-small col-no-border">
                        <span v-html="formatStoreFront(props.item.storeFormat)" />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td
                v-for="(unit, i) in getUnitsFromWorkPackage(props.item)"
                :key="`workPackagesUnit${i}`"
                class="col-border-right col-unit pr-0"
              >
                <work-packages-unit
                  :unit-data="unit"
                  :is-expanded="isExpanded(props.index)"
                  :is-selectable="false"
                  :prevent-unit-selection="false"
                />
              </td>
              <!-- Fill in the gaps with blank units -->
              <td
                v-for="n in maxUnitCount - getUnitsFromWorkPackage(props.item).length"
                :key="`blankCell${n}`"
                class="col-border-right col-unit"
              />
              <td class="col-border-left content-end pl-0 pr-0">
                <v-icon
                  v-if="!isExpanded(props.index)"
                  class="pb-1"
                  large
                  e2e-slug="iconExpandWP"
                  @click="toggleExpansion(props.index)"
                >
                  expand_more
                </v-icon>
                <v-icon
                  v-if="isExpanded(props.index)"
                  class="pb-1"
                  large
                  e2e-slug="iconCollapseWP"
                  @click="toggleExpansion(props.index)"
                >
                  expand_less
                </v-icon>
              </td>
            </tr>
            <tr v-if="isExpanded(props.index) && canExecuteWorkpackage" class="button-row">
              <!-- Using colspan here breaks sticky cells -->
              <td class="text-left pt-2 pb-2">
                <v-btn
                  small
                  :disabled="disabled(props.item)"
                  :loading="transferring(props.item)"
                  color="primary"
                  e2e-slug="btnTransferWorkpackage"
                  depressed
                  @click="openTransferDialog(props.item)"
                >
                  {{ $t('workPackages.transferWorkpackage') }}
                </v-btn>
              </td>
              <td :colspan="maxUnitCount" />
              <td colspan="4" class="pt-2 pb-2 text-right">
                <feature-toggle toggle="displayExportPenaltyDataButton">
                  <v-btn
                    small
                    color="primary"
                    :loading="loadingPenaltyExports"
                    class="ml-2"
                    depressed
                    @click="
                      exportPenaltyData({
                        workpackage: props.item,
                      })
                    "
                  >
                    {{ $t('actions.downloadPenaltyData') }}
                  </v-btn>
                </feature-toggle>
                <feature-toggle toggle="displayExportToSAPButton">
                  <sap-export-button
                    :disabled="disabled(props.item) || disableOWUserExport"
                    :loading="exportingToSAP(props.item)"
                    :params="{
                      selectedWorkpackage: props.item,
                      fieldConfigs: exportToSAPFields,
                      exportConfig: exportConfigs.exportToSAP,
                      exportType: exportType.exportToSAP,
                    }"
                    @downloadExportToSAP="triggerDownloadExportToSAP"
                  />
                </feature-toggle>
                <feature-toggle toggle="displayExportToAPIButton">
                  <v-btn
                    small
                    :disabled="disabled(props.item) || disableOWUserExport"
                    :loading="posting(props.item)"
                    color="primary"
                    class="ml-2"
                    e2e-slug="btnPostPricing"
                    depressed
                    @click="openExportAPIDialog(props.item)"
                  >
                    {{ $t('actions.postPricing') }}
                  </v-btn>
                </feature-toggle>
                <span v-if="displayGoLiveDateDialog">
                  <v-btn
                    small
                    :disabled="disabled(props.item)"
                    :loading="exporting(props.item)"
                    color="primary"
                    class="ml-2"
                    e2e-slug="btnExportPricing"
                    depressed
                    @click="openExportPricingDialog(props.item)"
                  >
                    {{ $t('actions.downloadPricing') }}
                  </v-btn>
                </span>
                <span v-else>
                  <v-btn
                    small
                    :disabled="disabled(props.item)"
                    :loading="exporting(props.item)"
                    color="primary"
                    class="ml-2"
                    e2e-slug="btnExportPricing"
                    depressed
                    @click="
                      downloadWorkpackageResults({
                        selectedWorkpackage: props.item,
                        fields,
                        exportType: exportType.exportPricing,
                        columnFormatters,
                      })
                    "
                  >
                    {{ $t('actions.downloadPricing') }}
                  </v-btn>
                </span>

                <v-btn
                  v-if="props.item.type !== workpackageTypes.master"
                  small
                  color="primary"
                  class="ml-2"
                  :disabled="disabled(props.item)"
                  :loading="deleting(props.item)"
                  e2e-slug="btnDeleteWP"
                  depressed
                  @click="openDeleteDialog(props.item)"
                >
                  <v-icon small> fa-trash </v-icon>
                </v-btn>
              </td>
            </tr>
            <tr class="spacer-row">
              <td />
              <td colspan="2" />
              <td :colspan="maxUnitCount + 1" />
            </tr>
          </template>
          <template v-slot:body.prepend="{}">
            <tr v-if="searchWorkpackages" class="white-row">
              <td colspan="2" class="search-container">
                <v-text-field
                  class="search-field p-0"
                  :value="search"
                  append-icon="search"
                  :label="$t('actions.search')"
                  single-line
                  :loading="loading"
                  @input="debounceSearchUpdate"
                />
              </td>
            </tr>
            <tr
              v-show="creationMode && canEditWorkpackage"
              class="workpackage white-row new-workpackage"
            >
              <td class="text-xs-left">
                <v-text-field
                  v-model="newWorkpackage.description"
                  :label="$t('workPackages.description')"
                  single-line
                  class="ma-0 pa-0"
                  :rules="rules.missingDescription"
                />
                {{ $t('workPackages.owner') }}: <strong>{{ profile && profile.username }}</strong>
              </td>
              <td colspan="2" class="pa-0 col-border-right content-end">
                <table class="inner-table">
                  <tbody>
                    <tr>
                      <td class="pa-1 text-small col-fixed-width col-no-border">
                        <v-menu
                          v-model="completionDatePicker"
                          :close-on-content-click="false"
                          :nudge-right="5"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="computedCompletionDate"
                              append-icon="event"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="completionDate"
                            :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                            :locale="i18nconfig.fallbackLocale"
                            @input="completionDatePicker = false"
                          />
                        </v-menu>
                      </td>
                      <td class="pa-1 text-small col-fixed-width col-no-border">
                        <v-menu
                          v-model="goLiveDatePicker"
                          :close-on-content-click="false"
                          :nudge-right="5"
                          transition="scale-transition"
                          offset-y
                        >
                          <template v-slot:activator="{ on }">
                            <v-text-field
                              v-model="computedGoLiveDate"
                              append-icon="event"
                              readonly
                              v-on="on"
                            />
                          </template>
                          <v-date-picker
                            v-model="goLiveDate"
                            :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                            :locale="i18nconfig.fallbackLocale"
                            @input="goLiveDatePicker = false"
                          />
                        </v-menu>
                      </td>
                    </tr>
                    <tr>
                      <td colspan="2" class="pl-1 pb-0 text-small col-no-border">
                        <v-select
                          v-model="newWorkpackage.storeFormat"
                          :label="$t('general.labels.selectDefault')"
                          :items="masterWorkpackages"
                          :rules="rules.hasValue"
                          single-line
                          item-text="storeFormat"
                          @change="setAvailableCategories($event)"
                        />
                      </td>
                    </tr>
                  </tbody>
                </table>
              </td>
              <td
                v-for="(category, i) in availableCategories"
                :key="`category${i}`"
                class="col-border-right col-unit pr-0"
              >
                <work-packages-unit
                  :unit-data="category"
                  :is-expanded="true"
                  :is-selectable="true"
                  :prevent-unit-selection="true"
                  @onCategoriesUpdated="setCategories"
                />
              </td>
              <!-- Fill in the gaps with blank units -->
              <td
                v-for="n in maxUnitCount - availableCategories.length"
                :key="`blankCell${n}`"
                class="col-border-right col-unit"
              />
              <td class="col-border-left content-end pl-0 pr-0" />
            </tr>
            <tr v-show="creationMode && canEditWorkpackage" class="button-row">
              <!-- Using colspan here breaks sticky cells -->
              <td />
              <td :colspan="maxUnitCount + 1" />
              <td :colspan="2" class="pt-2 pb-2 new-workpackage-buttons">
                <v-btn color="primary" outlined small depressed @click="cancelNewWorkPackage()">
                  {{ $t('actions.cancel') }}
                </v-btn>
                <v-btn
                  small
                  :disabled="!isValid"
                  :loading="creating"
                  class="save"
                  color="success"
                  depressed
                  @click="saveNewWorkPackage()"
                >
                  {{ $t('actions.save') }}
                </v-btn>
              </td>
            </tr>
            <tr v-show="creationMode && canEditWorkpackage" class="spacer-row">
              <td />
              <td colspan="2" />
              <td :colspan="maxUnitCount + 1" />
            </tr>
          </template>
        </v-data-table>
      </div>
    </v-row>
    <v-row class="pt-2 pr-2">
      <plus-button
        v-if="allowNew && !creationMode && canEditWorkpackage"
        copy="workPackages.createNew"
        e2e-slug="btnCreateWP"
        @clickFunction="createNewWorkPackage"
      />
    </v-row>
  </v-container>
</template>

<script>
import to from 'await-to-js';
import { mapActions, mapState, mapGetters } from 'vuex';
import { times, max, clone, isEmpty, sortBy, get, debounce } from 'lodash';
import moment from 'moment';
import dateHasPassed from '../../../store/utils/date-checking';
import workpackageTypes from '@enums/workpackage-types';
import { yearMonthDayFormat, utcFormat } from '@enums/date-formats';
import numberFormats from '@enums/number-formats';
import {
  displayExportToSAPButton,
  displayExportToAPIButton,
  owUsersCanExport,
  showGoLiveDateDialog,
  useManualProductGoLiveDate,
} from '@enums/feature-flags';
import exportType from '@enums/export-type';
import sapExportUtilsMixin from '../../../mixins/sapExportUtils';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { preventPageNavigationOnHorizontalScroll } from '../../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [sapExportUtilsMixin, featureFlagsMixin],
  props: {
    heading: {
      type: String,
      required: true,
    },
    filteredWorkpackages: {
      type: Array,
      required: true,
    },
    sortDesc: {
      type: Boolean,
      required: true,
    },
    allowNew: {
      type: Boolean,
      required: true,
    },
    expanded: {
      type: Boolean,
      default: true,
    },
    searchWorkpackages: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      search: '',
      workpackageTypes,
      staticHeaders: [
        {
          text: this.$t('workPackages.description'),
          value: 'description',
          align: 'left',
          sortable: true,
        },
        {
          text: this.$t('workPackages.completionDate'),
          value: 'completionDate',
          align: 'left',
          class: 'col-fixed-width',
        },
        {
          text: this.$t('workPackages.goLiveDate'),
          value: 'goLiveDate',
          align: 'left',
          class: 'col-fixed-width col-border-right content-end',
        },
      ],
      rules: {
        missingDescription: [this.valueRequired],
        hasValue: [this.hasValue],
      },
      exportStatus: null,
      allWorkpackages: this.filteredWorkpackages,
      creationMode: false,
      completionDatePicker: false,
      goLiveDatePicker: false,
      goLiveDate: new Date().toISOString().substr(0, 10),
      completionDate: new Date().toISOString().substr(0, 10),
      availableCategories: [],
      selectedCategories: {},
      newWorkpackage: {},
      isDeleteDialogOpen: false,
      isTransferDialogOpen: false,
      isExportPricingDialogOpen: false,
      isExportAPIDialogOpen: false,
      workpackageToDelete: undefined,
      workpackageToTransfer: {},
      workpackageToExportPricing: undefined,
      workpackageToExportAPI: undefined,
      workpackagesExpanded: this.expanded,
      sorting: {},
      displayExportToSAPButton,
      displayExportToAPIButton,
      exportType,
      unitsToDisplay: 15,
      useManualProductGoLiveDate,
      useSapExport: false,
      sapExportParams: {},
      loadingPenaltyExports: false,
    };
  },

  computed: {
    ...mapState('context', ['profile']),
    ...mapState('clientConfig', ['i18nconfig', 'exportConfigs', 'toggleLogic']),
    ...mapState('workpackages', [
      'loading',
      'inactiveLoading',
      'workpackages',
      'selectedWorkpackage',
      'creating',
      'deletingIds',
      'exportingIds',
      'postingIds',
      'exportingToSAPIds',
      'updatingIds',
      'productsGoLiveBeforeToday',
      'productsGoLiveBeforeWorkpackage',
      'productsGoLiveAfterWorkpackage',
    ]),
    ...mapGetters('workpackages', ['masterWorkpackages', 'isAnyWorkpackageInTransferList']),
    ...mapGetters('context', [
      'isUnitManager',
      'isAdminLoggedIn',
      'isOWUser',
      'canExecuteWorkpackage',
      'canEditWorkpackage',
    ]),

    headers() {
      const unitsHeaders = [];
      times(this.maxUnitCount, i => {
        unitsHeaders.push({
          text: this.$tc(`hierarchy.hierarchyLevels.1`, 1),
          value: `unit${i}`,
          align: 'left',
          sortable: false,
          class: i === this.maxUnitCount - 1 ? 'content-end' : '',
        });
      });
      return [
        ...this.staticHeaders,
        ...unitsHeaders,
        {
          text: '',
          value: `reveal`,
          align: 'left',
          sortable: false,
          class: 'content-end col-border-left',
        },
      ];
    },

    columnFormatters() {
      return {
        productKeyDisplay: {
          type: get(
            this.exportConfigs,
            'exportToExcel.columnFormatter.productKeyDisplay',
            numberFormats.integer
          ),
        },
      };
    },

    maxUnitCount() {
      // we're forcing minimum 4 units
      return max([
        4,
        ...this.workpackages.map(workpackage => {
          const retailUnits = get(workpackage, 'units', []).filter(
            unit => unit.wholesale === false
          );
          return max([retailUnits.length, 4]);
        }),
      ]);
    },

    isValid() {
      return (
        !isEmpty(this.newWorkpackage.storeFormat) &&
        !isEmpty(this.newWorkpackage.description.trim()) &&
        !isEmpty(this.newWorkpackage.categoryIds) &&
        !isEmpty(this.newWorkpackage.workpackageId)
      );
    },

    computedCompletionDate() {
      return this.formatLocalisedDate(this.completionDate);
    },

    computedGoLiveDate() {
      return this.formatLocalisedDate(this.goLiveDate);
    },

    fields() {
      const results = 'workpackage.results';
      return {
        storeFormat: this.$t(`${results}.storeFormat`),
        region: this.$t(`${results}.region`),
        productKeyDisplay: this.$t(`${results}.productKey`),
        date: this.$t(`${results}.date`),
        dateFormat: this.$t(`${results}.dateFormat`),
        price: this.$t(`${results}.price`),
      };
    },

    apiExportFields() {
      const results = 'workpackage.exports.api';
      return {
        storeFormat: this.$t(`${results}.storeFormat`),
        productKeyDisplay: this.$t(`${results}.productKey`),
        date: this.$t(`${results}.date`),
        dateFormat: this.$t(`${results}.dateFormat`),
        price: this.$t(`${results}.price`),
      };
    },

    exportToSAPFields() {
      return this.getSapExportFields();
    },

    getExpansionIcon() {
      return this.workpackagesExpanded ? 'expand_less' : 'expand_more';
    },

    inactiveWps() {
      return this.heading === 'home.archivedHeading';
    },

    disableOWUserExport() {
      return !this.isFeatureFlagEnabled(owUsersCanExport) && this.isOWUser;
    },

    noProductsGoLiveBeforeToday() {
      return isEmpty(this.productsGoLiveBeforeToday);
    },

    noProductsGoLiveBeforeWorkpackage() {
      return isEmpty(this.productsGoLiveBeforeWorkpackage);
    },

    noProductsGoLiveAfterWorkpackage() {
      return isEmpty(this.productsGoLiveAfterWorkpackage);
    },

    displayGoLiveDateDialog() {
      return !!this.toggleLogic[showGoLiveDateDialog];
    },

    sortedProductsGoLiveBeforeToday() {
      return sortBy(this.productsGoLiveBeforeToday);
    },

    sortedProductsGoLiveBeforeWorkpackage() {
      return sortBy(this.productsGoLiveBeforeWorkpackage);
    },

    sortedProductsGoLiveAfterWorkpackage() {
      return sortBy(this.productsGoLiveAfterWorkpackage);
    },
  },

  watch: {
    filteredWorkpackages(value) {
      // this watcher is needed since Data table isn't notified
      // about updates in allWorkpackages (so that WP content is outdated).
      this.allWorkpackages = value;
    },
  },

  created() {
    this.sorting = {
      sortBy: ['goLiveDate', 'description'],
      sortDesc: [this.sortDesc, false],
    };
  },

  methods: {
    ...mapActions('workpackages', [
      'setUserSelectionWorkpackage',
      'downloadWorkpackageResults',
      'exportWorkpackageResultsToAPI',
      'downloadExportToSAP',
      'createWorkpackage',
      'deleteWorkpackage',
      'addWorkpackageToExportingList',
      'removeWorkpackageFromExportingList',
      'transferWorkpackage',
      'previewWorkpackageResults',
      'downloadPenaltyExportData',
    ]),

    debounceSearchUpdate: debounce(function(value) {
      this.search = value;
    }, clientConfig.inputDebounceValue),

    async exportPenaltyData({ workpackage }) {
      this.loadingPenaltyExports = true;
      const [err] = await to(this.downloadPenaltyExportData({ workpackage }));
      if (err) this.loadingPenaltyExports = false;
      this.loadingPenaltyExports = false;
    },

    triggerDownloadExportToSAP(event) {
      if (this.displayGoLiveDateDialog) {
        this.useSapExport = true;
        this.sapExportParams = event;
        const workpackage = event.selectedWorkpackage;
        this.openExportPricingDialog(workpackage);
      } else {
        this.downloadExportToSAP(event);
      }
    },

    callDownloadExportToSAP() {
      this.isExportPricingDialogOpen = false;
      this.downloadExportToSAP(this.sapExportParams);
      this.useSapExport = false;
      this.sapExportParams = {};
    },

    handleScroll(event) {
      const isUserScrollingHorizontally = event.target.scrollLeft > 0;
      if (isUserScrollingHorizontally) {
        // The Magic Number 9 used below ensures that we never see blank units
        // ie the next units to be shown are loaded before we scroll there
        let percentageScrolled = event.target.scrollLeft / event.target.scrollWidth;
        // event.target.scrollLeft will never equal event.target.scrollWidth
        if (percentageScrolled > 0.9) percentageScrolled = 1;
        const newUnitsToDisplay = Math.round(this.maxUnitCount * percentageScrolled) + 9;
        this.unitsToDisplay =
          newUnitsToDisplay > this.unitsToDisplay - 9 ? newUnitsToDisplay : this.unitsToDisplay;
      }
    },

    deleting(workpackage) {
      return this.deletingIds.includes(workpackage._id);
    },

    transferring(workpackage) {
      return this.isAnyWorkpackageInTransferList([workpackage._id]);
    },

    exportingToSAP(workpackage) {
      return this.exportingToSAPIds.includes(workpackage._id);
    },

    exporting(workpackage) {
      return this.exportingIds.includes(workpackage._id);
    },

    posting(workpackage) {
      return this.postingIds.includes(workpackage._id);
    },

    updating(workpackage) {
      return this.updatingIds.includes(workpackage._id);
    },

    disabled(workpackage) {
      return (
        this.exporting(workpackage) ||
        this.posting(workpackage) ||
        this.exportingToSAP(workpackage) ||
        this.updating(workpackage) ||
        this.deleting(workpackage) ||
        this.transferring(workpackage)
      );
    },

    openExportPricingDialog(workpackage) {
      this.workpackageToExportPricing = workpackage;
      const params = {
        selectedWorkpackage: workpackage,
        fields: this.fields,
        exportType: exportType.exportPricing,
      };
      this.previewWorkpackageResults(params);

      this.isExportPricingDialogOpen = true;
    },

    closeExportPricingDialog() {
      this.useSapExport = false;
      this.sapExportParams = {};
      this.isExportPricingDialogOpen = false;
    },

    openExportAPIDialog(workpackage) {
      this.workpackageToExportAPI = workpackage;
      this.isExportAPIDialogOpen = true;
    },

    closeExportAPIDialog() {
      this.workpackageToExportAPI = undefined;
      this.isExportAPIDialogOpen = false;
    },

    runExportPricing() {
      this.isExportPricingDialogOpen = false;
      const params = {
        selectedWorkpackage: this.workpackageToExportPricing,
        fields: this.fields,
        exportType: exportType.exportPricing,
        columnFormatters: this.columnFormatters,
      };
      this.downloadWorkpackageResults(params);
    },

    runExport() {
      if (this.useSapExport) return this.callDownloadExportToSAP();
      return this.runExportPricing();
    },

    openDeleteDialog(workpackage) {
      this.workpackageToDelete = workpackage;
      this.isDeleteDialogOpen = true;
    },

    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
      this.workpackageToDelete = undefined;
    },

    openTransferDialog(workpackage) {
      this.workpackageToTransfer = workpackage;
      this.isTransferDialogOpen = true;
    },

    closeTransferDialog() {
      this.isTransferDialogOpen = false;
      this.workpackageToTransfer = {};
    },

    async runTransferWorkpackage(event) {
      const { selectedFields, destinationWorkpackage, selectedCategories } = event;
      this.isTransferDialogOpen = false;
      this.transferWorkpackage({
        destinationWorkpackageId: destinationWorkpackage._id,
        sourceWorkpackageId: this.workpackageToTransfer._id,
        fields: selectedFields,
        selectedCategories,
      });
      this.workpackageToTransfer = {};
    },

    async runDeleteWorkpackage() {
      this.isDeleteDialogOpen = false;
      await this.deleteWorkpackage(this.workpackageToDelete);
    },

    formatStoreFront(storeFront) {
      const hierarchyLevel0 = this.$t('hierarchy.hierarchyLevels.0');
      const nStart = storeFront.indexOf(hierarchyLevel0);
      const nEnd = nStart + hierarchyLevel0.length;

      if (nStart === -1) {
        return storeFront;
      }

      return `${storeFront.slice(0, nStart)}<b>${storeFront.slice(
        nStart,
        nEnd
      )}</b>${storeFront.slice(nEnd)}`;
    },

    isSelected(workpackage) {
      return workpackage._id === this.selectedWorkpackage._id ? 'selected' : '';
    },

    isActive(date, mocked) {
      return dateHasPassed(date) || mocked ? 'archived' : '';
    },

    isExpanded(i) {
      return this.allWorkpackages[i].isExpanded === true;
    },

    valueRequired(v) {
      return !!v || v === 0 || this.$t('settings.parameters.errors.fieldInvalid');
    },

    hasValue(v) {
      return !!v || v === 0 || this.$t('settings.parameters.errors.fieldInvalid');
    },

    getCompletionDate(completionDate) {
      return completionDate ? this.formatLocalisedDate(completionDate) : '-';
    },

    toggleExpansion(i) {
      const workpackage = this.allWorkpackages[i];
      if (get(workpackage, 'mocked')) return;
      workpackage.isExpanded =
        workpackage.isExpanded === undefined ? true : !workpackage.isExpanded;
      this.$set(this.allWorkpackages, i, workpackage);
    },

    toggleWorkpackagesExpansion() {
      this.workpackagesExpanded = !this.workpackagesExpanded;
      if (this.workpackagesExpanded) this.$emit('expand');
    },

    async selectWorkpackage(workpackage) {
      if (get(workpackage, 'mocked')) return;
      await this.setUserSelectionWorkpackage({ selectedWorkpackageId: workpackage._id });
      if (this.isUnitManager && !this.isAdminLoggedIn) {
        this.$router.push({ path: 'pricing/impact-view' });
      } else {
        this.$router.push({ path: 'pricing/desk-view' });
      }
    },

    createNewWorkPackage() {
      this.resetNewWorkPackage();
      this.creationMode = true;
      this.$el.querySelector('.scroll').scrollTop = 0;
    },

    async saveNewWorkPackage() {
      this.newWorkpackage.owner = this.profile.username;

      this.newWorkpackage.completionDate = moment(this.completionDate).format(utcFormat);

      // YYYY-MM-DD format is required for analytics
      this.newWorkpackage.goLiveDate = moment(this.goLiveDate).format(yearMonthDayFormat);

      await this.createWorkpackage(this.newWorkpackage);
      this.creationMode = false;
    },

    cancelNewWorkPackage() {
      this.creationMode = false;
      this.resetNewWorkPackage();
    },

    setCategories(unit, categories) {
      const clonedSelectedCategories = clone(this.selectedCategories);
      clonedSelectedCategories[unit] = categories;
      this.selectedCategories = clonedSelectedCategories;

      let categoryIds = [];
      Object.keys(clonedSelectedCategories).forEach(function(key) {
        categoryIds = categoryIds.concat(clonedSelectedCategories[key]);
      });
      this.newWorkpackage.categoryIds = categoryIds;
    },

    setAvailableCategories(selectedStoreFormat) {
      const workpackage = this.masterWorkpackages.find(
        ({ storeFormat }) => storeFormat === selectedStoreFormat
      );

      this.availableCategories = this.getUnitsFromWorkPackage(workpackage, true);
      this.newWorkpackage.workpackageId = workpackage._id;
      this.newWorkpackage.categoryIds = [];
      this.selectedCategories = {};
    },

    resetNewWorkPackage() {
      this.newWorkpackage = {
        goLiveDate: new Date().toISOString().substr(0, 10),
        completionDate: new Date().toISOString().substr(0, 10),
        storeFormat: undefined,
        description: undefined,
        owner: undefined,
        categoryIds: undefined,
        workpackageId: undefined,
      };
      this.availableCategories = [];
      this.selectedCategories = {};
      this.goLiveDate = new Date().toISOString().substr(0, 10);
      this.completionDate = new Date().toISOString().substr(0, 10);
    },

    getUnitsFromWorkPackage(item, creation = false) {
      const retailUnits = get(item, 'units', []).filter(unit => unit.wholesale === false);
      if (creation) {
        return sortBy(retailUnits, 'levelEntryDescription');
      }
      return sortBy(retailUnits, 'levelEntryDescription').slice(0, this.unitsToDisplay);
    },

    preventPageNavigationOnHorizontalScroll,
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.search-container {
  height: 4.5rem !important;
  border: none !important;
}

.search-field {
  position: absolute;
  min-width: 250px;
}

%sticky-column {
  position: -webkit-sticky;
  /* for Safari */
  position: sticky;
  z-index: 1;
}

@mixin column-width($width) {
  min-width: $width;
  width: $width;
}

#export-api-warning {
  color: $pricing-red;
}

.scroll {
  max-height: 50rem;
  overflow: scroll;
}

.work-package-table.v-data-table {
  overflow: scroll;
  position: relative;
  max-width: 99%;

  .content-end.col-border-right {
    -webkit-box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
    box-shadow: 4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  }

  .content-end.col-border-left {
    -webkit-box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
    -moz-box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
    box-shadow: -4px 0px 0px 0px rgba(0, 0, 0, 0.14);
  }

  .col-border-left {
    border-left: 0.1rem solid $pricing-grey-dark !important;
  }

  .v-data-table__wrapper {
    table {
      border-collapse: separate;
      /* Don't collapse */
      $first-column-width: 27rem;
      $dates-column-width: 10rem;
      $second-column-width: $dates-column-width;
      $third-column-width: $dates-column-width;
      $first-column-offset: 0;
      $second-column-offset: $first-column-offset + $first-column-width;
      $third-column-offset: $second-column-offset + $second-column-width;

      thead th,
      tbody td {
        &:nth-child(1) {
          @extend %sticky-column;
          @include column-width($first-column-width);
          left: $first-column-offset;
        }

        &:nth-child(2) {
          @extend %sticky-column;
          @include column-width($second-column-width);
          left: $second-column-offset;
        }

        &:last-child {
          @extend %sticky-column;
          @include column-width(6rem);
          right: 0;
        }
      }

      thead th {
        &:nth-child(3) {
          @extend %sticky-column;
          @include column-width($third-column-width);
          left: $third-column-offset;
        }
      }

      thead {
        tr {
          height: 2.2rem;
          font-size: 2.2rem;
        }

        th {
          transform: scale(0.999);
          background-color: $pricing-background;
          padding: 0 0.5rem;
          height: unset;

          &:not(:last-child) {
            border-right: 0.1rem solid $pricing-grey-dark;
          }
        }

        .v-datatable__progress {
          background-color: $pricing-grey-dark;
        }
      }

      tbody {
        tr {
          &.workpackage td:not(.col-no-border) {
            border-top: 0.2rem solid $pricing-grey-dark;
          }

          &.selected td:not(.col-no-border) {
            border-top: 0.2rem solid $workPackagesStatusPending;
          }

          &.white-row td {
            background: $pricing-white;
          }

          &.spacer-row {
            background: $pricing-background;
          }

          &.spacer-row:last-child {
            td {
              border: 0;
              -webkit-box-shadow: unset;
              -moz-box-shadow: unset;
              box-shadow: unset;
            }
          }

          &.button-row td {
            background: $pricing-white;
            text-align: right;
          }
        }

        td {
          color: $workPackagesTableTextColor;
          height: 2.2rem;
          font-size: 1.4rem;
          vertical-align: top;

          &:not(.col-no-border) {
            border-bottom: 0.1rem solid $pricing-grey-dark;
          }

          &.text-small {
            font-size: 1.2rem;
          }

          &.text-bold {
            font-weight: 600;
          }

          &.col-fixed-width {
            min-width: $dates-column-width;
          }

          &.col-unit {
            min-width: 31rem;
          }

          &.col-border-right:not(:last-child) {
            border-right: 0.1rem solid $pricing-grey-dark !important;
          }

          &:first-child {
            vertical-align: top;
            padding: 5px 10px;
          }

          .inner-table td {
            min-width: $dates-column-width;
            max-width: $dates-column-width;
            border-bottom: unset;
          }
        }
      }
    }
  }

  .workpackage.archived {
    color: $pricing-grey-dark !important;

    a {
      color: $pricing-grey-dark !important;
    }

    .v-icon {
      color: $pricing-grey-dark;
    }
  }

  .no-data-text {
    width: 29rem;
    font-size: 1.2rem;
    padding: 1rem 0;
    white-space: normal;
  }

  .v-text-field {
    padding: unset;
    margin: unset;
  }

  .new-workpackage {
    .v-icon {
      font-size: 1.5rem;
    }
  }

  .new-workpackage-buttons {
    min-width: 20rem !important;
  }
}

.panel-header {
  font-size: 1.4rem;
}
</style>
