<template>
  <!-- eslint-disable vue/no-unused-vars -->
  <v-expansion-panel class="mb-5">
    <v-expansion-panel-header class="line-pricing-group pt-0 pb-0 pl-0" @click="preventExpand">
      <v-col cols="7">
        <v-text-field
          v-model="linePricingGroup.linePricedGroupDescription"
          class="pt-0 pb-0 highlight"
          :rules="rules.missingDescription"
          :disabled="disabled"
          @keyup.space="preventExpand"
        />
      </v-col>
      <v-col>
        <span class="float-right">
          <v-icon v-if="linePricingGroup.products.length && subGroupValidationError" color="red">
            mdi-alert-circle-outline
          </v-icon>
        </span>
      </v-col>
      <template v-slot:actions>
        <v-icon>expand_more</v-icon>
      </template>
    </v-expansion-panel-header>
    <v-expansion-panel-content class="line-pricing-group">
      <v-col cols="12" class="pt-0">
        <v-data-table
          class="line-priced-products products-table"
          :items="linePricingGroupProducts"
          :options.sync="pagination"
          item-key="_id"
          :sort-desc.sync="pagination.descending"
          hide-default-footer
        >
          <!-- Keep v-slot:header="props" so that header is accessible from the child -->
          <template v-if="linePricingGroup.products.length > 0" v-slot:header="props">
            <tr class="v-data-table-header">
              <!-- empty th above delete icon -->
              <th />
              <th
                v-for="header in headers"
                :key="header.text"
                :class="getHeaderClasses(header, pagination)"
                :style="header.style"
                class="column"
                @click="header.sortable ? changeSort(header.value) : ''"
              >
                {{ header.text }}
                <v-icon v-if="header.sortable" small class="v-data-table-header__icon">
                  arrow_upward
                </v-icon>
              </th>
            </tr>
          </template>
          <template v-slot:item="props">
            <tr :key="props.item._id" class="product-row">
              <td>
                <v-icon class="delete-icon" :disabled="disabled" @click="deleteProduct(props.item)">
                  delete
                </v-icon>
              </td>
              <td class="text-xs-left">{{ props.item.productKeyDisplay }}</td>
              <td v-if="isFeatureFlagEnabled(useZones)" class="text-xs-left">
                <tooltipped-truncated-field
                  :text="storegroupKeyNameMap[props.item.toolStoreGroupKey]"
                  :truncation-length="truncationLength.toolStoreGroupDescription"
                />
              </td>
              <td class="text-xs-left text-bold description-cell">
                <tooltipped-truncated-field
                  :text="props.item.description"
                  :truncation-length="truncationLength.linePricedProducts"
                />
              </td>
              <td class="text-xs-left">{{ props.item.size }}</td>
              <td class="text-xs-center">{{ props.item.packageTypeDescription }}</td>
              <td class="text-xs-center">
                {{
                  formatNumber({
                    number: getIntentionCost(props.item),
                    format: numberFormats.priceFormat,
                    nullAsDash: true,
                  })
                }}
              </td>
              <td class="text-xs-center">
                {{
                  formatNumber({
                    number: getLivePrice(props.item),
                    format: numberFormats.priceFormat,
                    nullAsDash: true,
                  })
                }}
              </td>
              <td>{{ props.item.subGroup }}</td>
              <td class="line-price-factor">
                <!-- <form novalidate> is required to disable browser native validation  -->
                <!-- if "step"="0.1" and a user enters 0.13, the following notification will be displayed:  -->
                <!-- please enter a valid value. the two nearest valid values are 0.1 and 0.2 -->
                <form novalidate>
                  <v-text-field
                    v-model.number="props.item.linePriceFactor"
                    :rules="rules.linePriceFactorRules"
                    :disabled="disabled"
                    type="number"
                    min="0.1"
                    step="0.1"
                    class="pt-0 pb-0 pr-2 highlight line-price-factor-input"
                  />
                </form>
              </td>
            </tr>
          </template>
          <template v-slot:no-data>
            {{ $t('settings.architecture.noDataLinePriceGroup') }}
          </template>
        </v-data-table>
      </v-col>
      <v-row>
        <v-col class="text-right pt-0">
          <span
            v-if="linePricingGroup.products.length && subGroupValidationError"
            class="validation-fail"
          >
            {{ $t('settings.architecture.differentSubGroupValidationError') }}
          </span>
          <v-btn
            class="delete-btn text-right"
            text
            icon
            :disabled="disabled"
            depressed
            @click="openDeleteDialog()"
          >
            <v-icon>delete</v-icon>
          </v-btn>
        </v-col>
      </v-row>
    </v-expansion-panel-content>
    <!-- Delete dialog -->
    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      @onOk="deleteLinePricingGroupHandler(true)"
      @onCancel="deleteLinePricingGroupHandler(false)"
    >
      <template v-slot:header>{{ $t('dialogs.deleteLinePricingGroup.header') }}</template>
      <template v-slot:body>
        {{ $t('dialogs.deleteLinePricingGroup.body') }}
        <strong>{{ linePricingGroup ? linePricingGroup.linePricedGroupDescription : '' }}</strong>
      </template>
    </alert-dialog>
  </v-expansion-panel>
</template>

<script>
import { map, isEqual } from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { truncationLength } from '@sharedModules/config/products-grid';
import priceTypes from '@enums/price-types';
import getPriceFieldMixin from '../../../../mixins/getPriceField';
import numberFormats from '@enums/number-formats';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import tablesMixin from '../../../../mixins/tables';
import { useZones } from '@enums/feature-flags';

export default {
  mixins: [getPriceFieldMixin, featureFlagsMixin, tablesMixin],
  props: {
    linePricingGroup: {
      type: Object,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      rules: {
        linePriceFactorRules: [this.valueRequired, this.valueIsPositive],
        missingDescription: [this.valueRequired],
      },
      pagination: {
        sortBy: ['productKeyDisplay'],
        itemsPerPage: -1,
        descending: false,
      },
      articleHeaders: [
        {
          text: this.$t('settings.architecture.productKey'),
          sortable: true,
          value: 'productKeyDisplay',
        },
        {
          text: this.$t('settings.architecture.toolStoreGroup'),
          sortable: true,
          value: 'toolStoreGroupDescription',
          featureFlag: useZones,
        },
        {
          text: this.$t('settings.architecture.productDescription'),
          sortable: true,
          value: 'description',
        },
        {
          text: this.$t('settings.architecture.productSize'),
          value: 'size',
          sortable: true,
        },
        {
          text: this.$t('settings.architecture.packageTypeDescription'),
          value: 'packageTypeDescription',
          sortable: true,
        },
      ],
      linePricedHeaders: [
        {
          text: this.$t(
            'settings.setArchitectureRules.architectureDriversRules.productTable.subGroup'
          ),
          sortable: true,
          value: 'subGroup',
        },
        {
          text: this.$t('settings.architecture.LinePriceFactorHeader'),
          value: 'linePriceFactor',
        },
      ],
      truncationLength,
      isDeleteDialogOpen: false,
      numberFormats,
      useZones,
    };
  },

  computed: {
    ...mapGetters('hierarchy', ['getSubGroupsForProduct']),
    ...mapGetters('architectureGroup', ['hasMultipleSubGroups']),
    ...mapState('filters', ['selectedPriceType']),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),

    subGroupValidationError() {
      return this.hasMultipleSubGroups(this.linePricingGroup.products);
    },
    linePricingGroupProducts() {
      return map(this.linePricingGroup.products, product => {
        product.subGroup = this.getSubGroupsForProduct({
          productKey: product.productKey,
          toolStoreGroupKey: product.toolStoreGroupKey,
        });
        product.productToolStoreGroupKey = `${product.productKey}::${product.toolStoreGroupKey}`;
        return product;
      });
    },

    getLivePriceField() {
      // TODO PRICE-308:
      // when this component is changed to use aggregated-scenario-results, remove this function and use the mixin
      if (this.selectedPriceType === priceTypes.uom) return `pricePerContentUnit`;
      if (this.selectedPriceType === priceTypes.normWeight) return `pricePerNormWeight`;
      return `price`;
    },

    filteredArticlesHeaders() {
      return this.filterArrayByFeatureFlag(this.articleHeaders);
    },

    headers() {
      const dynamicHeaders = [
        {
          text: this.$t('settings.architecture.costPrice'),
          sortable: true,
          value: `intentionCost.${this.getPriceField('price')}`,
        },
        {
          text: this.$t('settings.architecture.livePrice'),
          sortable: true,
          value: `livePrice.${this.getPriceField('price')}`,
        },
      ];

      const headers = [
        ...this.filteredArticlesHeaders,
        ...dynamicHeaders,
        ...this.linePricedHeaders,
      ];
      return headers;
    },
  },

  methods: {
    deleteProduct(linePricedProduct) {
      this.$emit('deleteLinePricedProduct', { linePricedProduct });
    },

    deleteLinePricingGroup() {
      this.$emit('deleteLinePricingGroup', { linePricingGroup: this.linePricingGroup });
    },

    deleteLinePricingGroupHandler(shouldDelete = false) {
      if (shouldDelete) {
        this.deleteLinePricingGroup();
      }

      this.isDeleteDialogOpen = false;
    },

    preventExpand(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    valueRequired(v) {
      return !!v || v === 0 || this.$t('settings.parameters.errors.fieldInvalid');
    },

    valueIsPositive(v) {
      return v > 0 || this.$t('settings.parameters.errors.fieldInvalid');
    },

    openDeleteDialog() {
      this.isDeleteDialogOpen = true;
    },

    changeSort(column) {
      if (isEqual(this.pagination.sortBy, [column])) {
        this.pagination.descending = !this.pagination.descending;
        return;
      }
      this.pagination.sortBy = [column];
      this.pagination.descending = false;
    },

    getLivePrice(item) {
      const field = this.getLivePriceField;
      return item.livePrice[field];
    },

    getIntentionCost(item) {
      const field = this.getLivePriceField;
      return item.intentionCost[field];
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
$line-price-factor-min-width: 120px;

.validation-fail {
  color: red;
  font-size: 1.4rem;
  vertical-align: middle;
}

.theme--light.line-priced-products.v-data-table {
  border-bottom: 0rem;

  .v-data-table__wrapper {
    position: relative;
  }

  table {
    th {
      &.line-price-factor-header {
        min-width: $line-price-factor-min-width;
      }
    }
    tbody {
      td {
        &.line-price-factor {
          padding: 0;
          width: 5rem;
          background: $pricing-background;

          .line-price-factor-input {
            min-width: $line-price-factor-min-width;
            input {
              background: $pricing-white;
            }
          }

          .v-text-field__details {
            position: absolute;
            right: 0;
          }

          .v-input {
            padding-top: 0;
            height: 2.2rem;
            font-size: 1.2rem;

            .v-text-field__slot input {
              padding: 0;
              padding-left: 0.5rem;
            }
          }
        }
      }
    }
  }
}

.v-expansion-panel--active > .line-pricing-group.v-expansion-panel-header {
  border-bottom: 0.1rem solid #9b9b9b;
  margin-bottom: 1.5rem;
}
</style>
