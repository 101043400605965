import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        VDialog,
        {
          attrs: {
            "content-class": _vm.dialogClass,
            persistent: "",
            "max-width": "40rem",
          },
          model: {
            value: _vm.isOpen,
            callback: function ($$v) {
              _vm.isOpen = $$v
            },
            expression: "isOpen",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                { staticClass: "headline" },
                [_vm._t("header")],
                2
              ),
              _vm._v(" "),
              _c(VCardText, { staticClass: "body" }, [_vm._t("body")], 2),
              _vm._v(" "),
              _c(
                VCardActions,
                { staticClass: "btn-group" },
                [
                  _c(VSpacer),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "cancel-btn",
                      attrs: { text: "", outlined: "", depressed: "" },
                      on: { click: _vm.emitCancel },
                    },
                    [_vm._v(_vm._s(_vm.cancelBtnText))]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "ok-btn",
                      attrs: { disabled: _vm.disableOkButton, depressed: "" },
                      on: { click: _vm.emitOk },
                    },
                    [_vm._v(_vm._s(_vm.okBtnText))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }