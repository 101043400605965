<template>
  <div>
    <v-layout row>
      <!-- Select the attribute to filter on -->
      <v-flex xs4 class="pr-2">
        <v-autocomplete
          v-model="selectedAttribute"
          :disabled="selectedAttribute.disabled"
          dense
          solo
          flat
          hide-details
          return-object
          :value="filterRule"
          :items="availableFilters"
          :label="$t('attributes.filters.attributeLabel')"
          item-text="displayDescription"
          color="indigo darken-2"
          class="mb-1"
          height="30"
          @input="filterAttributes(selectedAttributeValues, (fetchData = false))"
        >
          <template v-slot:no-data>
            <v-alert :value="true" color="error" icon="warning" outlined>
              {{ $t('attributes.filters.noData') }}
            </v-alert>
          </template>
          <template v-slot:item="props">
            <span class="item-text">
              {{ props.item.displayDescription }}
            </span>
          </template>
        </v-autocomplete>
      </v-flex>

      <!-- Select the value to filter on -->
      <v-flex xs7>
        <v-autocomplete
          v-model="selectedAttribute.attributeValue"
          multiple
          dense
          solo
          flat
          small-chips
          hide-details
          :items="availableFilterValues"
          :disabled="noFilterSelected || selectedAttribute.disabled"
          :label="$t('attributes.filters.valueLabel')"
          :loading="loadingAvailableValues"
          item-value="attributeKey"
          item-text="attributeValue"
          color="indigo darken-2"
          :search-input.sync="searchInput"
          @input="filterAttributes"
          @update:search-input="searchInputHandler"
          @paste="pasteInputHandler"
          @blur="$emit('filter-blurred')"
        >
          <template v-slot:selection="{ item, index, selected }">
            <!-- Display only the first selections -->
            <v-chip
              v-if="index < maxItemsToList"
              :input-value="selected"
              close
              class="chip--select"
              :color="
                (item.attributeValue || []).includes(errorMapping.missingStoreGroup) ? 'red' : ''
              "
              @click:close="deselect(item)"
            >
              {{ item.attributeValue | truncate(truncationLength) }}
            </v-chip>
            <span v-if="index === maxItemsToList">
              {{
                $tc(
                  'attributes.filters.numValuesSelected',
                  selectedAttributeValues.size - maxItemsToList
                )
              }}
            </span>
          </template>
          <template v-slot:no-data>
            <div v-if="!loadingAvailableValues">
              <v-alert :value="true" color="error" icon="warning" outlined>
                {{ $t('attributes.filters.noData') }}
              </v-alert>
            </div>
            <div v-else>
              <v-alert :value="true" color="info" icon="info" outlined>
                {{ $t('general.loadingMessage') }}
              </v-alert>
            </div>
          </template>
          <template v-slot:item="props">
            <span class="item-text">
              {{ props.item.attributeValue }}
            </span>
          </template>
        </v-autocomplete>
      </v-flex>
      <v-flex xs1 class="pt-2">
        <!-- Remove this filter -->
        <v-btn
          :disabled="selectedAttribute.disabled"
          icon
          small
          text
          color="red darken-3"
          class="ml-2"
          depressed
          @click="removeAttributeFilter"
        >
          <v-icon v-once color="red darken-3" size="2rem" class="font-weight-bold">clear</v-icon>
        </v-btn>
      </v-flex>
    </v-layout>
  </div>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import moment from 'moment';
import sortStoreGroups from '../utils/sort-store-groups-util';
import filterPredicates from '@enums/filter-predicates';
import filterOptions from '@enums/filter-options';
import DataTypes from '@enums/data-types';
import {
  globalRule,
  defaultRule,
  pricingGroupRule,
  priceGroupOverrideRule,
} from '@enums/rule-type';
import numberFormats from '@enums/number-formats';
import { yearMonthDayFormat } from '@enums/date-formats';
import attributeScope from '@enums/attribute-scope';
import { architectureGroupLevel } from '@enums/hierarchy';
import { errorMapping } from '@enums/attribute-filters';

const {
  isEmpty,
  sortBy,
  toLower,
  filter,
  cloneDeep,
  size,
  includes,
  partition,
  isNull,
  isNaN,
  uniq,
  compact,
} = require('lodash');

export default {
  props: {
    rulesetType: {
      type: String,
      required: true,
    },
    filterRule: {
      type: Object,
      required: false,
      default: () => {},
    },
    availableAttributeMetadata: {
      type: Array,
      required: true,
    },
    selectedAttributeMetadata: {
      type: Array,
      required: true,
    },
  },

  data() {
    return {
      selectedAttribute: {},
      truncationLength: 25,
      maxItemsToList: 5,
      searchInput: null,
      pasteInput: null,
      errorMapping,
    };
  },

  computed: {
    ...mapGetters('hierarchy', ['agChildrenForCurrentPricingGroup']),
    ...mapState('hierarchy', ['hierarchy']),
    ...mapState('workpackages', ['selectedWorkpackageToolStoreGroups']),
    ...mapState('attributes', ['availableAttributeValues', 'loadingAvailableValues']),
    ...mapState('clientConfig', [
      'toolStoreGroupConfig',
      'storeGroupOrderConfig',
      'dateFormats',
      'toggleLogic',
    ]),
    isGlobalScopeRule() {
      return [globalRule, defaultRule].includes(this.rulesetType);
    },
    isPricingGroupScopeRule() {
      return [pricingGroupRule, priceGroupOverrideRule].includes(this.rulesetType);
    },

    selectedAttributeValues() {
      return new Set(this.selectedAttribute.attributeValue || []);
    },

    noFilterSelected() {
      // Disable the value selection until an attribute is selected
      return isEmpty(this.selectedAttribute);
    },

    availableFilters() {
      let filteredAttributeMetadata;

      // Remove duplicated attributes if these are already selected in other filters
      // However we need to keep the currently selected attribute in the list of metadata
      const currentAttributeKey = !isEmpty(this.filterRule) ? this.filterRule.attributeKey : null;
      const disallowedAttributeKeys = this.selectedAttributeMetadata
        .filter(attribute => attribute.attributeKey !== currentAttributeKey)
        .map(attribute => attribute.attributeKey);
      const filteredMetadata = this.availableAttributeMetadata.filter(
        element => !disallowedAttributeKeys.includes(element.attributeKey)
      );

      if (this.isGlobalScopeRule) {
        // only allow hierarchy and global attributes in global/default rules.
        filteredAttributeMetadata = filteredMetadata.filter(
          attrMeta =>
            attrMeta.scope === attributeScope.global ||
            attrMeta.attributeFilterType === filterOptions.hierarchyOption ||
            attrMeta.attributeFilterType === filterOptions.storegroupOption
        );
      } else {
        filteredAttributeMetadata = filteredMetadata;
      }

      const hierarchyOptions = filter(filteredAttributeMetadata, {
        attributeFilterType: filterOptions.hierarchyOption,
      });
      const wholesaleHierarchyOptions = filter(filteredAttributeMetadata, {
        attributeFilterType: filterOptions.wholesaleHierarchyOption,
      });
      const productAttributesOptions = filter(filteredAttributeMetadata, {
        attributeFilterType: filterOptions.productKeyDisplayOption,
      });
      const storegroupOptions = filter(filteredAttributeMetadata, {
        attributeFilterType: filterOptions.storegroupOption,
      });

      const toolStoreGroupTranslation = this.toolStoreGroupConfig.name;
      const attributeOptions = sortBy(
        filter(filteredAttributeMetadata, option => {
          const isAttribute = option.attributeFilterType === filterOptions.attributeOption;
          const isNotToolStoreGroupAttr = option.displayDescription !== toolStoreGroupTranslation;

          return isAttribute && isNotToolStoreGroupAttr;
        }),
        attribute => toLower(attribute.displayDescription)
      );

      // Product key display first, followed by store group, hierarchy options and other attribute options
      const res = [
        ...storegroupOptions,
        ...productAttributesOptions,
        ...hierarchyOptions,
        ...wholesaleHierarchyOptions,
        ...attributeOptions,
      ];

      return res;
    },

    availableFilterValues() {
      const { attributeKey } = this.selectedAttribute;
      if (attributeKey && !isEmpty(this.availableAttributeValues[attributeKey])) {
        const attributeValues = cloneDeep(this.availableAttributeValues[attributeKey]);
        if (this.isStoreGroupAttribute(this.selectedAttribute)) {
          return sortStoreGroups(attributeValues, this.storeGroupOrderConfig, 'attributeValue');
        }
        return this.formatAndFilterAttributes({ attributeValues });
      }
      return [];
    },

    availableFilterAttributeKeys() {
      return new Set(this.availableFilterValues.map(attribute => attribute.attributeKey));
    },
  },

  async mounted() {
    this.selectedAttribute = this.filterRule;
    await this.fetchAvailableAttributesForSaveRule();
  },

  methods: {
    ...mapActions('attributes', ['fetchAvailableAttributeValues']),

    formatAndFilterAttributes({ attributeValues }) {
      let filteredValues;
      if (
        this.selectedAttribute.attributeKey === architectureGroupLevel &&
        this.isPricingGroupScopeRule
      ) {
        // for pg rules, only show ags within pg scope from top page filter
        filteredValues = (this.agChildrenForCurrentPricingGroup || []).map(ag => ({
          attributeKey: ag.levelEntryKey,
          attributeValue: ag.levelEntryDescription,
        }));
      }
      const formattedAttributeValues = (filteredValues || attributeValues).map(attribute => {
        if (this.selectedAttribute.dataType === DataTypes.date) {
          attribute.attributeValue = this.formatLocalisedDate(attribute.attributeValue);
        }
        if (this.selectedAttribute.dataType === DataTypes.number && attribute.attributeValue) {
          attribute.attributeValue = this.formatNumber({
            number: attribute.attributeValue,
            format: numberFormats.decimal,
          });
        }
        return attribute;
      });
      return sortBy(formattedAttributeValues, attribute => {
        if (typeof attribute.attributeValue === 'string') return toLower(attribute.attributeValue);
        if (attribute.attributeValue === null) return ''; // null values will appear at the start of the list with empty str
        return attribute.attributeValue;
      });
    },

    pasteInputHandler(e) {
      // New line formatting is removed by the filter search input field so must be handled by @paste event
      const pasteInput = e.clipboardData.getData('text/plain');
      const delimiter = includes(pasteInput, '\t') ? '\t' : /\r?\n/;

      // Get individual search terms by splitting on delimiter
      this.pasteInput = pasteInput ? pasteInput.split(delimiter) : '';
    },

    searchInputHandler() {
      if (!size(this.pasteInput)) {
        // Required to correctly clear pasted text after clearing text manually
        this.searchInput = this.searchInput !== '' ? this.searchInput : null;
        return;
      }
      const { dataType, attributeValue } = this.selectedAttribute;
      // Format the pasted input based on the data type of the selected attribute
      this.searchInput = this.formatFilterSearchInput(this.pasteInput, dataType);
      // Handle search terms
      if (size(this.searchInput)) {
        // Find valid filter options that are not already selected
        const [validSelections, invalidSelections] = partition(this.searchInput, term => {
          return (
            !this.selectedAttributeValues.has(term) && this.availableFilterAttributeKeys.has(term)
          );
        });
        if (size(validSelections)) {
          this.selectedAttribute.attributeValue = attributeValue.concat(validSelections);
          this.filterAttributes(this.selectedAttributeValues);
        }
        if (size(invalidSelections)) this.searchInput = invalidSelections;
      }
      this.searchInput = this.searchInput.toString();
      this.pasteInput = null;
    },

    formatFilterSearchInput(input, type) {
      if (input === '') return input;
      // Remove duplicates
      const uniqueSearchTerms = uniq(input);
      switch (type) {
        case DataTypes.number:
          return uniqueSearchTerms.reduce((acc, term) => {
            if (!size(term)) return acc;
            const numericTerm = this.formatStringToNumber(term);
            acc.push(isNull(numericTerm) || isNaN(numericTerm) ? term : numericTerm);
            return acc;
          }, []);
        case DataTypes.date:
          // Dates could contain duplicates if search terms contain the same date in different formats
          return uniq(
            uniqueSearchTerms.reduce((acc, term) => {
              if (!size(term)) return acc;
              const date = moment(term, this.dateFormats.clientDateFormatForMoment).format(
                yearMonthDayFormat
              );
              acc.push(date !== this.$t('date.invalidDateText') ? date : term);
              return acc;
            }, [])
          );
        default:
          return compact(uniqueSearchTerms);
      }
    },

    isStoreGroupAttribute(attribute) {
      return attribute.filterType === filterOptions.storegroupOption;
    },

    async filterAttributes(attributeValue, fetchData = true) {
      // Clears out the text after a chip has been added
      this.searchInput = '';
      // attributeValue is set when deselected. ensure it's always array.

      const attributeValueArray = Array.from(attributeValue);

      const displayValue =
        this.selectedAttribute.attributeFilterType === filterOptions.hierarchyOption
          ? Array.from(
              this.availableFilterValues
                .filter(v => attributeValueArray.includes(v.attributeKey))
                .map(v => v.attributeValue)
            )
          : null;

      const attributeFilter = {
        attributeKey: this.selectedAttribute.attributeKey,
        predicate: filterPredicates.IN,
        attributeValue: attributeValueArray,
        // write display values to mongo for hierarchy filters
        displayValue,
        attributeFilterType: this.selectedAttribute.attributeFilterType,
        attributeDescription: this.selectedAttribute.attributeDescription,
        displayDescription: this.selectedAttribute.displayDescription,
      };

      await this.$emit('update-filter-attributes', { attributeFilter, fetchData });
      if (!fetchData) {
        // sync attributeValue after attribute change
        this.$set(this.selectedAttribute, 'attributeValue', this.filterRule.attributeValue);
      }
    },

    removeAttributeFilter() {
      this.$emit('remove-filter');
    },

    deselect(item) {
      const index = this.selectedAttribute.attributeValue.indexOf(item.attributeKey);
      if (index >= 0) this.$delete(this.selectedAttribute.attributeValue, index);
      this.filterAttributes(this.selectedAttributeValues);
    },

    fetchAvailableAttributesForSaveRule() {
      if (this.filterRule.attributeKey) {
        this.fetchAvailableAttributeValues({
          selectedAttributeMetadata: this.selectedAttributeMetadata,
          selectedAttributeKey: this.filterRule.attributeKey,
          selectedAttributeFilterType: this.filterRule.attributeFilterType,
          selectedAttributeDescription: this.filterRule.attributeDescription,
          selectedAttributeValue: this.filterRule.attributeValue,
        });
      }
    },
  },
};
</script>
