var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "error-page py-4 text-center" }, [
    _c("h1", { staticClass: "title" }, [
      _vm._v(
        "\n    " + _vm._s(_vm.$t(`errorPage.${_vm.status}.title`)) + "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("h2", { staticClass: "my-5" }, [
      _c("span", { staticClass: "error-code" }, [
        _vm._v("\n      " + _vm._s(_vm.status) + "\n    "),
      ]),
    ]),
    _vm._v(" "),
    _c("p", { staticClass: "description" }, [
      _vm._v(_vm._s(_vm.$t(`errorPage.${_vm.status}.description`))),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }