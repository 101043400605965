import axios from 'axios';
import to from 'await-to-js';

const store = {
  namespaced: true,

  state: {
    loading: false,
  },

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },
  },
  actions: {
    // params should be an object with array test_values (negative values for lower curve, positive for upper)
    // and penalty_params with { lower, upper }
    // both lower and upper should have the following keys:
    //   penalty_type → standard/kick/dissipate from configuration/constants
    //   w1 → B.deviation/C.deviation
    //   h1 → B.penalty/C.penalty
    //   w2 → A.deviation/D.deviation
    //   h2 → A.penalty/D.penalty
    //   n → Power
    async fetchPenaltyCurve({ commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const [err, { data: penaltyFunctions } = {}] = await to(
        axios.post('/api/penalty-functions', { upper: params.upper, lower: params.lower })
      );
      if (err) throw new Error(err.message);
      commit('setLoading', false);
      return {
        penaltyCurve: penaltyFunctions.series,
        maxValue: penaltyFunctions.maxValue,
      };
    },
  },
};

export default store;
