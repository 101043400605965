import axios from 'axios';
import to from 'await-to-js';
import { get } from 'lodash';

const getInitialState = () => {
  return {
    wholesaleGridLoading: false,
    setWholesaleGridListLoading: false,
    setWholesaleGridListDiffUploading: false,
    saveWholesaleGridLoading: false,
    selectedWholesaleGrid: null,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },
    setGridsStoreGroupsUpdateLoading(state, gridsStoreGroupsUpdateLoading) {
      state.gridsStoreGroupsUpdateLoading = gridsStoreGroupsUpdateLoading;
    },
    setWholesaleGridListLoading(state, wholesaleGridListLoading) {
      state.wholesaleGridListLoading = wholesaleGridListLoading;
    },
    setWholesaleGridListDiffUploading(state, wholesaleGridListDiffUploading) {
      state.wholesaleGridListDiffUploading = wholesaleGridListDiffUploading;
    },
    setWholesaleGridLoading(state, wholesaleGridLoading) {
      state.wholesaleGridLoading = wholesaleGridLoading;
    },
    setSaveWholesaleGridLoading(state, saveWholesaleGridLoading) {
      state.saveWholesaleGridLoading = saveWholesaleGridLoading;
    },
    setSelectedWholesaleGrid(state, selectedWholesaleGrid) {
      state.selectedWholesaleGrid = selectedWholesaleGrid;
    },
  },

  actions: {
    resetState({ commit }) {
      commit('resetState');
    },

    setSelectedWholesaleGrid({ commit }, { selectedWholesaleGrid }) {
      commit('setSelectedWholesaleGrid', selectedWholesaleGrid);
    },

    async getOwnBrandAttributeValues({ rootState }) {
      const {
        ownBrandAttributeName,
        ownBrandTruthyValue,
        ownBrandFalsyValue,
      } = rootState.clientConfig.wholesaleConfig;
      const { attributeMetadata } = rootState.attributes;

      const { dataType, displayDescription } = attributeMetadata.find(
        ({ attributeDescription }) => attributeDescription === ownBrandAttributeName
      );

      return {
        displayDescription,
        dataType,
        values: [ownBrandTruthyValue, ownBrandFalsyValue],
      };
    },

    async updateGridsStoreGroups({ commit }, { newStoreGroupsDataMap }) {
      commit('setGridsStoreGroupsUpdateLoading', true);
      const [err] = await to(axios.patch('/api/wholesale/gridsStoreGroups', newStoreGroupsDataMap));
      commit('setGridsStoreGroupsUpdateLoading', false);

      if (err) {
        throw err;
      }
    },

    async applyWholesaleGridByDiff({ commit }, { gridListDiff }) {
      commit('setWholesaleGridListDiffUploading', true);
      const [err] = await to(axios.patch('/api/wholesale/gridListDiff', gridListDiff));
      commit('setWholesaleGridListDiffUploading', false);

      if (err) {
        throw err;
      }
    },

    async fetchWholesaleGridList({ rootState, commit }, workpackageId) {
      workpackageId = workpackageId || rootState.workpackages.selectedWorkpackage._id;
      commit('setWholesaleGridListLoading', true);
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/gridList`)
      );
      commit('setWholesaleGridListLoading', false);

      if (err) {
        throw err;
      }
      return response.data.grids;
    },

    async fetchWholesaleGrid({ rootState, commit }, { gridId }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setWholesaleGridLoading', true);
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/grid/${gridId}`)
      );
      commit('setWholesaleGridLoading', false);
      if (err) {
        return {};
      }

      return response.data.storeGroupGrids;
    },

    async updateWholesaleGrid({ commit, rootState }, { gridId, updates }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setSaveWholesaleGridLoading', true);
      const [err] = await to(
        axios.patch(`/api/wholesale/workpackage/${workpackageId}/grid/${gridId}`, {
          updates,
        })
      );
      commit('setSaveWholesaleGridLoading', false);
      return err ? { failed: true } : { failed: false };
    },

    async createWholesaleGrid({ commit, rootState }, { grid }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      commit('setSaveWholesaleGridLoading', true);
      const [err, response] = await to(
        axios.put(`/api/wholesale/workpackage/${workpackageId}/grid`, {
          ...grid,
        })
      );
      commit('setSaveWholesaleGridLoading', false);
      return err ? { failed: true } : { failed: false, gridId: response.data.gridId };
    },

    async validateWholesaleGrid({ rootState, commit, state }, { dataFile, translationMap = {} }) {
      commit('setSaveWholesaleGridLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const gridId = state.selectedWholesaleGrid.gridId;
      const ownBrandAttributeName = rootState.clientConfig.wholesaleConfig.ownBrandAttributeName;
      const workpackageDescription = get(
        rootState.workpackages,
        'selectedWorkpackage.description',
        ''
      );
      // TODO: PRICE-2315 remove this hack
      const hardcodedStoreGroups = get(
        rootState.clientConfig,
        `wholesaleConfig.hardcodedStoreGroups.${workpackageDescription}`,
        []
      );
      translationMap.ownBrandColumn = ownBrandAttributeName;
      const [err] = await to(
        axios.post(
          `/api/wholesale/workpackage/${workpackageId}/grid/${gridId}/import-grid/validate`,
          dataFile,
          {
            params: { translationMap, hardcodedStoreGroups },
            headers: {
              // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
              // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
              'Content-Type': undefined,
            },
          }
        )
      );
      commit('setSaveWholesaleGridLoading', false);
      if (err) throw err.response.data;
    },

    async uploadWholesaleGridConfirm({ rootState, commit, state }) {
      commit('setSaveWholesaleGridLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const gridId = state.selectedWholesaleGrid.gridId;
      const [err] = await to(
        axios.patch(
          `/api/wholesale/workpackage/${workpackageId}/grid/${gridId}/import-grid/confirm`
        )
      );
      commit('setSaveWholesaleGridLoading', false);
      if (err) throw err.response.data;
    },
  },
};

export default store;
