<template>
  <tr class="hierarchy__row hierarchy__row--group-headers">
    <th class="border-right">
      <v-icon v-once class="filter-text" size="2.75rem">filter_list</v-icon>
      <span class="font-weight-bold filter-text">
        {{ $tc('attributes.filters.numApplied', numFiltersApplied) }}
      </span>
    </th>
    <th colspan="4" class="border-right">{{ $t('gridView.salesMetrics') }}</th>
    <th colspan="2" class="border-right">{{ $t('gridView.margin') }}</th>
    <th
      v-for="competitorIndex in selectedCompetitors.length"
      :key="competitorIndex"
      colspan="4"
      class="border-right"
    >
      <v-row dense no-gutters>
        {{ $t('gridView.priceDistance') }}
      </v-row>
      <v-row>
        <competitor-filter
          :selected-competitor="selectedCompetitors[competitorIndex - 1]"
          @selectCompetitor="selectCompetitor($event, competitorIndex - 1)"
        />
      </v-row>
    </th>
    <th class="border-right">{{ $t('gridView.tension') }}</th>
    <th>{{ $t('gridView.changes') }}</th>
    <th />
  </tr>
</template>

<script>
import { mapActions, mapState } from 'vuex';

export default {
  computed: {
    ...mapState('gridView', ['selectedCompetitors']),
    ...mapState('filters', ['retailAttributesFilter']),

    numFiltersApplied() {
      return this.retailAttributesFilter.length;
    },
  },

  methods: {
    ...mapActions('gridView', ['setSelectedCompetitorAtIndex']),

    selectCompetitor(selectedCompetitor, index) {
      this.setSelectedCompetitorAtIndex({ selectedCompetitor, index });
      this.globalEmit('competitor-selection-changed');
    },
  },
};
</script>

<style lang="scss" scoped>
.hierarchy__row--headers > td {
  padding-top: 0;
  padding-bottom: 0;
  font-size: 1.2rem;
  font-weight: 700;
}

th {
  font-size: 1rem;
  font-weight: 600;
}
</style>
