import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    [
      _c(
        VFlex,
        [
          _c("pricing-filter", {
            attrs: {
              filter: _vm.competitorFilter,
              loading: _vm.loading,
              "hide-label": true,
              "override-value": _vm.selectedCompetitor._id,
              dense: false,
              solo: false,
            },
            on: { filterSelected: _vm.selectCompetitor },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }