// serializer implementation from axios 0.2x
// Link to the original serializer: https://github.com/axios/axios/blob/a64050a6cfbcc708a55a7dc8030d85b1c78cdf38/lib/helpers/buildURL.js#L5-L13
// Link to the original util functions: https://github.com/axios/axios/blob/a64050a6cfbcc708a55a7dc8030d85b1c78cdf38/lib/utils.js
// Look https://github.com/axios/axios/issues/5630 for the details
/* eslint-disable */

function encode(val) {
  return encodeURIComponent(val)
    .replace(/%3A/gi, ':')
    .replace(/%24/g, '$')
    .replace(/%2C/gi, ',')
    .replace(/%20/g, '+')
    .replace(/%5B/gi, '[')
    .replace(/%5D/gi, ']');
}

function utilsIsURLSearchParams(val) {
  return typeof URLSearchParams !== 'undefined' && val instanceof URLSearchParams;
}

function utilsIsArray(val) {
  return toString.call(val) === '[object Array]';
}

function utilsIsDate(val) {
  return toString.call(val) === '[object Date]';
}

function utilsIsObject(val) {
  return val !== null && typeof val === 'object';
}

function utilsForEach(obj, fn) {
  // Don't bother if no value provided
  if (obj === null || typeof obj === 'undefined') {
    return;
  }

  // Force an array if not already something iterable
  if (typeof obj !== 'object') {
    /* eslint no-param-reassign:0 */
    obj = [obj];
  }

  if (utilsIsArray(obj)) {
    // Iterate over array values
    for (let i = 0, l = obj.length; i < l; i++) {
      fn.call(null, obj[i], i, obj);
    }
  } else {
    // Iterate over object keys
    for (const key in obj) {
      if (Object.prototype.hasOwnProperty.call(obj, key)) {
        fn.call(null, obj[key], key, obj);
      }
    }
  }
}

/**
 * Build a queryString as Axios v0.21.4 would do
 *
 * @param {object} [params] The params to be appended
 * @returns {string} The serialized params
 */
export function serialize(params) {
  if (utilsIsURLSearchParams(params)) {
    return params.toString();
  }

  const parts = [];

  utilsForEach(params, function serialize(val, key) {
    if (val === null || typeof val === 'undefined') {
      return;
    }

    if (utilsIsArray(val)) {
      key += '[]';
    } else {
      val = [val];
    }

    utilsForEach(val, function parseValue(v) {
      if (utilsIsDate(v)) {
        v = v.toISOString();
      } else if (utilsIsObject(v)) {
        v = JSON.stringify(v);
      }
      parts.push(`${encode(key)}=${encode(v)}`);
    });
  });

  return parts.join('&');
}

export const paramsSerializer = {
  serialize,
};
