import axios from 'axios';
import to from 'await-to-js';
import _ from 'lodash';
import { useZones } from '@enums/feature-flags';

const getInitialState = () => {
  return {
    storeGroups: [],
    storeGroupRelationships: [],
    loadingStoreGroupRelationships: false,
    mainTsgKey: null,
    storegroupKeyNameMap: null,
    workpackageStoreGroups: [],
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setStoreGroups(state, storeGroups) {
      state.storeGroups = storeGroups;
    },

    setStoreGroupRelationships(state, relationships) {
      state.storeGroupRelationships = relationships;
    },

    setLoadingStoreGroupRelationships(state, loading) {
      state.loadingStoreGroupRelationships = loading;
    },

    setStoregroupKeyNameMap(state, value) {
      state.storegroupKeyNameMap = value;
    },

    setWorkpackageStoreGroups(state, value) {
      state.workpackageStoreGroups = value;
    },
  },

  getters: {
    // todo: swap to workpackage? data already exists there
    mainToolStoreGroupKeys: state =>
      state.workpackageStoreGroups
        .filter(storegroup => storegroup.parentPricingStoreGroupKey === null)
        .map(storegroup => storegroup.toolStoreGroupKey),

    // PRICE-2315 tmp hack for dzh.
    // TODO: remove these and revert to original values provided by vuex
    hardcodedStoreGroups: (state, getters, rootState) => {
      const workpackageDescription = _.get(
        rootState.workpackages,
        'selectedWorkpackage.description',
        ''
      );
      return _.get(
        rootState.clientConfig,
        `wholesaleConfig.hardcodedStoreGroups.${workpackageDescription}`,
        []
      );
    },
    hackedSelectedWorkpackageToolStoreGroups: (state, getters) => {
      return getters.hardcodedStoreGroups.map(v => ({
        ...v,
        description: v.toolStoreGroupDescription,
        key: v.toolStoreGroupKey,
      }));
    },
    allHardcodedStoreGroups: (state, getters, rootState) => {
      return _.get(rootState.clientConfig, 'wholesaleConfig.hardcodedStoreGroups', {});
    },
    toolStoreGroupsDescriptionMap: (state, getters) => {
      return _.reduce(
        _.isEmpty(getters.hardcodedStoreGroups)
          ? getters.storeGroupRelationships
          : getters.hardcodedStoreGroups,
        (acc, item) => {
          acc[item.toolStoreGroupKey] = item.toolStoreGroupDescription;
          return acc;
        },
        {}
      );
    },
  },

  actions: {
    resetState({ commit }) {
      commit('resetState');
    },

    async fetchStoregroupKeyNameMap({ commit, rootState }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, data] = await to(
        axios.get(
          `/api/store-group-relationships/workpackage/${workpackageId}/workpackage-description-map`
        )
      );
      if (err) console.error(err);

      commit('setStoregroupKeyNameMap', data.data);
    },

    async fetchStoreGroupRelationships({ commit, rootState }) {
      const useZonesFlag = rootState.clientConfig.toggleLogic[useZones];
      if (!useZonesFlag) {
        commit('setStoreGroupRelationships', []);
        return;
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!workpackageId) return;
      commit('setLoadingStoreGroupRelationships', true);
      const [err, res] = await to(
        axios.get(`/api/store-group-relationships/workpackage/${workpackageId}`)
      );

      if (err) {
        commit('setStoreGroupRelationships', []);
        commit('setLoadingStoreGroupRelationships', false);
        throw new Error(err.message);
      }
      commit('setStoreGroupRelationships', res.data);
      commit('setLoadingStoreGroupRelationships', false);

      return res.data;
    },

    async fetchWorkpackageStoreGroups({ commit, rootState }) {
      const useZonesFlag = rootState.clientConfig.toggleLogic[useZones];
      if (!useZonesFlag) {
        commit('setWorkpackageStoreGroups', []);
        return;
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!workpackageId) return;

      const [err, res] = await to(
        axios.get(
          `/api/store-group-relationships/workpackage/${workpackageId}/workpackage-with-parent`
        )
      );

      if (err) throw new Error(err.message);

      commit('setWorkpackageStoreGroups', res.data);
      return res.data;
    },

    // temporary fix for PRICE-2755
    // store-group-relationships collection is populated by a script and can get out of sync with the datafeed.
    // created new api endpoint to read from workpacakge-store-group-relationships only and store here
    // TODO: remove this after PRICE-2796 and rely on storeGroupRelationships
    async fetchStoreGroups({ commit, rootState }) {
      const useZonesFlag = rootState.clientConfig.toggleLogic[useZones];
      if (!useZonesFlag) {
        commit('setStoreGroups', []);
        return;
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (!workpackageId) return;
      const [err, res] = await to(
        axios.get(
          `/api/store-group-relationships/workpackage/${workpackageId}/workpackage-store-groups`
        )
      );

      if (err) {
        commit('setStoreGroups', []);
        throw new Error(err.message);
      }
      commit('setStoreGroups', res.data);

      return res.data;
    },
  },
};

export default store;
