<template>
  <div class="input-screen-page-wrapper" style="width: 100%; height: 100%">
    <!-- Menu options -->

    <inputs-grid
      ref="inputsGrid"
      :additional-columns="additionalColumns"
      :additional-product-columns="additionalProductColumns"
      :additional-grid-options="additionalGridOptions"
      :save-changes="saveChanges"
      :export-action="downloadItems"
      :is-exporting="downloadingItems"
    >
      <!-- Slots for inputs-grid, -->
      <!-- Import/ Add buttons etc. -->
      <template #buttons>
        <!-- FEATURE_FLAG: display competitor add button -->
        <feature-toggle :toggle="displayAddCompetitorButton">
          <v-btn
            color="primary"
            small
            depressed
            @click="openCompetitorCreateUpdateDialog(dialogModes.create)"
          >
            {{ $t('actions.add') }}
          </v-btn>
        </feature-toggle>

        <feature-toggle :toggle="displayCompetitorUpload">
          <competitor-upload
            :enabled-value-translations="enabledValueTranslations"
            :is-master-workpackage="isSelectedWorkpackageMaster"
          />
        </feature-toggle>
      </template>
    </inputs-grid>

    <competitor-create-update-dialog
      :key="competitorCreateDialogKey"
      :is-open="isCompetitorCreateUpdateDialogOpen"
      :mode="mode"
      :competitor="competitorToEdit"
      @closeDialog="closeCompetitorCreateUpdateDialog"
    />
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import { get, startCase, set, flatMap, isUndefined } from 'lodash';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import {
  displayCompetitorUpload,
  displayAddCompetitorButton,
  displayHierarchyAndFilter,
  useZones,
} from '@enums/feature-flags';
import dialogModes from '@enums/dialog-modes';
import DataTypes from '@enums/data-types';
import { inputTableCssClasses } from '@enums/tables';
import numberFormats from '@enums/number-formats';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import checkboxCellRenderer from './components/checkbox-cell-renderer.vue';
import { localisedNumericCol } from '../../../utils/inputs-grid-utils';

export default {
  name: 'CompetitorMatchesEditor',
  components: {
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    checkboxCellRenderer,
  },
  mixins: [featureFlagsMixin, tablesMixin, inputScreensTableMixin],

  async beforeRouteLeave(destination, from, next) {
    const preventNavigation = await this.$refs.inputsGrid.resolveEditsBeforeNavigation();

    if (preventNavigation) return;

    next();
  },

  data() {
    return {
      get,
      startCase,
      isCompetitorCreateUpdateDialogOpen: false,
      mode: dialogModes.create,
      dialogModes,
      displayCompetitorUpload,
      displayAddCompetitorButton,
      competitorToEdit: null,
      additionalGridOptions: {
        getMainMenuItems: this.getColumnMenuItems,
      },
      enabledValueTranslations: {
        1: this.$t('competitorMatches.editor.enabledTrue'),
        0: this.$t('competitorMatches.editor.enabledFalse'),
      },
      additionalProductColumns: [
        {
          field: 'productSizeType',
          tooltipField: 'productSizeType',
          colId: 'productSizeType',
          headerName: this.$t('competitorMatches.editor.productSize'),
          sortable: true,
          editable: false,
          pinned: 'left',
          cellClass: 'bold-text',
          valueFormatter: params =>
            this.formatMetricContainedUnitOfMeasurement(params.value, numberFormats.weight),
        },
      ],
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('workpackageProducts', ['downloadingItems']),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapState('competitorMetadata', ['competitors', 'competitorKeyToTypeMap']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapState('filters', ['retailAttributesFilter']),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    exportTranslationMap() {
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};

      const toolStoreGroupColumn = this.zonesEnabled
        ? { toolStoreGroupKey: this.$t('pricing.toolStoreGroup') }
        : {};
      return {
        productKeyDisplay: this.$t('competitorMatches.editor.productKey'),
        ...toolStoreGroupColumn,
        productName: this.$t('competitorMatches.editor.productDescription'),
        productSizeType: this.$t('competitorMatches.editor.productSize'),
        competitorName: this.$t('competitorMatches.editor.competitorName'),
        competitorPrice: this.$t('competitorMatches.editor.price'),
        competitorEnabled: this.$t('competitorMatches.editor.enabled'),
        ...hierarchyColumnTranslations,
      };
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    competitorCreateDialogKey() {
      const competitorDisplayDescription = get(
        this.competitorToEdit,
        'competitorDisplayDescription',
        ''
      );
      return `competitorCreateDialogKey-${competitorDisplayDescription}`;
    },

    isUseZones() {
      return this.isFeatureFlagEnabled(useZones);
    },

    toolStoreGroupColumn() {
      return this.isUseZones
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('competitorMatches.editor.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.fourFixedColumns}`,
          }
        : null;
    },

    staticHeaders() {
      const tsgHeaders = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('competitorMatches.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `sortable s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeaders,
        {
          text: this.$t('competitorMatches.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productName',
          class: `sortable l-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        {
          text: this.$t('competitorMatches.editor.productSize'),
          align: 'start',
          sortable: true,
          value: 'productSizeType',
          class: `sortable border-right s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
      ];
    },

    headers() {
      const competitorHeaders = flatMap(this.competitors, (c, i) => {
        return [
          {
            text: this.$t('competitorMatches.editor.price'),
            sortable: true,
            align: 'start',
            key: 'competitorPrice',
            competitorKey: c.competitorKey,
            value: `competitor.${c.competitorType}.${c.competitorKey}.competitorPrice`,
            competitor: true,
            class: 'sortable',
            competitorType: c.competitorType,
            dataType: DataTypes.number,
          },
          {
            text: this.$t('competitorMatches.editor.enabled'),
            align: 'start',
            key: `competitor[${c.competitorDescription}].disabled`,
            value: `competitor.${i}.disabled`,
            competitor: true,
            class: 'border-right',
            competitorType: c.competitorType,
            dataType: DataTypes.boolean,
          },
        ];
      });

      return [...this.staticHeaders, ...this.hierarchyHeaders, ...competitorHeaders];
    },

    zonesEnabled() {
      return this.isFeatureFlagEnabled(useZones);
    },

    additionalColumns() {
      const mapCompetitor = (competitor, competitorIndex) => ({
        headerName: competitor.competitorDisplayDescription,
        groupId: `competitor::${competitor.competitorType}::${competitor.competitorKey}`,
        ...(competitorIndex !== 0 && { columnGroupShow: 'open' }),
        children: [
          {
            headerName: `${this.$t('competitorMatches.editor.price')} (${this.$t(
              'clientCurrencySymbol'
            )})`,
            headerTooltip: `${this.$t('competitorMatches.editor.price')} (${this.$t(
              'clientCurrencySymbol'
            )})`,
            colId: `competitor::${competitor.competitorType}::${
              competitor.competitorKey
            }::competitorPrice`,
            field: `competitor.${competitor.competitorType}.${
              competitor.competitorKey
            }.competitorPrice`,
            cellDataType: 'number',
            cellClass: 'left-competitor-border',
            headerClass: 'left-competitor-border',
            onCellValueChanged: this.compatitorPriceChangeHandler,
            editable: true,
            onCellClicked: params => {
              return this.populateEmptyCompetitorsData(params, null);
            },
            tooltipValueGetter: params => {
              const competitorData = get(
                params.data,
                `competitor.${competitor.competitorType}.${competitor.competitorKey}`
              );
              const { competitorProductDescription, contentValue, contentUnitOfMeasure } =
                competitorData || {};

              const previousValueText = this.tooltipValueGetterPreviousValue(params);

              const unknownValueText = this.$t('tooltip.unknownValueText');
              const competitorProductDescriptionText = `${this.$t(
                'tooltip.competitorDescription'
              )}: ${competitorProductDescription || unknownValueText}`;

              const competitorSizeText = `${this.$t('tooltip.competitorSize')}: ${contentValue ||
                unknownValueText}`;
              const competitorUoMText = `${this.$t(
                'tooltip.competitorUnitOfMeasure'
              )}: ${contentUnitOfMeasure || unknownValueText}`;

              const additionalText = [
                competitorProductDescriptionText,
                competitorSizeText,
                competitorUoMText,
              ];

              return {
                ...previousValueText,
                additionalText,
              };
            },
            ...localisedNumericCol,
          },
          {
            headerName: this.$t('competitorMatches.editor.enabled'),
            suppressFillHandle: true,
            colId: `competitor::${competitor.competitorType}::${
              competitor.competitorKey
            }::disabled`,
            field: `competitor.${competitor.competitorType}.${competitor.competitorKey}.disabled`,
            cellRenderer: 'checkboxCellRenderer',
            cellRendererParams: {
              negate: true,
              setter: (params, value) => {
                set(
                  params,
                  `data.competitor.${competitor.competitorType}.${
                    competitor.competitorKey
                  }.disabled`,
                  value
                );
              },
            },
            onCellClicked: params => {
              return this.populateEmptyCompetitorsData(params, false);
            },
            filter: 'agSetColumnFilter',
            filterParams: {
              suppressMiniFilter: true,
              valueFormatter: params => {
                if (params.value === null) return null;
                return (!params.value).toString();
              },
            },
          },
        ],
      });

      const res = [
        {
          headerName: this.$t('competitorMatches.tabs.feed'),
          groupId: `feedCompetitors`,
          openByDefault: true,
          children: this.competitors
            .filter(competitor => competitor.competitorType === 'feed')
            .map(mapCompetitor),
        },
        {
          headerName: this.$t('competitorMatches.tabs.manual'),
          groupId: `manualCompetitors`,
          openByDefault: true,
          children: this.competitors
            .filter(competitor => competitor.competitorType === 'manual')
            .map(mapCompetitor),
        },
      ];
      return res;
    },
  },

  methods: {
    ...mapActions('workpackageProducts', ['downloadCompetitorMatches']),
    tooltipValueGetterPreviousValue(params) {
      return this.$refs.inputsGrid.tooltipValueGetter(params);
    },
    isMainbanner(toolStoreGroupKey) {
      // If not using zones, you only have mainbanner
      if (!this.zonesEnabled) return true;

      return toolStoreGroupKey === this.mainTsgKey;
    },

    compatitorPriceChangeHandler(params) {
      // uncheck competitor checkbox if price is cleared
      const {
        column: { colId },
        node: rowNode,
        newValue,
      } = params;
      if (newValue === null) {
        const [, competitorType, competitorKey] = colId.split('::');
        rowNode.setDataValue(`competitor::${competitorType}::${competitorKey}::disabled`, true);
      }
    },

    async downloadItems() {
      this.downloadCompetitorMatches({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        pick: this.getColumnExportPickOptions(this.toolStoreGroupColumn),
        enabledValueTranslations: this.enabledValueTranslations,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    openCompetitorCreateUpdateDialog(mode, competitor = null) {
      this.mode = mode;
      this.competitorToEdit = competitor;
      this.isCompetitorCreateUpdateDialogOpen = true;
    },

    closeCompetitorCreateUpdateDialog() {
      this.competitorToEdit = null;
      this.isCompetitorCreateUpdateDialogOpen = false;
    },
    populateEmptyCompetitorsData(params, defaultValue) {
      // Populate fields that did not exist on the original structure
      // passed in to ag-grid for the row being clicked
      if (isUndefined(get(params, `data.${params.colDef.field}`))) {
        set(params, `data.${params.colDef.field}`, defaultValue);
      }

      return params;
    },

    async saveChanges({ updates, mainTsgKey }) {
      const { data: results } = await axios.post(
        `/api/inputs/workpackage/${this.selectedWorkpackage._id}/competitor-matches`,
        { updates, mainTsgKey }
      );
      return results;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.invalid-blocking {
  border-bottom: 0.2rem solid #c13939 !important;
}

.pale {
  opacity: 0.7;
}

// match table body cells and last cell of header
[col-id^='competitor::'].left-competitor-border,
// match table header group cells
[col-id*='competitor' i].ag-header-group-cell-with-group {
  border-left: 0.1rem solid var(--ag-border-color);
}
</style>
