var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "13px",
        height: "13px",
        viewBox: "0 0 13 13",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Icon / Export")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "Icon-/-Export",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("path", {
            attrs: {
              id: "Export",
              d: "M6.51041667,8.16928044 C6.234375,8.16928044 6.015625,7.95110701 6.015625,7.69741697 L6.015625,2.94326568 L6.05729167,2.23293358 L5.765625,2.59824723 L5.10416667,3.28321033 C5.02083333,3.37453875 4.89583333,3.42020295 4.77604167,3.42020295 C4.53645833,3.42020295 4.34375,3.25276753 4.34375,3.01429889 C4.34375,2.88745387 4.390625,2.79612546 4.47916667,2.71494465 L6.13020833,1.16743542 C6.25520833,1.04059041 6.38020833,1 6.51041667,1 C6.640625,1 6.76041667,1.04059041 6.88541667,1.16743542 L8.53645833,2.71494465 C8.625,2.79612546 8.671875,2.88745387 8.671875,3.01429889 C8.671875,3.25276753 8.47395833,3.42020295 8.23958333,3.42020295 C8.11458333,3.42020295 7.99479167,3.37453875 7.91145833,3.28321033 L7.25,2.59824723 L6.95833333,2.23800738 L7,2.94326568 L7,7.69741697 C7,7.95110701 6.78125,8.16928044 6.51041667,8.16928044 Z M3.66145833,12 C2.578125,12 2,11.4469557 2,10.4017528 L2,5.77444649 C2,4.72416974 2.578125,4.17112546 3.66145833,4.17112546 L4.984375,4.17112546 L4.984375,5.21125461 L3.74479167,5.21125461 C3.30729167,5.21125461 3.06770833,5.42942804 3.06770833,5.87592251 L3.06770833,10.295203 C3.06770833,10.7467712 3.30729167,10.9598708 3.74479167,10.9598708 L9.25520833,10.9598708 C9.69270833,10.9598708 9.93229167,10.7467712 9.93229167,10.295203 L9.93229167,5.87592251 C9.93229167,5.42942804 9.69270833,5.21125461 9.25520833,5.21125461 L8.03125,5.21125461 L8.03125,4.17112546 L9.34375,4.17112546 C10.4270833,4.17112546 11,4.72924354 11,5.77444649 L11,10.4017528 C11,11.4469557 10.4270833,12 9.34375,12 L3.66145833,12 Z",
              fill: "#FFFFFF",
              "fill-rule": "nonzero",
            },
          }),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }