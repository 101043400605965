<template>
  <div class="translation-editor">
    <div class="translation-editor__container">
      <pricing-json-editor
        :response="i18nTranslations"
        class="translation-editor__container--editor"
        :emit-change="true"
        :emit-error="true"
        @change="trackNewTranslations"
        @error="trackError"
      />
      <div class="translation-editor__container--button">
        <v-btn
          color="primary"
          small
          depressed
          :disabled="disableSave || isEmpty(updatedTranslations) || translationsHaveNotChanged"
          @click="setTranslations"
        >
          {{ $t('actions.save') }}
        </v-btn>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapMutations } from 'vuex';
import { isEmpty } from 'lodash';

export default {
  data() {
    return {
      updatedTranslations: {},
      disableSave: false,
    };
  },
  computed: {
    ...mapState('clientConfig', ['i18nTranslations']),
    translationsHaveNotChanged() {
      return JSON.stringify(this.updatedTranslations) === JSON.stringify(this.i18nTranslations);
    },
  },
  methods: {
    isEmpty,
    ...mapMutations('clientConfig', ['setI18nTranslations']),

    setTranslations() {
      this.setI18nTranslations(this.updatedTranslations);
    },

    trackNewTranslations({ json }) {
      this.updatedTranslations = json;
    },

    trackError(error) {
      this.disableSave = error;
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.translation-editor {
  height: 100%;
  width: 100%;
  background-color: white;
  font-size: 1.4rem;

  &__container {
    padding-top: 1rem;
    display: flex;
    flex-direction: column;

    &--button {
      padding-left: 1rem;
      margin-top: 1rem;
      margin-bottom: 1rem;
    }
    &--editor {
      padding-left: 1rem;
    }
  }

  ::v-deep {
    .v-messages {
      display: none;
    }
    .v-input__slot {
      margin: unset;
    }
  }
}
</style>
