import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      staticClass: "save ml-2 align-self-center",
      attrs: {
        color: "success",
        small: "",
        depressed: "",
        disabled: _vm.gridsAndL6NodesExporting,
        loading: _vm.gridsAndL6NodesExporting,
      },
      on: { click: _vm.initExportGridsAndL6Nodes },
    },
    [_vm._v("\n  " + _vm._s(_vm.$t("wholesale.downloadGridsAndNodes")) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }