'use strict';

const moment = require('moment');
const _ = require('lodash');
const XLSX = require('xlsx');

const getExportFileName = (exportConfig, entityName = '') => {
  const fileNamePattern = exportConfig.fileName.pattern || 'export';
  const displayedEntityName = entityName || exportConfig.fileName.defaultEntityName || '';
  const fileName = fileNamePattern
    .replace('<timestamp>', moment().format(exportConfig.timestampFormat))
    .replace(
      '<entityName>',
      displayedEntityName.replace(/\s/g, exportConfig.fileName.entityNameWordSeparator)
    );
  return `${fileName}.${exportConfig.fileName.extension}`;
};

/**
 * Filters out column configs required for export.
 * @param {Array} columnDefs all column configs.
 * @returns column configs required for export.
 */
const getExportedColumnConfigs = columnDefs =>
  columnDefs.filter(columnDef => _.get(columnDef, ['customExport', 'exported'], false));

/**
 * A callback function invoked once per column. Return a string to be displayed in the column header.
 * @param {Object} columnDef column config.
 * @returns a string to be displayed in the column header.
 */
const processHeaderCallback = columnDef => {
  const headerNameGetter = _.get(
    columnDef,
    ['customExport', 'headerNameGetter'],
    () => columnDef.headerName
  );
  return headerNameGetter();
};

/**
 * A callback function invoked once per cell in the grid. Return a string value to be displayed in the export.
 * For example this is useful for formatting date values.
 * @param { columnDef, row } param0 includes columnDef (column config), row.
 * @returns a string value to be displayed in the export.
 */
const processCellCallback = ({ columnDef, row }) => {
  if (_.has(columnDef, ['customExport', 'processCellCallback'])) {
    return columnDef.customExport.processCellCallback({ columnDef, row });
  }
  const field = _.get(columnDef, ['customExport', 'field'], columnDef.field);
  const value = _.get(row, field);
  return value;
};

/**
 * Returns an array of ranges that should be merged. For example [{s: {r:0, c:0}, e: {r:0, c:1}}] for [A1:B1].
 * @param { columnDefs, rows } param0 includes columnDefs (exported column configs), rows (all exported data rows).
 * @returns  an array of ranges that should be merged.
 */
const getMergedCells = ({ columnDefs, rows }) => {
  const rowsNumberWithoutHeader = rows.length;
  const mergedCells = [];
  columnDefs.forEach((columnDef, columnIndex) => {
    const rowSpanFunc = _.get(columnDef, ['customExport', 'rowSpan'], null);
    const rowSpan = rowSpanFunc ? rowSpanFunc() : 0;
    if (!rowSpan) {
      return;
    }
    let rowIndex = 1;
    const step = rowSpan - 1;
    for (; rowIndex < rowsNumberWithoutHeader; rowIndex += rowSpan) {
      mergedCells.push({
        s: { r: rowIndex, c: columnIndex },
        e: { r: rowIndex + step, c: columnIndex },
      });
    }
  });
  return mergedCells;
};

/**
 * Generates exported data.
 * @param {columnDefs, rows} param0 includes columnDefs (exported column configs), rows (all exported data rows).
 * @returns array of arrays (rows).
 */
const generateData = ({ columnDefs: columnDefList, rows }) => {
  const processDataRowFn = row =>
    columnDefList.map(columnDef => processCellCallback({ columnDef, row }));

  const exportDataHeadersRow = columnDefList.map(processHeaderCallback);
  const exportDataRows = rows.map(processDataRowFn);

  return [exportDataHeadersRow, ...exportDataRows];
};

/**
 * Download excel
 * @param {string} fileName file name.
 * @param {Array} tableDataList array of table data, each includes table(2d array of strings), processWorksheet(a function to mutate worksheet), pageName(name of the xlsx page).
 */

const writeTablesToXLSX = (fileName, tableDataList) => {
  const workbook = XLSX.utils.book_new();

  _.forEach(tableDataList, tableData => {
    const worksheet = XLSX.utils.aoa_to_sheet(tableData.table);
    if (typeof tableData.processWorksheet === 'function') {
      tableData.processWorksheet(worksheet);
    }
    XLSX.utils.book_append_sheet(workbook, worksheet, tableData.pageName || undefined);
  });

  XLSX.writeFile(workbook, fileName);
};

/**
 * Download an Excel export of the provided data.
 * @param {exportedData, exportedColumns, rows, fileName} param0 includes exportedData (unmerged export datadata), exportedColumns (column config), rows (table rows), fileName (String to use as the file name).
 */
const writeGridXLSX = ({ exportedData, exportedColumns, rows, fileName }) => {
  const ws = XLSX.utils.aoa_to_sheet(exportedData);
  ws['!merges'] = getMergedCells({ columnDefs: exportedColumns, rows });

  const wb = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(wb, ws);
  XLSX.writeFile(wb, fileName);
};

/**
 * Downloads an Excel export of the grid's data.
 * @param {columnDefs, rows, fileName } param0 includes columnDefs (exported column configs), rows (table rows), fileName (String to use as the file name).
 * @returns exported data.
 */
const exportGrid = ({ columnDefs, rows, fileName }) => {
  const exportedColumns = getExportedColumnConfigs(columnDefs);
  const exportedData = generateData({ columnDefs: exportedColumns, rows });
  writeGridXLSX({ exportedData, exportedColumns, rows, fileName });
  return exportedData;
};

/**
 * Extracts level 6 nodes with their hierarchy ids and assigned grid name.
 * @param {wholesaleHierarchyIdGridMap, rootNode} param0 includes wholesaleHierarchyIdGridMap (map from levelEntrykey to gridDescription), rootNode (root node of the hierarchy).
 * @returns CDT Nodes
 */
const makeCDTNodesTable = ({ wholesaleHierarchyIdGridMap, rootNode }) => {
  const cdtNodesHeader = ['Hierarchy Node', 'Grid Name'];
  const cdtNodes = [cdtNodesHeader];

  // Recursion for the sake of simplicity.
  const dfsL6Nodes = node => {
    if (node.level === 6) {
      cdtNodes.push([
        _.get(node, 'wholesaleHierarchyKeyDisplay'),
        _.get(wholesaleHierarchyIdGridMap, [node.levelEntryKey, 'gridDescription'], null),
      ]);
      return;
    }

    _.forEach(node.children, child => dfsL6Nodes(child));
  };

  dfsL6Nodes(rootNode);

  return cdtNodes;
};

/**
 * Makes grid page header row for grids export.
 * @param {Array} gridValueRanges list of objects which contains all displayed texts for the grid value ranges.
 * @returns headers row.
 */
const makeGridPageHeadersRow = gridValueRanges => [
  'Grid Name',
  'Tool Store Group',
  'PRICE BRAND CATEGORY',
  ...gridValueRanges.map(({ displayedText }) => displayedText),
];

/**
 * Makes grid page value row for grids export.
 * @param {Array} gridValueRanges list of objects which contains all displayed texts for the grid value ranges.
 * @param {any} defaultGridValue default value for the grid values.
 * @param {gridName, toolStoreGroup, priceBrandCategory, gridValues} param2 includes gridName(name of the grid), toolStoreGroup(name of the tool store group), priceBrandCategory(name of the price brand category), gridValues(grid values of the storegroup).
 * @returns store group value row.
 */
const makeStoreGroupValueRow = (
  gridValueRanges,
  defaultGridValue,
  toolStoreGroupsDescriptionMap
) => ({ gridName, toolStoreGroup, priceBrandCategory, gridValues }) => [
  gridName,
  // a fallback, in case there is no group descriptions
  _.get(toolStoreGroupsDescriptionMap, toolStoreGroup, toolStoreGroup),
  priceBrandCategory,
  ...gridValueRanges.map(({ upperLimit: rangeUpperLimit }) => {
    // It doesn't make much sense to use a hash map for searching through 118 items.
    const gridValue = gridValues.find(({ upperLimit }) => upperLimit === rangeUpperLimit);
    return _.get(gridValue, 'value', defaultGridValue);
  }),
];

const convertStoreGroupGridsToRows = (
  makeValueRowFn,
  hardcodedStoreGroupOrder,
  sortStoreGroupsFn,
  toolStoreGroupsDescriptionMap
) => ({ gridDescription, storeGroupGrids }) => {
  const rows = [];

  const generateStoreGroupGridRows = (priceBrandIdGridMap, storeGroupKey) => {
    _.forEach(priceBrandIdGridMap, (gridValues, priceBrandGroup) => {
      rows.push(
        makeValueRowFn({
          gridName: gridDescription,
          toolStoreGroup: storeGroupKey,
          priceBrandCategory: priceBrandGroup,
          gridValues,
        })
      );
    });
  };

  if (!_.isEmpty(hardcodedStoreGroupOrder) && sortStoreGroupsFn) {
    // preserve hardcodedStoreGroupOrder when exporting
    const storeGroups = _.map(storeGroupGrids, (value, storeGroupKey) => {
      return {
        storeGroupKey,
        description: _.get(toolStoreGroupsDescriptionMap, storeGroupKey, storeGroupKey),
        ...value,
      };
    });
    const sortedStoreGroups = sortStoreGroupsFn(
      storeGroups,
      hardcodedStoreGroupOrder,
      'description'
    );

    _.forEach(sortedStoreGroups, ({ storeGroupKey, grid: priceBrandIdGridMap }) =>
      generateStoreGroupGridRows(priceBrandIdGridMap, storeGroupKey)
    );
  } else {
    _.forEach(storeGroupGrids, (obj, storeGroupKey) => {
      const priceBrandIdGridMap = _.get(obj, 'grid');
      generateStoreGroupGridRows(priceBrandIdGridMap, storeGroupKey);
    });
  }

  return rows;
};

const reduceGridsToRowsForExport = ({
  gridList,
  gridValuesRanges,
  defaultGridValue,
  toolStoreGroupsDescriptionMap,
  skipHeader = false,
  // TODO: use both storegroup name and storegroup id lookup ?
  hardcodedStoreGroupOrder,
  sortStoreGroupsFn,
}) => {
  const makeValueRow = makeStoreGroupValueRow(
    gridValuesRanges,
    defaultGridValue,
    toolStoreGroupsDescriptionMap
  );
  const convertToRows = convertStoreGroupGridsToRows(
    makeValueRow,
    hardcodedStoreGroupOrder,
    sortStoreGroupsFn,
    toolStoreGroupsDescriptionMap
  );

  return _.reduce(
    gridList,
    (acc, gridItem) =>
      acc.concat(convertToRows(_.pick(gridItem, ['gridDescription', 'storeGroupGrids']))),
    skipHeader ? [] : [makeGridPageHeadersRow(gridValuesRanges)]
  );
};

module.exports = {
  getExportFileName,
  exportGrid,
  getExportedColumnConfigs,
  processHeaderCallback,
  processCellCallback,
  getMergedCells,
  generateData,
  makeCDTNodesTable,
  writeTablesToXLSX,
  reduceGridsToRowsForExport,
  makeGridPageHeadersRow,
};
