import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tr",
    { staticClass: "hierarchy__row hierarchy__row--group-headers" },
    [
      _c(
        "th",
        { staticClass: "border-right" },
        [
          _vm._m(0),
          _vm._v(" "),
          _c("span", { staticClass: "font-weight-bold filter-text" }, [
            _vm._v(
              "\n      " +
                _vm._s(
                  _vm.$tc(
                    "attributes.filters.numApplied",
                    _vm.numFiltersApplied
                  )
                ) +
                "\n    "
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c("th", { staticClass: "border-right", attrs: { colspan: "4" } }, [
        _vm._v(_vm._s(_vm.$t("gridView.salesMetrics"))),
      ]),
      _vm._v(" "),
      _c("th", { staticClass: "border-right", attrs: { colspan: "2" } }, [
        _vm._v(_vm._s(_vm.$t("gridView.margin"))),
      ]),
      _vm._v(" "),
      _vm._l(_vm.selectedCompetitors.length, function (competitorIndex) {
        return _c(
          "th",
          {
            key: competitorIndex,
            staticClass: "border-right",
            attrs: { colspan: "4" },
          },
          [
            _c(VRow, { attrs: { dense: "", "no-gutters": "" } }, [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("gridView.priceDistance")) + "\n    "
              ),
            ]),
            _vm._v(" "),
            _c(
              VRow,
              [
                _c("competitor-filter", {
                  attrs: {
                    "selected-competitor":
                      _vm.selectedCompetitors[competitorIndex - 1],
                  },
                  on: {
                    selectCompetitor: function ($event) {
                      return _vm.selectCompetitor($event, competitorIndex - 1)
                    },
                  },
                }),
              ],
              1
            ),
          ],
          1
        )
      }),
      _vm._v(" "),
      _c("th", { staticClass: "border-right" }, [
        _vm._v(_vm._s(_vm.$t("gridView.tension"))),
      ]),
      _vm._v(" "),
      _c("th", [_vm._v(_vm._s(_vm.$t("gridView.changes")))]),
      _vm._v(" "),
      _c("th"),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "filter-text", attrs: { size: "2.75rem" } },
      [_vm._v("filter_list")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }