// Utility to create a reversed map of any two properties on an array of objects. For example:
// createMapping([{ id: 1, name: 'J' }, { id: 2, name: 'K' }], 'id', 'name')
//   => { 1: 'J', 2: 'K', J: 1, K:2 }
// Used for cases where repeated lookups are necessary to cut down on unnecessary array traversals

const createMapping = (arrayOfObjects, prop1, prop2) =>
  arrayOfObjects.reduce((mapObj, element) => {
    return { ...mapObj, [element[prop1]]: element[prop2], [element[prop2]]: element[prop1] };
  }, {});

export default createMapping;
