<template>
  <v-layout>
    <v-flex>
      <v-expansion-panels :value="value">
        <v-expansion-panel
          :disabled="disabled"
          class="line-pricing borderless-panel"
          @change="panelOpenedOrClosed"
        >
          <v-expansion-panel-header>
            <span>{{ $t('settings.setLinePricing') }}</span>
            <template v-slot:actions>
              <v-icon>expand_more</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-body">
            <v-container fluid>
              <v-row>
                <v-col cols="12" class="products-header" md="6" lg="6" xl="5">
                  <v-row>
                    <v-col cols="9" class="pl-0 pb-0 pt-0">
                      <span>{{ $t('settings.architecture.productsHeader') }}</span>
                    </v-col>
                    <v-col class="text-right pl-0 pb-0 pt-0">
                      <span class="products-count">
                        {{ $t('settings.architecture.productsCount', { productsCount }) }}
                      </span>
                    </v-col>
                  </v-row>
                  <v-row>
                    <v-col class="pl-0">
                      <attribute-filter-panel
                        :filter-rules="architectureRulesLinePricingFilter"
                        title-localisation="attributes.filters.filterByAttributes"
                        filter-count-localisation="attributes.filters.numApplied"
                        :fixed-filter-rules="fixedFilterRules"
                        @attributeFilterChange="setAttributeFilter"
                      />
                      <div class="pb-5 justify-between">
                        <norm-weight-toggle />
                        <span>
                          <line-pricing-export-button />
                        </span>
                        <div>
                          <v-btn
                            class="add-products-btn"
                            color="primary"
                            small
                            :disabled="isAddBtnDisabled"
                            depressed
                            @click="addProducts()"
                          >
                            {{ $t('settings.architecture.addProducts') }}
                          </v-btn>
                        </div>
                      </div>
                      <div class="ag-grid-box">
                        <ag-grid-vue
                          style="width: 100%; height: 100%;"
                          class="ag-theme-custom ag-theme-custom-stripped ag-line-pricing-grid"
                          :grid-options="gridOptions"
                          :column-defs="columnDefs"
                          :row-data="rowData"
                          :default-col-def="defaultColDef"
                          @grid-ready="onGridReady"
                        />
                      </div>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col>
                  <line-pricing-groups-list
                    v-if="scenario && architectureGroup"
                    :line-pricing-groups="mutableLinePricingGroups"
                    :active-line-pricing-group="activeLinePricingGroup"
                    :architecture-group-id="architectureGroup"
                    :scenario-key="scenario"
                    :disabled="!editable"
                    @setActiveLinePricingGroupIndex="setActiveLinePricingGroupIndex"
                    @deleteLinePricingGroup="deleteLinePricingGroup"
                    @deleteLinePricedProduct="deleteLinePricedProduct"
                  />
                  <v-col cols="10" class="text-right pl-0 pb-0 pt-0">
                    <v-btn
                      class="save-btn save"
                      color="success"
                      small
                      :disabled="isSaveBtnDisabled || !editable || isLoading"
                      :loading="isLoading"
                      depressed
                      @click="onSaveLinePricingGroups"
                    >
                      {{ $t('actions.save') }}
                    </v-btn>
                  </v-col>
                </v-col>
              </v-row>
            </v-container>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import {
  find,
  isEmpty,
  some,
  isEqual,
  cloneDeep,
  get,
  flattenDeep,
  map,
  reduce,
  pick,
  omit,
  assign,
  debounce,
  keyBy,
  size,
  forEach,
  compact,
  filter,
  findIndex,
} from 'lodash';
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import selectAllHeaderCheckbox from '../../../../components/ag-grid-cell-renderers/ag-grid-header-checkbox.vue';
import { truncationLength } from '@sharedModules/config/products-grid';
import { truncateString } from '../../../../utils/truncate-string-util';
import isTooltipDisabled from '../../../../utils/tooltip-util';
import agGridUtils from '../../../../utils/ag-grid-utils';
import hierarchyFiltersMixin from '../../../../mixins/hierarchyFilters';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import tooltipFormatterMixin from '../../../../mixins/tooltipFormatter';
import numberFormats from '@enums/number-formats';
import { useZones, includeNormWeight } from '@enums/feature-flags';
import priceTypes from '@enums/price-types';

export default {
  components: {
    AgGridVue,
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    selectAllHeaderCheckbox,
  },

  mixins: [hierarchyFiltersMixin, featureFlagsMixin, tooltipFormatterMixin],

  props: {
    disabled: {
      type: Boolean,
      required: true,
    },

    editable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      mutableLinePricingGroups: [],
      value: null,
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      columnApi: null,
      columnDefs: null,
      storeGroupGrids: null,
      rowData: null,
      defaultColDef: null,
      components: null,
      errors: {},
      updates: {},
      expandedMainPricingStoreGroups: new Set(),
    };
  },

  computed: {
    ...mapState('architectureGroup', [
      'filteredAttributes',
      'linePricingGroups',
      'activeLinePricingGroupIndex',
      'selectedProducts',
      'loading',
      'architectureProductsLoading',
    ]),
    ...mapState('filters', [
      'architectureGroup',
      'scenario',
      'pricingGroup',
      'selectedPriceType',
      'architectureRulesLinePricingFilter',
    ]),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),
    ...mapGetters('architectureGroup', ['linePricingArchitectureProducts', 'hasMultipleSubGroups']),
    ...mapGetters('hierarchy', ['getSubGroupsForProduct']),

    filteredProductsById() {
      return keyBy(this.getFilteredProducts, '_id');
    },

    selectedProductIds() {
      return new Set(map(this.selectedProducts, '_id'));
    },

    isAllToggled() {
      return size(this.selectedProducts) === size(this.rowData) && !!size(this.rowData);
    },

    // always filter by AG, regardless of other applied filters
    fixedFilterRules() {
      const architectureGroupFilter = { attributeValue: [this.architectureGroup] };
      return [Object.assign(this.getArchitectureGroupFilterDefaults(), architectureGroupFilter)];
    },

    productsCount() {
      return size(this.getFilteredProducts);
    },

    isAddBtnDisabled() {
      return (
        this.activeLinePricingGroup === null || !this.selectedProducts.length || !this.editable
      );
    },

    failsSameSubGroupValidation() {
      return some(this.mutableLinePricingGroups, lpg =>
        this.hasMultipleSubGroups(get(lpg, 'products'))
      );
    },

    isSaveBtnDisabled() {
      // Check for missing line price factor value
      const isLinePriceFactorInvalid = product => product.linePriceFactor <= 0;
      const products = flattenDeep(map(this.mutableLinePricingGroups, g => g.products));
      const productsHasErrors = some(products, isLinePriceFactorInvalid);

      // Check that groups have a description
      const groupsMissingDescription = map(
        this.mutableLinePricingGroups,
        g => g.linePricedGroupDescription !== '' && g.linePricedGroupDescription !== null
      );
      const groupsHasErrors = some(groupsMissingDescription, d => d === false);

      // Check products are selected
      const productsAreSelected = products.length > 0;

      return (
        !productsAreSelected ||
        groupsHasErrors ||
        productsHasErrors ||
        isEqual(this.linePricingGroups, this.mutableLinePricingGroups) ||
        this.failsSameSubGroupValidation
      );
    },

    activeLinePricingGroup() {
      return get(this.mutableLinePricingGroups, this.activeLinePricingGroupIndex, null);
    },

    isDisabled() {
      return this.disabled || !this.editable;
    },

    isLoading() {
      return this.loading || this.architectureProductsLoading;
    },

    /**
     * to filter products we retrieve filtered attributes,
     * then we display only that products that have this attribute values
     */
    getFilteredProducts() {
      if (isEmpty(this.filteredAttributes) && isEmpty(this.mutableLinePricingGroups)) {
        return this.linePricingArchitectureProducts;
      }

      return this.linePricingArchitectureProducts.filter(product => {
        return !this.isProductFiltered(product) && !this.isLinePricedProduct(product);
      });
    },
  },

  watch: {
    disabled(isDisabled) {
      if (isDisabled) this.value = null;
    },

    loading(isLoading) {
      this.toggleLoadingOverlay(isLoading);
    },

    architectureProductsLoading(isLoading) {
      this.toggleLoadingOverlay(isLoading);
    },

    linePricingGroups: {
      deep: true,
      handler(newValue) {
        if (!isEqual(newValue, this.mutableLinePricingGroups)) {
          this.mutableLinePricingGroups = cloneDeep(newValue);
        }
      },
    },

    getFilteredProducts: {
      deep: true,
      handler() {
        this.rowData = this.prepareNewGridRowData();
        const visibleSelectedRows = filter(this.filteredProductsById, (product, key) =>
          this.selectedProductIds.has(key)
        );
        this.setSelectedProducts({ products: visibleSelectedRows });

        const { columnDefs } = this.gridOptions;
        if (columnDefs) {
          const isSelectedColumnIndex = findIndex(
            columnDefs,
            column => column.field === 'isSelected'
          );
          columnDefs[isSelectedColumnIndex].headerComponentParams.selected =
            size(this.rowData) === size(visibleSelectedRows) && !!size(this.rowData);
          this.gridOptions.api.setColumnDefs(columnDefs);
        }
      },
    },

    selectedPriceType() {
      this.rowData = this.prepareNewGridRowData();
    },
  },

  beforeMount() {
    this.gridOptions = {
      suppressContextMenu: true,
      tooltipShowDelay: 500,
      suppressMovableColumns: true,
      suppressFieldDotNotation: true,
      suppressRowDrag: true,
      suppressPropertyNamesCheck: true,
      rowHeight: 30,
      headerHeight: 40,
      suppressRowClickSelection: true,
    };

    const numericComparator = (valueA, valueB) => {
      return this.formatStringToNumber(valueA) - this.formatStringToNumber(valueB);
    };

    const headers = [
      {
        field: 'productKey',
        headerName: this.$t('settings.architecture.productKey'),
        sort: 'asc',
        width: 110,
      },
      this.isFeatureFlagEnabled(useZones)
        ? {
            field: 'toolStoreGroupDescription',
            headerName: this.$t('settings.architecture.toolStoreGroup'),
            width: 110,
            ...agGridUtils.getTruncatedValueGetters({
              truncationLength: get(truncationLength, 'toolStoreGroupDescription'),
            }),
          }
        : null,
      {
        field: 'description',
        headerName: this.$t('settings.architecture.productDescription'),
        cellClass: 'bold-text',
        ...agGridUtils.getTruncatedValueGetters({
          truncationLength: get(truncationLength, 'architectureProducts'),
        }),
        width: 190,
      },
      {
        field: 'normWeightWithMeasure',
        headerName: this.$t('settings.architecture.productSize'),
        valueGetter: params => {
          // size -> productSize to avoid confusions and linter errors with lodash size()
          const { size: productSize } = params.data;
          const { wasTruncated, truncatedValue } = truncateString({
            text: productSize,
            truncationLength: get(truncationLength, 'normWeight'),
          });
          return wasTruncated ? truncatedValue : productSize;
        },
        tooltipValueGetter: params => {
          const formattedUOMValue = this.formatMetricContainedUnitOfMeasurement(
            params.data.size,
            numberFormats.weight
          );
          const pricePerNormWeightText = {
            [this.$t('gridView.priceToggles.pricePerNormWeight')]: `${this.formatNumber({
              number: params.data.normWeight,
              format: numberFormats.weight,
              useZeroAsDash: true,
            })} ${params.data.normWeightUnitOfMeasure}`,
          };
          const productSizeText = {
            [this.$t('pricing.productSizeType')]: formattedUOMValue,
          };
          const displayNormWeight = params.data.normWeight && this.toggleLogic[includeNormWeight];
          const shouldTruncate = !isTooltipDisabled(
            formattedUOMValue,
            get(truncationLength, 'normWeight')
          );
          if (shouldTruncate && displayNormWeight) {
            return this.formatTooltipMessage({
              ...productSizeText,
              ...pricePerNormWeightText,
            });
          }
          if (shouldTruncate) return formattedUOMValue;
          if (displayNormWeight) return this.formatTooltipMessage(pricePerNormWeightText);
          return '';
        },
        flex: 1,
      },
      {
        field: 'packageTypeDescription',
        headerName: this.$t('settings.architecture.packageTypeDescription'),
        flex: 1,
      },
      {
        field: 'costPrice',
        headerName: this.$t('settings.architecture.costPrice'),
        comparator: numericComparator,
        flex: 1,
      },
      {
        field: 'livePrice',
        headerName: this.$t('settings.architecture.livePrice'),
        comparator: numericComparator,
        flex: 1,
      },
      {
        field: 'subGroup',
        headerName: this.$t(
          'settings.setArchitectureRules.architectureDriversRules.productTable.subGroup'
        ),
        flex: 1,
      },
      {
        field: 'isSelected',
        headerName: '',
        cellClass: 'justify-center',
        headerComponent: 'selectAllHeaderCheckbox',
        headerComponentParams: {
          changeHandler: this.toggleAll,
          selected: this.isAllToggled,
        },
        cellRenderer: params => agGridUtils.utils.checkboxRenderer(params, this.onProductSelection),
        cellRendererParams: {
          field: 'isSelected',
          disabled: !this.editable,
        },
        sortable: false,
        width: 50,
      },
    ];

    this.columnDefs = compact(headers);
    this.defaultColDef = {
      suppressMenu: true,
      editable: false,
      resizable: false,
      suppressMovable: true,
    };
  },

  async created() {
    const newTabOpenLinePricingRules = await this.consumeNewTabOpenLinePricingRules();

    if (newTabOpenLinePricingRules) {
      this.value = 0; // open the panel
      this.getFilteredAttributes({
        params: { where: [...this.fixedFilterRules, ...this.architectureRulesLinePricingFilter] },
        additionalFilters: { architectureGroupId: this.architectureGroup },
      });
    }
  },

  methods: {
    ...mapActions('architectureGroup', [
      'getFilteredAttributes',
      'saveLinePricingGroupsWithProducts',
      'setActiveLinePricingGroupIndex',
      'deleteLinePricingGroup',
      'deleteLinePricedProduct',
      'clearSelectedProducts',
      'consumeNewTabOpenLinePricingRules',
      'addSelectedProduct',
      'removeSelectedProduct',
      'setSelectedProducts',
    ]),
    ...mapActions('filters', ['setSelectedFilter']),

    prepareNewGridRowData() {
      return map(this.getFilteredProducts, product => ({
        id: product._id,
        productKey: product.productKeyDisplay,
        toolStoreGroupDescription: get(this.storegroupKeyNameMap, product.toolStoreGroupKey),
        description: product.description,
        size: product.size,
        normWeight: product.normWeight,
        normWeightUnitOfMeasure: product.normWeightUnitOfMeasure,
        packageTypeDescription: product.packageTypeDescription,
        costPrice: this.formatNumber({
          number: this.getIntentionCost(product),
          format: numberFormats.priceFormat,
          nullAsDash: true,
        }),
        livePrice: this.formatNumber({
          number: this.getLivePrice(product),
          format: numberFormats.priceFormat,
          nullAsDash: true,
        }),
        subGroup: this.getSubGroupsForProduct({
          productKey: product.productKey,
          toolStoreGroupKey: product.toolStoreGroupKey,
        }),
        isSelected: this.selectedProductIds.has(product._id),
      }));
    },

    toggleLoadingOverlay(isLoading) {
      if (!this.gridOptions.api) return;
      if (isLoading) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },

    async onProductSelection(params) {
      const value = !params.value;
      params.setValue(value);
      const productId = get(params, 'data.id');
      const product = this.filteredProductsById[productId];
      if (value) await this.addSelectedProduct({ product });
      else await this.removeSelectedProduct({ product });
      params.colDef.headerComponentParams.selected = this.isAllToggled;
      this.gridApi.refreshHeader();
    },

    toggleAll() {
      if (this.isAllToggled) this.clearSelectedProducts();
      else this.setSelectedProducts({ products: this.getFilteredProducts.slice() });

      forEach(this.rowData, row => {
        row.isSelected = this.isAllToggled;
      });
      this.gridApi.refreshCells({ columns: ['isSelected'], force: true });
    },

    getLivePrice(item) {
      const field = this.getPriceFieldCustom();
      return item.livePrice[field];
    },

    getIntentionCost(item) {
      const field = this.getPriceFieldCustom();
      return item.intentionCost[field];
    },

    getPriceFieldCustom() {
      // TODO PRICE-308:
      // when this component is changed to use aggregatedscenarioresults, remove this function and use the mixin
      if (this.selectedPriceType === priceTypes.uom) return 'pricePerContentUnit';
      if (this.selectedPriceType === priceTypes.normWeight) return 'pricePerNormWeight';
      return 'price';
    },

    sizeColumnsToFit: debounce(function() {
      this.gridApi.sizeColumnsToFit();
    }, 300),

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    panelOpenedOrClosed() {
      // the API of the expansion panels isn't great, 0 means the panel is expanding
      this.value = this.value === 0 ? null : 0;
    },

    isProductFiltered(product) {
      return isEmpty(this.filteredAttributes)
        ? false
        : !find(
            this.filteredAttributes,
            attribute =>
              attribute.productKey === product.productKey &&
              attribute.toolStoreGroupKey === product.toolStoreGroupKey
          );
    },

    isLinePricedProduct(product) {
      return isEmpty(this.mutableLinePricingGroups)
        ? false
        : some(this.mutableLinePricingGroups, linePricingGroup =>
            some(
              linePricingGroup.products,
              linePricedProduct =>
                linePricedProduct.productKey === product.productKey &&
                linePricedProduct.toolStoreGroupKey === product.toolStoreGroupKey
            )
          );
    },

    setAttributeFilter(filters) {
      this.setSelectedFilter({
        filterName: 'architectureRulesLinePricingFilter',
        filterValue: filters,
      });
      this.getFilteredAttributes({
        params: { where: filters },
        additionalFilters: { architectureGroupId: this.architectureGroup },
      });
    },

    addProducts() {
      const addedProducts = this.selectedProducts.map(product => ({
        _id: null,
        // workpackageId will be added in the store
        scenarioKey: this.activeLinePricingGroup.scenarioKey,
        linePricingGroupKey: get(this.activeLinePricingGroup, 'linePricingGroupKey', null),
        linePriceFactor: 1.0,
        productKey: get(product, 'productKey'),
        productKeyDisplay: get(product, 'productKeyDisplay'),
        description: get(product, 'description'),
        toolStoreGroupKey: product.toolStoreGroupKey,
        toolStoreGroupDescription: product.toolStoreGroupDescription,
        size: get(product, 'size'),
        livePrice: get(product, 'livePrice'),
        intentionCost: get(product, 'intentionCost'),
        packageTypeDescription: get(product, 'packageTypeDescription'),
      }));

      this.activeLinePricingGroup.products = this.activeLinePricingGroup.products.concat(
        addedProducts
      );

      this.clearSelectedProducts();
    },

    formatLinePricingGroupsForSave() {
      return reduce(
        this.mutableLinePricingGroups,
        (lpgResult, linePricingGroup) => {
          const productPickKeys = [
            'productKey',
            'productKeyDisplay',
            'toolStoreGroupKey',
            'scenarioKey',
            'linePricingGroupKey',
            'linePriceFactor',
          ];
          const linePricingGroupProducts = map(linePricingGroup.products, p =>
            pick(p, productPickKeys)
          );
          // a new linePricingGroup doesn't have linePricingGroupKey
          if (!get(linePricingGroup, 'linePricingGroupKey')) {
            const linePricingGroupToCreate = omit(linePricingGroup, 'products');
            linePricingGroupToCreate.products = linePricingGroupProducts;
            (
              lpgResult.linePricingGroupsToCreate || (lpgResult.linePricingGroupsToCreate = [])
            ).push(linePricingGroupToCreate);
          } else {
            // linePricingGroup is already existed in db
            let savedLinePricingGroup = find(
              this.linePricingGroups,
              lpg => lpg.linePricingGroupKey === linePricingGroup.linePricingGroupKey
            );
            const savedLinePricingGroupProducts = map(savedLinePricingGroup.products, p =>
              pick(p, productPickKeys)
            );
            savedLinePricingGroup = omit(savedLinePricingGroup, 'products');
            const linePricingGroupToUpdate = {};
            // check has line pricing group changed
            if (!isEqual(omit(linePricingGroup, 'products'), savedLinePricingGroup)) {
              assign(linePricingGroupToUpdate, omit(linePricingGroup, 'products'));
            }
            // check have line-priced products changed
            if (!isEqual(linePricingGroupProducts, savedLinePricingGroupProducts)) {
              linePricingGroupToUpdate.products = reduce(
                linePricingGroupProducts,
                (result, p) => {
                  const savedProduct = find(savedLinePricingGroupProducts, sp => {
                    return (
                      p.productKey === sp.productKey && p.toolStoreGroupKey === sp.toolStoreGroupKey
                    );
                  });
                  if (!isEqual(p, savedProduct)) {
                    result.push(p);
                  }
                  return result;
                },
                []
              );
            }
            if (!isEmpty(linePricingGroupToUpdate)) {
              (
                lpgResult.linePricingGroupsToUpdate || (lpgResult.linePricingGroupsToUpdate = [])
              ).push(linePricingGroupToUpdate);
            }
          }
          return lpgResult;
        },
        {}
      );
    },

    onSaveLinePricingGroups() {
      this.saveLinePricingGroupsWithProducts({
        linePricingGroups: this.formatLinePricingGroupsForSave(),
      });
    },
  },

  events: {
    onArchitectureRulesFilterUpdated() {
      // don't carry selected products to changed architecture group
      this.clearSelectedProducts();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.line-pricing {
  box-shadow: none;

  .expansion-body {
    padding: 0;
    background-color: $pricing-background;
  }
}

.products-header {
  span {
    font-size: 1.4rem;

    &.products-count {
      font-size: 1.2rem;
    }
  }
}

.add-products-btn.v-btn {
  margin-right: 0;
  text-transform: capitalize;
}

.justify-between {
  display: flex;
  justify-content: space-between;
}

::v-deep {
  .ag-grid-box {
    height: 50rem;
    width: 100%;
  }

  .ag-line-pricing-grid {
    .ag-root-wrapper {
      border: none;
    }

    .ag-tooltip {
      white-space: pre-line !important;
    }

    .ag-row-hover {
      background: $grid-view-hover-color !important;
      background-color: $grid-view-hover-color !important;
    }

    &.ag-theme-custom .ag-row-selected {
      &.ag-row-even {
        background: $custom-ag-grid-even-rows-background-color;
      }

      &.ag-row-odd {
        background: $custom-ag-grid-odd-rows-background-color;
      }
    }

    .ag-cell:first-child {
      padding-left: 10px;
    }

    .ag-header-cell {
      padding-left: 0;

      &:first-child {
        padding-left: 10px;

        .ag-header-cell-text {
          color: rgba(0, 0, 0, 0.87);
          font-weight: bold;
        }
      }

      &:last-child {
        padding-left: 11px;
      }

      .ag-header-cell-text {
        color: rgba(0, 0, 0, 0.54);
        font-weight: bold;
        white-space: normal;
      }
    }
  }
}
</style>
