import { VCol } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("tr", { staticClass: "hierarchy__row hierarchy__row--headers" }, [
    _c(
      "th",
      { staticClass: "border-right first-column" },
      [
        _c(
          VRow,
          {
            staticClass: "flex-nowrap",
            attrs: { justify: "end", "no-gutters": "" },
          },
          [
            _c(
              "feature-toggle",
              { attrs: { toggle: _vm.displayHistoricalDropdown } },
              [
                _c(
                  VCol,
                  {
                    staticClass:
                      "text-right flex-shrink-1 mr-3 header__historical-periods",
                    attrs: { cols: "3" },
                  },
                  [
                    _c(VSelect, {
                      staticClass: "float-right mt-0",
                      attrs: {
                        value: _vm.selectedPeriod,
                        items: _vm.historicPeriodsOptions,
                        label: _vm.$t(`gridView.historicPeriods.label`),
                        disabled: _vm.timeFlexibleAggregationsLoading,
                        loading: _vm.timeFlexibleAggregationsLoading,
                        "single-line": "",
                        dense: "",
                        "hide-details": "",
                      },
                      on: { input: _vm.setSelectedHistoricPeriod },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              VCol,
              {
                staticClass: "flex-grow-1 align-self-center header__toggles",
                attrs: { cols: "1" },
              },
              [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.displayWeightToggle } },
                  [
                    _c(VSwitch, {
                      attrs: {
                        label: _vm.$t(
                          `gridView.weightings.${_vm.weightingOptions[1]}`
                        ),
                        "input-value": _vm.selectedWeighting,
                        "true-value": _vm.weightingOptions[1],
                        "false-value": _vm.weightingOptions[0],
                        primary: "",
                        "hide-details": "",
                      },
                      on: { change: _vm.toggleCalculation },
                    }),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(VSwitch, {
                  staticClass: "pb-1",
                  attrs: {
                    label: _vm.$t(`gridView.regularImpactToggle.label`),
                    "input-value": _vm.showRegularImpact,
                    primary: "",
                    "hide-details": "",
                  },
                  on: { change: _vm.toggleRegularImpact },
                }),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.salesMAT"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.promoShare"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(
        "\n    " +
          _vm._s(
            _vm.$t(
              `gridView.${
                _vm.showRegularImpact ? "regularSalesImpact" : "salesImpactMAT"
              }`
            )
          ) +
          "\n  "
      ),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number border-right" }, [
      _vm._v(_vm._s(_vm.$t("gridView.totalCostImpact"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.proposedMargin"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number border-right" }, [
      _vm._v(_vm._s(_vm.$t("gridView.delta"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.target"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.live"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.proposed"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number border-right" }, [
      _vm._v(_vm._s(_vm.$t("gridView.delta"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.target"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.live"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number" }, [
      _vm._v(_vm._s(_vm.$t("gridView.proposed"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number border-right" }, [
      _vm._v(_vm._s(_vm.$t("gridView.delta"))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-number border-right" }, [
      _vm._v(_vm._s(_vm.$t(_vm.tensionLabelTranslation))),
    ]),
    _vm._v(" "),
    _c("th", { staticClass: "fixed-width-chip" }),
    _vm._v(" "),
    _c("th"),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }