<template>
  <span>
    <v-btn
      color="primary"
      small
      depressed
      class="pa-0"
      :loading="busyImportingPriceOverride"
      @click.stop="initiateModal()"
    >
      {{ $t('priceOverrides.upload.uploadButton') }}
      <v-icon small>$import</v-icon>
    </v-btn>
    <v-dialog v-model="uploadDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('priceOverrides.upload.dialogHeading') }}
        </v-card-title>
        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          source="inputs"
          @save="save"
        />
        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    {{ $t('priceOverrides.upload.inputsReceived') }}:
                    <b>{{ uploadCounts.articlesReceived }}</b>
                    <br />
                    {{ $t('attributes.upload.confirmUpload') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" outlined @click="closeModal()">{{ $t('actions.cancel') }}</v-btn>
            <v-btn
              class="primary"
              :disabled="isUploading"
              :loading="busyImportingPriceOverride"
              @click="confirm()"
            >
              {{ $t('actions.upload') }}
            </v-btn>
          </v-card-actions>
        </div>
        <div v-if="isUploading">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">
                    {{ $t('attributes.upload.uploadingState') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
        <div
          v-if="
            isFailed &&
              !(
                duplicateKeysError ||
                incorrectTSGsError ||
                productKeysWithExpiryEffectiveDateErrors
              )
          "
        >
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div v-if="uploadError.tooManyRows" class="flex xs12">
                  <div class="v-input">
                    {{ $t('priceOverrides.upload.tooManyRows', { maxRows }) }}
                  </div>
                </div>
                <div v-else class="flex xs12">
                  <div class="v-input">
                    {{ errorDisplayMessage }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
        <!-- FEATURE_FLAG: display zones-related validation errors -->
        <feature-toggle :toggle="useZones">
          <div v-if="duplicateKeysError || incorrectTSGsError">
            <v-card-text>
              <div class="container grid-list-md">
                <div class="layout wrap">
                  <upload-error-card
                    v-if="duplicateKeysError"
                    :error-message="$t('priceOverrides.upload.conflictedToolStoreGroupKeys')"
                    :details="uploadError.duplicateProductKeys"
                    unique-key-prefix="conflictToolStoreGroupKeys"
                  />
                  <upload-error-card
                    v-if="incorrectTSGsError"
                    :error-message="$t('priceOverrides.upload.incorrectToolStoreGroups')"
                    :details="uploadError.productKeysWithIncorrectTSGs"
                    unique-key-prefix="incorrectToolStoreGroups"
                  />
                </div>
              </div>
            </v-card-text>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
            </v-card-actions>
          </div>
        </feature-toggle>
        <div v-if="productKeysWithExpiryEffectiveDateErrors">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <upload-error-card
                  v-if="productKeysWithExpiryEffectiveDateErrors"
                  :error-message="$t('engineInputs.upload.expiryEffectiveDateErrors')"
                  :details="uploadError.productKeysWithExpiryEffectiveDateErrors"
                  unique-key-prefix="expiryEffectiveDateErrors"
                />
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { isEmpty, get } from 'lodash';
import states from '@enums/upload-states';
import { useZones } from '@enums/feature-flags';

export default {
  props: {
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      uploadDialog: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
      useZones,
    };
  },
  computed: {
    ...mapState('scenarioProducts', ['uploadCounts', 'busyImportingPriceOverride']),
    ...mapState('clientConfig', ['uploadConfig']),

    maxRows() {
      return this.uploadConfig.attributeUpload.maxRows;
    },
    isInitial() {
      return this.currentStatus === states.initial;
    },
    isSaving() {
      return this.currentStatus === states.saving;
    },
    isValidated() {
      return this.currentStatus === states.validated;
    },
    isFailed() {
      return this.currentStatus === states.failed;
    },
    noPriceOverrides() {
      return (
        this.currentStatus === states.failed && get(this.uploadError, 'noPriceOverrides', false)
      );
    },
    duplicateKeysError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'duplicateProductKeys', []))
      );
    },
    incorrectTSGsError() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithIncorrectTSGs', []))
      );
    },
    productKeysWithExpiryEffectiveDateErrors() {
      return (
        this.currentStatus === states.failed &&
        !isEmpty(get(this.uploadError, 'productKeysWithExpiryEffectiveDateErrors', []))
      );
    },
    isUploading() {
      return this.currentStatus === states.uploading;
    },
    errorDisplayMessage() {
      return this.noPriceOverrides
        ? this.$t('attributes.upload.noPriceOverrides')
        : this.$t('attributes.upload.genericError');
    },
  },
  methods: {
    ...mapActions('scenarioProducts', ['uploadPriceOverrides', 'uploadPriceOverridesConfirm']),

    initiateModal() {
      this.uploadDialog = true;
      this.reset();
    },
    closeModal() {
      this.uploadDialog = false;
      this.reset();
    },
    save(formData) {
      this.currentStatus = states.saving;
      // ensures we can upload regardless of language
      const translationMap = {
        overrideDate: this.$t('priceOverrides.editor.overrideDate'),
        price: this.$t('priceOverrides.editor.price'),
        referencePriceAtOverride: this.$t('priceOverrides.editor.referencePriceAtOverride'),
        expiryDate: this.$t('priceOverrides.editor.expiryDate'),
        effectiveDate: this.$t('priceOverrides.editor.effectiveDate'),
      };
      return this.uploadPriceOverrides({
        dataFile: formData,
        params: this.params,
        translationMap,
      })
        .then(() => {
          this.currentStatus = states.validated;
        })
        .catch(err => {
          this.uploadError = err;
          this.currentStatus = states.failed;
        });
    },
    confirm() {
      this.currentStatus = states.uploading;
      return this.uploadPriceOverridesConfirm()
        .then(() => {
          this.closeModal();
          this.reset();
        })
        .catch(err => {
          this.uploadError = err.response;
          this.currentStatus = states.failed;
        });
    },
    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}
</style>
