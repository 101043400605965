'use strict';

const blacklistedAlerts = new Set([
  'missingHistoricalData52WeeksLookBack',
  'missingHistoricalData13WeeksLookBack',
  'missingHistoricalData4WeeksLookBack',
  'missingHistoricalData2WeeksLookBack',
  'missingHistoricalData1WeeksLookBack',
  'missingHistoricalData13WeeksLookForward',
  'missingHistoricalData4WeeksLookForward',
  'missingHistoricalData2WeeksLookForward',
  'missingHistoricalData1WeeksLookForward',
]);

module.exports = {
  // Need to match up with alert-types.yaml
  categoryManagerPermissions: [
    'outlier',
    'priceIncreasingTooFast',
    'significantCompetitorMove',
    'manualOverride',
    'productOnPromotion',
    'productBeforePromotion',
    'priceBelowArchitectureMin',
    'priceAboveArchitectureMax',
    'linePricingGroupOverride',
    'missingEngineInput',
    'invalidEngineInput',
  ],
  blacklistedAlerts,
};
