var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    _vm.params.value.component,
    _vm._b({ tag: "component" }, "component", _vm.params.value.props, false)
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }