<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Architecture order</title>
    <g
      id="architecture-order-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="architecture-order-transform" transform="translate(-138.000000, -520.000000)">
        <g id="architecture-order" transform="translate(138.000000, 520.000000)">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            id="Combined-Shape"
            d="M6,17 C7.1045695,17 8,17.8954305 8,19 L8,21 C8,22.1045695 7.1045695,23 6,23 L4,23
            C2.8954305,23 2,22.1045695 2,21 L2,19 C2,17.8954305 2.8954305,17 4,17 L6,17 Z M14,0 L14,3
            L19,3 C20.6569,3 22,4.34315 22,6 L22,18 C22,19.6569 20.6569,21 19,21 L11,21 C10.4477,21
            10,20.5523 10,20 C10,19.4477 10.4477,19 11,19 L19,19 C19.5523,19 20,18.5523 20,18 L20,6
            C20,5.44772 19.5523,5 19,5 L14,5 L14,8 L10,4 L14,0 Z M6,9 C7.10457,9 8,9.89543 8,11 L8,11
            L8,13 C8,14.1046 7.10457,15 6,15 L6,15 L4,15 C2.89543,15 2,14.1046 2,13 L2,13 L2,11
            C2,9.89543 2.89543,9 4,9 L4,9 Z M6,11 L4,11 L4,13 L6,13 L6,11 Z M6,1 C7.10457,1 8,1.89543
            8,3 L8,3 L8,5 C8,6.10457 7.10457,7 6,7 L6,7 L4,7 C2.89543,7 2,6.10457 2,5 L2,5 L2,3
            C2,1.89543 2.89543,1 4,1 L4,1 Z M6,3 L4,3 L4,5 L6,5 L6,3 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ArchitectureOrder',
};
</script>
