import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.initialLoading
        ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
        : _vm._e(),
      _vm._v(" "),
      _c("div", { staticClass: "d-flex justify-start mx-1" }, [
        _c(
          "div",
          { staticClass: "d-flex" },
          [
            _c("span", { staticClass: "root" }, [
              _vm._v(
                " " + _vm._s(_vm.$t("wholesale.historicPeriodsLabel")) + ":"
              ),
            ]),
            _vm._v(" "),
            _c(VSelect, {
              staticClass: "label-part grid-dropdown",
              attrs: {
                value:
                  _vm.selectedHistoricalPeriod || _vm.defaultHistoricalPeriod,
                items: _vm.historicPeriodsOptions,
                "menu-props": {
                  contentClass: "margin-splits-grids-menu",
                },
                loading: _vm.loading,
                disabled: _vm.loading,
              },
              on: { input: _vm.setSelectedHistoricPeriod },
            }),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c("wholesale-hierarchy", {
        staticClass: "wholesale-hierarchy",
        attrs: { "row-data": _vm.tableRows, "is-loading": _vm.loading },
        on: {
          toggleLevel: _vm.onToggleExpansion,
          toggleStoreGroup: _vm.toggleStoreGroupExpansion,
          gridReady: function ($event) {
            _vm.initialLoading = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }