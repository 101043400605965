import { render, staticRenderFns } from "./attribute-create-update-dialog.vue?vue&type=template&id=32c19728&scoped=true"
import script from "./attribute-create-update-dialog.vue?vue&type=script&lang=js"
export * from "./attribute-create-update-dialog.vue?vue&type=script&lang=js"
import style0 from "./attribute-create-update-dialog.vue?vue&type=style&index=0&id=32c19728&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "32c19728",
  null
  
)

export default component.exports