import { VCheckbox } from 'vuetify/lib/components/VCheckbox';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      class: [_vm.isCategoryLevel ? "categoryLevel" : ""],
      staticStyle: { display: "contents" },
    },
    [
      _c("td", [
        _c("div", { staticClass: "d-flex justify-space-between" }, [
          !_vm.isCategoryLevel
            ? _c(
                "div",
                {
                  staticClass: "clickable category-heading",
                  on: {
                    click: function ($event) {
                      return _vm.$emit("toggle")
                    },
                  },
                },
                [
                  _c("i", {
                    class: [
                      _vm.expanded
                        ? "mdi mdi-chevron-down"
                        : "mdi mdi-chevron-right",
                    ],
                  }),
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$tc(`hierarchy.hierarchyLevels.${_vm.level + 1}`, 2)
                      ) +
                      "\n      "
                  ),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.isCategoryLevel
            ? _c("div", { staticClass: "my-auto hierarchy-heading" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(
                      _vm.$tc(`hierarchy.hierarchyLevels.${_vm.level + 1}`, 2)
                    ) +
                    "\n      "
                ),
              ])
            : _vm._e(),
          _vm._v(" "),
          _c(
            "div",
            { staticClass: "actions" },
            [
              _vm.isCategoryLevel
                ? _c(
                    "div",
                    {
                      attrs: {
                        "custom-title-msg": _vm.$t(
                          "gridView.tooltips.pricingSpecialistRelease"
                        ),
                        "custom-title-position": "top-right",
                      },
                    },
                    [
                      _c(VCheckbox, {
                        staticClass: "ma-0 checkbox-all",
                        attrs: {
                          readonly:
                            _vm.allCategoryPricingGroupsReleased ||
                            !_vm.pricingSpecialistAndManagerCategoryLevel ||
                            _vm.isSelectedWorkpackageMaster,
                          "input-value": _vm.selectedOrReleased,
                          dense: "",
                          disabled:
                            _vm.allCategoryPricingGroupsReleased ||
                            !_vm.pricingSpecialistAndManagerCategoryLevel ||
                            _vm.isSelectedWorkpackageMaster,
                          color: "success",
                        },
                        on: {
                          change: function ($event) {
                            return _vm.toggleCategory()
                          },
                        },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "prepend",
                              fn: function () {
                                return [
                                  _c("span", { staticClass: "release-text" }, [
                                    _vm._v(_vm._s(_vm.releaseOrReleased)),
                                  ]),
                                ]
                              },
                              proxy: true,
                            },
                          ],
                          null,
                          false,
                          3930299189
                        ),
                      }),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isCategoryLevel
                ? _c("hierarchy-level-item-approvals", {
                    attrs: { approvals: _vm.approvals },
                  })
                : _vm._e(),
            ],
            1
          ),
        ]),
      ]),
      _vm._v(" "),
      _c("td", { attrs: { colspan: "14" } }, [
        _vm.isCategoryLevel
          ? _c("span", { staticClass: "release-text" }, [
              _vm._v(_vm._s(_vm.approveOrApproved)),
            ])
          : _vm._e(),
      ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }