import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "ag-menu-header" },
    [
      _c(
        VMenu,
        {
          attrs: { "offset-y": "" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on }) {
                return [
                  _c(
                    "icon",
                    _vm._g(
                      {
                        staticClass: "menu-icon",
                        attrs: { "icon-name": "menu-button-icon" },
                      },
                      on
                    )
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _c(
            VList,
            _vm._l(_vm.params.menuOptions, function (menuOption) {
              return _c(
                VListItem,
                {
                  key: menuOption.text,
                  on: {
                    click: function ($event) {
                      return menuOption.itemClick(menuOption.params)
                    },
                  },
                },
                [
                  _c(VListItemTitle, [
                    _vm._v(
                      "\n          " + _vm._s(menuOption.text) + "\n        "
                    ),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          staticClass: "ag-cell-label-container",
          class: `ag-sort-${_vm.sort}`,
        },
        [
          _c(
            "span",
            {
              ref: "menuButton",
              staticClass: "ag-header-icon ag-header-cell-menu-button",
              attrs: { "aria-hidden": "true" },
              on: { click: _vm.onMenuClicked },
            },
            [
              _c("span", {
                class: `ag-icon ag-icon-menu`,
                attrs: { role: "presentation" },
              }),
            ]
          ),
          _vm._v(" "),
          !_vm.params.unsortable && _vm.sort !== "none"
            ? _c(
                "span",
                {
                  staticClass: "ag-header-icon ag-header-label-icon",
                  class: `ag-sort-${_vm.sort}-icon`,
                  attrs: { "aria-hidden": "true" },
                },
                [
                  _c("span", {
                    class: `ag-icon ag-icon-${_vm.sortShort}`,
                    attrs: { role: "presentation" },
                  }),
                ]
              )
            : _vm._e(),
          _vm._v(" "),
          !_vm.params.unsortable && _vm.sort === "none"
            ? _c("div", { staticClass: "sort-none" })
            : _vm._e(),
          _vm._v(" "),
          _c("span", { on: { click: _vm.toggleSort } }, [
            _vm._v(_vm._s(_vm.params.displayName)),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }