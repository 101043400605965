var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Missing historical data")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "missing-historical-data-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "missing-historical-data-transform",
                transform: "translate(-375.000000, -520.000000)",
                "fill-rule": "nonzero",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "missing-historical-data",
                    transform: "translate(375.000000, 520.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M20,20 C20.5523,20 21,20.4477 21,21 C21,21.51285 20.613973,21.9355092 20.1166239,21.9932725 L20,22 C19.48715,22 19.0644908,21.613973 19.0067275,21.1166239 L19,21 C19,20.48715 19.386027,20.0644908 19.8833761,20.0067275 L20,20 Z M14.4591,1 L16.8427,5.45908 L14.3315,4.69741 L11.683,13.2944 C11.5745,13.6466 11.2811,13.9105 10.9195,13.9813 C10.5579,14.0522 10.1867,13.9184 9.95332,13.6332 L7.90893,11.1345 L5.14659,21.2631 C5.03922,21.6568 4.70341,21.9457 4.29811,21.9932 C3.89281,22.0407 3.49933,21.8371 3.30393,21.4788 L1.12211,17.4788 C0.857648,16.994 1.03631,16.3866 1.52115,16.1221 C2.006,15.8576 2.61344,16.0363 2.8779,16.5211 L3.8705,18.3409 L6.48979,8.73687 C6.58828,8.37573 6.88022,8.09984 7.24635,8.0219 C7.61247,7.94396 7.99147,8.07703 8.22851,8.36675 L10.3206,10.9237 L12.4176,4.11691 L10,3.38363 L14.4591,1 Z M20,8 C22.21,8 24,9.79 24,12 C24,12.88 23.64,13.68 23.07,14.25 L22.17,15.17 C21.6349,15.7125 21.2489,16.1833 21.086,17.005 C20.9868615,17.5050308 20.6159976,17.9297385 20.1251294,17.9921193 L20,18 C19.48715,18 19.0644908,17.613973 19.0067275,17.1166239 L19,17 C19,15.9 19.45,15.4 20.17,14.67 L21.41,13.41 C21.78,13.05 22,12.55 22,12 C22,10.9 21.1,10 20,10 C19.2466,10 18.5871,10.4221 18.2462,11.0416 C18.0020917,11.4850833 17.6224465,11.9208361 17.1354559,11.9903818 L17,12 C16.4477,12 15.9872,11.5453 16.1233,11.0101 C16.5636,9.27927 18.1317,8 20,8 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }