<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Long term intention price</title>
    <g
      id="long-term-intention-price-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="long-term-intention-price-transform" transform="translate(-138.000000, -719.000000)">
        <g id="long-term-intention-price" transform="translate(138.000000, 719.000000)">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            id="Shape"
            d="M11,1 C11,0.447715 11.4477,0 12,0 C12.5523,0 13,0.447715 13,1 L13,4.06189 C16.6187,4.51314 19.4869,7.38128 19.9381,11 L23,11 C23.5523,11 24,11.4477 24,12 C24,12.5523 23.5523,13 23,13 L19.9381,13 C19.4869,16.6187 16.6187,19.4869 13,19.9381 L13,23 C13,23.5523 12.5523,24 12,24 C11.4477,24 11,23.5523 11,23 L11,19.9381 C7.38128,19.4869 4.51314,16.6187 4.06189,13 L1,13 C0.447715,13 0,12.5523 0,12 C0,11.4477 0.447715,11 1,11 L4.06189,11 C4.51314,7.38128 7.38128,4.51314 11,4.06189 L11,1 Z M6.08296,13 L7,13 C7.55228,13 8,12.5523 8,12 C8,11.4477 7.55228,11 7,11 L6.08296,11 C6.50448,8.4875 8.4875,6.50448 11,6.08296 L11,7 C11,7.55228 11.4477,8 12,8 C12.5523,8 13,7.55228 13,7 L13,6.08296 C15.5125,6.50448 17.4955,8.4875 17.917,11 L17,11 C16.4477,11 16,11.4477 16,12 C16,12.5523 16.4477,13 17,13 L17.917,13 C17.4955,15.5125 15.5125,17.4955 13,17.917 L13,17 C13,16.4477 12.5523,16 12,16 C11.4477,16 11,16.4477 11,17 L11,17.917 C8.4875,17.4955 6.50448,15.5125 6.08296,13 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'LongTermIntentionPrice',
};
</script>
