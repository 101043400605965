<template>
  <v-card-text>
    <div class="container grid-list-md px-0">
      <div class="layout wrap">
        <div class="flex xs12 pb-3">
          <div class="v-input">{{ uploadParams.fileInstructions }}</div>
        </div>
        <div class="flex xs12 pb-4">
          <form enctype="multipart/form-data">
            <label class="file v-label">
              <input
                type="file"
                multiple
                :name="uploadFieldName"
                :disabled="isSaving"
                accept=".xlsx"
                class="input-file"
                @change="fileSelected($event.target.name, $event.target.files)"
              />
              <span class="file-custom" />
            </label>
          </form>
        </div>
        <div class="flex xs12 tips">
          <div>{{ uploadParams.helpfulText }}</div>
          <!-- FEATURE_FLAG: display a zones-related warning -->
          <feature-toggle v-if="!toolStoreGroupIndependent" :toggle="useZones">
            <span class="warning-text">{{ $t('attributes.upload.warning') }}</span>
            {{ $t('attributes.upload.toolStoreGroupsWarning') }}
          </feature-toggle>
        </div>
      </div>
    </div>
  </v-card-text>
</template>

<script>
import { mapGetters } from 'vuex';
import { each } from 'lodash';
import { useZones } from '@enums/feature-flags';

export default {
  props: {
    isSaving: {
      type: Boolean,
      required: true,
    },
    uploadFieldName: {
      type: String,
      required: true,
    },
    toolStoreGroupIndependent: {
      type: Boolean,
      default: false,
    },
    source: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      useZones,
    };
  },
  computed: {
    ...mapGetters('workpackages', ['getSelectedWorkpackage']),
    uploadParams() {
      if (this.source === 'inputs') {
        return {
          helpfulText: this.$t('attributes.upload.helpfulText'),
          fileInstructions: this.$t('attributes.upload.fileInstructions'),
          extraFields: {
            workpackageId: this.getSelectedWorkpackage._id,
            primaryColumn: this.$t('pricing.productKey'),
            toolStoreGroupColumn: this.$t('pricing.toolStoreGroup'),
            productDescriptionColumn: this.$t('pricing.productDescription'),
          },
        };
      }
      if (this.source === 'wholesale') {
        return {
          helpfulText: this.$t('wholesale.upload.helpfulText'),
          fileInstructions: this.$t('general.fileInstructions'),
          extraFields: {},
        };
      }
      return { helpfulText: '', extraFields: {} };
    },
  },
  methods: {
    fileSelected(fieldName, fileList) {
      const extraFields = this.uploadParams.extraFields;
      const formData = new FormData();
      if (!fileList.length) return;
      each(fileList, file => formData.append(fieldName, file, file.name));
      each(extraFields, (value, key) => formData.append(key, value));
      this.$emit('save', formData);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';

.file {
  position: relative;
  display: inline-block;
  cursor: pointer;
  height: 4rem;
  width: 100%;
}
.file input {
  min-width: 14rem;
  margin: 0;
  filter: alpha(opacity=0);
  opacity: 0;
}
.file-custom {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  z-index: 5;
  height: 4rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: #555;
  background-color: #fff;
  border: 0.075rem solid #ddd;
  border-radius: 0.25rem;
  box-shadow: inset 0 0.2rem 0.4rem rgba(0, 0, 0, 0.05);
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}
.file-custom:after {
  content: 'Choose file...';
}
.file-custom:before {
  position: absolute;
  top: -0.075rem;
  right: -0.075rem;
  bottom: -0.075rem;
  z-index: 6;
  display: block;
  content: 'Browse';
  height: 4rem;
  padding: 0.5rem 1rem;
  line-height: 1.5;
  color: white;
  background-color: $pricing-primary;
  border: 0.075rem solid $pricing-primary;
  border-radius: 0 0.25rem 0.25rem 0;
}

.v-btn {
  font-size: 1.25rem;
}

/* Focus */
.file input:focus ~ .file-custom {
  box-shadow: 0 0 0 0.075rem #fff, 0 0 0 0.2rem #0074d9;
}

.tips {
  text-align: justify;

  .warning-text {
    font-weight: 800;
  }
}
</style>
