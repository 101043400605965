<template>
  <div v-show="hasSelectedWorkpackage" class="input-screen-page-wrapper">
    <v-row no-gutters>
      <v-col>
        <engine-input-upload v-if="canEditAttributes" class="pb-2" :params="getBaseParams()" />
      </v-col>
      <v-col cols="3">
        <v-text-field
          class="search-field py-0"
          :value="search"
          append-icon="search"
          :label="$t('actions.search')"
          single-line
          @input="debounceSearchUpdate"
        />
      </v-col>
    </v-row>

    <!-- FEATURE_FLAG: temporary until attribute filter and hierarchy columns are released -->
    <feature-toggle :toggle="displayHierarchyAndFilter">
      <attribute-filter-panel
        title-localisation="attributes.filters.filterByAttributes"
        filter-count-localisation="attributes.filters.numApplied"
        enable-hierarchy-filters
        :filter-rules="retailAttributesFilter"
        @attributeFilterChange="setFilterRules"
      />
    </feature-toggle>

    <v-data-table
      :items="workpackageProductEngineInputs"
      :headers="headers"
      :loading="loading"
      :custom-filter="customFilter"
      :height="dataTableHeight"
      :loading-text="$t('general.loadingMessage')"
      disable-sort
      disable-pagination
      dense
      item-key="productKey"
      class="pricing-table elevation-1 striped-table"
      fixed-header
      hide-default-header
      hide-default-footer
      @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table__wrapper')"
    >
      <template v-slot:header="{ props }">
        <thead class="v-data-table-header">
          <tr>
            <th
              v-for="header in props.headers"
              :key="header.value"
              :class="[...getHeaderClasses(header, pagination), header.class]"
              @click="changeSort(header)"
            >
              <span>{{ header.text }}</span>
              <span v-if="header.unit">({{ header.unit }})</span>
              <span v-if="header.deprecated">({{ $t('general.deprecated') }})</span>
              <div>
                <v-icon v-if="header.sortable" size="1.4rem" class="v-data-table-header__icon">
                  arrow_upward
                </v-icon>
              </div>
            </th>
          </tr>
        </thead>
      </template>

      <template v-slot:item="props">
        <tr :key="props.index">
          <td class="text-xs table-cell" :class="fixedColumnsClass">
            {{ props.item.productKeyDisplay }}
          </td>
          <!-- FEATURE_FLAG: display a tool store group cell -->
          <td v-if="toolStoreGroupColumn" class="text-xs table-cell" :class="fixedColumnsClass">
            <tooltipped-truncated-field
              :text="props.item.toolStoreGroupDescription"
              :truncation-length="truncationLengthMedium"
              class="tooltipped-truncated-field"
            />
          </td>
          <td class="text-xs table-cell border-right" :class="fixedColumnsClass">
            {{ props.item.productName }}
          </td>

          <!-- FEATURE_FLAG Hierarchy columns -->
          <feature-toggle :toggle="displayHierarchyAndFilter">
            <td
              v-for="level in [categoryLevel, pricingGroupLevel, architectureGroupLevel]"
              :key="level"
              class="text-xs table-cell"
              :class="level === architectureGroupLevel ? 'border-right' : ''"
            >
              <tooltip
                :value="getHierarchyName(props.item, level)"
                :disabled="
                  isTooltipDisabled(getHierarchyName(props.item, level), truncationLengthMedium)
                "
              >
                {{ getHierarchyName(props.item, level) | truncate(truncationLengthMedium) }}
              </tooltip>
            </td>
          </feature-toggle>

          <!-- Engine input editable columns -->
          <td
            v-for="(value, input) in mandatoryEngineInputs"
            :key="input"
            class="text-xs table-cell"
          >
            <template v-if="value === mandatoryEngineInputs.intentionCost">
              <div class="intention-cost-cell">
                <pricing-edit-text-field
                  class="float-left"
                  :disabled="futureCostEditDisabled"
                  :data-type="dataTypes.number"
                  :number-format="numberFormats.priceFormat"
                  :value="getInputValue(props.item, input, intentionCostFields.price)"
                  :previous-value="getPreviousValue(props.item, input, intentionCostFields.price)"
                  :error="updateErrors[props.item.keys[input]]"
                  :cell-class="getInputEditFieldClass(props.item, input)"
                  previous-value-translation-key="engineInputs.editor"
                  :tooltip-position="
                    props.index > size(workpackageProductEngineInputs) / 2
                      ? 'top-left'
                      : 'bottom-left'
                  "
                  :tooltip-value="getIntentionCostTooltip(props.item)"
                  @change="
                    recordChanges($event.target.value, input, props.item, intentionCostFields.price)
                  "
                  @focus="debounceShowDatesEditor(props.item)"
                  @blur="debounceRemoveIcon(props.item)"
                />
                <div class="date-pickers-container">
                  <v-menu
                    v-if="shouldDisplayDatesEditor(props.item)"
                    :disabled="!canEditAttributes"
                    :close-on-content-click="false"
                    :nudge-right="5"
                    :top="props.index > size(workpackageProductEngineInputs) / 2"
                    offset-y
                    transition="scale-transition"
                    @input="closeDatesEditor($event)"
                  >
                    <template v-slot:activator="{ on }">
                      <v-btn color="primary" class="calendar-button" depressed x-small v-on="on">
                        <v-icon small> event </v-icon>
                      </v-btn>
                    </template>
                    <v-date-picker
                      :key="`${props.item.productKey}-${input}-effectiveDate-expiryDate`"
                      v-model="dateRange"
                      :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                      :min="size(dateRange) === 1 ? dateRange[0] : ''"
                      :locale="i18nconfig.fallbackLocale"
                      range
                      :show-current="false"
                      flat
                      no-title
                      :picker-date.sync="pickerDate"
                      @input="updateIntentionCostDates(props.item, $event, input)"
                    />
                  </v-menu>
                </div>
              </div>
            </template>
            <template v-else>
              <pricing-edit-text-field
                :key="`${props.item.productKey}-${input}`"
                :disabled="!canEditAttributes"
                class="float-left"
                :data-type="dataTypes.number"
                :number-format="numberFormats.decimal"
                :value="getInputValue(props.item, input)"
                :previous-value="getPreviousValue(props.item, input)"
                :error="updateErrors[props.item.keys[input]]"
                :cell-class="getInputEditFieldClass(props.item, input)"
                previous-value-translation-key="engineInputs.editor"
                @change="recordChanges($event.target.value, input, props.item)"
              />
            </template>
          </td>
        </tr>
      </template>

      <template slot="footer">
        <v-flex :class="{ 'input-screen-sticky-table-footer': showHierarchy }">
          <v-row justify="end">
            <v-col md="3" offset-md="8">
              <v-pagination
                v-model="pagination.page"
                :length="pages"
                @input="loadMandatoryInputsFromAttributes"
              />
            </v-col>
          </v-row>
          <v-row justify="end" class="pr-6 pb-2" align="center">
            <v-btn
              color="primary"
              small
              :loading="downloading"
              class="mr-3"
              depressed
              @click="downloadEngineInputs"
            >
              {{ $t('actions.download') }}
              <v-icon small>$export</v-icon>
            </v-btn>
            <span class="mr-3 btn-divider" />
            <v-btn
              class="save"
              :disabled="isSaveDisabled"
              :loading="loading"
              color="success"
              small
              depressed
              @click="persistUpdates"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-row>
        </v-flex>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import moment from 'moment';
import {
  ceil,
  debounce,
  isNil,
  isNaN,
  keys,
  some,
  reduce,
  get,
  isEmpty,
  omit,
  has,
  size,
  isEqual,
  mapValues,
  each,
} from 'lodash';
import sortDirectionEnums from '@enums/sort-direction';
import {
  displayHierarchyAndFilter,
  useZones,
  allowTemplateIntentionCostChange,
} from '@enums/feature-flags';
import mandatoryEngineInputs from '@enums/mandatory-engine-inputs';
import costFields from '@enums/cost-fields';
import numberFormats from '@enums/number-formats';
import { maxDateTimestamp } from '@enums/dates';
import customFilter from '../../../utils/filter-util';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import isTooltipDisabled from '../../../utils/tooltip-util';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import DataTypes from '@enums/data-types';
import { yearMonthDayFormat } from '@enums/date-formats';
import { inputTableCssClasses } from '@enums/tables';
import inputUpdateKey from '../../../mixins/inputUpdateKeys';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tablesMixin from '../../../mixins/tables';
import inputScreensTableMixin from '../../../mixins/inputScreensTable';
import { preventPageNavigationOnHorizontalScroll } from '../../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [inputUpdateKey, featureFlagsMixin, tablesMixin, inputScreensTableMixin],
  data() {
    return {
      search: '',
      mandatoryEngineInputs,
      categoryLevel,
      pricingGroupLevel,
      architectureGroupLevel,
      truncationLengthMedium: 28,
      displayHierarchyAndFilter,
      pagination: {
        // used by v-data-table for sorting and pagination
        descending: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'productKeyDisplay',
        sortDataType: DataTypes.str,
      },
      dataTypes: DataTypes,
      numberFormats,
      updates: {},
      updateErrors: {},
      updatedRowsDetailsMap: {},
      calendarDisplays: null,
      calendarOpen: false,
      intentionCostFields: costFields,
      pickerDate: null,
      dateRange: [],
      maxMomentDate: moment.utc(maxDateTimestamp),
      maxDateChecksMap: new Map(),
      dateColumnTranslations: {
        effectiveDate: this.$t('engineInputs.editor.effectiveDate'),
        expiryDate: this.$t('engineInputs.editor.expiryDate'),
      },
    };
  },

  computed: {
    ...mapState('engineInputs', ['downloading']),
    ...mapState('filters', [
      'inputScreensFetchParams',
      'inputScreensFilterRules',
      'retailAttributesFilter',
    ]),
    ...mapState('attributes', [
      'attributes',
      'count',
      'loading',
      'attributeMetadata',
      'attributeFetchParams',
      'attributesFilterRules',
    ]),
    ...mapGetters('workpackages', ['hasSelectedWorkpackage', 'isSelectedWorkpackageMaster']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapState('workpackageProducts', ['loading']),
    ...mapState('clientConfig', ['i18nconfig', 'exportConfigs', 'dateFormats']),

    getTodaysDate() {
      return moment().format(yearMonthDayFormat);
    },

    futureCostEditDisabled() {
      return (
        this.isSelectedWorkpackageMaster &&
        !this.isFeatureFlagEnabled(allowTemplateIntentionCostChange)
      );
    },

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            ...this.toolStoreGroupColumnDefaultProperties,
            text: this.$t('engineInputs.editor.toolStoreGroup'),
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
          }
        : null;
    },

    fixedColumnsClass() {
      return this.toolStoreGroupColumn
        ? inputTableCssClasses.threeFixedColumns
        : inputTableCssClasses.twoFixedColumns;
    },

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    exportTranslationMap() {
      const toolStoreGroupTranslation = this.getColumnExportTranslation(this.toolStoreGroupColumn);
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};
      return {
        productKeyDisplay: this.$t('engineInputs.editor.productKey'),
        ...toolStoreGroupTranslation,
        productName: this.$t('engineInputs.editor.productDescription'),
        ...hierarchyColumnTranslations,
        ...mapValues(mandatoryEngineInputs, path => this.$t(path)),
        ...this.dateColumnTranslations,
      };
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    headers() {
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('engineInputs.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('engineInputs.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productName',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        ...this.hierarchyHeaders,
        {
          text: this.$t('engineInputs.editor.targetMargin'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.targetMargin',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.creationCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.creationCost',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.goLiveCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.nonPromoNetCost',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoParticipationSales'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoParticipationSales',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoDiscount'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoDiscount',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.promoFunding'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.promoFunding',
          unit: '%',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('engineInputs.editor.intentionCost'),
          align: 'start',
          sortable: true,
          value: 'mandatoryEngineInputs.intentionCost.price',
          unit: this.$t('clientCurrencySymbol'),
          dataType: DataTypes.number,
          deprecated: true,
        },
      ];
    },

    canEditAttributes() {
      return this.isPricingSpecialist;
    },

    pages() {
      return ceil(this.count / this.pagination.itemsPerPage);
    },

    hasUnsavedUpdates() {
      return keys(this.updates).length > 0 || some(this.workProductHierarchyUpdates);
    },

    hasInvalidUpdates() {
      return some(this.updateErrors);
    },

    isSaveDisabled() {
      return !this.hasUnsavedUpdates || !this.canEditAttributes || this.hasInvalidUpdates;
    },

    workpackageProductEngineInputs() {
      if (this.loading) {
        return [];
      }

      return this.attributes.map(product => {
        product.keys = {};
        Object.keys(this.mandatoryEngineInputs).forEach(input => {
          product.keys[input] = this.getInputUpdateKey(product._id, input);
        });
        return product;
      });
    },
  },

  async created() {
    if (this.hasSelectedWorkpackage) {
      const fromPricingView = this.$route.meta.previousRoute.includes('/pricing/');
      if (isEmpty(this.attributes) || !this.showHierarchy || fromPricingView)
        await this.loadMandatoryInputsFromAttributes();
      else {
        this.pagination = omit(this.inputScreensFetchParams, 'pick');
        this.pagination.itemsPerPage = 25;
        this.pagination.page = this.getDefaultSelectedPage(this.pagination, this.pages);
        this.search = this.pagination.search;
        if (has(this.inputScreensFetchParams, 'pick')) {
          await this.loadMandatoryInputsFromAttributes();
        }
      }
    }
  },

  destroyed() {
    this.maxDateChecksMap.clear();
  },

  methods: {
    ...mapActions('workpackageProducts', ['updateMandatoryEngineInputs']),
    ...mapActions('engineInputs', [
      'resetEngineInputUpdate',
      'downloadItems',
      'doesProductExistWithMissingEngineInputs',
      'doesProductExistWithInvalidEngineInputs',
    ]),
    ...mapActions('attributes', ['fetchAttributesAggregated']),
    ...mapActions('filters', ['setSelectedFilter']),
    get,
    customFilter,

    getBaseParams() {
      let params = {
        limit: this.pagination.itemsPerPage,
        offset: this.pagination.itemsPerPage * (this.pagination.page - 1),
        search: this.search,
        where: this.retailAttributesFilter,
      };

      if (this.pagination.sortBy) {
        params = {
          ...params,
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.descending
            ? sortDirectionEnums.descending
            : sortDirectionEnums.ascending,
          sortDataType: this.pagination.sortDataType,
        };
      }
      return params;
    },

    getFormatters() {
      const clientDateFormat = this.dateFormats.clientDateFormatForMoment;
      const columnFormatters = this.getColumnFormatters(this.headers);
      each(this.dateColumnTranslations, column => {
        columnFormatters[column] = {
          type: DataTypes.date,
          format: clientDateFormat,
        };
      });
      return columnFormatters;
    },

    maxDateExceeded(date) {
      if (this.maxDateChecksMap.has(date)) {
        return this.maxDateChecksMap.get(date);
      }
      const momentDate = moment.utc(date);
      if (!momentDate.isValid()) {
        this.maxDateChecksMap.set(date, false);
        return false;
      }
      const maxDateExceeded = isSameOrAfter(momentDate, this.maxMomentDate);
      this.maxDateChecksMap.set(date, maxDateExceeded);
      return maxDateExceeded;
    },

    getIntentionCostTooltip(item) {
      const expiryDate = this.getInputValue(
        item,
        'intentionCost',
        this.intentionCostFields.expiryDate
      );
      const effectiveDate = this.getInputValue(
        item,
        'intentionCost',
        this.intentionCostFields.effectiveDate
      );
      return {
        [this.dateColumnTranslations.effectiveDate]: this.maxDateExceeded(effectiveDate)
          ? '-'
          : this.formatLocalisedDate(effectiveDate),
        [this.dateColumnTranslations.expiryDate]: this.maxDateExceeded(expiryDate)
          ? '-'
          : this.formatLocalisedDate(expiryDate),
      };
    },

    async setFilterRules(filterRules) {
      // Reset the pagination as there might be no results on page X
      this.pagination.page = 1;

      this.setSelectedFilter({ filterName: 'retailAttributesFilter', filterValue: filterRules });
      await this.loadMandatoryInputsFromAttributes();
    },

    loadMandatoryInputsFromAttributes() {
      const params = this.getBaseParams();
      return this.fetchAttributesAggregated({ params });
    },

    changeSort(column) {
      const columnValue = this.getColumnValue(column);
      if (!columnValue) return;
      if (this.pagination.sortBy === columnValue) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = columnValue;
        this.pagination.descending = false;
        this.pagination.sortDataType = column.dataType;
      }
      this.loadMandatoryInputsFromAttributes();
    },

    debounceSearchUpdate: debounce(async function(value) {
      this.search = value;
      this.pagination.page = 1;
      await this.loadMandatoryInputsFromAttributes();
    }, clientConfig.inputDebounceValue),

    getUpdateForSave(input, newValue) {
      if (input !== 'intentionCost') {
        const savedValue = newValue === '' ? null : newValue;
        return {
          [`mandatoryEngineInputs.${input}`]: savedValue,
          ...(input === 'creationCost' && { 'liveCost.price': savedValue, liveCost: savedValue }),
        };
      }
      const intentionCostUpdate = reduce(
        newValue,
        (result, value, key) => {
          const savedValue = value === '' ? null : value;
          result[`mandatoryEngineInputs.${input}.${key}`] = savedValue;
          result[`${input}.${key}`] = savedValue;
          if (key === this.intentionCostFields.price) result[input] = savedValue;
          return result;
        },
        {}
      );
      return intentionCostUpdate;
    },

    formatUpdatesForSave() {
      return reduce(
        this.updates,
        (updatePayload, newValue, updateKey) => {
          const [productId, input] = this.parseInputUpdateKey(updateKey);
          const updatedRow = this.updatedRowsDetailsMap[productId];
          if (!updatedRow) return updatePayload;
          const update = this.getUpdateForSave(input, newValue);
          return [
            ...updatePayload,
            {
              filter: {
                productKeyDisplay: updatedRow.productKeyDisplay,
                toolStoreGroupKey: updatedRow.toolStoreGroupKey,
              },
              update,
            },
          ];
        },
        []
      );
    },

    async persistUpdates() {
      const params = {
        ...this.getBaseParams(),
        where: this.retailAttributesFilter,
      };
      const updates = this.formatUpdatesForSave();
      await this.updateMandatoryEngineInputs({
        updates,
        params,
      });
      this.doesProductExistWithMissingEngineInputs();
      this.doesProductExistWithInvalidEngineInputs();
      this.clearUpdates();
    },

    downloadEngineInputs() {
      this.downloadItems({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        pick: this.getColumnExportPickOptions(this.toolStoreGroupColumn),
        columnFormatters: this.getFormatters(),
      });
    },

    getHierarchyName({ hierarchy }, level) {
      return hierarchy[level].levelEntryDescription;
    },

    isTooltipDisabled,
    preventPageNavigationOnHorizontalScroll,

    getInputValue(product, input, key) {
      const pathToProductData = ['mandatoryEngineInputs', input];
      const pathToUpdate = [product.keys[input]];
      if (key) {
        pathToProductData.push(key);
        pathToUpdate.push(key);
      }
      const updatedValue = get(this.updates, pathToUpdate);
      return !isNil(updatedValue) ? updatedValue : get(product, pathToProductData);
    },

    getPreviousValue(product, input, key) {
      const path = ['mandatoryEngineInputs', input];
      if (key) path.push(key);
      return get(product, path);
    },

    getInputEditFieldClass(product, input, key) {
      // invalid value entered
      if (this.updateErrors[product.keys[input]]) {
        return 'attribute-cell-error';
      }

      // value edited and valid (not shown if the updated value is the same as the original)
      const currentValue = this.getInputValue(product, input, key);
      const previousValue = this.getPreviousValue(product, input, key);
      if (!isEqual(currentValue, previousValue)) {
        return 'attribute-cell-updated';
      }

      return '';
    },

    invalidRange(value, input) {
      if (value < 0) {
        return this.$t('engineInputs.editor.validationErrors.mustBePositive');
      }
      if (value >= 100) {
        if (input === 'nonPromoNetCost') {
          // Always positive, Cost in Euro
          return null;
        }
        return this.$t('engineInputs.editor.validationErrors.mustBeUnderHundred');
      }
    },

    validateEntry(value, input, product) {
      if (value === null) return true;
      const formattedNumber = this.formatStringToNumber(value);
      const validNumber = formattedNumber === 0 || formattedNumber; // 0 would be invalid without explicit check

      if (validNumber) {
        const invalidRange = this.invalidRange(formattedNumber, input);
        if (invalidRange) {
          this.$set(this.updateErrors, product.keys[input], invalidRange);
        } else {
          // Remove this value from updateErrors, so the page can be saved
          this.$delete(this.updateErrors, product.keys[input]);
        }
      } else {
        // Add this value to updateErrors
        this.$set(
          this.updateErrors,
          product.keys[input],
          this.$t('validationErrors.incorrectDataType', {
            dataType: this.dataTypes.number,
          })
        );
      }
      return !!validNumber;
    },

    recordChanges(rawValue, input, product, key) {
      this.validateEntry(rawValue, input, product);
      const formattedValue = rawValue ? this.formatValue(rawValue) : rawValue;
      this.saveUpdates(product, product.keys[input], formattedValue, key);
      if (input === 'intentionCost') {
        this.validateDates(
          product,
          this.getInputValue(product, input, this.intentionCostFields.effectiveDate),
          input,
          this.intentionCostFields.effectiveDate
        );
      }
    },

    formatValue(rawValue) {
      const numericValue = this.formatStringToNumber(rawValue);
      return isNaN(numericValue) ? rawValue : numericValue;
    },

    clearUpdates() {
      this.updates = {};
      this.updatedRowsDetailsMap = {};
    },

    saveUpdates(product, uniqueKey, update, key) {
      if (key) {
        const fullUpdates = this.updates[uniqueKey] || {};
        this.$set(this.updates, uniqueKey, {
          ...fullUpdates,
          [key]: update,
        });
      } else {
        this.$set(this.updates, uniqueKey, update);
      }
      this.markRowAsUpdated(product);
    },

    markRowAsUpdated(row) {
      if (this.updatedRowsDetailsMap[row._id]) {
        return;
      }

      this.updatedRowsDetailsMap[row._id] = {
        productKeyDisplay: row.productKeyDisplay,
        toolStoreGroupKey: row.toolStoreGroupKey,
      };
    },

    validateEffectiveDate(value, product, input) {
      const expiryDate = this.getInputValue(product, input, this.intentionCostFields.expiryDate);
      if (!expiryDate) {
        this.$set(this.updateErrors, product.keys[input], this.$t('engineInputs.editor.noExpiry'));
      }

      const isEffectiveDateMoreThanExpiry =
        moment(value, yearMonthDayFormat) > moment(expiryDate, yearMonthDayFormat);
      if (isEffectiveDateMoreThanExpiry) {
        this.$set(
          this.updateErrors,
          product.keys[input],
          this.$t('engineInputs.editor.effectiveGreater')
        );
      } else {
        // Only remove the error if the error pertains to the
        // effectiveDate being greater than the expiryDate
        const isGreaterThanError =
          this.updateErrors[product.keys[input]] ===
          this.$t('engineInputs.editor.effectiveGreater');
        if (isGreaterThanError) this.$delete(this.updateErrors, product.keys[input]);
      }
    },

    validateDates(product, value, input, key) {
      if (!value && key === this.intentionCostFields.effectiveDate) {
        this.$set(
          this.updateErrors,
          product.keys[input],
          this.$t('engineInputs.editor.noEffective')
        );
        return;
      }
      if (key === this.intentionCostFields.effectiveDate) {
        this.validateEffectiveDate(value, product, input);
      }
      if (key === this.intentionCostFields.expiryDate) {
        this.$delete(this.updateErrors, product.keys[input]);
      }

      const price = this.getInputValue(product, input, this.intentionCostFields.price);
      if (!price) {
        this.$set(this.updateErrors, product.keys[input], this.$t('engineInputs.editor.needPrice'));
      } else {
        // Only remove the error if the error pertains to the
        // price not being set
        const isPriceError =
          this.updateErrors[product.keys[input]] === this.$t('engineInputs.editor.needPrice');
        if (isPriceError) this.$delete(this.updateErrors, product.keys[input]);
      }
    },

    updateIntentionCostDates(item, dates, input) {
      if (size(dates) === 2) {
        this.saveUpdates(item, item.keys[input], dates[0], this.intentionCostFields.effectiveDate);
        this.saveUpdates(
          item,
          item.keys[input],
          moment(dates[0], yearMonthDayFormat).unix(),
          this.intentionCostFields.effectiveDate.replace('Date', 'Timestamp')
        );
        this.saveUpdates(item, item.keys[input], dates[1], this.intentionCostFields.expiryDate);
        this.saveUpdates(
          item,
          item.keys[input],
          moment(dates[1], yearMonthDayFormat).unix(),
          this.intentionCostFields.expiryDate.replace('Date', 'Timestamp')
        );
        this.validateDates(item, dates[0], input, this.intentionCostFields.effectiveDate);
        this.validateDates(item, dates[1], input, this.intentionCostFields.expiryDate);
        this.debounceCloseDatesEditor();
      }
    },

    size,

    debounceRemoveIcon: debounce(function(item) {
      if (this.calendarOpen || this.calendarDisplays !== item._id) {
        return;
      }
      this.calendarDisplays = null;
    }, 150), // the delay must be long enough to open the calendar

    debounceCloseDatesEditor: debounce(function() {
      this.pickerDate = null;
      this.dateRange = [];
      this.calendarDisplays = null;
      this.calendarOpen = false;
    }, 1000),

    debounceShowDatesEditor: debounce(function(item) {
      const fullDate =
        this.getInputValue(item, 'intentionCost', 'effectiveDate') || this.getTodaysDate;
      const yearMonth = fullDate.substr(0, fullDate.lastIndexOf('-'));
      this.pickerDate = yearMonth;
      const effectiveDate = this.getInputValue(
        item,
        'intentionCost',
        this.intentionCostFields.effectiveDate
      );
      const expiryDate = this.getInputValue(
        item,
        'intentionCost',
        this.intentionCostFields.expiryDate
      );
      this.dateRange = [];
      if (effectiveDate) this.dateRange.push(effectiveDate);
      if (effectiveDate && expiryDate) this.dateRange.push(expiryDate);
      this.calendarDisplays = item._id;
    }, 300),

    shouldDisplayDatesEditor(item) {
      return this.calendarDisplays === item._id;
    },

    closeDatesEditor(event) {
      if (!event) {
        this.calendarDisplays = null;
        this.calendarOpen = false;
        return;
      }
      this.calendarOpen = true;
    },
  },
};
</script>

<style lang="scss" scoped>
.date-pickers-container {
  position: absolute;
  left: 58%;
}

.intention-cost-cell {
  position: relative;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}
</style>

<style lang="scss">
.calendar-button {
  margin: 0.3rem;
  padding: 0 !important;
  min-width: unset !important;

  > .v-btn__content {
    padding: 0 !important;
  }
}
</style>
