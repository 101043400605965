import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        VDialog,
        {
          attrs: { width: 850, persistent: "" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("workPackages.incorrectMappings")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "close-button",
                  attrs: { color: "grey" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("close")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(VCardText, [
                    _c(
                      "div",
                      { staticClass: "d-flex" },
                      [
                        _c(VSimpleTable, {
                          staticClass: "flex-fill",
                          attrs: { height: "40rem" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "workPackages.userWorkpackageMapping"
                                              )
                                            ) +
                                            " -\n                      " +
                                            _vm._s(
                                              _vm.userWorkpackageDescription
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pricing.toolStoreGroup")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pricing.pricingStoreGroup")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.userWpMappings,
                                      function (psg, ix) {
                                        return _c(
                                          "tr",
                                          { key: psg.pricingStoreGroupKey },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.showIfDifferent(
                                                      psg.toolStoreGroupDescription,
                                                      "toolStoreGroupDescription",
                                                      ix,
                                                      _vm.userWpMappings
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.showIfDifferent(
                                                      psg.pricingStoreGroupDescription,
                                                      "pricingStoreGroupDescription",
                                                      ix,
                                                      _vm.userWpMappings
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                        _vm._v(" "),
                        _c(VSimpleTable, {
                          staticClass: "flex-fill",
                          attrs: { height: "40rem" },
                          scopedSlots: _vm._u([
                            {
                              key: "default",
                              fn: function () {
                                return [
                                  _c("thead", [
                                    _c("tr", [
                                      _c("th", { attrs: { colspan: "2" } }, [
                                        _vm._v(
                                          "\n                      " +
                                            _vm._s(
                                              _vm.$t(
                                                "workPackages.masterWorkpackageMapping"
                                              )
                                            ) +
                                            " -\n                      " +
                                            _vm._s(
                                              _vm.masterWorkpackageDescription
                                            ) +
                                            "\n                    "
                                        ),
                                      ]),
                                    ]),
                                    _vm._v(" "),
                                    _c("tr", [
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pricing.toolStoreGroup")
                                          )
                                        ),
                                      ]),
                                      _vm._v(" "),
                                      _c("th", { staticClass: "text-left" }, [
                                        _vm._v(
                                          _vm._s(
                                            _vm.$t("pricing.pricingStoreGroup")
                                          )
                                        ),
                                      ]),
                                    ]),
                                  ]),
                                  _vm._v(" "),
                                  _c(
                                    "tbody",
                                    _vm._l(
                                      _vm.masterWpMappings,
                                      function (psg, ix) {
                                        return _c(
                                          "tr",
                                          { key: psg.pricingStoreGroupKey },
                                          [
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.showIfDifferent(
                                                      psg.toolStoreGroupDescription,
                                                      "toolStoreGroupDescription",
                                                      ix,
                                                      _vm.masterWpMappings
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                            _vm._v(" "),
                                            _c("td", [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.showIfDifferent(
                                                      psg.pricingStoreGroupDescription,
                                                      "pricingStoreGroupDescription",
                                                      ix,
                                                      _vm.masterWpMappings
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]),
                                          ]
                                        )
                                      }
                                    ),
                                    0
                                  ),
                                ]
                              },
                              proxy: true,
                            },
                          ]),
                        }),
                      ],
                      1
                    ),
                  ]),
                  _vm._v(" "),
                  _c(VDivider),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }