import { render, staticRenderFns } from "./architecture-sub-group.vue?vue&type=template&id=ca2e542c"
import script from "./architecture-sub-group.vue?vue&type=script&lang=js"
export * from "./architecture-sub-group.vue?vue&type=script&lang=js"
import style0 from "./architecture-sub-group.vue?vue&type=style&index=0&id=ca2e542c&prod&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports