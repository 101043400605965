<template>
  <v-layout row justify-center>
    <v-dialog :value="isOpen" persistent max-width="40rem">
      <v-card>
        <v-form ref="form">
          <v-card-text class="header-text">
            {{ dialogTitle() }}
          </v-card-text>
          <v-card-text class="body">
            <v-select
              v-if="multipleHierarchyLevels"
              :items="existingCategories"
              item-text="levelEntryDescription"
              return-object
              dense
              single-line
              @input="setSelectedGrandparentHierarchy"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>

              <template v-slot:label>
                <span class="pg-dropdown-label-text">
                  {{ selectHierarchyText(level - 2) }}
                </span>
              </template>
            </v-select>

            <v-select
              :items="existingParentHierarchies"
              item-text="levelEntryDescription"
              :disabled="!selectedGrandparentHierarchy && multipleHierarchyLevels"
              return-object
              dense
              single-line
              @input="setSelectedParentHierarchy"
            >
              <template v-slot:item="props">
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>

              <template v-slot:label>
                <span class="pg-dropdown-label-text">
                  {{ selectHierarchyText(level - 1) }}
                </span>
              </template>
            </v-select>
            <v-select
              multiple
              dense
              single-line
              flat
              small-chips
              hide-details
              return-object
              :items="existingChildHierarchies"
              item-text="levelEntryDescription"
              :disabled="!selectedParentHierarchy"
              :loading="loadingEmptyHierarchies"
              :readonly="loadingEmptyHierarchies"
              @input="setSelectedChildHierarchies"
            >
              <template v-slot:selection="{ item, index, selected }">
                <v-chip
                  :input-value="selected"
                  close
                  class="chip--select"
                  @click:close="deselect(item)"
                >
                  {{ item.levelEntryDescription }}
                </v-chip>
              </template>
              <template v-slot:item="props">
                <v-checkbox :input-value="selectedChildHierarchies.includes(props.item)" />
                <span class="pg-dropdown-item-text">{{ props.item.levelEntryDescription }}</span>
              </template>
              <template v-slot:label>
                <span class="pg-dropdown-label-text">
                  {{ selectHierarchyText(level) }}
                </span>
              </template>
              <template v-slot:item="props">
                <v-checkbox :input-value="selectedChildHierarchies.includes(props.item)" />
                <span class="pg-dropdown-item-text dropdown-text">
                  {{ props.item.levelEntryDescription }}
                </span>
              </template>
              <template v-slot:no-data>
                <span class="dropdown-text no-data-slot">
                  {{ noEmptyHierarchyText(level) }}
                </span>
              </template>
            </v-select>
          </v-card-text>
          <v-card-actions class="btn-group">
            <v-spacer />
            <v-btn class="cancel-btn" text outlined depressed @click="cancel">Cancel</v-btn>
            {{ /* AHTDPR-823 TODO: add confirmation with list of things that will be deleted */ }}
            <v-btn class="ok-btn" :disabled="!canDelete" depressed @click="deleteChildHierarchies">
              {{ $t('attributes.editor.actions.delete') }}
            </v-btn>
          </v-card-actions>
          {{ /* AHTDPR-823 TODO: get meta errors working, so users can see if deletion failed */}}
          <v-layout v-if="metaErrors.length" class="meta-errors">
            <v-layout v-for="(error, index) in metaErrors" :key="`error-${index}`">
              {{ error }}
            </v-layout>
          </v-layout>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { mapGetters, mapState, mapActions } from 'vuex';
import { sortBy, isEmpty, some } from 'lodash';
import { pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';

export default {
  props: {
    isOpen: {
      required: true,
    },
    level: {
      required: true,
      validator(level) {
        return [pricingGroupLevel, architectureGroupLevel, null].includes(level);
      },
    },
    fetchHierarchy: {
      type: Function,
      required: true,
      default: () => console.error('Prop failed to bind for fetchHierarchy'),
    },
  },

  data() {
    return {
      selectedGrandparentHierarchy: null,
      selectedParentHierarchy: null,
      selectedChildHierarchies: [],
      childHierarchyName: '',
      metaErrors: [],
      existingChildHierarchies: [],
    };
  },

  computed: {
    ...mapState('hierarchy', ['loadingEmptyHierarchies']),
    ...mapGetters('hierarchy', ['getHierarchy']),

    canDelete() {
      return this.isValidSelection && !this.loadingEmptyHierarchies;
    },

    isValidSelection() {
      if (isEmpty(this.selectedChildHierarchies)) return false;

      // ensure selected child hierarchies match selected parent before delete is allowed.
      const mismatchedParent = some(
        this.selectedChildHierarchies,
        h => h.parentId !== this.selectedParentHierarchy.levelEntryKey
      );

      if (mismatchedParent) return false;

      // if we have grandparent level, we should check parent.parentId matches selected grandparent
      if (this.level === architectureGroupLevel) {
        return (
          this.selectedParentHierarchy.parentId === this.selectedGrandparentHierarchy.levelEntryKey
        );
      }

      return true;
    },

    existingCategories() {
      // Used to allow the user to select a category before selecting a pricing group when creating a new Architecture Group.
      // Minus 2 as Catgeory is 2 levels above Architecture Group in Hierarchy.
      return sortBy(this.getHierarchy({ level: this.level - 2 }), 'levelEntryDescription');
    },

    existingParentHierarchies() {
      if (this.selectedGrandparentHierarchy)
        return sortBy(
          this.getHierarchy({
            level: this.level - 1,
            parentId: this.selectedGrandparentHierarchy.levelEntryKey,
          }),
          'levelEntryDescription'
        );
      return sortBy(this.getHierarchy({ level: this.level - 1 }), 'levelEntryDescription');
    },

    multipleHierarchyLevels() {
      return this.level === architectureGroupLevel;
    },
  },

  methods: {
    ...mapActions('hierarchy', ['deleteHierarchy', 'filterEmptyHierarchies']),

    // TODO: abstract into own v-select wrapper? already used in attribute-filter.vue
    deselect(item) {
      const index = this.selectedChildHierarchies.indexOf(item);
      if (index >= 0) this.$delete(this.selectedChildHierarchies, index);
    },

    selectHierarchyText(level) {
      const hierarchyName = this.$tc(`hierarchy.hierarchyLevels.${level}`, 1);
      return this.$t('attributes.editor.selectHierarchy', { hierarchyName });
    },

    noEmptyHierarchyText(level) {
      const hierarchyName = this.$tc(`hierarchy.hierarchyLevels.${level}`, 1);
      return this.$t('attributes.editor.noEmptyHierarchies', { hierarchyName });
    },

    dialogTitle() {
      const hierarchyName = this.$tc(`hierarchy.hierarchyLevels.${this.level}`, 1);
      return this.$t('attributes.editor.titles.delete', { hierarchyName });
    },

    async setSelectedParentHierarchy(category) {
      this.selectedParentHierarchy = category;
      await this.checkForExistingChildHierarchies();
    },

    setSelectedGrandparentHierarchy(category) {
      this.selectedGrandparentHierarchy = category;
    },

    setSelectedChildHierarchies(hierarchies) {
      this.selectedChildHierarchies = hierarchies;
    },

    async checkForExistingChildHierarchies() {
      if (!this.selectedParentHierarchy) return [];

      if (this.level === architectureGroupLevel && !this.selectedGrandparentHierarchy) return [];

      const res = await this.filterEmptyHierarchies({
        level: this.level,
        parentId: this.selectedParentHierarchy.levelEntryKey,
      });

      this.existingChildHierarchies = res;
    },

    async deleteChildHierarchies() {
      this.metaErrors = [];

      await Promise.all(
        this.selectedChildHierarchies.map(ag =>
          this.deleteHierarchy(ag).catch(err => {
            this.metaErrors.push(err);

            // Resolving prevents a single error exiting early.
            return Promise.resolve();
          })
        )
      );

      if (isEmpty(this.metaErrors)) this.closeModal();
      // Fetch hierarchy in the grid to refresh.
      await this.fetchHierarchy();
      this.closeModal();
    },

    reset() {
      this.childHierarchyName = '';
      this.selectedParentHierarchy = undefined;
    },

    cancel() {
      this.reset();
      this.closeModal();
    },

    closeModal() {
      this.$emit('childHierarchyDeleted');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.v-card {
  .meta-errors {
    color: $vuetify-error-red;
  }

  .body.v-card__text {
    margin-bottom: 7rem;
  }

  .header-text {
    padding-top: 2rem;
    font-size: 2rem;
    color: black;
  }

  .btn-group.v-card__actions {
    .cancel-btn {
      color: $btn-cancel-color;
      border-color: $btn-cancel-color;
    }

    .ok-btn {
      color: $pricing-white;
      background-color: $btn-ok-color;
      border-color: $btn-ok-color;
    }
  }
}

.pg-dropdown-item-text {
  font-size: 1.5rem;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}

.v-btn {
  font-size: 1.5rem;
}

.dropdown-text {
  font-size: 1.5rem;
  color: black;
}

.no-data-slot {
  padding-left: 2.2rem;
}
</style>
