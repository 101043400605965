import { render, staticRenderFns } from "./wholesale-export-dialog.vue?vue&type=template&id=64cb390a&scoped=true"
import script from "./wholesale-export-dialog.vue?vue&type=script&lang=js"
export * from "./wholesale-export-dialog.vue?vue&type=script&lang=js"
import style0 from "./wholesale-export-dialog.vue?vue&type=style&index=0&id=64cb390a&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "64cb390a",
  null
  
)

export default component.exports