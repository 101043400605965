<!-- Uses the generic pricing-filter component to set architecture group filter -->

<template>
  <v-layout class="heading-bar">
    <v-col class="heading-bar-filter">
      <v-flex lg5>
        <pricing-filter
          :filter="architectureGroupFilter"
          :loading="hierarchyLoading"
          @filterSelected="onSelectFilter"
        />
      </v-flex>
    </v-col>
  </v-layout>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import { ascending } from '@enums/sort-direction';
import { architectureGroupLevel } from '@enums/hierarchy';
import hierarchyFiltersMixin from '../mixins/hierarchyFilters';

const { sortBy, toLower } = require('lodash');

export default {
  mixins: [hierarchyFiltersMixin],

  data() {
    return {
      architectureGroups: [],
    };
  },

  events: {
    onPricingGroupSelected({ pricingGroupFilter }) {
      this.getArchitectureGroups(pricingGroupFilter);
    },
  },

  computed: {
    ...mapState('hierarchy', {
      hierarchyLoading: state => state.loading,
    }),
    ...mapState('filters', {
      selectedPricingGroup: state => state.pricingGroup,
      selectedScenario: state => state.scenario,
      selectedArchitectureGroup: state => state.architectureGroup,
      defaultAttributeFilterValue: state => state.defaultAttributeFilterValue,
    }),

    architectureGroupFilter() {
      return {
        name: this.$t('filters.architectureGroup'),
        state: 'architectureGroup',
        values: sortBy(this.architectureGroups, ag => toLower(ag.levelEntryDescription)),
        label: 'levelEntryDescription',
        itemValue: 'levelEntryKey',
      };
    },
  },

  async created() {
    this.getArchitectureGroups(this.selectedPricingGroup);

    if (this.selectedPricingGroup && this.selectedScenario && this.selectedArchitectureGroup) {
      await Promise.all([
        this.fetchLinePricingGroupsWithProducts(),
        this.fetchArchitectureDrivers(),
      ]);
    }
  },

  methods: {
    ...mapActions('hierarchy', ['fetchHierarchy', 'hierarchiesWithProducts']),
    ...mapActions('architectureGroup', [
      'fetchLinePricingGroupsWithProducts',
      'fetchArchitectureProducts',
      'getFilteredAttributes',
    ]),
    ...mapActions('architectureDrivers', ['fetchArchitectureDrivers']),
    ...mapActions('filters', ['setSelectedFilter']),

    async getArchitectureGroups(pricingGroupFilter) {
      await this.fetchHierarchy({
        params: {
          sortBy: 'levelEntryDescription',
          sortDirection: ascending,
        },
      });
      this.architectureGroups = await this.hierarchiesWithProducts({
        level: architectureGroupLevel,
        parentId: pricingGroupFilter,
      });
    },

    async onSelectFilter({ filterValue }) {
      const architectureGroupFilter = { attributeValue: [filterValue] };
      const appliedFilterRule = [
        Object.assign(this.getArchitectureGroupFilterDefaults(), architectureGroupFilter),
      ];

      // Reset line pricing filter on architecture rules page (to avoid using mismatched filter values)
      this.setSelectedFilter({
        filterName: 'architectureRulesLinePricingFilter',
        filterValue: [this.defaultAttributeFilterValue],
      });

      this.globalEmit('architecture-rules-filter-updated', this.defaultAttributeFilterValue);
      await this.fetchArchitectureProducts({ architectureGroupId: filterValue });
      // process fetchLinePricingGroupsWithProducts after architecture products are loaded
      this.fetchLinePricingGroupsWithProducts();
      // update filtered attributes after every architectureGroup filter change
      this.getFilteredAttributes({
        params: { where: [...appliedFilterRule, this.defaultAttributeFilterValue] },
      });
    },
  },
};
</script>
