<template>
  <div>
    <hierarchy v-if="hasAccessToImpactView" :units="unitsForDisplay" is-unit-manager-view />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { unitLevel } from '@enums/hierarchy';

export default {
  computed: {
    ...mapGetters('hierarchy', ['getHierarchy']),
    ...mapGetters('context', ['isUnitManager', 'isPricingManager', 'isPricingSpecialist', 'units']),

    hasAccessToImpactView() {
      return this.isUnitManager || this.isPricingManager || this.isPricingSpecialist;
    },

    unitsForDisplay() {
      if (this.isPricingManager || this.isPricingSpecialist) {
        return this.getHierarchy()
          .filter(({ level }) => level === unitLevel)
          .map(({ levelEntryKey }) => levelEntryKey);
      }

      return this.units;
    },
  },
};
</script>
