import { VCardText } from 'vuetify/lib/components/VCard';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VCardText, [
    _c("div", { staticClass: "container grid-list-md px-0" }, [
      _c("div", { staticClass: "layout wrap" }, [
        _c("div", { staticClass: "flex xs12 pb-3" }, [
          _c("div", { staticClass: "v-input" }, [
            _vm._v(_vm._s(_vm.uploadParams.fileInstructions)),
          ]),
        ]),
        _vm._v(" "),
        _c("div", { staticClass: "flex xs12 pb-4" }, [
          _c("form", { attrs: { enctype: "multipart/form-data" } }, [
            _c("label", { staticClass: "file v-label" }, [
              _c("input", {
                staticClass: "input-file",
                attrs: {
                  type: "file",
                  multiple: "",
                  name: _vm.uploadFieldName,
                  disabled: _vm.isSaving,
                  accept: ".xlsx",
                },
                on: {
                  change: function ($event) {
                    return _vm.fileSelected(
                      $event.target.name,
                      $event.target.files
                    )
                  },
                },
              }),
              _vm._v(" "),
              _c("span", { staticClass: "file-custom" }),
            ]),
          ]),
        ]),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "flex xs12 tips" },
          [
            _c("div", [_vm._v(_vm._s(_vm.uploadParams.helpfulText))]),
            _vm._v(" "),
            !_vm.toolStoreGroupIndependent
              ? _c("feature-toggle", { attrs: { toggle: _vm.useZones } }, [
                  _c("span", { staticClass: "warning-text" }, [
                    _vm._v(_vm._s(_vm.$t("attributes.upload.warning"))),
                  ]),
                  _vm._v(
                    "\n          " +
                      _vm._s(
                        _vm.$t("attributes.upload.toolStoreGroupsWarning")
                      ) +
                      "\n        "
                  ),
                ])
              : _vm._e(),
          ],
          1
        ),
      ]),
    ]),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }