<template>
  <ag-grid-vue
    class="ag-theme-custom ag-wholesale-hierarchy"
    :grid-options="gridOptions"
    :column-defs="columnDefs"
    :row-data="rowData"
    :default-col-def="defaultColDef"
    @grid-ready="onGridReady"
  />
</template>

<script>
/* eslint-disable-next-line */
import { AgGridVue } from 'ag-grid-vue';
import { debounce, includes, values } from 'lodash';
import agGridUtils from '../../../utils/ag-grid-utils';
import { wholesaleHierarchyLevels } from '@enums/hierarchy';
import numberFormats from '@enums/number-formats';
import agGridChevronIcon from '../../../components/ag-grid-cell-renderers/ag-grid-chevron-icon.vue';

const {
  unitLevel,
  categoryLevel,
  segmentLevel,
  subsegmentLevel,
  microsegmentLevel,
} = wholesaleHierarchyLevels;

const columnType = {
  numericColumn: 'customNumericColumn',
  percentColumn: 'customPercentColumn',
  bpsColumn: 'customBPSColumn',
};

const columnGroup = {
  retailMargin: 'retailMargin',
  dllSplit: 'dllSplit',
  wholesaleMargin: 'wholesaleMargin',
  affiliateMargin: 'affiliateMargin',
};

const getRowClasses = ({ data }) => {
  if (data.isStoreGroupAggregation) return 'row-store-group';
  if (data.isStoreGroupEnd) return 'row-store-group-end';
  return `row-hierarchy-level-${data.level}`;
};

export default {
  components: {
    AgGridVue,
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    agGridChevronIcon,
  },

  props: {
    rowData: {
      type: Array,
      required: false,
      // rowData may be null while loading, avoid issue where 'no rows' is displayed while loading.
    },
    isLoading: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      columnApi: null,
      columnDefs: null,
      defaultColDef: null,
    };
  },

  watch: {
    isLoading(isLoading) {
      this.toggleLoadingOverlay(isLoading);
    },
  },

  beforeDestroy() {
    window.removeEventListener('resize', this.sizeColumnsToFit);
  },

  beforeMount() {
    this.gridOptions = {
      suppressContextMenu: true,
      tooltipShowDelay: 500,
      suppressMovableColumns: true,
      suppressFieldDotNotation: true,
      suppressClickEdit: true,
      suppressCellFocus: true,
      suppressRowDeselection: true,
      suppressRowClickSelection: true,
      suppressMenuHide: true,
      suppressRowDrag: true,
      getRowHeight: ({ data }) => (data.isStoreGroupEnd ? 10 : 34),
      headerHeight: 22,
      domLayout: 'autoHeight',
      getRowClass: getRowClasses,
      // register vue components
      columnTypes: {
        [columnType.numericColumn]: {
          ...this.getDefaultNumericConfigDef({
            numberFormat: numberFormats.oneDecimalPlace,
            divideBy: 1000,
          }),
        },
        [columnType.percentColumn]: {
          ...this.getDefaultNumericConfigDef({ numberFormat: numberFormats.percent }),
        },
        [columnType.bpsColumn]: {
          ...this.getDefaultNumericConfigDef({
            numberFormat: numberFormats.oneDecimalPlace,
            divideBy: 0.0001, // aka multiply by 10000
          }),
        },
      },
    };
    this.columnDefs = [
      {
        headerName: '',
        field: '',
        minWidth: 20,
        cellRendererSelector: params =>
          !params.data.isStoreGroupAggregation && this.isExpandable(params.data.level)
            ? {
                component: 'agGridChevronIcon',
              }
            : null,
        cellRendererParams: params => {
          return {
            classes: ['expand-icon', 'clickable'],
            expandedStateIcon: 'mdi-chevron-down',
            collapsedStateIcon: 'mdi-chevron-right',
            fieldName: 'isLevelExpanded',
            clickHandler: () => this.toggleExpandIcon(params),
          };
        },
      },
      {
        headerName: '',
        field: 'name',
        cellClass: 'narrow-padding bold-text name-column clickable',
        onCellClicked: params => this.toggleExpandIcon(params),
        ...agGridUtils.getStrictWidthColumn(220),
        ...agGridUtils.getTruncatedValueGetters({ truncationLength: 30 }),
      },
      {
        headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.retailMargin'),
        headerClass: 'narrow-padding border-left',
        children: this.generateGroupColumns(columnGroup.retailMargin),
      },
      {
        headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.dllSplit'),
        headerClass: 'narrow-padding border-left',
        children: this.generateGroupColumns(columnGroup.dllSplit),
      },
      {
        headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.wholesaleMargin'),
        headerClass: 'narrow-padding border-left',
        children: this.generateGroupColumns(columnGroup.wholesaleMargin),
      },
      {
        headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.affiliateMargin'),
        headerClass: 'narrow-padding border-left',
        children: this.generateGroupColumns(columnGroup.affiliateMargin),
      },
      {
        headerClass: 'narrow-padding border-left',
        children: [
          {
            headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.toolStoreGroup'),
            headerClass: 'narrow-padding border-left',
            field: '',
            cellClass: 'default-padding border-left',
            cellRendererSelector: params => {
              if (!params.data.isStoreGroupAggregation) {
                return {
                  component: 'agGridChevronIcon',
                };
              }
              return null;
            },
            cellRendererParams: params => {
              return {
                classes: ['expand-icon', 'clickable'],
                expandedStateIcon: 'mdi-chevron-down',
                collapsedStateIcon: 'mdi-chevron-right',
                fieldName: 'isStoreGroupExpanded',
                clickHandler: () => this.toggleStoreGroupExpansion(params),
              };
            },
          },
        ],
      },
    ];
    this.defaultColDef = {
      editable: false,
      suppressMenu: true,
      resizable: false,
      sortable: false,
      suppressMovable: true,
      valueParser: agGridUtils.defaultParser,
    };
  },

  created() {
    this.toggleLoadingOverlay(this.isLoading);
  },

  methods: {
    toggleLoadingOverlay(isLoading) {
      if (!this.gridApi) return;
      if (isLoading) {
        // without nextTick, loading overlay doesn't display on non-tsg expansion
        this.$nextTick(() => this.gridApi.showLoadingOverlay());
      }
      // this.gridApi.hideOverlay() hides the no rows overlay in some cases, which we want to keep.
    },

    generateGroupColumns(field) {
      const defaultCssClasses = 'default-padding justified-right-cell bold-text';
      const deltaColumnWidth = 80;

      const percentageGroupColumns = {
        current: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.currentPercent'),
          field: `${field}CurrentPercent`,
          type: columnType.percentColumn,
          headerClass: 'narrow-padding border-left',
          cellClass: `${defaultCssClasses} border-left`,
        },
        proposed: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.proposedPercent'),
          field: `${field}ProposedPercent`,
          type: columnType.percentColumn,
          headerClass: 'narrow-padding',
          cellClass: defaultCssClasses,
        },
        delta: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.deltabps'),
          field: `${field}DeltaPercent`,
          type: columnType.bpsColumn,
          cellClass: `${defaultCssClasses} delta-column`,
          cellClassRules: {
            'colour-red': params => params.data[`${field}DeltaPercent`] < 0,
            'colour-green': params => params.data[`${field}DeltaPercent`] >= 0,
          },
          headerClass: 'narrow-padding',
          ...agGridUtils.getStrictWidthColumn(deltaColumnWidth),
        },
      };

      const numericGroupColumns = {
        current: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.current'),
          field: `${field}Current`,
          type: columnType.numericColumn,
          headerClass: 'narrow-padding border-left',
          cellClass: `${defaultCssClasses} border-left`,
        },
        proposed: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.proposed'),
          field: `${field}Proposed`,
          type: columnType.numericColumn,
          cellClass: defaultCssClasses,
        },
        delta: {
          headerName: this.$t('wholesale.wholesaleMargin.tableHeaders.delta'),
          field: `${field}Delta`,
          type: columnType.numericColumn,
          cellClass: `${defaultCssClasses} delta-column`,
          cellClassRules: {
            'colour-red': params => params.data[`${field}Delta`] < 0,
            'colour-green': params => params.data[`${field}Delta`] >= 0,
          },
          headerClass: 'narrow-padding',
          ...agGridUtils.getStrictWidthColumn(deltaColumnWidth),
        },
      };

      if (field === columnGroup.retailMargin) {
        return [percentageGroupColumns.current];
      }
      if (field === columnGroup.dllSplit) {
        return [...values(percentageGroupColumns)];
      }
      return [...values(percentageGroupColumns), ...values(numericGroupColumns)];
    },

    sizeColumnsToFit: debounce(function() {
      this.gridApi.sizeColumnsToFit();
    }, 300),

    onGridReady(params) {
      this.gridApi = params.api;
      this.toggleLoadingOverlay(this.isLoading);
      this.gridColumnApi = params.columnApi;
      this.gridApi.sizeColumnsToFit();
      window.addEventListener('resize', this.sizeColumnsToFit);
      this.$emit('gridReady');
    },

    isExpandable(level) {
      return includes([unitLevel, categoryLevel, segmentLevel, subsegmentLevel], level);
    },

    toggleExpandIcon(params) {
      const { _id, level, isLevelExpanded, isStoreGroupAggregation } = params.data;
      if (level >= microsegmentLevel || isStoreGroupAggregation) {
        return;
      }
      this.$emit('toggleLevel', { parentId: _id, level, beingExpanded: !isLevelExpanded });
    },

    toggleStoreGroupExpansion(params) {
      // Get store group aggregations for params.data.levelEntryKey
      const { levelEntryKey, parentId, level, isStoreGroupExpanded } = params.data;
      this.$emit('toggleStoreGroup', {
        parentId,
        levelEntryKey,
        level,
        beingExpanded: !isStoreGroupExpanded,
      });
    },

    getDefaultNumericConfigDef({ numberFormat, divideBy }) {
      return {
        ...agGridUtils.getNumericColumnCustomType({
          formatter: this.formatNumber,
          formatterParams: {
            format: numberFormat,
            nullAsDash: true,
          },
          stringToNumberFormatter: this.formatStringToNumber,
          divideBy,
        }),
      };
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

$hierarchy-rows: (
  '0' 1.8rem $level-0-row-border-color $level-0-row-color,
  '1' 1.6rem $level-1-row-border-color $level-1-row-color,
  '2' 1.4rem $level-2-row-border-color $level-2-row-color,
  '3' 1.2rem $level-3-row-border-color $level-3-row-color,
  '4' 1rem $level-4-row-border-color $level-4-row-color,
  '5' 1rem $level-5-row-border-color $level-5-row-color
);

.ag-wholesale-hierarchy {
  @each $level, $size, $border-color, $color in $hierarchy-rows {
    .row-hierarchy-level-#{$level} {
      border-color: $border-color;

      .ag-cell.name-column {
        color: $color;
        font-size: $size;
      }
    }
  }

  .row-hierarchy-level-3,
  .row-hierarchy-level-5 {
    .ag-cell.name-column {
      font-weight: normal;
    }
  }

  .row-store-group {
    .ag-cell.name-column {
      display: inline-block;
      color: black;
      font-weight: normal;
      text-align: right;
      padding-right: 1rem;
    }
  }

  .row-store-group-end {
    border-top: none;

    .ag-cell {
      display: none;
    }
  }

  .expand-icon {
    transform: scale(2);
    color: $icon-colour;
  }

  .delta-column {
    background-color: $delta-column-background-color;
  }

  .ag-root-wrapper {
    border: none;
  }

  .ag-header {
    border-color: $header-bottom-border-color;
    border-width: 2px;
  }

  .ag-cell,
  .ag-header-cell,
  .ag-header-group-cell {
    &.border-right {
      border-right: 1px solid $custom-ag-grid-header-separator-color;
    }

    &.border-left {
      border-left: 1px solid $custom-ag-grid-header-separator-color;
    }

    &.bold-text {
      font-weight: 600;
    }
  }
}
</style>
