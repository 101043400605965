import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", { staticClass: "translation-editor" }, [
    _c(
      "div",
      { staticClass: "translation-editor__container" },
      [
        _c("pricing-json-editor", {
          staticClass: "translation-editor__container--editor",
          attrs: {
            response: _vm.i18nTranslations,
            "emit-change": true,
            "emit-error": true,
          },
          on: { change: _vm.trackNewTranslations, error: _vm.trackError },
        }),
        _vm._v(" "),
        _c(
          "div",
          { staticClass: "translation-editor__container--button" },
          [
            _c(
              VBtn,
              {
                attrs: {
                  color: "primary",
                  small: "",
                  depressed: "",
                  disabled:
                    _vm.disableSave ||
                    _vm.isEmpty(_vm.updatedTranslations) ||
                    _vm.translationsHaveNotChanged,
                },
                on: { click: _vm.setTranslations },
              },
              [
                _vm._v(
                  "\n        " + _vm._s(_vm.$t("actions.save")) + "\n      "
                ),
              ]
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }