var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Line pricing group override")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "line-pricing-group-override-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "line-pricing-group-override-transform",
                transform: "translate(-138.000000, -679.000000)",
                "fill-rule": "nonzero",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "line-pricing-group-override",
                    transform: "translate(138.000000, 679.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M12.168,11.4453 C12.4743,10.9858 13.0952,10.8616 13.5547,11.168 C14.0142,11.4743 14.1363,12.0905 13.83,12.55 L13.83,12.55 L12.767,14.1523 C13.4909,14.4532 14.0001099,15.1672 14.0001099,16 L14.0001099,16 L14.0001099,18 C14.0001099,19.1046 13.1046,20 12,20 L12,20 L10,20 C8.89543,20 8,19.1046 8,18 L8,18 L8,17.85 L6,17.85 L6,18 C6,19.1 4.79826,20 4,20 L4,20 L2,20 C0.89543,20 0,19.1046 0,18 L0,18 L0,16 C0,14.8954 0.895431,14 2,14 L2,14 L4,14 C5.10457,14 6,14.8954 6,16 L6,16 L6,16.15 L8,16.15 L8,16 C8,14.8954 9.20173,14 10,14 L10,14 L10.4648,14 Z M18.4453,11.168 C18.9048,10.8616 19.5257,10.9858 19.832,11.4453 L19.832,11.4453 L21.5352,14 L22,14 C23.1046,14 24,14.8954 24,16 L24,16 L24,18 C24,19.1046 23.1046,20 22,20 L22,20 L20,20 C18.8954,20 18,19.1046 18,18 L18,18 L18,16 C18,15.1672 18.5091,14.4532 19.233,14.1523 L19.233,14.1523 L18.1679,12.5547 C17.8616,12.0952 17.9858,11.4743 18.4453,11.168 Z M12,16 L10,16 L10,18 L12,18 L12,16 Z M4,16 L2,16 L2,18 L4,18 L4,16 Z M22,16 L20,16 L20,18 L22,18 L22,16 Z M17,4 C18.1045695,4 19,4.8954305 19,6 L19,8 C19,9.1045695 18.1045695,10 17,10 L15,10 C13.8954305,10 13,9.1045695 13,8 L13,6 C13,4.8954305 13.8954305,4 15,4 L17,4 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }