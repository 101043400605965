'use strict';

const _ = require('lodash');
const { mapGridView } = require('../data/utils/time-flexible-utils');

const formatProduct = (product, additionalContentEnhancer) => {
  /**
   * Format a product's information before sending it to the client side.
   * @param product - the product to format
   * @param additionalContentEnhancer - function to apply to the product (e.g. addIndexToProductInfo)
   *  this may be an identity function, i.e. the product is returned unchanged.
   * @returns the formatted product
   */
  return additionalContentEnhancer(product);
};

const calculateCompetitorIndex = (competitor, clientPriceHistory, clientLivePrice) => {
  /**
   * Calculate the competitor PAS for each week of historical competitor prices.
   * @param competitor - competitor field from the workpackage-product document
   * @param clientPriceHistory - the array of client history (priceHistoryWeek)
   * @param clientLivePrice - thelivePrice object
   * @returns {Array} - competitor field with competitorIndex field added to competitorHistoryWeek
   */
  const fullClientHistory = [...clientPriceHistory, clientLivePrice];
  const competitorWithIndex = _.map(competitor, c => {
    const historyWithIndex = _.map(c.competitorHistoryWeek, h => {
      const clientPrice = _.get(
        _.find(
          fullClientHistory,
          clientHistory =>
            h.effectiveTimestamp >= clientHistory.effectiveTimestamp &&
            h.effectiveTimestamp < clientHistory.expiryTimestamp
        ),
        'price',
        null
      );
      h.competitorIndex = _.isNil(clientPrice)
        ? null
        : (h.competitorIndex = (h.price - clientPrice) / clientPrice);
      return h;
    });
    c.competitorHistoryWeek = historyWithIndex;

    c.livePriceIndex = (c.competitorPrice - clientLivePrice.price) / clientLivePrice.price;

    return c;
  });
  return competitorWithIndex;
};

const addIndexToProductInfo = product => {
  /**
   * Given an individual product, add a competitorIndex field to each element of each competitor's
   * competitorHistoryWeek and return the product
   */
  product.competitor = calculateCompetitorIndex(
    product.competitor,
    product.priceHistoryWeek,
    product.livePrice
  );
  return product;
};

const formatFieldsForExport = product => {
  return {
    productKey: product.productKey,
    productKeyDisplay: product.productKeyDisplay,
    productDescription: product.productDescription,
    productSizeType: product.productSizeType,
    yearlySales: product.yearlySales,
    yearlyVolume: product.yearlyVolume,
    promo: product.mandatoryEngineInputs.promoParticipationSales,
    nonPromoNetCost: product.mandatoryEngineInputs.nonPromoNetCost,
    costDelta: product.costDelta,
    livePrice: product.livePrice,
    intentionPrice: product.intentionPrice,
    intentionMargin: product.intentionMargin,
    scenarioCostImpact: product.scenarioCostImpact,
    scenarioSalesImpact: product.scenarioSalesImpact,
  };
};

const addNormWeightToExport = (currentFields, product) => {
  return {
    ...currentFields,
    normWeight: product.normWeight,
    scenarioPricePerNormWeight: product.scenarioPricePerNormWeight,
    scenarioPricePerContentUnit: product.scenarioPricePerContentUnit,
  };
};

const addStoreGroupToExport = (currentFields, product) => {
  return {
    ...currentFields,
    toolStoreGroupDescription: product.toolStoreGroupDescription,
    storeGroupReferencePrice: product.storeGroupReferencePrice,
  };
};

const useTimeFlexibleDataForExport = (currentFields, product, selectedHistoricalPeriod) => {
  const timeFlexibleFields = mapGridView(selectedHistoricalPeriod, true);
  return { ...currentFields, ...timeFlexibleFields(product) };
};

module.exports = {
  addIndexToProductInfo,
  formatProduct,
  formatFieldsForExport,
  addNormWeightToExport,
  addStoreGroupToExport,
  useTimeFlexibleDataForExport,
};
