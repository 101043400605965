export default {
  xAxis: {
    labels: {
      style: {
        fontSize: '1rem',
      },
      y: 17,
    },
    tickLength: 6,
  },
  yAxis: {
    labels: {
      style: {
        fontSize: '1rem',
      },
    },
  },
};
