import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    { attrs: { value: _vm.isOpen, persistent: "", width: "40rem" } },
    [
      _c(
        VCard,
        [
          _c(
            VContainer,
            [
              _c(
                VForm,
                {
                  ref: "attributeForm",
                  attrs: { "lazy-validation": "" },
                  on: {
                    submit: function ($event) {
                      $event.preventDefault()
                      return _vm.submit.apply(null, arguments)
                    },
                  },
                },
                [
                  _c(VCardText, { staticClass: "header-text" }, [
                    _vm._v("\n          " + _vm._s(_vm.title) + "\n        "),
                  ]),
                  _vm._v(" "),
                  _c(
                    VIcon,
                    {
                      staticClass: "close-button",
                      attrs: { color: "grey" },
                      on: { click: _vm.cancel },
                    },
                    [_vm._v("close")]
                  ),
                  _vm._v(" "),
                  _c(
                    VCardText,
                    { staticClass: "body" },
                    [
                      _vm.isCreateMode &&
                      _vm.scope === _vm.attributeScope.pricingGroup
                        ? [
                            _c(VAutocomplete, {
                              staticClass: "ma-0 pt-0 mb-2",
                              attrs: {
                                dense: "",
                                "return-object": "",
                                flat: "",
                                items: _vm.existingGrandparentHierarchies,
                                "item-text": "levelEntryDescription",
                                "item-value": "levelEntryDescription",
                              },
                              on: {
                                input: _vm.setSelectedGrandparentHierarchy,
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pg-dropdown-item-text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  props.item
                                                    .levelEntryDescription
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectHierarchyText(
                                                _vm.level - 2
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                30152066
                              ),
                              model: {
                                value: _vm.selectedGrandparentHierarchy,
                                callback: function ($$v) {
                                  _vm.selectedGrandparentHierarchy = $$v
                                },
                                expression: "selectedGrandparentHierarchy",
                              },
                            }),
                            _vm._v(" "),
                            _c(VAutocomplete, {
                              staticClass: "ma-0 pt-0 mb-2",
                              attrs: {
                                dense: "",
                                "return-object": "",
                                flat: "",
                                "small-chips": "",
                                multiple: "",
                                "deletable-chips": "",
                                clearable: "",
                                items: _vm.existingParentHierarchies,
                                disabled: !_vm.selectedGrandparentHierarchy,
                                "item-text": "levelEntryDescription",
                                "item-value": "levelEntryDescription",
                                "search-input": _vm.parentSearchInput,
                              },
                              on: {
                                "update:searchInput": function ($event) {
                                  _vm.parentSearchInput = $event
                                },
                                "update:search-input": function ($event) {
                                  _vm.parentSearchInput = $event
                                },
                                input: function ($event) {
                                  _vm.parentSearchInput = null
                                },
                              },
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "item",
                                    fn: function (props) {
                                      return [
                                        _c(
                                          "span",
                                          {
                                            staticClass:
                                              "pg-dropdown-item-text",
                                          },
                                          [
                                            _vm._v(
                                              "\n                  " +
                                                _vm._s(
                                                  props.item
                                                    .levelEntryDescription
                                                ) +
                                                "\n                "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                  },
                                  {
                                    key: "label",
                                    fn: function () {
                                      return [
                                        _c("span", [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectHierarchyText(
                                                _vm.level - 1
                                              )
                                            )
                                          ),
                                        ]),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                false,
                                1596975873
                              ),
                              model: {
                                value: _vm.selectedParentHierarchy,
                                callback: function ($$v) {
                                  _vm.selectedParentHierarchy = $$v
                                },
                                expression: "selectedParentHierarchy",
                              },
                            }),
                          ]
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VTextField, {
                        attrs: {
                          value: _vm.attributeName,
                          label: _vm.$t("general.labels.attributeName"),
                          rules: _vm.attributeNameRules,
                          required: "",
                        },
                        model: {
                          value: _vm.attributeName,
                          callback: function ($$v) {
                            _vm.attributeName =
                              typeof $$v === "string" ? $$v.trim() : $$v
                          },
                          expression: "attributeName",
                        },
                      }),
                      _vm._v(" "),
                      _vm.isCreateMode
                        ? _c(VSelect, {
                            attrs: {
                              items: _vm.dataTypes,
                              "item-text": "value",
                              "item-value": "key",
                              "single-line": "",
                              dense: "",
                              label: _vm.$t("general.labels.attributeDataType"),
                            },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-item-text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(props.item.value) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                              ],
                              null,
                              false,
                              1682939843
                            ),
                            model: {
                              value: _vm.selectedDataType,
                              callback: function ($$v) {
                                _vm.selectedDataType = $$v
                              },
                              expression: "selectedDataType",
                            },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.isCreateMode
                        ? [
                            _c(
                              "div",
                              {
                                staticClass:
                                  "attribute-info d-flex flex-column",
                              },
                              [
                                _c("span", { staticClass: "pb-2" }, [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(_vm.$t("general.labels.source")) +
                                        ":"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.sourceField) +
                                      "\n              "
                                  ),
                                ]),
                                _vm._v(" "),
                                _c("span", [
                                  _c("strong", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t("general.labels.dataType")
                                      ) + ":"
                                    ),
                                  ]),
                                  _vm._v(
                                    " " +
                                      _vm._s(_vm.dataTypeField) +
                                      "\n              "
                                  ),
                                ]),
                              ]
                            ),
                          ]
                        : _vm._e(),
                    ],
                    2
                  ),
                  _vm._v(" "),
                  _c(
                    VCardActions,
                    { staticClass: "btn-group" },
                    [
                      _c(VSpacer),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: {
                            color: "primary",
                            outlined: "",
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-2",
                          attrs: {
                            disabled: !_vm.isValid,
                            color: "primary",
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.submit },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.submitBtnCaption) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }