<template>
  <div class="workpackage-unit">
    <v-row no-gutters>
      <v-col cols="12" class="pb-0 pt-0 pl-4 d-flex align-center justify-space-between">
        <div class="d-flex" @click="selectUnit">
          <tooltipped-truncated-field
            class="link-arrow-text"
            :text="unitData.levelEntryDescription"
            :truncation-length="truncationLength"
          />
          <div v-if="!preventUnitSelection" class="d-flex link-arrow" />
        </div>
        <v-checkbox
          v-if="isSelectable"
          :value="hasSelectedAll"
          class="checkbox my-0 mr-1 pa-0"
          @change="selectAllCategories()"
        />
      </v-col>
    </v-row>
    <div v-if="!isSelectable" class="d-flex">
      <div v-for="(icon, index) in kpiData" :key="index" class="mr-1">
        <work-packages-unit-kpi-icon
          :number="icon.number"
          :tooltip-message="icon.tooltipMessage"
          :rejected="icon.rejected"
          :user-role="icon.userRole"
        />
      </div>
      <div class="mr-1">
        <approval-icon
          :approved="unitComplete"
          :unit-level="true"
          tooltip-position="top-left"
          :tooltip-message="$t('workPackages.unitComplete')"
        />
      </div>
    </div>

    <v-container
      v-if="isExpanded"
      fluid
      pr-0
      pl-0
      pb-0
      :class="isSelectable ? 'border-creation' : 'border'"
    >
      <v-row class="header mr-0" />
      <v-row
        v-for="(category, index) in unitCategories"
        :key="index"
        no-gutters
        :class="[index % 2 ? 'even-rows' : '']"
      >
        <div class="category d-flex justify-space-between">
          <div class="px-3">
            <tooltipped-truncated-field
              :text="category.levelEntryDescription"
              :truncation-length="truncationLength"
            />
          </div>
          <div class="px-1 d-flex">
            <v-checkbox
              v-if="isSelectable"
              v-model="categories"
              :value="category.levelEntryKey"
              class="checkbox ma-0 pa-0"
              @change="updateCategories($event)"
            />
            <approval-icon
              v-if="!isSelectable"
              :tooltip-message="$t('gridView.tooltips.categoryManagerApproval')"
              tooltip-position="top-left"
              :approved="isCategoryApproved(category, 'categoryManagerApproval')"
              class="pr-1"
            />
            <approval-icon
              v-if="!isSelectable"
              :tooltip-message="$t('gridView.tooltips.pricingSpecialistApproval')"
              tooltip-position="top-left"
              :approved="isCategoryApproved(category, 'pricingSpecialistApproval')"
            />
          </div>
        </div>
      </v-row>
    </v-container>
  </div>
</template>

<script>
import { mapGetters, mapActions, mapState } from 'vuex';
import { forEach, size, every, get, filter, isEmpty, some } from 'lodash';

export default {
  props: {
    unitData: {
      type: Object,
      required: true,
    },
    isExpanded: {
      type: Boolean,
      required: true,
    },
    isSelectable: {
      type: Boolean,
      required: true,
    },
    preventUnitSelection: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      categories: [],
      hasSelectedAll: false,
      submittedScenarios: [],
    };
  },

  computed: {
    ...mapGetters('context', ['isUnitManager']),
    ...mapGetters('hierarchy', ['getHierarchyForWorkpackage']),
    ...mapState('scenarioMetadata', ['scenarioMetadata']),
    ...mapState('workpackages', ['numberOfPriceChangesByWpIdAndCategoryId']),
    ...mapState('clientConfig', ['userRoles']),

    unitCategories() {
      return this.getHierarchyForWorkpackage(
        {
          level: this.unitData.level + 1,
          parentId: this.unitData.levelEntryKey,
        },
        this.unitData.workpackageId
      );
    },
    kpiData() {
      // this function could be changed to a vuex getter for when the same calculations need to appear in the status bar for ticket AHTDPR-607
      return [
        {
          number: this.numberOfCategories,
          tooltipMessage: this.$t('workPackages.categoriesInScope'),
          rejected: false,
        },
        {
          number: this.numberOfReleasedCategories,
          tooltipMessage: this.$t('workPackages.numberOfCategoriesReleased'),
          rejected: false,
        },
        {
          ...this.categoriesApprovedByCm,
          userRole: this.userRoles.categoryManager,
        },
        {
          ...this.categoriesApprovedByPs,
        },
        {
          number: this.numPriceChanges,
          tooltipMessage: this.$t('workPackages.numberOfPriceChangesFromApprovedCategories'),
        },
      ];
    },

    numberOfCategories() {
      return size(this.unitCategories);
    },

    categoriesApprovedByCm() {
      return this.categoriesApproved('categoryManagerApproval', 'Cm');
    },

    categoriesApprovedByPs() {
      return this.categoriesApproved('pricingSpecialistApproval', 'Ps');
    },

    // Gets the number of categories in a unit where all PGs are released
    numberOfReleasedCategories() {
      let pricingGroups;
      let allReleased;
      const result = this.unitCategories.map(category => {
        pricingGroups = this.getHierarchyForWorkpackage(
          pg => pg.parentId === category.levelEntryKey,
          this.unitData.workpackageId
        );
        if (isEmpty(pricingGroups)) return false;
        allReleased = every(pricingGroups, pg => get(pg, 'released.isReleased', null) === true);
        return allReleased;
      });
      return size(filter(result, res => res === true));
    },

    numPriceChanges() {
      return this.unitCategories.reduce((priceChanges, category) => {
        const pricingGroups = this.getHierarchyForWorkpackage(
          pg => pg.parentId === category.levelEntryKey,
          this.unitData.workpackageId
        );
        if (isEmpty(pricingGroups)) return priceChanges + 0;
        const allApprovedByPs = every(
          pricingGroups,
          pg => get(pg, `approvalStatus.pricingSpecialistApproval.approved`, null) === true
        );
        const someRejectedByCm = some(
          pricingGroups,
          pg => get(pg, 'approvalStatus.categoryManagerApproval.approved', null) === false
        );
        if (allApprovedByPs && !someRejectedByCm) {
          return (
            priceChanges +
            get(
              this.numberOfPriceChangesByWpIdAndCategoryId,
              `${this.unitData.workpackageId}::${category.levelEntryKey}`,
              0
            )
          );
        }
        return priceChanges;
      }, 0);
    },

    unitComplete() {
      const categoryNumber = this.numberOfCategories;
      const cmApproval = this.categoriesApprovedByCm;
      const cmApprovalNumber = cmApproval.rejected ? 0 : cmApproval.number;
      const psApproval = this.categoriesApprovedByPs;
      const psApprovalNumber = psApproval.rejected ? 0 : psApproval.number;
      if (categoryNumber === cmApprovalNumber && categoryNumber === psApprovalNumber) return true;
      return null;
    },
  },

  watch: {
    unitData() {
      this.categories = [];
      this.hasSelectedAll = false;
    },
  },

  created() {
    this.truncationLength = 36;
  },

  methods: {
    ...mapActions('workpackages', ['getPriceChangeData', 'setUserSelectionUnit']),
    ...mapActions('scenarioMetadata', ['fetchScenarioMetadata']),

    async selectUnit() {
      if (this.preventUnitSelection) return;
      const selectedWorkpackageId = this.unitData.workpackageId;
      const unitLevelEntryKey = this.unitData.levelEntryKey;
      const parentLevelEntryKey = this.unitData.parentId;
      await this.setUserSelectionUnit({
        selectedWorkpackageId,
        unitLevelEntryKey,
        parentLevelEntryKey,
      });
      if (this.isUnitManager) {
        this.$router.push({ path: 'pricing/impact-view' });
      } else {
        this.$router.push({ path: 'pricing/desk-view' });
      }
    },

    updateCategories(categories) {
      this.hasSelectedAll = this.categories.length === this.unitCategories.length;
      this.$emit('onCategoriesUpdated', this.unitData._id, categories);
    },

    selectAllCategories() {
      this.hasSelectedAll = !this.hasSelectedAll;
      const categories = [];
      if (this.hasSelectedAll) {
        forEach(this.unitCategories, category => {
          categories.push(category.levelEntryKey);
        });
      }
      this.categories = categories;
      this.$emit('onCategoriesUpdated', this.unitData._id, categories);
    },

    // Gets the number of categories in a unit where all PGs are approved
    categoriesApproved(approvalType, messageType) {
      let pricingGroups;
      let allApproved;
      let allNull;
      let someRejected;
      const result = this.unitCategories.map(category => {
        pricingGroups = this.getHierarchyForWorkpackage(
          pg => pg.parentId === category.levelEntryKey,
          this.unitData.workpackageId
        );
        if (isEmpty(pricingGroups))
          return { allApproved: false, someRejected: false, allNull: true };
        allApproved = every(
          pricingGroups,
          pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === true
        );
        someRejected = some(
          pricingGroups,
          pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === false
        );
        allNull = every(
          pricingGroups,
          pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === null
        );
        return { allApproved, allNull, someRejected };
      });
      const approvalCounts = size(filter(result, { allApproved: true }));
      const rejectedCounts = size(filter(result, { someRejected: true }));
      const nullCounts = size(filter(result, { allNull: true })); // If a PG has neither been approved or rejected it will be null
      if (nullCounts === size(this.unitCategories))
        return {
          number: 0,
          rejected: false,
          tooltipMessage: this.$t(`workPackages.numberOfCategoriesApprovedBy${messageType}`),
        };
      if (rejectedCounts)
        return {
          number: rejectedCounts,
          rejected: true,
          tooltipMessage: this.$t(`workPackages.numberOfCategoriesRejectedBy${messageType}`),
        };
      return {
        number: approvalCounts,
        rejected: false,
        tooltipMessage: this.$t(`workPackages.numberOfCategoriesApprovedBy${messageType}`),
      };
    },
    isCategoryApproved(category, approvalType) {
      const pricingGroups = this.getHierarchyForWorkpackage(
        {
          parentId: category.levelEntryKey,
        },
        this.unitData.workpackageId
      );
      if (isEmpty(pricingGroups)) return null;
      const isApproved = every(
        pricingGroups,
        pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === true
      );
      const hasRejections = some(
        pricingGroups,
        pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === false
      );
      const isNull = every(
        pricingGroups,
        pg => get(pg, `approvalStatus.${approvalType}.approved`, null) === null
      );
      if (isNull) return null;
      if (hasRejections) return false;
      if (isApproved) return true;
      return null;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.row.even-rows {
  background-color: $grid-view-dense-row-color;
}

.row {
  margin-left: -1.7rem;
  border-left: 0.1rem solid $pricing-grey-dark;

  .category {
    width: 100%;
    height: 2.3rem;
  }
}

.header {
  border-top: 0.1rem solid $pricing-grey-dark;
  .text {
    color: $pricing-grey;
  }
}

.v-icon.icon {
  color: $icon-colour;
}

.border {
  padding-top: 0.1rem;
}

.border-creation {
  padding-top: 2.9rem;
}

.checkbox {
  float: right;
}
</style>

<style lang="scss">
.workpackage-unit {
  .v-messages {
    display: none;
  }

  .d-flex {
    align-items: center;
  }

  .v-input__slot {
    margin: unset !important;
  }

  .v-input--selection-controls__input {
    margin-right: 0;
  }

  .v-input--selection-controls__ripple {
    height: 26px;
    width: 26px;
    left: -12px;
    top: calc(50% - 21px);
    margin: 7px;
  }
}

.circle-icon {
  font-size: 2rem;
  padding-top: 0rem;
  line-height: 2rem !important;
}

.fa-stack {
  line-height: 2rem !important;
  cursor: pointer;
  height: 2.5rem;
}

.unit-complete-col {
  padding-left: 1.6rem !important;
}
</style>
