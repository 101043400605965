import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
        },
        on: { onOk: _vm.runDeleteWorkpackage, onCancel: _vm.closeDeleteDialog },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(_vm._s(_vm.$t("dialogs.deleteWorkpackage.header"))),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("dialogs.deleteWorkpackage.body")) +
                    "\n      "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.workpackageToDelete
                        ? _vm.workpackageToDelete.description
                        : ""
                    )
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("transfer-dialog", {
        attrs: {
          "is-open": _vm.isTransferDialogOpen,
          "workpackage-to-transfer": _vm.workpackageToTransfer,
        },
        on: {
          onCancel: _vm.closeTransferDialog,
          onOk: _vm.runTransferWorkpackage,
        },
      }),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isExportPricingDialogOpen,
          "is-max-height-fixed": true,
          "ok-btn-text": _vm.useSapExport
            ? _vm.$t(
                `workPackages.exportToSAP.${
                  _vm.sapExportParams.toFTP ? "exportToSystem" : "downloadFile"
                }`
              )
            : _vm.$t("actions.downloadPricing"),
        },
        on: { onOk: _vm.runExport, onCancel: _vm.closeExportPricingDialog },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(
                      _vm.useSapExport
                        ? _vm.$t("workPackages.exportToSAP.title")
                        : _vm.$t("dialogs.downloadPricing.header")
                    ) +
                    "\n    "
                ),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.workpackageToExportPricing ||
                _vm.exporting(_vm.workpackageToExportPricing)
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("general.loadingMessage")) +
                          "\n      "
                      ),
                    ])
                  : _c(
                      "div",
                      [
                        _vm.noProductsGoLiveAfterWorkpackage
                          ? _c("div", [
                              _vm._v(
                                "\n          " +
                                  _vm._s(
                                    _vm.$t(
                                      "dialogs.downloadPricing.noProductsGoLiveAfterWP"
                                    )
                                  ) +
                                  "\n        "
                              ),
                            ])
                          : _c(
                              "div",
                              [
                                _c(
                                  VExpansionPanels,
                                  [
                                    _c(
                                      VExpansionPanel,
                                      [
                                        _c(
                                          VExpansionPanelHeader,
                                          {
                                            scopedSlots: _vm._u([
                                              {
                                                key: "actions",
                                                fn: function () {
                                                  return [
                                                    _c(VIcon, [
                                                      _vm._v("expand_more"),
                                                    ]),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ]),
                                          },
                                          [
                                            _c(
                                              "span",
                                              { staticClass: "panel-header" },
                                              [
                                                _vm._v(
                                                  "\n                  " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "dialogs.downloadPricing.hasProductsGoLiveAfterWP"
                                                      )
                                                    ) +
                                                    "\n                "
                                                ),
                                              ]
                                            ),
                                          ]
                                        ),
                                        _vm._v(" "),
                                        _c(
                                          VExpansionPanelContent,
                                          _vm._l(
                                            _vm.sortedProductsGoLiveAfterWorkpackage,
                                            function (productKey, index) {
                                              return _c(
                                                "p",
                                                {
                                                  key: `productKey-${index}`,
                                                  staticClass: "mb-1",
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(productKey) +
                                                      "\n                "
                                                  ),
                                                ]
                                              )
                                            }
                                          ),
                                          0
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                        _vm._v(" "),
                        _c("br"),
                        _vm._v(" "),
                        _c(
                          "feature-toggle",
                          { attrs: { toggle: _vm.useManualProductGoLiveDate } },
                          [
                            _vm.noProductsGoLiveBeforeWorkpackage
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "dialogs.downloadPricing.noProductsGoLiveBeforeWP"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      VExpansionPanels,
                                      [
                                        _c(
                                          VExpansionPanel,
                                          [
                                            _c(
                                              VExpansionPanelHeader,
                                              {
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c(VIcon, [
                                                          _vm._v("expand_more"),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "panel-header",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "dialogs.downloadPricing.hasProductsGoLiveBeforeWP"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              VExpansionPanelContent,
                                              _vm._l(
                                                _vm.sortedProductsGoLiveBeforeWorkpackage,
                                                function (productKey, index) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: `productKey-${index}`,
                                                      staticClass: "mb-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(productKey) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                            _vm._v(" "),
                            _c("br"),
                            _vm._v(" "),
                            _vm.noProductsGoLiveBeforeToday
                              ? _c("div", [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          "dialogs.downloadPricing.noProductsGoLiveBeforeToday"
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ])
                              : _c(
                                  "div",
                                  [
                                    _c(
                                      VExpansionPanels,
                                      [
                                        _c(
                                          VExpansionPanel,
                                          [
                                            _c(
                                              VExpansionPanelHeader,
                                              {
                                                scopedSlots: _vm._u([
                                                  {
                                                    key: "actions",
                                                    fn: function () {
                                                      return [
                                                        _c(VIcon, [
                                                          _vm._v("expand_more"),
                                                        ]),
                                                      ]
                                                    },
                                                    proxy: true,
                                                  },
                                                ]),
                                              },
                                              [
                                                _c(
                                                  "span",
                                                  {
                                                    staticClass: "panel-header",
                                                  },
                                                  [
                                                    _vm._v(
                                                      "\n                    " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "dialogs.downloadPricing.hasProductsGoLiveBeforeToday"
                                                          )
                                                        ) +
                                                        "\n                  "
                                                    ),
                                                  ]
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              VExpansionPanelContent,
                                              _vm._l(
                                                _vm.sortedProductsGoLiveBeforeToday,
                                                function (productKey, index) {
                                                  return _c(
                                                    "p",
                                                    {
                                                      key: `productKey-${index}`,
                                                      staticClass: "mb-1",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(productKey) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                }
                                              ),
                                              0
                                            ),
                                          ],
                                          1
                                        ),
                                      ],
                                      1
                                    ),
                                  ],
                                  1
                                ),
                          ]
                        ),
                      ],
                      1
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isExportAPIDialogOpen,
          "ok-btn-text": _vm.$t("actions.postPricing"),
        },
        on: {
          onOk: function ($event) {
            return _vm.exportWorkpackageResultsToAPI({
              selectedWorkpackage: _vm.workpackageToExportAPI,
              apiExportFields: _vm.apiExportFields,
            })
          },
          onCancel: _vm.closeExportAPIDialog,
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$t("dialogs.exportAPI.header")))]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _c("p", { attrs: { id: "export-api-warning" } }, [
                  _c("strong", [
                    _vm._v(_vm._s(_vm.$t("dialogs.exportAPI.warning"))),
                  ]),
                ]),
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("dialogs.exportAPI.body")) +
                    "\n      "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.workpackageToExportAPI
                        ? _vm.workpackageToExportAPI.description
                        : ""
                    )
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            { staticClass: "heading-bar mb-5", attrs: { cols: "12" } },
            [
              _c(
                "div",
                {
                  staticClass:
                    "d-flex justify-space-between heading-bar-heading",
                },
                [
                  _c("span", [_vm._v(_vm._s(_vm.$t(_vm.heading)))]),
                  _vm._v(" "),
                  _c(
                    "span",
                    [
                      _c(
                        VIcon,
                        {
                          attrs: { size: "3rem" },
                          on: {
                            click: function ($event) {
                              return _vm.toggleWorkpackagesExpansion()
                            },
                          },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.getExpansionIcon) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(VRow, [
        _c(
          "div",
          { staticClass: "flex scroll" },
          [
            _vm.workpackagesExpanded
              ? _c(VDataTable, {
                  staticClass: "work-package-table",
                  attrs: {
                    headers: _vm.headers,
                    items: _vm.allWorkpackages,
                    search: _vm.search,
                    "loading-text": _vm.$t("general.loadingMessage"),
                    loading: _vm.inactiveWps
                      ? _vm.inactiveLoading
                      : _vm.loading,
                    "sort-by": _vm.sorting.sortBy,
                    "sort-desc": _vm.sorting.sortDesc,
                    "max-height": "60rem",
                    "hide-default-footer": "",
                    "disable-pagination": "",
                    "item-key": "_id",
                  },
                  on: {
                    "update:sortBy": function ($event) {
                      return _vm.$set(_vm.sorting, "sortBy", $event)
                    },
                    "update:sort-by": function ($event) {
                      return _vm.$set(_vm.sorting, "sortBy", $event)
                    },
                    "update:sortDesc": function ($event) {
                      return _vm.$set(_vm.sorting, "sortDesc", $event)
                    },
                    "update:sort-desc": function ($event) {
                      return _vm.$set(_vm.sorting, "sortDesc", $event)
                    },
                  },
                  nativeOn: {
                    scroll: function ($event) {
                      return _vm.handleScroll.apply(null, arguments)
                    },
                    mousewheel: function ($event) {
                      return _vm.preventPageNavigationOnHorizontalScroll(
                        $event,
                        ".v-data-table"
                      )
                    },
                  },
                  scopedSlots: _vm._u(
                    [
                      {
                        key: "no-data",
                        fn: function () {
                          return [
                            _c("div", { staticClass: "no-data-text" }, [
                              !_vm.loading
                                ? _c("span", { attrs: { value: true } }, [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("workPackages.noData")) +
                                        "\n            "
                                    ),
                                  ])
                                : _vm._e(),
                            ]),
                          ]
                        },
                        proxy: true,
                      },
                      {
                        key: "item",
                        fn: function (props) {
                          return [
                            _c(
                              "tr",
                              {
                                staticClass: "workpackage white-row",
                                class: [
                                  _vm.isSelected(props.item),
                                  _vm.isActive(
                                    props.item.goLiveDate,
                                    props.item.mocked
                                  ),
                                ],
                              },
                              [
                                _c(
                                  "td",
                                  { staticClass: "text-xs-left text-bold" },
                                  [
                                    props.item.mocked
                                      ? _c("progress-spinner", {
                                          attrs: {
                                            "job-id": props.item.description,
                                            "job-type":
                                              "run_create_workpackage",
                                          },
                                        })
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _c(
                                      "a",
                                      {
                                        staticClass: "link-arrow pb-2",
                                        attrs: { "e2e-slug": "linkWP" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.selectWorkpackage(
                                              props.item
                                            )
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(props.item.description) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _vm.isExpanded(props.index)
                                      ? _c("work-package-reveal", {
                                          attrs: { workpackage: props.item },
                                        })
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "pa-0 col-border-right content-end",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "inner-table" },
                                      [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pa-1 text-small col-fixed-width col-no-border",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.getCompletionDate(
                                                        props.item
                                                          .completionDate
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pa-1 text-small col-fixed-width col-no-border",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                      " +
                                                    _vm._s(
                                                      _vm.formatLocalisedDate(
                                                        props.item.goLiveDate
                                                      )
                                                    ) +
                                                    "\n                    "
                                                ),
                                              ]
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pl-1 pb-0 text-small col-no-border",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c("span", {
                                                  domProps: {
                                                    innerHTML: _vm._s(
                                                      _vm.formatStoreFront(
                                                        props.item.storeFormat
                                                      )
                                                    ),
                                                  },
                                                }),
                                              ]
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.getUnitsFromWorkPackage(props.item),
                                  function (unit, i) {
                                    return _c(
                                      "td",
                                      {
                                        key: `workPackagesUnit${i}`,
                                        staticClass:
                                          "col-border-right col-unit pr-0",
                                      },
                                      [
                                        _c("work-packages-unit", {
                                          attrs: {
                                            "unit-data": unit,
                                            "is-expanded": _vm.isExpanded(
                                              props.index
                                            ),
                                            "is-selectable": false,
                                            "prevent-unit-selection": false,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.maxUnitCount -
                                    _vm.getUnitsFromWorkPackage(props.item)
                                      .length,
                                  function (n) {
                                    return _c("td", {
                                      key: `blankCell${n}`,
                                      staticClass: "col-border-right col-unit",
                                    })
                                  }
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "col-border-left content-end pl-0 pr-0",
                                  },
                                  [
                                    !_vm.isExpanded(props.index)
                                      ? _c(
                                          VIcon,
                                          {
                                            staticClass: "pb-1",
                                            attrs: {
                                              large: "",
                                              "e2e-slug": "iconExpandWP",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleExpansion(
                                                  props.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                expand_more\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                    _vm._v(" "),
                                    _vm.isExpanded(props.index)
                                      ? _c(
                                          VIcon,
                                          {
                                            staticClass: "pb-1",
                                            attrs: {
                                              large: "",
                                              "e2e-slug": "iconCollapseWP",
                                            },
                                            on: {
                                              click: function ($event) {
                                                return _vm.toggleExpansion(
                                                  props.index
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                expand_less\n              "
                                            ),
                                          ]
                                        )
                                      : _vm._e(),
                                  ],
                                  1
                                ),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _vm.isExpanded(props.index) &&
                            _vm.canExecuteWorkpackage
                              ? _c("tr", { staticClass: "button-row" }, [
                                  _c(
                                    "td",
                                    { staticClass: "text-left pt-2 pb-2" },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: {
                                            small: "",
                                            disabled: _vm.disabled(props.item),
                                            loading: _vm.transferring(
                                              props.item
                                            ),
                                            color: "primary",
                                            "e2e-slug":
                                              "btnTransferWorkpackage",
                                            depressed: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.openTransferDialog(
                                                props.item
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.$t(
                                                  "workPackages.transferWorkpackage"
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c("td", {
                                    attrs: { colspan: _vm.maxUnitCount },
                                  }),
                                  _vm._v(" "),
                                  _c(
                                    "td",
                                    {
                                      staticClass: "pt-2 pb-2 text-right",
                                      attrs: { colspan: "4" },
                                    },
                                    [
                                      _c(
                                        "feature-toggle",
                                        {
                                          attrs: {
                                            toggle:
                                              "displayExportPenaltyDataButton",
                                          },
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                loading:
                                                  _vm.loadingPenaltyExports,
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.exportPenaltyData({
                                                    workpackage: props.item,
                                                  })
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "actions.downloadPenaltyData"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "feature-toggle",
                                        {
                                          attrs: {
                                            toggle: "displayExportToSAPButton",
                                          },
                                        },
                                        [
                                          _c("sap-export-button", {
                                            attrs: {
                                              disabled:
                                                _vm.disabled(props.item) ||
                                                _vm.disableOWUserExport,
                                              loading: _vm.exportingToSAP(
                                                props.item
                                              ),
                                              params: {
                                                selectedWorkpackage: props.item,
                                                fieldConfigs:
                                                  _vm.exportToSAPFields,
                                                exportConfig:
                                                  _vm.exportConfigs.exportToSAP,
                                                exportType:
                                                  _vm.exportType.exportToSAP,
                                              },
                                            },
                                            on: {
                                              downloadExportToSAP:
                                                _vm.triggerDownloadExportToSAP,
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        "feature-toggle",
                                        {
                                          attrs: {
                                            toggle: "displayExportToAPIButton",
                                          },
                                        },
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                small: "",
                                                disabled:
                                                  _vm.disabled(props.item) ||
                                                  _vm.disableOWUserExport,
                                                loading: _vm.posting(
                                                  props.item
                                                ),
                                                color: "primary",
                                                "e2e-slug": "btnPostPricing",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openExportAPIDialog(
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "actions.postPricing"
                                                    )
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(" "),
                                      _vm.displayGoLiveDateDialog
                                        ? _c(
                                            "span",
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    small: "",
                                                    disabled: _vm.disabled(
                                                      props.item
                                                    ),
                                                    loading: _vm.exporting(
                                                      props.item
                                                    ),
                                                    color: "primary",
                                                    "e2e-slug":
                                                      "btnExportPricing",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openExportPricingDialog(
                                                        props.item
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "actions.downloadPricing"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            "span",
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ml-2",
                                                  attrs: {
                                                    small: "",
                                                    disabled: _vm.disabled(
                                                      props.item
                                                    ),
                                                    loading: _vm.exporting(
                                                      props.item
                                                    ),
                                                    color: "primary",
                                                    "e2e-slug":
                                                      "btnExportPricing",
                                                    depressed: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.downloadWorkpackageResults(
                                                        {
                                                          selectedWorkpackage:
                                                            props.item,
                                                          fields: _vm.fields,
                                                          exportType:
                                                            _vm.exportType
                                                              .exportPricing,
                                                          columnFormatters:
                                                            _vm.columnFormatters,
                                                        }
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                  " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "actions.downloadPricing"
                                                        )
                                                      ) +
                                                      "\n                "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                      _vm._v(" "),
                                      props.item.type !==
                                      _vm.workpackageTypes.master
                                        ? _c(
                                            VBtn,
                                            {
                                              staticClass: "ml-2",
                                              attrs: {
                                                small: "",
                                                color: "primary",
                                                disabled: _vm.disabled(
                                                  props.item
                                                ),
                                                loading: _vm.deleting(
                                                  props.item
                                                ),
                                                "e2e-slug": "btnDeleteWP",
                                                depressed: "",
                                              },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.openDeleteDialog(
                                                    props.item
                                                  )
                                                },
                                              },
                                            },
                                            [
                                              _c(
                                                VIcon,
                                                { attrs: { small: "" } },
                                                [_vm._v(" fa-trash ")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c("tr", { staticClass: "spacer-row" }, [
                              _c("td"),
                              _vm._v(" "),
                              _c("td", { attrs: { colspan: "2" } }),
                              _vm._v(" "),
                              _c("td", {
                                attrs: { colspan: _vm.maxUnitCount + 1 },
                              }),
                            ]),
                          ]
                        },
                      },
                      {
                        key: "body.prepend",
                        fn: function ({}) {
                          return [
                            _vm.searchWorkpackages
                              ? _c("tr", { staticClass: "white-row" }, [
                                  _c(
                                    "td",
                                    {
                                      staticClass: "search-container",
                                      attrs: { colspan: "2" },
                                    },
                                    [
                                      _c(VTextField, {
                                        staticClass: "search-field p-0",
                                        attrs: {
                                          value: _vm.search,
                                          "append-icon": "search",
                                          label: _vm.$t("actions.search"),
                                          "single-line": "",
                                          loading: _vm.loading,
                                        },
                                        on: { input: _vm.debounceSearchUpdate },
                                      }),
                                    ],
                                    1
                                  ),
                                ])
                              : _vm._e(),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.creationMode &&
                                      _vm.canEditWorkpackage,
                                    expression:
                                      "creationMode && canEditWorkpackage",
                                  },
                                ],
                                staticClass:
                                  "workpackage white-row new-workpackage",
                              },
                              [
                                _c(
                                  "td",
                                  { staticClass: "text-xs-left" },
                                  [
                                    _c(VTextField, {
                                      staticClass: "ma-0 pa-0",
                                      attrs: {
                                        label: _vm.$t(
                                          "workPackages.description"
                                        ),
                                        "single-line": "",
                                        rules: _vm.rules.missingDescription,
                                      },
                                      model: {
                                        value: _vm.newWorkpackage.description,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            _vm.newWorkpackage,
                                            "description",
                                            $$v
                                          )
                                        },
                                        expression:
                                          "newWorkpackage.description",
                                      },
                                    }),
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(_vm.$t("workPackages.owner")) +
                                        ": "
                                    ),
                                    _c("strong", [
                                      _vm._v(
                                        _vm._s(
                                          _vm.profile && _vm.profile.username
                                        )
                                      ),
                                    ]),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "pa-0 col-border-right content-end",
                                    attrs: { colspan: "2" },
                                  },
                                  [
                                    _c(
                                      "table",
                                      { staticClass: "inner-table" },
                                      [
                                        _c("tbody", [
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pa-1 text-small col-fixed-width col-no-border",
                                              },
                                              [
                                                _c(
                                                  VMenu,
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "nudge-right": 5,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VTextField,
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      "append-icon":
                                                                        "event",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.computedCompletionDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.computedCompletionDate =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "computedCompletionDate",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.completionDatePicker,
                                                      callback: function ($$v) {
                                                        _vm.completionDatePicker =
                                                          $$v
                                                      },
                                                      expression:
                                                        "completionDatePicker",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(VDatePicker, {
                                                      attrs: {
                                                        "first-day-of-week":
                                                          _vm.i18nconfig
                                                            .firstDayOfTheWeek,
                                                        locale:
                                                          _vm.i18nconfig
                                                            .fallbackLocale,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.completionDatePicker = false
                                                        },
                                                      },
                                                      model: {
                                                        value:
                                                          _vm.completionDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.completionDate =
                                                            $$v
                                                        },
                                                        expression:
                                                          "completionDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pa-1 text-small col-fixed-width col-no-border",
                                              },
                                              [
                                                _c(
                                                  VMenu,
                                                  {
                                                    attrs: {
                                                      "close-on-content-click": false,
                                                      "nudge-right": 5,
                                                      transition:
                                                        "scale-transition",
                                                      "offset-y": "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VTextField,
                                                                _vm._g(
                                                                  {
                                                                    attrs: {
                                                                      "append-icon":
                                                                        "event",
                                                                      readonly:
                                                                        "",
                                                                    },
                                                                    model: {
                                                                      value:
                                                                        _vm.computedGoLiveDate,
                                                                      callback:
                                                                        function (
                                                                          $$v
                                                                        ) {
                                                                          _vm.computedGoLiveDate =
                                                                            $$v
                                                                        },
                                                                      expression:
                                                                        "computedGoLiveDate",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      true
                                                    ),
                                                    model: {
                                                      value:
                                                        _vm.goLiveDatePicker,
                                                      callback: function ($$v) {
                                                        _vm.goLiveDatePicker =
                                                          $$v
                                                      },
                                                      expression:
                                                        "goLiveDatePicker",
                                                    },
                                                  },
                                                  [
                                                    _vm._v(" "),
                                                    _c(VDatePicker, {
                                                      attrs: {
                                                        "first-day-of-week":
                                                          _vm.i18nconfig
                                                            .firstDayOfTheWeek,
                                                        locale:
                                                          _vm.i18nconfig
                                                            .fallbackLocale,
                                                      },
                                                      on: {
                                                        input: function (
                                                          $event
                                                        ) {
                                                          _vm.goLiveDatePicker = false
                                                        },
                                                      },
                                                      model: {
                                                        value: _vm.goLiveDate,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.goLiveDate = $$v
                                                        },
                                                        expression:
                                                          "goLiveDate",
                                                      },
                                                    }),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("tr", [
                                            _c(
                                              "td",
                                              {
                                                staticClass:
                                                  "pl-1 pb-0 text-small col-no-border",
                                                attrs: { colspan: "2" },
                                              },
                                              [
                                                _c(VSelect, {
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "general.labels.selectDefault"
                                                    ),
                                                    items:
                                                      _vm.masterWorkpackages,
                                                    rules: _vm.rules.hasValue,
                                                    "single-line": "",
                                                    "item-text": "storeFormat",
                                                  },
                                                  on: {
                                                    change: function ($event) {
                                                      return _vm.setAvailableCategories(
                                                        $event
                                                      )
                                                    },
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.newWorkpackage
                                                        .storeFormat,
                                                    callback: function ($$v) {
                                                      _vm.$set(
                                                        _vm.newWorkpackage,
                                                        "storeFormat",
                                                        $$v
                                                      )
                                                    },
                                                    expression:
                                                      "newWorkpackage.storeFormat",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ]),
                                      ]
                                    ),
                                  ]
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.availableCategories,
                                  function (category, i) {
                                    return _c(
                                      "td",
                                      {
                                        key: `category${i}`,
                                        staticClass:
                                          "col-border-right col-unit pr-0",
                                      },
                                      [
                                        _c("work-packages-unit", {
                                          attrs: {
                                            "unit-data": category,
                                            "is-expanded": true,
                                            "is-selectable": true,
                                            "prevent-unit-selection": true,
                                          },
                                          on: {
                                            onCategoriesUpdated:
                                              _vm.setCategories,
                                          },
                                        }),
                                      ],
                                      1
                                    )
                                  }
                                ),
                                _vm._v(" "),
                                _vm._l(
                                  _vm.maxUnitCount -
                                    _vm.availableCategories.length,
                                  function (n) {
                                    return _c("td", {
                                      key: `blankCell${n}`,
                                      staticClass: "col-border-right col-unit",
                                    })
                                  }
                                ),
                                _vm._v(" "),
                                _c("td", {
                                  staticClass:
                                    "col-border-left content-end pl-0 pr-0",
                                }),
                              ],
                              2
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.creationMode &&
                                      _vm.canEditWorkpackage,
                                    expression:
                                      "creationMode && canEditWorkpackage",
                                  },
                                ],
                                staticClass: "button-row",
                              },
                              [
                                _c("td"),
                                _vm._v(" "),
                                _c("td", {
                                  attrs: { colspan: _vm.maxUnitCount + 1 },
                                }),
                                _vm._v(" "),
                                _c(
                                  "td",
                                  {
                                    staticClass:
                                      "pt-2 pb-2 new-workpackage-buttons",
                                    attrs: { colspan: 2 },
                                  },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        attrs: {
                                          color: "primary",
                                          outlined: "",
                                          small: "",
                                          depressed: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.cancelNewWorkPackage()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("actions.cancel")) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                    _vm._v(" "),
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "save",
                                        attrs: {
                                          small: "",
                                          disabled: !_vm.isValid,
                                          loading: _vm.creating,
                                          color: "success",
                                          depressed: "",
                                        },
                                        on: {
                                          click: function ($event) {
                                            return _vm.saveNewWorkPackage()
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n                " +
                                            _vm._s(_vm.$t("actions.save")) +
                                            "\n              "
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]
                            ),
                            _vm._v(" "),
                            _c(
                              "tr",
                              {
                                directives: [
                                  {
                                    name: "show",
                                    rawName: "v-show",
                                    value:
                                      _vm.creationMode &&
                                      _vm.canEditWorkpackage,
                                    expression:
                                      "creationMode && canEditWorkpackage",
                                  },
                                ],
                                staticClass: "spacer-row",
                              },
                              [
                                _c("td"),
                                _vm._v(" "),
                                _c("td", { attrs: { colspan: "2" } }),
                                _vm._v(" "),
                                _c("td", {
                                  attrs: { colspan: _vm.maxUnitCount + 1 },
                                }),
                              ]
                            ),
                          ]
                        },
                      },
                    ],
                    null,
                    false,
                    785712330
                  ),
                })
              : _vm._e(),
          ],
          1
        ),
      ]),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "pt-2 pr-2" },
        [
          _vm.allowNew && !_vm.creationMode && _vm.canEditWorkpackage
            ? _c("plus-button", {
                attrs: {
                  copy: "workPackages.createNew",
                  "e2e-slug": "btnCreateWP",
                },
                on: { clickFunction: _vm.createNewWorkPackage },
              })
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }