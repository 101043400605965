<template>
  <v-date-picker
    v-model="value"
    no-title
    :min="minDate"
    :first-day-of-week="params.firstDayOfWeek"
    :locale="params.locale"
    :picker-date="pickerDate"
    show-current
    @input="params.api.stopEditing()"
  />
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      value: null,
    };
  },
  computed: {
    minDate() {
      return this.params.minDate(this.params);
    },
    pickerDate() {
      if (this.params.pickerDate) return this.params.pickerDate(this.params);
      return undefined;
    },
  },
  mounted() {
    this.value = this.params.value;
  },
  methods: {
    /* Component Editor Lifecycle methods */
    // the final value to send to the grid, on completion of editing
    getValue() {
      // this simple editor doubles any value entered into the input
      return this.params.dateValue(this.value);
    },

    // Gets called once before editing starts, to give editor a chance to
    // cancel the editing before it even starts.
    isCancelBeforeStart() {
      return false;
    },

    // Gets called once when editing is finished (eg if Enter is pressed).
    // If you return true, then the result of the edit will be ignored.
    isCancelAfterEnd() {
      // our editor will reject any value greater than 1000
      return this.value > 1000;
    },
  },
});
</script>

<style lang="scss" scoped></style>
