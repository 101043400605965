'use strict';

const { productKeyDisplayOption } = require('./filter-options');

const filterOptionFieldMapping = {
  [productKeyDisplayOption]: 'productKeyDisplay',
};

const errorMapping = {
  missingStoreGroup: '(missing!)',
};

module.exports = {
  filterOptionFieldMapping,
  errorMapping,
};
