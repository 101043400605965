<template>
  <div :class="[isCategoryLevel ? 'categoryLevel' : '']" style="display: contents">
    <td>
      <div class="d-flex justify-space-between">
        <div v-if="!isCategoryLevel" class="clickable category-heading" @click="$emit('toggle')">
          <i :class="[expanded ? 'mdi mdi-chevron-down' : 'mdi mdi-chevron-right']" />
          {{ $tc(`hierarchy.hierarchyLevels.${level + 1}`, 2) }}
        </div>
        <div v-if="isCategoryLevel" class="my-auto hierarchy-heading">
          {{ $tc(`hierarchy.hierarchyLevels.${level + 1}`, 2) }}
        </div>

        <div class="actions">
          <div
            v-if="isCategoryLevel"
            :custom-title-msg="$t('gridView.tooltips.pricingSpecialistRelease')"
            custom-title-position="top-right"
          >
            <v-checkbox
              :readonly="
                allCategoryPricingGroupsReleased ||
                  !pricingSpecialistAndManagerCategoryLevel ||
                  isSelectedWorkpackageMaster
              "
              :input-value="selectedOrReleased"
              class="ma-0 checkbox-all"
              dense
              :disabled="
                allCategoryPricingGroupsReleased ||
                  !pricingSpecialistAndManagerCategoryLevel ||
                  isSelectedWorkpackageMaster
              "
              color="success"
              @change="toggleCategory()"
            >
              <template v-slot:prepend>
                <span class="release-text">{{ releaseOrReleased }}</span>
              </template>
            </v-checkbox>
          </div>
          <hierarchy-level-item-approvals v-if="isCategoryLevel" :approvals="approvals" />
        </div>
      </div>
    </td>
    <td colspan="14">
      <span v-if="isCategoryLevel" class="release-text">{{ approveOrApproved }}</span>
    </td>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { get, every } from 'lodash';
import { categoryLevel } from '@enums/hierarchy';

export default {
  props: {
    level: Number,
    expanded: Boolean,
    levelEntryKey: {
      type: String,
      default: () => null,
    },
    name: {
      type: String,
      default: () => '',
    },
    parentId: {
      type: String,
      default: () => null,
    },
    workpackageId: {
      type: String,
      default: () => null,
    },
    approvalStatus: {
      // needs to be computed
      type: Boolean,
      default: () => {},
    },
  },

  data() {
    return {
      approvalOptions: [true, false],
    };
  },

  computed: {
    ...mapState('gridView', ['categoryToRelease', 'hierarchy']),
    ...mapState('clientConfig', ['userRoles']),
    ...mapGetters('context', ['isPricingSpecialist', 'isCategoryManager', 'isPricingManager']),
    ...mapGetters('hierarchy', ['getHierarchy']),
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),

    selectedOrReleased() {
      return (
        get(this.categoryToRelease, 'levelEntryKey') === this.levelEntryKey ||
        this.allCategoryPricingGroupsReleased
      );
    },

    allCategoryPricingGroupsReleased() {
      const pgs = this.getHierarchy(pg => pg.parentId === this.levelEntryKey);
      const allReleased = every(pgs, pg => get(pg, 'released.isReleased', null) === true);
      const allNotReleased = every(pgs, pg => get(pg, 'released.isReleased', null) === false);
      if (allReleased) return true;
      if (allNotReleased) return false;
      return null;
    },

    isCategoryLevel() {
      return this.level === categoryLevel;
    },

    psApproval() {
      const pgs = this.getHierarchy(pg => pg.parentId === this.levelEntryKey);
      // can't default to true or false here as there could be a situation where no choice has been made
      const allApproved = every(
        pgs,
        pg => get(pg, 'approvalStatus.pricingSpecialistApproval.approved', null) === true
      );
      const allNotApproved = every(
        pgs,
        pg => get(pg, 'approvalStatus.pricingSpecialistApproval.approved', null) === false
      );
      if (allApproved) return true;
      if (allNotApproved) return false;
      return null;
    },

    cmApproval() {
      const pgs = this.getHierarchy(pg => pg.parentId === this.levelEntryKey);
      // can't default to true or false here as there could be a situation where no choice has been made
      const allApproved = every(
        pgs,
        pg => get(pg, 'approvalStatus.categoryManagerApproval.approved', null) === true
      );
      const allNotApproved = every(
        pgs,
        pg => get(pg, 'approvalStatus.categoryManagerApproval.approved', null) === false
      );
      if (allApproved) return true;
      if (allNotApproved) return false;
      return null;
    },

    releaseOrReleased() {
      return this.allCategoryPricingGroupsReleased
        ? this.$t('actions.released')
        : this.$t('actions.release');
    },

    approveOrApproved() {
      return this.psApproval ? this.$t('actions.approved') : this.$t('actions.approve');
    },

    pricingSpecialistAndManagerCategoryLevel() {
      return (this.isPricingSpecialist || this.isPricingManager) && this.isCategoryLevel;
    },

    categoryManagerCategoryLevel() {
      return this.isCategoryManager && this.isCategoryLevel;
    },

    approvals() {
      return [
        {
          id: this.userRoles.categoryManager,
          tooltipMessage: this.$t('gridView.tooltips.categoryManagerApproval'),
          value: this.cmApproval,
          disabled: !this.allCategoryPricingGroupsReleased || !this.categoryManagerCategoryLevel,
          change: this.categoryManagerCategoryApproval,
        },
        {
          id: this.userRoles.pricingSpecialist,
          tooltipMessage: this.$t('gridView.tooltips.pricingSpecialistApproval'),
          value: this.psApproval,
          disabled: !this.pricingSpecialistAndManagerCategoryLevel,
          change: this.pricingSpecialistCategoryApproval,
        },
      ];
    },
  },

  methods: {
    toggleCategory() {
      this.$emit('categoryReleased');
    },
    pricingSpecialistCategoryApproval({ approved }) {
      this.$emit('pricingSpecialistCategoryApproval', {
        approved,
      });
    },
    categoryManagerCategoryApproval({ approved }) {
      this.$emit('categoryManagerCategoryApproval', {
        approved,
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/components/_hierarchy-item-shared.scss';

.actions {
  @include hierarchy-item-actions-panel();
}

.checkbox-all {
  padding-top: $hierarchy-item-checkbox-padding-top;
}

tr.hierarchy__row > td {
  font-size: 1.4rem;
  word-spacing: 0.7rem;
  padding: 0.4rem 0.7rem;
  &.icons {
    height: 3.5rem;
    min-width: 25rem;
  }
  & > i {
    cursor: pointer;
  }
}
.hierarchy__row {
  td {
    border-bottom: 0.1rem solid $pricing-grey-dark;
  }
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.checkbox-row {
  .v-messages {
    display: none;
  }

  .v-input__slot {
    margin-bottom: unset !important;
  }

  .release-text {
    font-size: 1.25rem;
    padding-top: 0.5rem;
  }

  .alert-cell {
    background-color: $pricing-background !important;
    border-bottom: none !important;
    min-width: $grid-view-alert-column-width !important;
    border-top: none !important;
  }

  .category-heading {
    font-size: 1.4rem;
  }
  .hierarchy-heading {
    font-size: 1.25rem;
  }
}
</style>
