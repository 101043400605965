import { get } from 'lodash';
import FilterOps from '@enums/filter-operations';

// Where a = data value and b = filter value.
const operatorFunctionMap = {
  [FilterOps.contains]: (a, b) => a.toLowerCase().includes(b.toLowerCase()),
  [FilterOps.equals]: (a, b) => a === b,
  [FilterOps.notEqual]: (a, b) => a !== b,
  [FilterOps.greaterThan]: (a, b) => a > b,
  [FilterOps.lessThan]: (a, b) => a < b,
  [FilterOps.greaterThanOrEqual]: (a, b) => a >= b,
  [FilterOps.lessThanOrEqual]: (a, b) => a <= b,
  [FilterOps.isOneOf]: (a, b) => b.includes(a),
};

/**
 * Util to allow the filtering of data based on an array of filters.
 * Due to the structure of some of our data, the value to be compared is not always at the root level
 * of the object being filtered. To combat this the filter object itself will contain the path to the
 * value to be used.
 * e.g items array:
 * [
 *  {
 *    name: 'MockName',
 *    details: {
 *      address: 'MockAddress',
 *      age: 17,
 *    }
 *  }
 * ]
 * e.g. filters array:
 * [
 *  {
 *    datatype: 'str',
 *    op: 'equals',
 *    value: 'MockName',
 *    path: 'name',
 *  },
 *  {
 *    datatype: 'number',
 *    op: 'lessThan',
 *    value: 18,
 *    path: 'details.age',
 *  },
 * ]
 * The function will iterate over each item and apply each filter to the data.
 * The data from the item is retrieved by using a combination of lodash _.get and the path provided in the filter.
 * @param {Object[]} items - array of objects containing data to be filtered.
 * @param {Object[]} filters - array of filter objects
 */
export default function filterData(items, filters, alertFilter) {
  if (alertFilter) {
    items = items.filter(item => get(item, ['alertCounts', alertFilter], null));
  }
  if (!filters || !filters.length) return items;
  return items.filter(item =>
    filters.every(filter =>
      operatorFunctionMap[filter.op](get(item, filter.path, ''), filter.value)
    )
  );
}
