<template>
  <v-layout>
    <v-flex>
      <v-expansion-panels :value="value">
        <v-expansion-panel
          :disabled="disabled"
          class="borderless-panel"
          @change="panelOpenedOrClosed"
        >
          <v-expansion-panel-header>
            <span>{{ $t('settings.setArchitectureRules.title') }}</span>
            <template v-slot:actions>
              <v-icon>expand_more</v-icon>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content class="expansion-body px-0 py-0">
            <v-row>
              <v-col md="6" class="py-0 pr-0">
                <architecture-drivers-ruleset :disabled="isDisabled" />
              </v-col>
              <v-col md="6">
                <architecture-drivers-overview :disabled="isDisabled" />
              </v-col>
            </v-row>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-flex>
  </v-layout>
</template>

<script>
import { mapActions } from 'vuex';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
    editable: {
      type: Boolean,
      default: true,
    },
  },

  data() {
    return {
      value: null,
    };
  },

  computed: {
    isDisabled() {
      return this.disabled || !this.editable;
    },
  },

  watch: {
    disabled(isDisabled) {
      if (isDisabled) this.value = null;
    },
  },

  async created() {
    const newTabOpenArchitectureRules = await this.consumeNewTabOpenArchitectureRules();
    if (newTabOpenArchitectureRules) {
      this.value = 0; // open the panel
      this.globalEmit('architecture-rules-panel-opened');
    }
  },

  methods: {
    ...mapActions('architectureGroup', ['consumeNewTabOpenArchitectureRules']),
    panelOpenedOrClosed() {
      // the API of the expansion panels isn't great
      this.value = this.value === 0 ? null : 0;
      // if we've just opened it, emit an event
      if (this.value === 0) this.globalEmit('architecture-rules-panel-opened');
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.borderless-panel {
  box-shadow: none;

  .expansion-body {
    padding: 1.2rem;
    background-color: $pricing-background;
  }
}
</style>
