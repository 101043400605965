<template>
  <div>
    <attribute-filter-panel
      :filter-rules="selectedAttributeFilters"
      enable-wholesale-hierarchy-filters
      title-localisation="attributes.filters.filterByAttributes"
      filter-count-localisation="attributes.filters.numApplied"
      @attributeFilterChange="setWholesaleFilters"
    />

    <v-tabs
      height="4rem"
      background-color="transparent"
      color="white"
      class="pricing-tabs"
      active-class="pricing-tabs__tab--active"
      hide-slider
    >
      <v-tab class="pricing-tabs__tab pricing-tabs__tab--first">
        {{ $t('wholesale.tabs.marginSplits') }}
      </v-tab>

      <v-tab class="pricing-tabs__tab">
        {{ $t('wholesale.tabs.wholesaleMargins') }}
      </v-tab>
      <v-tab class="pricing-tabs__tab">
        {{ $t('wholesale.tabs.wholesaleHistoryChart') }}
      </v-tab>
      <div v-if="canRunWholesaleEngine" class="d-flex align-center">
        <v-btn
          color="primary"
          small
          depressed
          class="ml-4 btn-run-simulation"
          :disabled="wholesaleEngineLoading"
          :loading="wholesaleEngineLoading"
          @click="runWholesaleEngine"
        >
          {{ $t('wholesale.runSimulation') }}
          <v-icon v-once>mdi-refresh</v-icon>
        </v-btn>
      </div>

      <v-tab-item>
        <margin-splits />
      </v-tab-item>
      <v-tab-item>
        <wholesale-margins />
      </v-tab-item>
      <v-tab-item>
        <wholesale-history-chart />
      </v-tab-item>
    </v-tabs>
  </div>
</template>

<script>
import { debounce } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import to from 'await-to-js';
import clientConfig from '@sharedModules/config/client';

export default {
  computed: {
    ...mapGetters('context', ['isWholesaleAnalyst', 'isWholesaleManager']),
    ...mapState('wholesale', ['wholesaleEngineLoading']),
    ...mapState('wholesaleProductResults', ['selectedAttributeFilters']),

    canRunWholesaleEngine() {
      return this.isWholesaleAnalyst || this.isWholesaleManager;
    },
  },

  async created() {
    await this.checkSelectedWorkpackageWholesaleEngineStatus();
  },

  methods: {
    ...mapActions('wholesale', [
      'runWholesaleEngine',
      'checkSelectedWorkpackageWholesaleEngineStatus',
    ]),
    ...mapActions('wholesaleProductResults', [
      'setAttributeFilters',
      'fetchAggregations',
      'fetchMonthlyAggregations',
    ]),

    setWholesaleFilters(attributeFilters) {
      this.setAttributeFilters({ attributeFilters });
      this.debounceUpdateWholesaleReview();
    },

    debounceUpdateWholesaleReview: debounce(async function() {
      // don't remove "to", it's required to prevent printing of "unhandled promise rejection" errors
      await to(Promise.all([this.fetchAggregations(), this.fetchMonthlyAggregations()]));
    }, clientConfig.inputDebounceValue),
  },
};
</script>

<style lang="scss" scoped>
.pricing-tabs {
  .btn-run-simulation.v-btn {
    height: 2.4rem;
    padding: unset;
  }
}
</style>
