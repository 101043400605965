<template>
  <grid-view
    :headers="headersWithUniqueValues"
    :items="filteredItems"
    :level-entry-key="levelEntryKey"
    :is-unit-manager-view="isUnitManagerView"
    :architecture-group-description-to-color-map="architectureGroupDescriptionToColorMap"
    :architecture-sub-group-description-to-color-map="architectureSubGroupDescriptionToColorMap"
    @downloadData="downloadDataHandler()"
    @toggleFilteringByArchitectureGroup="onToggleFilteringByArchitectureGroup"
  />
</template>

<script>
import { flatMap, get, keys, omit, pick, isEmpty, map, uniqBy, groupBy } from 'lodash';
import { mapActions, mapGetters, mapState } from 'vuex';
import filterData from '../../../utils/filter-data-util';
import DataTypes from '@enums/data-types';
import priceTypes from '@enums/price-types';
import { useZones } from '@enums/feature-flags';
import timeFlexibleEndingsMap from '@enums/time-flexible-endings';
import { architectureGroupLevel } from '@enums/hierarchy';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { mapGridView } from '@sharedModules/data/utils/time-flexible-utils';
import { getMultiLevelSortProductGroupsFunc } from '../../../utils/sort-architecture-groups-util';
import numberFormats from '@enums/number-formats';

export default {
  mixins: [featureFlagsMixin],
  props: {
    levelEntryKey: String,
    isUnitManagerView: Boolean,
  },
  data() {
    return {
      articleHeaders: [
        {
          text: '',
          align: 'left',
          sortable: false,
          valuePath: 'architectureGroupDescription',
          class: 'architecture-group-cell-header',
        },
        {
          text: '',
          align: 'left',
          sortable: false,
          valuePath: 'subGroupDescription',
          class: 'architecture-group-cell-header',
        },
        {
          text: 'pricing.productKey',
          align: 'left',
          sortable: true,
          // Label states this is the product key but since we are displaying it
          // we actually use the display key here.
          valuePath: 'productKeyDisplay',
          datatype: DataTypes.str,
          class: 'fixed-width-number',
        },
        {
          text: 'pricing.toolStoreGroup',
          align: 'left',
          sortable: true,
          valuePath: 'toolStoreGroupDescription',
          datatype: DataTypes.str,
          featureFlag: useZones,
          style: 'width:14rem;',
        },
        {
          text: 'pricing.productDescription',
          align: 'left',
          sortable: true,
          valuePath: 'productDescription',
          datatype: DataTypes.str,
          style: 'min-width: 20rem;',
        },
        {
          text: 'pricing.productSizeType',
          align: 'left',
          sortable: true,
          valuePath: 'productSizeType',
          class: 'border-right fixed-width-number',
          datatype: DataTypes.str,
          style: 'min-width: 7rem;width: 7rem;',
        },
      ],
      salesHeaders: [
        {
          text: 'pricing.sales',
          align: 'left',
          sortable: true,
          valuePath: 'yearlySales',
          class: 'fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.volume',
          align: 'left',
          sortable: true,
          valuePath: 'yearlyVolume',
          class: 'fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.promo',
          align: 'left',
          sortable: true,
          valuePath: 'mandatoryEngineInputs.promoParticipationSales',
          class: 'border-right fixed-width-number',
          datatype: DataTypes.number,
        },
      ],
      staticSenarioHeaders: [
        {
          text: 'pricing.scenarioMargin',
          align: 'left',
          sortable: true,
          valuePath: 'scenarioMargin',
          datatype: DataTypes.number,
          class: 'scenario-colour fixed-width-number',
        },
        {
          text: 'pricing.scenarioSalesImpact',
          align: 'left',
          sortable: true,
          valuePath: 'scenarioSalesImpact',
          class: 'scenario-colour fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.scenarioCostImpact',
          align: 'left',
          sortable: true,
          valuePath: 'scenarioCostImpact',
          class: 'border-right scenario-colour fixed-width-number',
          datatype: DataTypes.number,
        },
      ],
    };
  },
  computed: {
    ...mapGetters('gridView', ['items', 'selectedHistoricalPeriod']),
    ...mapState('gridView', [
      'selectedCompetitors',
      'activeTableFilters',
      'alertFilter',
      'selectedHistoricalPeriod',
      'showRegularImpact',
    ]),
    ...mapState('filters', ['selectedPriceType']),
    ...mapState('clientConfig', ['exportConfigs', 'alertTypes']),

    filteredItems() {
      const mapGridViewBySelectedHistoricalPeriod = mapGridView(this.selectedHistoricalPeriod);
      const pathToArchitectureGroupDescription = [
        'hierarchy',
        architectureGroupLevel,
        'levelEntryDescription',
      ];
      const timeFlexibleItems = this.items.map(item => {
        return {
          ...mapGridViewBySelectedHistoricalPeriod(item),
          // makes it easier to access the field
          architectureGroupDescription: get(item, pathToArchitectureGroupDescription),
          // all historical period volume values required for price recalculation
          ...keys(timeFlexibleEndingsMap).reduce((acc, ending) => {
            acc[`volume${ending}`] = get(item, `volume${ending}`, 0);
            return acc;
          }, {}),
        };
      });
      return filterData(timeFlexibleItems, this.activeTableFilters, this.alertFilter);
    },

    architectureGroupDescriptionToColorMap() {
      if (this.isUnitManagerView || isEmpty(this.filteredItems)) {
        return {};
      }
      const architectureGroupDescriptionToPriorityMap = {};
      this.filteredItems.forEach(item => {
        if (architectureGroupDescriptionToPriorityMap[item.architectureGroupDescription]) {
          return;
        }
        architectureGroupDescriptionToPriorityMap[item.architectureGroupDescription] =
          item.architectureGroupPriority;
      });
      const architectureGroups = map(architectureGroupDescriptionToPriorityMap, (value, key) => ({
        architectureGroupDescription: key,
        architectureGroupPriority: value,
      }));
      const sortFunc = getMultiLevelSortProductGroupsFunc('architectureGroup');
      const sortedArchitectureGroups = architectureGroups.sort(sortFunc);
      return sortedArchitectureGroups.reduce((result, architectureGroup, index) => {
        // possible color numbers: 1, 2
        result[architectureGroup.architectureGroupDescription] = (index % 2) + 1;
        return result;
      }, {});
    },

    architectureSubGroupDescriptionToColorMap() {
      if (this.isUnitManagerView || isEmpty(this.filteredItems)) {
        return {};
      }
      const itemsGroupedByAG = groupBy(this.filteredItems, 'architectureGroupDescription');
      const sortFunc = getMultiLevelSortProductGroupsFunc('architectureSubGroup');
      return keys(itemsGroupedByAG).reduce((result, key) => {
        // possible color numbers: 1, 2
        result[key] = uniqBy(itemsGroupedByAG[key].sort(sortFunc), 'subGroupDescription').reduce(
          (res, item, index) => {
            res[item.subGroupDescription] = (index % 2) + 1;
            return res;
          },
          {}
        );
        return result;
      }, {});
    },

    filteredArticleHeaders() {
      return this.filterArrayByFeatureFlag(this.articleHeaders);
    },

    headers() {
      // only show selected competitors in table
      const competitorHeaders = flatMap(this.selectedCompetitors, (c, i, arr) => [
        {
          text: c.competitorDisplayDescription,
          align: 'left',
          sortable: true,
          key: `competitor${i + 1}.${this.getPriceValuePath('competitorPrice')}`,
          valuePath: `competitor${i + 1}.${this.getPriceValuePath('competitorPrice')}`,
          // index of competitor header: used to keep header text up-to-date with selected competitors
          competitorHeader: i,
          class: 'fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.competitorIndex',
          align: 'left',
          sortable: true,
          key: `competitor${i + 1}.competitorIndex`,
          valuePath: `competitor${i + 1}.competitorIndex`,
          class: [i === arr.length - 1 ? 'border-right' : '', 'fixed-width-number'],
          datatype: DataTypes.number,
        },
      ]);

      const costHeaders = [
        {
          text: 'pricing.goLiveCost',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('nonPromoNetCost')}`,
          class: 'medium-price-field',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.costDelta',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('costDelta')}`,
          class: 'border-right medium-price-field',
          datatype: DataTypes.number,
        },
      ];
      const tensionLabel = this.isFeatureFlagEnabled(useZones)
        ? 'pricing.tensionUseZones'
        : 'pricing.tension';
      const tensionHeader = {
        text: tensionLabel,
        align: 'left',
        sortable: false,
        valuePath: 'scenario.tension',
        class: 'fixed-width-number',
      };

      const alertsHeader = {
        text: '',
        sortable: false,
        class: 'alert-padding',
      };

      const priceHeaders = [
        {
          text: 'pricing.weekPriceChange',
          align: 'left',
          sortable: false,
          valuePath: 'priceHistoryWeek',
          class: 'fixed-width-number',
          style: 'width: 6rem',
        },
        {
          text: 'pricing.livePrice',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('livePrice')}`,
          class: 'fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.intentionPrice',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('intentionPrice.price')}`,
          class: 'fixed-width-number',
          datatype: DataTypes.number,
        },
        {
          text: 'pricing.intentionMargin',
          align: 'left',
          sortable: true,
          valuePath: 'intentionMargin',
          class: 'border-right fixed-width-number',
          datatype: DataTypes.number,
        },
      ];
      const scenarioPriceHeaders = [
        {
          text: 'pricing.scenarioPrice',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('scenarioPrice')}`,
          datatype: DataTypes.number,
          class: 'scenario-colour border-left-primary wide-price-field',
        },
        {
          text: 'pricing.scenarioPriceChange',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('scenarioPriceChange')}`,
          datatype: DataTypes.number,
          class: 'scenario-colour fixed-width-number',
        },
        {
          text: 'pricing.scenarioPriceChangeRatio',
          align: 'left',
          sortable: true,
          valuePath: `${this.getPriceValuePath('scenarioPriceChangeRatio')}`,
          datatype: DataTypes.number,
          class: 'scenario-colour fixed-width-number',
        },
      ];
      return [
        ...this.filteredArticleHeaders,
        ...this.salesHeaders,
        ...costHeaders,
        ...priceHeaders,
        ...scenarioPriceHeaders,
        ...this.staticSenarioHeaders,
        ...competitorHeaders,
        tensionHeader,
        alertsHeader,
      ];
    },
    headersWithUniqueValues() {
      // Get an array of the unique values for each header so that they can
      // later be used in filters.
      return this.headers.map(header => ({
        ...header,
        uniqueValueOptions: Array.from(
          this.filteredItems.reduce(
            (valueSet, currentRow) => valueSet.add(get(currentRow, header.valuePath, '')),
            new Set()
          )
        ).sort(),
      }));
    },
  },

  methods: {
    ...mapActions('gridView', ['downloadItems']),

    downloadDataHandler() {
      const alertTranslationMap = Object.values(this.alertTypes).reduce((acc, alertType) => {
        return {
          ...acc,
          [alertType]: this.$t(`alerts.${alertType}`),
        };
      }, {});

      const timeFlexibleAlerts = keys(timeFlexibleEndingsMap).reduce((acc, ending) => {
        const alertText = this.$tc(`alerts.missingHistoricalData`);
        acc[`missingHistoricalData${ending}`] = alertText;
        return acc;
      }, {});

      const toolStoreGroupTranslation = this.isFeatureFlagEnabled(useZones)
        ? {
            toolStoreGroupDescription: this.$t('pricing.toolStoreGroup'),
          }
        : {};

      const storeGroupReferencePriceTranslation = this.isFeatureFlagEnabled(useZones)
        ? {
            storeGroupReferencePrice: this.$t('pricing.storeGroupReferencePrice'),
          }
        : {};

      const translationMap = {
        productKeyDisplay: this.$t('pricing.productKey'),
        ...toolStoreGroupTranslation,
        productDescription: this.$t('pricing.productDescription'),
        productSizeType: this.$t('pricing.productSizeType'),
        // yearlySales and yearlyVolume will be overwritten by seleted time-flexible field
        yearlySales: this.$t('pricing.exportSales'),
        yearlyVolume: this.$t('pricing.exportVolume'),
        promo: this.$t('pricing.promo'),
        nonPromoNetCost: this.$t('pricing.goLiveCost'),
        costDelta: this.$t('pricing.costDelta'),
        livePrice: `${this.$t('pricing.livePrice')} ${this.$t('pricing.mainHeaders.prices')}`,
        'intentionPrice.price': `${this.$t('pricing.intentionPrice')} ${this.$t(
          'pricing.mainHeaders.prices'
        )}`,
        intentionMargin: this.$t('pricing.exportIntentionMargin'),
        scenarioPrice: this.$t('pricing.exportScenarioPrice'),
        scenarioPriceChange: this.$t('pricing.exportScenarioPriceChange'),
        scenarioPriceChangeRatio: this.$t('pricing.exportScenarioPriceChangeRatio'),
        scenarioMargin: this.$t('pricing.exportScenarioMargin'),
        scenarioSalesImpact: this.$t('pricing.exportTotalSalesImpact'),
        regularSalesImpact: this.$t('pricing.exportRegularSalesImpact'),
        scenarioCostImpact: this.$t('pricing.exportTotalCostImpact'),
        regularCostImpact: this.$t('pricing.exportRegularCostImpact'),
        economicReferencePrice: this.$t('pricing.economicReferencePrice'),
        architectureReferencePrice: this.$t('pricing.architectureReferencePrice'),
        competitorReferencePrice: this.$t('pricing.competitorReferencePrice'),
        ...storeGroupReferencePriceTranslation,
        normWeight: this.$t('pricing.normWeight'),
        scenarioPricePerNormWeight: this.$t('pricing.scenarioPricePerNormWeight'),
        scenarioPricePerContentUnit: this.$t('pricing.scenarioPricePerContentUnit'),
        ...omit(alertTranslationMap, 'missingHistoricalData'),
        ...pick(timeFlexibleAlerts, [`missingHistoricalData${this.selectedHistoricalPeriod}`]),
      };

      this.downloadItems({
        pricingGroupId: this.pricingGroupId,
        translationMap,
        isUnitManagerView: this.isUnitManagerView,
        columnFormatters: {
          [this.$t('pricing.productKey')]: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
      });
    },
    getPriceValuePath(priceName) {
      if (this.selectedPriceType === priceTypes.uom) return `${priceName}PerContentUnit`;
      if (this.selectedPriceType === priceTypes.normWeight) return `${priceName}PerNormWeight`;
      return priceName;
    },
    onToggleFilteringByArchitectureGroup(eventData) {
      this.$emit('toggleFilteringByArchitectureGroup', eventData);
    },
  },
};
</script>

<style lang="scss">
.wide-price-field {
  width: 10rem;
}
.medium-price-field {
  width: 6rem;
}
</style>
