import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render(_c, _vm) {
  return _c(
    "span",
    _vm._g(
      _vm._b(
        {
          ref: _vm.data.ref,
          class: [
            "v-icon",
            { "v-icon--small": _vm.props.small },
            { "v-icon--large": _vm.props.large },
            { "v-icon--right": _vm.props.right },
            { "v-icon--left": _vm.props.left },
            { "v-icon--disabled": _vm.props.disabled },
            _vm.props.iconColourType
              ? "v-icon--" + _vm.props.iconColourType
              : "",
            _vm.props.color ? _vm.props.color + "--text" : "",
            _vm.data.class,
            _vm.data.staticClass,
          ],
          style: [_vm.data.style, _vm.data.staticStyle],
        },
        "span",
        _vm.data.attrs,
        false
      ),
      _vm.listeners
    ),
    [
      _vm.$options.methods.getComponent(_vm.props.iconName)
        ? _c(_vm.props.iconName, { tag: "component" })
        : _c(
            VIcon,
            {
              class: [
                { "v-icon--small": _vm.props.small },
                { "v-icon--large": _vm.props.large },
                _vm.props.materialIconClass,
                "notranslate",
                "material-icons",
                "theme--light",
              ],
            },
            [_vm._v("\n    " + _vm._s(_vm.props.iconName) + "\n  ")]
          ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }