import axios from 'axios';
import to from 'await-to-js';
import { size, isEmpty } from 'lodash';
import { formatFilters } from '../utils/filters';
import downloadXlsxFile from '../utils/download-xlsx-file';

const getInitialState = () => {
  return {
    loading: false,
    workpackageProducts: [],
    workpackageProductsCount: 0,
    counts: {},
    uploadError: '',
    uploadData: {},
    busyImportingEngineInput: false,
    downloadingItems: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    setLoading(state, isLoading) {
      state.loading = isLoading;
    },

    setWorkpackageProducts(state, workpackageProducts) {
      state.workpackageProducts = workpackageProducts;
    },

    setWorkpackageProductsCount(state, workpackageProductsCount) {
      state.workpackageProductsCount = workpackageProductsCount;
    },

    setAffectedCounts(state, counts) {
      state.counts = counts;
    },

    setUploadData(state, uploadData) {
      state.uploadData = uploadData;
    },

    setErrorState(state, error) {
      state.uploadError = error;
    },

    setBusyImportingEngineInput(state, importing) {
      state.busyImportingEngineInput = importing;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setDownloading(state, downloadingItems) {
      state.downloadingItems = downloadingItems;
    },
  },

  actions: {
    async fetchWorkpackageProducts({ rootState, commit }, { params = {} } = {}) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, { data: workpackageProducts }] = await to(
        axios.get(`/api/workpackage-product/workpackage/${workpackageId}`, { params })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      commit('setWorkpackageProducts', workpackageProducts);
      commit('setLoading', false);
      return workpackageProducts;
    },

    async downloadCompetitorMatches(
      { rootState, commit },
      {
        translationMap = {},
        where = [],
        pick = [],
        enabledValueTranslations = {},
        columnFormatters = {},
      }
    ) {
      commit('setDownloading', true);

      const params = {
        export: true,
        formatForExport: true,
        translationMap,
        enabledValueTranslations,
        where: formatFilters({ where, rootState }),
        downloadingCompetitors: true,
        pick,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/inputs`, { params })
      );
      commit('setDownloading', false);
      if (err) throw new Error(err.message);
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'competitor_matches.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
    },

    async updateHierarchy({ rootState }, { updates }) {
      if (!updates.length) return; // user is only updating attributes
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      if (isEmpty(updates)) return;

      const [err] = await to(
        axios.patch(`/api/workpackage-product/workpackage/${workpackageId}/hierarchy`, { updates })
      );
      if (err) throw new Error(err.message);
    },

    async updateMandatoryEngineInputs({ rootState, dispatch, commit }, { updates, params }) {
      if (!size(updates)) return;
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, data] = await to(
        axios.patch(`/api/workpackage-product/workpackage/${workpackageId}`, updates)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      } else {
        await dispatch(
          'attributes/fetchAttributesAggregated',
          {
            params,
          },
          { root: true }
        );
        commit('setLoading', false);
        return data;
      }
    },

    async updateCompetitorPrices({ rootState, dispatch, commit }, { updates, params }) {
      if (!size(updates)) return;
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, data] = await to(
        axios.patch(`/api/workpackage-product/competitors/workpackage/${workpackageId}`, updates)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      } else {
        await dispatch(
          'attributes/fetchAttributesAggregated',
          {
            params,
          },
          { root: true }
        );
        commit('setLoading', false);
        return data;
      }
    },

    async postEngineInputsUpload({ commit }) {
      commit('setBusyImportingEngineInput', false);
    },

    async importEngineInputsConfirm({ rootState, commit }) {
      commit('setBusyImportingEngineInput', true);

      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(
          `/api/workpackage-product/workpackage/${workpackageId}/import-engine-inputs/confirm`
        )
      );
      if (err) throw new Error(err.message);
    },

    async importEngineInputs(
      { rootState, commit, dispatch },
      { dataFile, params, translationMap = {} }
    ) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const patchParams = {
        translationMap,
      };
      dispatch('filters/setInputScreensFetchParams', params, { root: true });
      const [err, res] = await to(
        axios.patch(
          `/api/workpackage-product/workpackage/${workpackageId}/import-engine-inputs/xlsx`,
          dataFile,
          {
            params: patchParams,
            headers: {
              // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
              // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
              'Content-Type': undefined,
            },
          }
        )
      );
      if (err) throw err.response.data;

      if (res.data.error) {
        commit('setErrorState', res.data.error);
      } else {
        commit('setAffectedCounts', res.data.counts);
        commit('setUploadData', res.data.mandatoryEngineUpdates);
      }
    },
    resetState({ commit }) {
      commit('resetState');
    },
  },
};

export default store;
