var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "i",
    _vm._g(
      {
        staticClass: "mdi",
        class: [
          _vm.isExpanded
            ? _vm.params.expandedStateIcon || "mdi-chevron-down"
            : _vm.params.collapsedStateIcon || "mdi-chevron-right",
          ..._vm.params.classes,
        ],
      },
      _vm.isLink ? { click: _vm.onClick } : {}
    )
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }