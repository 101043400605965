import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VCard,
    { key: _vm.productKey, attrs: { flat: "" } },
    [
      _vm.expandedProduct
        ? _c(
            VLayout,
            { attrs: { wrap: "", "border-bottom": "" } },
            [
              _c(
                VFlex,
                { attrs: { xs9: "", "border-bottom": "" } },
                [
                  _c(
                    VLayout,
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs12: "" } },
                        [
                          _c("price-history-chart", {
                            attrs: {
                              "price-history": _vm.addChartCoordinates(
                                _vm.expandedProduct.priceHistoryWeek
                              ),
                              "scenario-price": _vm.expandedProduct
                                .scenarioResults
                                ? _vm.expandedProduct.scenarioResults
                                    .scenarioPrice
                                : {},
                              "scenario-cost": _vm.expandedProduct.scenarioCost,
                              "cost-history": _vm.addChartCoordinates(
                                _vm.expandedProduct.costHistoryWeek
                              ),
                              competitors: _vm.expandedProduct.competitor,
                              "competitor-names-map":
                                _vm.competitorDescriptionToDisplayDescriptionMap,
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VLayout,
                    { attrs: { "border-top": "" } },
                    [
                      _c(
                        VFlex,
                        { attrs: { xs6: "" } },
                        [
                          _c("penalty-functions", {
                            attrs: {
                              "scenario-results":
                                _vm.expandedProduct.scenarioResults,
                              "product-key": _vm.productKey,
                              "scenario-key": _vm.scenarioKey,
                              "tool-store-group-key": _vm.toolStoreGroupKey,
                              "competitor-names-map":
                                _vm.competitorDescriptionToDisplayDescriptionMap,
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        { attrs: { xs6: "", "border-left": "", "pa-2": "" } },
                        [
                          _c("h3", [
                            _vm._v(_vm._s(_vm.$t("gridView.settings"))),
                          ]),
                          _vm._v(" "),
                          _c("p", { staticClass: "my-0" }, [
                            _vm._v(
                              _vm._s(_vm.$t("gridView.linksToSettings")) + ":"
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            VContainer,
                            { attrs: { "pb-0": "", "pt-2": "" } },
                            [
                              _c(
                                VRow,
                                { staticClass: "goto-row-separator" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$tc(
                                          `hierarchy.hierarchyLevels.${_vm.architectureGroupLevel}`,
                                          1
                                        )
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "py-1 px-0 d-flex align-center",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.architectureGroup
                                              .levelEntryDescription
                                          ) +
                                          "\n                "
                                      ),
                                      _c(VCheckbox, {
                                        staticClass: "checkbox ml-2 pa-0",
                                        attrs: {
                                          "prepend-icon": "filter_list",
                                          "hide-details": "",
                                          dense: "",
                                          disabled: _vm.loading,
                                        },
                                        on: {
                                          click: function ($event) {
                                            $event.preventDefault()
                                            $event.stopPropagation()
                                            return _vm.onToggleFilteringByArchitectureGroup.apply(
                                              null,
                                              arguments
                                            )
                                          },
                                        },
                                        model: {
                                          value:
                                            _vm.isFilteringByArchitectureGroupEnabled,
                                          callback: function ($$v) {
                                            _vm.isFilteringByArchitectureGroupEnabled =
                                              $$v
                                          },
                                          expression:
                                            "isFilteringByArchitectureGroupEnabled",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-1 px-0 text-right",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ok-btn",
                                          attrs: { small: "" },
                                          on: {
                                            click: _vm.goToArchitectureGroup,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "gridView.goToButtonText"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                { staticClass: "goto-row-separator" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`gridView.architectureDrivers`)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-1 px-0",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(/* Finding list relevant ADs is complicated. Compromise for now is to show AG name instead. */) +
                                          "\n                " +
                                          _vm._s(
                                            _vm.architectureGroup
                                              .levelEntryDescription
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-1 px-0 text-right",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ok-btn",
                                          attrs: { small: "" },
                                          on: {
                                            click: _vm.goToArchitectureDrivers,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "gridView.goToButtonText"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              !_vm.isUnitManagerView
                                ? _c(
                                    "feature-toggle",
                                    {
                                      attrs: {
                                        toggle:
                                          _vm.displayArchitectureSubGroupSplittingAttributes,
                                      },
                                    },
                                    [
                                      _c(
                                        VRow,
                                        { staticClass: "goto-row-separator" },
                                        [
                                          _c("h4", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  `gridView.architectureSubGroupSplittingAttributes`
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VRow,
                                        [
                                          _c(
                                            VCol,
                                            {
                                              staticClass: "py-1 px-0",
                                              attrs: { cols: "8" },
                                            },
                                            [
                                              _vm._v(
                                                "\n                  " +
                                                  _vm._s(
                                                    _vm.formattedArchitectureSubGroupDescription
                                                  ) +
                                                  "\n                "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            VCol,
                                            {
                                              staticClass:
                                                "py-1 px-0 text-right",
                                              attrs: { cols: "4" },
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  staticClass: "ok-btn",
                                                  attrs: { small: "" },
                                                  on: {
                                                    click:
                                                      _vm.goToArchitectureSubGroupSplittingAttributes,
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "gridView.viewButtonText"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                VRow,
                                { staticClass: "goto-row-separator" },
                                [
                                  _c("h4", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(`gridView.linePricingGroup`)
                                      )
                                    ),
                                  ]),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-1 px-0",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.linePricingGroup
                                              .linePricedGroupDescription
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-1 px-0 text-right",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "ok-btn",
                                          attrs: { small: "" },
                                          on: {
                                            click: _vm.goToLinePricingGroups,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  " +
                                              _vm._s(
                                                _vm.$t(
                                                  "gridView.goToButtonText"
                                                )
                                              ) +
                                              "\n                "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VFlex,
                { attrs: { xs3: "", "border-left": "", "border-bottom": "" } },
                [
                  _c("product-attributes", {
                    attrs: { item: _vm.expandedProduct },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }