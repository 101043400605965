import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-screen-page-wrapper",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("inputs-grid", {
        ref: "inputsGrid",
        attrs: {
          "additional-columns": _vm.additionalColumns,
          "additional-grid-options": _vm.additionalGridOptions,
          "save-changes": _vm.saveChanges,
          "export-action": _vm.downloadAttributes,
          "is-exporting": _vm.downloadingItems,
          "editable-hierarchies": true,
          "route-suffix": "attributes",
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _vm.canEditAttributes
                  ? _c(
                      VMenu,
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass:
                                          "inputs-actions__add grid-btn",
                                        attrs: {
                                          color: "primary",
                                          disabled: _vm.dataLoading,
                                          depressed: "",
                                          small: "",
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, [_vm._v("mdi-plus")]),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("actions.add")) +
                                          "\n            "
                                      ),
                                      _c(VIcon, [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          4070777510
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          VList,
                          [
                            _c(
                              VListItem,
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.openAttributeCreateUpdateDialog(
                                      _vm.dialogModes.create,
                                      _vm.attributeScope.global
                                    )
                                  },
                                },
                              },
                              [
                                _c(VListItemTitle, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.startCase(
                                          _vm.$t(
                                            "attributes.actions.createGlobalAttribute"
                                          )
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm.canEditAttributes
                  ? _c(
                      VMenu,
                      {
                        attrs: { "offset-y": "" },
                        scopedSlots: _vm._u(
                          [
                            {
                              key: "activator",
                              fn: function ({ on }) {
                                return [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticClass:
                                          "inputs-actions__import grid-btn",
                                        attrs: {
                                          color: "primary",
                                          small: "",
                                          disabled: _vm.dataLoading,
                                          depressed: "",
                                          loading:
                                            _vm.busyImportingAttributes ||
                                            _vm.busyImportingGroup,
                                        },
                                      },
                                      on
                                    ),
                                    [
                                      _c(VIcon, { attrs: { small: "" } }, [
                                        _vm._v("$import"),
                                      ]),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(_vm.$t("actions.upload")) +
                                          "\n            "
                                      ),
                                      _c(VIcon, [
                                        _vm._v("mdi-chevron-down"),
                                      ]),
                                    ],
                                    1
                                  ),
                                ]
                              },
                            },
                          ],
                          null,
                          false,
                          902015205
                        ),
                      },
                      [
                        _vm._v(" "),
                        _c(
                          VList,
                          [
                            _c(
                              VListItem,
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showHierarchyUpload = true
                                  },
                                },
                              },
                              [
                                _c(VListItemTitle, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.startCase(
                                          _vm.$t(
                                            "attributes.uploadHierarchy.uploadButton"
                                          )
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VListItem,
                              {
                                on: {
                                  click: function ($event) {
                                    _vm.showAttributeUpload = true
                                  },
                                },
                              },
                              [
                                _c(VListItemTitle, [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        _vm.startCase(
                                          _vm.$t(
                                            "attributes.upload.uploadButton"
                                          )
                                        )
                                      ) +
                                      "\n            "
                                  ),
                                ]),
                              ],
                              1
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    )
                  : _vm._e(),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
          "disable-ok-button":
            _vm.checkingIfAttributeCanBeDeleted ||
            !_vm.attributeCanBeDeleted ||
            _vm.deletingAttribute,
        },
        on: {
          onOk: function ($event) {
            return _vm.deleteAttributeHandler(true)
          },
          onCancel: function ($event) {
            return _vm.deleteAttributeHandler(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$t("dialogs.deleteDialog.header")))]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.checkingIfAttributeCanBeDeleted &&
                _vm.attributeCanBeDeleted
                  ? _c("div", [
                      _vm.attributeHasUses
                        ? _c(
                            "div",
                            [
                              _c(
                                "strong",
                                { staticClass: "strong-warning-text" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.$t(
                                        "attributes.editor.deleteMessages.deleteUsedAttributeWarning"
                                      )
                                    )
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _vm._l(
                                _vm.attributeUses,
                                function (attributeUse, index) {
                                  return _c(
                                    "div",
                                    { key: index, staticClass: "pt-3" },
                                    [
                                      attributeUse.exists
                                        ? _c(
                                            "div",
                                            { staticClass: "pl-2" },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticClass: "pb-1",
                                                  attrs: {
                                                    size: "1.7rem",
                                                    color: _vm.alertsIconColor,
                                                  },
                                                },
                                                [_vm._v("warning")]
                                              ),
                                              _vm._v(
                                                "\n              " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                                                      attributeUse.translationParams
                                                    )
                                                  ) +
                                                  "\n            "
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ]
                                  )
                                }
                              ),
                            ],
                            2
                          )
                        : _c("div", [
                            _vm._v(
                              "\n          " +
                                _vm._s(_vm.$t("dialogs.deleteDialog.body")) +
                                "\n          "
                            ),
                            _c("strong", [
                              _vm._v(
                                _vm._s(
                                  _vm.attributeToDelete
                                    ? _vm.attributeToDelete.attributeDescription
                                    : ""
                                )
                              ),
                            ]),
                          ]),
                    ])
                  : _vm.checkingIfAttributeCanBeDeleted &&
                    _vm.attributeUses.length === 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "attributes.editor.deleteMessages.checkingIfTheAttribute"
                              )
                            ) +
                            "\n        "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.attributeToDelete
                                ? _vm.attributeToDelete.text
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "attributes.editor.deleteMessages.canBeDeleted"
                              )
                            ) +
                            "\n        "
                        ),
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            color: "primary",
                            size: "20",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "pb-1" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.attributeToDelete
                                  ? _vm.attributeToDelete.text
                                  : ""
                              )
                            ),
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "attributes.editor.deleteMessages.attributeCannotBeDeleted"
                                )
                              ) +
                              ":\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.attributeUses,
                          function (attributeUse, index) {
                            return _c("div", { key: index }, [
                              attributeUse.exists
                                ? _c(
                                    "div",
                                    { staticClass: "pl-2" },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "pb-1",
                                          attrs: {
                                            size: "1.7rem",
                                            color: _vm.alertsIconColor,
                                          },
                                        },
                                        [_vm._v("warning")]
                                      ),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                                              attributeUse.translationParams
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("attribute-create-update-dialog", {
        key: _vm.get(
          _vm.attributeToEdit,
          "attributeKey",
          _vm.dialogModes.create
        ),
        attrs: {
          "is-open": _vm.isAttributeCreateUpdateDialogOpen,
          scope: _vm.attributeCreateUpdateScope,
          mode: _vm.mode,
          attribute: _vm.attributeToEdit,
          "ignore-attribute-fetch": true,
        },
      }),
      _vm._v(" "),
      _c("hierarchy-upload-data", {
        attrs: { "show-dialog": _vm.showHierarchyUpload },
        on: {
          closeDialog: function ($event) {
            _vm.showHierarchyUpload = false
          },
        },
      }),
      _vm._v(" "),
      _c("attribute-upload-data", {
        attrs: { "show-dialog": _vm.showAttributeUpload },
        on: {
          closeDialog: function ($event) {
            _vm.showAttributeUpload = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }