<template>
  <v-dialog :value="isOpen" persistent width="40rem">
    <v-card>
      <v-container>
        <v-form ref="competitorForm" lazy-validation @submit.prevent="submit">
          <v-card-text class="header-text">
            {{ title }}
          </v-card-text>
          <v-icon class="close-button" color="grey" @click="cancel">close</v-icon>
          <v-card-text class="body">
            <v-text-field
              v-model.trim="competitorName"
              :value="competitorName"
              :label="$t('general.labels.competitorName')"
              :rules="competitorNameRules"
              required
            />
            <v-checkbox
              v-model="incVAT"
              :disabled="!isCreateMode"
              :label="$t('general.labels.incVAT')"
            />
          </v-card-text>

          <v-card-actions class="btn-group">
            <v-spacer />
            <v-btn class="mr-2" color="primary" outlined small @click="cancel">
              {{ $t('actions.cancel') }}
            </v-btn>
            <v-btn :disabled="!isValid" class="mr-2" color="primary" small @click="submit">
              {{ submitBtnCaption }}
            </v-btn>
          </v-card-actions>
        </v-form>
      </v-container>
    </v-card>
  </v-dialog>
</template>

<script>
import moment from 'moment';
import { mapState, mapActions } from 'vuex';
import { startCase, find } from 'lodash';
import dialogModes from '@enums/dialog-modes';

export default {
  props: {
    mode: {
      type: String,
      default: () => dialogModes.create,
    },
    competitor: {
      type: Object,
      default: () => {},
    },
    isOpen: {
      required: true,
    },
  },
  data() {
    return {
      competitorName: this.competitor ? this.competitor.competitorDisplayDescription : null,
      incVAT: this.competitor ? this.competitor.incVAT : false,
      competitorNameRules: [
        v => !!v || this.$t('competitorMatches.editor.errors.emptyCompetitorName'),
        () =>
          !this.competitorNameAlreadyExists ||
          this.$t('competitorMatches.editor.errors.duplicateCompetitorName'),
      ],
    };
  },
  computed: {
    ...mapState('competitorMetadata', ['competitors']),

    title() {
      return startCase(this.$t(`competitorMatches.actions.${this.mode}Competitor`));
    },

    isValid() {
      return !!this.competitorName && !this.competitorNameAlreadyExists;
    },

    submitBtnCaption() {
      return this.isCreateMode ? this.$t('actions.add') : this.$t('actions.save');
    },

    competitorNameAlreadyExists() {
      if (!this.competitorName) return false;
      const lowerCasedCompetitorName = this.competitorName.toLowerCase();
      return this.competitors.some(
        comp => lowerCasedCompetitorName === comp.competitorDisplayDescription.toLowerCase()
      );
    },

    isCreateMode() {
      return this.mode === dialogModes.create;
    },
  },

  methods: {
    ...mapActions('competitorMetadata', ['createCompetitorMetadata', 'editCompetitor']),

    resetSelections() {
      this.competitorName = null;
      this.incVAT = false;
      if (this.$refs.competitorForm) this.$refs.competitorForm.resetValidation();
    },

    async submit() {
      if (!this.isValid) return false;

      if (this.isCreateMode) {
        this.$emit('closeDialog');
        await this.createCompetitorMetadata({
          competitorDescription: this.getCompetitorDescription(this.competitorName),
          competitorDisplayDescription: this.competitorName,
          competitorType: 'manual',
          incVAT: this.incVAT,
        });
      } else {
        const params = {
          competitorDisplayDescription: this.competitorName,
          competitorKey: this.competitor.competitorKey,
        };
        this.$emit('closeDialog');
        await this.editCompetitor(params);
      }
      this.resetSelections();
    },

    getCompetitorDescription(competitorDisplayDescription) {
      const isDescriptionAlreadyExist = find(this.competitors, {
        competitorDescription: competitorDisplayDescription,
      });
      if (!isDescriptionAlreadyExist) return competitorDisplayDescription;
      return `${competitorDisplayDescription}_${moment().unix()}`;
    },

    cancel() {
      this.$emit('closeDialog');
      this.resetSelections();
    },
  },
};
</script>

<style lang="scss" scoped>
.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}
</style>
