'use strict';

module.exports = {
  priceOverrides: 'price-overrides',
  engineInputs: 'mandatory-engine-inputs',
  productInformation: 'product-information',
  penaltyRules: 'penalty-rules',
  competition: 'competition',
  attributes: 'attributes',
  architectureSetup: 'architecture-setup',
};
