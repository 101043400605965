import { toLower, get } from 'lodash';

const compareArchitectureDescriptions = (a, b) => {
  if (a === b) return 0;
  if (!a) return 1;
  if (!b) return -1;
  return a.localeCompare(b);
};

const compareInteger = (a, b) => {
  if (a === b) return 0;
  if (a > b) return 1;
  return -1;
};

/**
 * Multi level sorting of product groups by architectureGroupPriority -> architectureGroup -> architectureSubGroup ->
 * linePricingGroup -> Brand sorting -> contentValue
 * @param {*} a first value to compare
 * @param {*} b second value to compare
 * @param {string|undefined} stopOn stop on one of the levels: 'architectureGroupPriority' -> 'architectureGroup' -> 'architectureSubGroup' ->
 * 'linePricingGroup' -> 'Brand' -> 'contentValue'; falsy - sort by all levels
 * @returns {number} -1 if a < b, 0 if a === b, 1 if a > b
 */
const multiLevelSortProductGroups = (a, b, stopOn) => {
  // ---------- lvl 1: architectureGroupPriority sorting -----------
  if (a.architectureGroupPriority !== b.architectureGroupPriority) {
    if (!a.architectureGroupPriority) return 1;
    if (!b.architectureGroupPriority) return -1;
    return a.architectureGroupPriority - b.architectureGroupPriority;
  }
  if (stopOn === 'architectureGroupPriority') {
    return 0;
  }

  // ---------- lvl 2: architectureGroup sorting -----------
  const aAG = toLower(get(a, 'architectureGroupDescription', ''));
  const bAG = toLower(get(b, 'architectureGroupDescription', ''));
  const architectureGroupComparison = compareArchitectureDescriptions(aAG, bAG);

  if (architectureGroupComparison !== 0 || stopOn === 'architectureGroup')
    return architectureGroupComparison;

  // ---------- lvl 3: architectureSubGroup sorting -----------
  const subGroupComparison = compareArchitectureDescriptions(
    a.subGroupDescription,
    b.subGroupDescription
  );
  if (subGroupComparison !== 0 || stopOn === 'architectureSubGroup') return subGroupComparison;

  // ---------- lvl 4: linePricingGroup sorting -----------

  const linePricingGroupComparison = compareArchitectureDescriptions(
    a.linePricingGroupDescription,
    b.linePricingGroupDescription
  );
  if (linePricingGroupComparison !== 0 || stopOn === 'linePricingGroup') {
    return linePricingGroupComparison;
  }

  // ---------- lvl 5: brandName sorting -----------
  const brandNameComparison = compareArchitectureDescriptions(a.brandName, b.brandName);
  if (brandNameComparison !== 0 || stopOn === 'Brand') {
    return brandNameComparison;
  }
  // ---------- lvl 6: contentValue sorting -----------
  const contentValueComparison = compareInteger(a.contentValue, b.contentValue);
  if (contentValueComparison !== 0 || stopOn === 'contentValue') {
    return contentValueComparison;
  }

  return 0;
};

export const getMultiLevelSortProductGroupsFunc = sortBySubGroupAndLPG => (a, b) =>
  multiLevelSortProductGroups(a, b, sortBySubGroupAndLPG);

export default multiLevelSortProductGroups;
