<template>
  <div class="input-screen-page-wrapper" style="width: 100%; height: 100%">
    <!-- Menu options -->

    <inputs-grid
      ref="inputsGrid"
      :additional-columns="additionalColumns"
      :save-changes="saveChanges"
      :column-validations="columnValidations"
      :additional-dependent-columns="dependentColumns"
      :is-saveable="isSaveable"
      :export-action="downloadOverrides"
      :is-exporting="downloading"
      route-suffix="overrides"
    >
      <!-- Slots for inputs-grid, -->
      <!-- Import/ Add buttons etc. -->
      <template #buttons>
        <price-overrides-upload v-if="canEditPriceOverrides" />
      </template>
    </inputs-grid>
  </div>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import axios from 'axios';
import moment from 'moment';
import tablesMixin from '../../../mixins/tables';
import { get, startCase, isEmpty } from 'lodash';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { yearMonthDayFormat } from '@enums/date-formats';
import { maxDateTimestamp } from '@enums/dates';
import numberFormats from '@enums/number-formats';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import { useZones, displayHierarchyAndFilter } from '@enums/feature-flags';
import { inputTableCssClasses } from '@enums/tables';
import DataTypes from '@enums/data-types';
import { categoryLevel, pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import datePickerEditor from './components/date-picker-editor.vue';
import { validations, localisedNumericCol } from '../../../utils/inputs-grid-utils';

const requiresPrice = {
  message: 'needPrice',
  test(params) {
    const hasEffectiveDate = Boolean(get(params, 'node.data.overridePrice.effectiveDate'));
    const hasOverridePrice = Boolean(get(params, 'node.data.overridePrice.price'));
    return !(hasEffectiveDate && !hasOverridePrice);
  },
};
const requiresExpireDate = {
  message: 'needExpireDate',
  test(params) {
    const hasEffectiveDate = Boolean(get(params, 'node.data.overridePrice.effectiveDate'));
    const hasExpiryDate = Boolean(get(params, 'node.data.overridePrice.expiryDate'));
    return !(hasEffectiveDate && !hasExpiryDate);
  },
};

const { greaterThan0 } = validations;

export default {
  name: 'PriceOverridesEditor',
  components: {
    // register vue components
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    datePickerEditor,
  },

  mixins: [featureFlagsMixin, tablesMixin],

  async beforeRouteLeave(destination, from, next) {
    const preventNavigation = await this.$refs.inputsGrid.resolveEditsBeforeNavigation();

    if (preventNavigation) return;

    next();
  },

  data() {
    return {
      get,
      startCase,
      invalidRows: {},
      showDatePicker: false,
      selectedCellData: null,
      maxDateChecksMap: new Map(),
      maxMomentDate: moment.utc(maxDateTimestamp),
      columnValidations: {
        'overridePrice::price': { greaterThan0, requiresPrice },
        'overridePrice::expiryDate': { requiresExpireDate },
      },
      dependentColumns: {
        'overridePrice::effectiveDate': ['overridePrice::price', 'overridePrice::expiryDate'],
      },
      priceOverrideFields: [
        { field: 'price', type: DataTypes.number, text: this.$t('priceOverrides.editor.price') },
        {
          field: 'referencePriceAtOverride',
          type: DataTypes.number,
          text: this.$t('priceOverrides.editor.referencePriceAtOverride'),
        },
        {
          field: 'effectiveDate',
          type: DataTypes.str,
          text: this.$t('priceOverrides.editor.effectiveDate'),
        },
        {
          field: 'expiryDate',
          type: DataTypes.str,
          text: this.$t('priceOverrides.editor.expiryDate'),
        },
      ],
    };
  },

  computed: {
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('scenarioProducts', ['downloading']),
    ...mapState('hierarchy', {
      loadingHierarchy: 'loading',
      busyImportingGroup: 'busyImportingGroup',
    }),
    ...mapState('competitorMetadata', ['competitors', 'competitorKeyToTypeMap']),
    ...mapGetters('context', ['isPricingSpecialist']),
    ...mapState('clientConfig', ['i18nconfig']),
    ...mapState('filters', ['retailAttributesFilter']),

    zonesEnabled() {
      return this.isFeatureFlagEnabled(useZones);
    },

    canEditPriceOverrides() {
      return this.isPricingSpecialist;
    },

    getTodaysDate() {
      return moment().format(yearMonthDayFormat);
    },

    isSaveable() {
      return isEmpty(this.invalidRows);
    },

    toolStoreGroupColumn() {
      return this.isFeatureFlagEnabled(useZones)
        ? {
            text: this.$t('priceOverrides.editor.toolStoreGroup'),
            value: 'toolStoreGroupDescription',
            sortable: true,
            align: 'start',
            class: `m-width ${inputTableCssClasses.threeFixedColumns}`,
            dataType: DataTypes.str,
          }
        : null;
    },

    showHierarchy() {
      return this.isFeatureFlagEnabled(displayHierarchyAndFilter);
    },

    hierarchyHeaders() {
      return this.showHierarchy
        ? [
            {
              text: this.$t('pricing.category'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${categoryLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.pricingGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${pricingGroupLevel}.levelEntryDescription`,
              class: 'm-width',
              dataType: DataTypes.str,
            },
            {
              text: this.$t('pricing.architectureGroup'),
              align: 'start',
              sortable: true,
              value: `hierarchy.${architectureGroupLevel}.levelEntryDescription`,
              class: 'border-right m-width',
              dataType: DataTypes.str,
            },
          ]
        : [];
    },

    headers() {
      const tsgHeader = this.toolStoreGroupColumn ? [this.toolStoreGroupColumn] : [];
      return [
        {
          text: this.$t('priceOverrides.editor.productKey'),
          align: 'start',
          sortable: true,
          value: 'productKeyDisplay',
          class: `s-width ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
          formatter: {
            type: get(
              this.exportConfigs,
              'exportToExcel.columnFormatter.productKeyDisplay',
              numberFormats.integer
            ),
          },
        },
        ...tsgHeader,
        {
          text: this.$t('priceOverrides.editor.productDescription'),
          align: 'start',
          sortable: true,
          value: 'productDescription',
          class: `l-width border-right ${this.fixedColumnsClass}`,
          dataType: DataTypes.str,
        },
        ...this.hierarchyHeaders,
        {
          text: this.$t('priceOverrides.editor.price'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.price',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('priceOverrides.editor.referencePriceAtOverride'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.referencePriceAtOverride',
          dataType: DataTypes.number,
        },
        {
          text: this.$t('priceOverrides.editor.effectiveDate'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.effectiveTimestamp',
          isDate: true,
          dataType: DataTypes.number,
          formatter: {
            type: DataTypes.date,
            format: this.dateFormats.clientDateFormatForMoment,
          },
        },
        {
          text: this.$t('priceOverrides.editor.expiryDate'),
          align: 'start',
          sortable: true,
          value: 'overridePrice.expiryTimestamp',
          isDate: true,
          dataType: DataTypes.number,
          formatter: {
            type: DataTypes.date,
            format: this.dateFormats.clientDateFormatForMoment,
          },
        },
      ];
    },

    exportTranslationMap() {
      const hierarchyColumnTranslations = this.showHierarchy
        ? {
            [`hierarchy.${categoryLevel}.levelEntryDescription`]: this.$t('pricing.category'),
            [`hierarchy.${pricingGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.pricingGroup'
            ),
            [`hierarchy.${architectureGroupLevel}.levelEntryDescription`]: this.$t(
              'pricing.architectureGroup'
            ),
          }
        : {};
      const priceOverrideFields = this.priceOverrideFields.reduce((acc, fieldObj) => {
        acc[fieldObj.field] = fieldObj.text;
        return acc;
      }, {});

      const toolStoreGroupColumn = this.zonesEnabled
        ? { toolStoreGroupDescription: this.$t('pricing.toolStoreGroup') }
        : {};

      return {
        productKeyDisplay: this.$t('priceOverrides.editor.productKey'),
        ...toolStoreGroupColumn,
        productDescription: this.$t('priceOverrides.editor.productDescription'),
        ...hierarchyColumnTranslations,
        ...priceOverrideFields,
      };
    },

    additionalColumns() {
      return [
        {
          headerName: this.$t('linkText.priceOverrides'),
          groupId: `priceOverrides`,
          children: [
            {
              headerName: this.$t('priceOverrides.editor.price'),
              colId: `overridePrice::price`,
              field: `overridePrice.price`,
              editable: params => get(params, `data.hierarchy[${architectureGroupLevel}].id`),
              onCellValueChanged: this.onCellValueChangedHandler,
              ...localisedNumericCol,
            },
            {
              headerName: this.$t('priceOverrides.editor.referencePriceAtOverride'),
              colId: `overridePrice::referencePriceAtOverride`,
              field: `overridePrice.referencePriceAtOverride`,
              tooltipValueGetter: this.tooltipValueGetter,
              ...localisedNumericCol,
              editable: false,
            },
            {
              headerName: this.$t('priceOverrides.editor.effectiveDate'),
              colId: `overridePrice::effectiveDate`,
              field: `overridePrice.effectiveDate`,
              valueFormatter: params => this.formatDateValue(params.value),
              cellEditor: 'datePickerEditor',
              cellEditorPopup: true,
              cellEditorPopupPosition: 'under',
              cellEditorParams: {
                firstDayOfWeek: this.i18nconfig.firstDayOfTheWeek,
                locale: this.i18nconfig.fallbackLocale,
                minDate: () => this.getTodaysDate,
                dateValue: date => this.getDateValue(date, true),
              },
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  if (cellValue == null) return -1;
                  const cellDate = moment(cellValue, yearMonthDayFormat);
                  const filterDate = moment(
                    filterLocalDateAtMidnight.toISOString().split('T')[0],
                    yearMonthDayFormat
                  ).add({ days: 1 });
                  if (filterDate.isSame(cellDate)) {
                    return 0;
                  }
                  if (cellDate.isBefore(filterDate)) {
                    return -1;
                  }
                  if (cellDate.isAfter(filterDate)) {
                    return 1;
                  }
                },
                browserDatePicker: true,
                minValidYear: 2000,
              },
              editable: true,
            },
            {
              headerName: this.$t('priceOverrides.editor.expiryDate'),
              colId: `overridePrice::expiryDate`,
              field: `overridePrice.expiryDate`,
              valueFormatter: params => this.formatDateValue(params.value),
              cellEditor: 'datePickerEditor',
              cellEditorPopup: true,
              cellEditorPopupPosition: 'under',
              cellEditorParams: {
                firstDayOfWeek: this.i18nconfig.firstDayOfTheWeek,
                locale: this.i18nconfig.fallbackLocale,
                minDate: params => {
                  return (
                    this.getDateValue(params.data.overridePrice.effectiveDate, true) ||
                    this.getTodaysDate
                  );
                },
                dateValue: date => this.getDateValue(date, true),
              },
              filter: 'agDateColumnFilter',
              filterParams: {
                comparator: (filterLocalDateAtMidnight, cellValue) => {
                  if (cellValue == null) return -1;
                  const cellDate = moment(cellValue, yearMonthDayFormat);
                  const filterDate = moment(
                    filterLocalDateAtMidnight.toISOString().split('T')[0],
                    yearMonthDayFormat
                  ).add({ days: 1 });
                  if (filterDate.isSame(cellDate)) {
                    return 0;
                  }
                  if (cellDate.isBefore(filterDate)) {
                    return -1;
                  }
                  if (cellDate.isAfter(filterDate)) {
                    return 1;
                  }
                },
                browserDatePicker: true,
                minValidYear: 2000,
              },
              editable: true,
              onCellValueChanged: this.onExpiryDateValueChangedHandler,
            },
          ],
        },
      ];
    },
  },

  destroyed() {
    this.maxDateChecksMap.clear();
  },

  methods: {
    ...mapActions('scenarioProducts', ['downloadPriceOverrides']),
    tooltipValueGetter(params) {
      return this.$refs.inputsGrid.tooltipValueGetter(params);
    },
    isMainbanner(toolStoreGroupKey) {
      // If not using zones, you only have mainbanner
      if (!this.zonesEnabled) return true;

      return toolStoreGroupKey === this.mainTsgKey;
    },

    downloadOverrides() {
      this.downloadPriceOverrides({
        where: this.retailAttributesFilter,
        translationMap: this.exportTranslationMap,
        columnFormatters: this.getColumnFormatters(this.headers),
      });
    },

    async saveChanges({ updates, mainTsgKey }) {
      const { data: results } = await axios.post(
        `/api/inputs/workpackage/${this.selectedWorkpackage._id}/overrides`,
        { updates, mainTsgKey }
      );
      return results;
    },

    onCellValueChangedHandler(params) {
      if (params.column.colId === 'overridePrice::price') {
        const newValue = params.data.overridePrice.price
          ? params.data.optimizedPrice || params.data.scenarioPrice || 0.0
          : null;
        const rowNode = params.node;
        const colKey = 'overridePrice::referencePriceAtOverride';
        rowNode.setDataValue(colKey, newValue);
      }
    },

    onExpiryDateValueChangedHandler(params) {
      if (params.column.colId === 'overridePrice::expiryDate') {
        const effectiveDate = get(params, 'data.overridePrice.effectiveDate');
        if (!effectiveDate) {
          const rowNode = params.node;
          const colKey = 'overridePrice::effectiveDate';
          rowNode.setDataValue(colKey, this.getTodaysDate);
        }
      }
    },

    maxDateExceeded(date) {
      if (this.maxDateChecksMap.has(date)) {
        return this.maxDateChecksMap.get(date);
      }
      const momentDate = moment.utc(date);
      if (!momentDate.isValid()) {
        this.maxDateChecksMap.set(date, false);
        return false;
      }
      const maxDateExceeded = isSameOrAfter(momentDate, this.maxMomentDate);
      this.maxDateChecksMap.set(date, maxDateExceeded);
      return maxDateExceeded;
    },

    formatDateValue(date) {
      return this.maxDateExceeded(date) ? '-' : this.formatLocalisedDate(date);
    },

    getDateValue(date, checkOnMaxDate) {
      if (!checkOnMaxDate || !date) {
        return date;
      }
      return this.maxDateExceeded(date) ? null : date;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.main-header th {
  position: relative;
}

#buttons-bar {
  max-width: 20rem;
}

.v-list-item__title {
  font-size: 1.5rem;
}

.v-list-item {
  min-height: 35px;
}

.no-data-slot {
  padding-left: 2.2rem;
}

.input-screen-page-wrapper .table-cell::v-deep .tooltipped-truncated-field {
  position: unset;
}

.date-attribute-cell {
  position: relative;
}

.pale {
  opacity: 0.7;
}
</style>
