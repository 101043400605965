<template>
  <v-footer app height="40px" elevation="8" class="pricing-toolbar">
    <v-btn
      text
      class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
      color="white"
      :href="termsOfUse"
    >
      <v-flex class="subheading">{{ $t('footer.termsOfUse') }}</v-flex>
    </v-btn>
    <v-btn
      text
      class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
      color="white"
      :href="privacyNote"
    >
      <v-flex class="subheading">{{ $t('footer.privacyNote') }}</v-flex>
    </v-btn>
    <template v-if="showCookieBanner">
      <v-btn
        text
        class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
        color="white"
        :href="cookieNotice"
      >
        <v-flex class="subheading">{{ $t('footer.cookieNotice') }}</v-flex>
      </v-btn>
      <v-btn
        text
        class="nav-link pa-0 mr-6 cookie-manager-btn text-none"
        color="white"
        @click="onManageCookiesClick"
      >
        <v-flex class="subheading">{{ $t('footer.manageCookiesButtonTitle') }}</v-flex>
      </v-btn>
    </template>
    <div class="subheading">
      {{ copyrightOwText }}
    </div>
    <template v-if="loading">
      <v-spacer />
      <div>
        <v-icon class="fa-spin" color="white">autorenew</v-icon>
        <span class="pl-2 subheading">{{ $t(loadingMsg) || $t('general.loadingMessage') }}</span>
      </div>
    </template>
    <v-spacer />
    <v-btn text class="text-none ml-5" :href="owLink">
      <v-flex class="subheading">{{ $t('footer.solutionFrom') }}:</v-flex>
      <v-flex offset-md1 xs12 class="ow-logo">
        <img :src="owLogo" height="12px" width="103px" />
      </v-flex>
    </v-btn>
  </v-footer>
</template>

<script>
import moment from 'moment';
import { mapState } from 'vuex';
import owLogo from '../../img/logo-oliver-wyman-white.svg';

export default {
  data() {
    return {
      moment,
      owLogo,
      owLink: 'http://labs.oliverwyman.com/index.html',
      termsOfUse:
        'https://www.oliverwyman.com/content/dam/oliver-wyman/v2/legal/OW-General-Web-Portal-ToU.pdf',
      privacyNote: 'https://www.oliverwyman.com/policies/privacy-notice-platforms.html',
      cookieNotice: 'https://www.oliverwyman.com/policies/cookie-notice.html',
    };
  },
  computed: {
    ...mapState('gridView', ['loading', 'loadingMsg']),
    ...mapState('clientConfig', ['showCookieBanner']),
    copyrightOwText() {
      return `Copyright © ${moment().format('YYYY')} Oliver Wyman`;
    },
  },
  methods: {
    onManageCookiesClick() {
      // eslint-disable-next-line no-undef
      Osano.cm.showDrawer('osano-cm-dom-info-dialog-open');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.loading {
  font-size: 1.25rem;
}

.pricing-toolbar {
  background-color: $pricing-black;
  color: $pricing-white;
  text-decoration-color: $pricing-white;
  align-content: center;
}

.v-btn:hover:before,
.v-btn:focus:before {
  color: transparent;
}

.cookie-manager-btn {
  text-decoration: underline;
}

.subheading {
  font-size: 1.25rem;
  color: $pricing-white;
  text-decoration-color: $pricing-white;
}

.ow-logo {
  padding-top: 5px;
}
</style>
