<template>
  <svg
    width="24px"
    height="23px"
    viewBox="0 0 24 23"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Group 68</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Group-68">
        <rect
          id="Rectangle"
          fill="#2F477C"
          transform="translate(12.000000, 11.500000) scale(-1, 1) translate(-12.000000, -11.500000) "
          x="0"
          y="0"
          width="24"
          height="23"
          rx="3"
        />
        <path
          id="Icon-colour"
          d="M15.125,4 C15.3781305,4 15.5873273,4.23408322 15.6204356,4.53779035 L15.625,4.62222222 L15.625,4.933 L17.0909091,4.93333333 C18.0973453,4.93333333 18.9218849,5.79000501 18.9947636,6.87660787 L19,7.03333333 L19,16.8333333 C19,17.9931313 18.1452709,18.9333333 17.0909091,18.9333333 L17.0909091,18.9333333 L6.90909091,18.9333333 C5.85472911,18.9333333 5,17.9931313 5,16.8333333 L5,16.8333333 L5,7.03333333 C5,5.87353536 5.85472911,4.93333333 6.90909091,4.93333333 L6.90909091,4.93333333 L8.5,4.933 L8.5,4.62222222 C8.5,4.27857838 8.72385763,4 9,4 C9.25313051,4 9.46232731,4.23408322 9.49543559,4.53779035 L9.5,4.62222222 L9.5,4.933 L14.625,4.933 L14.625,4.62222222 C14.625,4.27857838 14.8488576,4 15.125,4 Z M17.727,9.666 L6.272,9.666 L6.27272727,16.8333333 C6.27272727,17.1847873 6.50818984,17.4757464 6.81505375,17.5257435 L6.90909091,17.5333333 L17.0909091,17.5333333 C17.442363,17.5333333 17.7272727,17.2199327 17.7272727,16.8333333 L17.7272727,16.8333333 L17.727,9.666 Z M8.5,6.333 L6.90909091,6.33333333 C6.55763698,6.33333333 6.27272727,6.64673401 6.27272727,7.03333333 L6.27272727,7.03333333 L6.272,8.666 L17.727,8.666 L17.7272727,7.03333333 C17.7272727,6.6818794 17.4918102,6.39092022 17.1849462,6.34092313 L17.0909091,6.33333333 L15.625,6.333 L15.625,7.11111111 C15.625,7.45475496 15.4011424,7.73333333 15.125,7.73333333 C14.8718695,7.73333333 14.6626727,7.49925011 14.6295644,7.19554298 L14.625,7.11111111 L14.625,6.333 L9.5,6.333 L9.5,7.11111111 C9.5,7.45475496 9.27614237,7.73333333 9,7.73333333 C8.74686949,7.73333333 8.53767269,7.49925011 8.50456441,7.19554298 L8.5,7.11111111 L8.5,6.333 Z"
          fill="#FFFFFF"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Calendar',
};
</script>
