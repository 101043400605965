import PromotionPR from './components/component-icons/pr-promotion.vue';
import Import from './components/component-icons/import.vue';
import Export from './components/component-icons/export.vue';
import architectureOrder from './components/component-icons/alerts/architectureOrder.vue';
import engineCalculationError from './components/component-icons/alerts/engineCalculationError.vue';
import productBeforePromotion from './components/component-icons/alerts/productBeforePromotion.vue';
import productOnPromotion from './components/component-icons/alerts/productOnPromotion.vue';
import linePricingGroupOverride from './components/component-icons/alerts/linePricingGroupOverride.vue';
import longTermIntentionPrice from './components/component-icons/alerts/longTermIntentionPrice.vue';
import missingEngineInput from './components/component-icons/alerts/missingEngineInput.vue';
// PRICE-2151 TODO: invalidEngineInputs
import missingHistoricalData from './components/component-icons/alerts/missingHistoricalData.vue';
import outlier from './components/component-icons/alerts/outlier.vue';
import priceIncreasingTooFast from './components/component-icons/alerts/priceIncreasingTooFast.vue';
import significantCompetitorMove from './components/component-icons/alerts/significantCompetitorMove.vue';
import underlyingReferenceChange from './components/component-icons/alerts/underlyingReferenceChange.vue';
import underspecifiedPenaltyCurve from './components/component-icons/alerts/underspecifiedPenaltyCurve.vue';
import manualOverride from './components/component-icons/alerts/manualOverride.vue';
import competitorMatchLost from './components/component-icons/alerts/competitorMatchLost.vue';

export default {
  values: {
    'promotion-pr': {
      component: PromotionPR,
    },
    import: {
      component: Import,
    },
    export: {
      component: Export,
    },
    architectureOrder: {
      component: architectureOrder,
    },
    // always show architecture order icon for deviations from architecture
    priceBelowArchitectureMin: {
      component: architectureOrder,
    },
    priceAboveArchitectureMax: {
      component: architectureOrder,
    },
    engineCalculationError: {
      component: engineCalculationError,
    },
    productOnPromotion: {
      component: productOnPromotion,
    },
    productBeforePromotion: {
      component: productBeforePromotion,
    },
    linePricingGroupOverride: {
      component: linePricingGroupOverride,
    },
    longTermIntentionPrice: {
      component: longTermIntentionPrice,
    },
    missingEngineInput: {
      component: missingEngineInput,
    },
    missingHistoricalData: {
      component: missingHistoricalData,
    },
    manualOverride: {
      component: manualOverride,
    },
    outlier: {
      component: outlier,
    },
    priceIncreasingTooFast: {
      component: priceIncreasingTooFast,
    },
    significantCompetitorMove: {
      component: significantCompetitorMove,
    },
    underlyingReferenceChange: {
      component: underlyingReferenceChange,
    },
    underspecifiedPenaltyCurve: {
      component: underspecifiedPenaltyCurve,
    },
    competitorMatchLost: {
      component: competitorMatchLost,
    },
  },
};
