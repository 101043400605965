import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        VDialog,
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("attributes.upload.dialogHeading")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "close-button",
                  attrs: { color: "grey" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("close")]
              ),
              _vm._v(" "),
              _vm.isInitial
                ? _c("file-upload", {
                    attrs: {
                      "upload-field-name": _vm.uploadFieldName,
                      "is-saving": _vm.isSaving,
                      source: "inputs",
                    },
                    on: { save: _vm.save },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSaving
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c("div", { staticClass: "v-input" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("attributes.upload.uploadingState")
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isValidated
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c("div", { staticClass: "dialog-copy" }, [
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "attributes.upload.articlesReceived"
                                      )
                                    ) +
                                    ":\n                  "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.counts.articlesReceived)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "attributes.upload.articlesMatched"
                                      )
                                    ) +
                                    ":\n                  "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.counts.articlesMatched)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "attributes.upload.attributesReceived"
                                      )
                                    ) +
                                    ":\n                  "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.counts.attributesReceived)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t(
                                        "attributes.upload.attributesMatched"
                                      )
                                    ) +
                                    ":\n                  "
                                ),
                                _c("b", [
                                  _vm._v(_vm._s(_vm.counts.attributesMatched)),
                                ]),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(" "),
                                _c("br"),
                                _vm._v(
                                  "\n                  " +
                                    _vm._s(
                                      _vm.$t("attributes.upload.confirmUpload")
                                    ) +
                                    "\n                "
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "dialog-button",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                depressed: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary dialog-button",
                              attrs: {
                                depressed: "",
                                loading: _vm.busyImportingAttributes,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirm()
                                },
                              },
                            },
                            [
                              _vm._v(
                                _vm._s(_vm.$t("actions.upload")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFailed || _vm.isNotValidated
                ? _c(
                    "div",
                    [
                      !(
                        _vm.duplicateKeysError ||
                        _vm.incorrectTSGsError ||
                        _vm.productsGoLiveBeforeTodayError
                      )
                        ? _c(
                            "div",
                            [
                              _c(VCardText, [
                                _c(
                                  "div",
                                  { staticClass: "container grid-list-md" },
                                  [
                                    _c("div", { staticClass: "layout wrap" }, [
                                      _vm.uploadError &&
                                      _vm.uploadError.tooManyRows
                                        ? _c(
                                            "div",
                                            { staticClass: "flex xs12" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "v-input" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.uploadHierarchy.tooManyRows",
                                                          {
                                                            maxRows:
                                                              _vm.maxRows,
                                                          }
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          )
                                        : _c(
                                            "div",
                                            { staticClass: "flex xs12" },
                                            [
                                              _c(
                                                "div",
                                                { staticClass: "v-input" },
                                                [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.getErrorMessage()
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]
                                              ),
                                            ]
                                          ),
                                    ]),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.duplicateKeysError || _vm.incorrectTSGsError
                        ? _c(
                            "feature-toggle",
                            { attrs: { toggle: _vm.useZones } },
                            [
                              _c(VCardText, [
                                _c(
                                  "div",
                                  { staticClass: "container grid-list-md" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "layout wrap" },
                                      [
                                        _vm.duplicateKeysError
                                          ? _c("upload-error-card", {
                                              attrs: {
                                                "error-message": _vm.$t(
                                                  "attributes.upload.conflictedToolStoreGroupKeys"
                                                ),
                                                details:
                                                  _vm.uploadError
                                                    .duplicateProductKeys,
                                                "unique-key-prefix":
                                                  "conflictToolStoreGroupKeys",
                                              },
                                            })
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.incorrectTSGsError
                                          ? _c("upload-error-card", {
                                              attrs: {
                                                "error-message": _vm.$t(
                                                  "attributes.upload.incorrectToolStoreGroups"
                                                ),
                                                details:
                                                  _vm.uploadError
                                                    .productKeysWithIncorrectTSGs,
                                                "unique-key-prefix":
                                                  "incorrectToolStoreGroups",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.productsGoLiveBeforeTodayError
                        ? _c(
                            "feature-toggle",
                            {
                              attrs: { toggle: _vm.useManualProductGoLiveDate },
                            },
                            [
                              _c(VCardText, [
                                _c(
                                  "div",
                                  { staticClass: "container grid-list-md" },
                                  [
                                    _c(
                                      "div",
                                      { staticClass: "layout wrap" },
                                      [
                                        _vm.productsGoLiveBeforeTodayError
                                          ? _c("upload-error-card", {
                                              attrs: {
                                                "error-message": _vm.$t(
                                                  "attributes.upload.productsGoLiveBeforeToday"
                                                ),
                                                details:
                                                  _vm.uploadError
                                                    .productKeysGoLiveBeforeToday,
                                                "unique-key-prefix":
                                                  "productsGoLiveBeforeToday",
                                              },
                                            })
                                          : _vm._e(),
                                      ],
                                      1
                                    ),
                                  ]
                                ),
                              ]),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary",
                              on: {
                                click: function ($event) {
                                  return _vm.closeDialog()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.close")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }