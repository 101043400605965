import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    [
      _c(
        VFlex,
        [
          _c(
            VExpansionPanels,
            { attrs: { value: _vm.value } },
            [
              _c(
                VExpansionPanel,
                {
                  staticClass: "line-pricing borderless-panel",
                  attrs: { disabled: _vm.disabled },
                  on: { change: _vm.panelOpenedOrClosed },
                },
                [
                  _c(
                    VExpansionPanelHeader,
                    {
                      scopedSlots: _vm._u([
                        {
                          key: "actions",
                          fn: function () {
                            return [_c(VIcon, [_vm._v("expand_more")])]
                          },
                          proxy: true,
                        },
                      ]),
                    },
                    [
                      _c("span", [
                        _vm._v(_vm._s(_vm.$t("settings.setLinePricing"))),
                      ]),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VExpansionPanelContent,
                    { staticClass: "expansion-body" },
                    [
                      _c(
                        VContainer,
                        { attrs: { fluid: "" } },
                        [
                          _c(
                            VRow,
                            [
                              _c(
                                VCol,
                                {
                                  staticClass: "products-header",
                                  attrs: {
                                    cols: "12",
                                    md: "6",
                                    lg: "6",
                                    xl: "5",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass: "pl-0 pb-0 pt-0",
                                          attrs: { cols: "9" },
                                        },
                                        [
                                          _c("span", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.$t(
                                                  "settings.architecture.productsHeader"
                                                )
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                      _vm._v(" "),
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "text-right pl-0 pb-0 pt-0",
                                        },
                                        [
                                          _c(
                                            "span",
                                            { staticClass: "products-count" },
                                            [
                                              _vm._v(
                                                "\n                      " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "settings.architecture.productsCount",
                                                      {
                                                        productsCount:
                                                          _vm.productsCount,
                                                      }
                                                    )
                                                  ) +
                                                  "\n                    "
                                              ),
                                            ]
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VRow,
                                    [
                                      _c(
                                        VCol,
                                        { staticClass: "pl-0" },
                                        [
                                          _c("attribute-filter-panel", {
                                            attrs: {
                                              "filter-rules":
                                                _vm.architectureRulesLinePricingFilter,
                                              "title-localisation":
                                                "attributes.filters.filterByAttributes",
                                              "filter-count-localisation":
                                                "attributes.filters.numApplied",
                                              "fixed-filter-rules":
                                                _vm.fixedFilterRules,
                                            },
                                            on: {
                                              attributeFilterChange:
                                                _vm.setAttributeFilter,
                                            },
                                          }),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            {
                                              staticClass:
                                                "pb-5 justify-between",
                                            },
                                            [
                                              _c("norm-weight-toggle"),
                                              _vm._v(" "),
                                              _c(
                                                "span",
                                                [
                                                  _c(
                                                    "line-pricing-export-button"
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                "div",
                                                [
                                                  _c(
                                                    VBtn,
                                                    {
                                                      staticClass:
                                                        "add-products-btn",
                                                      attrs: {
                                                        color: "primary",
                                                        small: "",
                                                        disabled:
                                                          _vm.isAddBtnDisabled,
                                                        depressed: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.addProducts()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                          " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.architecture.addProducts"
                                                            )
                                                          ) +
                                                          "\n                        "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                          _vm._v(" "),
                                          _c(
                                            "div",
                                            { staticClass: "ag-grid-box" },
                                            [
                                              _c("ag-grid-vue", {
                                                staticClass:
                                                  "ag-theme-custom ag-theme-custom-stripped ag-line-pricing-grid",
                                                staticStyle: {
                                                  width: "100%",
                                                  height: "100%",
                                                },
                                                attrs: {
                                                  "grid-options":
                                                    _vm.gridOptions,
                                                  "column-defs": _vm.columnDefs,
                                                  "row-data": _vm.rowData,
                                                  "default-col-def":
                                                    _vm.defaultColDef,
                                                },
                                                on: {
                                                  "grid-ready": _vm.onGridReady,
                                                },
                                              }),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c(
                                VCol,
                                [
                                  _vm.scenario && _vm.architectureGroup
                                    ? _c("line-pricing-groups-list", {
                                        attrs: {
                                          "line-pricing-groups":
                                            _vm.mutableLinePricingGroups,
                                          "active-line-pricing-group":
                                            _vm.activeLinePricingGroup,
                                          "architecture-group-id":
                                            _vm.architectureGroup,
                                          "scenario-key": _vm.scenario,
                                          disabled: !_vm.editable,
                                        },
                                        on: {
                                          setActiveLinePricingGroupIndex:
                                            _vm.setActiveLinePricingGroupIndex,
                                          deleteLinePricingGroup:
                                            _vm.deleteLinePricingGroup,
                                          deleteLinePricedProduct:
                                            _vm.deleteLinePricedProduct,
                                        },
                                      })
                                    : _vm._e(),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "text-right pl-0 pb-0 pt-0",
                                      attrs: { cols: "10" },
                                    },
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          staticClass: "save-btn save",
                                          attrs: {
                                            color: "success",
                                            small: "",
                                            disabled:
                                              _vm.isSaveBtnDisabled ||
                                              !_vm.editable ||
                                              _vm.isLoading,
                                            loading: _vm.isLoading,
                                            depressed: "",
                                          },
                                          on: {
                                            click: _vm.onSaveLinePricingGroups,
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                    " +
                                              _vm._s(_vm.$t("actions.save")) +
                                              "\n                  "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }