import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        "offset-y": "",
        "close-on-content-click": false,
        disabled: !_vm.totalAlertCount,
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "alert-header d-flex pl-3",
                    on: { click: _vm.getOnDemandAlerts },
                  },
                  on
                ),
                [
                  _c(
                    "div",
                    { staticClass: "flex-grow-1" },
                    [
                      _vm._m(0),
                      _vm._v(" "),
                      _c("span", { staticClass: "pl-1" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number: _vm.daysToCompletion,
                                format: _vm.numberFormats.integer,
                                zeroAsDash: true,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.$tc(
                                "workPackages.daysToCompletion",
                                _vm.daysToCompletion
                              )
                            ) +
                            " /\n          " +
                            _vm._s(
                              _vm.formatNumber({
                                number: _vm.totalAlertCount,
                                format: _vm.numberFormats.integer,
                              })
                            ) +
                            "\n          " +
                            _vm._s(
                              _vm.$tc("alerts.alerts", _vm.totalAlertCount)
                            ) +
                            "\n        "
                        ),
                      ]),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    "div",
                    { staticClass: "pr-3" },
                    [
                      _vm.alertFilter && _vm.onGridViewPages
                        ? _vm._m(1)
                        : _vm._e(),
                      _vm._v(" "),
                      !_vm.alertsOpen && _vm.totalAlertCount
                        ? _vm._m(2)
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.alertsOpen && _vm.totalAlertCount
                        ? _vm._m(3)
                        : _vm._e(),
                    ],
                    1
                  ),
                ]
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.alertsOpen,
        callback: function ($$v) {
          _vm.alertsOpen = $$v
        },
        expression: "alertsOpen",
      },
    },
    [
      _vm._v(" "),
      _c(
        VList,
        { staticClass: "alerts-list", attrs: { dense: "" } },
        [
          _vm._l(_vm.alertCountsAll, function (alertCount, alertType) {
            return [
              _c(
                "div",
                { key: alertType },
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, { staticClass: "pb-0" }, [
                                _vm._v(_vm._s(_vm.getAlertText(alertType))),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                {
                                  staticClass:
                                    "text-right pb-0 d-flex justify-end",
                                },
                                [
                                  _c(
                                    "span",
                                    {
                                      class:
                                        alertType !== "all" &&
                                        _vm.onGridViewPages
                                          ? "my-1 mx-5"
                                          : "",
                                    },
                                    [_vm._v(_vm._s(alertCount))]
                                  ),
                                  _vm._v(" "),
                                  alertType !== "all" && _vm.onGridViewPages
                                    ? _c(
                                        VIcon,
                                        {
                                          staticClass: "my-0",
                                          attrs: {
                                            color:
                                              _vm.getFilterColour(alertType),
                                            size: "2rem",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.setAlertFilterSelection(
                                                alertType
                                              )
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "\n                  filter_list\n                "
                                          ),
                                        ]
                                      )
                                    : _vm._e(),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              ),
            ]
          }),
          _vm._v(" "),
          _vm.productExistsWithIncompleteHierarchy && _vm.canSeeOnDemandAlerts
            ? _c(
                "div",
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "alerts.pleaseReviewHierarchyInformation"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "text-right pb-0 pl-0 pt-2" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        height: "2rem",
                                        loading: _vm.downloadingUnassignedItems,
                                        color: _vm.buttonColor,
                                        depressed: "",
                                      },
                                      on: {
                                        click:
                                          _vm.downloadProductsWithMissingPgsOrAgs,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("actions.download"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productExistsWithUnassignedPricingGroupAttribute &&
          _vm.canSeeOnDemandAlerts
            ? _c(
                "div",
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "alerts.productsExistWithMissingPricingGroupAttributesValues"
                                    )
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "text-right pb-0 pl-0 pt-2" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        height: "2rem",
                                        loading:
                                          _vm.downloadingProductsWithUnassignedPricingGroupAttributes,
                                        color: _vm.buttonColor,
                                        depressed: "",
                                      },
                                      on: {
                                        click:
                                          _vm.downloadProductsWithUnassignedPGAttributes,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("actions.download"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productExistsWithMissingEngineInputs && _vm.canSeeOnDemandAlerts
            ? _c(
                "div",
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("alerts.productMissingEngineInputs")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "text-right pb-0 pl-0 pt-2" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        height: "2rem",
                                        loading:
                                          _vm.downloadingProductsMissingInputs,
                                        color: _vm.buttonColor,
                                        depressed: "",
                                      },
                                      on: {
                                        click:
                                          _vm.downloadProductsMissingEngineInputs,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("actions.download"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.productExistsWithInvalidEngineInputs && _vm.canSeeOnDemandAlerts
            ? _c(
                "div",
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("alerts.productInvalidEngineInputs")
                                  )
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "text-right pb-0 pl-0 pt-2" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        height: "2rem",
                                        loading:
                                          _vm.downloadingProductsInvalidEngineInputs,
                                        color: _vm.buttonColor,
                                        depressed: "",
                                      },
                                      on: {
                                        click:
                                          _vm.downloadProductsInvalidEngineInputs,
                                      },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [
                                          _vm._v(
                                            _vm._s(_vm.$t("actions.download"))
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              )
            : _vm._e(),
          _vm._v(" "),
          _vm.size(_vm.failedRuns) && _vm.canSeeOnDemandAlerts
            ? _c(
                "div",
                [
                  _c(
                    VListItem,
                    [
                      _c(
                        VListItemTitle,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, [
                                _vm._v(
                                  _vm._s(_vm.$t("alerts.failedEngineRunsExist"))
                                ),
                              ]),
                              _vm._v(" "),
                              _c(
                                VCol,
                                { staticClass: "text-right pb-0 pl-0 pt-2" },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "download-button",
                                      attrs: {
                                        height: "2rem",
                                        color: _vm.buttonColor,
                                        depressed: "",
                                      },
                                      on: { click: _vm.openEngineRunModal },
                                    },
                                    [
                                      _c(
                                        "span",
                                        { staticClass: "button-text" },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.$t("alerts.seeFailedRunInfo")
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, { staticClass: "alerts-divider" }),
                ],
                1
              )
            : _vm._e(),
        ],
        2
      ),
      _vm._v(" "),
      _c("failed-runs-modal", {
        attrs: { "show-dialog": _vm.showEngineRunModal, runs: _vm.failedRuns },
        on: { closeDialog: _vm.closeEngineRunModal },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "alert-icon", attrs: { color: "white" } },
      [_vm._v("\n          warning\n        ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      {
        staticClass: "pr-4",
        attrs: { color: "white", size: "2rem" },
        on: {
          click: function ($event) {
            $event.stopPropagation()
            return _vm.clearFilter.apply(null, arguments)
          },
        },
      },
      [_vm._v("\n          mdi-filter-variant-remove\n        ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "font-weight-bold", attrs: { color: "white" } },
      [_vm._v("\n          expand_more\n        ")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "font-weight-bold", attrs: { color: "white" } },
      [_vm._v("\n          expand_less\n        ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }