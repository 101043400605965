import HomePage from './home.vue';

const routes = [
  {
    path: '/home',
    name: 'home',
    id: 'home',
    component: HomePage,
    index: 0,
    omitFromNav: true,
    meta: {
      title: 'tabText.home',
    },
  },
];

export default routes;
