import { VBtn } from 'vuetify/lib/components/VBtn';
import { VBtnToggle } from 'vuetify/lib/components/VBtnToggle';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "feature-toggle",
    { attrs: { toggle: _vm.includeNormWeight } },
    [
      _c(
        VBtnToggle,
        {
          attrs: {
            mandatory: "",
            dense: "",
            value: _vm.selectedPriceType,
            depressed: "",
          },
        },
        [
          _c(
            VBtn,
            {
              staticClass: "regular-price-button",
              attrs: {
                small: "",
                value: _vm.priceTypes.regular,
                depressed: "",
              },
              on: {
                click: function ($event) {
                  return _vm.changePriceType(_vm.priceTypes.regular)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("gridView.priceToggles.regularPrice")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              staticClass: "uom-price-button",
              attrs: { small: "", value: _vm.priceTypes.uom, depressed: "" },
              on: {
                click: function ($event) {
                  return _vm.changePriceType(_vm.priceTypes.uom)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("gridView.priceToggles.pricePerContentUnit")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VBtn,
            {
              attrs: {
                small: "",
                value: _vm.priceTypes.normWeight,
                depressed: "",
              },
              on: {
                click: function ($event) {
                  return _vm.changePriceType(_vm.priceTypes.normWeight)
                },
              },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("gridView.priceToggles.pricePerNormWeight")) +
                  "\n    "
              ),
            ]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }