import axios from 'axios';
import to from 'await-to-js';
import downloadXlsxFile from '../utils/download-xlsx-file';
import { formatFilters } from '../utils/filters';

const getInitialState = () => {
  return {
    productExistsWithMissingEngineInputs: false,
    productExistsWithInvalidEngineInputs: false,
    downloadingProductsMissingInputs: false,
    downloadingProductsInvalidInputs: false,
    downloading: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setProductExistsWithMissingEngineInputs(state, productMissingEngineInputs) {
      state.productExistsWithMissingEngineInputs = productMissingEngineInputs;
    },

    setProductExistsWithInvalidEngineInputs(state, productsWithInvalidEngineInputs) {
      state.productExistsWithInvalidEngineInputs = productsWithInvalidEngineInputs;
    },

    setDownloadingProductsMissingInputs(state, downloading) {
      state.downloadingProductsMissingInputs = downloading;
    },

    setDownloadingProductsInvalidInputs(state, downloading) {
      state.downloadingProductsInvalidInputs = downloading;
    },

    setDownloadingEngineInputs(state, downloading) {
      state.downloading = downloading;
    },
  },

  actions: {
    async downloadItems(
      { rootState, commit },
      { translationMap = {}, columnFormatters = {}, where = [], pick = [] }
    ) {
      commit('setDownloadingEngineInputs', true);
      const params = {
        formatForExport: true,
        translationMap,
        where: formatFilters({ where, rootState }),
        pick,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}/inputs`, { params })
      );
      commit('setDownloadingEngineInputs', false);
      if (err) throw new Error(err.message);
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'economic_engine_inputs.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
    },

    resetState({ commit }) {
      commit('resetState');
    },

    async doesProductExistWithMissingEngineInputs({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/workpackage-product/workpackage/${workpackageId}/missingEngineInputs`)
      );

      if (err) {
        throw new Error(err.message);
      }
      commit('setProductExistsWithMissingEngineInputs', response.data);
    },

    async doesProductExistWithInvalidEngineInputs({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(`/api/workpackage-product/workpackage/${workpackageId}/invalidEngineInputs`)
      );

      if (err) {
        throw new Error(err.message);
      }
      commit('setProductExistsWithInvalidEngineInputs', response.data);
    },

    async downloadProductsWithMissingEngineInputs(
      { rootState, commit },
      { translationMap = {}, columnFormatters = {} }
    ) {
      commit('setDownloadingProductsMissingInputs', true);
      const params = {
        translationMap,
        pick: [
          'article.productKeyDisplay',
          'article.productDescription',
          'mandatoryEngineInputs',
          'toolStoreGroupKey',
        ],
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/productsMissingEngineInputs`,
          { params }
        )
      );
      if (err) throw new Error(err.message);
      const { rowsPerFile } = rootState.clientConfig.exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'products_missing_economic_engine_inputs.xlsx', {
        rowsPerFile,
        columnFormatters,
      });
      commit('setDownloadingProductsMissingInputs', false);
    },

    async downloadProductsWithInvalidEngineInputs(
      { rootState, commit },
      { translationMap = {}, columnFormatters = {} }
    ) {
      commit('setDownloadingProductsInvalidInputs', true);
      const params = {
        translationMap,
        pick: [
          'article.productKeyDisplay',
          'article.productDescription',
          'mandatoryEngineInputs',
          'attributes', // attributes may affect engine run too. e.g. attributes.vatRate: 0 mandatoryEngineInputs.targetMargin: 100
          'toolStoreGroupKey',
        ],
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, response] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/productsInvalidEngineInputs`,
          { params }
        )
      );
      if (err) throw new Error(err.message);
      const { rowsPerFile } = rootState.clientConfig.exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'products_with_invalid_economic_engine_inputs.xlsx', {
        rowsPerFile,
        columnFormatters,
      });
      commit('setDownloadingProductsInvalidInputs', false);
    },
  },
};

export default store;
