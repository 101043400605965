import { mapValues, keyBy } from 'lodash';

const mixin = {
  methods: {
    formatLocalisedDate(formattableDate) {
      // allow for missing dates in e.g. intention cost
      if (!formattableDate) return '';
      const clientConfig = this.$store.state.clientConfig;
      const dateFormats = clientConfig.dateFormats;
      const separator = dateFormats.dateSeparator;
      const clientOverrideDateFormat = clientConfig.i18nconfig.overrideDateFormat || 'en';
      const dtf = new Intl.DateTimeFormat(clientOverrideDateFormat, {
        year: dateFormats.year,
        month: dateFormats.month,
        day: dateFormats.day,
      });
      const datePartsMapping = mapValues(
        keyBy(dtf.formatToParts(new Date(formattableDate)), 'type'),
        'value'
      );
      const dateParts = dateFormats.dateOrder.split('::').map(o => datePartsMapping[o]);
      return dateParts.join(separator);
    },
  },
};

export default mixin;
