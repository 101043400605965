import { VCard } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "architecture-tile pl-2" },
    [
      _c(
        VFlex,
        { staticClass: "pr-1", attrs: { xs11: "", grow: "" } },
        [
          _c(
            VCard,
            { staticClass: "my-1" },
            [
              _c(
                VCardTitle,
                { staticClass: "px-2 py-0" },
                [
                  _c(
                    VLayout,
                    [
                      _c(
                        VFlex,
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            VInput,
                            {
                              staticClass: "mt-2",
                              attrs: {
                                "background-color": "grey lighten-3",
                                disabled: _vm.disabled,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "px-1 text-truncate" },
                                [
                                  _vm._v(
                                    _vm._s(_vm.attribute.displayDescription)
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(VFlex, { attrs: { xs5: "" } }),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        { staticClass: "pt-2", attrs: { xs1: "" } },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "delete-icon float-right",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.emitRemoveFromSubGroupSplittingAttributes(
                                    _vm.attribute.attributeKey
                                  )
                                },
                              },
                            },
                            [_vm._v("\n              delete\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VFlex,
        { staticClass: "draggable-attribute-icon", attrs: { xs1: "" } },
        [
          _c(
            VIcon,
            {
              staticClass: "drag-icon pt-3",
              attrs: { disabled: _vm.disabled, "text-xs-right": "" },
            },
            [_vm._v("open_with")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }