import XLSX from 'xlsx';
import moment from 'moment';

function downloadCsvFile(data, filename) {
  const blob = new Blob([data], { type: 'text/csv' });

  const link = document.createElement('a');
  link.href = window.URL.createObjectURL(blob);
  link.download = filename || 'data.csv';
  link.click();
}

function downloadCsvWholesaleMarginSplits(data, fileIndex, { csvDelimiter }) {
  const timestamp = moment().format('YYYYMMDDHHMMSS');
  const filename = `GTI038_${timestamp}_${fileIndex}.csv`;
  const ws = XLSX.utils.aoa_to_sheet(data);
  // FS = "Field Separator" delimiter between fields
  const wsData = XLSX.utils.sheet_to_csv(ws, { FS: csvDelimiter });

  // Remove the trailing delimeters on first row after the number (SAP uses this format)
  const indexOfFirstDelimeter = wsData.indexOf(csvDelimiter);
  const indexOfSecondDelimeter = wsData.indexOf(csvDelimiter, indexOfFirstDelimeter + 1);
  const modifiedWsData =
    wsData.substring(0, indexOfSecondDelimeter) + wsData.substring(wsData.indexOf('\n'));

  downloadCsvFile(modifiedWsData, filename);
}

export default {
  downloadCsvWholesaleMarginSplits,
  downloadCsvFile,
};
