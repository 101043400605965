<template>
  <v-row class="architecture-driver pl-2">
    <v-flex xs11 grow class="pr-1">
      <v-card class="my-1">
        <v-card-title class="px-2 py-0">
          <v-layout>
            <v-flex xs6>
              <v-input background-color="grey lighten-3" class="mt-2" :disabled="disabled">
                <span class="px-1 text-truncate">{{ driverAttribute.displayDescription }}</span>
              </v-input>
            </v-flex>
            <v-flex xs5 class="pl-2">
              <v-select
                v-model="driverAttribute.driverCalculationType"
                :items="relevantArchitectureDriversRules"
                :disabled="disabled"
                item-text="label"
                item-value="key"
                height="2.5rem"
                class="py-0 architecture-drivers-rule"
                @change="setDriverType"
              >
                <template v-slot:item="props">
                  <span class="item-text">{{ props.item.label }}</span>
                </template>
              </v-select>
            </v-flex>
            <v-flex xs1 class="pt-2">
              <v-icon
                class="delete-icon float-right"
                :disabled="disabled"
                @click="emitRemoveFromAttributesDrivers(driverAttribute.attributeKey)"
              >
                delete
              </v-icon>
            </v-flex>
          </v-layout>
        </v-card-title>

        <v-card-text
          v-if="driverAttribute.driverCalculationType === calculationType.categorical"
          class="driver-container"
        >
          <v-row>
            <v-col
              v-for="attribute in attributes"
              :key="attribute.attributeValue"
              cols="6"
              class="pt-0 pb-0"
            >
              <v-row>
                <v-col cols="8" class="architecture-value pt-2 pb-0 mt-0 mb-0">
                  {{
                    getFormattedAttributeValue(attribute.attributeValue)
                      | truncate(truncationLength)
                  }}
                </v-col>
                <v-col cols="4" class="pt-0 pb-0 mt-0 mb-0">
                  <v-text-field
                    v-model.number="attribute.impact"
                    step="0.1"
                    type="number"
                    class="highlight"
                    :rules="rules.impact"
                    :disabled="disabled"
                    @change="updateCategoricalArchitectureDriver($event, attribute.attributeValue)"
                  />
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-text
          v-if="driverAttribute.driverCalculationType === calculationType.linear"
          class="driver-container"
        >
          <v-layout justify-space-between align-baseline>
            <span class="font-weight-bold">
              {{ $t('settings.setArchitectureRules.linearParameters.anchor') }}
            </span>
            <v-flex xs1>
              <v-text-field
                v-model.number="linearImpact.anchor"
                step="0.1"
                type="number"
                class="highlight"
                :rules="rules.impact"
                :disabled="disabled"
                @change="updateLinearArchitectureDriver"
              />
            </v-flex>
            <span> (per unit) </span>

            <span class="font-weight-bold">
              {{ $t('settings.setArchitectureRules.linearParameters.reference') }}
            </span>
            <v-flex xs1>
              <v-text-field
                v-model.number="linearImpact.reference"
                step="0.1"
                type="number"
                class="highlight"
                :rules="rules.impact"
                :disabled="disabled"
                @change="updateLinearArchitectureDriver"
              />
            </v-flex>
            <span> (per unit) </span>

            <span class="font-weight-bold">
              {{ $t('settings.setArchitectureRules.linearParameters.discount') }}
            </span>
            <v-flex xs1>
              <v-text-field
                v-model.number="linearImpact.discount"
                step="0.1"
                type="number"
                class="highlight"
                :rules="rules.discount"
                :disabled="disabled"
                @change="updateLinearArchitectureDriver"
              />
            </v-flex>
            <span> % </span>
          </v-layout>
        </v-card-text>
      </v-card>
    </v-flex>
    <v-flex xs1 class="draggable-attribute-icon">
      <v-icon class="drag-icon pt-3" :disabled="disabled" text-xs-right>open_with</v-icon>
    </v-flex>
  </v-row>
</template>

<script>
import { isNull, omit, map, some, cloneDeep, take, isNumber, isNaN } from 'lodash';
import { mapActions } from 'vuex';
import DataTypes from '@enums/data-types';
import numberFormats from '@enums/number-formats';
import calculationType from '@enums/driver-calculation-type';

export default {
  props: {
    driverAttribute: {
      type: Object,
      required: true,
    },
    disabled: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      truncationLength: 20,
      calculationType,
      rules: {
        impact: [this.valueRequired, this.valueIsMoreThanZero],
        discount: [this.valueRequired, this.valueLessThanOrEqual100],
      },
      attributes: this.driverAttribute.categoricalImpact || [],
      linearImpact: cloneDeep(this.driverAttribute.linearImpact),
    };
  },
  computed: {
    relevantArchitectureDriversRules() {
      const architectureDriversRules = [
        {
          key: calculationType.categorical,
          label: this.$t('settings.setArchitectureRules.architectureDriversRules.categorical'),
        },
        {
          key: calculationType.linear,
          label: this.$t('settings.setArchitectureRules.architectureDriversRules.linear'),
        },
      ];
      // If a driver is based on a numeric attribute or is already set as linear
      // then we provide both options
      return this.driverAttribute.dataType === DataTypes.number ||
        this.driverAttribute.driverCalculationType === calculationType.linear
        ? architectureDriversRules
        : take(architectureDriversRules);
    },
  },
  methods: {
    ...mapActions('attributes', ['fetchAttributeValuesForProducts']),

    emitUpdatedDriver() {
      this.driverAttribute.categoricalImpact = this.attributes;
      this.driverAttribute.linearImpact = cloneDeep(this.linearImpact);
      this.$emit('updateArchitectureDriver', omit(this.driverAttribute, ['displayDescription']));
    },

    async setDriverType() {
      if (this.driverAttribute.driverCalculationType === calculationType.linear) {
        this.linearImpact = {
          reference: 2,
          anchor: 1,
          discount: 10,
        };
        this.attributes = [];
      }
      if (this.driverAttribute.driverCalculationType === calculationType.categorical) {
        this.attributes = await this.setDefaultCategoricalImpacts();
        this.linearImpact = {};
      }
      // add updated driver to updates in architecture-drivers-ruleset
      this.emitUpdatedDriver();
    },

    // Set an impact factor of 1 for each distinct value
    async setDefaultCategoricalImpacts() {
      const attributes = await this.fetchAttributeValuesForProducts({
        attributeKey: this.driverAttribute.attributeKey,
      });

      return map(attributes, attribute => ({ attributeValue: attribute, impact: 1 }));
    },

    emitRemoveFromAttributesDrivers(attributeKey) {
      this.$emit('removeFromAttributesDrivers', { attributeKey });
    },

    valueRequired(v) {
      return !!v || v === 0 || this.$t('settings.parameters.errors.fieldRequired');
    },

    valueIsMoreThanZero(v) {
      return v > 0 || this.$t('settings.parameters.errors.positiveValidation');
    },

    valueLessThanOrEqual100(v) {
      return v <= 100 || this.$t('settings.parameters.errors.lessThan100');
    },

    updateCategoricalArchitectureDriver(newValue, attributeValue) {
      // if validation fails, attributeValue will be null
      if (attributeValue && newValue && newValue !== '' && newValue > 0) {
        this.emitUpdatedDriver();
      }
    },

    updateLinearArchitectureDriver() {
      const { reference, anchor, discount } = this.linearImpact;
      if (some([reference, anchor, discount], val => isNull(val) || val === '')) {
        // input validation failed: do not emit updates
        return;
      }
      if (!(reference > 0 && anchor > 0)) return;
      if (discount > 100) return;
      this.emitUpdatedDriver();
    },

    getFormattedAttributeValue(attributeValue) {
      const number = +attributeValue;
      return attributeValue && isNumber(number) && !isNaN(number)
        ? this.formatNumber({ number, format: numberFormats.decimal })
        : attributeValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.architecture-value {
  font-size: 1.2rem;
  color: $text-color;
}

.v-text-field {
  padding-top: 0;
}

.driver-container {
  max-height: 25rem;
  overflow-y: scroll;
  overflow-x: hidden;
}

.v-icon {
  font-size: 1.6rem;
}
</style>

<style lang="scss">
.architecture-driver {
  .v-messages__message {
    padding-top: 0.5rem;
  }

  .v-input__slot {
    margin: 0;
  }

  .v-input input {
    height: 1.7rem;
    padding: 0.1rem 0.5rem;
  }
}
</style>
