import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
      staticClass: "input-screen-page-wrapper",
    },
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            [
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.displayAddCompetitorButton } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: { color: "primary", small: "", depressed: "" },
                      on: {
                        click: function ($event) {
                          return _vm.openCompetitorCreateUpdateDialog(
                            _vm.dialogModes.create
                          )
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.add")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.displayCompetitorUpload } },
                [
                  _c("competitor-upload", {
                    staticClass: "pb-2",
                    attrs: {
                      params: _vm.getBaseParams(),
                      "enabled-value-translations":
                        _vm.enabledValueTranslations,
                      "is-master-workpackage": _vm.isSelectedWorkpackageMaster,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(VTextField, {
                staticClass: "search-field py-0",
                attrs: {
                  value: _vm.competitorMatchesSearch,
                  "append-icon": "search",
                  label: _vm.$t("actions.search"),
                  "loading-text": _vm.$t("general.loadingMessage"),
                  "single-line": "",
                },
                on: { input: _vm.debounceSearchUpdate },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        "feature-toggle",
        { attrs: { toggle: _vm.displayHierarchyAndFilter } },
        [
          _c("attribute-filter-panel", {
            attrs: {
              "title-localisation": "attributes.filters.filterByAttributes",
              "filter-count-localisation": "attributes.filters.numApplied",
              "enable-hierarchy-filters": "",
              "filter-rules": _vm.retailAttributesFilter,
            },
            on: { attributeFilterChange: _vm.setFilterRules },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VDataTable,
        {
          staticClass:
            "pricing-table elevation-1 competitor-matches-table striped-table",
          attrs: {
            items: _vm.competitorMatches,
            loading: _vm.loading,
            headers: _vm.headers,
            height: _vm.dataTableHeight,
            "loading-text": _vm.$t("general.loadingMessage"),
            "disable-pagination": "",
            "disable-sort": "",
            dense: "",
            "fixed-header": "",
            "hide-default-header": "",
            "hide-default-footer": "",
            "item-key": "productKey",
          },
          nativeOn: {
            mousewheel: function ($event) {
              return _vm.preventPageNavigationOnHorizontalScroll(
                $event,
                ".v-data-table__wrapper"
              )
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function () {
                return [
                  _c("thead", { staticClass: "v-data-table-header" }, [
                    _vm.isUseZones
                      ? _c("tr", { staticClass: "competitor-row" }, [
                          _c("th", {
                            staticClass: "one-fixed border-right",
                            attrs: {
                              colspan:
                                _vm.stickyColumnsNumberBeforeCompetitorTabs,
                            },
                          }),
                          _vm._v(" "),
                          !!_vm.hierarchyHeaders.length
                            ? _c("th", {
                                staticClass: "border-right",
                                attrs: { colspan: _vm.hierarchyHeaders.length },
                              })
                            : _vm._e(),
                          _vm._v(" "),
                          _c(
                            "th",
                            {
                              staticClass: "box-shadow",
                              attrs: { colspan: _vm.getCompetitors.length * 2 },
                            },
                            [
                              _c(
                                "div",
                                { staticClass: "competitor-wrapper" },
                                _vm._l(_vm.toggleTypes, function (type) {
                                  return _c(
                                    VBtn,
                                    {
                                      key: type,
                                      class: [
                                        "competitor-btn",
                                        _vm.isActiveCompetitorToggle(type)
                                          ? "active"
                                          : "",
                                      ],
                                      attrs: {
                                        disabled:
                                          _vm.isCompetitorToggleDisabled(type),
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.setActiveCompetitorToggle(
                                            type
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `competitorMatches.tabs.${type}`
                                          )
                                        )
                                      ),
                                    ]
                                  )
                                }),
                                1
                              ),
                            ]
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _c(
                      "tr",
                      [
                        _c("th", {
                          class: _vm.fixedColumnsClass,
                          attrs: { colspan: "1" },
                        }),
                        _vm._v(" "),
                        _vm.toolStoreGroupColumn
                          ? _c("th", {
                              class: _vm.fixedColumnsClass,
                              attrs: { colspan: "1" },
                            })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("th", {
                          class: _vm.fixedColumnsClass,
                          attrs: { colspan: "1" },
                        }),
                        _vm._v(" "),
                        _c("th", {
                          staticClass: "border-right",
                          class: _vm.fixedColumnsClass,
                          attrs: { colspan: "1" },
                        }),
                        _vm._v(" "),
                        _c(
                          "th",
                          {
                            class: [
                              _vm.showHierarchy ? "" : "hidden-header",
                              "border-right",
                            ],
                            attrs: { colspan: "3" },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t("attributes.editor.headers.hierarchy")
                                ) +
                                "\n          "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _vm._l(_vm.getCompetitors, function (header) {
                          return _c(
                            "th",
                            {
                              key: header.competitorKey,
                              class: [
                                "border-right",
                                !_vm.isActiveCompetitorToggle(
                                  header.competitorType
                                )
                                  ? "hidden-header"
                                  : "",
                              ],
                              attrs: { colspan: "2" },
                            },
                            [
                              _c(
                                "span",
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(
                                        header.competitorDisplayDescription
                                      ) +
                                      "\n              "
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    "feature-toggle",
                                    {
                                      attrs: {
                                        toggle:
                                          _vm.displayCompetitorActionsMenu,
                                      },
                                    },
                                    [
                                      _c(
                                        VMenu,
                                        {
                                          attrs: { bottom: "", "offset-y": "" },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function ({ on }) {
                                                  return [
                                                    header.competitorType ===
                                                    _vm.competitorSources.manual
                                                      ? _c(
                                                          VIcon,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "actions-btn",
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _vm._v(
                                                              "\n                      more_horiz\n                    "
                                                            ),
                                                          ]
                                                        )
                                                      : _vm._e(),
                                                  ]
                                                },
                                              },
                                            ],
                                            null,
                                            true
                                          ),
                                        },
                                        [
                                          _vm._v(" "),
                                          _c(
                                            VList,
                                            [
                                              _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openCompetitorCreateUpdateDialog(
                                                        _vm.dialogModes.edit,
                                                        header
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t("actions.edit")
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                VListItem,
                                                {
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.openDeleteDialog(
                                                        header
                                                      )
                                                    },
                                                  },
                                                },
                                                [
                                                  _c(VListItemTitle, [
                                                    _vm._v(
                                                      "\n                        " +
                                                        _vm._s(
                                                          _vm.$t(
                                                            "actions.delete"
                                                          )
                                                        ) +
                                                        "\n                      "
                                                    ),
                                                  ]),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ]
                          )
                        }),
                      ],
                      2
                    ),
                    _vm._v(" "),
                    _c(
                      "tr",
                      _vm._l(_vm.getHeaders, function (header) {
                        return _c(
                          "th",
                          {
                            key: header.value,
                            class: [
                              ..._vm.getHeaderClasses(header, _vm.pagination),
                              header.class,
                              !header.competitor ||
                              (header.competitor &&
                                _vm.isActiveCompetitorToggle(
                                  header.competitorType
                                ))
                                ? ""
                                : "hidden-header",
                            ],
                            on: {
                              click: function ($event) {
                                return _vm.changeSort(header)
                              },
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t(header.text)) +
                                "\n            "
                            ),
                            _c(
                              "div",
                              [
                                header.sortable
                                  ? _c(
                                      VIcon,
                                      {
                                        staticClass:
                                          "v-data-table-header__icon",
                                        attrs: { size: "1.4rem" },
                                      },
                                      [
                                        _vm._v(
                                          "\n                arrow_upward\n              "
                                        ),
                                      ]
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "tr",
                    { key: props.index },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.item.productKeyDisplay) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.toolStoreGroupColumn
                        ? _c(
                            "td",
                            {
                              staticClass: "text-xs table-cell",
                              class: _vm.fixedColumnsClass,
                            },
                            [
                              _c("tooltipped-truncated-field", {
                                staticClass: "tooltipped-truncated-field",
                                attrs: {
                                  text: props.item.toolStoreGroupDescription,
                                  "truncation-length":
                                    _vm.truncationLengthMedium,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.item.productName) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell border-right",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.formatMetricContainedUnitOfMeasurement(
                                  props.item.productSizeType,
                                  _vm.numberFormats.weight
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c(
                        "feature-toggle",
                        { attrs: { toggle: _vm.displayHierarchyAndFilter } },
                        _vm._l(
                          [
                            _vm.categoryLevel,
                            _vm.pricingGroupLevel,
                            _vm.architectureGroupLevel,
                          ],
                          function (level) {
                            return _c(
                              "td",
                              {
                                key: level,
                                staticClass: "text-xs table-cell",
                                class:
                                  level === _vm.architectureGroupLevel
                                    ? "border-right"
                                    : "",
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      value: _vm.getHierarchyName(
                                        props.item,
                                        level
                                      ),
                                      disabled: _vm.isTooltipDisabled(
                                        _vm.getHierarchyName(props.item, level),
                                        _vm.truncationLengthMedium
                                      ),
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm._f("truncate")(
                                            _vm.getHierarchyName(
                                              props.item,
                                              level
                                            ),
                                            _vm.truncationLengthMedium
                                          )
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          }
                        ),
                        0
                      ),
                      _vm._v(" "),
                      _vm._l(_vm.getCompetitors, function (c, cIndex) {
                        return [
                          _c(
                            "td",
                            {
                              key: c.competitorDescription + "-price",
                              staticClass: "input-cell",
                              class: [
                                "text-xs",
                                "table-cell",
                                !_vm.isActiveCompetitorToggle(c.competitorType)
                                  ? "hidden-cell"
                                  : "",
                              ],
                            },
                            [
                              _c("pricing-edit-text-field", {
                                attrs: {
                                  value: _vm.getCompetitorPrice(
                                    props.item,
                                    props.item.competitorMatches[cIndex]
                                  ),
                                  "previous-value": _vm.getPreviousValue(
                                    props.item.competitorMatches[cIndex]
                                  ),
                                  disabled: !_vm.canEditCompetitorMatches,
                                  "data-type": _vm.dataTypes.number,
                                  "number-format":
                                    _vm.numberFormats.priceFormat,
                                  error:
                                    _vm.updateErrors[
                                      _vm.getProductCompetitorKey(
                                        props.item,
                                        props.item.competitorMatches[cIndex]
                                      )
                                    ],
                                  "cell-class": _vm.getInputEditFieldClass(
                                    props.item,
                                    props.item.competitorMatches[cIndex]
                                  ),
                                  "previous-value-translation-key":
                                    "competitorMatches.editor",
                                  "tooltip-position":
                                    props.index >
                                    _vm.pagination.itemsPerPage / 2
                                      ? "top-left"
                                      : "bottom-left",
                                  "tooltip-value": {
                                    [_vm.$t("tooltip.competitorDescription")]:
                                      props.item.competitorMatches[cIndex]
                                        .competitorProductDescription || "-",
                                    [_vm.$t("tooltip.competitorSize")]:
                                      _vm.formatNumber({
                                        number:
                                          props.item.competitorMatches[cIndex]
                                            .contentValue,
                                        format: _vm.numberFormats.priceFormat,
                                        nullAsDash: true,
                                      }),
                                    [_vm.$t("tooltip.competitorUnitOfMeasure")]:
                                      props.item.competitorMatches[cIndex]
                                        .contentUnitOfMeasure || "-",
                                  },
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.recordChanges(
                                      $event.target.value,
                                      props.item,
                                      props.item.competitorMatches[cIndex]
                                    )
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            "td",
                            {
                              key: c.competitorDescription + "-disabled",
                              class: [
                                "table-cell",
                                "border-right",
                                !_vm.isActiveCompetitorToggle(
                                  c.competitorType
                                ) ||
                                _vm.hideStoreGroupCompetitors(c.competitorType)
                                  ? "hidden-cell"
                                  : "",
                              ],
                            },
                            [
                              _c("input", {
                                attrs: {
                                  type: "checkbox",
                                  disabled: !_vm.canEditCompetitorMatches,
                                },
                                domProps: {
                                  checked: _vm.getCompetitorDisabled(
                                    props.item,
                                    props.item.competitorMatches[cIndex]
                                  ),
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.recordCheckboxChange(
                                      $event.target.checked,
                                      props.item,
                                      props.item.competitorMatches[cIndex]
                                    )
                                  },
                                },
                              }),
                            ]
                          ),
                        ]
                      }),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                VFlex,
                {
                  class: {
                    "input-screen-sticky-table-footer": _vm.showHierarchy,
                  },
                },
                [
                  _c(
                    VRow,
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        VCol,
                        { attrs: { md: "3", "offset-md": "9" } },
                        [
                          _c(VPagination, {
                            attrs: { length: _vm.pages },
                            on: { input: _vm.loadCompetitorMatchData },
                            model: {
                              value: _vm.pagination.page,
                              callback: function ($$v) {
                                _vm.$set(_vm.pagination, "page", $$v)
                              },
                              expression: "pagination.page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    {
                      staticClass: "pr-6 pb-2",
                      attrs: { justify: "end", align: "center" },
                    },
                    [
                      _c(
                        "feature-toggle",
                        {
                          staticClass: "feature-toggle-btn",
                          attrs: { toggle: _vm.displayCompetitorDownload },
                        },
                        [
                          _c(
                            VBtn,
                            {
                              staticClass: "mr-3",
                              attrs: {
                                color: "primary",
                                small: "",
                                loading: _vm.downloadingItems,
                                depressed: "",
                              },
                              on: { click: _vm.downloadItems },
                            },
                            [
                              _vm._v(
                                "\n              " +
                                  _vm._s(_vm.$t("actions.download")) +
                                  "\n              "
                              ),
                              _c(VIcon, { attrs: { small: "" } }, [
                                _vm._v("$export"),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", { staticClass: "mr-3 btn-divider" }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "save",
                          attrs: {
                            disabled:
                              !_vm.canEditCompetitorMatches ||
                              !_vm.hasUnsavedUpdates ||
                              _vm.hasInvalidUpdates,
                            color: "success",
                            loading: _vm.loading,
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.persistUpdates },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("competitor-create-update-dialog", {
        key: _vm.competitorCreateDialogKey,
        attrs: {
          "is-open": _vm.isCompetitorCreateUpdateDialogOpen,
          mode: _vm.mode,
          competitor: _vm.competitorToEdit,
        },
        on: { closeDialog: _vm.closeCompetitorCreateUpdateDialog },
      }),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
          "disable-ok-button":
            _vm.checkingIfCompetitorCanBeDeleted || !_vm.competitorCanBeDeleted,
        },
        on: {
          onOk: function ($event) {
            return _vm.deleteCompetitorHandler(true)
          },
          onCancel: function ($event) {
            return _vm.deleteCompetitorHandler(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(_vm._s(_vm.$t("dialogs.deleteCompetitorDialog.header"))),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.checkingIfCompetitorCanBeDeleted &&
                _vm.competitorCanBeDeleted
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.$t("dialogs.deleteCompetitorDialog.body")
                          ) +
                          "\n        "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.competitorToDelete
                              ? _vm.competitorToDelete
                                  .competitorDisplayDescription
                              : ""
                          )
                        ),
                      ]),
                    ])
                  : _vm.checkingIfCompetitorCanBeDeleted &&
                    _vm.competitorUses.length === 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "competitorMatches.deleteMessages.checkingIfTheCompetitor"
                              )
                            ) +
                            "\n        "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.competitorToDelete
                                ? _vm.competitorToDelete
                                    .competitorDisplayDescription
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "competitorMatches.deleteMessages.canBeDeleted"
                              )
                            ) +
                            "\n        "
                        ),
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            color: "primary",
                            size: "20",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "pb-1" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.competitorToDelete
                                  ? _vm.competitorToDelete
                                      .competitorDisplayDescription
                                  : ""
                              )
                            ),
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "competitorMatches.deleteMessages.competitorCannotBeDeleted"
                                )
                              ) +
                              ":\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.competitorUses,
                          function (competitorUse, index) {
                            return _c("div", { key: index }, [
                              _c(
                                "div",
                                { staticClass: "pl-2" },
                                [
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "pb-1",
                                      attrs: {
                                        size: "1.7rem",
                                        color: _vm.alertsIconColor,
                                      },
                                    },
                                    [_vm._v("warning")]
                                  ),
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm.$t(
                                          `competitorMatches.deleteMessages.${competitorUse.translationKey}`,
                                          competitorUse.translationParams
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ],
                                1
                              ),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }