import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(
    "div",
    { staticClass: "container" },
    [
      _c(
        VBtn,
        {
          staticClass: "grid-btn",
          attrs: {
            secondary: "",
            small: "",
            disabled: _vm.dataLoading || !_vm.editsExist,
            depressed: "",
          },
          on: { click: _vm.discardChanges },
        },
        [_vm._v(_vm._s(_vm.$t("inputs.cancel")))]
      ),
      _vm._v(" "),
      _c(
        VBtn,
        {
          attrs: {
            disabled: !_vm.saveAllowed,
            small: "",
            color: "primary",
            depressed: "",
          },
          on: { click: _vm.handleSave },
        },
        [
          _vm._v("\n    " + _vm._s(_vm.$t("actions.save")) + "\n    "),
          _c(VIcon, { attrs: { small: "" } }, [_vm._v("$import")]),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }