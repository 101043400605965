'use strict';

module.exports = {
  economic: 'economic',
  competitor: 'competitor',
  architecture: 'architecture',
  combined: 'combined',
  pricePointing: 'pricePointing',
  storeGroup: 'storeGroup',
};
