<template>
  <div class="expansion margin-splits-expansion">
    <div class="margin-splits-expansion-header">{{ $tc('wholesale.split', 2) }}</div>
    <v-text-field
      class="search-field px-2"
      :value="marginSplitsSearch"
      append-icon="search"
      :label="$t('actions.search')"
      single-line
      flat
      :disabled="wholesaleMarginSplitsLoading"
      @input="debounceSearchUpdate"
    />
    <v-list dense class="py-0 margin-splits-expansion-list">
      <template v-if="wholesaleMarginSplitsLoading">
        <basic-spinner class="spinner-wrapper" />
      </template>
      <template v-else-if="noSearchResult">
        <v-list-item class="splits-list-item no-results">
          <v-list-item-content>
            <v-list-item-title>
              {{ $t('product.noResults', { search: marginSplitsSearch }) }}
            </v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </template>
      <template v-else>
        <div
          v-for="{ gridDescription, gridId } in filteredGrids"
          :key="gridId"
          class="list-item-div"
        >
          <v-list-item class="splits-list-item" :class="{ clicked: isGridHighlighted(gridId) }">
            <v-list-item-content>
              <v-list-item-title>
                <tooltipped-truncated-field
                  :text="gridDescription"
                  :truncation-length="truncationLength"
                  tooltip-position="left"
                  class="tooltipped-truncated-field"
                  :class="highlightLoading ? 'disabled' : ''"
                  @click="highlightLoading ? () => {} : setGridIdToHighlight(gridId)"
                />
              </v-list-item-title>
              <v-progress-linear
                v-if="highlightLoading && gridIdToHighlight === gridId"
                indeterminate
              />
            </v-list-item-content>
            <v-list-item-avatar class="splits-list-item-avatar">
              <i
                class="mdi mdi-pencil-outline splits-list-item-icon clickable"
                @click="openEditWholesaleGridDialog(gridId, gridDescription)"
              />
            </v-list-item-avatar>
          </v-list-item>
        </div>
      </template>
    </v-list>
    <wholesale-grid-dialog
      v-if="selectedGrid"
      :grid-details="selectedGrid"
      :is-open="isEditWholesaleGridDialogOpened"
      @closeDialog="closeWholesaleGridDialog"
    />
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { debounce, reduce, includes, sortBy, toLower, isEmpty } from 'lodash';
import clientConfig from '@sharedModules/config/client';

export default {
  data() {
    return {
      isExpanded: false,
      marginSplitsSearch: '',
      selectedGrid: null,
      isEditWholesaleGridDialogOpened: false,
      truncationLength: 28,
    };
  },
  computed: {
    ...mapState('wholesale', [
      'gridIdDescriptionMap',
      'gridIdToHighlight',
      'highlightLoading',
      'wholesaleMarginSplitsLoading',
    ]),

    filteredGrids() {
      const filteredGrids = reduce(
        this.gridIdDescriptionMap,
        (acc, gridDescription, gridId) => {
          if (includes(toLower(gridDescription), toLower(this.marginSplitsSearch)))
            acc.push({ gridDescription, gridId });
          return acc;
        },
        []
      );
      return sortBy(filteredGrids, grid => grid.gridDescription);
    },
    noSearchResult() {
      return isEmpty(this.filteredGrids);
    },
  },
  methods: {
    ...mapActions('wholesale', ['setGridIdToHighlight']),
    ...mapActions('wholesaleGrid', ['setSelectedWholesaleGrid']),

    debounceSearchUpdate: debounce(function(value) {
      this.marginSplitsSearch = value;
    }, clientConfig.inputDebounceValue),

    openEditWholesaleGridDialog(gridId, gridDescription) {
      this.selectedGrid = { gridId, gridDescription };
      this.setSelectedWholesaleGrid({
        selectedWholesaleGrid: {
          gridId,
          gridDescription,
        },
      });
      this.isEditWholesaleGridDialogOpened = true;
    },

    closeWholesaleGridDialog() {
      this.selectedGrid = null;
      this.isEditWholesaleGridDialogOpened = false;
    },

    isGridHighlighted(gridId) {
      return gridId === this.gridIdToHighlight;
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables';
.margin-splits-expansion {
  [custom-title-msg]:after {
    right: 95% !important;
  }

  .tooltipped-truncated-field {
    &.disabled {
      cursor: unset;
      &:hover {
        background-color: transparent !important;
      }
    }
    padding: 5px;
    position: unset;
    cursor: pointer;
    &:hover {
      background-color: $grid-view-hover-color !important;
    }
  }
}

.search-field {
  .v-input__control .v-input__slot {
    background-color: $pricing-white !important;
  }
  input {
    padding-left: 2px;
  }
}
</style>

<style lang="scss" scoped>
@import '@style/base/_variables';
$row-height: 2rem;

.expansion {
  width: 100%;
  background-color: $settingsTableRowColor;

  .margin-splits-expansion-header {
    font-weight: bold;
    font-size: 1.2rem;
    padding: 1rem 1rem 0 1rem;
  }

  .margin-splits-expansion-list {
    height: 50rem;
    overflow-y: scroll;
    position: relative;
  }

  .search-field {
    max-width: unset;
  }

  .no-results {
    text-align: center;
  }

  .list-item-div:nth-child(odd) .splits-list-item {
    background-color: $grid-view-dense-row-color;
  }

  .splits-list-item {
    min-height: $row-height;
    $icon-size: 2rem;

    &-icon {
      font-size: $icon-size;
      color: $icon-colour;
    }

    &-avatar.v-list-item__avatar {
      &:first-child {
        margin-right: 1.6rem;
      }

      // !important is required to override style
      height: $row-height !important;
      min-width: $icon-size !important;
      width: $icon-size !important;
    }
  }
  .splits-list-item.clicked {
    background-color: $grid-view-hover-color !important;
  }
}
</style>
