<template>
  <aside
    class="main-sidebar elevation-2"
    :class="`main-sidebar--${isExpanded ? 'expanded' : 'collapsed'}`"
  >
    <div class="main-sidebar__nav clickable" @click="toggleSidebar()">
      <div class="sidebar-label">
        {{ $t('wholesale.listOfSplits') }}
        <i
          aria-hidden="true"
          :class="isExpanded ? 'mdi-chevron-down' : 'mdi-chevron-up'"
          class="mdi expand-icon"
        />
      </div>
    </div>
    <div v-show="isExpanded" class="main-sidebar__expanded-content">
      <sidebar-margin-splits />
    </div>
  </aside>
</template>

<script>
export default {
  data() {
    return {
      isExpanded: false,
    };
  },
  methods: {
    toggleSidebar() {
      this.isExpanded = !this.isExpanded;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

@mixin flex-column($vertical-alignment: flex-start) {
  display: flex;
  flex-direction: column;
  justify-content: $vertical-alignment;
}

$expand-icon-size: 2rem;

.sidebar-label {
  font-size: 1.2rem;
  font-weight: bold;
  text-orientation: sideways-right;
  text-transform: uppercase;
  writing-mode: vertical-rl;
  transform: rotate(-180deg);
  background-color: $tab-border-blue;
  padding-left: 0.2rem;
  color: $icon-colour;
  padding: 1rem 0.1rem 0.1rem 0.1rem;
  border-radius: 4px;
}

.expand-icon {
  color: $icon-colour;
  font-size: $expand-icon-size;
}

.main-sidebar {
  position: absolute;
  top: 0;
  right: 0;
  z-index: $wholesale-sidebar-zindex;
  transition: all 0.5s;
  box-shadow: unset !important;

  display: flex;
  flex-direction: row;

  &--collapsed {
    width: $wholesale-sidebar-width;
  }

  &__expanded-content {
    @include flex-column;
    width: 25rem;
    background-color: $pricing-white;
    box-shadow: -8px 8px 3px -4px $settingsTableRowColor;
  }
}
</style>
