<template>
  <v-menu
    v-model="open"
    top
    :close-on-content-click="false"
    max-height="15rem"
    max-width="30rem"
    min-width="30rem"
    offset-y
  >
    <template v-slot:activator="{ on }">
      <div class="icons-container" v-on="on" @click.stop="">
        <v-avatar v-if="numAlerts > 1" class="pa-2 alerts-count-badge" size="10">
          <span class="white--text">{{ numAlerts }}</span>
        </v-avatar>
        <v-icon v-once size="2.5rem" class="alerts-icon" :color="alertsIconColor">
          {{ alertIcon }}
        </v-icon>
      </div>
    </template>
    <v-card class="scrolling-card" height="15rem">
      <v-card-text class="px-0 py-0">
        <v-row v-for="(alert, index) in sortedAndFilteredAlerts" :key="index" class="border-bottom">
          <v-col class="px-4 py-1">
            <span class="alerts-text">
              <strong>{{ alert.metadata }}</strong>
            </span>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </v-menu>
</template>

<script>
import { mapState } from 'vuex';
import { get, isEmpty, reduce, sortBy } from 'lodash';
import colors from '../../../ow-colors';
import icons from '../../../icons';

export default {
  props: {
    alerts: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      open: false,
      alertsIconColor: colors.alertsIconColor,
    };
  },
  computed: {
    ...mapState('clientConfig', ['alertTypes', 'alertOrder', 'alertsWithoutIcons']),
    alertsWithoutIconsList() {
      return isEmpty(this.alertsWithoutIcons) ? [] : this.alertsWithoutIcons;
    },
    // TODO: PRICE-2598 remove this computed and use this.alertOrder directly, once fully migrated to fabric.
    alertOrderComputed() {
      if (!isEmpty(this.alertOrder)) {
        return this.alertOrder;
      }

      return reduce(
        this.alertTypes,
        (acc, value, key) => {
          acc[key] = Object.keys(acc).length;
          return acc;
        },
        {}
      );
    },
    sortedAndFilteredAlerts() {
      const filteredAlerts = this.alerts.filter(
        alert => !this.alertsWithoutIconsList.includes(alert.alertType)
      );
      return sortBy(filteredAlerts, alert =>
        get(this.alertOrderComputed, alert.alertType, this.alertTypes.length + 1)
      );
    },
    alertIcon() {
      if (isEmpty(this.sortedAndFilteredAlerts)) return '';
      const alertType = get(this.sortedAndFilteredAlerts, '0.alertType');
      return get(icons.values, alertType) ? `$${alertType}` : 'warning';
    },
    numAlerts() {
      if (this.sortedAndFilteredAlerts.length <= 9) {
        return this.sortedAndFilteredAlerts.length;
      }
      return '9+';
    },
  },

  methods: {
    closeMenu() {
      this.open = false;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.icons-container {
  position: relative;
}

.alerts-count-badge {
  cursor: pointer;
  position: absolute;
  right: -4px;
  top: 1px;
  background-color: $alertsIconColor;
  font-size: 1.2rem !important;
  font-weight: bold;
  // // Needs to be greater than the z-index of alerts-icon
  z-index: 2;
}

.alerts-icon {
  z-index: 1;
}

.alerts-text {
  color: white;
  font-size: 1.5rem;
}

.border-bottom {
  border-bottom: 0.1rem solid $alertsIconColor;
}

.scrolling-card {
  overflow-y: auto;
  overflow-x: hidden;
  background-color: $alertsBackground;
  border: 4px solid $alertsIconColor;
}
</style>
