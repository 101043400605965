import { VBtn } from 'vuetify/lib/components/VBtn';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.loadingMonthlyAggregations
        ? _c("basic-spinner", { staticClass: "spinner-wrapper" })
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex" },
        [
          _c("span", { staticClass: "root" }, [
            _vm._v(" " + _vm._s(_vm.$t("wholesale.graphTypeTitle")) + ":"),
          ]),
          _vm._v(" "),
          _c(VSelect, {
            staticClass: "label-part grid-dropdown",
            attrs: {
              value: _vm.selectedGraph,
              items: _vm.graphType,
              loading: _vm.loadingMonthlyAggregations,
              disabled: _vm.loadingMonthlyAggregations,
            },
            on: {
              change: function ($event) {
                return _vm.setSelectedGraph($event)
              },
            },
          }),
          _vm._v(" "),
          _c(VSelect, {
            staticClass: "label-part grid-dropdown",
            attrs: {
              value: _vm.selectedDataRepresentation,
              items: _vm.graphDataRepresentation,
              loading: _vm.loadingMonthlyAggregations,
              disabled: _vm.loadingMonthlyAggregations,
            },
            on: {
              change: function ($event) {
                return _vm.setSelectedGraphDataRepresentation($event)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _vm.dataAvailable
        ? _c(
            "div",
            [
              _c(
                VFlex,
                { attrs: { xs9: "" } },
                [
                  _c("highcharts", {
                    ref: "highcharts",
                    staticClass: "highchart",
                    attrs: { options: _vm.selectedOptions },
                  }),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      !_vm.dataAvailable && !_vm.loadingMonthlyAggregations
        ? _c("div", { staticClass: "no-data" }, [
            _vm._v("\n    " + _vm._s(_vm.$t("product.noData")) + "\n  "),
          ])
        : _vm._e(),
      _vm._v(" "),
      _c(
        "div",
        { staticClass: "d-flex justify-end pa-3" },
        [
          _c(
            VBtn,
            {
              attrs: {
                color: "primary",
                small: "",
                disabled: !_vm.dataAvailable,
                loading: _vm.downloadingItems,
                depressed: "",
              },
              on: { click: _vm.downloadExtract },
            },
            [
              _vm._v(
                "\n      " + _vm._s(_vm.$t("actions.download")) + "\n      "
              ),
              _c(VIcon, { attrs: { small: "" } }, [_vm._v("$export")]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }