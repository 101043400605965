'use strict';

module.exports = {
  yearly: 'yearly',

  yearTimePeriod: {
    yearToGo: 'YTG',
    yearToDate: 'YTD',
  },
};
