import { cloneDeep, some, get, isNull } from 'lodash';
import priceTypes from '@enums/price-types';
import filterPredicates from '@enums/filter-predicates';
import filterOptions from '@enums/filter-options';
import { categoryLevelDescription, categoryLevel } from '@enums/hierarchy';

const getInitialState = () => {
  return {
    selectedLevelEntryKey: null, // used by UM view
    scenario: null,
    pricingGroup: null,
    architectureGroup: null,
    newTabScenario: null,
    newTabPricingGroup: null,
    newTabArchitectureGroup: null,
    newTabArchitectureGroupAttributeFilter: null, // only gets consumed in attribute-filter-panel.vue
    // regular/UOM/norm weight
    selectedPriceType: priceTypes.regular,
    newTabPriceType: priceTypes.regular,
    inputScreensFilterRules: [],
    inputScreensFetchParams: {},
  };
};

const getInitialGlobalState = () => {
  return {
    defaultAttributeFilterValue: {},
    retailAttributesFilter: [],
    previousRetailAttributesFilter: [],
    architectureRulesLinePricingFilter: [],
  };
};

const store = {
  namespaced: true,
  // state should store only selected filters and new tab overrides.
  state: {
    ...getInitialState(),
    ...getInitialGlobalState(),
  },

  getters: {
    getSelectedFilter: state => filterName => state[filterName],
  },

  mutations: {
    setSelectedFilter(state, { filterName, filterValue }) {
      state[filterName] = filterValue;
    },

    resetFilter(state, { filterName }) {
      state[filterName] = null;
    },

    resetState(state) {
      // We reset only basic filters, not global attribute one
      Object.assign(state, {
        ...state,
        ...getInitialState(),
      });
    },

    setInputScreensFilterRules(state, rules) {
      state.inputScreensFilterRules = rules;
    },

    setInputScreensFetchParams(state, params) {
      state.inputScreensFetchParams = params;
    },

    setDefaultAttributeFilterValue(state, value) {
      state.defaultAttributeFilterValue = value;
    },
  },

  actions: {
    setSelectedFilter({ commit }, { filterName, filterValue }) {
      commit('setSelectedFilter', { filterName, filterValue });
    },

    resetFilter({ commit }, { filterName }) {
      commit('resetFilter', { filterName });
    },

    resetState({ commit }) {
      commit('resetState');
    },

    resetNewTabFilterState({ commit }) {
      const newTabFilterNames = [
        'newTabScenario',
        'newTabPricingGroup',
        'newTabArchitectureGroup',
        'newTabArchitectureGroupAttributeFilter',
      ];
      newTabFilterNames.map(filterName => commit('resetFilter', { filterName }));
    },

    // similar to consume actions in architecture-groups, but with added side effect of writing to filters.
    handleNewTabFilterState({ commit, getters }) {
      const filtersToCopy = [
        { filterName: 'scenario', newTabFilterName: 'newTabScenario' },
        { filterName: 'pricingGroup', newTabFilterName: 'newTabPricingGroup' },
        { filterName: 'architectureGroup', newTabFilterName: 'newTabArchitectureGroup' },
        { filterName: 'selectedPriceType', newTabFilterName: 'newTabPriceType' },
      ];
      filtersToCopy.forEach(({ filterName, newTabFilterName }) => {
        const newTabValue = getters.getSelectedFilter(newTabFilterName);
        if (newTabValue) {
          // copy new tab value to current filter, then set new tab value to null
          commit('setSelectedFilter', { filterName, filterValue: newTabValue });
          commit('resetFilter', { filterName: newTabFilterName });
        }
      });
    },

    setInputScreensFilterRules({ commit }, rules) {
      commit('setInputScreensFilterRules', rules);
    },

    setInputScreensFetchParams({ commit }, params) {
      commit('setInputScreensFetchParams', params);
    },

    async setDefaultFilter({ dispatch, commit, rootState, rootGetters }) {
      // Get parent tool store group (the one which parentPricingStoreGroupKey is null)
      const { workpackageStoreGroups } = rootState.storeGroupRelationships;
      const mainbanner = workpackageStoreGroups.find(x => isNull(x.parentPricingStoreGroupKey));
      const mainbannerKey = get(mainbanner, 'toolStoreGroupKey', null);

      const { 'clientConfig/getTranslation': getTranslation } = rootGetters;

      const defaultFilterValue = {
        attributeKey: 'toolStoreGroupKey',
        predicate: filterPredicates.IN,
        attributeValue: [mainbannerKey],
        displayValue: [null],
        attributeFilterType: filterOptions.storegroupOption,
        attributeDescription: getTranslation('pricing.toolStoreGroup'),
        displayDescription: getTranslation('pricing.toolStoreGroup'),
      };

      // Save default filter value to use all across application
      await commit('setDefaultAttributeFilterValue', defaultFilterValue);

      // Set global attribute filter value that is used on gridView and Inputs pages
      const defaultRetailAttributesFilter = {
        filterName: 'retailAttributesFilter',
        filterValue: [defaultFilterValue],
      };
      await dispatch('filters/setSelectedFilter', defaultRetailAttributesFilter, { root: true });
    },

    async setDefaultCategoryFilter({ dispatch, getters }, { defaultCategoryFilter }) {
      const defaultFilterValue = {
        attributeKey: categoryLevel,
        predicate: filterPredicates.IN,
        attributeValue: defaultCategoryFilter.map(v => v.attributeValue),
        displayValue: defaultCategoryFilter.map(v => v.displayValue),
        attributeFilterType: filterOptions.hierarchyOption,
        attributeDescription: categoryLevelDescription,
        displayDescription: categoryLevelDescription,
      };

      const currentFilterValues = getters.getSelectedFilter('retailAttributesFilter');
      // use _.some to compare objects by key/value, not reference
      if (some(currentFilterValues, defaultFilterValue)) {
        // default filter is already applied
        return;
      }

      // no hierarchy filter options in webtool/client/js/components/architecture-group-filter.vue which is the only place defaultAttributeFilterValue is used
      // await commit('setDefaultAttributeFilterValue', defaultFilterValue);

      // Set global attribute filter value that is used on gridView and Inputs pages
      // Merge with existing toolStoreGroupKey default filter if needed (currentFilterValues)
      const defaultRetailCategoryFilter = {
        filterName: 'retailAttributesFilter',
        filterValue: [...currentFilterValues, defaultFilterValue],
      };
      await dispatch('filters/setSelectedFilter', defaultRetailCategoryFilter, { root: true });
    },

    restoreRetailAttributesFilter({ commit, state }) {
      commit('setSelectedFilter', {
        filterName: 'retailAttributesFilter',
        filterValue: state.previousRetailAttributesFilter,
      });

      commit('setSelectedFilter', {
        filterName: 'previousRetailAttributesFilter',
        filterValue: [],
      });
    },

    saveAndResetRetailAttributesFilter({ commit, state }, newAttributeFilters = []) {
      commit('setSelectedFilter', {
        filterName: 'previousRetailAttributesFilter',
        filterValue: cloneDeep(state.retailAttributesFilter),
      });

      commit('setSelectedFilter', {
        filterName: 'retailAttributesFilter',
        filterValue: newAttributeFilters,
      });
    },
  },
};

export default store;
