<template>
  <v-tabs
    v-model="selectedTab"
    height="2.5rem"
    background-color="transparent"
    class="pricing-tabs"
    active-class="pricing-tabs__tab--active"
    hide-slider
    @change="navigateToTab"
  >
    <template v-for="tab in tabs">
      <!-- Used to fill and style the space between tabs. -->
      <v-tab
        :key="`tab-${tab.label}`"
        :disabled="tab.disabled"
        class="pricing-tabs__tab"
        :class="tab.class"
      >
        {{ toSentenceCase(tab.label) }}
      </v-tab>
      <div :key="`spacer-${tab.label}`" class="pricing-tabs__spacer" />
    </template>

    <!-- Used to fill and style the remaining space from the last tab to the end of the tabs container. -->
    <div class="pricing-tabs__filler" />

    <v-tab-item
      v-for="tab in tabs"
      :key="`tab-item-${tab.label}`"
      :transition="false"
      :reverse-transition="false"
      :active-class="tab.class"
      class="tab"
    >
      <component :is="tab.component" />
    </v-tab-item>
  </v-tabs>
</template>

<script>
import { findIndex, includes } from 'lodash';

export default {
  props: {
    tabs: {
      type: Array,
      required: true,
    },
  },
  data() {
    return {
      selectedTab: 0,
    };
  },
  created() {
    this.setDefaultTab();
  },
  methods: {
    navigateToTab() {
      this.tabs[this.selectedTab].navigateTo();
    },

    setDefaultTab() {
      const routeName = this.$route.name;
      const tabToOpen = findIndex(this.tabs, tab => includes(tab.routeNames, routeName));

      this.selectedTab = tabToOpen;
    },
    toSentenceCase(phrase) {
      if (!phrase) return phrase;

      const firstLetter = phrase.charAt(0);
      const remainingLetters = phrase.slice(1);

      return `${firstLetter.toUpperCase()}${remainingLetters}`;
    },
  },
};
</script>

<style scoped lang="scss">
::v-deep .v-tabs-items {
  background-color: transparent !important;
}

// Tab specific colours.
$tab-inactive: #f9f9fa;
$tab-border-accent: #aecadb;

$tab-border: 1px solid #dddddd;
.pricing-tabs {
  & &__tab {
    min-width: 15rem;
    padding: 1rem;
    justify-content: left;

    border: $tab-border;
    border-left: 0.3rem solid $tab-border-accent;
    background-color: $tab-inactive;

    font-size: 1.2rem;
    letter-spacing: normal;

    &--active:not(.v-tab--disabled) {
      border-bottom: none;
      background-color: white;
      font-weight: 600;
    }
  }

  &__spacer {
    width: 0.5rem;
    border-bottom: $tab-border;
  }

  &__tab-button {
    border-bottom: $tab-border;
  }

  &__tab-button-spacer {
    width: 0.9rem;
    border-bottom: $tab-border;
  }

  &__filler {
    flex-grow: 1;
    border-bottom: $tab-border;
  }

  &__notifications-icon {
    padding-right: 1rem;
    border-bottom: $tab-border;
  }
}
</style>
