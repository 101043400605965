import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "tbody",
    [
      !_vm.isUnitManagerView
        ? [
            _c("tr", [
              _c("td", { staticClass: "border-bottom" }, [
                _c("div", { staticClass: "d-flex flex-row-reverse" }, [
                  _vm._v(_vm._s(_vm.$t("gridView.marginLever"))),
                ]),
              ]),
              _vm._v(" "),
              _c("td", {
                staticClass: "border-bottom",
                attrs: { colspan: "16" },
              }),
              _vm._v(" "),
              _c("td", { staticClass: "alert-cell" }),
            ]),
            _vm._v(" "),
            _vm._l(_vm.scenarioAggregations, function (scenarioAggregation) {
              return _c("grid-view-scenario-selector", {
                key: scenarioAggregation._id,
                attrs: {
                  scenario: scenarioAggregation,
                  "level-entry-key": _vm.levelEntryKey,
                },
                on: { updateDefaultScenario: _vm.updateDefaultScenario },
              })
            }),
            _vm._v(" "),
            _c("tr", { staticClass: "add-scenario-row" }, [
              _c(
                "td",
                { staticClass: "pl-0" },
                [
                  _vm.canAddScenario
                    ? _c(
                        VBtn,
                        {
                          staticClass: "pl-0",
                          attrs: { color: "primary", small: "", text: "" },
                          on: { click: _vm.showAddScenarioDialog },
                        },
                        [
                          _vm._m(0),
                          _vm._v(" "),
                          _c("span", { staticClass: "add-button ml-1 pl-0" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "gridView.addScenario.addScenarioButton"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c("td", { staticClass: "blank-cell", attrs: { colspan: "16" } }),
              _vm._v(" "),
              _c("td", { staticClass: "alert-cell" }),
            ]),
          ]
        : _vm._e(),
      _vm._v(" "),
      _c("tr", [
        _c(
          "td",
          { attrs: { colspan: "19" } },
          [
            _c("grid-view-container", {
              attrs: {
                "is-unit-manager-view": _vm.isUnitManagerView,
                "level-entry-key": _vm.levelEntryKey,
              },
              on: {
                toggleFilteringByArchitectureGroup:
                  _vm.onToggleFilteringByArchitectureGroup,
              },
            }),
          ],
          1
        ),
      ]),
    ],
    2
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { staticClass: "pl-0" }, [_vm._v("add_box")])
  },
]
render._withStripped = true

export { render, staticRenderFns }