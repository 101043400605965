import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.workpackage
        ? _c(
            VContainer,
            {
              attrs: {
                fluid: "",
                "pr-0": "",
                "pl-0": "",
                "pb-0": "",
                "pt-6": "",
              },
            },
            [
              _c(
                VRow,
                {
                  staticClass: "header text-row pl-4",
                  attrs: { "no-gutters": "" },
                },
                [
                  _c(VCol, { staticClass: "pb-0 pt-2" }, [
                    _vm._v(
                      "\n        " + _vm._s(_vm.$t("workPackages.owner")) + ": "
                    ),
                    _c("strong", [_vm._v(_vm._s(_vm.workpackage.owner))]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                "feature-toggle",
                { attrs: { toggle: _vm.displayLastUpdated } },
                [
                  _vm.workpackage.lastUpdated
                    ? _c(
                        VRow,
                        {
                          staticClass: "header text-row pl-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(VCol, { staticClass: "pb-0 pt-2" }, [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("workPackages.lastUpdated")) +
                                  ":\n            "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFormattedDateTime(
                                      _vm.workpackage.lastUpdated
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.workpackage.competitorLastUpdated &&
                  _vm.isMasterWorkpackage
                    ? _c(
                        VRow,
                        {
                          staticClass: "header text-row pl-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(VCol, { staticClass: "pb-0 pt-2" }, [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t("workPackages.competitorLastUpdated")
                                  ) +
                                  ":\n            "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.getFormattedDateTime(
                                      _vm.workpackage.competitorLastUpdated
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.workpackage.cumulativeProductCount &&
                  _vm.isMasterWorkpackage
                    ? _c(
                        VRow,
                        {
                          staticClass: "header text-row pl-4",
                          attrs: { "no-gutters": "" },
                        },
                        [
                          _c(VCol, { staticClass: "pb-0 pt-2" }, [
                            _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("workPackages.documents")) +
                                  "\n            "
                              ),
                              _c("br"),
                              _vm._v(" "),
                              _c("strong", [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("workPackages.documentsCount", {
                                      previousProductCount: _vm.formatNumber({
                                        number:
                                          _vm.workpackage.previousProductCount,
                                        format: _vm.numberFormats.integer,
                                      }),
                                      cumulativeProductCount: _vm.formatNumber({
                                        number:
                                          _vm.workpackage
                                            .cumulativeProductCount,
                                        format: _vm.numberFormats.integer,
                                      }),
                                    })
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VRow,
                { staticClass: "text-row pl-4", attrs: { "no-gutters": "" } },
                [
                  _vm.workpackage.releaseDate
                    ? _c(VCol, { staticClass: "pb-0 pt-2" }, [
                        _vm._v(
                          "\n        " +
                            _vm._s(_vm.$t("workPackages.releaseDate")) +
                            ": "
                        ),
                        _c("strong", [
                          _vm._v(_vm._s(_vm.workpackage.releaseDate)),
                        ]),
                      ])
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          )
        : _c(
            VContainer,
            {
              staticClass: "empty-reveal",
              attrs: { fluid: "", "pr-0": "", "pl-0": "", "pb-0": "" },
            },
            [
              _c(VRow, {
                staticClass: "header text-row",
                attrs: { "no-gutters": "" },
              }),
            ],
            1
          ),
      _vm._v(" "),
      _c("incorrect-mappings-modal", {
        attrs: { "show-dialog": _vm.showIncorrectMappingsModal },
        on: { closeDialog: _vm.closeIncorrectMappingsModal },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }