import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VInput } from 'vuetify/lib/components/VInput';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VRow,
    { staticClass: "architecture-driver pl-2" },
    [
      _c(
        VFlex,
        { staticClass: "pr-1", attrs: { xs11: "", grow: "" } },
        [
          _c(
            VCard,
            { staticClass: "my-1" },
            [
              _c(
                VCardTitle,
                { staticClass: "px-2 py-0" },
                [
                  _c(
                    VLayout,
                    [
                      _c(
                        VFlex,
                        { attrs: { xs6: "" } },
                        [
                          _c(
                            VInput,
                            {
                              staticClass: "mt-2",
                              attrs: {
                                "background-color": "grey lighten-3",
                                disabled: _vm.disabled,
                              },
                            },
                            [
                              _c(
                                "span",
                                { staticClass: "px-1 text-truncate" },
                                [
                                  _vm._v(
                                    _vm._s(
                                      _vm.driverAttribute.displayDescription
                                    )
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        { staticClass: "pl-2", attrs: { xs5: "" } },
                        [
                          _c(VSelect, {
                            staticClass: "py-0 architecture-drivers-rule",
                            attrs: {
                              items: _vm.relevantArchitectureDriversRules,
                              disabled: _vm.disabled,
                              "item-text": "label",
                              "item-value": "key",
                              height: "2.5rem",
                            },
                            on: { change: _vm.setDriverType },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (props) {
                                  return [
                                    _c("span", { staticClass: "item-text" }, [
                                      _vm._v(_vm._s(props.item.label)),
                                    ]),
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.driverAttribute.driverCalculationType,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.driverAttribute,
                                  "driverCalculationType",
                                  $$v
                                )
                              },
                              expression:
                                "driverAttribute.driverCalculationType",
                            },
                          }),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VFlex,
                        { staticClass: "pt-2", attrs: { xs1: "" } },
                        [
                          _c(
                            VIcon,
                            {
                              staticClass: "delete-icon float-right",
                              attrs: { disabled: _vm.disabled },
                              on: {
                                click: function ($event) {
                                  return _vm.emitRemoveFromAttributesDrivers(
                                    _vm.driverAttribute.attributeKey
                                  )
                                },
                              },
                            },
                            [_vm._v("\n              delete\n            ")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _vm.driverAttribute.driverCalculationType ===
              _vm.calculationType.categorical
                ? _c(
                    VCardText,
                    { staticClass: "driver-container" },
                    [
                      _c(
                        VRow,
                        _vm._l(_vm.attributes, function (attribute) {
                          return _c(
                            VCol,
                            {
                              key: attribute.attributeValue,
                              staticClass: "pt-0 pb-0",
                              attrs: { cols: "6" },
                            },
                            [
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass:
                                        "architecture-value pt-2 pb-0 mt-0 mb-0",
                                      attrs: { cols: "8" },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm._f("truncate")(
                                              _vm.getFormattedAttributeValue(
                                                attribute.attributeValue
                                              ),
                                              _vm.truncationLength
                                            )
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                  _vm._v(" "),
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "pt-0 pb-0 mt-0 mb-0",
                                      attrs: { cols: "4" },
                                    },
                                    [
                                      _c(VTextField, {
                                        staticClass: "highlight",
                                        attrs: {
                                          step: "0.1",
                                          type: "number",
                                          rules: _vm.rules.impact,
                                          disabled: _vm.disabled,
                                        },
                                        on: {
                                          change: function ($event) {
                                            return _vm.updateCategoricalArchitectureDriver(
                                              $event,
                                              attribute.attributeValue
                                            )
                                          },
                                        },
                                        model: {
                                          value: attribute.impact,
                                          callback: function ($$v) {
                                            _vm.$set(
                                              attribute,
                                              "impact",
                                              _vm._n($$v)
                                            )
                                          },
                                          expression: "attribute.impact",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        }),
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.driverAttribute.driverCalculationType ===
              _vm.calculationType.linear
                ? _c(
                    VCardText,
                    { staticClass: "driver-container" },
                    [
                      _c(
                        VLayout,
                        {
                          attrs: {
                            "justify-space-between": "",
                            "align-baseline": "",
                          },
                        },
                        [
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.setArchitectureRules.linearParameters.anchor"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            VFlex,
                            { attrs: { xs1: "" } },
                            [
                              _c(VTextField, {
                                staticClass: "highlight",
                                attrs: {
                                  step: "0.1",
                                  type: "number",
                                  rules: _vm.rules.impact,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  change: _vm.updateLinearArchitectureDriver,
                                },
                                model: {
                                  value: _vm.linearImpact.anchor,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.linearImpact,
                                      "anchor",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "linearImpact.anchor",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(" (per unit) ")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.setArchitectureRules.linearParameters.reference"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            VFlex,
                            { attrs: { xs1: "" } },
                            [
                              _c(VTextField, {
                                staticClass: "highlight",
                                attrs: {
                                  step: "0.1",
                                  type: "number",
                                  rules: _vm.rules.impact,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  change: _vm.updateLinearArchitectureDriver,
                                },
                                model: {
                                  value: _vm.linearImpact.reference,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.linearImpact,
                                      "reference",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "linearImpact.reference",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(" (per unit) ")]),
                          _vm._v(" "),
                          _c("span", { staticClass: "font-weight-bold" }, [
                            _vm._v(
                              "\n            " +
                                _vm._s(
                                  _vm.$t(
                                    "settings.setArchitectureRules.linearParameters.discount"
                                  )
                                ) +
                                "\n          "
                            ),
                          ]),
                          _vm._v(" "),
                          _c(
                            VFlex,
                            { attrs: { xs1: "" } },
                            [
                              _c(VTextField, {
                                staticClass: "highlight",
                                attrs: {
                                  step: "0.1",
                                  type: "number",
                                  rules: _vm.rules.discount,
                                  disabled: _vm.disabled,
                                },
                                on: {
                                  change: _vm.updateLinearArchitectureDriver,
                                },
                                model: {
                                  value: _vm.linearImpact.discount,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.linearImpact,
                                      "discount",
                                      _vm._n($$v)
                                    )
                                  },
                                  expression: "linearImpact.discount",
                                },
                              }),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c("span", [_vm._v(" % ")]),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VFlex,
        { staticClass: "draggable-attribute-icon", attrs: { xs1: "" } },
        [
          _c(
            VIcon,
            {
              staticClass: "drag-icon pt-3",
              attrs: { disabled: _vm.disabled, "text-xs-right": "" },
            },
            [_vm._v("open_with")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }