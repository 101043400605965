<template class="rule-entry">
  <v-card outlined elevated elevation="6" class="rule-card">
    <v-layout class="card-text">
      <v-flex v-if="isEditMode && !expanded" class="description description-text">
        <b>{{ rule.description }}</b>
      </v-flex>

      <v-flex v-if="isCreateMode || (isEditMode && expanded)" class="description description-text">
        <v-text-field
          v-model="rule.description"
          :placeholder="$t('settings.penaltyRules.enterRuleName')"
          class="description-text-input"
          :disabled="disabled"
          @input="value => updateDescriptionChange(value)"
        />
      </v-flex>

      <v-flex class="px-3 description store-group-text text-truncate">
        {{ selectedStoreGroupsText }}
      </v-flex>

      <v-flex class="px-3 description scope-text text-truncate">
        <span v-for="(word, ix) in scopeText" :key="ix" :class="scopeClass(word)">
          {{ splitScopeText(word) }}
        </span>
      </v-flex>

      <div text-xs-right class="product-proportion px-3">
        <b>{{ productScopeCount }}</b> / {{ productAppliedCount }}
      </div>

      <div text-xs-right class="d-flex px-3">
        <v-avatar
          v-for="penalty in headers"
          :key="penalty.field"
          :size="trafficLightIconSize"
          :class="[
            penalty.class,
            {
              'inactive-traffic-light':
                !rule[penalty.field] || rule[penalty.field].status === inactive,
            },
          ]"
          class="traffic-light"
        >
          <span class="headline">{{ $t(penalty.headline) }}</span>
        </v-avatar>

        <v-avatar :size="trafficLightIconSize" class="traffic-light other-penalty-traffic-light">
          <span class="white--text headline">
            {{ $t('settings.penaltyRules.otherPenaltyInitial') }}
          </span>
        </v-avatar>
      </div>

      <div class="px-2">
        <v-icon
          v-if="rule._id"
          :size="trafficLightIconSize"
          :disabled="disabled"
          @click="openDeleteDialog()"
        >
          delete
        </v-icon>
      </div>
      <v-btn
        class="edit-text pa-0"
        :disabled="!isExpandable"
        :ripple="false"
        small
        plain
        text
        text-xs-right
        @click="expand"
      >
        <span v-if="isEditMode">{{ $t('actions.edit') }}</span>
        <v-icon class="edit-dropdown-icon subheading" :class="{ expanded }"> expand_more </v-icon>
      </v-btn>
    </v-layout>

    <!-- Delete dialog -->
    <alert-dialog
      :is-open="isDeleteDialogOpen"
      :ok-btn-text="$t('actions.delete')"
      @onOk="deletePenaltyRuleHandler"
      @onCancel="closeDeleteDialog"
    >
      <template v-slot:header>{{ $t('dialogs.deletePenaltyRule.header') }}</template>
      <template v-slot:body>
        <p>
          {{ $t('dialogs.deletePenaltyRule.deleteRule') }}
          <strong>{{ rule.description }}</strong>
        </p>
        <p>{{ $t('dialogs.deletePenaltyRule.clickApply') }}</p>
      </template>
    </alert-dialog>
  </v-card>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { isEmpty, slice, get, debounce, find } from 'lodash';
import { inactive } from '@enums/rule-status';
import { storegroupOption } from '@enums/filter-options';
import { edit, create } from '@enums/dialog-modes';
import { ruleHeaders } from '@sharedModules/data/constants/penalties';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [featureFlagsMixin],
  props: {
    rule: {
      type: Object,
      required: true,
      default: () => {},
    },
    productCounts: {
      type: Array,
      default: () => [],
    },
    expanded: {
      type: Boolean,
      required: false,
    },
    mode: {
      type: String,
      default: () => edit,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      inactive,
      trafficLightIconSize: 22,
      isDeleteDialogOpen: false,
    };
  },

  computed: {
    ...mapGetters('context', [
      'isAdmin',
      'isAdminLoggedIn',
      'isWholesaleManager',
      'isWholesaleAnalyst',
    ]),
    ...mapState('storeGroupRelationships', ['storegroupKeyNameMap']),

    scopeText() {
      if (this.rule.scope.length === 0 || isEmpty(this.rule.scope[0])) {
        return [];
      }

      const scopeAttributes = this.rule.scope.filter(
        attribute => attribute.attributeFilterType !== storegroupOption
      );
      if (isEmpty(scopeAttributes)) return [];
      const { displayDescription, attributeValue, displayValue } = scopeAttributes[0];

      // Separate array elements by comma + space
      const delimitArray = value => value.join(', ');

      // Have added :: to separate the words for formatting
      // have added @@ to replace spaces in attributes, this is to allow formatting the brackets around the word
      // Use displayValue for hierarchy filters. otherwise use attributeValue.
      const whereList = `WHERE::${displayDescription}::IN::[::${delimitArray(
        displayValue || attributeValue
      )}::]`;

      const andLists = slice(scopeAttributes, 1).reduce(
        (sentence, scope) =>
          `${sentence}::AND::${scope.displayDescription}::IN::[::${delimitArray(
            scope.displayValue || scope.attributeValue
          )}::]`,
        ''
      );

      return `${whereList}${andLists}`.split('::');
    },

    selectedStoreGroupsText() {
      const scope = get(this.rule, 'scope');
      if (isEmpty(scope)) return '';

      const storegroupScope = find(scope, { attributeFilterType: storegroupOption });

      if (!storegroupScope) return '';

      const mappedNames = storegroupScope.attributeValue.map(key => this.storegroupKeyNameMap[key]);
      return mappedNames.join(', ');
    },

    isEditMode() {
      return this.mode === edit;
    },

    isCreateMode() {
      return this.mode === create;
    },

    productScopeCount() {
      return this.productCounts.length ? this.productCounts[0] : '-';
    },

    productAppliedCount() {
      return this.productCounts.length ? this.productCounts[1] : '-';
    },

    headers() {
      return this.filterArrayByFeatureFlag(ruleHeaders);
    },

    isExpandable() {
      return this.isAdminLoggedIn || (!this.isWholesaleManager && !this.isWholesaleAnalyst);
    },
  },

  methods: {
    isOperator(value) {
      const result = value.match(/WHERE|IN|AND/g);
      return result && result.length;
    },

    isBracket(value) {
      const result = value.match(/[[|]|(|)]/g);
      return result && result.length;
    },

    scopeClass(value) {
      if (this.isOperator(value)) {
        return 'scope-entry scope-op';
      }
      if (this.isBracket(value)) {
        return 'scope-entry scope-br';
      }
      return 'scope-default';
    },

    splitScopeText(word) {
      // converts (IN:... to IN...) this is incase a truncate hits a space (::) and only shows one char (:)
      return `${!this.isOperator(word) && !this.isBracket(word) ? word : word.replace(':.', '.')} `;
    },

    expand() {
      this.$emit('expand');
    },

    openDeleteDialog() {
      this.isDeleteDialogOpen = true;
    },

    closeDeleteDialog() {
      this.isDeleteDialogOpen = false;
    },

    deletePenaltyRuleHandler() {
      this.$emit('deleteRule', { id: this.rule._id });
    },

    updateDescriptionChange: debounce(function(description) {
      this.$emit('updateDescription', description);
    }, clientConfig.inputDebounceValue),
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.rule-entry {
  transition-duration: 5s;
}

.card-text {
  font-size: 1.25rem;
}

.traffic-light {
  color: white;
  cursor: default;
  margin-right: 0.5rem;

  .headline {
    font-size: 1.5rem !important;
  }
}

.product-proportion {
  flex: 0.6;
  margin-right: 1rem;
  text-align: right;
}

.edit-text {
  align-items: baseline;
  cursor: pointer;
  color: $rulesetDarkBlue;
  font-size: 1.25rem;
  height: auto !important;
  letter-spacing: 0;

  &:before {
    content: none;
  }

  &.theme--light.v-btn.v-btn--disabled {
    color: inherit !important;
  }

  ::v-deep {
    .v-btn__content {
      padding: 0;
    }
  }
}

.expanded {
  transform: rotate(-180deg);
}

.scope-entry {
  word-spacing: 0.6rem;
}

.scope-entry.scope-op {
  color: $scopeHighlightBlue;
}

.scope-entry.scope-br {
  color: $scopeHighlightRed;
}
.scope-default {
  color: black;
  margin-right: 0.6rem;
}

.economic-penalty-traffic-light {
  background-color: $economicTrafficLightColor;
}

.competitor-penalty-traffic-light {
  background-color: $competitorTrafficLightColor;
}

.store-group-penalty-traffic-light {
  background-color: $storeGroupTrafficLightColor;
}

.architecture-penalty-traffic-light {
  background-color: $architectureTrafficLightColor;
}

.price-pointing-traffic-light {
  background-color: $pricePointingTrafficLightColor;
}

.other-penalty-traffic-light {
  background-color: $otherTrafficLightColor;
}

.inactive-traffic-light {
  background-color: $inactiveTrafficLightColor;
  color: $inactiveTrafficLightColor;
}

.edit-dropdown-icon {
  color: $rulesetDarkBlue;
}

.description-text-input {
  padding-top: 0;
  margin-top: -1rem;
  font-size: 1.2rem;
}

.description {
  padding-top: 0.3rem;
}

.description-text,
.product-proportion,
.scope-text,
.store-group-text {
  min-width: 0;
}

.description-text {
  flex: 1.8;
}

.scope-text {
  flex: 3;
}

.store-group-text {
  color: $text-color;
  flex: 2;
}
</style>
