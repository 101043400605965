var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c("input", {
    staticClass: "ag-input-field-input ag-checkbox-input",
    attrs: { type: "checkbox" },
    domProps: { checked: _vm.params.selected },
    on: { click: _vm.params.changeHandler },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }