'use strict';

const generalCssClasses = Object.freeze({
  evenRows: 'even-rows',
  active: 'active',
  descendingSorting: 'desc',
  ascendingSorting: 'asc',
  sortable: 'sortable',
});

const inputTableCssClasses = Object.freeze({
  twoFixedColumns: 'two-fixed',
  threeFixedColumns: 'three-fixed',
  fourFixedColumns: 'four-fixed',
});

module.exports = {
  generalCssClasses,
  inputTableCssClasses,
};
