<template>
  <div>
    <rule-header
      :rule="mutableRule"
      :product-counts="productCounts || []"
      :class="{ 'expanded-top': isExpanded(rulesetType, ruleIndex) }"
      :expanded="isExpanded(rulesetType, ruleIndex)"
      :disabled="disabled"
      @expand="toggleExpanded(rulesetType, ruleIndex)"
      @deleteRule="deleteRule"
      @updateDescription="updateDescription"
    />
    <v-expand-transition>
      <v-flex v-if="isExpanded(rulesetType, ruleIndex)" grow>
        <v-card
          outlined
          :class="{ 'elevation-6': !isExpanded(rulesetType, ruleIndex) }"
          class="rule-card expanded-bottom"
        >
          <v-card flat class="rule-content">
            <attribute-filter-panel
              :ruleset-type="rulesetType"
              :filter-rules="mutableRule.scope"
              :enable-non-attribute-filters="false"
              :enable-hierarchy-filters="true"
              :hierarchy-filter-level="hierarchyFilterLevel"
              title-localisation="settings.penaltyRules.scope.scopeApplied"
              filter-count-localisation="settings.penaltyRules.scope.numApplied"
              @attributeFilterChange="updateRuleScope"
            />
            <v-tabs
              v-model="tab"
              active-class="pricing-tabs__tab--active"
              background-color="transparent"
              class="pricing-tabs"
              color="white"
              height="4rem"
              hide-slider
            >
              <template v-for="(item, i) in tabs">
                <div
                  :key="item.iconText + i"
                  class="d-flex"
                  :custom-title-msg="item.tooltipMessage"
                  :custom-title-hidden="!item.tooltipMessage"
                  custom-title-position="right"
                >
                  <v-tab
                    class="pricing-tabs__tab pricing-tabs__tab--first pr-0"
                    @click="tabChange(item)"
                  >
                    <v-layout>
                      <v-layout align-self-center justify-start class="pr-1">
                        <v-avatar size="2.5rem" class="traffic-light" :class="item.iconClass">
                          <span class="white--text headline">{{ item.iconText }}</span>
                        </v-avatar>
                        <span class="tab-title">{{ item.tabText }}</span>
                      </v-layout>
                      <v-layout align-self-center justify-end>
                        <v-checkbox
                          v-model="item.status"
                          class="activate-checkbox pl-4 pr-0"
                          :true-value="active"
                          :false-value="inactive"
                          :disabled="isTabDisabled(item)"
                          @change="setStatus(item)"
                        />
                      </v-layout>
                    </v-layout>
                  </v-tab>
                </div>
              </template>
              <div class="pricing-tabs__filler" />
            </v-tabs>
            <v-tabs-items v-model="tab">
              <v-tab-item
                v-for="(item, i) in tabs"
                :key="item.iconText + i"
                class="tab-content"
                :class="{ opaque: item.disabled }"
              >
                <v-container v-if="item.penalty === penaltyCategories.competitor" fluid>
                  <v-row>
                    <div class="field-heading pr-3 pt-2">
                      {{ $t('settings.penaltyRules.competitorSelectText') }}:
                    </div>
                    <div class="pr-2">
                      <v-select
                        v-model="selectedCompetitorIndex"
                        class="pa-0 ma-0"
                        :items="competitorDropdownValues"
                      >
                        <template v-slot:item="props">
                          <v-layout>
                            <v-layout align-self-center justify-start>
                              <span class="item-text">{{ props.item.text }}</span>
                            </v-layout>
                            <v-layout align-self-center justify-end>
                              <v-icon v-if="isCompetitorActive(props.item.value)" right>
                                done
                              </v-icon>
                            </v-layout>
                          </v-layout>
                        </template>
                      </v-select>
                    </div>
                    <div>
                      <v-checkbox
                        v-model="competitorIsActive"
                        class="pa-0 ma-0"
                        :label="$t('settings.penaltyRules.activeCompetitor')"
                        :disabled="isTabDisabled(item)"
                      />
                    </div>
                  </v-row>
                </v-container>

                <v-container v-if="item.penalty === penaltyCategories.storeGroup" fluid>
                  <v-row>
                    <div class="field-heading pr-3 pt-2">
                      {{ $t('settings.penaltyRules.storeGroupSelectText') }}:
                    </div>
                    <div class="pr-2">
                      <v-select
                        v-model="selectedStoreGroupIndex"
                        class="pa-0 ma-0"
                        :items="storeGroupDropdownValues"
                        :disabled="isTabDisabled(item)"
                      >
                        <template v-slot:item="props">
                          <v-layout>
                            <v-layout align-self-center justify-start>
                              <span class="item-text">{{ props.item.text }}</span>
                            </v-layout>
                            <v-layout align-self-center justify-end>
                              <v-icon v-if="isStoreGroupActive(props.item.value)" right>
                                done
                              </v-icon>
                            </v-layout>
                          </v-layout>
                        </template>
                      </v-select>
                    </div>
                    <div>
                      <v-checkbox
                        v-model="storeGroupIsActive"
                        class="pa-0 ma-0"
                        :label="$t('settings.penaltyRules.activeCompetitor')"
                        :disabled="isTabDisabled(item)"
                      />
                    </div>
                    <div
                      v-if="displayStoreGroupPenaltyError"
                      class="field-heading pl-3 pt-1 red--text"
                    >
                      <span>{{ selectedStoreGroupPenalty.error }}</span>
                    </div>
                  </v-row>
                </v-container>

                <v-container v-if="item.penalty === penaltyCategories.pricePointing" fluid>
                  <div class="d-flex flex-column">
                    <span v-if="duplicateRangeMaximumsInPricePointing" class="validation-fail pl-2">
                      {{
                        $t(
                          'settings.penaltyRules.pricePointing.pricePointingRangeMaximumValidationError'
                        )
                      }}
                    </span>
                    <span v-if="missingEmptyPricePointingRange" class="validation-fail pl-2">
                      {{ $t('settings.penaltyRules.pricePointing.pricePointingMissingMaxRange') }}
                    </span>
                    <span v-if="missingTokensForPricePointingRange" class="validation-fail pl-2">
                      {{ $t('settings.penaltyRules.pricePointing.pricePointingMissingOffsets') }}
                    </span>
                  </div>
                  <price-pointing
                    v-for="(allowedValue, index) in item.rule.allowedValues"
                    :key="index"
                    :price-pointing="allowedValue"
                    :index-in-array="index"
                    :disabled="isTabDisabled(item)"
                    @deletePricePointing="deletePricePointing"
                    @updateRangeMaximum="updateRangeMaximum"
                    @updateOffset="updateOffset"
                    @updateBase="updateBase"
                  />
                  <div v-if="!disabled" class="add-pricepointing-container pl-2">
                    <v-icon small class="add-pricepointing-icon" @click="startPricePointingCreation"
                      >fa-plus-square</v-icon
                    >
                    <span class="add-pricepointing-text">{{
                      $t('settings.penaltyRules.pricePointing.addRangeBreak')
                    }}</span>
                  </div>
                </v-container>

                <v-layout v-if="item.penalty !== penaltyCategories.pricePointing">
                  <v-flex xs12>
                    <v-layout>
                      <!-- <form novalidate> is required to disable browser native validation  -->
                      <form class="formWrapper" novalidate>
                        <v-flex xs8>
                          <v-layout align-baseline>
                            <v-text-field
                              flat
                              :background-color="owWhite"
                              :label="$t('settings.penaltyRules.offset')"
                              type="number"
                              :value="getPenaltyRuleFromItem(item).penaltyOffset"
                              :rules="rules.offsetInput"
                              :disabled="isTabDisabled(item)"
                              @input="value => debounceOffsetChange(item, value)"
                            />
                            <span class="suffix"> % </span>
                          </v-layout>
                          <v-layout
                            v-if="item.penalty === penaltyCategories.competitor"
                            align-baseline
                          >
                            <span class="prefix">
                              {{ $t('clientCurrencySymbol') }}
                            </span>
                            <v-text-field
                              :value="
                                formatNumber({
                                  number: getPenaltyRuleFromItem(item).absoluteOffset,
                                  format: numberFormats.priceFormatNegative,
                                })
                              "
                              type="text"
                              :background-color="owWhite"
                              :label="$t('settings.penaltyRules.absoluteOffset')"
                              :disabled="isTabDisabled(item)"
                              @keypress="ensureDecimal($event, true)"
                              @focus="previousFieldValue = $event.target.value"
                              @blur="verifyFieldValue"
                              @change="absoluteOffsetChange($event, item)"
                            />
                          </v-layout>
                        </v-flex>
                      </form>
                      <v-flex layout xs6 pt-8 class="chart-container">
                        <penalty-chart
                          :key="forceRefresh[item.penalty]"
                          :penalty-category="item.penalty"
                          :penalty-curve-points="getPenaltyRuleFromItem(item)"
                        />
                      </v-flex>
                      <v-flex xs4 layout>
                        <v-layout justify-space-between>
                          <div class="formWrapper mr-3">
                            <penalty-parameters-form
                              penalty-name="lower"
                              class="penalty-form"
                              :penalty-rule="getPenaltyParameters(item, 'lower')"
                              :disabled="isTabDisabled(item)"
                              @updatePenaltyRule="event => updatePenaltyRuleHandler(item, event)"
                              @penaltyRuleInvalid="validRule = false"
                            />
                          </div>
                          <div class="formWrapper">
                            <penalty-parameters-form
                              penalty-name="upper"
                              class="penalty-form"
                              :penalty-rule="getPenaltyParameters(item, 'upper')"
                              :disabled="isTabDisabled(item)"
                              @updatePenaltyRule="event => updatePenaltyRuleHandler(item, event)"
                              @penaltyRuleInvalid="validRule = false"
                            />
                          </div>
                        </v-layout>
                      </v-flex>
                    </v-layout>
                  </v-flex>
                </v-layout>
              </v-tab-item>
            </v-tabs-items>

            <v-layout align-baseline justify-end>
              <v-btn
                v-show="!disabled"
                class="mt-2 mr-2"
                small
                color="primary"
                outlined
                depressed
                @click="cancel"
              >
                {{ $t('actions.cancel') }}
              </v-btn>
              <v-btn
                v-show="!disabled"
                color="success"
                :disabled="disableSaveBtn"
                class="mt-2 save"
                small
                depressed
                @click="save"
              >
                {{ $t('actions.save') }}
              </v-btn>
            </v-layout>
          </v-card>
        </v-card>
      </v-flex>
    </v-expand-transition>
  </div>
</template>

<script>
import {
  get,
  cloneDeep,
  isEqual,
  isEmpty,
  debounce,
  map,
  sortBy,
  find,
  round,
  forEach,
  uniq,
  uniqBy,
  size,
  some,
} from 'lodash';
import { mapState, mapActions } from 'vuex';
import { active, inactive } from '@enums/rule-status';
import { edit, create } from '@enums/dialog-modes';
import penaltyCategories from '@enums/penalty-categories';
import numberFormats from '@enums/number-formats';
import { displayStoreGroupPenalty, displayStoreGroupCompetitors } from '@enums/feature-flags';
import { competitorSources } from '@enums/source-types';
import { storegroupOption } from '@enums/filter-options';
import featureFlagsMixin from '../../../../mixins/featureFlags';
import colours from '../../../../ow-colors';
import { priceGroupOverrideRule, pricingGroupRule } from '@enums/rule-type';
import { architectureGroupLevel, pricingGroupLevel } from '@enums/hierarchy';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [featureFlagsMixin],
  props: {
    rulesetType: {
      type: String,
      required: true,
    },
    ruleIndex: {
      type: Number,
      required: true,
    },
    rule: {
      type: Object,
      required: true,
    },
    mode: {
      type: String,
      default: edit,
    },
    productCounts: {
      type: Array,
      default: () => [],
    },
    expandedKey: {
      type: String,
      default: () => '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: '',
    },
  },

  data() {
    return {
      forceRefresh: {},
      owWhite: colours.owWhite,
      tab: null,
      // TODO: look into vuelidate to check form input is dirty, (i.e. form has changed since it was last submitted)
      // rather than keeping multiple clones of the same object.
      // https://github.com/vuelidate/vuelidate
      mutableRule: cloneDeep(this.rule),
      savedRule: cloneDeep(this.rule), // overwritten with updates after Save pressed
      tabs: [],
      active,
      inactive,
      selectedCompetitorIndex: 0,
      selectedStoreGroupIndex: 0,
      offset: 0, // hack: This is left in so we start with a valid offset. It's updated to the right value before any checks.
      validRule: true,
      previousFieldValue: 0,
      rules: {
        offsetInput: [
          v => !!v || v === 0 || this.$t('settings.parameters.errors.fieldRequired'),
          v =>
            (v >= -100 && v <= 100) ||
            this.$t('settings.parameters.errors.minMaxValidation', { min: -100, max: 100 }),
        ],
      },
      competitorDropdownValues: [],
      storeGroupDropdownValues: [],
      penaltyCategories,
      numberFormats,
    };
  },

  computed: {
    ...mapState('competitorMetadata', ['competitors']),
    ...mapState('workpackages', ['selectedWorkpackageToolStoreGroups']),

    enableArchitectureFilter() {
      return [priceGroupOverrideRule, pricingGroupRule].includes(this.rulesetType);
    },

    hierarchyFilterLevel() {
      if (this.enableArchitectureFilter) {
        return architectureGroupLevel;
      }
      return pricingGroupLevel;
    },

    toolStoreGroupMap() {
      return this.selectedWorkpackageToolStoreGroups.reduce((result, tsg) => {
        result[tsg.key] = tsg.description;
        result[tsg.description] = tsg.key;
        return result;
      }, {});
    },

    allToolStoreGroupKeys() {
      return isEmpty(this.selectedWorkpackageToolStoreGroups)
        ? null
        : this.selectedWorkpackageToolStoreGroups.map(tsg => tsg.key);
    },

    selectedCompetitorPenalty() {
      return find(this.mutableRule.competitorPenalty.penalties, {
        competitorKey: this.selectedCompetitorIndex,
      });
    },

    selectedStoreGroupPenalty() {
      return find(this.mutableRule.storeGroupPenalty.penalties, {
        toolStoreGroupKey: this.selectedStoreGroupIndex,
      });
    },

    competitorIsActive: {
      get() {
        return isEmpty(this.selectedCompetitorPenalty)
          ? false
          : this.selectedCompetitorPenalty.isActive;
      },
      set(value) {
        this.$set(this.selectedCompetitorPenalty, 'isActive', value);
      },
    },

    storeGroupIsActive: {
      get() {
        if (isEmpty(this.selectedStoreGroupPenalty)) return false;
        return this.selectedStoreGroupPenalty.isActive;
      },
      set(value) {
        this.$set(this.selectedStoreGroupPenalty, 'isActive', value);
        if (value) {
          this.deactivateStoreGroupPenalties();
        }
      },
    },

    isEditMode() {
      return this.mode === edit;
    },

    isCreateMode() {
      return this.mode === create;
    },

    duplicateRangeMaximumsInPricePointing() {
      return (
        size(this.mutableRule.pricePointing.allowedValues) !==
        size(uniqBy(this.mutableRule.pricePointing.allowedValues, 'rangeMaximum'))
      );
    },

    missingEmptyPricePointingRange() {
      return !some(this.mutableRule.pricePointing.allowedValues, { rangeMaximum: null });
    },

    missingTokensForPricePointingRange() {
      return this.mutableRule.pricePointing.allowedValues.some(pp => isEmpty(pp.offsets));
    },

    disableSaveBtn() {
      if (
        !this.disabled &&
        this.validRule &&
        (this.isEditMode || this.isCreateMode) &&
        this.validateMinMax(this.offset, -100, 100) &&
        !this.duplicateRangeMaximumsInPricePointing &&
        !this.missingEmptyPricePointingRange &&
        !this.missingTokensForPricePointingRange
      ) {
        return isEqual(this.mutableRule, this.savedRule);
      }
      return true;
    },

    visibleTabs() {
      const tabs = [
        { id: penaltyCategories.economic },
        { id: penaltyCategories.competitor },
        { id: penaltyCategories.storeGroup, featureFlag: displayStoreGroupPenalty },
        { id: penaltyCategories.architecture },
        { id: penaltyCategories.pricePointing },
      ];
      return this.filterArrayByFeatureFlag(tabs).map(tab => tab.id);
    },

    storeGroupTab() {
      return this.tabs.find(tab => tab.penalty === penaltyCategories.storeGroup);
    },

    selectedToolStoreGroupsInScope() {
      const res = this.storeGroupTab ? this.getToolStoreGroupsFromScope(this.mutableRule) : [];

      return res;
    },

    displayStoreGroupPenaltyError() {
      const storeGroupTabStatus = get(
        this.mutableRule,
        [`${penaltyCategories.storeGroup}Penalty`, 'status'],
        this.inactive
      );
      return (
        storeGroupTabStatus === this.active &&
        this.storeGroupIsActive &&
        this.selectedStoreGroupPenalty.error
      );
    },
  },

  watch: {
    // TODO: think a better way of handling this. using watcher seems expensive
    // Update mutableRule and tabs whenever this.rule changes
    rule() {
      this.resetMutableRule();
    },
  },

  async created() {
    this.tabs = this.generateTabs();
    this.competitorDropdownValues = this.getCompetitorDropdownValues();
    this.selectedCompetitorIndex = this.competitorDropdownValues[0].value;
    this.updateStoreGroupTabData();
  },

  methods: {
    ...mapActions('rules', ['deleteRule']),

    isTabDisabled(tab) {
      return this.disabled || !tab || tab.disabled;
    },

    generateTabs() {
      return this.visibleTabs.map(penalty => ({
        penalty: `${penalty}`,
        iconText: this.$t(`settings.penaltyRules.${penalty}PenaltyInitial`),
        tabText: this.$t(`settings.penaltyRules.${penalty}PenaltyTabText`),
        status:
          penalty === penaltyCategories.pricePointing
            ? get(this.mutableRule, [`${penalty}`, 'status'], this.inactive)
            : get(this.mutableRule, [`${penalty}Penalty`, 'status'], this.inactive),
        iconClass: `${penalty}-penalty-traffic-light`,
        rule: this.mutableRule[`${penalty}Penalty`] || this.mutableRule[`${penalty}`],
      }));
    },

    getCompetitorDropdownValues() {
      const competitorsInUse = this.isFeatureFlagEnabled(displayStoreGroupCompetitors)
        ? this.competitors
        : this.competitors.filter(c => c.competitorType !== competitorSources.storeGroup);
      return map(competitorsInUse, item => ({
        text: item.competitorDisplayDescription,
        value: item.competitorKey,
      }));
    },

    setStatus(item) {
      if (get(this.mutableRule[`${item.penalty}`], 'status')) {
        this.mutableRule[`${item.penalty}`].status = item.status;
      } else this.mutableRule[`${item.penalty}Penalty`].status = item.status;
    },

    updateRuleScope(newScope) {
      this.mutableRule.scope = cloneDeep(newScope);
      this.updateStoreGroupTabData();
    },

    getStoreGroupDropdownValues(toolStoreGroupFilters) {
      const keys = this.allToolStoreGroupKeys.filter(key => !toolStoreGroupFilters.includes(key));
      return keys.map(key => ({
        text: this.toolStoreGroupMap[key],
        value: key,
      }));
    },

    deactivateStoreGroupPenalties() {
      const nonSelectedPenalties = this.mutableRule.storeGroupPenalty.penalties.filter(
        penalty =>
          penalty.toolStoreGroupKey && penalty.toolStoreGroupKey !== this.selectedStoreGroupIndex
      );
      if (isEmpty(nonSelectedPenalties)) return;
      nonSelectedPenalties.forEach(penalty => {
        if (penalty.isActive) {
          penalty.isActive = false;
        }
      });
    },

    setPreselectedStoreGroup() {
      if (!this.storeGroupDropdownValues.length) {
        this.selectedStoreGroupIndex = null;
        return;
      }
      const selectedStoreGroup = this.storeGroupDropdownValues.find(
        item => item.value === this.selectedStoreGroupIndex
      );
      if (selectedStoreGroup) {
        return;
      }
      this.selectedStoreGroupIndex = this.storeGroupDropdownValues[0].value;
    },

    resetStoreGroupTabToInitialState() {
      this.storeGroupTab.disabled = true;
      this.storeGroupTab.tooltipMessage = this.$t('settings.penaltyRules.disabledStoreGroupTab');
      this.selectedStoreGroupIndex = null;
      this.storeGroupDropdownValues = [];
      const newPenalties = this.mutableRule.storeGroupPenalty.penalties.filter(
        penalty => !penalty.toolStoreGroupKey
      );
      this.$set(this.mutableRule.storeGroupPenalty, 'penalties', newPenalties);
      if (this.storeGroupTab.status !== inactive) {
        this.$set(this.storeGroupTab, 'status', inactive);
        this.setStatus(this.storeGroupTab);
      }
    },

    removeUnusedStoreGroupPenalties() {
      if (!this.storeGroupDropdownValues || !this.storeGroupDropdownValues.length) {
        const newPenalties = this.mutableRule.storeGroupPenalty.penalties.filter(
          penalty => !penalty.toolStoreGroupKey
        );
        this.$set(this.mutableRule.storeGroupPenalty, 'penalties', newPenalties);
        return;
      }
      const newPenalties = this.mutableRule.storeGroupPenalty.penalties.filter(
        penalty =>
          !penalty.toolStoreGroupKey ||
          this.storeGroupDropdownValues.find(group => group.value === penalty.toolStoreGroupKey)
      );
      this.$set(this.mutableRule.storeGroupPenalty, 'penalties', newPenalties);
    },

    updateStoreGroupTabData() {
      if (!this.storeGroupTab) {
        return;
      }
      if (isEmpty(this.selectedToolStoreGroupsInScope)) {
        this.resetStoreGroupTabToInitialState();
        return;
      }
      this.storeGroupTab.tooltipMessage = null;
      this.storeGroupDropdownValues = this.getStoreGroupDropdownValues(
        this.selectedToolStoreGroupsInScope
      );
      this.setPreselectedStoreGroup();
      this.removeUnusedStoreGroupPenalties();
      this.storeGroupTab.disabled = !this.selectedStoreGroupIndex;
      if (this.storeGroupTab.status !== inactive && this.storeGroupTab.disabled) {
        this.$set(this.storeGroupTab, 'status', inactive);
        this.setStatus(this.storeGroupTab);
      }
    },

    getCompetitorRuleFromItem(item) {
      let penalty = find(item.rule.penalties, { competitorKey: this.selectedCompetitorIndex });

      if (!item.rule.penalties || !penalty) {
        penalty = {
          ...item.rule.defaultPenalty,
          competitorKey: this.selectedCompetitorIndex,
          competitorName: find(this.competitors, { competitorKey: this.selectedCompetitorIndex })
            .competitorDescription,
          isActive: false,
        };
        item.rule.penalties.push(penalty);
      }
      return penalty;
    },

    getStoreGroupRuleFromItem(item) {
      let penalty = find(item.rule.penalties, { toolStoreGroupKey: this.selectedStoreGroupIndex });
      if (!item.rule.penalties || !penalty) {
        penalty = {
          ...item.rule.defaultPenalty,
          toolStoreGroupKey: this.selectedStoreGroupIndex,
          isActive: false,
        };
        item.rule.penalties.push(penalty);
      }
      return penalty;
    },

    getPenaltyRuleFromItem(item) {
      if (item.penalty === penaltyCategories.competitor) {
        return this.getCompetitorRuleFromItem(item);
      }
      if (item.penalty === penaltyCategories.storeGroup) {
        return this.getStoreGroupRuleFromItem(item);
      }
      return item.rule;
    },

    getPenaltyParameters(item, penaltyName) {
      const penaltyRule = this.getPenaltyRuleFromItem(item);
      return get(penaltyRule, penaltyName);
    },

    resetMutableRule() {
      this.mutableRule = cloneDeep(this.rule);
      this.tabs = this.generateTabs();
      this.updateStoreGroupTabData();
    },

    cancel() {
      if (this.isEditMode) {
        this.resetMutableRule();
        this.toggleExpanded(this.rulesetType, this.ruleIndex);
        return;
      }
      this.$emit('cancelNewRule');
    },

    removeUnusedDataFromRuleBeforeSaving(rule) {
      if (rule.storeGroupPenalty) {
        const group = rule.storeGroupPenalty;
        group.penalties = group.penalties.filter(penalty => !!penalty.toolStoreGroupKey);
      }
    },

    async save() {
      // sort the price pointing entries on rangeMaximum, null values for rangeMaximum should be at bottom
      const sortedPricePointingAllowedValues = sortBy(
        this.mutableRule.pricePointing.allowedValues,
        'rangeMaximum'
      );
      forEach(sortedPricePointingAllowedValues, allowedValue => {
        allowedValue.offsets = sortBy(uniq(allowedValue.offsets));
      });
      this.$set(this.mutableRule.pricePointing, 'allowedValues', sortedPricePointingAllowedValues);
      const rule = cloneDeep(this.mutableRule);
      this.removeUnusedDataFromRuleBeforeSaving(rule);
      if (!this.isEditMode) {
        this.$emit('saveNewRule', rule, this.rulesetType);
        return;
      }
      // updates object can't contain _id field
      const { _id, ...updates } = rule;
      let mostRecentTab;
      if (!isEmpty(this.forceRefresh)) {
        mostRecentTab = Object.keys(this.forceRefresh).reduce((key, v) =>
          this.forceRefresh[v] > this.forceRefresh[key] ? v : key
        );
      } else mostRecentTab = penaltyCategories.economic;
      await this.$emit('updateRule', { id: this.savedRule._id, updates });
      this.savedRule = cloneDeep(this.mutableRule);
      // If price pointing has been updated we do not collapse the rule
      // and it is the most recent tab opened
      if (mostRecentTab !== penaltyCategories.pricePointing)
        this.toggleExpanded(this.rulesetType, this.ruleIndex);
    },

    updatePenaltyRuleHandler(item, { penaltyName, updatedParameters }) {
      this.validRule = true; // this handler is only triggered if the other params are already valid.
      if (this.validateMinMax(this.offset, -100, 100)) {
        // all other validations handled in penalty-parameters-form
        const penaltyRule = this.getPenaltyRuleFromItem(item);
        penaltyRule[penaltyName] = cloneDeep(updatedParameters);
      }
    },

    verifyFieldValue(event) {
      const value = event.target.value;
      if (Number(value.replace(this.$t('number.decimalPoint'), '.')) === 0) return;
      if (!value.trim() || !Number(value.replace(this.$t('number.decimalPoint'), '.'))) {
        // restores previous value in case of input field value is not valid
        event.target.value = this.previousFieldValue;
      }
    },

    absoluteOffsetChange(newValue, item) {
      let absoluteOffset = Number(newValue.replace(this.$t('number.decimalPoint'), '.'));
      if (!absoluteOffset && absoluteOffset !== 0) return;
      absoluteOffset = round(absoluteOffset, 2);
      const rule = this.getPenaltyRuleFromItem(item);
      this.$set(rule, 'absoluteOffset', absoluteOffset);
    },

    debounceOffsetChange: debounce(function(item, value) {
      this.offset = value;
      const rule = this.getPenaltyRuleFromItem(item);
      rule.penaltyOffset = +value;
    }, clientConfig.inputDebounceValue),

    tabChange(item) {
      // updating key forces a refresh of the component
      // we need to force a refresh as when the chart loads
      // the parent element is 'display:none' there is no access to width
      // and height which highcharts requires to build out
      // the chart nicely.
      this.forceRefresh[item.penalty] = Date.now();
    },

    isCompetitorActive(index) {
      const competitor = find(this.mutableRule.competitorPenalty.penalties, {
        competitorKey: index,
      });
      return isEmpty(competitor) ? false : competitor.isActive;
    },

    isStoreGroupActive(index) {
      const storeGroup = find(this.mutableRule.storeGroupPenalty.penalties, {
        toolStoreGroupKey: index,
      });
      return isEmpty(storeGroup) ? false : storeGroup.isActive;
    },

    validateMinMax(val, min, max) {
      return val >= min && val <= max;
    },

    isExpanded(rulesetType, ruleIndex) {
      return this.expandedKey === `${rulesetType}-${ruleIndex}`;
    },

    toggleExpanded(rulesetType, ruleIndex) {
      if (this.isExpanded(rulesetType, ruleIndex)) {
        this.$emit('setExpandedKey', '');
        return;
      }
      this.$emit('setExpandedKey', `${rulesetType}-${ruleIndex}`);
    },

    updateDescription(description) {
      this.mutableRule.description = description;
    },

    startPricePointingCreation() {
      this.mutableRule.pricePointing.allowedValues.push({
        rangeMaximum: null,
        base: null,
        offsets: [],
      });
    },
    deletePricePointing({ index }) {
      this.mutableRule.pricePointing.allowedValues.splice(index, 1);
    },
    updateRangeMaximum({ updatedRangeMaximum, index }) {
      this.$set(
        this.mutableRule.pricePointing.allowedValues[index],
        'rangeMaximum',
        isEmpty(updatedRangeMaximum) ? null : Number(updatedRangeMaximum)
      );
    },
    updateOffset({ updatedOffsets, index }) {
      // ensures reactivity
      this.mutableRule.pricePointing.allowedValues[index].offsets = [];
      this.mutableRule.pricePointing.allowedValues[index].offsets.push(...updatedOffsets);
    },
    updateBase({ updatedBase, index }) {
      this.$set(this.mutableRule.pricePointing.allowedValues[index], 'base', Number(updatedBase));
    },
    /**
     * Returns keys of tool store groups selected in the scope of a rule.
     * @param rule
     * @return {string[]}
     */
    getToolStoreGroupsFromScope(rule) {
      if (!rule || !rule.scope || isEmpty(this.toolStoreGroupMap)) return [];
      const filter = rule.scope.find(attr => attr.attributeFilterType === storegroupOption);

      if (!filter || isEmpty(filter.attributeValue)) {
        return [];
      }

      return filter.attributeValue;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.rule-content {
  height: 100%;
  overflow: scroll;

  &::v-deep .v-label {
    font-size: 1.2rem;
  }

  .formWrapper {
    background-color: #e7e7e7;
    padding: 1.5rem;

    &::v-deep .v-label {
      top: 0;
    }

    &::v-deep input {
      padding: 0.3rem;
    }

    &::v-deep .v-select__selection--comma {
      margin: 0;
      padding: 0.3rem;
    }

    &::v-deep .v-select__slot {
      height: 2.6rem;
    }
  }

  .penalty-form {
    min-width: 15rem;
  }
  .chart-container {
    min-width: 57rem;
  }

  .activate-checkbox {
    margin-top: 2rem;
    justify-content: flex-end;
  }

  .tab-title {
    margin-left: 0.5rem;
    margin-top: 0.5rem;
  }

  .headline {
    font-size: 1.5rem !important;
  }

  .traffic-light {
    margin-right: 0.5rem;
  }

  .economic-penalty-traffic-light {
    background-color: $economicTrafficLightColor;
  }

  .competitor-penalty-traffic-light {
    background-color: $competitorTrafficLightColor;
  }

  .storeGroup-penalty-traffic-light {
    background-color: $storeGroupTrafficLightColor;
  }

  .architecture-penalty-traffic-light {
    background-color: $architectureTrafficLightColor;
  }

  .pricePointing-penalty-traffic-light {
    background-color: $pricePointingTrafficLightColor;
  }

  .other-penalty-traffic-light {
    background-color: $otherTrafficLightColor;
  }

  .tab-content {
    font-size: 1.6rem;
  }

  .btn-block {
    height: calc(100% - 11rem);
  }

  .prefix,
  .suffix {
    font-size: 1.3rem;
    padding: 0;
  }

  .prefix {
    padding-right: 0.5rem;
  }

  .suffix {
    padding-left: 0.5rem;
  }

  .field-heading {
    font-size: 1.2rem !important;
  }

  .v-input--checkbox {
    font-size: 1.2rem;
  }

  .error-message {
    font-size: 1.2rem;
    margin-right: 0.5rem;
  }
}

.expanded-bottom {
  box-shadow: 0 0.3rem 0.5rem -0.1rem rgba(0, 0, 0, 0.2), 0 0.6rem 1rem 0 rgba(0, 0, 0, 0.14);
  border-top-left-radius: 0;
  border-top-right-radius: 0;
}

.rule-card {
  padding: 1rem 1rem 1rem 1rem;
  transition: none;

  &.expanded-top {
    margin-bottom: 0;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
}

.card-text {
  font-size: 1.25rem;
}

.rule-content {
  padding-bottom: 1rem;
}

.add-pricepointing-text {
  font-size: 1.25rem;
  margin-left: 1rem;
}

.add-pricepointing-container {
  display: flex;
  align-items: center;
  flex: 1 0 0;
}

.add-pricepointing-icon {
  color: $rulesetDarkBlue;
  padding-left: 0;
}

.opaque {
  opacity: 0.5;
}
</style>
