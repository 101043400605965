import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VExpansionPanels,
    [
      _c(
        VExpansionPanel,
        { staticClass: "mb-4", attrs: { focusable: "" } },
        [
          _c(
            VExpansionPanelHeader,
            {
              staticClass: "panel-header",
              scopedSlots: _vm._u([
                {
                  key: "actions",
                  fn: function () {
                    return [_vm._m(0)]
                  },
                  proxy: true,
                },
              ]),
            },
            [
              _c(
                "div",
                { staticClass: "body-2" },
                [
                  _c("span", { staticClass: "filter-text-title" }, [
                    _vm._v(_vm._s(_vm.$t(_vm.titleLocalisation)) + ":"),
                  ]),
                  _vm._v(" "),
                  _vm._m(1),
                  _vm._v(" "),
                  _c("span", { staticClass: "font-weight-bold filter-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(
                          _vm.$tc(
                            _vm.filterCountLocalisation,
                            _vm.numFiltersApplied
                          )
                        ) +
                        "\n        "
                    ),
                  ]),
                ],
                1
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VExpansionPanelContent,
            { staticClass: "panel-header" },
            [
              _c(
                VCardText,
                { staticClass: "px-6 pt-0" },
                [
                  _vm._l(_vm.mutableFilterRules, function (rule, index) {
                    return _c(
                      VFlex,
                      { key: rule.key },
                      [
                        _vm.mutableFilterRules.length > 1 && index > 0
                          ? _c(VDivider, { key: index, staticClass: "my-2" })
                          : _vm._e(),
                        _vm._v(" "),
                        _c("attribute-filter", {
                          key: rule.key,
                          attrs: {
                            "ruleset-type": _vm.rulesetType,
                            "filter-rule": rule.rule,
                            "selected-attribute-metadata": _vm.nonEmptyRules,
                            "available-attribute-metadata":
                              _vm.availableAttributeMetadata,
                          },
                          on: {
                            "update-filter-attributes": function ($event) {
                              return _vm.updateAttributeFilter($event, rule.key)
                            },
                            "remove-filter": function ($event) {
                              return _vm.removeAttributeFilter(rule.key)
                            },
                            "filter-blurred": _vm.emitAttributeFilterChange,
                          },
                        }),
                      ],
                      1
                    )
                  }),
                  _vm._v(" "),
                  _c(
                    VLayout,
                    { attrs: { row: "" } },
                    [
                      _c(
                        VBtn,
                        {
                          attrs: {
                            right: "",
                            icon: "",
                            text: "",
                            depressed: "",
                          },
                          on: { click: _vm.addAttributeFilter },
                        },
                        [_vm._m(2)],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { color: "indigo darken-2" } }, [
      _vm._v("expand_more"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { staticClass: "filter-text", attrs: { size: "2.75rem" } },
      [_vm._v("filter_list")]
    )
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      { attrs: { color: "indigo darken-2", size: "2.75rem" } },
      [_vm._v("add_box")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }