<template>
  <v-app>
    <link
      href="https://fonts.googleapis.com/css?family=Source+Sans+Pro&display=swap"
      rel="stylesheet"
    />
    <!-- New stylesheets and fonts should be added to CSP Directives (webtool/config/config.js) -->
    <link href="https://fonts.googleapis.com/css?family=Material+Icons" rel="stylesheet" />
    <link
      href="https://cdn.jsdelivr.net/npm/@mdi/font@4.x/css/materialdesignicons.min.css"
      rel="stylesheet"
    />
    <main-navbar v-if="!hideNavbar" />
    <v-main>
      <v-container fluid>
        <transition name="fade" mode="out-in">
          <router-view id="main-page" class="main-page" />
        </transition>
      </v-container>
    </v-main>

    <div
      v-for="notification in notifications"
      :key="`${notification.id}::${notification.jobStatus}`"
    >
      <v-snackbar
        v-if="getNotificationPayload(notification).length"
        :value="true"
        :timeout="popupTimeout"
        :color="get(notificationColours, notification.jobStatus, 'accent')"
        bottom
      >
        <div class="notification-wrapper">
          {{ getNotificationPayload(notification) }}
        </div>
      </v-snackbar>
    </div>

    <main-footer />
    <div id="consent_blackbar" />
  </v-app>
</template>

<script>
import { find, get, map, isEmpty } from 'lodash';
import { mapGetters, mapActions, mapState } from 'vuex';
import clientConfig from '@sharedModules/config/client';
import { jobStatuses } from '@enums/jobapi';

export default {
  data() {
    return {
      snackbars: {},
      popupTimeout: clientConfig.popupTimeout,
      notificationColours: {
        [jobStatuses.starting]: 'info',
        [jobStatuses.finished]: 'success',
        [jobStatuses.argoFinished]: 'success',
        [jobStatuses.finishedCreationRecalculate]: 'success',
        [jobStatuses.failed]: 'error',
        [jobStatuses.argoFailed]: 'error',
        // replaces starting notification for run_pricing_for_all (to prevent delay while task is picked up)
        [jobStatuses.sentToJobApi]: 'info',
        [jobStatuses.sentToJobApiFromCreation]: 'info',
        [jobStatuses.noResults]: 'info',
      },
    };
  },

  computed: {
    ...mapGetters('notifications', ['notifications']),
    ...mapState('clientConfig', ['i18nTranslations', 'showCookieBanner']),
    ...mapState('context', ['profile']),

    topLevelRoute() {
      const topLevelNavPath = get(this, '$route.matched.0.path');
      return find(this.$router.options.routes, { path: topLevelNavPath }) || {};
    },

    hideNavbar() {
      if (!this.topLevelRoute.meta) return false;
      return this.topLevelRoute.meta.hideNavbar;
    },
  },

  // There is nowhere in the template to trigger a computed
  // This is an exception rather than the rule
  watch: {
    $route(to) {
      this.setTabText(to);
    },

    i18nTranslations() {
      // client-specific translations are loaded after login
      // we can't change access the i18n instance in vuex so watching it here
      this.setI18nTranslations();
      // set translated tab text
      this.setTabText(this.$route);
    },
  },

  mounted() {
    if (this.showCookieBanner) {
      const cookieScript = document.createElement('script');
      cookieScript.setAttribute('async', 'async');
      cookieScript.setAttribute('crossorigin', 'crossorigin');
      cookieScript.setAttribute(
        'src',
        '//cmp.osano.com/16CFuWSuPfE35eNP/9e220dc6-b4e9-4b7d-8de4-9aa0e81a60b0/osano.js'
      );
      document.body.appendChild(cookieScript);
    }
  },

  async created() {
    // Handles refresh and landing at a page using URL
    this.setI18nTranslations();
    this.setTabText(this.$route);

    // to handle the case where we want to push state into a new tab.
    // e.g. state.filters.newTabPricingGroup to preselect filters.
    this.handleNewTabFilterState();
  },

  methods: {
    ...mapActions('filters', ['handleNewTabFilterState']),

    getNotificationPayload(notification) {
      return this.$t(`${notification.baseTranslation}.${notification.jobStatus}`, {
        notificationPayload: notification.notificationPayload,
      });
    },

    setI18nTranslations() {
      map(this.i18nTranslations, (messages, lang) => {
        this.$i18n.setLocaleMessage(lang, messages);
      });
    },

    setTabText(route) {
      if (isEmpty(this.i18nTranslations)) {
        document.title = 'Pricing';
      } else {
        document.title = route.meta.title
          ? `${this.$t(route.meta.title)} - ${this.$t('tabText.pricingTool')}`
          : this.$t('tabText.pricingTool');
      }
    },

    get,
  },
};
</script>

<style lang="scss">
.v-application--wrap {
  // account for footer in v-app. default is min-height: 100vh
  // without this page will scroll too far down, causing elements with position fixed / sticky to float on top of position relative content.
  // e.g. on attributes page, scrolling down to the bottom of the page will put the filter over the table headers. worse if filters are open.
  min-height: calc(100vh - 20rem) !important;

  .v-snack__content {
    font-size: 1.25rem;
    line-height: 21px;
  }

  .notification-wrapper {
    word-wrap: break-word;
    width: 100%;
    color: white; // Vuetify 2.7.2 chaged styles overrides
    font-size: 14px; // Vuetify 2.7.2 chaged styles overrides
  }

  .v-snack__wrapper {
    border-radius: 4px; // Vuetify 2.7.2 chaged styles overrides
  }
}
</style>
