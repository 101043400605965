'use strict';

module.exports = {
  yearMonthDayFormat: 'YYYY-MM-DD',
  yearMonthDayHoursMinutesFormat: 'YYYY-MM-DD HHmm',
  yearMonthDayHoursMinutesSecondsFormat: 'YYYY-MM-DD HH:mm:ss',
  hoursMinutesDayMonthYearFormat: 'HH mm DD MM YYYY',
  utcFormat: 'YYYY-MM-DDThh:mm:ssZ',
  monthDayYearSlashesFormat: 'MM/D/YYYY',
  dayMonthYearFormat: 'DD-MM-YYYY',
};
