import { VDivider } from 'vuetify/lib/components/VDivider';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "dropdown-list-item__container" },
    [
      _vm.divider ? _c(VDivider) : _vm._e(),
      _vm._v(" "),
      _c(
        VListItem,
        {
          attrs: { disabled: _vm.disabled },
          on: { click: _vm.emitSelectOption },
        },
        [
          _c(
            VListItemAvatar,
            { staticClass: "dropdown-list-item__avatar" },
            [
              _c("i", {
                staticClass: "mdi dropdown-list-item__icon clickable",
                class: [
                  `mdi-${_vm.iconClass}`,
                  { "dropdown-list-item__icon--disabled": _vm.disabled },
                ],
              }),
            ]
          ),
          _vm._v(" "),
          _c(
            VListItemContent,
            [
              _c(VListItemTitle, [
                _vm._v("\n        " + _vm._s(_vm.title) + "\n      "),
              ]),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }