var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("grid-view", {
    attrs: {
      headers: _vm.headersWithUniqueValues,
      items: _vm.filteredItems,
      "level-entry-key": _vm.levelEntryKey,
      "is-unit-manager-view": _vm.isUnitManagerView,
      "architecture-group-description-to-color-map":
        _vm.architectureGroupDescriptionToColorMap,
      "architecture-sub-group-description-to-color-map":
        _vm.architectureSubGroupDescriptionToColorMap,
    },
    on: {
      downloadData: function ($event) {
        return _vm.downloadDataHandler()
      },
      toggleFilteringByArchitectureGroup:
        _vm.onToggleFilteringByArchitectureGroup,
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }