var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "19px",
        height: "18px",
        viewBox: "0 0 19 18",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Page-1",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c("g", { attrs: { id: "Group-32" } }, [
            _c("rect", {
              attrs: {
                id: "Rectangle",
                fill: "#EA9B66",
                x: "0",
                y: "0",
                width: "19",
                height: "16.8",
                rx: "2",
              },
            }),
            _vm._v(" "),
            _c(
              "text",
              {
                attrs: {
                  id: "PR",
                  "font-family": "SourceSansPro-Semibold, Source Sans Pro",
                  "font-size": "12",
                  "font-weight": "500",
                  "line-spacing": "14",
                  fill: "#FFFFFF",
                },
              },
              [_c("tspan", { attrs: { x: "2.7", y: "12.8" } }, [_vm._v("PR")])]
            ),
          ]),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }