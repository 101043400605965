import _ from 'lodash';

/**
 * Checks whether the text should be truncated and what truncation length should be used to do that.
 * The text should be truncated if it's longer than a given maximum string length.
 * The given maximum string length is defined as follows:
 * 1) if ignoreRegistry is true => truncationLength;
 * 2) if ignoreRegistry is false and the text does not consist of only capital letters => truncationLength;
 * 3) if ignoreRegistry is false and the text consists of only capital letters => Math.floor(0.85 * truncationLength).
 * Uppercase letters are wider than lowercase. That is why we need to artificially decrease truncationLength.
 * @param {text: string, truncationLength: number, ignoreRegistry: boolean}
 * text - the string to check for.
 * truncationLength - the maximum expected truncation length.
 * ignoreRegistry - indicates whether to take into account registry when computing maximum string length.
 * @returns an object contained two properties: shouldTruncate (boolean) and truncationLength (number).
 */
export const shouldTruncateString = ({ text, truncationLength, ignoreRegistry }) => {
  const stringValue = String(text);
  if (stringValue !== '' && !stringValue) {
    return { shouldTruncate: false, truncationLength };
  }
  if (ignoreRegistry) {
    const shouldTruncate = stringValue.length > truncationLength;
    return {
      shouldTruncate,
      truncationLength,
    };
  }
  const isUppercasedText = `${stringValue}`.toUpperCase() === stringValue;
  const modifiedTruncationLength = isUppercasedText
    ? Math.floor(0.85 * truncationLength)
    : truncationLength;
  const shouldTruncate = stringValue.length > modifiedTruncationLength;
  return {
    shouldTruncate,
    truncationLength: modifiedTruncationLength,
  };
};

/**
 * Truncates string if it's longer than a maximum string length.
 * The given maximum string length is defined as follows:
 * 1) if ignoreRegistry is true => truncationLength;
 * 2) if ignoreRegistry is false and the text does not consist of only capital letters => truncationLength;
 * 3) if ignoreRegistry is false and the text consists of only capital letters => Math.floor(0.85 * truncationLength).
 * Uppercase letters are wider than lowercase. That is why we need to artificially decrease truncationLength.
 * @param {text: string, truncationLength: number, ignoreRegistry: boolean}
 * text - the string to truncate.
 * truncationLength - the maximum expected truncation length.
 * ignoreRegistry - indicates whether to take into account registry when computing maximum string length.
 * @returns an object contained two properties: wasTruncated (boolean) and truncatedValue (string).
 */
export const truncateString = ({ text, truncationLength, ignoreRegistry }) => {
  const { shouldTruncate, truncationLength: modifiedTruncationLength } = shouldTruncateString({
    text,
    truncationLength,
    ignoreRegistry,
  });
  return {
    wasTruncated: shouldTruncate,
    truncatedValue: shouldTruncate
      ? _.truncate(`${text}`, { length: modifiedTruncationLength })
      : text,
  };
};

export default truncateString;
