import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
      attrs: { column: "" },
    },
    [
      _c(
        "div",
        { staticClass: "sticky-header-group" },
        [
          _c(
            "div",
            { staticClass: "pa-2" },
            [
              _c(
                "div",
                { staticClass: "page-rule-heading pr-10 d-inline-flex" },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("settings.architecture.pageHeading")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c("scenario-filter", { staticClass: "d-inline-flex" }),
            ],
            1
          ),
          _vm._v(" "),
          _c("architecture-group-filter"),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VFlex,
        { attrs: { "mt-3": "" } },
        [
          _c("architecture-drivers-rulesets", {
            staticClass: "architecture-expansion",
            attrs: {
              disabled: !_vm.isRulesEnabled,
              editable: _vm.canEditRules,
            },
            on: {
              panelChange: function ($event) {
                return _vm.setArchitectureOpen($event.value === 0)
              },
            },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VFlex,
        { attrs: { "mt-3": "" } },
        [
          _c("line-pricing", {
            staticClass: "architecture-expansion",
            attrs: {
              disabled: !_vm.isRulesEnabled,
              editable: _vm.canEditRules,
            },
            on: {
              panelChange: function ($event) {
                return _vm.setLinePricingOpen($event.value === 0)
              },
            },
          }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }