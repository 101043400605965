<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Upcoming Promotion</title>
    <g
      id="product-before-promotion-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g
        id="product-before-promotion-transform"
        transform="translate(-138.000000, -680.000000)"
        fill="#6D7278"
      >
        <path
          id="product-before-promotion"
          d="M144,693.999999 L149,698.999999 L144,703.999999 L144,700.999999 L139,700.999999 C138.4477,700.999999 138,700.552279 138,699.999999 L138,697.999999 C138,697.447719 138.4477,696.999999 139,696.999999 L144,696.999999 L144,693.999999 Z M152.678264,679.999999 C153.038783,679.999999 153.386397,680.12532 153.662871,680.35184 L153.776835,680.455046 L161.544981,688.223204 C162.113755,688.791978 162.149303,689.692157 161.651626,690.302357 L161.544981,690.420346 L154.420348,697.544979 C153.851574,698.113753 152.951395,698.149302 152.341195,697.651625 L152.223206,697.544979 L144.455047,689.776833 C144.200105,689.521873 144.042916,689.187454 144.007602,688.83181 L144,688.678262 L144,681.553629 C144,680.743252 144.620444,680.077787 145.412219,680.006348 L145.553631,679.999999 L152.678264,679.999999 Z M152.504731,682.00149 L146.00479,682.00149 L146.00479,688.496617 L153.324214,695.819999 L159.82,689.314212 L152.504731,682.00149 Z M149,683.499999 C149.828427,683.499999 150.5,684.171571 150.5,684.999999 C150.5,685.828426 149.828427,686.499999 149,686.499999 C148.171573,686.499999 147.5,685.828426 147.5,684.999999 C147.5,684.171571 148.171573,683.499999 149,683.499999 Z"
        />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'ProductBeforePromotion',
};
</script>
