import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: {
        "max-width": "450",
        "no-click-animation": "",
        "hide-overlay": "",
        value: _vm.isOpen,
        attach: _vm.attachTo,
        persistent: "",
      },
    },
    [
      _c(
        VCard,
        {
          staticClass: "wholesale-grid-card card-container",
          class: {
            "border-error": _vm.exportFailed,
            small: _vm.exportFailed || _vm.exportNoResults,
          },
        },
        [
          _vm.exportFailed || _vm.exportNoResults
            ? [
                _c(VCardTitle, [
                  _vm.exportFailed
                    ? _c("p", { staticClass: "completed-label mb-1 hint" }, [
                        _c("span", { staticClass: "error-hint" }, [
                          _vm._v(_vm._s(_vm.$t("general.error")) + ": "),
                        ]),
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("wholesale.export.modal.errorHint")) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _vm.exportNoResults
                    ? _c("p", { staticClass: "completed-label mb-1 hint" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t("wholesale.export.modal.noResultsHint")
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                ]),
                _vm._v(" "),
                _c(
                  VCardText,
                  [
                    _vm.exportFailed
                      ? _c("p", { staticClass: "error-explanation mb-4" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(_vm.$t("wholesale.export.modal.error")) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.exportNoResults
                      ? _c("p", { staticClass: "error-explanation mb-4" }, [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t("wholesale.export.modal.noResults")
                              ) +
                              "\n        "
                          ),
                        ])
                      : _vm._e(),
                    _vm._v(" "),
                    _vm.allowFTPExport
                      ? [
                          _c(VCardActions, [
                            _c("div", { staticClass: "button-container" }, [
                              _c(
                                "button",
                                {
                                  staticClass: "close-action",
                                  on: { click: _vm.resetLatestWholesaleExport },
                                },
                                [
                                  _vm._v(
                                    "\n                " +
                                      _vm._s(_vm.$t("actions.close")) +
                                      "\n              "
                                  ),
                                ]
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c(
                            "div",
                            {
                              staticClass: "close-icon",
                              on: { click: _vm.resetLatestWholesaleExport },
                            },
                            [_c("close")],
                            1
                          ),
                        ]
                      : _vm._e(),
                  ],
                  2
                ),
              ]
            : [
                _c(VCardTitle, { staticClass: "justify-space-between" }, [
                  _c("span", { staticClass: "grid-description" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(_vm.$t("wholesale.export.modal.title")) +
                        "\n        "
                    ),
                  ]),
                ]),
                _vm._v(" "),
                _c(
                  VCardText,
                  [
                    _c(VDivider, { staticClass: "mb-2" }),
                    _vm._v(" "),
                    _c(
                      "div",
                      { staticClass: "pt-5 px-8" },
                      [
                        _c(VProgressLinear, {
                          staticClass: "mb-4",
                          attrs: {
                            value: _vm.latestWholesaleExport.progress,
                            color: _vm.exportFailed ? "error" : "primary",
                            rounded: "",
                            indeterminate: _vm.inProgress,
                            height: "15",
                          },
                        }),
                        _vm._v(" "),
                        _vm.exportSuccessful
                          ? [
                              _c(
                                "p",
                                { staticClass: "completed-label mb-8" },
                                [
                                  !_vm.downloadingFile
                                    ? _c(
                                        "i18n",
                                        {
                                          attrs: {
                                            path: "wholesale.export.modal.successHint",
                                            tag: "span",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.latestWholesaleExport
                                                  .numFiles
                                              )
                                            ),
                                          ]),
                                        ]
                                      )
                                    : _c(
                                        "i18n",
                                        {
                                          attrs: {
                                            path: "wholesale.export.modal.downloading",
                                            tag: "span",
                                          },
                                        },
                                        [
                                          _c("strong", [
                                            _vm._v(
                                              _vm._s(
                                                _vm.latestWholesaleExport
                                                  .numFiles
                                              )
                                            ),
                                          ]),
                                        ]
                                      ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("p", { staticClass: "hint px-6 mb-2" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.$t(
                                        "wholesale.export.modal.destination"
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("div", { staticClass: "button-container" }, [
                                _c(
                                  "div",
                                  { staticClass: "export-button pr-2" },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "export-button px-0",
                                        attrs: {
                                          disabled: _vm.downloadingFile,
                                          color: "primary",
                                          small: "",
                                          depressed: "",
                                        },
                                        on: { click: _vm.onDownloadClick },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "wholesale.export.modal.download"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                        _vm.latestWholesaleExport.fileSizeMB
                                          ? _c("span", [
                                              _vm._v(
                                                "\n                     " +
                                                  _vm._s(
                                                    _vm.latestWholesaleExport
                                                      .fileSizeMB
                                                  ) +
                                                  "MB\n                  "
                                              ),
                                            ])
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _c(
                                          "div",
                                          {
                                            staticClass: "cta-button download",
                                          },
                                          [_c("download")],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                                _vm._v(" "),
                                _c(
                                  "div",
                                  { staticClass: "export-button pl-2" },
                                  [
                                    _c(
                                      VBtn,
                                      {
                                        staticClass: "export-button px-0",
                                        attrs: {
                                          disabled:
                                            _vm.downloadingFile ||
                                            !_vm.allowFTPExport,
                                          loading: _vm.exportInProgress,
                                          color: "primary",
                                          small: "",
                                          depressed: "",
                                        },
                                        on: { click: _vm.onFTPExportClick },
                                      },
                                      [
                                        _vm._v(
                                          "\n                  " +
                                            _vm._s(
                                              _vm.$t(
                                                "wholesale.export.modal.exportToFTP"
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                        _c(
                                          "div",
                                          { staticClass: "cta-button export" },
                                          [_c("export")],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          : [
                              _c("p", { staticClass: "hint px-6 mb-2" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t("wholesale.export.modal.pending")
                                  )
                                ),
                              ]),
                            ],
                        _vm._v(" "),
                        _vm.canForceExportClose
                          ? [
                              _c(
                                VLayout,
                                { attrs: { row: "", "justify-center": "" } },
                                [
                                  _c(
                                    VBtn,
                                    {
                                      staticClass: "mt-2",
                                      on: {
                                        click: _vm.resetLatestWholesaleExport,
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                " +
                                          _vm._s(
                                            _vm.$t("wholesale.forceExportClose")
                                          ) +
                                          "\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ]
                          : _vm._e(),
                      ],
                      2
                    ),
                  ],
                  1
                ),
              ],
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }