import { isEmpty, isNaN, isNumber } from 'lodash';
import { encaseNegativesInParentheses } from '../../lang/customFormatters';
import numberFormats from '@enums/number-formats';

const mixin = {
  methods: {
    /**
     * Format a number using i18n ($n) with additional custom formatting
     * @param {Object} params - Options for formatting a number
     * @param {int|float} params.number - the numeric value to format
     * @param {string} params.format - name of the format to apply (from numberFormats.js)
     * @param {string} params.locale - name of the locale to apply
     * @param {boolean} params.zeroAsDash - flag to control returning `-` for zero/null/NaN values
     * @param {boolean} params.nullAsDash - flag to control returning `-` for null/NaN values (0 will remain 0)
     * @returns {string} the formatted number
     */
    formatNumber({ number, format, locale = null, zeroAsDash = false, nullAsDash = false }) {
      const {
        overrideNumberFormat,
        displayNegativesInParentheses,
      } = this.$store.state.clientConfig.i18nconfig;
      if (zeroAsDash && !number) return '-';
      if (nullAsDash && !number && number !== 0) return '-';

      if (locale !== null) {
        return this.$n(number, format, locale);
      }

      if (overrideNumberFormat !== '') {
        // Any manipulations of the base translation should be done afterwards and defined in customFormatters.js
        const baseTranslation = this.$n(number, format, overrideNumberFormat);
        if (format === numberFormats.priceFormatNegative) {
          return baseTranslation.split('.').join(''); // remove all '.' when the number > 1000
        }
        if (displayNegativesInParentheses && number < 0) {
          return encaseNegativesInParentheses(baseTranslation);
        }
        return baseTranslation;
      }

      return this.$n(number, format);
    },

    formatNumberEmptyCheck(object, key, format, zeroAsDash = false) {
      return isEmpty(object) ||
        object[key] === undefined ||
        object[key] === null ||
        (object[key] === 0 && zeroAsDash)
        ? '-'
        : this.formatNumber({ number: object[key], format });
    },

    formatNumberColourClass(number, negative = false) {
      const positiveCondition = negative ? number <= 0 : number >= 0;
      return positiveCondition ? 'colour-green' : 'colour-red';
    },

    formatStringToNumber(stringFormatNumber) {
      if (typeof stringFormatNumber === 'number') {
        return stringFormatNumber;
      }

      // there can be multiple thousand/million separators, replace globally
      const thousandsRegex = new RegExp(`\\${this.$t('number.thousandsSeparator')}`, 'g');
      return stringFormatNumber || stringFormatNumber === 0
        ? Number(
            `${stringFormatNumber}`
              // there can be multiple thousand/million separators
              .replace(thousandsRegex, '')
              .replace(this.$t('number.decimalPoint'), '.')
          )
        : null;
    },

    /**
     * Format the numeric part of metric using i18n ($n)
     * @param metricValue the value to format
     * @param numberFormat name of the format to apply (from numberFormats.js)
     * @return {string|*}
     */
    formatMetricContainedUnitOfMeasurement(metricValue, numberFormat) {
      if (!metricValue) {
        return '';
      }
      const [value, unitOfMeasurement] = metricValue.split(' ');
      const numericValue = +value;

      if (!value || !isNumber(numericValue) || isNaN(numericValue)) {
        return metricValue;
      }

      return `${this.formatNumber({
        number: numericValue,
        format: numberFormat,
      })} ${unitOfMeasurement}`;
    },
  },
};

export default mixin;
