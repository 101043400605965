var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
      attrs: { fliud: "" },
    },
    [
      _c(
        "div",
        { staticClass: "sticky-header-group pa-2" },
        [
          _c("div", { staticClass: "page-rule-heading pr-10 d-inline-flex" }, [
            _vm._v(
              "\n      " +
                _vm._s(_vm.$t("settings.penaltyRules.pageHeading")) +
                "\n    "
            ),
          ]),
          _vm._v(" "),
          _c("scenario-filter", {
            staticClass: "d-inline-flex",
            on: { selectScenario: _vm.selectScenario },
          }),
          _vm._v(" "),
          _vm.scenario
            ? _c("div", { staticClass: "page-rule-copy d-inline-flex" }, [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("settings.penaltyRules.marginLever")) +
                    ":\n      "
                ),
                _vm.marginLever
                  ? _c("b", { staticClass: "pl-2" }, [
                      _vm._v(
                        "\n        " +
                          _vm._s(
                            _vm.formatNumber({
                              number: _vm.marginLever,
                              format: _vm.numberFormats.priceFormat,
                            })
                          ) +
                          "%\n      "
                      ),
                    ])
                  : _vm._e(),
              ])
            : _vm._e(),
        ],
        1
      ),
      _vm._v(" "),
      _vm.isRulesEnabled ? _c("rulesets") : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }