<template>
  <div class="page">
    <tabs :tabs="tabs" />
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { canAccessDevAdminTabs } from '@enums/feature-flags';

export default {
  computed: {
    ...mapState('clientConfig', ['toggleLogic']),

    tabs() {
      let clientAdminTabs = [];

      if (this.toggleLogic[canAccessDevAdminTabs]) {
        clientAdminTabs = [
          {
            label: this.$t('tabText.featureToggler'),
            disabled: false,
            linkText: 'tabText.featureToggler',
            omitFromNav: true,
            component: 'feature-toggler',
            navigateTo: () => this.$router.push({ name: 'feature-toggler' }),
            routeNames: ['feature-toggler'],
          },
          {
            label: this.$t('tabText.diagnostics'),
            disabled: false,
            linkText: 'tabText.diagnostics',
            omitFromNav: true,
            component: 'diagnostics',
            navigateTo: () => this.$router.push({ name: 'diagnostics' }),
            routeNames: ['diagnostics'],
          },
          {
            label: this.$t('tabText.translationEditor'),
            disabled: false,
            linkText: 'tabText.translationEditor',
            omitFromNav: true,
            component: 'translation-editor',
            navigateTo: () => this.$router.push({ name: 'translation-editor' }),
            routeNames: ['translation-editor'],
          },
          {
            label: this.$t('tabText.audit'),
            disabled: false,
            linkText: 'tabText.audit',
            omitFromNav: true,
            component: 'audit',
            navigateTo: () => this.$router.push({ name: 'audit' }),
            routeNames: ['audit'],
          },
        ];
      }

      const userPermissionTab = [
        {
          label: this.$t('tabText.userRoles'),
          disabled: false,
          linkText: 'tabText.userRoles',
          omitFromNav: true,
          component: 'user-roles',
          navigateTo: () => this.$router.push({ name: 'user-roles' }),
          routeNames: ['user-roles'],
        },
      ];

      return [...userPermissionTab, ...clientAdminTabs];
    },
  },
};
</script>

<style scoped lang="scss">
.page {
  height: 100%;
  padding: 0 1rem;
  padding-top: 2rem;
}

// Vuetify overrides needed for making the tabs fill the available vertical space.
::v-deep .v-tabs.promo-tabs.theme--light {
  height: 100%;
  display: flex;
  flex-direction: column;
}

::v-deep .v-item-group.theme--light.v-slide-group.v-tabs-bar.primary--text.transparent {
  flex: 0 1 0;
}

::v-deep .v-window.v-item-group.theme--light.v-tabs-items {
  flex: 1 0 0;
}

::v-deep .v-window__container {
  height: 100%;
}

::v-deep .v-window-item.tab.v-window-item--active {
  height: 100%;
}
</style>
