'use strict';

// FIXME: Ensure contents are sensible enums and check overlap with config.

const hierarchyLevels = {
  rootHierarchyLevel: 0,
  unitLevel: 1,
  categoryLevel: 2,
  pricingGroupLevel: 3,
  architectureGroupLevel: 4,
};

// although we only display levels below 2 category, we need the full hierarchy tree to exist in the db.
// wholesale starts from level 2 category, but include level 1 unit and level 0 root too.
const wholesaleHierarchyLevels = {
  rootLevel: 0,
  unitLevel: 1,
  categoryLevel: 2,
  subcategoryLevel: 3,
  segmentLevel: 4,
  subsegmentLevel: 5,
  microsegmentLevel: 6,
};

module.exports = {
  ...hierarchyLevels,
  wholesaleHierarchyLevels,
  pricingGroupUndefined: 'PG_UNDEFINED',
  architectureGroupUndefined: 'AG_UNDEFINED',
  unitLevelDescription: 'Unit',
  categoryLevelDescription: 'Category',
  pricingGroupLevelDescription: 'Pricing Group',
  architectureGroupLevelDescription: 'Architecture Group',
  defaultMarginTarget: 0.25,
};
