import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VExpansionPanel,
    { staticClass: "mb-5" },
    [
      _c(
        VExpansionPanelHeader,
        {
          staticClass: "line-pricing-group pt-0 pb-0 pl-0",
          on: { click: _vm.preventExpand },
          scopedSlots: _vm._u([
            {
              key: "actions",
              fn: function () {
                return [_c(VIcon, [_vm._v("expand_more")])]
              },
              proxy: true,
            },
          ]),
        },
        [
          _c(
            VCol,
            { attrs: { cols: "7" } },
            [
              _c(VTextField, {
                staticClass: "pt-0 pb-0 highlight",
                attrs: {
                  rules: _vm.rules.missingDescription,
                  disabled: _vm.disabled,
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "space", 32, $event.key, [
                        " ",
                        "Spacebar",
                      ])
                    )
                      return null
                    return _vm.preventExpand.apply(null, arguments)
                  },
                },
                model: {
                  value: _vm.linePricingGroup.linePricedGroupDescription,
                  callback: function ($$v) {
                    _vm.$set(
                      _vm.linePricingGroup,
                      "linePricedGroupDescription",
                      $$v
                    )
                  },
                  expression: "linePricingGroup.linePricedGroupDescription",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(VCol, [
            _c(
              "span",
              { staticClass: "float-right" },
              [
                _vm.linePricingGroup.products.length &&
                _vm.subGroupValidationError
                  ? _c(VIcon, { attrs: { color: "red" } }, [
                      _vm._v("\n          mdi-alert-circle-outline\n        "),
                    ])
                  : _vm._e(),
              ],
              1
            ),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VExpansionPanelContent,
        { staticClass: "line-pricing-group" },
        [
          _c(
            VCol,
            { staticClass: "pt-0", attrs: { cols: "12" } },
            [
              _c(VDataTable, {
                staticClass: "line-priced-products products-table",
                attrs: {
                  items: _vm.linePricingGroupProducts,
                  options: _vm.pagination,
                  "item-key": "_id",
                  "sort-desc": _vm.pagination.descending,
                  "hide-default-footer": "",
                },
                on: {
                  "update:options": function ($event) {
                    _vm.pagination = $event
                  },
                  "update:sortDesc": function ($event) {
                    return _vm.$set(_vm.pagination, "descending", $event)
                  },
                  "update:sort-desc": function ($event) {
                    return _vm.$set(_vm.pagination, "descending", $event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    _vm.linePricingGroup.products.length > 0
                      ? {
                          key: "header",
                          fn: function (props) {
                            return [
                              _c(
                                "tr",
                                { staticClass: "v-data-table-header" },
                                [
                                  _c("th"),
                                  _vm._v(" "),
                                  _vm._l(_vm.headers, function (header) {
                                    return _c(
                                      "th",
                                      {
                                        key: header.text,
                                        staticClass: "column",
                                        class: _vm.getHeaderClasses(
                                          header,
                                          _vm.pagination
                                        ),
                                        style: header.style,
                                        on: {
                                          click: function ($event) {
                                            header.sortable
                                              ? _vm.changeSort(header.value)
                                              : ""
                                          },
                                        },
                                      },
                                      [
                                        _vm._v(
                                          "\n              " +
                                            _vm._s(header.text) +
                                            "\n              "
                                        ),
                                        header.sortable
                                          ? _c(
                                              VIcon,
                                              {
                                                staticClass:
                                                  "v-data-table-header__icon",
                                                attrs: { small: "" },
                                              },
                                              [
                                                _vm._v(
                                                  "\n                arrow_upward\n              "
                                                ),
                                              ]
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  }),
                                ],
                                2
                              ),
                            ]
                          },
                        }
                      : null,
                    {
                      key: "item",
                      fn: function (props) {
                        return [
                          _c(
                            "tr",
                            { key: props.item._id, staticClass: "product-row" },
                            [
                              _c(
                                "td",
                                [
                                  _c(
                                    VIcon,
                                    {
                                      staticClass: "delete-icon",
                                      attrs: { disabled: _vm.disabled },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deleteProduct(props.item)
                                        },
                                      },
                                    },
                                    [
                                      _vm._v(
                                        "\n                delete\n              "
                                      ),
                                    ]
                                  ),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.productKeyDisplay)),
                              ]),
                              _vm._v(" "),
                              _vm.isFeatureFlagEnabled(_vm.useZones)
                                ? _c(
                                    "td",
                                    { staticClass: "text-xs-left" },
                                    [
                                      _c("tooltipped-truncated-field", {
                                        attrs: {
                                          text: _vm.storegroupKeyNameMap[
                                            props.item.toolStoreGroupKey
                                          ],
                                          "truncation-length":
                                            _vm.truncationLength
                                              .toolStoreGroupDescription,
                                        },
                                      }),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                              _vm._v(" "),
                              _c(
                                "td",
                                {
                                  staticClass:
                                    "text-xs-left text-bold description-cell",
                                },
                                [
                                  _c("tooltipped-truncated-field", {
                                    attrs: {
                                      text: props.item.description,
                                      "truncation-length":
                                        _vm.truncationLength.linePricedProducts,
                                    },
                                  }),
                                ],
                                1
                              ),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-xs-left" }, [
                                _vm._v(_vm._s(props.item.size)),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-xs-center" }, [
                                _vm._v(
                                  _vm._s(props.item.packageTypeDescription)
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-xs-center" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatNumber({
                                        number: _vm.getIntentionCost(
                                          props.item
                                        ),
                                        format: _vm.numberFormats.priceFormat,
                                        nullAsDash: true,
                                      })
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", { staticClass: "text-xs-center" }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatNumber({
                                        number: _vm.getLivePrice(props.item),
                                        format: _vm.numberFormats.priceFormat,
                                        nullAsDash: true,
                                      })
                                    ) +
                                    "\n            "
                                ),
                              ]),
                              _vm._v(" "),
                              _c("td", [_vm._v(_vm._s(props.item.subGroup))]),
                              _vm._v(" "),
                              _c("td", { staticClass: "line-price-factor" }, [
                                _c(
                                  "form",
                                  { attrs: { novalidate: "" } },
                                  [
                                    _c(VTextField, {
                                      staticClass:
                                        "pt-0 pb-0 pr-2 highlight line-price-factor-input",
                                      attrs: {
                                        rules: _vm.rules.linePriceFactorRules,
                                        disabled: _vm.disabled,
                                        type: "number",
                                        min: "0.1",
                                        step: "0.1",
                                      },
                                      model: {
                                        value: props.item.linePriceFactor,
                                        callback: function ($$v) {
                                          _vm.$set(
                                            props.item,
                                            "linePriceFactor",
                                            _vm._n($$v)
                                          )
                                        },
                                        expression:
                                          "props.item.linePriceFactor",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ]),
                            ]
                          ),
                        ]
                      },
                    },
                    {
                      key: "no-data",
                      fn: function () {
                        return [
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "settings.architecture.noDataLinePriceGroup"
                                )
                              ) +
                              "\n        "
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "text-right pt-0" },
                [
                  _vm.linePricingGroup.products.length &&
                  _vm.subGroupValidationError
                    ? _c("span", { staticClass: "validation-fail" }, [
                        _vm._v(
                          "\n          " +
                            _vm._s(
                              _vm.$t(
                                "settings.architecture.differentSubGroupValidationError"
                              )
                            ) +
                            "\n        "
                        ),
                      ])
                    : _vm._e(),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "delete-btn text-right",
                      attrs: {
                        text: "",
                        icon: "",
                        disabled: _vm.disabled,
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.openDeleteDialog()
                        },
                      },
                    },
                    [_c(VIcon, [_vm._v("delete")])],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
        },
        on: {
          onOk: function ($event) {
            return _vm.deleteLinePricingGroupHandler(true)
          },
          onCancel: function ($event) {
            return _vm.deleteLinePricingGroupHandler(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [
                _vm._v(_vm._s(_vm.$t("dialogs.deleteLinePricingGroup.header"))),
              ]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                _vm._v(
                  "\n      " +
                    _vm._s(_vm.$t("dialogs.deleteLinePricingGroup.body")) +
                    "\n      "
                ),
                _c("strong", [
                  _vm._v(
                    _vm._s(
                      _vm.linePricingGroup
                        ? _vm.linePricingGroup.linePricedGroupDescription
                        : ""
                    )
                  ),
                ]),
              ]
            },
            proxy: true,
          },
        ]),
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }