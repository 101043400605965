import numeral from 'numeral';
import { isNil, isNaN } from 'lodash';
import CustomNumberCellEditor from '../pages/inputs/engine-inputs/localisedNumberCellEditor';

// Validations
const lessThan100 = {
  message: 'lessThan100',
  test({ value } = {}) {
    if (isNil(value)) return true;

    return value <= 100;
  },
};

const greaterThan0 = {
  message: 'greaterThan0',
  test({ value } = {}) {
    if (isNil(value)) return true;

    return value > 0;
  },
};

const mustBeValidNumber = {
  message: 'mustBeValidNumber',
  test({ value } = {}) {
    return !isNaN(value);
  },
};

const validations = {
  mustBeValidNumber,
  lessThan100,
  greaterThan0,
};

// [] brackets make it optional
const fmtDecimalOptionalMantissa = '0[.][00]';

function localisedFormatter(params) {
  if (isNaN(params.value)) {
    return params.value;
  }

  if (isNil(params.value)) {
    return;
  }
  const { value } = params;
  const res = numeral(value).format(fmtDecimalOptionalMantissa);

  return res;
}

function printColumnFilterValue(val) {
  if (isNil(val)) return;

  const res = numeral(val).format(fmtDecimalOptionalMantissa);

  return res;
}

function handleNumericFilterInput(inputString) {
  // Always comes in as a string - parse to number so it can be compared to column data
  const res = numeral(inputString).value();
  return res;
}

const localisedNumericColFilterParams = {
  // Only allow digits, . and ,
  // The locales don't load in time for this file. Otherwise, this would restrict to digits and current decimal
  // However, this causes no errors - just a possibility of an odd parse which is displayed to the user so they can fix.
  allowedCharPattern: '\\d,\\.',
  // This formats the number in the input box next to the filters
  numberFormatter: printColumnFilterValue,
  // This parses the localised input in the number filters
  numberParser: handleNumericFilterInput,
};

// This is the base number editor, setting the editor and localised inputs
const localisedNumericCol = {
  cellEditor: CustomNumberCellEditor,
  editable: true, // TODO: only allow editing if enabled is true
  valueFormatter: localisedFormatter,
  filter: 'agNumberColumnFilter',
  filterParams: localisedNumericColFilterParams,
  // Needs to be false to prevent exporting all formats like percentages
  // Necessary to parse pasted cells
  valueParser(params) {
    if (params.newValue) {
      // This is triggered by paste operations. We parse the string value according to locale.
      const res = numeral(params.newValue).value();
      return res;
    }

    return params.value;
  },
};

const percentageNumericCol = {
  ...localisedNumericCol,
  valueFormatter: localisedFormatter,
  filterParams: {
    // Only allow numbers, . and ,
    allowedCharPattern: '\\d,\\.',
    ...localisedNumericColFilterParams,
    valueFormatter: localisedFormatter,
  },
};

export { validations, localisedNumericCol, percentageNumericCol };
