import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VBtn,
    {
      staticClass: "align-self-center",
      attrs: {
        color: "success",
        small: "",
        depressed: "",
        loading: _vm.storeGroupsUpdating,
      },
      on: { click: _vm.initUpdate },
    },
    [
      _vm._v(
        "\n  " +
          _vm._s(_vm.$t("wholesale.update_grid_storegroups.button")) +
          "\n"
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }