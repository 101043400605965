var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "aside",
    {
      staticClass: "main-sidebar elevation-2",
      class: `main-sidebar--${_vm.isExpanded ? "expanded" : "collapsed"}`,
    },
    [
      _c(
        "div",
        {
          staticClass: "main-sidebar__nav clickable",
          on: {
            click: function ($event) {
              return _vm.toggleSidebar()
            },
          },
        },
        [
          _c("div", { staticClass: "sidebar-label" }, [
            _vm._v(
              "\n      " + _vm._s(_vm.$t("wholesale.listOfSplits")) + "\n      "
            ),
            _c("i", {
              staticClass: "mdi expand-icon",
              class: _vm.isExpanded ? "mdi-chevron-down" : "mdi-chevron-up",
              attrs: { "aria-hidden": "true" },
            }),
          ]),
        ]
      ),
      _vm._v(" "),
      _c(
        "div",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.isExpanded,
              expression: "isExpanded",
            },
          ],
          staticClass: "main-sidebar__expanded-content",
        },
        [_c("sidebar-margin-splits")],
        1
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }