var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("ag-grid-vue", {
    staticClass: "ag-theme-custom ag-wholesale-hierarchy",
    attrs: {
      "grid-options": _vm.gridOptions,
      "column-defs": _vm.columnDefs,
      "row-data": _vm.rowData,
      "default-col-def": _vm.defaultColDef,
    },
    on: { "grid-ready": _vm.onGridReady },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }