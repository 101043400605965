<!--
    Generic template for an auto-complete filter
    which stores selected values in the filters vuex store
-->

<template>
  <v-layout align-center fill-height>
    <span v-if="!hideLabel" class="page-rule-filter">{{ filter.name }}:</span>
    <v-autocomplete
      :dense="dense"
      :solo="solo"
      hide-details
      :loading="loading"
      :items="filter.values"
      :item-text="filter.label"
      :item-value="getItemValue()"
      :label="hideLabel ? '' : $t('filters.selectPrompt', { filterName: filter.name })"
      :value="overrideValue || getSelectedFilter(filter.state)"
      class="px-3 ma-0 pt-0 mb-1"
      height="30"
      @input="setSelection({ filterValue: $event, filterName: filter.state })"
    >
      <template v-slot:item="props">
        <span class="item-text">{{ props.item[filter.label] }}</span>
      </template>
      <template v-slot:no-data>
        <div v-if="!loading">
          <v-alert :value="true" color="error" icon="warning" outlined>
            {{ $t('filters.noData') }}
          </v-alert>
        </div>
        <div v-else>
          <v-alert :value="true" color="info" icon="info" outlined>
            {{ $t('general.loadingMessage') }}
          </v-alert>
        </div>
      </template>
    </v-autocomplete>
  </v-layout>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';

const { mapActions, mapGetters } = createNamespacedHelpers('filters');

export default {
  props: {
    // filter structure: { name, itemValue, values, label, state }
    filter: { type: Object, required: true },
    loading: { type: Boolean, required: true },
    hideLabel: { type: Boolean, default: false },
    overrideValue: { type: String, default: null },
    dense: { type: Boolean, default: true },
    solo: { type: Boolean, default: true },
  },
  computed: {
    ...mapGetters(['getSelectedFilter']),
  },

  methods: {
    ...mapActions(['setSelectedFilter', 'resetFilter']),

    getItemValue() {
      return this.filter.itemValue || '_id';
    },

    setSelection({ filterName, filterValue }) {
      // need to reset architectureGroup so component is disabled properly.
      // should set arch group if manually selected, or passing in newTab state.
      this.resetFilter({ filterName: 'architectureGroup' });
      this.setSelectedFilter({ filterName, filterValue });
      this.$emit('filterSelected', { filterName, filterValue });
    },
  },
};
</script>
