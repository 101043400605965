<template>
  <div class="container">
    <v-btn
      class="grid-btn"
      secondary
      small
      :disabled="dataLoading || !editsExist"
      depressed
      @click="discardChanges"
      >{{ $t('inputs.cancel') }}</v-btn
    >

    <v-btn :disabled="!saveAllowed" small color="primary" depressed @click="handleSave">
      {{ $t('actions.save') }}
      <v-icon small>$import</v-icon>
    </v-btn>
  </div>
</template>

<script>
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      editsExist: false,
      saveAllowed: false,
      dataLoading: false,
    };
  },
  methods: {
    discardChanges() {
      this.$parent.$emit('discard-changes');
    },
    handleSave() {
      this.$parent.$emit('save-changes');
    },
  },
});
</script>
