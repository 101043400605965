import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VItemGroup } from 'vuetify/lib/components/VItemGroup';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemIcon } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "mx-2 mt-2 flex-row-reverse" },
        [
          _c(
            VItemGroup,
            [
              _c(
                VBtn,
                {
                  class: !_vm.isManualInputMode
                    ? "toggle-btn-active"
                    : "toggle-btn-inactive",
                  attrs: { small: "", tile: "", depressed: "" },
                  on: { click: _vm.toggleInputMode },
                },
                [_vm._m(0)],
                1
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  class: _vm.isManualInputMode
                    ? "toggle-btn-active"
                    : "toggle-btn-inactive",
                  attrs: { small: "", tile: "", depressed: "" },
                  on: { click: _vm.toggleInputMode },
                },
                [_vm._m(1)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "mx-2" },
        [
          _c(
            VCol,
            { staticClass: "pb-0", attrs: { cols: "5" } },
            [
              _c(VSelect, {
                attrs: {
                  items: _vm.filterOperatorOptions,
                  label: _vm.$t("filters.selectOp"),
                },
                model: {
                  value: _vm.selectedOp,
                  callback: function ($$v) {
                    _vm.selectedOp = $$v
                  },
                  expression: "selectedOp",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "pb-0", attrs: { cols: "5" } },
            [
              !_vm.isManualInputMode
                ? _c(VAutocomplete, {
                    attrs: {
                      disabled: !_vm.selectedOp,
                      items: _vm.uniqueValueOptions,
                      "max-width": 200,
                      type: _vm.inputType,
                      multiple: _vm.allowMultiple,
                      label: _vm.$t("filters.selectValue"),
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  })
                : _c(VTextField, {
                    attrs: {
                      type: _vm.inputType,
                      disabled: !_vm.selectedOp,
                      label: _vm.$t("filters.enterValue"),
                    },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { staticClass: "my-auto", attrs: { cols: "2" } },
            [
              _c(
                VBtn,
                {
                  attrs: {
                    disabled: !_vm.isValidFilter,
                    color: _vm.addFilterButtonColor,
                    icon: "",
                    depressed: "",
                  },
                  on: { click: _vm.upsertFilter },
                },
                [_vm._m(2)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _vm.currentFilters.length
        ? _c(
            VRow,
            { staticClass: "ml-1" },
            [
              _c(
                VCol,
                { staticClass: "py-0 align-self-center", attrs: { cols: "6" } },
                [
                  _c("small", [
                    _vm._v(
                      "\n        " +
                        _vm._s(
                          _vm.$t("filters.numApplied", {
                            numFilters: _vm.currentFilters.length,
                          })
                        ) +
                        "\n      "
                    ),
                  ]),
                ]
              ),
              _vm._v(" "),
              _c(
                VCol,
                { staticClass: "text-right py-0", attrs: { cols: "6" } },
                [
                  _c(
                    VBtn,
                    {
                      attrs: {
                        disabled: _vm.currentFilters.length === 0,
                        color: "primary",
                        text: "",
                        depressed: "",
                      },
                      on: {
                        click: function ($event) {
                          return _vm.removeActiveTableFilter({
                            filter: _vm.currentFilters,
                          })
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("actions.clearAll")) +
                          "\n      "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _vm._v(" "),
      _c(VDivider),
      _vm._v(" "),
      _vm.currentFilters.length
        ? _c(
            VList,
            { staticClass: "py-0", attrs: { dense: "" } },
            [
              _vm._l(_vm.currentFilters, function (filter) {
                return [
                  _c(
                    VListItem,
                    { key: filter.id, staticClass: "my-auto" },
                    [
                      _c(
                        VListItemContent,
                        [
                          _c(
                            VRow,
                            [
                              _c(VCol, { attrs: { cols: "4" } }, [
                                _vm._v(
                                  " " +
                                    _vm._s(
                                      _vm.$t(
                                        `filters.filterOperators.${filter.op}`
                                      )
                                    ) +
                                    ": "
                                ),
                              ]),
                              _vm._v(" "),
                              _c(VCol, { attrs: { cols: "8" } }, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.formatFilterValue(filter.value)
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        VListItemIcon,
                        { staticClass: "align-self-center" },
                        [
                          _c(
                            VIcon,
                            {
                              attrs: { color: "primary" },
                              on: {
                                click: function ($event) {
                                  return _vm.removeActiveTableFilter({ filter })
                                },
                              },
                            },
                            [_vm._v("delete")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider, {
                    key: `divider-${filter.id}`,
                    staticClass: "mx-3",
                  }),
                ]
              }),
            ],
            2
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("list")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("text_format")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, [_vm._v("add_box")])
  },
]
render._withStripped = true

export { render, staticRenderFns }