var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Significant competitor move")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "significant-competitor-move-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "significant-competitor-move-transform",
                transform: "translate(-375.000000, -639.000000)",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "significant-competitor-move",
                    transform: "translate(375.000000, 639.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      "fill-rule": "nonzero",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M16,6 L23.72,14.0095 C23.8996,14.1959 24,14.4446 24,14.7035 L24,14.7035 L24,23 C24,23.5523 23.5523,24 23,24 L23,24 L18,24 C17.4477,24 17,23.5523 17,23 L17,23 L17,19 L15,19 L15,23 C15,23.5523 14.5523,24 14,24 L14,24 L9,24 C8.44772,24 8,23.5523 8,23 L8,23 L8,14.7035 C8,14.4446 8.10037,14.1959 8.28,14.0095 L8.28,14.0095 L16,6 Z M16,8.88195 L10,15.1069 L10,22 L13,22 L13,17 L19,17 L19,22 L22,22 L22,15.1069 L16,8.88195 Z M5,0 L10,5 L7,5 L7,11 C7,11.5523 6.55228,12 6,12 L4,12 C3.44772,12 3,11.5523 3,11 L3,5 L0,5 L5,0 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }