<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Underlying reference change</title>
    <g
      id="underlying-reference-change-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="underlying-reference-change-transform" transform="translate(-375.000000, -679.000000)">
        <g id="underlying-reference-change" transform="translate(375.000000, 679.000000)">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            id="Combined-Shape"
            d="M8,11 L8,16 C8,16.5523 8.44772,17 9,17 L14,17 L14,22 C14,22.5523 13.5523,23 13,23 L1,23 C0.447716,23 0,22.5523 0,22 L0,12 C0,11.4477 0.447715,11 1,11 L8,11 Z M23,1 C23.5523,1 24,1.44772 24,2 L24,2 L24,14 C24,14.5523 23.5523,15 23,15 L23,15 L11,15 C10.4477,15 10,14.5523 10,14 L10,14 L10,2 C10,1.44772 10.4477,1 11,1 L11,1 Z M22,3 L12,3 L12,13 L22,13 L22,3 Z M4,1 L8,5 L6,5 L6,8 C6,8.55229 5.55228,9 5,9 L3,9 C2.44772,9 2,8.55229 2,8 L2,5 L0,5 L4,1 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'UnderlyingReferenceChange',
};
</script>
