<template>
  <v-layout row justify-center>
    <v-dialog v-model="showDialog" persistent max-width="40rem">
      <v-card>
        <v-form ref="form">
          <v-card-text class="header-text">{{
            $t('unsavedChangesDialog.headerConfirmText')
          }}</v-card-text>
          <v-card-text class="body">
            <p class="lead">{{ $t('unsavedChangesDialog.unsavedChangeStatement') }}.</p>

            <p class="lead">{{ $t('unsavedChangesDialog.optionPrompt') }}.</p>

            <p v-if="!saveAllowed" class="lead error-message">
              {{ $t('unsavedChangesDialog.saveDisabledMessage') }}.
            </p>
          </v-card-text>
          <v-card-actions class="btn-group">
            <v-btn class="cancel-btn" text outlined depressed @click="closeDialog(cancel)">{{
              $t('actions.cancel')
            }}</v-btn>
            <v-btn
              id="unsaved-discard-button"
              text
              outlined
              depressed
              @click="closeDialog(discard)"
              >{{ $t('actions.discard') }}</v-btn
            >
            <v-btn
              id="unsaved-save-button"
              depressed
              :disabled="!saveAllowed"
              @click="closeDialog(save)"
              >{{ $t('actions.save') }}</v-btn
            >
          </v-card-actions>
        </v-form>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
import { cancel, save, discard } from '@enums/inputs/unsaved-changes-options';

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    saveAllowed: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      cancel,
      save,
      discard,
    };
  },

  methods: {
    closeDialog(action) {
      this.$emit('closedUnsavedChangesDialog', action);
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.v-card {
  .body.v-card__text {
    margin-bottom: 7rem;
    font-size: 1.5rem;
  }

  .header-text {
    padding-top: 2rem;
    font-size: 2rem;
    color: black;
  }

  .btn-group.v-card__actions {
    .cancel-btn {
      color: $btn-cancel-color;
      border-color: $btn-cancel-color;
    }

    .ok-btn {
      color: $pricing-white;
      background-color: $btn-ok-color;
      border-color: $btn-ok-color;
    }
  }
}

#unsaved-save-button {
  color: $pricing-white;
  background-color: $btn-ok-color;
  border-color: $btn-ok-color;
}

#unsaved-discard-button {
  color: $btn-cancel-color;
  border-color: $btn-cancel-color;
}

.pg-dropdown-item-text {
  font-size: 1.5rem;
}

.header-text {
  padding-top: 0.5rem;
  font-size: 2rem;
  color: black;
}

.v-btn {
  font-size: 1.5rem;
}

.error-message {
  color: $error-color;
}
</style>
