<template>
  <div class="user-roles elevation-1">
    <v-dialog :value="editDialogOpen" persistent max-width="40rem" scrollable>
      <v-card :key="editedUser._id">
        <v-card-title class="headline font-weight-bold">
          {{ $t('userRoles.editor.selectRoles') }}
        </v-card-title>
        <v-card-text>
          <v-expansion-panels>
            <v-expansion-panel
              v-for="storeFormat in treeViewHierarchies"
              :key="storeFormat.levelEntryDescription"
              focusable
            >
              <v-expansion-panel-header>
                <div class="hierarchy-item">
                  {{ storeFormat.levelEntryDescription }}
                </div>
                <template v-slot:actions>
                  <v-icon color="indigo darken-2">expand_more</v-icon>
                </template>
              </v-expansion-panel-header>
              <v-expansion-panel-content>
                <v-treeview
                  v-model="accessByStoreFormat[storeFormat.levelEntryDescription]"
                  selectable
                  hoverable
                  dense
                  item-key="levelEntryKey"
                  item-text="levelEntryDescription"
                  open-on-click
                  selection-type="independent"
                  :items="storeFormat.children"
                  class="hierarchy-item"
                />
              </v-expansion-panel-content>
            </v-expansion-panel>
          </v-expansion-panels>
        </v-card-text>
        <v-card-actions class="btn-group">
          <v-spacer />
          <v-btn class="cancel-btn" text outlined depressed @click="closeEditDialog">
            {{ $t('actions.cancel') }}
          </v-btn>
          <v-btn class="save-btn save" color="success" depressed @click="updateUser">{{
            $t('actions.save')
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-row class="search-box">
      <v-text-field
        :value="search"
        append-icon="search"
        :label="$t('actions.search')"
        single-line
        hide-details
        dense
        @input="debounceSearchUpdate"
      />
    </v-row>

    <v-data-table
      :items="search ? searchResults : categoryAccessList"
      :headers="headers"
      :loading="loading"
      :options.sync="pagination"
      :sort-desc.sync="pagination.descending"
      dense
      class="pricing-table elevation-1"
      item-key="userid"
      hide-default-header
      hide-default-footer
      @mousewheel.native="preventPageNavigationOnHorizontalScroll($event, '.v-data-table')"
    >
      <template v-slot:header="{ props }">
        <tr class="main-header v-data-table-header">
          <th
            v-for="header in props.headers"
            :key="header.value"
            :class="[
              pagination.descending ? 'desc' : 'asc',
              header.value === pagination.sortBy ? 'active' : '',
              header.class,
            ]"
            class="sortable py-2"
            @click="changeSort(header)"
          >
            <span>{{ $t(header.text) }}</span>
            <v-icon v-if="header.sortable" size="1.4rem" class="v-data-table-header__icon">
              arrow_upward
            </v-icon>
          </th>
        </tr>
      </template>

      <template v-slot:item="props">
        <tr :class="{ 'even-rows': props.index % 2 }">
          <td class="text-xs table-cell">{{ props.item.userid }}</td>
          <td class="text-xs table-cell">{{ props.item.email }}</td>
          <td class="text-xs table-cell">{{ formatUserRoles(getUserRoles(props.item.roles)) }}</td>
          <td class="text-xs table-cell">
            <span class="mr-1">
              <i
                class="v-icon notranslate mr-2 v-icon--link material-icons"
                @click="editUser(props.item)"
              >
                edit
              </i>
            </span>
            <span>
              {{
                formatExistingAccess(props.item.hierarchyAccess) | truncate(accessTruncationLength)
              }}
            </span>
          </td>
          <td class="text-xs table-cell">
            <tooltip :value="$t('userRoles.editor.disabled')">
              <v-checkbox
                dense
                hide-details
                disabled
                class="mt-0"
                :input-value="isAdmin(props.item.roles)"
              />
            </tooltip>
          </td>
        </tr>
      </template>

      <template slot="footer">
        <v-row>
          <v-col md="3" offset-md="8">
            <v-pagination v-model="pagination.page" :length="pages" @input="loadUserProfiles" />
          </v-col>
        </v-row>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import {
  ceil,
  groupBy,
  filter,
  find,
  flatMap,
  includes,
  map,
  reduce,
  size,
  sortBy,
  startCase,
  debounce,
  isEqual,
} from 'lodash';

import sortDirectionEnums from '@enums/sort-direction';
import { unitLevel, categoryLevel } from '@enums/hierarchy';
import { preventPageNavigationOnHorizontalScroll } from '../../utils/data-table-utils';
import clientConfig from '@sharedModules/config/client';

export default {
  data() {
    return {
      accessTruncationLength: 150,

      pagination: {
        // used by v-data-table for sorting and pagination
        descending: false,
        page: 1,
        itemsPerPage: 25,
        sortBy: 'userid',
      },

      editDialogOpen: false,
      editedUser: {},
      accessByStoreFormat: {},

      headers: [
        {
          text: this.$t('userRoles.editor.name'),
          align: 'start',
          sortable: true,
          value: 'userid',
        },
        {
          text: this.$t('userRoles.editor.email'),
          align: 'start',
          sortable: true,
          value: 'email',
        },
        {
          text: this.$t('userRoles.editor.role'),
          align: 'start',
          sortable: true,
          // this sorts on the first role - if there are multiple and admin is first, this should be changed to sort on the front-end
          value: 'roles[0]',
        },
        {
          text: this.$t('userRoles.editor.access'),
          value: 'access',
          align: 'left',
          sortable: false,
        },
        {
          text: this.$t('userRoles.editor.admin'),
          align: 'start',
          sortable: false,
          value: 'admin',
        },
      ],
      search: '',
      searchResults: [],
    };
  },

  computed: {
    ...mapState('userProfiles', ['userProfiles', 'loading', 'userProfilesCount']),
    ...mapState('hierarchy', ['hierarchyByStoreFormat']),
    ...mapState('clientConfig', ['userRoles']),

    pages() {
      return ceil(this.userProfilesCount / this.pagination.itemsPerPage);
    },
    categoryAccessList() {
      return map(this.userProfiles, profile => {
        const accessKeys = flatMap(profile.access, levelAccess => {
          return map(levelAccess, 'levelEntryKey');
        });
        profile.hierarchyAccess = reduce(
          this.hierarchyByStoreFormat,
          (output, hierarchies, storeFormat) => {
            output[storeFormat] = filter(hierarchies, h => includes(accessKeys, h.levelEntryKey));
            return output;
          },
          {}
        );
        return profile;
      });
    },
    treeViewHierarchies() {
      return map(this.hierarchyByStoreFormat, (hierarchies, storeFormat) => {
        const levelGroupedHierarchies = groupBy(hierarchies, 'level');
        return {
          levelEntryDescription: storeFormat,
          level: 0,
          children: sortBy(
            map(levelGroupedHierarchies[unitLevel], unit => {
              return {
                levelEntryDescription: unit.levelEntryDescription,
                levelEntryKey: unit.levelEntryKey,
                level: unitLevel,
                children: sortBy(
                  filter(levelGroupedHierarchies[categoryLevel], {
                    parentId: unit.levelEntryKey,
                  }).map(category => ({
                    levelEntryDescription: category.levelEntryDescription,
                    levelEntryKey: category.levelEntryKey,
                    level: categoryLevel,
                  })),
                  'levelEntryDescription'
                ),
              };
            }),
            'levelEntryDescription'
          ),
        };
      });
    },
  },

  async created() {
    const params = { where: { level: { $in: [unitLevel, categoryLevel] } } };
    await Promise.all([this.fetchHierarchyByStoreFormat({ params }), this.loadUserProfiles()]);
  },

  methods: {
    ...mapActions('userProfiles', ['fetchUserProfiles', 'updateUserProfile']),
    ...mapActions('hierarchy', ['fetchHierarchyByStoreFormat']),

    startCase,
    preventPageNavigationOnHorizontalScroll,

    async loadUserProfiles() {
      let params = {};
      if (this.pagination.sortBy) {
        params = {
          sortBy: this.pagination.sortBy,
          sortDirection: this.pagination.descending
            ? sortDirectionEnums.descending
            : sortDirectionEnums.ascending,
        };
      }

      await this.fetchUserProfiles({ params });
    },

    formatExistingAccess(storeFormatAccess) {
      return reduce(
        storeFormatAccess,
        (formattedAccess, access, storeFormat) => {
          const levelEntryDescriptions = map(access, a => a.levelEntryDescription).join(', ');
          if (size(access)) {
            formattedAccess += `${storeFormat}: ${levelEntryDescriptions}. `;
          }
          return formattedAccess;
        },
        ''
      );
    },

    // do not display admin role
    getUserRoles(roles) {
      return filter(roles, role => role !== this.userRoles.admin);
    },

    formatUserRoles(roles) {
      return map(roles, r => startCase(r)).join(', ');
    },

    isAdmin(roles) {
      return reduce(this.userRoles.adminRoles, (res, role) => res || includes(roles, role), false);
    },

    async changeSort(column) {
      if (!column.sortable) return;

      if (isEqual(this.pagination.sortBy, [column.value])) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = [column.value];
        this.pagination.descending = false;
      }
      await this.loadUserProfiles();
    },

    editUser(user) {
      this.editedUser = user;
      const currentAccess = flatMap(this.editedUser.access);
      this.accessByStoreFormat = reduce(
        this.treeViewHierarchies,
        (output, tvh) => {
          const storeFormat = tvh.levelEntryDescription;
          const flatAccess = currentAccess.map(a => a.levelEntryKey);
          const existingAccess = filter(this.hierarchyByStoreFormat[storeFormat], h =>
            includes(flatAccess, h.levelEntryKey)
          );
          output[storeFormat] = map(existingAccess, 'levelEntryKey');
          return output;
        },
        {}
      );
      this.editDialogOpen = true;
    },

    closeEditDialog() {
      this.editedUser = {};
      this.editDialogOpen = false;
    },

    formatUpdates() {
      const levels = {
        [unitLevel]: 'Unit',
        [categoryLevel]: 'Category',
      };
      const formattedAccess = { Unit: [], Category: [] };
      return reduce(
        this.accessByStoreFormat,
        (o, storeFormatAccess, storeFormat) => {
          return reduce(
            storeFormatAccess,
            (output, access) => {
              const { level, levelEntryKey } = find(
                this.hierarchyByStoreFormat[storeFormat],
                h => h.levelEntryKey === access
              );
              output[levels[level]].push({
                levelEntryKey,
              });
              return output;
            },
            o
          );
        },
        formattedAccess
      );
    },

    async updateUser() {
      const updates = { access: this.formatUpdates(), _id: this.editedUser._id };
      await this.updateUserProfile({ _id: this.editedUser._id, updatedItem: updates });
      await this.loadUserProfiles();
      this.getUserSearchResults();
      this.editedUser = {};
      this.editDialogOpen = false;
    },

    getUserSearchResults() {
      if (this.search) {
        this.userSearchRegex = new RegExp(`.*${this.search}.*`, 'i');
        this.searchResults = this.categoryAccessList.filter(profile => {
          return profile.username.match(this.userSearchRegex);
        });
      }
    },

    debounceSearchUpdate: debounce(function(value) {
      this.search = value;
      this.pagination.page = 1;
      this.getUserSearchResults();
    }, clientConfig.inputDebounceValue),
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables';

.user-roles {
  background-color: $pricing-white;
  .v-card {
    .btn-group.v-card__actions {
      .cancel-btn {
        color: $btn-cancel-color;
        border-color: $btn-cancel-color;
      }

      .ok-btn {
        color: $pricing-white;
        background-color: $btn-ok-color;
        border-color: $btn-ok-color;
      }
    }
  }

  .main-header th {
    position: relative;
  }

  .v-treeview-node {
    padding-bottom: 1rem !important;
    margin-bottom: 1rem !important;
  }

  .hierarchy-item {
    font-size: 1.5rem;
  }
}

.search-box {
  width: 25vw;
  margin-left: auto;
  margin-right: 1%;
}
</style>

<style lang="scss">
.hierarchy-item {
  .v-treeview-node__label,
  .v-treeview-node__checkbox,
  .v-treeview-node__toggle {
    padding-bottom: 0.5rem !important;
  }
}
</style>
