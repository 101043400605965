import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VExpandTransition } from 'vuetify/lib/components/transitions';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTabsItems } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("rule-header", {
        class: {
          "expanded-top": _vm.isExpanded(_vm.rulesetType, _vm.ruleIndex),
        },
        attrs: {
          rule: _vm.mutableRule,
          "product-counts": _vm.productCounts || [],
          expanded: _vm.isExpanded(_vm.rulesetType, _vm.ruleIndex),
          disabled: _vm.disabled,
        },
        on: {
          expand: function ($event) {
            return _vm.toggleExpanded(_vm.rulesetType, _vm.ruleIndex)
          },
          deleteRule: _vm.deleteRule,
          updateDescription: _vm.updateDescription,
        },
      }),
      _vm._v(" "),
      _c(
        VExpandTransition,
        [
          _vm.isExpanded(_vm.rulesetType, _vm.ruleIndex)
            ? _c(
                VFlex,
                { attrs: { grow: "" } },
                [
                  _c(
                    VCard,
                    {
                      staticClass: "rule-card expanded-bottom",
                      class: {
                        "elevation-6": !_vm.isExpanded(
                          _vm.rulesetType,
                          _vm.ruleIndex
                        ),
                      },
                      attrs: { outlined: "" },
                    },
                    [
                      _c(
                        VCard,
                        { staticClass: "rule-content", attrs: { flat: "" } },
                        [
                          _c("attribute-filter-panel", {
                            attrs: {
                              "ruleset-type": _vm.rulesetType,
                              "filter-rules": _vm.mutableRule.scope,
                              "enable-non-attribute-filters": false,
                              "enable-hierarchy-filters": true,
                              "hierarchy-filter-level":
                                _vm.hierarchyFilterLevel,
                              "title-localisation":
                                "settings.penaltyRules.scope.scopeApplied",
                              "filter-count-localisation":
                                "settings.penaltyRules.scope.numApplied",
                            },
                            on: { attributeFilterChange: _vm.updateRuleScope },
                          }),
                          _vm._v(" "),
                          _c(
                            VTabs,
                            {
                              staticClass: "pricing-tabs",
                              attrs: {
                                "active-class": "pricing-tabs__tab--active",
                                "background-color": "transparent",
                                color: "white",
                                height: "4rem",
                                "hide-slider": "",
                              },
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            [
                              _vm._l(_vm.tabs, function (item, i) {
                                return [
                                  _c(
                                    "div",
                                    {
                                      key: item.iconText + i,
                                      staticClass: "d-flex",
                                      attrs: {
                                        "custom-title-msg": item.tooltipMessage,
                                        "custom-title-hidden":
                                          !item.tooltipMessage,
                                        "custom-title-position": "right",
                                      },
                                    },
                                    [
                                      _c(
                                        VTab,
                                        {
                                          staticClass:
                                            "pricing-tabs__tab pricing-tabs__tab--first pr-0",
                                          on: {
                                            click: function ($event) {
                                              return _vm.tabChange(item)
                                            },
                                          },
                                        },
                                        [
                                          _c(
                                            VLayout,
                                            [
                                              _c(
                                                VLayout,
                                                {
                                                  staticClass: "pr-1",
                                                  attrs: {
                                                    "align-self-center": "",
                                                    "justify-start": "",
                                                  },
                                                },
                                                [
                                                  _c(
                                                    VAvatar,
                                                    {
                                                      staticClass:
                                                        "traffic-light",
                                                      class: item.iconClass,
                                                      attrs: { size: "2.5rem" },
                                                    },
                                                    [
                                                      _c(
                                                        "span",
                                                        {
                                                          staticClass:
                                                            "white--text headline",
                                                        },
                                                        [
                                                          _vm._v(
                                                            _vm._s(
                                                              item.iconText
                                                            )
                                                          ),
                                                        ]
                                                      ),
                                                    ]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass: "tab-title",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(item.tabText)
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _vm._v(" "),
                                              _c(
                                                VLayout,
                                                {
                                                  attrs: {
                                                    "align-self-center": "",
                                                    "justify-end": "",
                                                  },
                                                },
                                                [
                                                  _c(VCheckbox, {
                                                    staticClass:
                                                      "activate-checkbox pl-4 pr-0",
                                                    attrs: {
                                                      "true-value": _vm.active,
                                                      "false-value":
                                                        _vm.inactive,
                                                      disabled:
                                                        _vm.isTabDisabled(item),
                                                    },
                                                    on: {
                                                      change: function (
                                                        $event
                                                      ) {
                                                        return _vm.setStatus(
                                                          item
                                                        )
                                                      },
                                                    },
                                                    model: {
                                                      value: item.status,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          item,
                                                          "status",
                                                          $$v
                                                        )
                                                      },
                                                      expression: "item.status",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ]
                              }),
                              _vm._v(" "),
                              _c("div", {
                                staticClass: "pricing-tabs__filler",
                              }),
                            ],
                            2
                          ),
                          _vm._v(" "),
                          _c(
                            VTabsItems,
                            {
                              model: {
                                value: _vm.tab,
                                callback: function ($$v) {
                                  _vm.tab = $$v
                                },
                                expression: "tab",
                              },
                            },
                            _vm._l(_vm.tabs, function (item, i) {
                              return _c(
                                VTabItem,
                                {
                                  key: item.iconText + i,
                                  staticClass: "tab-content",
                                  class: { opaque: item.disabled },
                                },
                                [
                                  item.penalty ===
                                  _vm.penaltyCategories.competitor
                                    ? _c(
                                        VContainer,
                                        { attrs: { fluid: "" } },
                                        [
                                          _c(VRow, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field-heading pr-3 pt-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "settings.penaltyRules.competitorSelectText"
                                                      )
                                                    ) +
                                                    ":\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pr-2" },
                                              [
                                                _c(VSelect, {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    items:
                                                      _vm.competitorDropdownValues,
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function (props) {
                                                          return [
                                                            _c(
                                                              VLayout,
                                                              [
                                                                _c(
                                                                  VLayout,
                                                                  {
                                                                    attrs: {
                                                                      "align-self-center":
                                                                        "",
                                                                      "justify-start":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .text
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  VLayout,
                                                                  {
                                                                    attrs: {
                                                                      "align-self-center":
                                                                        "",
                                                                      "justify-end":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.isCompetitorActive(
                                                                      props.item
                                                                        .value
                                                                    )
                                                                      ? _c(
                                                                          VIcon,
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                right:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              done\n                            "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.selectedCompetitorIndex,
                                                    callback: function ($$v) {
                                                      _vm.selectedCompetitorIndex =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedCompetitorIndex",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(VCheckbox, {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "settings.penaltyRules.activeCompetitor"
                                                    ),
                                                    disabled:
                                                      _vm.isTabDisabled(item),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.competitorIsActive,
                                                    callback: function ($$v) {
                                                      _vm.competitorIsActive =
                                                        $$v
                                                    },
                                                    expression:
                                                      "competitorIsActive",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.penalty ===
                                  _vm.penaltyCategories.storeGroup
                                    ? _c(
                                        VContainer,
                                        { attrs: { fluid: "" } },
                                        [
                                          _c(VRow, [
                                            _c(
                                              "div",
                                              {
                                                staticClass:
                                                  "field-heading pr-3 pt-2",
                                              },
                                              [
                                                _vm._v(
                                                  "\n                    " +
                                                    _vm._s(
                                                      _vm.$t(
                                                        "settings.penaltyRules.storeGroupSelectText"
                                                      )
                                                    ) +
                                                    ":\n                  "
                                                ),
                                              ]
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              { staticClass: "pr-2" },
                                              [
                                                _c(VSelect, {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    items:
                                                      _vm.storeGroupDropdownValues,
                                                    disabled:
                                                      _vm.isTabDisabled(item),
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "item",
                                                        fn: function (props) {
                                                          return [
                                                            _c(
                                                              VLayout,
                                                              [
                                                                _c(
                                                                  VLayout,
                                                                  {
                                                                    attrs: {
                                                                      "align-self-center":
                                                                        "",
                                                                      "justify-start":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      "span",
                                                                      {
                                                                        staticClass:
                                                                          "item-text",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            props
                                                                              .item
                                                                              .text
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ]
                                                                ),
                                                                _vm._v(" "),
                                                                _c(
                                                                  VLayout,
                                                                  {
                                                                    attrs: {
                                                                      "align-self-center":
                                                                        "",
                                                                      "justify-end":
                                                                        "",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm.isStoreGroupActive(
                                                                      props.item
                                                                        .value
                                                                    )
                                                                      ? _c(
                                                                          VIcon,
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                right:
                                                                                  "",
                                                                              },
                                                                          },
                                                                          [
                                                                            _vm._v(
                                                                              "\n                              done\n                            "
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    true
                                                  ),
                                                  model: {
                                                    value:
                                                      _vm.selectedStoreGroupIndex,
                                                    callback: function ($$v) {
                                                      _vm.selectedStoreGroupIndex =
                                                        $$v
                                                    },
                                                    expression:
                                                      "selectedStoreGroupIndex",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _c(
                                              "div",
                                              [
                                                _c(VCheckbox, {
                                                  staticClass: "pa-0 ma-0",
                                                  attrs: {
                                                    label: _vm.$t(
                                                      "settings.penaltyRules.activeCompetitor"
                                                    ),
                                                    disabled:
                                                      _vm.isTabDisabled(item),
                                                  },
                                                  model: {
                                                    value:
                                                      _vm.storeGroupIsActive,
                                                    callback: function ($$v) {
                                                      _vm.storeGroupIsActive =
                                                        $$v
                                                    },
                                                    expression:
                                                      "storeGroupIsActive",
                                                  },
                                                }),
                                              ],
                                              1
                                            ),
                                            _vm._v(" "),
                                            _vm.displayStoreGroupPenaltyError
                                              ? _c(
                                                  "div",
                                                  {
                                                    staticClass:
                                                      "field-heading pl-3 pt-1 red--text",
                                                  },
                                                  [
                                                    _c("span", [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm
                                                            .selectedStoreGroupPenalty
                                                            .error
                                                        )
                                                      ),
                                                    ]),
                                                  ]
                                                )
                                              : _vm._e(),
                                          ]),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.penalty ===
                                  _vm.penaltyCategories.pricePointing
                                    ? _c(
                                        VContainer,
                                        { attrs: { fluid: "" } },
                                        [
                                          _c(
                                            "div",
                                            {
                                              staticClass: "d-flex flex-column",
                                            },
                                            [
                                              _vm.duplicateRangeMaximumsInPricePointing
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "validation-fail pl-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.penaltyRules.pricePointing.pricePointingRangeMaximumValidationError"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.missingEmptyPricePointingRange
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "validation-fail pl-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.penaltyRules.pricePointing.pricePointingMissingMaxRange"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                              _vm._v(" "),
                                              _vm.missingTokensForPricePointingRange
                                                ? _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "validation-fail pl-2",
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                    " +
                                                          _vm._s(
                                                            _vm.$t(
                                                              "settings.penaltyRules.pricePointing.pricePointingMissingOffsets"
                                                            )
                                                          ) +
                                                          "\n                  "
                                                      ),
                                                    ]
                                                  )
                                                : _vm._e(),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _vm._l(
                                            item.rule.allowedValues,
                                            function (allowedValue, index) {
                                              return _c("price-pointing", {
                                                key: index,
                                                attrs: {
                                                  "price-pointing":
                                                    allowedValue,
                                                  "index-in-array": index,
                                                  disabled:
                                                    _vm.isTabDisabled(item),
                                                },
                                                on: {
                                                  deletePricePointing:
                                                    _vm.deletePricePointing,
                                                  updateRangeMaximum:
                                                    _vm.updateRangeMaximum,
                                                  updateOffset:
                                                    _vm.updateOffset,
                                                  updateBase: _vm.updateBase,
                                                },
                                              })
                                            }
                                          ),
                                          _vm._v(" "),
                                          !_vm.disabled
                                            ? _c(
                                                "div",
                                                {
                                                  staticClass:
                                                    "add-pricepointing-container pl-2",
                                                },
                                                [
                                                  _c(
                                                    VIcon,
                                                    {
                                                      staticClass:
                                                        "add-pricepointing-icon",
                                                      attrs: { small: "" },
                                                      on: {
                                                        click:
                                                          _vm.startPricePointingCreation,
                                                      },
                                                    },
                                                    [_vm._v("fa-plus-square")]
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    "span",
                                                    {
                                                      staticClass:
                                                        "add-pricepointing-text",
                                                    },
                                                    [
                                                      _vm._v(
                                                        _vm._s(
                                                          _vm.$t(
                                                            "settings.penaltyRules.pricePointing.addRangeBreak"
                                                          )
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        2
                                      )
                                    : _vm._e(),
                                  _vm._v(" "),
                                  item.penalty !==
                                  _vm.penaltyCategories.pricePointing
                                    ? _c(
                                        VLayout,
                                        [
                                          _c(
                                            VFlex,
                                            { attrs: { xs12: "" } },
                                            [
                                              _c(
                                                VLayout,
                                                [
                                                  _c(
                                                    "form",
                                                    {
                                                      staticClass:
                                                        "formWrapper",
                                                      attrs: { novalidate: "" },
                                                    },
                                                    [
                                                      _c(
                                                        VFlex,
                                                        { attrs: { xs8: "" } },
                                                        [
                                                          _c(
                                                            VLayout,
                                                            {
                                                              attrs: {
                                                                "align-baseline":
                                                                  "",
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                VTextField,
                                                                {
                                                                  attrs: {
                                                                    flat: "",
                                                                    "background-color":
                                                                      _vm.owWhite,
                                                                    label:
                                                                      _vm.$t(
                                                                        "settings.penaltyRules.offset"
                                                                      ),
                                                                    type: "number",
                                                                    value:
                                                                      _vm.getPenaltyRuleFromItem(
                                                                        item
                                                                      )
                                                                        .penaltyOffset,
                                                                    rules:
                                                                      _vm.rules
                                                                        .offsetInput,
                                                                    disabled:
                                                                      _vm.isTabDisabled(
                                                                        item
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    input: (
                                                                      value
                                                                    ) =>
                                                                      _vm.debounceOffsetChange(
                                                                        item,
                                                                        value
                                                                      ),
                                                                  },
                                                                }
                                                              ),
                                                              _vm._v(" "),
                                                              _c(
                                                                "span",
                                                                {
                                                                  staticClass:
                                                                    "suffix",
                                                                },
                                                                [_vm._v(" % ")]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          item.penalty ===
                                                          _vm.penaltyCategories
                                                            .competitor
                                                            ? _c(
                                                                VLayout,
                                                                {
                                                                  attrs: {
                                                                    "align-baseline":
                                                                      "",
                                                                  },
                                                                },
                                                                [
                                                                  _c(
                                                                    "span",
                                                                    {
                                                                      staticClass:
                                                                        "prefix",
                                                                    },
                                                                    [
                                                                      _vm._v(
                                                                        "\n                            " +
                                                                          _vm._s(
                                                                            _vm.$t(
                                                                              "clientCurrencySymbol"
                                                                            )
                                                                          ) +
                                                                          "\n                          "
                                                                      ),
                                                                    ]
                                                                  ),
                                                                  _vm._v(" "),
                                                                  _c(
                                                                    VTextField,
                                                                    {
                                                                      attrs: {
                                                                        value:
                                                                          _vm.formatNumber(
                                                                            {
                                                                              number:
                                                                                _vm.getPenaltyRuleFromItem(
                                                                                  item
                                                                                )
                                                                                  .absoluteOffset,
                                                                              format:
                                                                                _vm
                                                                                  .numberFormats
                                                                                  .priceFormatNegative,
                                                                            }
                                                                          ),
                                                                        type: "text",
                                                                        "background-color":
                                                                          _vm.owWhite,
                                                                        label:
                                                                          _vm.$t(
                                                                            "settings.penaltyRules.absoluteOffset"
                                                                          ),
                                                                        disabled:
                                                                          _vm.isTabDisabled(
                                                                            item
                                                                          ),
                                                                      },
                                                                      on: {
                                                                        keypress:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.ensureDecimal(
                                                                              $event,
                                                                              true
                                                                            )
                                                                          },
                                                                        focus:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            _vm.previousFieldValue =
                                                                              $event.target.value
                                                                          },
                                                                        blur: _vm.verifyFieldValue,
                                                                        change:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.absoluteOffsetChange(
                                                                              $event,
                                                                              item
                                                                            )
                                                                          },
                                                                      },
                                                                    }
                                                                  ),
                                                                ],
                                                                1
                                                              )
                                                            : _vm._e(),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    VFlex,
                                                    {
                                                      staticClass:
                                                        "chart-container",
                                                      attrs: {
                                                        layout: "",
                                                        xs6: "",
                                                        "pt-8": "",
                                                      },
                                                    },
                                                    [
                                                      _c("penalty-chart", {
                                                        key: _vm.forceRefresh[
                                                          item.penalty
                                                        ],
                                                        attrs: {
                                                          "penalty-category":
                                                            item.penalty,
                                                          "penalty-curve-points":
                                                            _vm.getPenaltyRuleFromItem(
                                                              item
                                                            ),
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm._v(" "),
                                                  _c(
                                                    VFlex,
                                                    {
                                                      attrs: {
                                                        xs4: "",
                                                        layout: "",
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        VLayout,
                                                        {
                                                          attrs: {
                                                            "justify-space-between":
                                                              "",
                                                          },
                                                        },
                                                        [
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "formWrapper mr-3",
                                                            },
                                                            [
                                                              _c(
                                                                "penalty-parameters-form",
                                                                {
                                                                  staticClass:
                                                                    "penalty-form",
                                                                  attrs: {
                                                                    "penalty-name":
                                                                      "lower",
                                                                    "penalty-rule":
                                                                      _vm.getPenaltyParameters(
                                                                        item,
                                                                        "lower"
                                                                      ),
                                                                    disabled:
                                                                      _vm.isTabDisabled(
                                                                        item
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    updatePenaltyRule:
                                                                      (event) =>
                                                                        _vm.updatePenaltyRuleHandler(
                                                                          item,
                                                                          event
                                                                        ),
                                                                    penaltyRuleInvalid:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.validRule = false
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _vm._v(" "),
                                                          _c(
                                                            "div",
                                                            {
                                                              staticClass:
                                                                "formWrapper",
                                                            },
                                                            [
                                                              _c(
                                                                "penalty-parameters-form",
                                                                {
                                                                  staticClass:
                                                                    "penalty-form",
                                                                  attrs: {
                                                                    "penalty-name":
                                                                      "upper",
                                                                    "penalty-rule":
                                                                      _vm.getPenaltyParameters(
                                                                        item,
                                                                        "upper"
                                                                      ),
                                                                    disabled:
                                                                      _vm.isTabDisabled(
                                                                        item
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    updatePenaltyRule:
                                                                      (event) =>
                                                                        _vm.updatePenaltyRuleHandler(
                                                                          item,
                                                                          event
                                                                        ),
                                                                    penaltyRuleInvalid:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.validRule = false
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      )
                                    : _vm._e(),
                                ],
                                1
                              )
                            }),
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VLayout,
                            {
                              attrs: {
                                "align-baseline": "",
                                "justify-end": "",
                              },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.disabled,
                                      expression: "!disabled",
                                    },
                                  ],
                                  staticClass: "mt-2 mr-2",
                                  attrs: {
                                    small: "",
                                    color: "primary",
                                    outlined: "",
                                    depressed: "",
                                  },
                                  on: { click: _vm.cancel },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("actions.cancel")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                              _vm._v(" "),
                              _c(
                                VBtn,
                                {
                                  directives: [
                                    {
                                      name: "show",
                                      rawName: "v-show",
                                      value: !_vm.disabled,
                                      expression: "!disabled",
                                    },
                                  ],
                                  staticClass: "mt-2 save",
                                  attrs: {
                                    color: "success",
                                    disabled: _vm.disableSaveBtn,
                                    small: "",
                                    depressed: "",
                                  },
                                  on: { click: _vm.save },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.$t("actions.save")) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }