<template>
  <div v-if="approvals && approvals.length" class="approvals">
    <div
      v-for="approval of approvals"
      :key="approval.id"
      class="approval"
      :custom-title-msg="approval.tooltipMessage"
      :custom-title-hidden="approval.tooltipMessage === null"
      custom-title-position="top-right"
    >
      <v-select
        class="pb-0 ma-0 approval-select"
        :items="approvalOptions"
        height="2rem"
        :value="approval.value"
        :disabled="approval.disabled || isSelectedWorkpackageMaster"
        @change="approvalChange(approval, $event)"
      >
        <template v-slot:item="{ item }">
          <span v-if="item"><v-icon v-once color="success">check_circle</v-icon></span>
          <span v-else><v-icon v-once color="red">cancel</v-icon></span>
        </template>
        <template v-slot:selection="{ item }">
          <span v-if="item">
            <v-icon v-once size="2.2rem" color="success">check_circle</v-icon>
          </span>
          <span v-else>
            <v-icon v-once size="2.2rem" color="red">cancel</v-icon>
          </span>
        </template>
      </v-select>
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
  name: 'HierarchyLevelItemApprovals',
  props: {
    approvals: {
      type: Array,
      required: true,
      default: () => [],
    },
  },
  data() {
    return {
      approvalOptions: [true, false],
    };
  },
  computed: {
    ...mapGetters('workpackages', ['isSelectedWorkpackageMaster']),
  },
  methods: {
    approvalChange(approval, approvalStatus) {
      approval.change({ approved: approvalStatus });
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
$approval-action-offset: 0.5rem;

.approvals {
  display: flex;
}

.approval {
  box-sizing: border-box;
  padding: 0 $approval-action-offset;
  width: 4.4rem;

  &:last-child {
    padding-right: 0;
  }

  &:first-child {
    padding-left: 0;
  }
}

.approval + .approval {
  border-left: 1px solid $paramsFormBackgroundColor;
}

.approval-select {
  padding-top: 0.5rem;

  .v-text-field__details {
    display: none;
  }

  .v-select__selections {
    height: 2.8rem;
  }

  .v-input__slot {
    margin-bottom: unset !important;
  }
}
</style>
