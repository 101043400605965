<template>
  <div class="grid-view-container">
    <v-data-table
      ref="dataTable"
      class="grid-view"
      :class="{
        'colored-architecture-groups':
          isSortByArchitectureGroupsEnabled || isSortByArchitectureSubGroupsEnabled,
        'colored-architecture-sub-groups': isSortByArchitectureSubGroupsEnabled,
      }"
      :options.sync="pagination"
      :sort-desc.sync="pagination.descending"
      :items="items"
      :headers="headers"
      :search="search"
      :custom-sort="customSort"
      :custom-filter="customFilter"
      :single-expand="true"
      :loading="itemsNotReadyForDisplay"
      item-key="_id"
      hide-default-footer
      hide-default-header
      @update:page="$vuetify.goTo($refs.dataTable)"
    >
      <template v-slot:top>
        <table>
          <tr>
            <td>
              <v-btn
                id="download-button"
                :loading="downloadingItems"
                color="primary"
                small
                depressed
                @click="downloadData"
              >
                {{ $t('actions.download') }}
              </v-btn>
              <span class="align-self-center summary-text">{{ rowSummary }} </span>
              <v-icon
                v-if="items < totalRows"
                v-once
                small
                color="primary"
                @click="removeActiveTableFilter(activeTableFilters)"
              >
                delete
              </v-icon>
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
      <template v-slot:header="{ props }">
        <tr class="main-header border-bottom">
          <!-- 4 columns: architecture group, product key, article, size -->
          <!-- 5th column: tool store group column -->
          <th class="border-right" :colspan="showToolStoreGroup ? 6 : 5">
            <v-row>
              <v-col class="col pt-0 pb-0">
                <v-text-field
                  class="search-box pt-0"
                  :value="search"
                  append-icon="search"
                  :hide-details="true"
                  :label="$t('actions.search')"
                  single-line
                  :loading="loading"
                  @input="debounceSearchUpdate"
                />
              </v-col>
            </v-row>
            <div>
              <v-row>
                <v-col class="pt-0">
                  <v-row>
                    <v-col class="col pt-0">
                      <v-row>
                        <v-switch
                          v-if="!isUnitManagerView"
                          v-model="isSortByArchitectureGroupsEnabled"
                          class="mr-2 pt-0 sort-by-architecture-groups-switcher"
                          :label="$t('gridView.sortByArchitectureGroups')"
                          primary
                          data-test-id="sort-by-architecture-groups-switcher"
                          dense
                          hide-details
                          @change="toggleSortingByArchitectureGroups"
                        />
                      </v-row>
                      <v-row>
                        <v-switch
                          v-if="!isUnitManagerView"
                          v-model="isSortByArchitectureSubGroupsEnabled"
                          class="mr-2 pt-0 sort-by-architecture-groups-switcher"
                          :label="$t('gridView.sortByArchitectureSubGroups')"
                          primary
                          data-test-id="sort-by-architecture-sub-groups-switcher"
                          dense
                          hide-details
                          @change="toggleSortingByArchitectureSubGroups"
                        />
                      </v-row>
                    </v-col>
                  </v-row>
                </v-col>

                <v-col class="pt-0 mt-2">
                  <norm-weight-toggle class="norm-weight-toggle" />
                </v-col>
              </v-row>
            </div>
          </th>
          <th class="border-right text-left" colspan="3">{{ $t('pricing.mainHeaders.sales') }}</th>
          <th class="border-right text-left" colspan="2">{{ $t('pricing.mainHeaders.cost') }}</th>
          <th class="border-right text-left" colspan="4">
            {{ $t('pricing.mainHeaders.prices') }}
          </th>
          <th
            :class="['border-right', 'text-left', 'border-left-primary', 'scenario-colour']"
            colspan="6"
          >
            <v-row v-if="isUnitManagerView" no-gutters>
              {{ $t('pricing.mainHeaders.proposedPricing') }}
            </v-row>
            <v-row v-else no-gutters>
              <scenario-filter
                :hide-pricing-group-selection="true"
                :dense="false"
                :solo="false"
                :hide-label="true"
                @selectScenario="selectScenario"
              />
              <tooltip :value="engineBlockedMsg" position="top-left" :disabled="engineCanRun">
                <v-btn
                  depressed
                  color="primary"
                  :loading="calculatingScenarioResults"
                  :disabled="!isEditable || !engineCanRun"
                  class="mr-2 btn-refresh"
                  data-dd-action-name="Grid view recalculate all"
                  @click="recalculate"
                >
                  <v-icon v-once>mdi-refresh</v-icon>
                </v-btn>
              </tooltip>
            </v-row>
          </th>
          <th class="border-right text-left" colspan="4">
            {{ $t('pricing.mainHeaders.competitors') }}
          </th>
          <th class="text-left">{{ $t('pricing.mainHeaders.tension') }}</th>
          <th class="alert-padding" />
        </tr>
        <tr class="sub-header v-data-table-header">
          <th
            v-for="header in props.headers"
            :key="header.key || header.valuePath"
            :class="[
              'column text-xs-left',
              header.sortable ? 'sortable' : '',
              pagination.descending ? 'desc' : 'asc',
              header.valuePath === pagination.sortBy[0] ? 'active' : '',
              header.class,
            ]"
            :style="header.style"
          >
            <!-- Keep competitor headers up-to-date with selected competitors -->
            <span v-if="header.hasOwnProperty('competitorHeader')">
              {{ selectedCompetitors[header.competitorHeader].competitorDisplayDescription }}
            </span>
            <span
              v-else
              data-dd-action-name="Sort grid view table column"
              @click="changeSort(header)"
            >
              <span>
                {{ $t(header.text) }}
              </span>
            </span>
            <div v-if="header && header.sortable">
              <grid-view-filter-popover :header="header" />
              <v-icon
                size="1.4rem"
                class="v-data-table-header__icon"
                data-dd-action-name="Sort grid view table column"
                @click="changeSort(header)"
              >
                arrow_upward
              </v-icon>
            </div>
          </th>
        </tr>
      </template>

      <!-- NOTE: please update getAllDeprecated in workpackage-product.controller if any data reference below has changed -->
      <template v-slot:item="expandableItem">
        <tr
          :key="expandableItem.index"
          :class="['border-right', { 'on-promo-row-color': itemOnPromotion(expandableItem.item) }]"
          @click="expandableItem.expand(!expandableItem.isExpanded)"
        >
          <!-- Article section -->
          <!-- Architecture Group -->
          <td
            class="architecture-group-cell"
            :class="
              'color-' +
                architectureGroupDescriptionToColorMap[
                  expandableItem.item.architectureGroupDescription
                ]
            "
            :custom-title-msg="expandableItem.item.architectureGroupDescription"
            :custom-title-hidden="!isSortByArchitectureGroupsEnabled"
            custom-title-position="right"
          />
          <!-- Architecture Sub Group -->
          <td
            class="architecture-sub-group-cell"
            :class="
              'color-' +
                get(architectureSubGroupDescriptionToColorMap, [
                  expandableItem.item.architectureGroupDescription,
                  expandableItem.item.subGroupDescription,
                ])
            "
            :custom-title-msg="getSubGroupTitleMsg(expandableItem)"
            :custom-title-hidden="!isSortByArchitectureSubGroupsEnabled"
            custom-title-position="right"
          />
          <!-- Nasa -->
          <td class="text-right">{{ expandableItem.item.productKeyDisplay }}</td>

          <!-- Tool Group Store -->
          <td v-if="showToolStoreGroup" class="text-xs-left">
            <tooltipped-truncated-field
              :text="expandableItem.item.toolStoreGroupDescription"
              :truncation-length="truncationLength.toolStoreGroup"
              tooltip-position="right"
            />
          </td>

          <!-- Article -->
          <td class="text-xs-left font-weight-bold">
            <tooltipped-truncated-field
              :text="expandableItem.item.productDescription"
              :truncation-length="truncationLength.article"
              tooltip-position="right"
            />
          </td>

          <!-- Size -->
          <td class="text-right border-right">
            <norm-weight-tooltip
              :value="expandableItem.item.productSizeType"
              :norm-weight="expandableItem.item.normWeight"
              :norm-weight-uo-m="expandableItem.item.normWeightUnitOfMeasure"
            />
          </td>
          <!-- End Article section -->

          <!-- Sales section -->
          <!-- Sales -->
          <td class="text-right">
            {{
              formatNumber({
                number: expandableItem.item.yearlySales / 1000,
                format: numberFormats.oneDecimalPlace,
              })
            }}
          </td>

          <!-- Volume -->
          <td class="text-right">
            {{
              formatNumber({
                number: expandableItem.item.yearlyVolume / 1000,
                format: numberFormats.oneDecimalPlace,
              })
            }}
          </td>

          <!-- Promo -->
          <td
            class="text-right border-right"
            custom-title-position="right"
            :custom-title-msg="
              formatTooltipMessage({
                [$t('gridView.popupText.promoParticipationSales')]: formatNumber({
                  number: formatEngineInput(
                    expandableItem.item.mandatoryEngineInputs.promoParticipationSales
                  ),
                  format: numberFormats.percent,
                  nullAsDash: true,
                }),
                [$t('gridView.popupText.promoDiscount')]: formatNumber({
                  number: formatEngineInput(
                    expandableItem.item.mandatoryEngineInputs.promoDiscount
                  ),
                  format: numberFormats.percent,
                  nullAsDash: true,
                }),
                [$t('gridView.popupText.promoFunding')]: formatNumber({
                  number: formatEngineInput(expandableItem.item.mandatoryEngineInputs.promoFunding),
                  format: numberFormats.percent,
                  nullAsDash: true,
                }),
              })
            "
          >
            <div class="right-aligned-text-inside-tooltip">
              {{
                formatNumber({
                  number: formatEngineInput(
                    expandableItem.item.mandatoryEngineInputs.promoParticipationSales
                  ),
                  format: numberFormats.percent,
                  nullAsDash: true,
                })
              }}
            </div>
          </td>
          <!-- End Sales section -->

          <!-- Cost section -->
          <!-- Go-live -->
          <td class="text-right">
            {{
              formatNumber({
                number: getNonPromoNetCost(expandableItem.item),
                format: numberFormats.priceFormat,
                nullAsDash: true,
              })
            }}
          </td>

          <!-- Cost Delta -->
          <td
            class="text-right border-right"
            :class="[formatNumberColourClass(-expandableItem.item.costDelta)]"
          >
            {{ getCostDelta(expandableItem.item) }}
          </td>
          <!-- End Cost section -->

          <!-- Price Section -->
          <!-- Chart -->
          <td class="text-right chart pa-0">
            <sparkline-chart
              :price-history-week="expandableItem.item.priceHistoryWeek"
              :intention-price="expandableItem.item.intentionPrice.price"
              :options="chartOptions"
            />
          </td>

          <!-- Live price -->
          <td class="text-right font-weight-bold">
            {{ getLivePrice(expandableItem.item) }}
          </td>

          <!-- Future Price / Intention Price -->
          <td
            class="text-right"
            custom-title-position="right"
            :custom-title-msg="intentionPriceTooltipValue(expandableItem)"
            :custom-title-hidden="!displayIntentionPriceTooltip(expandableItem)"
          >
            {{ getFuturePrice(expandableItem.item) }}
          </td>

          <!-- Margin -->
          <td class="text-right border-right font-weight-bold">
            {{
              formatNumber({
                number: expandableItem.item.intentionMargin,
                format: numberFormats.percent,
              })
            }}
          </td>
          <!-- End Price section -->

          <!-- Scenario Results section -->
          <!-- Price -->
          <td
            :class="[
              'text-right',
              'pl-0',
              'price-override',
              'border-left-primary',
              'border-right-primary',
              'scenario-colour',
            ]"
          >
            <v-layout xs12 :class="getClassForOverride(expandableItem.item)" @click="preventExpand">
              <v-flex xs3 class="text-xs-left">
                <div
                  v-if="
                    isEditable &&
                      hasOverride(expandableItem.item) &&
                      !isSmartOverride(expandableItem.item)
                  "
                  class="revert-button"
                  @click="handleRevertButtonClick(expandableItem.item)"
                >
                  <v-icon v-once x-small dark>fa fa-undo</v-icon>
                </div>
              </v-flex>
              <v-flex xs9>
                <div v-if="expandableItem.item.scenarioPrice" class="d-flex justify-space-between">
                  <pricing-edit-text-field
                    :key="expandableItem.item._id"
                    class="font-weight-bold pa-0"
                    :data-type="dataType.number"
                    :number-format="numberFormats.priceFormat"
                    :value="getOverridePrice(expandableItem.item)"
                    :previous-value="getPreviousValue(expandableItem.item)"
                    :error="updateErrors[expandableItem.item._id]"
                    :cell-class="getOverrideFieldClass(expandableItem.item)"
                    :tooltip-value="scenarioPriceTooltip(expandableItem.item)"
                    tooltip-position="right"
                    previous-value-translation-key="gridView.tooltips"
                    :disabled="!isEditable"
                    @focus="debounceShowDatePicker(expandableItem.item)"
                    @change="changeOverridePrice($event.target.value, expandableItem.item)"
                    @dblclick="cancelScenarioPrice(expandableItem.item)"
                    @blur="debounceRemoveIcon(expandableItem.item)"
                  />
                  <div class="override-container">
                    <v-menu
                      v-if="isEditable && shouldDisplayCalendar(expandableItem.item)"
                      :close-on-content-click="false"
                      :nudge-right="5"
                      transition="scale-transition"
                      offset-x
                      top
                      @input="closeCalendar($event)"
                    >
                      <template v-slot:activator="{ on }">
                        <v-btn
                          color="primary"
                          class="override-calendar-button"
                          depressed
                          x-small
                          v-on="on"
                        >
                          <v-icon v-once small> event </v-icon>
                        </v-btn>
                      </template>
                      <v-date-picker
                        v-model="priceOverrideDates[expandableItem.item._id]"
                        :first-day-of-week="i18nconfig.firstDayOfTheWeek"
                        :min="today"
                        no-title
                        :locale="i18nconfig.fallbackLocale"
                        @input="selectOverrideDates(expandableItem.item, $event)"
                      >
                        <v-spacer />
                        <v-btn
                          text
                          outlined
                          depressed
                          small
                          color="primary"
                          @click="removePriceLock(expandableItem.item)"
                        >
                          {{ $t('gridView.removePriceLock') }}
                        </v-btn>
                      </v-date-picker>
                    </v-menu>
                  </div>
                </div>
                <div v-else>-</div>
              </v-flex>
            </v-layout>
          </td>

          <!-- Price change -->
          <td class="text-right scenario-colour">
            {{ getScenarioPriceChange(expandableItem.item) }}
          </td>

          <!-- Price change ratio -->
          <td class="text-right scenario-colour">
            {{ getScenarioPriceChangeRatio(expandableItem.item) }}
          </td>

          <!-- Margin -->
          <td class="text-right scenario-colour font-weight-bold">
            {{
              formatNumberEmptyCheck(expandableItem.item, 'scenarioMargin', numberFormats.percent)
            }}
          </td>

          <!-- Sales impact -->
          <td
            class="text-right scenario-colour font-weight-bold"
            :class="[
              formatNumberColourClass(
                getSalesImpact(expandableItem.item) ? getSalesImpact(expandableItem.item) / 1000 : 0
              ),
            ]"
          >
            {{
              getSalesImpact(expandableItem.item)
                ? formatNumber({
                    number: getSalesImpact(expandableItem.item) / 1000,
                    format: numberFormats.oneDecimalPlace,
                  })
                : '-'
            }}
          </td>

          <!-- Cost impact -->
          <td
            class="text-right border-right scenario-colour font-weight-bold"
            :class="[
              formatNumberColourClass(
                getCostImpact(expandableItem.item) ? getCostImpact(expandableItem.item) / 1000 : 0,
                true
              ),
            ]"
          >
            {{
              getCostImpact(expandableItem.item)
                ? formatNumber({
                    number: -getCostImpact(expandableItem.item) / 1000,
                    format: numberFormats.oneDecimalPlace,
                  })
                : '-'
            }}
          </td>
          <!-- End Scenario Results section -->

          <!-- Competitors section: selected competitors only -->
          <td
            class="text-right font-weight-bold"
            custom-title-max-size="medium"
            custom-title-position="right"
            :custom-title-msg="
              formatTooltipMessage({
                [$t('tooltip.competitorDescription')]:
                  expandableItem.item.competitor1.competitorProductDescription || '-',
                [$t('tooltip.competitorSize')]: formatNumber({
                  number: expandableItem.item.competitor1.contentValue,
                  format: numberFormats.priceFormat,
                  zeroAsDash: true,
                  nullAsDash: true,
                }),
                [$t('tooltip.competitorUnitOfMeasure')]:
                  expandableItem.item.competitor1.contentUnitOfMeasure || '-',
                [$t('tooltip.grossReferencePrice')]: formatNumber({
                  number: expandableItem.item.competitor1.grossReferenceCompetitorPrice,
                  format: numberFormats.priceFormat,
                  zeroAsDash: true,
                  nullAsDash: true,
                }),
              })
            "
          >
            <div class="right-aligned-text-inside-tooltip">
              {{ getCompetitorPrice(expandableItem.item.competitor1) }}
            </div>
          </td>
          <td
            class="text-right"
            :class="[formatNumberColourClass(expandableItem.item.competitor1.competitorIndex)]"
          >
            {{
              formatNumberEmptyCheck(
                expandableItem.item.competitor1,
                'competitorIndex',
                numberFormats.percent
              )
            }}
          </td>

          <td
            class="text-right font-weight-bold"
            custom-title-position="right"
            custom-title-max-size="medium"
            :custom-title-msg="
              formatTooltipMessage({
                [$t('tooltip.competitorDescription')]:
                  expandableItem.item.competitor2.competitorProductDescription || '-',
                [$t('tooltip.competitorSize')]: formatNumber({
                  number: expandableItem.item.competitor2.contentValue,
                  format: numberFormats.priceFormat,
                  zeroAsDash: true,
                  nullAsDash: true,
                }),
                [$t('tooltip.competitorUnitOfMeasure')]:
                  expandableItem.item.competitor2.contentUnitOfMeasure || '-',
                [$t('tooltip.grossReferencePrice')]: formatNumber({
                  number: expandableItem.item.competitor2.grossReferenceCompetitorPrice,
                  format: numberFormats.priceFormat,
                  zeroAsDash: true,
                  nullAsDash: true,
                }),
              })
            "
          >
            <div class="right-aligned-text-inside-tooltip">
              {{ getCompetitorPrice(expandableItem.item.competitor2) }}
            </div>
          </td>
          <td
            class="text-right border-right"
            :class="[formatNumberColourClass(expandableItem.item.competitor2.competitorIndex)]"
          >
            {{
              formatNumberEmptyCheck(
                expandableItem.item.competitor2,
                'competitorIndex',
                numberFormats.percent
              )
            }}
          </td>
          <!-- End Competitors section -->

          <!-- Tension chart -->
          <td class="text-xs-left chart">
            <tension-chart
              v-if="expandableItem.item.scenarioPrice"
              :options="tensionChartOptions"
              :scenario-price="expandableItem.item.scenarioPrice"
              :economic-reference-price="expandableItem.item.economicReferencePrice"
              :competitor-reference-price="expandableItem.item.competitorReferencePrice"
              :architecture-reference-price="expandableItem.item.architectureReferencePrice"
              :store-group-reference-price="expandableItem.item.storeGroupReferencePrice"
            />
          </td>
          <!-- End Tension chart -->
          <td class="alert-cell">
            <v-row class="ml-0">
              <v-col cols="6" class="pa-0">
                <product-alerts
                  v-if="canSeeAlerts && expandableItem.item.filteredAlerts.length"
                  :key="expandableItem.item._id"
                  :alerts="expandableItem.item.filteredAlerts"
                />
              </v-col>
              <v-col cols="6" class="pa-0">
                <tooltip
                  v-if="itemHasUpcomingPromotion(expandableItem.item)"
                  :value="getFreezePromotionTooltip(expandableItem.item)"
                  :position="'top-left'"
                >
                  <v-icon v-once> $promotion-pr </v-icon>
                </tooltip>
              </v-col>
            </v-row>
          </td>
        </tr>
      </template>
      <template v-slot:expanded-item="props">
        <td :colspan="headers.length - 1" class="expanded-product-view-cell">
          <expanded-product-view
            :key="`${props.item.productKey}::${props.item.toolStoreGroupKey}`"
            :product-key="String(props.item.productKey)"
            :pricing-group-id="props.item.hierarchy[pricingGroupLevel].id"
            :architecture-group-id="props.item.hierarchy[architectureGroupLevel].id"
            :architecture-sub-group-description="props.item.subGroupDescription"
            :scenario-key="props.item.scenarioKey"
            :tool-store-group-key="props.item.toolStoreGroupKey"
            :is-unit-manager-view="isUnitManagerView"
            @toggleFilteringByArchitectureGroup="onToggleFilteringByArchitectureGroup"
          />
        </td>
        <td class="alert-row-cell" />
      </template>
      <template slot="footer">
        <table class="product-table-footer">
          <tr>
            <td>
              <v-row justify="space-between">
                <v-col md="3" offset-md="9">
                  <v-pagination v-model="pagination.page" :length="pages" />
                </v-col>
              </v-row>
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
      <template v-slot:no-data>
        <table class="no-results">
          <tr>
            <td :colspan="headers.length - 1">
              {{ $t('product.noData') }}
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
      <template v-slot:no-results>
        <table class="no-results">
          <tr>
            <td :colspan="headers.length - 1">
              {{ $t('product.noResults', { search }) }}
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
      <template v-slot:loading>
        <table class="no-results">
          <tr>
            <td :colspan="headers.length - 1">
              {{ $t('general.loadingMessage') }}
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
      <template v-slot:progress>
        <table class="no-results">
          <tr>
            <td :colspan="headers.length - 1" class="progress-cell-grid-view">
              <v-progress-linear
                absolute
                :active="itemsNotReadyForDisplay"
                color="primary"
                height="0.2rem"
                :indeterminate="itemsNotReadyForDisplay"
                class="progress-bar-grid-view"
              />
            </td>
            <td class="alert-row-cell" />
          </tr>
        </table>
      </template>
    </v-data-table>
  </div>
</template>

<script>
import { ceil, debounce, find, get, isEmpty, isNaN, isNil, has, set, merge, round } from 'lodash';
import { mapState, mapActions, mapGetters } from 'vuex';
import moment from 'moment';
import customFilter from '../../../utils/filter-util';
import multiLevelSortProductGroups from '../../../utils/sort-architecture-groups-util';
import { getCompareProductsFunc } from '../../../utils/sort-store-groups-util';
import DataTypes from '@enums/data-types';
import { pricingGroupLevel, architectureGroupLevel } from '@enums/hierarchy';
import { yearMonthDayFormat } from '@enums/date-formats';
import numberFormats from '@enums/number-formats';
import getPriceFieldMixin from '../../../mixins/getPriceField';
import featureFlagsMixin from '../../../mixins/featureFlags';
import tooltipFormatterMixin from '../../../mixins/tooltipFormatter';
import {
  beforePromotionDays,
  useZones,
  useFirstFuturePrice,
  useStickersPromotion,
  useManualProductGoLiveDate,
  groupBySubGroupsAndArchitecture,
} from '@enums/feature-flags';
import { normWeight, uom } from '@enums/price-types';
import { isSameOrAfter } from '@sharedModules/data/utils/dates-utils';
import { ascending, descending } from '@enums/sort-direction';
import clientConfig from '@sharedModules/config/client';

export default {
  mixins: [getPriceFieldMixin, featureFlagsMixin, tooltipFormatterMixin],
  props: {
    isUnitManagerView: Boolean,

    headers: {
      type: Array,
      required: true,
    },

    items: {
      type: Array,
      required: true,
    },

    levelEntryKey: {
      type: String,
      required: true,
    },

    architectureGroupDescriptionToColorMap: {
      type: Object,
      default: () => {},
    },
    architectureSubGroupDescriptionToColorMap: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      search: '',
      expanded: [],
      pagination: {
        sortBy: ['productDescription'],
        descending: false,
        itemsPerPage: 50,
      },
      priceOverrides: {},
      priceOverrideDates: {},
      previousFieldValue: 0,
      updateErrors: {}, // key of the form 'item.id',
      calendarDisplays: null,
      calendarOpen: false,
      showToolStoreGroup: false,
      isSortByArchitectureGroupsEnabled: null,
      isSortByArchitectureSubGroupsEnabled: null,
      engineCanRun: false,
      engineBlockedMsg: '',
    };
  },

  computed: {
    ...mapState('gridView', [
      'loading',
      'calculatingScenarioResults',
      'selectedCompetitors',
      'activeTableFilters',
      'downloadingItems',
      'showRegularImpact',
    ]),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapState('filters', ['scenario', 'selectedPriceType']), // selectedPriceType used in mixin
    ...mapState('scenarioMetadata', ['scenarioMetadata']),
    ...mapState('clientConfig', ['i18nconfig', 'toggleLogic', 'storeGroupOrderConfig']),
    ...mapGetters('gridView', { totalRows: 'items' }),
    ...mapGetters('context', [
      'isPricingSpecialist',
      'isCategoryManager',
      'isWholesaleManager',
      'isWholesaleAnalyst',
      'isAdminLoggedIn',
    ]),

    pricingGroupName() {
      return get(this.items, [0, 'hierarchy', pricingGroupLevel, 'levelEntryDescription']);
    },

    itemsNotReadyForDisplay() {
      return this.loading || !this.items.length;
    },

    childPromotionEnabled() {
      return this.isFeatureFlagEnabled(useStickersPromotion);
    },

    isEditable() {
      return this.isAdminLoggedIn || (!this.isWholesaleManager && !this.isWholesaleAnalyst);
    },

    rowSummary() {
      return this.$t('tables.rowsSummary', {
        rows: Math.min(this.totalRows.length, this.items.length),
        totalRows: this.totalRows.length,
        numFilters: this.activeTableFilters.length,
      });
    },

    pages() {
      // Allows the pagination to update if there is a searched name (filtered items)
      // The search is controlled by the v-data-table and currently no nice way of doing this
      if (this.search.length > 0 && this.$refs.dataTable.$children) {
        return ceil(
          this.$refs.dataTable.$children[0].filteredItems.length / this.pagination.itemsPerPage
        );
      }
      return ceil(this.items.length / this.pagination.itemsPerPage);
    },
  },

  // FIXME: this watcher seems expensive
  watch: {
    items() {
      this.priceOverrides = this.items.reduce((obj, item) => {
        if (get(item, ['overridePrice', 'price']))
          Object.assign(obj, { [item._id]: item.overridePrice.price });
        return obj;
      }, {});

      this.priceOverrideDates = this.items.reduce((obj, item) => {
        if (get(item, ['overridePrice', 'expiryDate']))
          Object.assign(obj, { [item._id]: item.overridePrice.expiryDate });
        else Object.assign(obj, { [item._id]: '' });
        return obj;
      }, {});
    },
  },

  async created() {
    this.showToolStoreGroup = this.isFeatureFlagEnabled(useZones);
    this.clearItems();
    this.isSortByArchitectureGroupsEnabled =
      !this.isUnitManagerView && this.isFeatureFlagEnabled(groupBySubGroupsAndArchitecture);
    this.isSortByArchitectureSubGroupsEnabled =
      !this.isUnitManagerView && this.isFeatureFlagEnabled(groupBySubGroupsAndArchitecture);

    const { canEngineRun, missingProduct, invalidProduct } = await this.canEngineRun({
      pricingGroupId: this.levelEntryKey,
    });
    this.engineCanRun = canEngineRun;

    if (!this.engineCanRun) {
      // Format message to inform user
      const getProductError = p =>
        this.showToolStoreGroup
          ? `${p.article.productKeyDisplay} ${this.$t('pricing.inStoregroup')}: ${
              p.toolStoreGroupDescription
            }`
          : p.article.productKey;

      this.engineBlockedMsg = {
        [this.$t('pricing.error')]: this.$t('pricing.engineCannotRun', {
          pricingGroupName: this.pricingGroupName,
        }),
      };

      if (missingProduct)
        this.engineBlockedMsg[this.$t('pricing.missing')] = getProductError(missingProduct);
      if (invalidProduct)
        this.engineBlockedMsg[this.$t('pricing.invalid')] = getProductError(invalidProduct);
    }

    if (this.isUnitManagerView) {
      this.pagination.sortBy = ['scenarioSalesImpact'];
      this.pagination.descending = true;
      this.fetchItems({
        levelEntryKey: this.levelEntryKey,
        isUnitManagerView: this.isUnitManagerView,
      });
    }
    this.defaultSortingOptions = {
      sortBy: this.pagination.sortBy,
      descending: this.pagination.descending,
    };
    this.pricingGroupLevel = pricingGroupLevel;
    this.architectureGroupLevel = architectureGroupLevel;
    this.chartOptions = {
      chart: {
        width: 60,
        height: 23,
        spacingTop: 0,
        spacingRight: 0,
        spacingBottom: 0,
        spacingLeft: 0,
        plotBorderWidth: 0,
        margin: [0, 0, 0, 0],
      },
    };
    this.tensionChartOptions = {
      chart: {
        width: 60,
        height: 23,
        spacing: [5, 0, 5, 0],
        style: {
          margin: '0 auto',
        },
      },
      xAxis: {
        offset: -15,
      },
    };
    this.dataType = DataTypes;
    this.numberFormats = numberFormats;
    this.truncationLength = {
      article: 50,
      toolStoreGroup: 21,
    };
    this.today = moment().format(yearMonthDayFormat);
    this.canSeeAlerts = this.isPricingSpecialist || this.isCategoryManager;
    this.previousSearchValue = '';
    this.isUserSort = false;
  },

  methods: {
    ...mapActions('gridView', [
      'handleRecalculate',
      'clearItems',
      'updateOverridePrice',
      'fetchItems',
      'removeActiveTableFilter',
      'canEngineRun',
    ]),

    customFilter,
    isEmpty,
    get,

    downloadData() {
      this.$emit('downloadData');
    },

    intentionPriceTooltipValue(expandableItem) {
      if (this.displayIntentionPriceTooltip(expandableItem)) {
        return this.formatTooltipMessage({
          [this.$t('gridView.popupText.effectiveDate')]: this.formatLocalisedDate(
            expandableItem.item.intentionPrice.effectiveDate
          ),
        });
      }
      return '';
    },

    displayIntentionPriceTooltip(expandableItem) {
      const intentionPriceEffectiveDate = get(expandableItem.item, 'intentionPrice.effectiveDate');
      return (
        this.isFeatureFlagEnabled(useFirstFuturePrice) && !isEmpty(intentionPriceEffectiveDate)
      );
    },

    changeSort(header) {
      if (!header.sortable) {
        return;
      }
      this.isUserSort = true;
      if (this.pagination.sortBy[0] === header.valuePath) {
        this.pagination.descending = !this.pagination.descending;
      } else {
        this.pagination.sortBy = [header.valuePath];
        this.pagination.descending = false;
      }
    },

    itemOnPromotion(item) {
      const onPromotioin = item.isOnPromotion;
      if (this.childPromotionEnabled) {
        const childCurrentPromotions = get(item, 'childPromotions.currentPromotion');
        return onPromotioin || !isEmpty(childCurrentPromotions);
      }
      return onPromotioin;
    },

    itemHasUpcomingPromotion(item) {
      const hasUpcomingPromotion = item.hasUpcomingPromotion;
      if (this.childPromotionEnabled) {
        const childUpcomingPromotions = get(item, 'childPromotions.upcomingPromotion');
        return hasUpcomingPromotion || !isEmpty(childUpcomingPromotions);
      }
      return hasUpcomingPromotion;
    },

    scenarioPrice(item) {
      const scenarioPriceField = this.getPriceField('scenarioPrice');
      return item[scenarioPriceField];
    },

    hasOverride(item) {
      return has(this.priceOverrides, item._id) && !!item.scenarioPrice;
    },

    isSmartOverride(item) {
      return !!get(this.priceOverrideDates, item._id, null) && this.hasOverride(item);
    },

    lockPriceOnPromotion(smartOverrideEnd, promotions) {
      // N.B. Keep the logic consistent with lock_price_on_promotion in the engine
      if (isEmpty(promotions)) return false;
      const promotionEnd = moment.max(promotions.map(p => moment(get(p, 'expiryDate'))));
      return isSameOrAfter(promotionEnd, smartOverrideEnd);
    },

    calculate_before_promotion_period(upcomingPromotion, beforePromoDays) {
      // N.B. Keep the logic consistent with _calculate_before_promotion_period in the engine
      const upcomingPromotionStart = moment(upcomingPromotion.effectiveDate);
      const startDate = upcomingPromotionStart.clone().subtract(beforePromoDays, 'day');
      const endDate = upcomingPromotionStart.clone().subtract(1, 'day');
      return [startDate, endDate];
    },

    lockPriceBeforePromotion(smartOverrideEnd, upcomingPromotions, beforePromoDays) {
      // N.B. Keep the logic consistent with lock_price_before_promotion in the engine
      if (isEmpty(upcomingPromotions)) return false;
      const lockedPeriods = upcomingPromotions.map(p =>
        this.calculate_before_promotion_period(p, beforePromoDays)
      );
      const lockStart = moment.min(lockedPeriods.map(p => p[0]));
      const lockEnd = moment.max(lockedPeriods.map(p => p[1]));
      return isSameOrAfter(smartOverrideEnd, lockStart) && isSameOrAfter(lockEnd, smartOverrideEnd);
    },

    useLockedPrice(item) {
      // N.B. Keep the logic consistent with _should_use_locked_price in the engine
      // TODO PRICE-2253: Think about unifying this function across all repos/products
      if (!this.isSmartOverride(item)) return false;
      const smartOverrideEnd = moment(
        get(this.priceOverrideDates, item._id, 'expiryDate'),
        yearMonthDayFormat
      );
      let promotions = item.currentPromotion ? [item.currentPromotion] : [];
      let upcomingPromotions = item.upcomingPromotion ? [item.upcomingPromotion] : [];
      if (this.childPromotionEnabled) {
        const childPromotions = get(item, 'childPromotions.currentPromotion', {});
        const childUpcomingPromotions = get(item, 'childPromotions.upcomingPromotion', {});
        promotions = [...promotions, ...Object.values(childPromotions)];
        upcomingPromotions = [...upcomingPromotions, ...Object.values(childUpcomingPromotions)];
      }
      const days = this.toggleLogic[beforePromotionDays];
      const lockOnPromotion = this.lockPriceOnPromotion(smartOverrideEnd, promotions);
      const lockBeforePromotion = this.lockPriceBeforePromotion(
        smartOverrideEnd,
        upcomingPromotions,
        days
      );
      return lockOnPromotion || lockBeforePromotion;
    },

    useOverridePrice(item) {
      return !this.useLockedPrice(item) && this.hasOverride(item);
    },

    priceRelevantToPriceType(item, price) {
      if (this.selectedPriceType === uom) {
        return item.contentValue ? round(price / item.contentValue, 2) : price;
      }
      if (this.selectedPriceType === normWeight) {
        return item.normWeight ? round(price * item.normWeight, 2) : price;
      }
      return price;
    },

    getOverridePrice(item) {
      const price = this.useOverridePrice(item)
        ? this.priceRelevantToPriceType(item, this.priceOverrides[item._id])
        : this.scenarioPrice(item);
      return price;
    },

    async recalculate() {
      // Prevent user from recalculating if scenarioKey is not selected.
      const scenarioKey = get(this, this.scenario ? 'scenario' : 'items[0].scenarioKey');
      // Reset updateErrors when engine is recalculated
      this.updateErrors = {};
      await this.handleRecalculate({
        scenarioKey,
        workpackageId: this.selectedWorkpackage._id,
      });
    },

    multiLevelSortProductGroups,

    customSort(items, [primarySortField], [isDesc]) {
      const columnDataType = get(
        find(this.headers, { valuePath: primarySortField }),
        'datatype',
        DataTypes.number
      );
      const compareFunc = getCompareProductsFunc({
        sortByField: primarySortField,
        sortDirection: isDesc ? descending : ascending,
        dataType: columnDataType,
        storeGroupOrderConfig: this.storeGroupOrderConfig,
        compareByAbsoluteValues: primarySortField === 'scenarioSalesImpact',
        compareByToolStoreGroup: this.showToolStoreGroup,
      });

      items.sort((a, b) => {
        const sortFieldForSubGroups = this.isUserSort ? 'architectureSubGroup' : 'ContentValue';
        const sortFieldForGroups = this.isUserSort ? 'architectureGroup' : 'ContentValue';

        if (this.isSortByArchitectureSubGroupsEnabled) {
          const sortByArchitectureSubGroupsResult = this.multiLevelSortProductGroups(
            a,
            b,
            sortFieldForSubGroups
          );
          if (sortByArchitectureSubGroupsResult !== 0) return sortByArchitectureSubGroupsResult;
        } else if (this.isSortByArchitectureGroupsEnabled) {
          const sortByArchitectureGroupsResult = this.multiLevelSortProductGroups(
            a,
            b,
            sortFieldForGroups
          );
          if (sortByArchitectureGroupsResult !== 0) return sortByArchitectureGroupsResult;
        }

        return compareFunc(a, b);
      });
      return items;
    },

    preventExpand(event) {
      event.preventDefault();
      event.stopPropagation();
    },

    debounceSearchUpdate: debounce(function(value) {
      this.search = value;
    }, clientConfig.inputDebounceValue),

    getPreviousValue(item) {
      const promotion = this.itemOnPromotion(item) || this.itemHasUpcomingPromotion(item);
      if (this.hasOverride(item) && !promotion) {
        const referencePriceAtOverride = get(
          item,
          ['overridePrice', 'referencePriceAtOverride'],
          0
        );
        const optimizedPrice = get(item, 'optimizedPrice', 0);
        let price = referencePriceAtOverride !== 0 ? referencePriceAtOverride : optimizedPrice;
        if (!price || price === 0) price = this.getIntentionPrice(item);
        return this.priceRelevantToPriceType(item, price);
      }
      if (promotion) return this.getIntentionPrice(item);
      return round(this.scenarioPrice(item), 2);
    },

    scenarioPriceTooltip(item) {
      if (this.isSmartOverride(item))
        return this.$t('gridView.priceLockedUntil', {
          date: this.formatLocalisedDate(this.priceOverrideDates[item._id]),
        });
    },

    getOverrideFieldClass(item) {
      // invalid value entered
      if (has(this.updateErrors, item._id)) {
        return 'override-cell-error';
      }
      return 'override-base-class';
    },

    verifyFieldValue(item, value) {
      const valid = value !== 0 && !isNaN(value);
      if (valid) {
        // Remove this value from updateErrors
        this.$delete(this.updateErrors, item._id);
      }
      // Add this value to updateErrors
      else {
        if (value === 0) {
          this.$set(this.updateErrors, item._id, this.$t('validationErrors.required'));
          return !!valid;
        }

        this.$set(
          this.updateErrors,
          item._id,
          this.$t('validationErrors.incorrectDataType', {
            // this is a bit tricky, formatted number is a str, but user should input a valid number
            dataType: DataTypes.number,
          })
        );
        return !!valid;
      }
      return !!valid;
    },

    convertPriceToRegular(item, price) {
      if (this.selectedPriceType === uom) {
        return item.contentValue ? round(price * item.contentValue, 2) : price;
      }
      if (this.selectedPriceType === normWeight) {
        return item.normWeight ? round(price / item.normWeight, 2) : price;
      }
      return price;
    },

    changeOverridePrice(newValue, listItem) {
      const intentionPrice = this.getIntentionPrice(listItem);
      let numericPrice = this.formatStringToNumber(newValue);
      numericPrice = this.convertPriceToRegular(listItem, numericPrice);
      const usePromoPriceOnSmartOverride = this.useLockedPrice(listItem);
      const updatedOverridePrice = usePromoPriceOnSmartOverride ? intentionPrice : numericPrice;
      this.$set(this.priceOverrides, listItem._id, updatedOverridePrice);
      const isValid = this.verifyFieldValue(listItem, numericPrice);
      if (!isValid) return;
      const updatedOverridePriceObject = merge(get(listItem, 'overridePrice', {}), {
        price: numericPrice,
        overrideDate: moment().format(yearMonthDayFormat),
        overrideTimestamp: moment().unix(),
        referencePriceAtOverride:
          get(listItem, 'optimizedPrice') || get(listItem, 'scenarioPrice', null),
        isSmart: !!get(listItem, ['overridePrice', 'effectiveDate'], null),
      });
      set(listItem, 'overridePrice', updatedOverridePriceObject);
      const { workpackageId, scenarioKey, toolStoreGroupKey } = listItem;
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
        tool_store_group_key: toolStoreGroupKey,
      };
      this.updateOverridePrice({
        item: listItem,
        intentionPrice,
        alertParameters: params,
        usePromoPriceOnSmartOverride,
      });
    },

    debounceShowDatePicker: debounce(function(item) {
      if (this.calendarDisplays === 'doubleClick') this.calendarDisplays = null;
      else this.calendarDisplays = item._id;
    }, 300),

    handleRevertButtonClick(item) {
      const intentionPrice = this.getIntentionPrice(item);
      const referencePriceAtOverride = get(item, ['overridePrice', 'referencePriceAtOverride']);
      const updatedOverridePriceObject = merge(get(item, 'overridePrice', {}), {
        price: null,
        overrideDate: null,
        overrideTimestamp: null,
        referencePriceAtOverride: null,
      });
      set(item, 'overridePrice', updatedOverridePriceObject);
      this.$delete(this.priceOverrides, item._id);
      this.$delete(this.updateErrors, item._id);
      const { workpackageId, scenarioKey, toolStoreGroupKey } = item;
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
        tool_store_group_key: toolStoreGroupKey,
      };
      this.calendarDisplays = null;
      this.calendarOpen = false;
      this.updateOverridePrice({
        item,
        intentionPrice: intentionPrice || referencePriceAtOverride,
        alertParameters: params,
      });
    },

    selectScenario() {
      // fetch items with updated sceanario
      this.fetchItems({ levelEntryKey: this.levelEntryKey });
    },

    getLivePrice(item) {
      const field = this.getPriceField('livePrice');
      return this.formatNumber({
        number: item[field],
        format: numberFormats.priceFormat,
        nullAsDash: true,
      });
    },

    getLiveCost(item) {
      const field = this.getPriceField('liveCost');
      return item[field];
    },

    getNonPromoNetCost(item) {
      const field = this.getPriceField('nonPromoNetCost');
      return item[field];
    },

    getCostDelta(item) {
      const field = this.getPriceField('costDelta');
      return this.formatNumber({
        number: item[field],
        format: numberFormats.priceFormat,
        zeroAsDash: true,
        nullAsDash: true,
      });
    },

    getSalesImpact(item) {
      return get(item, this.showRegularImpact ? 'regularSalesImpact' : 'scenarioSalesImpact');
    },

    getCostImpact(item) {
      return get(item, this.showRegularImpact ? 'regularCostImpact' : 'scenarioCostImpact');
    },

    getFuturePrice(item) {
      const liveField = this.getPriceField('livePrice');
      const intentionField = this.getPriceField('intentionPrice');
      const intentionValue = get(item, intentionField);
      if (item[liveField] !== intentionValue) {
        return this.formatNumber({
          number: intentionValue,
          format: numberFormats.priceFormat,
          zeroAsDash: true,
          nullAsDash: true,
        });
      }
      return '—';
    },

    getCompetitorPrice(item) {
      const field = this.getPriceField('competitorPrice');
      return this.formatNumberEmptyCheck(item, field, numberFormats.priceFormat);
    },

    getScenarioPriceChange(item) {
      const field = this.getPriceField('scenarioPriceChange');
      return this.formatNumberEmptyCheck(item, field, numberFormats.priceFormat, true);
    },

    getScenarioPriceChangeRatio(item) {
      const field = this.getPriceField('scenarioPriceChangeRatio');
      return this.formatNumberEmptyCheck(item, field, numberFormats.percent, true);
    },

    valueIsNullOrNan(value) {
      return isNil(value) || isNaN(value);
    },

    formatEngineInput(engineInput) {
      return this.valueIsNullOrNan(engineInput) ? null : engineInput / 100;
    },

    getClassForOverride(item) {
      let baseClass = 'ma-0';
      if (this.isSmartOverride(item)) baseClass += ' price-locked';
      return baseClass;
    },

    getFreezePromotionTooltip(item) {
      let date = get(item.upcomingPromotion, 'effectiveDate', '9999-12-31');
      if (this.childPromotionEnabled) {
        const childPromotionStarts = Object.values(
          get(item, 'childPromotions.upcomingPromotion', {})
        ).map(child => child.effectiveDate);
        date = moment
          .min([...childPromotionStarts, date].map(x => moment(x)))
          .format(yearMonthDayFormat);
      }
      const formattedDate = this.formatLocalisedDate(date);
      return `${this.$t('gridView.tooltips.hasUpcomingPromotion')} ${formattedDate}`;
    },

    getIntentionPrice(item) {
      const productIntentionPrice = get(item, 'intentionPrice.price', null);
      const productLivePrice = get(item, 'livePrice');
      if (this.toggleLogic[useFirstFuturePrice]) {
        const inferredGoLiveDate = moment(get(item, 'inferredGoLiveDate'), null);
        const futurePriceEffectiveDate = moment(get(item, 'intentionPrice.effectiveDate'), null);
        const isValidFuturePrice = isSameOrAfter(inferredGoLiveDate, futurePriceEffectiveDate);
        return productIntentionPrice && isValidFuturePrice
          ? productIntentionPrice
          : productLivePrice;
      }
      if (this.toggleLogic[useManualProductGoLiveDate]) {
        const inferredGoLivePrice = get(item, 'inferredGoLivePrice');
        return inferredGoLivePrice;
      }
      return productIntentionPrice;
    },

    cancelScenarioPrice(item) {
      this.calendarDisplays = 'doubleClick';
      const intentionPrice = this.getIntentionPrice(item);
      // don't override the price if intention price is 0 or null
      if (!intentionPrice) return;
      this.$set(this.priceOverrides, item._id, intentionPrice);
      const updatedOverridePriceObject = merge(get(item, 'overridePrice', {}), {
        price: intentionPrice,
        overrideDate: moment().format(yearMonthDayFormat),
        overrideTimestamp: moment().unix(),
        referencePriceAtOverride: get(item, 'optimizedPrice', get(item, 'scenarioPrice', null)),
        isSmart: !!get(item, ['overridePrice', 'effectiveDate'], null),
      });
      set(item, 'overridePrice', updatedOverridePriceObject);
      const { workpackageId, scenarioKey, toolStoreGroupKey } = item;
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
        tool_store_group_key: toolStoreGroupKey,
      };
      this.updateOverridePrice({ item, intentionPrice, alertParameters: params });
    },

    shouldDisplayCalendar(item) {
      return this.calendarDisplays === item._id;
    },

    debounceRemoveIcon: debounce(function(item) {
      if (this.calendarOpen || this.calendarDisplays !== item._id) return;
      this.calendarDisplays = null;
    }, 150), // the delay must be long enough to open the calendar

    debounceCloseDatePicker: debounce(function() {
      this.calendarDisplays = null;
      this.calendarOpen = false;
    }, 1000),

    selectOverrideDates(item, event) {
      const usePromoPriceOnSmartOverride = this.useLockedPrice(item);
      const intentionPrice = this.getIntentionPrice(item);
      const updatedOverridePrice = usePromoPriceOnSmartOverride
        ? intentionPrice
        : get(item, 'overridePrice.price');
      this.$set(this.priceOverrides, item._id, updatedOverridePrice);
      const updatedOverridePriceObject = merge(get(item, 'overridePrice', {}), {
        effectiveDate: moment().format(yearMonthDayFormat),
        effectiveTimestamp: moment().unix(),
        expiryDate: event,
        expiryTimestamp: moment(event, yearMonthDayFormat).unix(),
        isSmart: true,
        price: updatedOverridePrice,
      });
      set(item, 'overridePrice', updatedOverridePriceObject);
      const proposalPrice = usePromoPriceOnSmartOverride ? intentionPrice : item.scenarioPrice;
      if (!get(item, 'overridePrice.price')) {
        // Set the proposal price as the override
        this.$set(this.priceOverrides, item._id, proposalPrice);
        const updatedOverridePriceObjectWithScenarioPrice = merge(get(item, 'overridePrice', {}), {
          price: proposalPrice,
          overrideDate: moment().format(yearMonthDayFormat),
          overrideTimestamp: moment().unix(),
          referencePriceAtOverride: get(item, 'optimizedPrice', get(item, 'scenarioPrice', null)),
        });
        set(item, 'overridePrice', updatedOverridePriceObjectWithScenarioPrice);
      }
      const { workpackageId, scenarioKey, toolStoreGroupKey } = item;
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
        tool_store_group_key: toolStoreGroupKey,
      };
      this.updateOverridePrice({
        item,
        intentionPrice,
        alertParameters: params,
        usePromoPriceOnSmartOverride,
      });

      this.debounceCloseDatePicker();
    },

    closeCalendar(event) {
      if (!event) {
        this.calendarDisplays = null;
        this.calendarOpen = false;
        return;
      }
      this.calendarOpen = true;
    },

    removePriceLock(item) {
      const updatedOverridePriceObject = merge(get(item, 'overridePrice', {}), {
        price: null,
        effectiveDate: null,
        effectiveTimestamp: null,
        expiryDate: null,
        expiryTimestamp: null,
        referencePriceAtOverride: null,
        overrideDate: null,
        overrideTimestamp: null,
      });
      set(item, 'overridePrice', updatedOverridePriceObject);
      this.$delete(this.priceOverrideDates, item._id);
      this.$delete(this.priceOverrides, item._id);
      const { workpackageId, scenarioKey, toolStoreGroupKey } = item;
      const params = {
        scenario_key: scenarioKey,
        workpackage_id: workpackageId,
        tool_store_group_key: toolStoreGroupKey,
      };
      this.calendarDisplays = null;
      this.calendarOpen = false;
      const intentionPrice = this.getIntentionPrice(item);
      this.updateOverridePrice({ item, intentionPrice, alertParameters: params });
    },

    onToggleFilteringByArchitectureGroup(eventData) {
      this.$emit('toggleFilteringByArchitectureGroup', eventData);
      if (this.search === this.previousSearchValue) {
        this.previousSearchValue = '';
        return;
      }
      if (eventData.isFilterEnabled) {
        this.search = this.previousSearchValue;
        this.previousSearchValue = '';
        return;
      }
      this.previousSearchValue = this.search;
      this.search = '';
    },

    toggleSortingByArchitectureGroups() {
      if (!this.isSortByArchitectureGroupsEnabled) {
        this.isSortByArchitectureSubGroupsEnabled = false;
      }

      if (!this.isSortByArchitectureGroupsEnabled && !this.isSortByArchitectureSubGroupsEnabled) {
        this.isUserSort = true;
        return;
      }
      this.pagination = {
        ...this.pagination,
        ...this.defaultSortingOptions,
      };
      this.isUserSort = false;
    },
    toggleSortingByArchitectureSubGroups() {
      if (this.isSortByArchitectureSubGroupsEnabled) {
        this.isSortByArchitectureGroupsEnabled = true;
      }
      this.toggleSortingByArchitectureGroups();
    },
    getSubGroupTitleMsg(expandableItem) {
      if (this.isSortByArchitectureGroupsEnabled) {
        return expandableItem.item.subGroupDescription || this.$t('gridView.noSubGropsExist');
      }
      return '';
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

$cell-height: 2.5rem;
$cell-padding: 0 0.4rem;

$architecture-column-width: 9px;

$architecture-group-color-1: #8ba4b7;
$architecture-group-color-2: #32739e;

$architecture-sub-group-colors: #d3dee7, #5882a3; // Define your color palette
$n: length($architecture-sub-group-colors); // Get the number of colors in your palette

.grid-view {
  $main-header-offset: 10.9rem;
  $main-header-height: 7.5rem;
  $sub-header-offset: $main-header-offset + $main-header-height;

  th {
    background: white;
    position: sticky;
    z-index: 7;
    font-size: 1rem;

    &.scenario-colour {
      background-color: $grid-view-scenario-blue;
    }
  }

  .main-header th {
    height: $main-header-height;
    top: $main-header-offset;
    font-weight: 600;
    color: #000;
    font-size: 1rem;
    border-bottom: none;
    text-align: center;
  }

  .sub-header th {
    top: $sub-header-offset;
    border-bottom: 0.1rem solid $pricing-grey-dark;
  }

  tr,
  th {
    padding: $cell-padding;
    &.alert-padding {
      background-color: $pricing-background !important;
      border-bottom: none !important;
      width: $grid-view-alert-column-width !important;
    }
  }
  .alert-cell {
    background-color: $pricing-background !important;
    border-bottom: none !important;
    min-width: $grid-view-alert-column-width !important;
  }
  .alert-row-cell {
    background-color: $pricing-background !important;
    width: $grid-view-alert-column-width;
    height: unset !important;
  }

  .architecture-group-cell-header,
  .architecture-group-cell,
  .architecture-sub-group-cell {
    padding: 0;
    width: $architecture-column-width;
  }

  &.colored-architecture-groups {
    .architecture-group-cell.color-2 {
      background-color: $architecture-group-color-2;
    }

    .architecture-group-cell.color-1 {
      background-color: $architecture-group-color-1;
    }
  }

  &.colored-architecture-sub-groups {
    @for $i from 1 through $n {
      .architecture-sub-group-cell.color-#{$i} {
        background-color: nth($architecture-sub-group-colors, $i);
      }
    }
  }

  tbody {
    tr {
      background-color: $pricing-white;
      cursor: pointer;

      &:hover {
        background-color: $grid-view-hover-color !important;
      }

      &.on-promo-row-color {
        background-color: $grid-view-row-promo-color;
        &:hover {
          background-color: $grid-view-row-promo-dense-hover-color;
        }
      }

      &:nth-child(even) {
        background-color: $grid-view-dense-row-color;

        &.on-promo-row-color {
          background-color: $grid-view-row-promo-dense-color;
          &:hover {
            background-color: $grid-view-row-promo-dense-hover-color;
          }
        }
      }

      &:not(.on-promo-row-color):not(:hover) {
        td.scenario-colour {
          background-color: $grid-view-scenario-blue;
        }
      }

      &:nth-child(even):not(:hover):not(.on-promo-row-color) {
        td.scenario-colour {
          background-color: $grid-view-scenario-dense-blue;
        }
      }
    }

    td {
      padding: 0 0.4rem !important; // Vuetify 2.7.2 chaged styles overrides
      height: unset;
      font-size: 1.2rem;
      border-bottom: unset !important;

      &.expanded-product-view-cell {
        padding: 0;
      }

      &.chart {
        padding: 0 !important; // Vuetify 2.7.2 chaged styles overrides
      }

      &.price-override {
        $right-padding: 0.5rem;
        padding: 0 $right-padding 0 0 !important; // Vuetify 2.7.2 chaged styles overrides
        width: 5rem;
        position: relative;

        .price-locked {
          border-right: $right-padding solid $pricing-primary;
          margin-right: -$right-padding !important;
          height: $cell-height;

          .v-text-field {
            margin-top: 0 !important;
            margin-bottom: -0.2rem !important;
          }
        }

        .v-text-field__details {
          position: absolute;
          right: 0;
        }

        .v-input {
          padding-top: 0;
          height: 2.5rem;
          font-size: 1.2rem;
          background-color: transparent;

          .v-text-field__slot input {
            padding: 0.8rem 0 0.8rem 0.4rem !important;
          }

          .v-input__slot::before {
            border-color: transparent;
          }
        }

        .revert-button {
          height: $cell-height;
          width: 1.3rem;
          background-color: $pricing-primary;
          padding: 0.4rem 0.2rem 0 0;
        }
      }
    }
  }
}

.grid-view,
.v-card.v-sheet {
  width: 100%;
}

.grid-view-container {
  position: relative;
  z-index: 7;
  background: $pricing-white;

  .summary-text {
    font-size: 1rem;
  }

  .product-table-footer {
    position: sticky;
    background-color: $pricing-white;
    bottom: 4.5rem;

    td:not(:last-child) {
      border-bottom: 0.1rem solid $pricing-grey-dark;
      border-top: 0.1rem solid $pricing-grey-dark;
    }
  }
}

.theme--light.v-data-table .v-data-table-header th {
  color: rgba(0, 0, 0, 0.38);
  vertical-align: bottom;
}

.theme--light.v-btn.v-btn--disabled .v-icon,
.theme--light.v-btn.v-btn--disabled {
  color: white !important;
}

.btn-refresh {
  min-width: unset !important;
  padding: unset !important;
  height: 3rem !important;
  width: 3rem;
}

.chart {
  background: $pricing-white;
}

.no-results {
  height: 100%;
}

.norm-weight-toggle {
  padding: 1.4rem 1rem 0rem 1rem;
}

.sort-by-architecture-groups-switcher::v-deep {
  $scale: 0.875;
  $expected-font-size: 1.25rem;
  $font-size: calc($expected-font-size / $scale);
  transform: scale($scale);
  transform-origin: right;

  .v-label {
    font-size: $font-size;
    font-weight: 300;
  }
}
</style>

<style lang="scss">
.grid-view tbody tr.v-data-table__empty-wrapper {
  > td {
    padding: 0 !important;
  }
}

.progress-cell-grid-view {
  height: 0.4rem !important;
}

.progress-bar-grid-view {
  // keep subtrahend in sync with grid-view-alert-column-width
  width: calc(100% - 5.4rem);
}

.override-calendar-button {
  left: 1.6rem !important;
  margin-top: 0.3rem !important;
  padding: 0 !important;
  min-width: unset !important;
  > .v-btn__content {
    padding: 0 !important;
  }
}

.override-container {
  position: absolute !important;
  right: -0.5rem;
}

.right-aligned-text-inside-tooltip {
  margin-left: auto;
}

.active .theme--light.v-icon:focus::after {
  opacity: 0;
}

.search-box {
  .v-label {
    font-size: 1.2rem;
  }
  input {
    padding: 0;
  }
}
</style>
