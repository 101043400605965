var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      attrs: {
        "custom-title-msg": _vm.tooltipMessage,
        "custom-title-position": "top-left",
      },
    },
    [
      _vm.isNumberOfReleasesIcon
        ? _c("span", { staticClass: "fa-stack" }, [
            _c("span", {
              staticClass:
                "fa fa-square-o fa-stack-2x success--text square-icon",
            }),
            _vm._v(" "),
            _c(
              "span",
              { staticClass: "fa-stack-1x success--text square-text" },
              [
                _c("strong", [
                  _vm._v(
                    "\n        " + _vm._s(_vm.formattedNumber) + "\n      "
                  ),
                ]),
              ]
            ),
          ])
        : _vm.isNumberOfPriceChanges
        ? _c("div", { staticClass: "pill-shape" }, [
            _c("strong", { staticClass: "pill-text" }, [
              _vm._v(_vm._s(_vm.formattedNumber)),
            ]),
          ])
        : _c("span", { staticClass: "fa-stack" }, [
            _c("span", {
              staticClass: "fa fa-circle fa-stack-2x circle-icon",
              style: { color: _vm.iconColour },
            }),
            _vm._v(" "),
            _c("strong", { staticClass: "fa-stack-1x icon-text" }, [
              _vm._v("\n      " + _vm._s(_vm.formattedNumber) + "\n    "),
            ]),
          ]),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }