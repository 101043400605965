<script>
import { truncateString } from '../utils/truncate-string-util';

export default {
  functional: true,
  props: {
    text: {
      type: String,
      default: '',
    },
    truncationLength: {
      type: Number,
      default: 100,
    },
    tooltipPosition: {
      type: String,
      default: 'top-right',
    },
    ignoreRegistry: {
      type: Boolean,
      default: false,
    },
  },
  render(createElement, context) {
    const { text, truncationLength, ignoreRegistry, tooltipPosition } = context.props;
    const { wasTruncated, truncatedValue } = truncateString({
      text,
      truncationLength,
      ignoreRegistry,
    });
    if (!wasTruncated) return createElement('span', context.data, text);
    return createElement(
      'span',
      {
        ...context.data,
        attrs: {
          'custom-title-msg': text,
          'custom-title-position': tooltipPosition,
        },
      },
      truncatedValue
    );
  },
};
</script>

<style scoped></style>
