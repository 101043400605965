import priceTypes from '@enums/price-types';

const mixin = {
  methods: {
    getPriceField(priceName) {
      // Based on the selectedPriceType stored in vuex, return the field to be used for prices
      // Only needed by a few components, so not loaded globally
      // priceName: options are `livePrice`, `intentionPrice`, `scenarioPrice`, `competitorPrice`,
      // `scenarioPriceChange`, `scenarioPriceChangeRatio`
      if (this.selectedPriceType === priceTypes.uom) return `${priceName}PerContentUnit`;
      if (this.selectedPriceType === priceTypes.normWeight) return `${priceName}PerNormWeight`;
      if (priceName === 'intentionPrice') return 'intentionPrice.price';
      return priceName;
    },
  },
};

export default mixin;
