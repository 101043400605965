<template>
  <!-- having some difficulty using rems due to translations below so height is in pixels -->
  <svg ref="graph" width="100%" :height="height" />
</template>

<script>
import * as d3 from 'd3';
import { mapState } from 'vuex';
import {
  flatten,
  forEach,
  fromPairs,
  groupBy,
  isEqual,
  map,
  max,
  min,
  range,
  truncate,
  uniq,
  uniqWith,
  zip,
} from 'lodash';
import numberFormats from '@enums/number-formats';

export default {
  props: {
    height: {
      type: Number,
      default: () => 500,
    },
    architectureProducts: {
      type: Array,
      default: () => [],
    },
  },
  data() {
    return {
      graphMargin: 50,
      chartHeight: 400,
      graphDrawn: false,
    };
  },

  computed: {
    ...mapState('architectureDrivers', ['architectureDrivers']),

    cumulativeImpactFactors() {
      return map(this.architectureProducts, prod => [
        ...prod.productImpact.cumulativeImpactFactors,
        prod.productImpact.impactFactorFinal,
      ]).filter(fac => !!fac);
    },

    /**
     * architectureProducts: [(p1 {productImpact.cumulativeImpactFactors: [1.5, 2.3]}, (p2 {productImpact.cumulativeImpactFactors: [1.5, 2.9]})]
     * return: {0: {1.5: [p1,p2,p3]}, 1: {2.3: [p1], 2.9: [p2,p3]}}
     * first level key is position on x axis, second level key is cumulativeImpactFactors.
     */
    architectureProductsGroupedByImpactFactor() {
      const numLevels = this.impactsByAD.length - 1; // basically num archdrivers +1 (to account for initial all products level)
      const groupedArr = range(numLevels).map(i => {
        return [
          i,
          groupBy(this.architectureProducts, p => p.productImpact.cumulativeImpactFactors[i]),
        ];
      });
      // add data for final level (impactFactorFinal: impact ajusted by manully added offset)
      groupedArr.push([
        numLevels,
        groupBy(this.architectureProducts, p => p.productImpact.impactFactorFinal),
      ]);
      return fromPairs(groupedArr);
    },

    distinctPaths() {
      return uniqWith(this.cumulativeImpactFactors, isEqual);
    },

    /**
     * The cumulativeImpactFactors is an array of impact factors for each products, M x N where
     * M is the number of products and N is the number of arch drivers. We want to transpose this
     * array s so we get an array of all distinct values for each arch driver (N x M).
     */
    impactsByAD() {
      // we only want to plot one node per arch driver x distinct impact factor
      return map(zip(...this.cumulativeImpactFactors), uniq);
    },

    // makes it easier to search through all the distinct impact factor values
    flatImpactFactors() {
      return flatten(this.impactsByAD);
    },

    smallestImpactFactor() {
      return min(this.flatImpactFactors);
    },

    largestImpactFactor() {
      return max(this.flatImpactFactors);
    },

    // what to multiply factors by to figure out their Y coord
    plotMultiplier() {
      return this.chartHeight / this.largestImpactFactor;
    },

    // pad the graph with 20% of the smallest impact factor
    graphPadding() {
      return this.smallestImpactFactor * 0.5;
    },
  },

  watch: {
    architectureDrivers: {
      deep: true,
      handler() {
        setTimeout(this.drawGraph, 0);
      },
    },
  },

  events: {
    // when the user switches from the list tab to this one
    onGraphTabActive() {
      setTimeout(this.drawGraph, 0);
    },

    // when the user switches to the list tab ensure tooltips are removed
    onListTabActive() {
      this.removeTooltip();
    },

    // when the user opens the expansion panel
    onArchitectureRulesPanelOpened() {
      setTimeout(this.drawGraph, 0);
    },

    onRedrawArchitectureDriversGraph() {
      this.removeTooltip();
      setTimeout(() => {
        this.drawGraph();
      }, 0);
    },
  },

  mounted() {
    setTimeout(this.drawGraph, 0);
  },

  beforeDestroy() {
    // need to remove tooltip from document body when component is no longer on screen, otherwise it persists across pages.
    // the tooltip needs to be in the document body for positioning.
    this.removeTooltip();
  },

  methods: {
    removeTooltip() {
      d3.select(
        'body div.fixed-footer-wrapper div.v-application #architectureGraphToolTip'
      ).remove();
    },

    getYCoord(impactFactor) {
      return (
        this.chartHeight -
        (impactFactor - this.graphPadding) * this.plotMultiplier +
        this.graphMargin
      );
    },

    drawGraph() {
      // remove the previously drawn elements
      d3.select('svg')
        .selectAll('*')
        .remove();

      const dataReady = !!this.impactsByAD.length && this.$refs.graph.clientWidth;
      if (!dataReady) return;

      // this.$el is the root element of the component
      const svg = d3.select(this.$el);

      // Create the scale
      const domain = [
        this.$t('settings.setArchitectureRules.architectureDriversGraph.allProducts'),
        ...this.architectureDrivers.map(ad => ad.displayDescription),
        this.$t('settings.setArchitectureRules.architectureDriversGraph.final'),
      ];
      const x = d3
        .scalePoint()
        // this is what is written on the axis
        .domain(domain)
        // where the axis is placed: from 50px to 150px from the RHS (leaving enough room for labels)
        .range([this.graphMargin, this.$refs.graph.clientWidth - 150]);

      // Draw the axis
      svg
        .append('g')
        // This controls the vertical position of the Axis
        // push it down 50px from the top
        .attr('transform', 'translate(0, 50)')
        // make the ticks the height of the graph - 50px padding top/bottom
        .call(d3.axisBottom(x).tickSize(400))
        // reove the horizontal line you would usually get with an x-axis
        .call(g => g.select('.domain').remove())
        // make the ticks lighter
        .call(g => g.selectAll('.tick line').attr('stroke-opacity', 0.2))
        .call(g => g.selectAll('text').attr('class', 'attr-labels'));

      // draw the edges of the graph
      const edgeGroup = svg.append('g').attr('class', 'edge-group');
      forEach(this.distinctPaths, path => {
        const lineCoords = [];
        // we want to style the edges so that they are not just straight lines
        // this loop basically adds invisible midpoints between every two joined nodes
        forEach(path, (factor, ix) => {
          lineCoords.push([x(domain[ix]), this.getYCoord(factor)]);
          if (!path[ix + 1]) return; // don't add additional 'midway' point for last point
          // in some cases domain.length < paths.length, which causes this to return NaN.
          // attr.js:17 Error: <path> attribute d: Expected number, "…25L510,182.8125LNaN,182.8125LNaN…".
          // Could be an issue with products missing the attributes reference in the architecture driver.
          // see https://ahtdao.atlassian.net/browse/AHTDPR-980
          // May be resolved by https://ahtdao.atlassian.net/browse/AHTDPR-774
          // by ensuring that linear architecture drivers are only applied to numeric attributes.
          const midwayX = x(domain[ix]) + (x(domain[ix + 1]) - x(domain[ix])) / 2;
          // midway point has same Y coord as next point
          lineCoords.push([midwayX, this.getYCoord(path[ix + 1])]);
        });
        const line = d3.line();
        edgeGroup
          .append('path')
          .attr('d', line(lineCoords))
          .attr('fill', 'none')
          .attr('stroke', '#029FE2');
      });

      const circleCoords = [];
      forEach(this.impactsByAD, (impacts, ix) => {
        circleCoords.push([]);
        impacts.forEach(impact => {
          const products = this.architectureProductsGroupedByImpactFactor[ix][impact];
          const cx = x(domain[ix]);
          const cy = this.getYCoord(impact);
          circleCoords[ix].push({ cx, cy });
          const circleGroup = svg.append('g').attr('class', 'circle-group');

          // add two circles to create the outline effect
          circleGroup
            .append('circle')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('class', 'outer');

          circleGroup
            .append('circle')
            .attr('cx', cx)
            .attr('cy', cy)
            .attr('class', 'inner');

          // makes the inner circle white and the outer one blue to give the effect of a blue ring
          const resetCircles = () => {
            circleGroup
              .select('.inner')
              .attr('r', '0.6rem')
              .attr('fill', 'white');
            circleGroup
              .select('.outer')
              .attr('r', '0.8rem')
              .attr('fill', '#029FE2');
          };

          resetCircles();

          circleGroup
            .on('mouseover', function() {
              // can't set svg radius using css :hover so need to use javascript
              d3.select(this)
                .select('.outer')
                .attr('r', '1.2rem')
                .attr('fill', '#85CEEB');
              d3.select(this)
                .select('.inner')
                .attr('r', '0.8rem')
                .attr('fill', '#029FE2');
            })
            .on('mouseout', resetCircles)
            .on('click', () => {
              // display one tooltip at a time. Remove old before creating new.
              this.removeTooltip();

              // need to put tooltip in document body because the coordinates emitted by d3 are relative to the entire document, not the svg.
              const tooltip = d3
                .select('body div.fixed-footer-wrapper div.v-application')
                .append('div')
                .attr('class', 'tooltip')
                .attr('id', 'architectureGraphToolTip')
                .style('left', `${d3.event.pageX - 210}px`) // slightly larger than tooltip width, so it's to the left of the node
                .style('top', `${d3.event.pageY + 5}px`);

              const impactFactor =
                products[0].productImpact.cumulativeImpactFactors[ix] ||
                products[0].productImpact.impactFactorFinal;

              const formattedImpactFactor = this.formatNumber({
                number: impactFactor,
                format: numberFormats.decimal,
              });

              // split tooltip into title and body for sticky header.
              // also resolves issue with scrollbar covering close button.
              const title = tooltip.append('div').attr('id', 'architectureGraphToolTipTitle');
              const titleLabel = this.$t(
                'settings.setArchitectureRules.architectureDriversRules.impactFactor'
              );
              title.append('strong').text(`${titleLabel}: ${formattedImpactFactor}`);
              title
                .append('button')
                .attr('id', 'closeArchitectureGraphToolTip')
                .style('float', 'right')
                .append('i')
                .attr('class', 'fa fa-times-circle');

              d3.select('#closeArchitectureGraphToolTip').on('click', this.removeTooltip);

              // tooltip scrollable body
              tooltip
                .append('div')
                .attr('id', 'architectureGraphToolTipBody')
                .selectAll('div.product')
                .data(products)
                .enter()
                .append('div')
                .attr('class', 'product')
                .text(function(d) {
                  const maxProductDescriptionLength = 40;
                  const { productSizeType, productDescription } = d.article;
                  const length = maxProductDescriptionLength - productSizeType.length;
                  const truncatedDescription = truncate(productDescription, { length });
                  return `${truncatedDescription} / ${productSizeType}`;
                });
            });
        });
      });
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';
.edge-group {
  path {
    stroke-width: 2px;

    &:hover {
      stroke-width: 3px !important;
    }
  }

  &:hover {
    path {
      stroke-width: 1px;
    }
  }
}
.tooltip {
  z-index: 2;
  min-width: 20rem;
  position: absolute;
  text-align: left;
  padding: 1rem;
  font-size: 1.2rem;
  border: 0.1rem solid $pricing-light-blue;
  border-left: 0.2rem solid $pricing-light-blue;
  box-shadow: 0.5rem 0.5rem 1rem $pricing-grey;
  background-color: $pricing-white;
  #architectureGraphToolTipBody {
    overflow: auto;
    max-height: 12rem;
  }
}
</style>
