<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        small
        color="primary"
        class="ml-2"
        :loading="downloadingLinePricing"
        depressed
        v-on="on"
      >
        {{ $t('actions.downloadLinePricing.main') }}
      </v-btn>
    </template>

    <v-list>
      <v-list-item
        v-if="isPricingManager || isPricingSpecialist"
        @click="downloadLinePricingHandler({ exportAG: false })"
      >
        <v-list-item-title>
          {{ $t('actions.downloadLinePricing.workpackage') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="downloadLinePricingHandler({ exportAG: true })">
        <v-list-item-title>
          {{ $t('actions.downloadLinePricing.ag') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { useZones } from '@enums/feature-flags';
import featureFlagsMixin from '../mixins/featureFlags';

export default {
  mixins: [featureFlagsMixin],
  computed: {
    ...mapState('architectureGroup', ['downloadingLinePricing']),
    ...mapState('filters', ['architectureGroup']),
    ...mapGetters('context', ['isPricingManager', 'isPricingSpecialist']),
  },
  methods: {
    ...mapActions('architectureGroup', ['downloadLinePricing']),
    downloadLinePricingHandler({ exportAG }) {
      const toolStoreGroupTranslation = this.isFeatureFlagEnabled(useZones)
        ? {
            toolStoreGroupDescription: this.$t('pricing.toolStoreGroup'),
          }
        : {};
      const translationMap = {
        productKeyDisplay: this.$t('pricing.productKey'),
        productName: this.$t('pricing.productDescription'),
        ...toolStoreGroupTranslation,
        category: this.$t('pricing.category'),
        pricingGroup: this.$t('pricing.pricingGroup'),
        architectureGroup: this.$t('pricing.architectureGroup'),
        linePricedGroupDescription: this.$t('settings.architecture.linePricingGroupHeader'),
        linePriceFactor: this.$t('settings.architecture.LinePriceFactorHeader'),
      };
      const params = exportAG
        ? { translationMap, architectureGroupId: this.architectureGroup }
        : { translationMap };
      this.downloadLinePricing(params);
    },
  },
};
</script>
