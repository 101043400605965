'use strict';

const _ = require('lodash');

const getAttributeMetadata = (attributeMetadata, additionalContentEnhancer, toolStoreGroupConfig) => {
  return additionalContentEnhancer(attributeMetadata, toolStoreGroupConfig);
};

const removeToolStoreGroup = (attributeMetadata, toolStoreGroupConfig) => {
  return _.filter(
    attributeMetadata,
    am => am.attributeDescription !== _.get(toolStoreGroupConfig, 'name', 'Tool Store Group')
  );
};

module.exports = {
  getAttributeMetadata,
  removeToolStoreGroup,
};
