import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VRow,
        { staticClass: "line-pricing-group-container" },
        [
          _c(
            VCol,
            { staticClass: "line-pricing-group-header pr-0 pb-0 pt-0" },
            [
              _c("span", [
                _vm._v(
                  _vm._s(_vm.$t("settings.architecture.linePricingGroupHeader"))
                ),
              ]),
            ]
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "10" } },
            [
              _c(
                VExpansionPanels,
                {
                  attrs: { value: _vm.value },
                  on: { change: _vm.onSelectActiveLinePricingGroup },
                },
                _vm._l(
                  _vm.linePricingGroups,
                  function (linePricingGroup, index) {
                    return _c("line-pricing-group", {
                      key: index,
                      attrs: {
                        "line-pricing-group": linePricingGroup,
                        disabled: _vm.disabled,
                      },
                      on: {
                        deleteLinePricingGroup: _vm.onDeleteLinePricingGroup,
                        deleteLinePricedProduct: _vm.onDeleteLinePricedProduct,
                      },
                    })
                  }
                ),
                1
              ),
              _vm._v(" "),
              _c(
                VBtn,
                {
                  staticClass: "add-btn",
                  attrs: {
                    color: "primary",
                    disabled: _vm.disabled,
                    small: "",
                    text: "",
                    icon: "",
                    depressed: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addNewLinePricingGroup()
                    },
                  },
                },
                [
                  _c(VIcon, { attrs: { disabled: _vm.disabled } }, [
                    _vm._v("add_box"),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }