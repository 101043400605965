import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VChip } from 'vuetify/lib/components/VChip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("feature-toggle", { attrs: { toggle: _vm.useZones } }, [
    _c(
      "div",
      { staticClass: "ma-2 store-group-filter" },
      [
        _c(VAutocomplete, {
          attrs: {
            dense: "",
            chips: "",
            "small-chips": "",
            multiple: "",
            solo: "",
            "hide-details": "",
            value: _vm.selectedToolStoreGroups,
            items: _vm.selectedWorkpackageToolStoreGroups,
            "item-value": "key",
            "item-text": "description",
            disabled: !_vm.selectedWorkpackageToolStoreGroups,
            label: _vm.$t("settings.penaltyRules.selectStoreGroup"),
            color: "indigo darken-2",
            "search-input": _vm.storeGroupSearchInput,
            loading:
              _vm.loadingStoreGroupRelationships ||
              _vm.loadingSelectedWorkpackageToolStoreGroups,
          },
          on: {
            "update:searchInput": function ($event) {
              _vm.storeGroupSearchInput = $event
            },
            "update:search-input": function ($event) {
              _vm.storeGroupSearchInput = $event
            },
            change: _vm.selectStoreGroup,
          },
          scopedSlots: _vm._u([
            {
              key: "selection",
              fn: function ({ item, selected }) {
                return [
                  _c(
                    VChip,
                    {
                      staticClass: "chip--select",
                      attrs: { "input-value": selected, close: "", small: "" },
                      on: {
                        "click:close": function ($event) {
                          return _vm.deselectStoreGroup(item)
                        },
                      },
                    },
                    [
                      _vm._v(
                        "\n          " + _vm._s(item.description) + "\n        "
                      ),
                    ]
                  ),
                ]
              },
            },
            {
              key: "no-data",
              fn: function () {
                return [
                  !_vm.loadingStoreGroupRelationships &&
                  !_vm.loadingSelectedWorkpackageToolStoreGroups
                    ? _c(
                        "div",
                        [
                          _c(
                            VAlert,
                            {
                              attrs: {
                                value: true,
                                color: "error",
                                icon: "warning",
                                outlined: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("attributes.filters.noData")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      )
                    : _c(
                        "div",
                        [
                          _c(
                            VAlert,
                            {
                              attrs: {
                                value: true,
                                color: "info",
                                icon: "info",
                                outlined: "",
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(_vm.$t("general.loadingMessage")) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                ]
              },
              proxy: true,
            },
            {
              key: "item",
              fn: function (props) {
                return [
                  _c("span", { staticClass: "item-text" }, [
                    _vm._v(
                      "\n          " +
                        _vm._s(props.item.description) +
                        "\n        "
                    ),
                  ]),
                ]
              },
            },
          ]),
        }),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }