<template>
  <svg
    width="13px"
    height="13px"
    viewBox="0 0 13 13"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Icon / Import</title>
    <g id="Icon-/-Import" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        id="Import"
        d="M6.50520833,1.1 C6.78125,1.1 7,1.31817343 7,1.57186347 L7,6.32601476 L6.95833333,7.03634686 L7.25,6.67103321 L7.91145833,5.98607011 C7.99479167,5.8947417 8.11979167,5.84907749 8.23958333,5.84907749 C8.47916667,5.84907749 8.671875,6.01651292 8.671875,6.25498155 C8.671875,6.38182657 8.625,6.47315498 8.53645833,6.55433579 L6.88541667,8.10184502 C6.76041667,8.22869004 6.63541667,8.26928044 6.50520833,8.26928044 C6.375,8.26928044 6.25520833,8.22869004 6.13020833,8.10184502 L4.47916667,6.55433579 C4.390625,6.47315498 4.34375,6.38182657 4.34375,6.25498155 C4.34375,6.01651292 4.54166667,5.84907749 4.77604167,5.84907749 C4.90104167,5.84907749 5.02083333,5.8947417 5.10416667,5.98607011 L5.765625,6.67103321 L6.05729167,7.03127306 L6.015625,6.32601476 L6.015625,1.57186347 C6.015625,1.31817343 6.234375,1.1 6.50520833,1.1 Z M3.66145833,11.8988745 C2.578125,11.8988745 2,11.3458303 2,10.3006273 L2,5.67332103 C2,4.62304428 2.578125,4.07 3.66145833,4.07 L4.984375,4.07 L4.984375,5.11012915 L3.74479167,5.11012915 C3.30729167,5.11012915 3.06770833,5.32830258 3.06770833,5.77479705 L3.06770833,10.1940775 C3.06770833,10.6456458 3.30729167,10.8587454 3.74479167,10.8587454 L9.25520833,10.8587454 C9.69270833,10.8587454 9.93229167,10.6456458 9.93229167,10.1940775 L9.93229167,5.77479705 C9.93229167,5.32830258 9.69270833,5.11012915 9.25520833,5.11012915 L8.03125,5.11012915 L8.03125,4.07 L9.34375,4.07 C10.4270833,4.07 11,4.62811808 11,5.67332103 L11,10.3006273 C11,11.3458303 10.4270833,11.8988745 9.34375,11.8988745 L3.66145833,11.8988745 Z"
        fill="#FFFFFF"
        fill-rule="nonzero"
        transform="translate(6.500000, 6.499437) scale(1, -1) rotate(-180.000000) translate(-6.500000, -6.499437) "
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Import',
};
</script>
