/* eslint-disable import/prefer-default-export */
import { get, reduce, isEmpty, sortBy, set, intersection } from 'lodash';
import {
  attributeOption,
  hierarchyOption,
  storegroupOption,
  wholesaleHierarchyOption,
} from '@enums/filter-options';
import { filterOptionFieldMapping } from '@enums/attribute-filters';

// returns filters in object format
// hierarchy filters are at the top level of the object - this allows applyAccessFilters to work without changes
// attribute filters are in an array inside $and (because `attributeKey` will be repeatedly used)
export function formatFilters({ where, ignoreHierarchyLevel = false, hierarchyByParentMap = {} }) {
  const filters = reduce(
    where,
    (formattedFilters, rule) => {
      const attributeValues = sortBy(rule.attributeValue);

      if (rule.attributeFilterType === storegroupOption) {
        formattedFilters.toolStoreGroupKey = {
          $in: attributeValues,
        };
      } else if (rule.attributeFilterType === attributeOption) {
        const filterRule = {
          attributes: {
            $elemMatch: {
              attributeKey: rule.attributeKey,
              attributeValue: { $in: attributeValues },
            },
          },
        };
        if (!isEmpty(formattedFilters.$and)) formattedFilters.$and.push(filterRule);
        else formattedFilters.$and = [filterRule];
      } else if (rule.attributeFilterType === hierarchyOption) {
        // TODO: this should become the code when we move to v2 inputs
        if (ignoreHierarchyLevel) {
          const hierarchyPath = `hierarchy.id`;
          set(formattedFilters, [`${hierarchyPath}`], {
            $in: [
              ...get(formattedFilters, [`${hierarchyPath}`, '$in'], []).filter(h => {
                return isEmpty(
                  intersection(hierarchyByParentMap[h].map(x => x.levelEntryKey), attributeValues)
                );
              }),
              ...attributeValues,
            ],
          });
        } else {
          const hierarchyPath = `hierarchy.${rule.attributeKey}.id`;
          formattedFilters[hierarchyPath] = { $in: attributeValues };
        }
      } else if (rule.attributeFilterType === wholesaleHierarchyOption) {
        const hierarchyPath = `wholesaleHierarchy.${rule.attributeKey}.id`;
        formattedFilters[hierarchyPath] = { $in: attributeValues };
      } else if (filterOptionFieldMapping[rule.attributeFilterType]) {
        const path = filterOptionFieldMapping[rule.attributeFilterType];
        formattedFilters[path] = { $in: attributeValues };
      }
      return formattedFilters;
    },
    {}
  );

  /** PRICE-2562, reverse the order of the attributeOption for improved $and query performance.
   * All products in mongo has a toolStoreGroupKey and it is easy to fetch the key and description mapping.
   * We can make the most out of our mongo indexes by removing the tool store group attribute and making a new filter optioin - StoreGroupOption.
   */
  if (!isEmpty(filters.$and)) filters.$and.reverse();

  return filters;
}
