<template>
  <v-menu offset-y>
    <template v-slot:activator="{ on }">
      <v-btn
        small
        color="primary"
        class="ml-2"
        :disabled="disabled"
        :loading="loading"
        depressed
        v-on="on"
      >
        {{ $t('workPackages.exportToSAP.title') }}
      </v-btn>
    </template>

    <v-list>
      <v-list-item @click="emitExportFile">
        <v-list-item-title>
          {{ $t('workPackages.exportToSAP.downloadFile') }}
        </v-list-item-title>
      </v-list-item>
      <v-list-item @click="emitExportToSystem">
        <v-list-item-title>
          {{ $t('workPackages.exportToSAP.exportToSystem') }}
        </v-list-item-title>
      </v-list-item>
    </v-list>
  </v-menu>
</template>

<script>
export default {
  props: {
    disabled: { type: Boolean, default: false },
    loading: { type: Boolean, default: false },
    params: {
      type: Object,
      default: () => ({}),
    },
  },
  methods: {
    emitExportFile() {
      this.$emit('downloadExportToSAP', this.params);
    },
    emitExportToSystem() {
      this.$emit('downloadExportToSAP', { ...this.params, toFTP: true });
    },
  },
};
</script>
