var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Underspecified penalty curve")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "underspecified-penalty-curve-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "underspecified-penalty-curve-transform",
                transform: "translate(-375.000000, -719.000000)",
                "fill-rule": "nonzero",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "underspecified-penalty-curve",
                    transform: "translate(375.000000, 719.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M2,22 C2.55228475,22 3,22.4477153 3,23 C3,23.5522847 2.55228475,24 2,24 C1.44771525,24 1,23.5522847 1,23 C1,22.4477153 1.44771525,22 2,22 Z M6,22 C6.55228475,22 7,22.4477153 7,23 C7,23.5522847 6.55228475,24 6,24 C5.44771525,24 5,23.5522847 5,23 C5,22.4477153 5.44771525,22 6,22 Z M10,22 C10.5522847,22 11,22.4477153 11,23 C11,23.5522847 10.5522847,24 10,24 C9.44771525,24 9,23.5522847 9,23 C9,22.4477153 9.44771525,22 10,22 Z M14,22 C14.5522847,22 15,22.4477153 15,23 C15,23.5522847 14.5522847,24 14,24 C13.4477153,24 13,23.5522847 13,23 C13,22.4477153 13.4477153,22 14,22 Z M18,22 C18.5522847,22 19,22.4477153 19,23 C19,23.5522847 18.5522847,24 18,24 C17.4477153,24 17,23.5522847 17,23 C17,22.4477153 17.4477153,22 18,22 Z M22,22 C22.5522847,22 23,22.4477153 23,23 C23,23.5522847 22.5522847,24 22,24 C21.4477153,24 21,23.5522847 21,23 C21,22.4477153 21.4477153,22 22,22 Z M22,1 C22.5523,1 23,1.44772 23,2 C23,4.91752 22.4895,9.31113 20.9208,13.015 C19.3566,16.7082 16.5947,20 12,20 C7.4053,20 4.64338,16.7082 3.07918,13.015 C1.51049,9.31113 1,4.91752 1,2 C1,1.44772 1.44772,1 2,1 C2.55228,1 3,1.44772 3,2 C3,4.74915 3.48951,8.85553 4.92082,12.235 C6.35662,15.6251 8.5947,18 12,18 C15.4053,18 17.6434,15.6251 19.0792,12.235 C20.5105,8.85553 21,4.74915 21,2 C21,1.44772 21.4477,1 22,1 Z M12,12.5 C12.8284271,12.5 13.5,13.1715729 13.5,14 C13.5,14.8284271 12.8284271,15.5 12,15.5 C11.1715729,15.5 10.5,14.8284271 10.5,14 C10.5,13.1715729 11.1715729,12.5 12,12.5 Z M12,1 C14.21,1 16,2.79 16,5 C16,5.88 15.64,6.68 15.07,7.25 L14.17,8.17 C13.6349,8.71253 13.2489,9.18326 13.086,10.005 C12.9786,10.5467 12.5523,11 12,11 C11.4477,11 11,10.5523 11,10 C11,8.9 11.45,8.4 12.17,7.67 L13.41,6.41 C13.78,6.05 14,5.55 14,5 C14,3.9 13.1,3 12,3 C11.2466,3 10.5871,3.42215 10.2462,4.04157 C9.97995,4.52543 9.55228,5 9,5 C8.44772,5 7.98716,4.54534 8.12331,4.0101 C8.56356,2.27927 10.1317,1 12,1 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }