import ErrorPage from './error.vue';

const routes = [
  {
    path: '/error/:statusCode',
    name: 'error',
    id: 'error',
    component: ErrorPage,
    omitFromNav: true,
    meta: {
      title: 'general.error',
    },
  },
];

export default routes;
