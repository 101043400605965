import inputsPage from './inputs.vue';
import attributesPageNew from './attributes/attribute-editor-new.vue';
import attributesPage from './attributes/attribute-editor.vue';
import competitorMatchesPageNew from './competitor-matches/competitor-matches-new.vue';
import engineInputsPageNew from './engine-inputs/engine-inputs-new.vue';
import competitorMatchesPage from './competitor-matches/competitor-matches-list.vue';
import engineInputPage from './engine-inputs/engine-input-editor.vue';
import priceOverridesPage from './price-overrides/price-overrides-editor.vue';
import priceOverridesPageNew from './price-overrides/price-overrides-new.vue';
import { inputs as inputsPageIndex } from '@enums/page-index';

import { displayV2Inputs, displayV1Inputs } from '@enums/feature-flags';

const routes = [
  {
    path: '/inputs',
    name: 'inputs',
    id: 'inputs',
    component: inputsPage,
    linkText: 'linkText.inputs',
    index: inputsPageIndex,
    children: [
      {
        path: 'attributes',
        name: 'attributes',
        id: 'attributes',
        component: attributesPage,
        linkText: 'linkText.attributes',
        index: 0,
        meta: {
          featureFlag: displayV1Inputs,
          title: 'tabText.attributes',
        },
      },
      {
        path: 'attributes-v2',
        name: 'attributes-v2',
        id: 'attributes-v2',
        component: attributesPageNew,
        linkText: 'linkText.attributes-v2',
        index: 0,
        meta: {
          featureFlag: displayV2Inputs,
          title: 'tabText.attributes-v2',
        },
      },
      {
        path: 'competitor-matches',
        name: 'competitor-matches',
        id: 'competitor-matches',
        component: competitorMatchesPage,
        linkText: 'linkText.competitorMatches',
        index: 1,
        meta: {
          featureFlag: displayV1Inputs,
          title: 'tabText.competitorMatches',
        },
      },
      {
        path: 'competitor-matches-v2',
        name: 'competitor-matches-v2',
        id: 'competitor-matches-v2',
        component: competitorMatchesPageNew,
        linkText: 'linkText.competitorMatches-v2',
        index: 1,
        meta: {
          featureFlag: displayV2Inputs,
          title: 'tabText.competitorMatches-v2',
        },
      },
      {
        path: 'engine-inputs',
        name: 'engine-inputs',
        id: 'engine-inputs',
        component: engineInputPage,
        linkText: 'linkText.engineInputs',
        index: 2,
        meta: {
          featureFlag: displayV1Inputs,
          title: 'tabText.engineInputs',
        },
      },
      {
        path: 'engine-inputs-v2',
        name: 'engine-inputs-v2',
        id: 'engine-inputs-v2',
        component: engineInputsPageNew,
        linkText: 'linkText.engineInputs-v2',
        index: 2,
        meta: {
          featureFlag: displayV2Inputs,
          title: 'tabText.engineInputs-v2',
        },
      },
      {
        path: 'price-overrides',
        name: 'price-overrides',
        id: 'price-overrides',
        component: priceOverridesPage,
        linkText: 'linkText.priceOverrides',
        index: 3,
        meta: {
          featureFlag: displayV1Inputs,
          title: 'tabText.priceOverrides',
        },
      },
      {
        path: 'price-overrides-v2',
        name: 'price-overrides-v2',
        id: 'price-overrides-v2',
        component: priceOverridesPageNew,
        linkText: 'linkText.priceOverrides-v2',
        index: 3,
        meta: {
          featureFlag: displayV2Inputs,
          title: 'tabText.priceOverrides-v2',
        },
      },
    ],
  },
];

export default routes;
