import { VBtn } from 'vuetify/lib/components/VBtn';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "nav-tab-wrapper" },
    [
      !_vm.route.children && _vm.isWorkPackageSelected
        ? _c(
            VBtn,
            {
              key: _vm.index,
              staticClass: "text-none subheading nav-link",
              class: [
                _vm.$route.fullPath.includes(_vm.route.path)
                  ? "active-link"
                  : "",
                _vm.isNavbarHighlighted ? "nav-link-dark" : "",
              ],
              attrs: {
                slot: "activator",
                exact: "",
                text: "",
                to: _vm.route.path,
                depressed: "",
              },
              slot: "activator",
            },
            [_vm._v("\n    " + _vm._s(_vm.$t(_vm.route.linkText)) + "\n  ")]
          )
        : _vm._e(),
      _vm._v(" "),
      _vm.route.children && _vm.isWorkPackageSelected
        ? _c(
            VMenu,
            {
              attrs: { "offset-y": "" },
              scopedSlots: _vm._u(
                [
                  {
                    key: "activator",
                    fn: function ({ on }) {
                      return [
                        _c(
                          VBtn,
                          _vm._g(
                            {
                              key: _vm.index,
                              staticClass: "text-none subheading nav-link",
                              class: [
                                _vm.$route.fullPath.includes(_vm.route.path)
                                  ? "active-link"
                                  : "",
                                _vm.isNavbarHighlighted ? "nav-link-dark" : "",
                              ],
                              attrs: { exact: "", text: "", depressed: "" },
                            },
                            on
                          ),
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(_vm.$t(_vm.route.linkText)) +
                                "\n      "
                            ),
                          ]
                        ),
                      ]
                    },
                  },
                ],
                null,
                false,
                2091151813
              ),
            },
            [
              _vm._v(" "),
              _vm.route.children
                ? _c(
                    VList,
                    _vm._l(_vm.availableChildRoutes, function (item, i) {
                      return _c(
                        VListItem,
                        {
                          key: i,
                          attrs: {
                            to: _vm.route.path + "/" + item.path,
                            dark: "",
                          },
                        },
                        [
                          _c(VListItemTitle, [
                            _vm._v(_vm._s(_vm.$t(item.linkText))),
                          ]),
                        ],
                        1
                      )
                    }),
                    1
                  )
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }