import { VBtn } from 'vuetify/lib/components/VBtn';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VForm } from 'vuetify/lib/components/VForm';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { fluid: "" } },
    [
      _c(
        VForm,
        {
          staticClass: "form-signin",
          model: {
            value: _vm.valid,
            callback: function ($$v) {
              _vm.valid = $$v
            },
            expression: "valid",
          },
        },
        [
          _c("h2", { staticClass: "form-signin-heading" }, [
            _vm._v("Please sign in"),
          ]),
          _vm._v(" "),
          _c(VTextField, {
            attrs: {
              label: "Email Address",
              type: "username",
              rules: _vm.rules.email,
              autocomplete: "false",
              required: "",
            },
            model: {
              value: _vm.user.username,
              callback: function ($$v) {
                _vm.$set(_vm.user, "username", $$v)
              },
              expression: "user.username",
            },
          }),
          _vm._v(" "),
          _c(VTextField, {
            attrs: {
              label: "Password",
              type: "password",
              rules: _vm.rules.password,
              autocomplete: "false",
              required: "",
            },
            on: {
              keyup: function ($event) {
                if (
                  !$event.type.indexOf("key") &&
                  _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                )
                  return null
                return _vm.onLogin()
              },
            },
            model: {
              value: _vm.user.password,
              callback: function ($$v) {
                _vm.$set(_vm.user, "password", $$v)
              },
              expression: "user.password",
            },
          }),
          _vm._v(" "),
          _c(
            VBtn,
            {
              attrs: {
                id: "submit",
                color: "primary",
                disabled: !_vm.valid,
                depressed: "",
              },
              on: {
                click: function ($event) {
                  return _vm.onLogin()
                },
              },
            },
            [_vm._v("\n      Sign in\n    ")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }