import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.noProductSettings && !_vm.loadingProductSettings
        ? _c(VLayout, [
            _c("i", [
              _vm._v(
                _vm._s(_vm.$t("pricing.penaltyFunctions.noProductSettings"))
              ),
            ]),
          ])
        : _vm._e(),
      _vm._v(" "),
      !_vm.loadingProductSettings && !_vm.noProductSettings
        ? _c(VSimpleTable, {
            staticClass: "penalty-table",
            attrs: { dense: "" },
            scopedSlots: _vm._u(
              [
                {
                  key: "default",
                  fn: function () {
                    return [
                      _c("thead", [
                        _c("tr", { staticClass: "header" }, [
                          _c("th", { staticClass: "text-left" }, [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pricing.penaltyFunctions.ruleName")
                                )
                              ),
                            ]),
                          ]),
                          _vm._v(" "),
                          _c("th", { staticClass: "text-left" }, [
                            _c("h4", [
                              _vm._v(
                                _vm._s(
                                  _vm.$t("pricing.penaltyFunctions.penaltyType")
                                )
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(
                        "tbody",
                        _vm._l(
                          _vm.ruleNamesWithPenaltyTypes,
                          function (penalty, ix) {
                            return _c(
                              "tr",
                              {
                                key: `${penalty.ruleName}-${ix}`,
                                staticClass: "penalty-row",
                              },
                              [
                                _c("td", { staticClass: "pt-1 pb-1" }, [
                                  _c("span", [
                                    _vm._v(
                                      _vm._s(
                                        _vm.showIfDifferent(
                                          penalty.ruleName,
                                          "ruleName",
                                          ix,
                                          _vm.ruleNamesWithPenaltyTypes
                                        )
                                      )
                                    ),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c("td", { staticClass: "pt-1 pb-1" }, [
                                  _c(
                                    "span",
                                    {
                                      class: _vm.getClassForPenaltyType(
                                        penalty.penaltyType
                                      ),
                                    },
                                    [
                                      _vm._v(
                                        _vm._s(
                                          _vm.$t(
                                            `pricing.penaltyFunctions.${_vm.showIfDifferent(
                                              penalty.penaltyType,
                                              "penaltyType",
                                              ix,
                                              _vm.ruleNamesWithPenaltyTypes
                                            )}`
                                          )
                                        )
                                      ),
                                    ]
                                  ),
                                ]),
                              ]
                            )
                          }
                        ),
                        0
                      ),
                    ]
                  },
                  proxy: true,
                },
              ],
              null,
              false,
              522883952
            ),
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }