import { VFlex } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VFlex,
    { staticClass: "upload-error" },
    [
      _c("p", { staticClass: "upload-error-name" }, [
        _vm._v("\n    " + _vm._s(_vm.errorMessage) + "\n  "),
      ]),
      _vm._v(" "),
      _vm.details && _vm.details.length
        ? [
            _vm._l(_vm.details.slice(0, 5), function (item, index) {
              return _c(
                "p",
                { key: `${_vm.uniqueKeyPrefix}-${index}` },
                [
                  _vm._t(
                    "row",
                    function () {
                      return [_vm._v("\n        " + _vm._s(item) + "\n      ")]
                    },
                    { item: item }
                  ),
                ],
                2
              )
            }),
            _vm._v(" "),
            _vm.details.length > 5
              ? _c("p", [
                  _vm._v(
                    "\n      " +
                      _vm._s(
                        _vm.$tc(
                          "attributes.upload.plusXOthers",
                          _vm.details.length - 5
                        )
                      ) +
                      "\n    "
                  ),
                ])
              : _vm._e(),
          ]
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }