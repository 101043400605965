<template>
  <v-dialog
    max-width="450"
    no-click-animation
    hide-overlay
    :value="isOpen"
    :attach="attachTo"
    persistent
  >
    <v-card
      class="wholesale-grid-card card-container"
      :class="{ 'border-error': exportFailed, small: exportFailed || exportNoResults }"
    >
      <template v-if="exportFailed || exportNoResults">
        <v-card-title>
          <p v-if="exportFailed" class="completed-label mb-1 hint">
            <span class="error-hint">{{ $t('general.error') }}: </span>
            {{ $t('wholesale.export.modal.errorHint') }}
          </p>
          <p v-if="exportNoResults" class="completed-label mb-1 hint">
            {{ $t('wholesale.export.modal.noResultsHint') }}
          </p>
        </v-card-title>
        <v-card-text>
          <p v-if="exportFailed" class="error-explanation mb-4">
            {{ $t('wholesale.export.modal.error') }}
          </p>
          <p v-if="exportNoResults" class="error-explanation mb-4">
            {{ $t('wholesale.export.modal.noResults') }}
          </p>
          <template v-if="allowFTPExport">
            <v-card-actions>
              <div class="button-container">
                <button class="close-action" @click="resetLatestWholesaleExport">
                  {{ $t('actions.close') }}
                </button>
              </div>
            </v-card-actions>
            <div class="close-icon" @click="resetLatestWholesaleExport">
              <close />
            </div>
          </template>
        </v-card-text>
      </template>
      <template v-else>
        <v-card-title class="justify-space-between">
          <span class="grid-description">
            {{ $t('wholesale.export.modal.title') }}
          </span>
        </v-card-title>
        <v-card-text>
          <v-divider class="mb-2" />
          <div class="pt-5 px-8">
            <v-progress-linear
              :value="latestWholesaleExport.progress"
              :color="exportFailed ? 'error' : 'primary'"
              rounded
              :indeterminate="inProgress"
              height="15"
              class="mb-4"
            />
            <template v-if="exportSuccessful">
              <p class="completed-label mb-8">
                <i18n v-if="!downloadingFile" path="wholesale.export.modal.successHint" tag="span">
                  <strong>{{ latestWholesaleExport.numFiles }}</strong>
                </i18n>
                <i18n v-else path="wholesale.export.modal.downloading" tag="span">
                  <strong>{{ latestWholesaleExport.numFiles }}</strong>
                </i18n>
              </p>
              <p class="hint px-6 mb-2">
                {{ $t('wholesale.export.modal.destination') }}
              </p>
              <div class="button-container">
                <div class="export-button pr-2">
                  <v-btn
                    :disabled="downloadingFile"
                    color="primary"
                    small
                    depressed
                    class="export-button px-0"
                    @click="onDownloadClick"
                  >
                    {{ $t('wholesale.export.modal.download') }}
                    <span v-if="latestWholesaleExport.fileSizeMB">
                      &nbsp;{{ latestWholesaleExport.fileSizeMB }}MB
                    </span>
                    <div class="cta-button download">
                      <download />
                    </div>
                  </v-btn>
                </div>
                <div class="export-button pl-2">
                  <v-btn
                    :disabled="downloadingFile || !allowFTPExport"
                    :loading="exportInProgress"
                    color="primary"
                    small
                    depressed
                    class="export-button px-0"
                    @click="onFTPExportClick"
                  >
                    {{ $t('wholesale.export.modal.exportToFTP') }}
                    <div class="cta-button export">
                      <export />
                    </div>
                  </v-btn>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- TODO: enable this when we start handling progress updates -->
              <!-- <p class="completed-label mb-8">
                <i18n path="wholesale.export.modal.pendingHint" tag="span">
                  <strong>{{ latestWholesaleExport.progress }}%</strong>
                </i18n>
              </p> -->
              <p class="hint px-6 mb-2">{{ $t('wholesale.export.modal.pending') }}</p>
            </template>
            <template v-if="canForceExportClose">
              <v-layout row justify-center>
                <v-btn class="mt-2" @click="resetLatestWholesaleExport">
                  {{ $t('wholesale.forceExportClose') }}
                </v-btn>
              </v-layout>
            </template>
          </div>
        </v-card-text>
      </template>
    </v-card>
  </v-dialog>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import exportStatus from '@enums/wholesale-export-status';
import download from '../../../components/component-icons/download.vue';
import featureFlagsMixin from '../../../mixins/featureFlags';
import { enableExportWholesaleForceClose } from '@enums/feature-flags';

export default {
  components: { download },
  mixins: [featureFlagsMixin],
  props: {
    attachTo: {
      type: HTMLElement,
      required: true,
    },
    isOpen: {
      type: Boolean,
      required: true,
    },
  },
  computed: {
    ...mapState('wholesale', ['latestWholesaleExport', 'downloadingFile', 'exportInProgress']),
    ...mapGetters('context', ['isWholesaleManager']),

    canForceExportClose() {
      return this.isFeatureFlagEnabled(enableExportWholesaleForceClose);
    },

    inProgress() {
      return (
        (!this.exportSuccessful && !this.exportFailed) ||
        this.downloadingFile ||
        this.exportInProgress
      );
    },
    exportSuccessful() {
      if (this.latestWholesaleExport) {
        return this.latestWholesaleExport.status === exportStatus.success;
      }
      return false;
    },
    exportFailed() {
      if (this.latestWholesaleExport) {
        const { status } = this.latestWholesaleExport;
        return status === exportStatus.failed;
      }
      return false;
    },
    exportNoResults() {
      if (this.latestWholesaleExport) {
        return this.latestWholesaleExport.status === exportStatus.noResults;
      }
      return false;
    },
    allowFTPExport() {
      return this.isWholesaleManager;
    },
  },
  methods: {
    ...mapActions('wholesale', ['resetLatestWholesaleExport', 'pushToFTP', 'downloadLastExport']),

    async onDownloadClick() {
      await this.downloadLastExport();
    },

    async onFTPExportClick() {
      await this.pushToFTP();
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/wholesale-dialogs.scss';

.wholesale-grid-card {
  position: relative;
  &.card-container {
    min-height: 23rem;

    &.border-error {
      border-color: $error-color;
    }

    &.small {
      min-height: initial;
      padding-top: 2rem;
      padding-bottom: 1rem;
      cursor: pointer;
    }
  }

  .close-icon {
    position: absolute;
    top: 1.5rem;
    right: 1.5rem;
    z-index: 1;
  }

  .close-action {
    color: $pricing-primary;
    font-size: 1.2rem;
    font-weight: 600;
  }

  .error-hint {
    color: $error-color;
  }

  .error-explanation {
    padding: 0 7rem;
    font-size: 1.2rem;
    color: $grid-view-dark-text-color;
    text-align: center;
    width: 100%;
  }

  .button-container {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: row;
  }

  .export-button {
    width: 100%;
  }

  .cta-button {
    margin-left: 0.5rem;
    width: 1.5rem;
    height: 1.5rem;
    display: flex;
    align-items: center;
    justify-content: center;

    &.download svg {
      height: 1.3rem;
    }

    &.export svg {
      height: 2rem;
      width: 1.8rem;
    }
  }
}
</style>
