<template>
  <div>
    <v-row class="mx-2 mt-2 flex-row-reverse">
      <v-item-group>
        <v-btn
          :class="!isManualInputMode ? 'toggle-btn-active' : 'toggle-btn-inactive'"
          small
          tile
          depressed
          @click="toggleInputMode"
        >
          <v-icon v-once>list</v-icon>
        </v-btn>
        <v-btn
          :class="isManualInputMode ? 'toggle-btn-active' : 'toggle-btn-inactive'"
          small
          tile
          depressed
          @click="toggleInputMode"
        >
          <v-icon v-once>text_format</v-icon>
        </v-btn>
      </v-item-group>
    </v-row>
    <v-row class="mx-2">
      <v-col cols="5" class="pb-0">
        <v-select
          v-model="selectedOp"
          :items="filterOperatorOptions"
          :label="$t('filters.selectOp')"
        />
      </v-col>
      <v-col cols="5" class="pb-0">
        <v-autocomplete
          v-if="!isManualInputMode"
          v-model="value"
          :disabled="!selectedOp"
          :items="uniqueValueOptions"
          :max-width="200"
          :type="inputType"
          :multiple="allowMultiple"
          :label="$t('filters.selectValue')"
        />
        <v-text-field
          v-else
          v-model="value"
          :type="inputType"
          :disabled="!selectedOp"
          :label="$t('filters.enterValue')"
        />
      </v-col>
      <v-col cols="2" class="my-auto">
        <v-btn
          :disabled="!isValidFilter"
          :color="addFilterButtonColor"
          icon
          depressed
          @click="upsertFilter"
        >
          <v-icon v-once>add_box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-row v-if="currentFilters.length" class="ml-1">
      <v-col cols="6" class="py-0 align-self-center">
        <small>
          {{ $t('filters.numApplied', { numFilters: currentFilters.length }) }}
        </small>
      </v-col>
      <v-col cols="6" class="text-right py-0">
        <v-btn
          :disabled="currentFilters.length === 0"
          color="primary"
          text
          depressed
          @click="removeActiveTableFilter({ filter: currentFilters })"
        >
          {{ $t('actions.clearAll') }}
        </v-btn>
      </v-col>
    </v-row>
    <v-divider />
    <v-list v-if="currentFilters.length" dense class="py-0">
      <template v-for="filter in currentFilters">
        <v-list-item :key="filter.id" class="my-auto">
          <v-list-item-content>
            <v-row>
              <v-col cols="4"> {{ $t(`filters.filterOperators.${filter.op}`) }}: </v-col>
              <v-col cols="8">
                {{ formatFilterValue(filter.value) }}
              </v-col>
            </v-row>
          </v-list-item-content>
          <v-list-item-icon class="align-self-center">
            <v-icon color="primary" @click="removeActiveTableFilter({ filter })">delete</v-icon>
          </v-list-item-icon>
        </v-list-item>
        <v-divider :key="`divider-${filter.id}`" class="mx-3" />
      </template>
    </v-list>
  </div>
</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import DataTypes from '@enums/data-types';
import FilterOps from '@enums/filter-operations';

const { mapState, mapActions } = createNamespacedHelpers('gridView');

export default {
  props: {
    datatype: {
      type: String,
      required: true,
    },
    path: {
      type: String,
      required: true,
    },
    uniqueValueOptions: {
      type: Array,
      required: false,
      default: () => [],
    },
  },
  data() {
    return {
      open: false,
      selectedOp: null,
      value: null,
      isManualInputMode: false,
    };
  },
  computed: {
    ...mapState(['activeTableFilters']),
    inputType() {
      return this.datatype === DataTypes.number ? 'number' : 'text';
    },
    isValidFilter() {
      return this.selectedOp !== null && this.value !== null;
    },
    allowMultiple() {
      return this.selectedOp === FilterOps.isOneOf;
    },
    currentFilters() {
      return this.activeTableFilters.filter(filter => filter.path === this.path);
    },
    filterIconColor() {
      return this.currentFilters.length > 0 ? 'blue' : 'grey';
    },
    addFilterButtonColor() {
      return this.isValidFilter ? 'primary' : 'grey';
    },
    filterOperatorOptions() {
      let operators = [];
      if (this.datatype === DataTypes.str)
        operators.push({
          text: this.$t('filters.filterOperators.contains'),
          value: FilterOps.contains,
        });
      if (!this.isManualInputMode)
        operators.push({
          text: this.$t('filters.filterOperators.isOneOf'),
          value: FilterOps.isOneOf,
        });
      operators = operators.concat([
        { text: this.$t('filters.filterOperators.equals'), value: FilterOps.equals },
        { text: this.$t('filters.filterOperators.notEqual'), value: FilterOps.notEqual },
      ]);
      if (this.datatype !== DataTypes.str) {
        operators = operators.concat([
          { text: this.$t('filters.filterOperators.greaterThan'), value: FilterOps.greaterThan },
          { text: this.$t('filters.filterOperators.lessThan'), value: FilterOps.lessThan },
          {
            text: this.$t('filters.filterOperators.greaterThanOrEqual'),
            value: FilterOps.greaterThanOrEqual,
          },
          {
            text: this.$t('filters.filterOperators.lessThanOrEqual'),
            value: FilterOps.lessThanOrEqual,
          },
        ]);
      }
      return operators;
    },
  },

  methods: {
    ...mapActions(['upsertActiveTableFilter', 'removeActiveTableFilter']),
    upsertFilter() {
      if (this.datatype === DataTypes.number && this.isManualInputMode)
        this.value = Number(this.value);
      this.upsertActiveTableFilter({
        datatype: this.datatype,
        op: this.selectedOp,
        value: this.value,
        path: this.path,
      });
      this.resetSelections();
    },
    toggleInputMode() {
      this.isManualInputMode = !this.isManualInputMode;
      this.resetSelections();
    },
    resetSelections() {
      this.value = null;
      this.selectedOp = null;
    },
    formatFilterValue(value) {
      return Array.isArray(value) ? value.join(', ') : value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.btn-toggle {
  opacity: 1;
}
.toggle-btn-active {
  color: $pricing-white !important;
  background-color: $btn-ok-color !important;
  border-color: $btn-ok-color !important;
}
.toggle-btn-inactive {
  color: $btn-ok-color !important;
  border-color: $btn-ok-color !important;
}
</style>
