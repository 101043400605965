<template>
  <v-container fluid>
    <div id="okta-login-container" />
  </v-container>
</template>

<script>
import { mapActions, mapState } from 'vuex';
import OktaSignIn from '@okta/okta-signin-widget';

const oktaBaseUrl = 'https://owlabs.okta.com';

export default {
  computed: {
    ...mapState('context', ['loginRedirectUrl', 'oktaClientId']),

    returnPath() {
      let returnPath = this.$route.query.returnPath || '/';
      if (returnPath === '/ow-auth/login') returnPath = '/';
      return returnPath;
    },
  },
  mounted() {
    this.$nextTick(() => {
      this.widget = new OktaSignIn({
        baseUrl: oktaBaseUrl,
        clientId: this.oktaClientId,
        redirectUri: this.loginRedirectUrl,
        authParams: {
          issuer: oktaBaseUrl,
          pkce: false,
          responseType: 'id_token',
          scopes: ['openid', 'email', 'profile'],
        },
      });
      this.widget.renderEl(
        { el: '#okta-login-container' },
        res => {
          if (res.status === 'SUCCESS') {
            // Post the token back to our backend to generate JWT token
            // TODO upgrade away from implicit flow to use authorization code flow
            this.loginOkta(res.tokens.idToken.value).then(() => {
              this.$router.push(this.returnPath);
            });
          }
        },
        err => {
          throw err;
        }
      );
    });
  },
  destroyed() {
    // Remove the widget from the DOM on path change
    this.widget.remove();
  },
  methods: {
    ...mapActions('context', ['loginOkta']),
  },
};
</script>
