import Vue from 'vue';

const eventBus = new Vue();

const EventBus = {
  install(VueInstance) {
    VueInstance.prototype.$eventBus = eventBus;
  },
};

export default EventBus;
