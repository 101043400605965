<template>
  <div class="custom-tooltip">
    <div v-if="isColumnHeader">
      <div class="entry">
        <span>{{ params.value }}</span>
      </div>
    </div>
    <div v-else>
      <div v-if="params.value.previousValueText" class="entry">
        <span>{{ params.value.previousValueText }}</span>
      </div>

      <div v-if="params.value.additionalText" class="entry">
        <template v-for="(text, ix) in params.value.additionalText">
          <div :key="ix">
            <span>{{ text }}</span>
          </div>
        </template>
      </div>

      <div v-if="params.value.errors" class="entry error-message">
        <div>{{ $t('inputs.validationErrorHeader') }}:</div>
        <template v-for="(errorCode, ix) in params.value.errors">
          <div :key="ix">
            <span>{{ $t(`validationErrors.${errorCode}`) }}</span>
          </div>
        </template>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  computed: {
    isColumnHeader() {
      return this.params.location === 'header';
    },
  },
};
</script>

<style lang="scss">
.custom-tooltip {
  max-width: 250px;
  overflow-y: auto;
  padding: 5px;
  background-color: white;
  border: 2px solid #ccc;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.error-message {
  color: #c13939;
}

/* Give top padding only to entries following another entry */
.entry + .entry {
  padding-top: 10px;
}
</style>
