<template>
  <div>
    <v-row class="price-pointing pb-2">
      <v-col class="pa-0 ml-5 mr-4 flex-grow-0">
        <span v-if="!indexInArray" class="header-text mb-2">
          <strong>{{ $t('settings.penaltyRules.pricePointing.range') }}</strong>
          {{ $t('settings.penaltyRules.pricePointing.max') }}
        </span>
        <div class="text-field pr-1">
          <v-text-field
            background-color="white"
            dense
            height="2.2rem"
            class="range-field"
            type="number"
            :value="pricePointing.rangeMaximum"
            :disabled="disabled"
            @change="updateRangeMaximum($event)"
          >
            <template v-slot:prepend>
              <span class="symbol px-2">{{ $t('clientCurrencySymbol') }}</span>
            </template>
          </v-text-field>
        </div>
      </v-col>

      <v-col class="pa-0" cols="9">
        <span v-if="!indexInArray" class="header-text mb-2">
          <strong>{{ $t('settings.penaltyRules.pricePointing.allowedTokens') }}</strong>
        </span>
        <div class="offset-fields py-0 px-2">
          <div
            v-for="(offset, index) in pricePointing.offsets"
            :key="index"
            class="py-1 offset-container"
          >
            <div v-if="isNewlyCreatedOrBaseTen">
              <v-text-field
                :background-color="textFieldColour"
                dense
                height="2.2rem"
                :value="formatBaseTenOffset(offset)"
                :rules="rules.offsetInput"
                :disabled="disabled"
                @change="updateOffset($event, index, false)"
              >
                <template v-slot:prepend>
                  <span class="pr-0 correct-prefix">{{
                    getCorrectPrefixForOffset(pricePointing.base)
                  }}</span>
                </template>
              </v-text-field>
            </div>
            <span v-if="isNewlyCreatedOrBaseTen" class="pt-2 pl-1 pr-1">,</span>
            <div class="pr-0">
              <v-text-field
                :background-color="textFieldColour"
                dense
                height="2.2rem"
                :value="formatOffset(offset)"
                class="pt-0 pb-0"
                :rules="rules.offsetInput"
                :disabled="disabled"
                @change="updateOffset($event, index, true)"
              >
                <template v-if="!isNewlyCreatedOrBaseTen" v-slot:prepend>
                  <span class="pr-0 correct-prefix">{{
                    getCorrectPrefixForOffset(pricePointing.base)
                  }}</span>
                </template>
                <template v-slot:append-outer>
                  <span
                    v-if="index !== pricePointing.offsets.length - 1"
                    class="pt-0 pb-0 pl-0 pr-2"
                  >
                    /
                  </span>
                  <span v-else class="pt-0 pb-0 pl-3 pr-0" />
                </template>
              </v-text-field>
            </div>
          </div>
          <div class="pl-0 add-icon-container">
            <v-icon
              :disabled="disabled || isMaxNumberOfTokensAdded"
              small
              class="add-icon"
              @click="addOffset"
              >fa-plus-square</v-icon
            >
          </div>
          <div class="delete-reset pl-3">
            <span @click="resetOffsets">{{ $t('settings.penaltyRules.pricePointing.reset') }}</span>
            <v-icon class="pl-2" :disabled="disabled" @click="deletePricePointing">delete</v-icon>
          </div>
        </div>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { size } from 'lodash';
import colours from '../../../../ow-colors';

export default {
  props: {
    pricePointing: {
      type: Object,
      default: () => {},
    },

    indexInArray: {
      type: Number,
      required: true,
    },

    disabled: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      textFieldColour: colours.paramsFormBackgroundColor,
      rules: {
        offsetInput: [],
      },
    };
  },

  computed: {
    isNewlyCreatedOrBaseTen() {
      return this.pricePointing.base === 10 || this.pricePointing.base === null;
    },

    isMaxNumberOfTokensAdded() {
      return size(this.pricePointing.offsets) >= 10;
    },
  },

  methods: {
    formatOffset(offset) {
      const stringOffset = offset.toString();
      return Number(stringOffset.split('.')[1]) || 0;
    },

    formatBaseTenOffset(offset) {
      const stringOffset = offset.toString();
      return Number(stringOffset.split('.')[0]);
    },

    deletePricePointing() {
      this.$emit('deletePricePointing', { index: this.indexInArray });
    },

    offsetToNumber(val, index) {
      let newVal;
      const currentValue = this.pricePointing.offsets[index];
      if (this.pricePointing.base === 10 || this.pricePointing.base === null) {
        if (val >= 1) {
          // base 10, value has been entered into LHS text-field. The whole part of the number has changed
          const decimal = Number(currentValue.toString().split('.')[1]) / 100 || 0.0;
          newVal = decimal + val;
        } else {
          // decimal part of the number has changed
          const unit = Number(currentValue.toString().split('.')[0]) || 0;
          newVal = unit + val;
        }
      } else {
        // base 0.1 or base 1
        newVal = val;
      }
      return newVal;
    },

    updateOffset(event, index, divideFlag) {
      const offsetsClone = this.pricePointing.offsets.slice();
      let val = Number(event);
      const numberLength = event.length;
      val = numberLength === 1 && this.pricePointing.base === 10 && divideFlag ? val * 10 : val; // if 7 is just enetered then we want this to equal 0.7 rather than 0.07
      const valForFunct = divideFlag ? val / 100 : val; // convert numbers to decimal if not using base 10 or using RHS text-field
      // if validation does not pass, abort
      const validNumber = this.offsetToNumber(valForFunct, index);
      if (this.pricePointing.base === null) this.updateBase(validNumber * 100);
      offsetsClone[index] = validNumber;
      this.$emit('updateOffset', {
        updatedOffsets: offsetsClone,
        index: this.indexInArray,
      });
    },

    updateBase(number) {
      let updatedBase;
      if (number >= 0 && number <= 9) updatedBase = 0.1;
      else if (number >= 10 && number <= 99) updatedBase = 1;
      else updatedBase = 10;
      this.$emit('updateBase', { updatedBase, index: this.indexInArray });
    },

    updateRangeMaximum(event) {
      this.$emit('updateRangeMaximum', { updatedRangeMaximum: event, index: this.indexInArray });
    },

    addOffset() {
      const offsetsClone = this.pricePointing.offsets.slice();
      offsetsClone.push(0.0);
      this.$emit('updateOffset', {
        updatedOffsets: offsetsClone,
        index: this.indexInArray,
      });
    },

    resetOffsets() {
      if (this.disabled) return;

      this.$emit('updateOffset', {
        updatedOffsets: [],
        index: this.indexInArray,
      });
    },

    getCorrectPrefixForOffset(base) {
      if (base === 0.1) return `${this.$t('clientCurrencySymbol')}-,-`;
      if (base === 1) return `${this.$t('clientCurrencySymbol')}--,`;
      return `${this.$t('clientCurrencySymbol')}--`;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.header-text {
  display: block;
  font-size: 1.25rem;
}

.text-field {
  align-items: center;
  background-color: $paramsFormBackgroundColor;
  display: flex;
  height: 3.8rem;
  min-width: 6.8rem;

  .range-field {
    width: auto;
  }

  ::v-deep {
    .v-input__control {
      min-width: 3.2rem;
    }
  }
}

.symbol {
  font-size: 1.5rem;
}

.add-icon {
  color: $rulesetDarkBlue;
  padding-top: 0.2rem;
}

.v-text-field__slot {
  font-size: 2rem;
}

.offset-fields {
  align-items: center;
  border: 1px solid $paramsFormBackgroundColor;
  display: flex;
  flex-direction: row;
  min-height: 3.8rem;

  ::v-deep {
    .v-input__control {
      max-width: 1.2rem;
    }
  }
}

.offset-container {
  display: flex;
  flex-direction: row;
}

.delete-reset {
  color: $rulesetDarkBlue;
  margin-left: auto;
  min-width: 7rem;

  span {
    cursor: pointer;
  }
}

.add-icon-container {
  line-height: 1;
}

.correct-prefix {
  font-size: 1.5rem;
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.price-pointing {
  .v-input__prepend-outer {
    margin-right: unset !important;
  }

  .v-text-field__details {
    display: none;
  }

  input {
    padding: unset !important;
  }
}
</style>
