import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.approvals && _vm.approvals.length
    ? _c(
        "div",
        { staticClass: "approvals" },
        _vm._l(_vm.approvals, function (approval) {
          return _c(
            "div",
            {
              key: approval.id,
              staticClass: "approval",
              attrs: {
                "custom-title-msg": approval.tooltipMessage,
                "custom-title-hidden": approval.tooltipMessage === null,
                "custom-title-position": "top-right",
              },
            },
            [
              _c(VSelect, {
                staticClass: "pb-0 ma-0 approval-select",
                attrs: {
                  items: _vm.approvalOptions,
                  height: "2rem",
                  value: approval.value,
                  disabled:
                    approval.disabled || _vm.isSelectedWorkpackageMaster,
                },
                on: {
                  change: function ($event) {
                    return _vm.approvalChange(approval, $event)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "item",
                      fn: function ({ item }) {
                        return [
                          item
                            ? _c("span", [_vm._m(0)], 1)
                            : _c("span", [_vm._m(1)], 1),
                        ]
                      },
                    },
                    {
                      key: "selection",
                      fn: function ({ item }) {
                        return [
                          item
                            ? _c("span", [_vm._m(2)], 1)
                            : _c("span", [_vm._m(3)], 1),
                        ]
                      },
                    },
                  ],
                  null,
                  true
                ),
              }),
            ],
            1
          )
        }),
        0
      )
    : _vm._e()
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { color: "success" } }, [
      _vm._v("check_circle"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { color: "red" } }, [_vm._v("cancel")])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { size: "2.2rem", color: "success" } }, [
      _vm._v("check_circle"),
    ])
  },
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(VIcon, { attrs: { size: "2.2rem", color: "red" } }, [
      _vm._v("cancel"),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }