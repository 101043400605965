<template>
  <div
    class="d-flex rtls-text-field-container"
    :custom-title-msg="formatTooltipMessage(tooltip)"
    :custom-title-error="!!error"
    :custom-title-hidden="hideTooltip"
    :custom-title-position="tooltipPosition"
  >
    <input
      :disabled="disabled"
      :value="formattedValue"
      class="base-class"
      :class="cellClass"
      v-on="inputListeners"
    />
  </div>
</template>

<script>
import { isObject } from 'lodash';
import DataTypes from '@enums/data-types';
import isTooltipDisabled from '../../../../utils/tooltip-util';
import tooltipFormatterMixin from '../../../../mixins/tooltipFormatter';
import { LINE_BREAK_SYMBOL } from '@sharedModules/data/constants/special-symbols';

export default {
  mixins: [tooltipFormatterMixin],
  props: {
    // --- text-field props ---
    value: {
      required: true,
    },
    previousValue: {
      required: true,
    },
    // the error message for the current value
    error: {
      required: false,
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    dataType: {
      type: String,
      required: true,
    },
    cellClass: {
      required: false,
      type: String,
      default: '',
    },
    allowNegative: {
      type: Boolean,
      default: false,
    },
    preventFormatting: {
      type: Boolean,
      default: false,
    },
    numberFormat: {
      required: false,
      type: String,
      default: null,
    },
    tooltipValue: {
      type: [String, Number, Object],
      default: null,
    },
    previousValueTranslationKey: {
      type: String,
      default: 'attributes.editor',
    },
    tooltipPosition: {
      type: String,
      default: 'top-right',
    },
  },

  data() {
    return {
      truncationLength: 15,
      dataTypesMap: {
        [DataTypes.str]: this.$t('attributes.dataTypes.text'),
        [DataTypes.number]: this.$t('attributes.dataTypes.number'),
      },
      isFocused: false,
      dataTypes: DataTypes,
    };
  },
  computed: {
    formattedValue() {
      return this.getFormattedValue(this.value);
    },
    inputListeners() {
      const vm = this;
      /* eslint prefer-object-spread: 0 */
      return Object.assign({}, this.$listeners, {
        keypress: event => {
          if (vm.dataType === DataTypes.number) vm.ensureDecimal(event, vm.allowNegative);
        },
        paste: event => {
          if (vm.dataType !== DataTypes.number) return;
          vm.ensurePastedDecimal(event, vm.allowNegative);
        },
        focus: event => {
          vm.isFocused = true;
          vm.$emit('focus', event);
        },
        blur: event => {
          vm.isFocused = false;
          vm.$emit('blur', event);
        },
      });
    },
    hideTooltip() {
      // true if the cell is edited/invalid, or the displayed value is longer than truncationLength
      const formattedPreviousValue = this.getFormattedValue(this.previousValue);
      const formattedPreviousValueWithZero = `${formattedPreviousValue}0`;
      return (
        this.isFocused ||
        (!this.error &&
          (this.formattedValue === formattedPreviousValue ||
            this.formattedValue === formattedPreviousValueWithZero) &&
          this.isTooltipDisabled(this.value, this.truncationLength) &&
          !this.tooltipValue)
      );
    },
    tooltip() {
      const formattedPreviousValue =
        this.dataType === this.dataTypes.number
          ? this.formatNumber({
              number: this.previousValue,
              format: this.numberFormat,
              zeroAsDash: true,
            })
          : this.previousValue;
      if (this.error) {
        return this.error;
      }
      if (
        this.tooltipValue &&
        (this.value !== formattedPreviousValue && this.value !== this.previousValue)
      ) {
        const previousValueText = this.$t(`${this.previousValueTranslationKey}.previousValue`);

        if (isObject(this.tooltipValue)) {
          return {
            ...this.tooltipValue,
            [previousValueText]: formattedPreviousValue || '-',
          };
        }
        return `${previousValueText}: ${formattedPreviousValue || '-'}${LINE_BREAK_SYMBOL}${
          this.tooltipValue
        }`;
      }
      if (this.tooltipValue) {
        return this.tooltipValue;
      }
      if (this.value !== formattedPreviousValue && this.value !== this.previousValue) {
        const previousValueText = this.$t(`${this.previousValueTranslationKey}.previousValue`);
        return `${previousValueText}: ${formattedPreviousValue || '-'}`;
      }
      // display current value if longer than truncationLength
      return this.formattedValue;
    },
  },

  methods: {
    isTooltipDisabled,

    getFormattedValue(value) {
      if (
        this.dataType === DataTypes.number &&
        this.isFocused &&
        value === '-' &&
        !this.allowNegative
      ) {
        return '';
      }
      if (this.dataType === DataTypes.number && value && !this.preventFormatting) {
        return this.formatNumber({
          number: value,
          format: this.numberFormat,
          nullAsDash: true,
        });
      }
      return value;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.rtls-text-field-container {
  align-items: center;
  height: 100%;
}

:focus {
  outline: none;
  background: $pricing-white;
}

.base-class {
  padding: 0.3rem;
}

.attribute-cell-error {
  outline: $pricing-red auto 1px;
}

.attribute-cell-updated {
  outline: $pricing-light-blue auto 1px;
}
.override-cell-error,
.override-base-class {
  width: 6rem;
  margin-right: 0;
  text-align: right;
}

.override-cell-error {
  border-bottom: 1px solid $pricing-red;
}
</style>
