import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    { attrs: { "ma-0": "", "pa-2": "", "pb-0": "" } },
    [
      _c(
        VLayout,
        [
          _c(VFlex, { attrs: { xs9: "" } }, [
            _c("h3", [
              _vm._v("\n        " + _vm._s(_vm.$t("pricing.priceHistory"))),
              _c("span", [_vm._v(_vm._s(_vm.$t("pricing.priceHistorySub")))]),
            ]),
          ]),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VLayout,
        { attrs: { row: "" } },
        [
          _c(
            VFlex,
            { attrs: { xs9: "" } },
            [
              _c("highcharts", {
                ref: "highcharts",
                attrs: { options: _vm.chartOptions },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VFlex,
            { staticClass: "legend", attrs: { xs3: "", "align-self-end": "" } },
            _vm._l(_vm.historiesToPlot, function (value, index) {
              return _c(VCheckbox, {
                key: value,
                attrs: {
                  value: !_vm.selectedPriceLines.includes(value),
                  "input-value": _vm.selectedPriceLines.includes(value),
                  column: "",
                  color: "light-blue darken-3",
                  height: "0.1rem",
                },
                on: {
                  change: function ($event) {
                    return _vm.updateSelectedPriceLines($event, value)
                  },
                },
                scopedSlots: _vm._u(
                  [
                    {
                      key: "label",
                      fn: function () {
                        return [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                disabled:
                                  _vm.tooltipExcludeLines.includes(value),
                                value: _vm.getTooltip(value),
                              },
                            },
                            [
                              _c(
                                "div",
                                {
                                  style: {
                                    color:
                                      _vm.getPriceHistoryLegendColour(index),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n              " +
                                      _vm._s(_vm.getLegendLabel(value)) +
                                      "\n            "
                                  ),
                                ]
                              ),
                            ]
                          ),
                        ]
                      },
                      proxy: true,
                    },
                  ],
                  null,
                  true
                ),
              })
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }