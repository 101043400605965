import { VAlert } from 'vuetify/lib/components/VAlert';
import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VChip } from 'vuetify/lib/components/VChip';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VLayout,
        { attrs: { row: "" } },
        [
          _c(
            VFlex,
            { staticClass: "pr-2", attrs: { xs4: "" } },
            [
              _c(VAutocomplete, {
                staticClass: "mb-1",
                attrs: {
                  disabled: _vm.selectedAttribute.disabled,
                  dense: "",
                  solo: "",
                  flat: "",
                  "hide-details": "",
                  "return-object": "",
                  value: _vm.filterRule,
                  items: _vm.availableFilters,
                  label: _vm.$t("attributes.filters.attributeLabel"),
                  "item-text": "displayDescription",
                  color: "indigo darken-2",
                  height: "30",
                },
                on: {
                  input: function ($event) {
                    _vm.filterAttributes(
                      _vm.selectedAttributeValues,
                      (_vm.fetchData = false)
                    )
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        _c(
                          VAlert,
                          {
                            attrs: {
                              value: true,
                              color: "error",
                              icon: "warning",
                              outlined: "",
                            },
                          },
                          [
                            _vm._v(
                              "\n            " +
                                _vm._s(_vm.$t("attributes.filters.noData")) +
                                "\n          "
                            ),
                          ]
                        ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item",
                    fn: function (props) {
                      return [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(props.item.displayDescription) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedAttribute,
                  callback: function ($$v) {
                    _vm.selectedAttribute = $$v
                  },
                  expression: "selectedAttribute",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VFlex,
            { attrs: { xs7: "" } },
            [
              _c(VAutocomplete, {
                attrs: {
                  multiple: "",
                  dense: "",
                  solo: "",
                  flat: "",
                  "small-chips": "",
                  "hide-details": "",
                  items: _vm.availableFilterValues,
                  disabled:
                    _vm.noFilterSelected || _vm.selectedAttribute.disabled,
                  label: _vm.$t("attributes.filters.valueLabel"),
                  loading: _vm.loadingAvailableValues,
                  "item-value": "attributeKey",
                  "item-text": "attributeValue",
                  color: "indigo darken-2",
                  "search-input": _vm.searchInput,
                },
                on: {
                  "update:searchInput": function ($event) {
                    _vm.searchInput = $event
                  },
                  "update:search-input": [
                    function ($event) {
                      _vm.searchInput = $event
                    },
                    _vm.searchInputHandler,
                  ],
                  input: _vm.filterAttributes,
                  paste: _vm.pasteInputHandler,
                  blur: function ($event) {
                    return _vm.$emit("filter-blurred")
                  },
                },
                scopedSlots: _vm._u([
                  {
                    key: "selection",
                    fn: function ({ item, index, selected }) {
                      return [
                        index < _vm.maxItemsToList
                          ? _c(
                              VChip,
                              {
                                staticClass: "chip--select",
                                attrs: {
                                  "input-value": selected,
                                  close: "",
                                  color: (item.attributeValue || []).includes(
                                    _vm.errorMapping.missingStoreGroup
                                  )
                                    ? "red"
                                    : "",
                                },
                                on: {
                                  "click:close": function ($event) {
                                    return _vm.deselect(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n            " +
                                    _vm._s(
                                      _vm._f("truncate")(
                                        item.attributeValue,
                                        _vm.truncationLength
                                      )
                                    ) +
                                    "\n          "
                                ),
                              ]
                            )
                          : _vm._e(),
                        _vm._v(" "),
                        index === _vm.maxItemsToList
                          ? _c("span", [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$tc(
                                      "attributes.filters.numValuesSelected",
                                      _vm.selectedAttributeValues.size -
                                        _vm.maxItemsToList
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ])
                          : _vm._e(),
                      ]
                    },
                  },
                  {
                    key: "no-data",
                    fn: function () {
                      return [
                        !_vm.loadingAvailableValues
                          ? _c(
                              "div",
                              [
                                _c(
                                  VAlert,
                                  {
                                    attrs: {
                                      value: true,
                                      color: "error",
                                      icon: "warning",
                                      outlined: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("attributes.filters.noData")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            )
                          : _c(
                              "div",
                              [
                                _c(
                                  VAlert,
                                  {
                                    attrs: {
                                      value: true,
                                      color: "info",
                                      icon: "info",
                                      outlined: "",
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n              " +
                                        _vm._s(
                                          _vm.$t("general.loadingMessage")
                                        ) +
                                        "\n            "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                      ]
                    },
                    proxy: true,
                  },
                  {
                    key: "item",
                    fn: function (props) {
                      return [
                        _c("span", { staticClass: "item-text" }, [
                          _vm._v(
                            "\n            " +
                              _vm._s(props.item.attributeValue) +
                              "\n          "
                          ),
                        ]),
                      ]
                    },
                  },
                ]),
                model: {
                  value: _vm.selectedAttribute.attributeValue,
                  callback: function ($$v) {
                    _vm.$set(_vm.selectedAttribute, "attributeValue", $$v)
                  },
                  expression: "selectedAttribute.attributeValue",
                },
              }),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VFlex,
            { staticClass: "pt-2", attrs: { xs1: "" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "ml-2",
                  attrs: {
                    disabled: _vm.selectedAttribute.disabled,
                    icon: "",
                    small: "",
                    text: "",
                    color: "red darken-3",
                    depressed: "",
                  },
                  on: { click: _vm.removeAttributeFilter },
                },
                [_vm._m(0)],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      {
        staticClass: "font-weight-bold",
        attrs: { color: "red darken-3", size: "2rem" },
      },
      [_vm._v("clear")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }