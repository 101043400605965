import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VChip } from 'vuetify/lib/components/VChip';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        VDialog,
        { attrs: { value: _vm.isOpen, persistent: "", "max-width": "40rem" } },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                { ref: "form" },
                [
                  _c(VCardText, { staticClass: "header-text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.dialogTitle()) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCardText,
                    { staticClass: "body" },
                    [
                      _vm.multipleHierarchyLevels
                        ? _c(VSelect, {
                            attrs: {
                              items: _vm.existingCategories,
                              "item-text": "levelEntryDescription",
                              "return-object": "",
                              dense: "",
                              "single-line": "",
                            },
                            on: { input: _vm.setSelectedGrandparentHierarchy },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-item-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.item.levelEntryDescription
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-label-text",
                                        },
                                        [
                                          _vm._v(
                                            "\n                " +
                                              _vm._s(
                                                _vm.selectHierarchyText(
                                                  _vm.level - 2
                                                )
                                              ) +
                                              "\n              "
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              387398878
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VSelect, {
                        attrs: {
                          items: _vm.existingParentHierarchies,
                          "item-text": "levelEntryDescription",
                          disabled:
                            !_vm.selectedGrandparentHierarchy &&
                            _vm.multipleHierarchyLevels,
                          "return-object": "",
                          dense: "",
                          "single-line": "",
                        },
                        on: { input: _vm.setSelectedParentHierarchy },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (props) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pg-dropdown-item-text" },
                                  [
                                    _vm._v(
                                      _vm._s(props.item.levelEntryDescription)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pg-dropdown-label-text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.selectHierarchyText(_vm.level - 1)
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _c(VSelect, {
                        attrs: {
                          multiple: "",
                          dense: "",
                          "single-line": "",
                          flat: "",
                          "small-chips": "",
                          "hide-details": "",
                          "return-object": "",
                          items: _vm.existingChildHierarchies,
                          "item-text": "levelEntryDescription",
                          disabled: !_vm.selectedParentHierarchy,
                          loading: _vm.loadingEmptyHierarchies,
                          readonly: _vm.loadingEmptyHierarchies,
                        },
                        on: { input: _vm.setSelectedChildHierarchies },
                        scopedSlots: _vm._u([
                          {
                            key: "selection",
                            fn: function ({ item, index, selected }) {
                              return [
                                _c(
                                  VChip,
                                  {
                                    staticClass: "chip--select",
                                    attrs: {
                                      "input-value": selected,
                                      close: "",
                                    },
                                    on: {
                                      "click:close": function ($event) {
                                        return _vm.deselect(item)
                                      },
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(item.levelEntryDescription) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "item",
                            fn: function (props) {
                              return [
                                _c(VCheckbox, {
                                  attrs: {
                                    "input-value":
                                      _vm.selectedChildHierarchies.includes(
                                        props.item
                                      ),
                                  },
                                }),
                                _vm._v(" "),
                                _c(
                                  "span",
                                  {
                                    staticClass:
                                      "pg-dropdown-item-text dropdown-text",
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          props.item.levelEntryDescription
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pg-dropdown-label-text" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.selectHierarchyText(_vm.level)
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                          {
                            key: "no-data",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "dropdown-text no-data-slot" },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm.noEmptyHierarchyText(_vm.level)
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCardActions,
                    { staticClass: "btn-group" },
                    [
                      _c(VSpacer),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "cancel-btn",
                          attrs: { text: "", outlined: "", depressed: "" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(
                        "\n          " +
                          _vm._s(/* AHTDPR-823 TODO: add confirmation with list of things that will be deleted */) +
                          "\n          "
                      ),
                      _c(
                        VBtn,
                        {
                          staticClass: "ok-btn",
                          attrs: { disabled: !_vm.canDelete, depressed: "" },
                          on: { click: _vm.deleteChildHierarchies },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("attributes.editor.actions.delete")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                  _vm._v(
                    "\n        " +
                      _vm._s(/* AHTDPR-823 TODO: get meta errors working, so users can see if deletion failed */) +
                      "\n        "
                  ),
                  _vm.metaErrors.length
                    ? _c(
                        VLayout,
                        { staticClass: "meta-errors" },
                        _vm._l(_vm.metaErrors, function (error, index) {
                          return _c(VLayout, { key: `error-${index}` }, [
                            _vm._v(
                              "\n            " + _vm._s(error) + "\n          "
                            ),
                          ])
                        }),
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }