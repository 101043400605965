import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSpacer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        VDialog,
        {
          attrs: { width: "500", persistent: "" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(
                        _vm.$t("attributes.uploadHierarchy.dialogHeading")
                      ) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "close-button",
                  attrs: { color: "grey" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("close")]
              ),
              _vm._v(" "),
              _vm.isInitial
                ? _c("file-upload", {
                    attrs: {
                      "upload-field-name": _vm.uploadFieldName,
                      "is-saving": _vm.isSaving,
                      "tool-store-group-independent": true,
                      source: "inputs",
                    },
                    on: { save: _vm.save },
                  })
                : _vm._e(),
              _vm._v(" "),
              _vm.isSaving
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c("div", { staticClass: "v-input" }, [
                                _vm._v(
                                  _vm._s(
                                    _vm.$t(
                                      "attributes.uploadHierarchy.uploadingState"
                                    )
                                  )
                                ),
                              ]),
                            ]),
                          ]),
                        ]),
                      ]),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isValidated
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c("div", { staticClass: "flex xs12" }, [
                              _c(
                                "div",
                                { staticClass: "dialog-copy" },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { color: "green darken-2" } },
                                    [_vm._v("check_circle")]
                                  ),
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        _vm.$t(
                                          "attributes.uploadHierarchy.validationSuccessful"
                                        )
                                      ) +
                                      "\n                  "
                                  ),
                                  _c(VFlex, [
                                    _vm._v(
                                      _vm._s(
                                        _vm.$t(
                                          "attributes.uploadHierarchy.confirmUpload"
                                        )
                                      )
                                    ),
                                  ]),
                                ],
                                1
                              ),
                            ]),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "dialog-button",
                              attrs: {
                                color: "primary",
                                outlined: "",
                                depressed: "",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.reset()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.cancel")))]
                          ),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary dialog-button",
                              attrs: {
                                depressed: "",
                                loading: _vm.busyImportingGroup,
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.confirm()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.upload")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.isFailed
                ? _c(
                    "div",
                    [
                      _c(VCardText, [
                        _c("div", { staticClass: "container grid-list-md" }, [
                          _c("div", { staticClass: "layout wrap" }, [
                            _c(
                              "div",
                              { staticClass: "flex xs12" },
                              [
                                _vm.uploadError.tooManyRows
                                  ? _c("div", { staticClass: "flex xs12" }, [
                                      _c("div", { staticClass: "v-input" }, [
                                        _vm._v(
                                          "\n                    " +
                                            _vm._s(
                                              _vm.$t(
                                                "attributes.uploadHierarchy.tooManyRows",
                                                { maxRows: _vm.maxRows }
                                              )
                                            ) +
                                            "\n                  "
                                        ),
                                      ]),
                                    ])
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.rowsWithMissingProductKey.length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.missingProductKey"
                                        ),
                                        "unique-key-prefix":
                                          "missingProductKey",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.productKeysWithoutData.length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.missingData"
                                        ),
                                        details:
                                          _vm.uploadError
                                            .productKeysWithoutData,
                                        "unique-key-prefix": "missingDataFor",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.uploadedProductKeysNotPresent
                                  .length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.invalidProductKey"
                                        ),
                                        details:
                                          _vm.uploadError
                                            .uploadedProductKeysNotPresent,
                                        "unique-key-prefix":
                                          "invalidProductKey",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.duplicateProductKeys.length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.duplicateProductKey"
                                        ),
                                        details:
                                          _vm.uploadError.duplicateProductKeys,
                                        "unique-key-prefix":
                                          "duplicateProductKey",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.productsWithIncorrectCategories
                                  .length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.inconsistentCategory"
                                        ),
                                        details:
                                          _vm.uploadError
                                            .productsWithIncorrectCategories,
                                        "unique-key-prefix":
                                          "inconsistentCategory",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.uploadError.uploadedCategoriesNotPresent
                                  .length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.invalidCategory"
                                        ),
                                        details:
                                          _vm.uploadError
                                            .uploadedCategoriesNotPresent,
                                        "unique-key-prefix": "invalidCategory",
                                      },
                                    })
                                  : _vm._e(),
                                _vm._v(" "),
                                _vm.multipleParentsErrors.length
                                  ? _c("upload-error-card", {
                                      attrs: {
                                        "error-message": _vm.$t(
                                          "attributes.uploadHierarchy.multipleHierarchiesAssigned"
                                        ),
                                        details: _vm.multipleParentsErrors,
                                        "unique-key-prefix": "multipleParent",
                                      },
                                      scopedSlots: _vm._u(
                                        [
                                          {
                                            key: "row",
                                            fn: function ({ item }) {
                                              return [
                                                _c("strong", [
                                                  _vm._v(_vm._s(item.key)),
                                                ]),
                                                _vm._v(
                                                  ": " +
                                                    _vm._s(item.values) +
                                                    "\n                  "
                                                ),
                                              ]
                                            },
                                          },
                                        ],
                                        null,
                                        false,
                                        1538960495
                                      ),
                                    })
                                  : _vm._e(),
                              ],
                              1
                            ),
                          ]),
                        ]),
                      ]),
                      _vm._v(" "),
                      _c(VDivider),
                      _vm._v(" "),
                      _c(
                        VCardActions,
                        [
                          _c(VSpacer),
                          _vm._v(" "),
                          _c(
                            VBtn,
                            {
                              staticClass: "primary",
                              on: {
                                click: function ($event) {
                                  return _vm.closeDialog()
                                },
                              },
                            },
                            [_vm._v(_vm._s(_vm.$t("actions.close")))]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }