<template>
  <highcharts :options="chartOptions" />
</template>

<script>
import { merge, first, get } from 'lodash';
import defaultOptions from '../default-chart-options';

export default {
  props: {
    // highchart.js options object to rewrite default values
    options: {
      type: Object,
      default: () => {},
    },
    priceHistoryWeek: {
      type: Array,
      default: () => [],
    },
    intentionPrice: {
      type: Number,
    },
  },
  computed: {
    values() {
      if (this.intentionPrice) {
        return [...this.priceHistoryWeek, this.intentionPrice];
      }
      return this.priceHistoryWeek;
    },

    chartOptions() {
      return merge({}, defaultOptions, this.yAxisOptions, this.options, {
        series: [{ data: this.values }],
      });
    },

    allValuesEqual() {
      const firstValue = first(this.values);
      return this.values.every(val => val === firstValue);
    },

    yAxisOptions() {
      if (this.allValuesEqual) {
        // straight line, so place it in the middle of the chart
        return {
          yAxis: {
            max: get(this.values, '0', 0) * 2,
          },
        };
      }
      return {
        yAxis: {
          min: Math.min(...this.values) * 0.98,
          max: Math.max(...this.values) * 1.02,
        },
      };
    },
  },
};
</script>
