'use strict';

const _ = require('lodash');
const XLSX = require('xlsx');
const VALIDATION_ERROR = require('../enums/wholesale-import-validation-error');
const { makeGridPageHeadersRow } = require('./export-utils');
const { isNumeric } = require('./validation-utils');

const readXLSXSheetFromArrayBuffer = (rawFileData, sheetName, gridValuesRanges) => {
  const wb = XLSX.read(rawFileData);
  const sheet = wb.Sheets[sheetName];
  if (!sheet) {
    throw new Error(VALIDATION_ERROR.noGridsSheet);
  }

  const table = XLSX.utils.sheet_to_json(sheet, { header: 1 });

  const headers = _.head(table);
  const data = _.tail(table);

  if (!headers) {
    throw new Error(VALIDATION_ERROR.noHeaders);
  }

  const baseHeaders = makeGridPageHeadersRow(gridValuesRanges);

  if (baseHeaders.length < headers.length) {
    throw new Error(VALIDATION_ERROR.badHeaders);
  }

  if (!_.isEqual(baseHeaders, headers)) {
    throw new Error(VALIDATION_ERROR.missingHeaders);
  }

  return data;
};

const makeFullKey = originalKeys => originalKeys.join('|');

const makeGridDescriptionToIdMap = gridList => {
  return _.reduce(
    gridList,
    (acc, item) => {
      acc.set(item.gridDescription, item._id);
      return acc;
    },
    new Map()
  );
};

const reduceGridRowsToMap = gridRows => {
  // Grid rows map description:
  // rows[0..2] -> { values: rows[3..], originalKeys: rows[0..2] }
  return _.reduce(
    gridRows,
    (acc, row) => {
      const originalKeys = row.slice(0, 3);
      const values = row.slice(3);

      // key doesn't matter, should be different for different originalKeys.
      acc.set(makeFullKey(originalKeys), {
        originalKeys,
        values,
      });
      return acc;
    },
    new Map()
  );
};

const validationError = (type, value) => ({ type, value });

const makeBottomUpKeyValidator = srcGridRowsMap => {
  const valdiatorSet = _.reduce(
    Array.from(srcGridRowsMap.values()),
    (acc, { originalKeys }) => {
      for (let i = 0; i < originalKeys.length; i += 1) {
        acc.add(makeFullKey(originalKeys.slice(0, i + 1)));
      }
      return acc;
    },
    new Set()
  );

  return originalKeysToTest => {
    if (!valdiatorSet.has(makeFullKey(originalKeysToTest.slice(0, 1)))) {
      return validationError(VALIDATION_ERROR.unknownGrid, [originalKeysToTest[0]]);
    }

    if (!valdiatorSet.has(makeFullKey(originalKeysToTest.slice(0, 2)))) {
      return validationError(VALIDATION_ERROR.unknownTSG, [originalKeysToTest[1]]);
    }

    if (!valdiatorSet.has(makeFullKey(originalKeysToTest.slice(0, 3)))) {
      return validationError(VALIDATION_ERROR.unknownOwnBrand, [originalKeysToTest[2]]);
    }

    return null;
  };
};

const validateNewGridRowsMap = (srcGridRowsMap, newGridRowsMap) => {
  let err;

  const newKeys = Array.from(newGridRowsMap.keys());

  const validateKey = makeBottomUpKeyValidator(srcGridRowsMap);

  // unknown key in newGridRows
  err = newKeys.find(key => !srcGridRowsMap.has(key));
  if (err) {
    return validateKey(newGridRowsMap.get(err).originalKeys);
  }

  // Wrong length of values row for a key.
  err = newKeys.find(
    key => srcGridRowsMap.get(key).values.length !== newGridRowsMap.get(key).values.length
  );
  if (err) {
    return validationError(
      VALIDATION_ERROR.wrongLengthRowForKey,
      srcGridRowsMap.get(err).originalKeys
    );
  }

  const getBadColumns = values => {
    return values
      .map((cell, index) => {
        if (!isNumeric(cell)) {
          return {
            cellValue: cell,
            column: index,
          };
        }
        return null;
      })
      .filter(error => error);
  };

  let badColumns = [];
  Array.from(newGridRowsMap.values()).forEach(item => {
    // has NaN or negative in a row.
    const singleRowBadColumns = getBadColumns(item.values);
    badColumns = badColumns.concat(singleRowBadColumns);
  });

  if (badColumns.length) {
    return validationError(VALIDATION_ERROR.nanOrNegativeInRowForKey, {
      badColumns,
    });
  }

  return null;
};

const getRowsMapsDiff = ({
  gridDescriptionToIdMap,
  toolStoreGroupsDescriptionMap,
  gridValuesRanges,
}) => {
  const descrToKeyMap = _.invert(toolStoreGroupsDescriptionMap);

  return ({ srcGridRowsMap, newGridRowsMap }) => {
    const diff = new Map();

    Array.from(newGridRowsMap.entries()).forEach(([key, newItem]) => {
      const srcItem = srcGridRowsMap.get(key);

      if (_.isEqual(srcItem.values, newItem.values)) {
        return;
      }

      const [documentDescription, storeGroupKey, priceBrandCategory] = newItem.originalKeys;
      const id = gridDescriptionToIdMap.get(documentDescription);

      const arr = diff.has(id) ? diff.get(id) : [];

      const newGridValues = _.map(_.zip(gridValuesRanges, newItem.values), pairs => ({
        upperLimit: pairs[0].upperLimit,
        value: pairs[1],
      }));

      arr.push({
        storeGroupKey: _.get(descrToKeyMap, storeGroupKey, storeGroupKey),
        priceBrandCategory,
        values: newGridValues,
      });
      diff.set(id, arr);
    });

    return Object.fromEntries(diff);
  };
};

/**
 * Generates a mapping of mainbanner keys based on master workpackages information and hardcoded store groups.
 * @param {Object} param0 An object containing the masterWorkpackagesInfoList and allHardcodedStoreGroups.
 * @returns {Object} A mapping where the key is the ID of a master workpackage and the value is the corresponding mainbanner's toolStoreGroupKey.
 */
const getMainbannerKeysMap = ({ masterWorkpackagesInfoList, allHardcodedStoreGroups }) => {
  return masterWorkpackagesInfoList.reduce((acc, { _id: wpId, description: workpackage }) => {
    const mainbanner = _.get(allHardcodedStoreGroups, workpackage, []).find(
      storegroup => storegroup.parentPricingStoreGroupKey === null
    );

    if (!mainbanner) {
      return acc;
    }

    acc[wpId] = mainbanner.toolStoreGroupKey;
    return acc;
  }, {});
};

/**
 * Generates a mapping of hardcoded store groups keys based on master workpackages information.
 * @param {Object} param0 An object containing the masterWorkpackagesInfoList and allHardcodedStoreGroups.
 * @returns {Object} A mapping where the key is the ID of a master workpackage and the value is an array of toolStoreGroupKeys corresponding to the hardcoded store groups associated with that workpackage.
 */
const getHardcodedStoreGroupsKeysMap = ({
  masterWorkpackagesInfoList,
  allHardcodedStoreGroups,
}) => {
  return masterWorkpackagesInfoList.reduce((acc, { _id: wpId, description: workpackage }) => {
    acc[wpId] = _.get(allHardcodedStoreGroups, workpackage, []).map(
      storegroup => storegroup.toolStoreGroupKey
    );
    return acc;
  }, {});
};

/**
 * Finds new store groups added to grids and their mainbanners based on master workpackages information and gridList.
 * @param {Object} param0 An object containing masterWorkpackagesInfoList, allHardcodedStoreGroups, and gridList.
 * @returns {Object} A mapping where the key is the ID of a grid document and the value is an object containing newStoreGroupsList (list of new store groups added to the grid) and mainbanner (mainbanner associated with the grid).
 */
const findNewStoreGroups = ({ masterWorkpackagesInfoList, allHardcodedStoreGroups, gridList }) => {
  const mainbannerKeysMap = getMainbannerKeysMap({
    masterWorkpackagesInfoList,
    allHardcodedStoreGroups,
  });

  const hardcodedStoreGroupsKeysMap = getHardcodedStoreGroupsKeysMap({
    masterWorkpackagesInfoList,
    allHardcodedStoreGroups,
  });

  return gridList
    .map(gridDoc => {
      const hardcodedStoreGroupsKeys = _.get(
        hardcodedStoreGroupsKeysMap,
        gridDoc.workpackageId,
        []
      );
      const existingStoreGroupsKeys = _.keys(_.get(gridDoc, 'storeGroupGrids', {}));

      const newStoreGroupsList = _.difference(hardcodedStoreGroupsKeys, existingStoreGroupsKeys);

      const mainbanner = _.get(
        gridDoc,
        ['storeGroupGrids', mainbannerKeysMap[gridDoc.workpackageId]],
        null
      );

      return {
        gridDocId: gridDoc._id,
        workpackageId: gridDoc.workpackageId,
        newStoreGroupsList,
        mainbanner,
      };
    })
    .filter(diffObj => diffObj.newStoreGroupsList.length)
    .reduce((acc, diffItem) => {
      acc[diffItem.gridDocId] = _.pick(diffItem, [
        'newStoreGroupsList',
        'mainbanner',
        'workpackageId',
      ]);
      return acc;
    }, {});
};

module.exports = {
  readXLSXSheetFromArrayBuffer,
  makeGridDescriptionToIdMap,
  reduceGridRowsToMap,
  validateNewGridRowsMap,
  getRowsMapsDiff,
  getMainbannerKeysMap,
  getHardcodedStoreGroupsKeysMap,
  findNewStoreGroups,
};
