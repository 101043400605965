import { VBtn } from 'vuetify/lib/components/VBtn';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "input-screen-page-wrapper",
      staticStyle: { width: "100%", height: "100%" },
    },
    [
      _c("inputs-grid", {
        ref: "inputsGrid",
        attrs: {
          "additional-columns": _vm.additionalColumns,
          "additional-product-columns": _vm.additionalProductColumns,
          "additional-grid-options": _vm.additionalGridOptions,
          "save-changes": _vm.saveChanges,
          "export-action": _vm.downloadItems,
          "is-exporting": _vm.downloadingItems,
        },
        scopedSlots: _vm._u([
          {
            key: "buttons",
            fn: function () {
              return [
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.displayAddCompetitorButton } },
                  [
                    _c(
                      VBtn,
                      {
                        attrs: { color: "primary", small: "", depressed: "" },
                        on: {
                          click: function ($event) {
                            return _vm.openCompetitorCreateUpdateDialog(
                              _vm.dialogModes.create
                            )
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n          " +
                            _vm._s(_vm.$t("actions.add")) +
                            "\n        "
                        ),
                      ]
                    ),
                  ],
                  1
                ),
                _vm._v(" "),
                _c(
                  "feature-toggle",
                  { attrs: { toggle: _vm.displayCompetitorUpload } },
                  [
                    _c("competitor-upload", {
                      attrs: {
                        "enabled-value-translations":
                          _vm.enabledValueTranslations,
                        "is-master-workpackage":
                          _vm.isSelectedWorkpackageMaster,
                      },
                    }),
                  ],
                  1
                ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      _c("competitor-create-update-dialog", {
        key: _vm.competitorCreateDialogKey,
        attrs: {
          "is-open": _vm.isCompetitorCreateUpdateDialogOpen,
          mode: _vm.mode,
          competitor: _vm.competitorToEdit,
        },
        on: { closeDialog: _vm.closeCompetitorCreateUpdateDialog },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }