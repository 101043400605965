import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VProgressLinear } from 'vuetify/lib/components/VProgressLinear';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "expansion margin-splits-expansion" },
    [
      _c("div", { staticClass: "margin-splits-expansion-header" }, [
        _vm._v(_vm._s(_vm.$tc("wholesale.split", 2))),
      ]),
      _vm._v(" "),
      _c(VTextField, {
        staticClass: "search-field px-2",
        attrs: {
          value: _vm.marginSplitsSearch,
          "append-icon": "search",
          label: _vm.$t("actions.search"),
          "single-line": "",
          flat: "",
          disabled: _vm.wholesaleMarginSplitsLoading,
        },
        on: { input: _vm.debounceSearchUpdate },
      }),
      _vm._v(" "),
      _c(
        VList,
        {
          staticClass: "py-0 margin-splits-expansion-list",
          attrs: { dense: "" },
        },
        [
          _vm.wholesaleMarginSplitsLoading
            ? [_c("basic-spinner", { staticClass: "spinner-wrapper" })]
            : _vm.noSearchResult
            ? [
                _c(
                  VListItem,
                  { staticClass: "splits-list-item no-results" },
                  [
                    _c(
                      VListItemContent,
                      [
                        _c(VListItemTitle, [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("product.noResults", {
                                  search: _vm.marginSplitsSearch,
                                })
                              ) +
                              "\n          "
                          ),
                        ]),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ]
            : _vm._l(_vm.filteredGrids, function ({ gridDescription, gridId }) {
                return _c(
                  "div",
                  { key: gridId, staticClass: "list-item-div" },
                  [
                    _c(
                      VListItem,
                      {
                        staticClass: "splits-list-item",
                        class: { clicked: _vm.isGridHighlighted(gridId) },
                      },
                      [
                        _c(
                          VListItemContent,
                          [
                            _c(
                              VListItemTitle,
                              [
                                _c("tooltipped-truncated-field", {
                                  staticClass: "tooltipped-truncated-field",
                                  class: _vm.highlightLoading ? "disabled" : "",
                                  attrs: {
                                    text: gridDescription,
                                    "truncation-length": _vm.truncationLength,
                                    "tooltip-position": "left",
                                  },
                                  on: {
                                    click: function ($event) {
                                      _vm.highlightLoading
                                        ? () => {}
                                        : _vm.setGridIdToHighlight(gridId)
                                    },
                                  },
                                }),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _vm.highlightLoading &&
                            _vm.gridIdToHighlight === gridId
                              ? _c(VProgressLinear, {
                                  attrs: { indeterminate: "" },
                                })
                              : _vm._e(),
                          ],
                          1
                        ),
                        _vm._v(" "),
                        _c(
                          VListItemAvatar,
                          { staticClass: "splits-list-item-avatar" },
                          [
                            _c("i", {
                              staticClass:
                                "mdi mdi-pencil-outline splits-list-item-icon clickable",
                              on: {
                                click: function ($event) {
                                  return _vm.openEditWholesaleGridDialog(
                                    gridId,
                                    gridDescription
                                  )
                                },
                              },
                            }),
                          ]
                        ),
                      ],
                      1
                    ),
                  ],
                  1
                )
              }),
        ],
        2
      ),
      _vm._v(" "),
      _vm.selectedGrid
        ? _c("wholesale-grid-dialog", {
            attrs: {
              "grid-details": _vm.selectedGrid,
              "is-open": _vm.isEditWholesaleGridDialogOpened,
            },
            on: { closeDialog: _vm.closeWholesaleGridDialog },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }