<!--
Wrapper component to handle hiding/displaying other HTML components
Prop toggle: a string from feature-flags.js enums, matching the keys of toggle-logic.js
-->
<template>
  <span v-if="toggleDisplay()" style="display: contents;">
    <slot />
  </span>
</template>

<script>
import { mapState } from 'vuex';

export default {
  props: {
    // key of the toggle feature in toggleLogic
    toggle: {
      type: String,
      required: true,
    },
  },

  computed: {
    ...mapState('clientConfig', ['toggleLogic']),
  },

  methods: {
    toggleDisplay() {
      /**
       * Return the (boolean cast) value of a feature decision.
       */
      return !!this.toggleLogic[this.toggle];
    },
  },
};
</script>
