import axios from 'axios';
import to from 'await-to-js';
import { isEmpty, get, groupBy, map, reverse, round, find, some, set } from 'lodash';
import moment from 'moment';
import downloadXlsxFile from '../utils/download-xlsx-file';
import { formatFilters } from '../utils/filters';
import { dayMonthYearFormat } from '@enums/date-formats';

const getInitialState = () => {
  return {
    architectureProducts: [],
    architectureProductsLoading: false,
    architectureProductsCount: 0,
    architectureProductsLoaded: false,
    filteredAttributes: [],
    linePricingGroups: [],
    linePricingGroupForExpandedProduct: {},
    loading: false, // is not shared for all actions! there is architectureProductsLoading too
    activeLinePricingGroupIndex: null,
    selectedProducts: [],
    finalImpactUpdateAvailable: false,
    // note newTab state needs to be in localStorage to work. see webtool/client/js/store/index.js VuexPersist
    newTabOpenLinePricingRules: false,
    newTabOpenArchitectureRules: false,
    newTabActiveLinePricingGroupIndex: null,
    newTabOpenArchitectureSubGroupSplittingAttributes: null,
    downloadingLinePricing: false,
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    linePricingArchitectureProducts: state =>
      state.architectureProducts.map(product => ({
        _id: get(product, '_id'),
        productKey: get(product, 'article.productKey'),
        productKeyDisplay: get(product, 'article.productKeyDisplay'),
        toolStoreGroupKey: get(product, 'toolStoreGroupKey'),
        toolStoreGroupDescription: get(product, 'toolStoreGroupDescription'),
        description: get(product, 'article.productDescription'),
        size: get(product, 'article.productSizeType'),
        livePrice: get(product, 'livePrice'),
        intentionCost: get(product, 'intentionCost'),
        packageTypeDescription: get(product, 'attributes.packageTypeDescription'),
        normWeight: get(product, 'attributes.normWeight'),
        normWeightUnitOfMeasure: get(product, 'attributes.normWeightUnitOfMeasure'),
      })),
    hasMultipleSubGroups: (state, getters, rootState, rootGetters) => products => {
      const subGroups = new Set();
      return some(products, product => {
        subGroups.add(
          rootGetters['hierarchy/getSubGroupsForProduct']({
            productKey: product.productKey,
            toolStoreGroupKey: product.toolStoreGroupKey,
          })
        );
        return subGroups.size > 1;
      });
    },
  },

  mutations: {
    setProducts(state, { architectureProducts }) {
      state.architectureProducts = architectureProducts;
    },

    setProductsCount(state, { count }) {
      state.architectureProductsCount = count;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setArchitectureProductsLoading(state, loading) {
      state.architectureProductsLoading = loading;
    },

    setArchitectureProductsLoaded(state, loadingState) {
      state.architectureProductsLoaded = loadingState;
    },

    setFilteredAttributes(state, { filteredAttributes }) {
      state.filteredAttributes = filteredAttributes;
    },

    setLinePricingGroups(state, { linePricingGroups }) {
      state.linePricingGroups = linePricingGroups;
    },

    setActiveLinePricingGroupIndex(state, { activeLinePricingGroupIndex }) {
      state.activeLinePricingGroupIndex = activeLinePricingGroupIndex;
    },

    setSelectedProducts(state, products) {
      state.selectedProducts = products;
    },

    addSelectedProduct(state, product) {
      state.selectedProducts.push(product);
    },

    removeSelectedProduct(state, product) {
      state.selectedProducts.splice(state.selectedProducts.indexOf(product), 1);
    },

    setFinalImpactUpdateAvailable(state, isAvailable) {
      state.finalImpactUpdateAvailable = isAvailable;
    },

    setNewTabOpenLinePricingRules(state, newTabOpenLinePricingRules) {
      state.newTabOpenLinePricingRules = newTabOpenLinePricingRules;
    },

    setNewTabOpenArchitectureRules(state, newTabOpenArchitectureRules) {
      state.newTabOpenArchitectureRules = newTabOpenArchitectureRules;
    },

    setNewTabActiveLinePricingGroupIndex(state, newTabActiveLinePricingGroupIndex) {
      state.newTabActiveLinePricingGroupIndex = newTabActiveLinePricingGroupIndex;
    },

    setNewTabOpenArchitectureSubGroupSplittingAttributes(state, architectureSubGroupDescription) {
      state.newTabOpenArchitectureSubGroupSplittingAttributes = architectureSubGroupDescription;
    },

    setLinePricingGroupForExpandedProduct(state, lpg) {
      state.linePricingGroupForExpandedProduct = lpg;
    },

    setDownloadingLinePricing(state, downloading) {
      state.downloadingLinePricing = downloading;
    },

    resetState(state) {
      Object.assign(state, getInitialState());
    },
  },

  actions: {
    setNewTabOpenLinePricingRules({ commit }, newTabOpenLinePricingRules) {
      commit('setNewTabOpenLinePricingRules', newTabOpenLinePricingRules);
    },

    setNewTabOpenArchitectureRules({ commit }, newTabOpenArchitectureRules) {
      commit('setNewTabOpenArchitectureRules', newTabOpenArchitectureRules);
    },

    setNewTabActiveLinePricingGroupIndex({ commit }, newTabActiveLinePricingGroupIndex) {
      commit('setNewTabActiveLinePricingGroupIndex', newTabActiveLinePricingGroupIndex);
    },

    setNewTabOpenArchitectureSubGroupSplittingAttributes(
      { commit },
      architectureSubGroupDescription
    ) {
      commit(
        'setNewTabOpenArchitectureSubGroupSplittingAttributes',
        architectureSubGroupDescription
      );
    },

    resetNewTabState({ commit }) {
      commit('setNewTabOpenLinePricingRules', null);
      commit('setNewTabOpenArchitectureRules', null);
      commit('setNewTabActiveLinePricingGroupIndex', null);
      commit('setNewTabOpenArchitectureSubGroupSplittingAttributes', null);
    },

    async consumeNewTabOpenLinePricingRules({ state, dispatch }) {
      const newTabOpenLinePricingRules = state.newTabOpenLinePricingRules;
      await dispatch('setNewTabOpenLinePricingRules', null);
      return newTabOpenLinePricingRules;
    },

    async consumeNewTabOpenArchitectureRules({ state, dispatch }) {
      const newTabOpenArchitectureRules = state.newTabOpenArchitectureRules;
      await dispatch('setNewTabOpenArchitectureRules', null);
      return newTabOpenArchitectureRules;
    },

    async consumeNewTabActiveLinePricingGroupIndex({ state, dispatch }) {
      const newTabActiveLinePricingGroupIndex = state.newTabActiveLinePricingGroupIndex;
      await dispatch('setNewTabActiveLinePricingGroupIndex', null);
      return newTabActiveLinePricingGroupIndex;
    },

    async consumeNewTabOpenArchitectureSubGroupSplittingAttributes({ state, dispatch }) {
      const newTabOpenArchitectureSubGroupSplittingAttributes =
        state.newTabOpenArchitectureSubGroupSplittingAttributes;
      await dispatch('setNewTabOpenArchitectureSubGroupSplittingAttributes', null);
      return newTabOpenArchitectureSubGroupSplittingAttributes;
    },

    async updateFinalImpacts({ commit, rootState, state }) {
      const architectureProducts = state.architectureProducts;
      const [err, response] = await to(
        axios.patch(
          `/api/architecture-drivers/workpackage/${
            rootState.workpackages.selectedWorkpackage._id
          }/update-product-final-impacts/${rootState.filters.architectureGroup}/${
            rootState.filters.scenario
          }`,
          { architectureProducts }
        )
      );
      if (err) {
        throw new Error(err.message);
      }

      commit('setFinalImpactUpdateAvailable', false);
      return response.data;
    },

    calculateFinalImpact(
      { commit, state },
      { productKey, toolStoreGroupKey, impactFactor, offset }
    ) {
      const architectureProducts = state.architectureProducts;
      const finalImpact = impactFactor * offset;

      const architectureProduct = find(
        architectureProducts,
        product =>
          product.article.productKey === productKey &&
          product.toolStoreGroupKey === toolStoreGroupKey
      );
      architectureProduct.productImpact.impactFactorFinal = round(finalImpact, 3);
      architectureProduct.productImpact.impactFactorCustomOffset = round(offset, 2);

      commit('setProducts', { architectureProducts });
      commit('setFinalImpactUpdateAvailable', true);
    },

    async fetchArchitectureProducts({ commit, rootState }) {
      const architectureGroupId = rootState.filters.architectureGroup;
      const scenarioKey = rootState.filters.scenario;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      // architecture products should be fetched only when both architecture group and scenario selected
      if (!architectureGroupId || !scenarioKey) return;

      commit('setFinalImpactUpdateAvailable', true);
      commit('setArchitectureProductsLoading', true);

      const [err, { data: architectureProducts }] = await to(
        axios.get(
          `/api/workpackage-product/workpackage/${workpackageId}/architecture-group/${architectureGroupId}/${scenarioKey}`
        )
      );
      if (err) {
        commit('setArchitectureProductsLoading', false);
        throw new Error(err.message);
      }

      commit('setProducts', { architectureProducts });
      commit('setProductsCount', { count: architectureProducts.length });
      commit('setFinalImpactUpdateAvailable', false);
      commit('setArchitectureProductsLoaded', true);
      commit('setArchitectureProductsLoading', false);
    },

    async getFilteredAttributes(
      { commit, rootState },
      { params = {}, additionalFilters = {} } = {}
    ) {
      commit('setLoading', true);

      const { where } = params;

      if (isEmpty(where)) {
        commit('setFilteredAttributes', { filteredAttributes: [] });
        commit('setLoading', false);
        return;
      }

      params.where = formatFilters({ where, rootState });
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      if (additionalFilters.architectureGroupId) {
        set(params, 'additionalFilters.architectureGroupId', additionalFilters.architectureGroupId);
      }

      const [err, { data: filteredAttributes }] = await to(
        axios.get(`/api/attributes/workpackage/${workpackageId}`, { params })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      commit('setFilteredAttributes', { filteredAttributes });
      commit('setLoading', false);
    },

    async getLinePricingGroupForProduct({ rootState, commit }, { productKey, toolStoreGroupKey }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const architectureGroupId = rootState.filters.architectureGroup;
      const scenarioKey = rootState.filters.scenario;
      const params = {
        architectureGroupId,
        toolStoreGroupKey,
      };
      const [err, res] = await to(
        axios.get(
          `/api/line-priced-products/workpackage/${workpackageId}/scenario/${scenarioKey}/product/${productKey}`,
          {
            params,
          }
        )
      );
      if (err) {
        throw new Error(err.message);
      }
      commit('setLinePricingGroupForExpandedProduct', res.data);
      return res.data;
    },

    async fetchLinePricingGroupsWithProducts({ commit, rootState, dispatch }) {
      const architectureGroupId = rootState.filters.architectureGroup;
      const scenarioKey = rootState.filters.scenario;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      // line pricing groups and their products should be fetched only when both architecture group and scenario selected
      if (!architectureGroupId || !scenarioKey) return;

      commit('setLoading', true);

      const params = {
        where: {
          architectureGroupId,
          scenarioKey,
        },
      };

      const [err, response] = await to(
        axios.get(`/api/line-pricing-groups/workpackage/${workpackageId}`, { params })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      const { data: linePricingGroups } = response;
      const products = await dispatch('fetchLinePricedProducts', {
        linePricingGroups,
        scenarioKey,
      });

      const linePricedProductsByGroup = groupBy(products, 'linePricingGroupKey');
      const linePricingGroupsWithProducts = map(linePricingGroups, linePricingGroup => {
        const linePricedProducts = linePricedProductsByGroup[linePricingGroup.linePricingGroupKey];
        // If there are no product an empty array must return
        return {
          ...linePricingGroup,
          products: !linePricedProducts ? [] : linePricedProducts,
        };
      });

      commit('setLinePricingGroups', {
        linePricingGroups: reverse(linePricingGroupsWithProducts),
      });
      commit('setLoading', false);
    },

    async fetchLinePricedProducts(
      { commit, state, rootState },
      { linePricingGroups = [], scenarioKey } = {}
    ) {
      const architectureGroupId = rootState.filters.architectureGroup;
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      if (!architectureGroupId || !linePricingGroups.length || !scenarioKey) {
        return Promise.resolve([]);
      }

      // get all products for the selected groups
      const linePricedProductsParams = {
        where: {
          linePricingGroupKeys: linePricingGroups.map(i => i.linePricingGroupKey),
        },
      };

      // TODO: check why this uses both line-priced-products and product-impact
      const [err, { data: linePricedProducts }] = await to(
        axios.get(`/api/line-priced-products/workpackage/${workpackageId}/${scenarioKey}`, {
          params: linePricedProductsParams,
        })
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      // join both line pricing group and product together
      return linePricedProducts.reduce((result, linePricedProduct) => {
        const product = state.architectureProducts.find(
          p =>
            p.article.productKey === linePricedProduct.productKey &&
            p.toolStoreGroupKey === linePricedProduct.toolStoreGroupKey
        );
        if (!product) {
          // fixes line pricing groups display for old work packages
          // there are line pricing groups containing products
          // that no longer belong to the corresponding architecture groups
          return result;
        }
        result.push({
          _id: get(linePricedProduct, '_id'),
          linePricingGroupKey: get(linePricedProduct, 'linePricingGroupKey'),
          linePriceFactor: get(linePricedProduct, 'linePriceFactor'),
          productKey: get(linePricedProduct, 'productKey'),
          productKeyDisplay: get(linePricedProduct, 'productKeyDisplay'),
          toolStoreGroupKey: get(linePricedProduct, 'toolStoreGroupKey'),
          toolStoreGroupDescription: get(product, 'toolStoreGroupDescription'),
          description: get(product, 'article.productDescription'),
          size: get(product, 'article.productSizeType'),
          livePrice: get(product, 'livePrice'),
          intentionCost: get(product, 'intentionCost'),
          packageTypeDescription: get(product, 'attributes.packageTypeDescription'),
          scenarioKey: get(linePricedProduct, 'scenarioKey'),
        });
        return result;
      }, []);
    },

    setLinePricingGroupForExpandedProduct({ commit }, lpg) {
      commit('setLinePricingGroupForExpandedProduct', lpg);
    },

    setActiveLinePricingGroupIndex({ commit }, { activeLinePricingGroupIndex }) {
      commit('setActiveLinePricingGroupIndex', { activeLinePricingGroupIndex });
    },

    async saveLinePricingGroupsWithProducts(
      { commit, dispatch, rootState },
      { linePricingGroups = {} } = {}
    ) {
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.post(
          `/api/line-pricing-groups/workpackage/${workpackageId}/with-products`,
          linePricingGroups
        )
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      commit('setLoading', false);
      dispatch('fetchLinePricingGroupsWithProducts');
    },

    async deleteLinePricingGroup({ commit, dispatch, rootState }, linePricingGroupKey) {
      if (!linePricingGroupKey) return;
      commit('setLoading', true);

      // deletes by (linePricingGroupKey, sceanrioKey, workpackageId)
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const scenarioKey = rootState.filters.scenario;
      const params = {
        where: {
          scenarioKey,
          linePricingGroupKey,
        },
      };

      const [[linePricingError], [productsError]] = await Promise.all([
        to(axios.delete(`/api/line-pricing-groups/workpackage/${workpackageId}`, { params })),
        to(axios.delete(`/api/line-priced-products/workpackage/${workpackageId}`, { params })),
      ]);

      const err = linePricingError || productsError;

      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      commit('setLoading', false);
      dispatch('fetchLinePricingGroupsWithProducts');
    },

    async deleteLinePricedProduct({ commit }, { linePricedProduct = {} } = {}) {
      commit('setLoading', true);

      const { _id } = linePricedProduct;

      if (!_id) {
        commit('setLoading', false);
        return;
      }

      const [err] = await to(axios.delete(`/api/line-priced-products/${_id}`));
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }

      commit('setLoading', false);
    },

    addSelectedProduct({ commit }, { product }) {
      commit('addSelectedProduct', product);
    },

    setSelectedProducts({ commit }, { products }) {
      commit('setSelectedProducts', products);
    },

    removeSelectedProduct({ commit }, { product }) {
      commit('removeSelectedProduct', product);
    },

    clearSelectedProducts({ commit }) {
      commit('setSelectedProducts', []);
    },

    resetState({ commit }) {
      commit('resetState');
    },

    addExportLinePricingNotification({ commit }, { selectedWorkpackage, jobStatus }) {
      const jobId = 'export_line_pricing';
      const baseTranslation = `notifications.${jobId}`;
      const notification = {
        id: `${jobId}:${jobStatus}`,
        jobStatus,
        notificationPayload: selectedWorkpackage.description,
        baseTranslation,
      };
      commit('notifications/addNotification', notification, { root: true });
    },

    async downloadLinePricing(
      { rootState, commit, dispatch },
      { translationMap, architectureGroupId }
    ) {
      commit('setDownloadingLinePricing', true);
      const selectedWorkpackage = rootState.workpackages.selectedWorkpackage;
      const baseURL = '/api/line-pricing-export';
      const genericURL = `/workpackage/${selectedWorkpackage._id}/line-pricing-results`;
      const url = architectureGroupId
        ? `${baseURL}/ag${genericURL}`
        : `${baseURL}/all${genericURL}`;
      const params = architectureGroupId
        ? { translationMap, architectureGroupId }
        : { translationMap };
      const [err, { data: linePricingData }] = await to(axios.get(url, { params }));
      if (err) {
        dispatch('addExportLinePricingNotification', {
          selectedWorkpackage,
          jobStatus: 'failed',
        });
        commit('setDownloadingLinePricing', false);
        throw new Error(err.message);
      }
      if (isEmpty(linePricingData)) {
        dispatch('addExportLinePricingNotification', {
          selectedWorkpackage,
          jobStatus: 'no_results',
        });
        commit('setDownloadingLinePricing', false);
        return;
      }
      const timestamp = moment().format(dayMonthYearFormat);
      downloadXlsxFile(linePricingData, `linepricing_extract_${timestamp}.xlsx`);
      dispatch('addExportLinePricingNotification', {
        selectedWorkpackage,
        jobStatus: 'finished',
      });
      commit('setDownloadingLinePricing', false);
    },
  },
};

export default store;
