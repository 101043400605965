<template>
  <v-layout row justify-center>
    <v-dialog v-model="isOpen" :content-class="dialogClass" persistent max-width="40rem">
      <v-card>
        <v-card-title class="headline">
          <slot name="header" />
        </v-card-title>
        <v-card-text class="body">
          <slot name="body" />
        </v-card-text>
        <v-card-actions class="btn-group">
          <v-spacer />
          <v-btn class="cancel-btn" text outlined depressed @click="emitCancel">{{
            cancelBtnText
          }}</v-btn>
          <v-btn class="ok-btn" :disabled="disableOkButton" depressed @click="emitOk">{{
            okBtnText
          }}</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-layout>
</template>

<script>
export default {
  props: {
    isOpen: {
      type: Boolean,
      required: true,
    },
    okBtnText: {
      type: String,
      default() {
        return this.$t('actions.ok');
      },
    },
    cancelBtnText: {
      type: String,
      default() {
        return this.$t('actions.cancel');
      },
    },
    disableOkButton: {
      type: Boolean,
      default: () => false,
    },
    isMaxHeightFixed: {
      type: Boolean,
      default: () => false,
    },
  },
  computed: {
    dialogClass() {
      return this.isMaxHeightFixed ? 'alert-dialog fixed-dialog-height' : 'alert-dialog';
    },
  },
  methods: {
    emitOk() {
      this.$emit('onOk');
    },

    emitCancel() {
      this.$emit('onCancel');
    },
  },
};
</script>

<style lang="scss">
.fixed-dialog-height {
  max-height: 40rem !important;
}
</style>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.row {
  // needed for cases where this is rendered in a table row, e.g. grid-view-scenario-selector.vue
  display: none;
}

.v-card {
  .body.v-card__text {
    font-size: 1.4rem;
    margin-bottom: 7rem;
  }

  .btn-group.v-card__actions {
    .cancel-btn {
      color: $btn-cancel-color;
      border-color: $btn-cancel-color;
    }

    .ok-btn {
      color: $pricing-white;
      background-color: $btn-ok-color;
      border-color: $btn-ok-color;
    }
  }
}

.v-btn {
  font-size: 1.5rem;
}
</style>
