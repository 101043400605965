<template>
  <!-- Don't remove hide-overlay. Overlay steals some touchpad events. -->
  <!-- If there is no data for vertical scrolling, horizontal scrolling will not work either. -->
  <!-- Keep persistent so that the dialog won't be closed by clicking on upload dialog -->
  <v-dialog max-width="90%" persistent no-click-animation hide-overlay :value="isOpen">
    <v-card class="wholesale-grid-card">
      <v-card-title class="justify-space-between">
        <span class="grid-description">
          {{ isNewGrid ? $t('wholesale.gridTable.newSplitSetup') : gridDetails.gridDescription }}
        </span>
        <i
          aria-hidden="true"
          class="mdi mdi-close-circle cancel-button clickable"
          @click="stopEditing()"
        />
      </v-card-title>

      <v-divider class="mb-2" />

      <v-card-text>
        <div v-if="isNewGrid" class="d-flex align-center new-grid-description-wrapper">
          <v-text-field
            v-model="newGridDescription"
            :rules="gridDescriptionRules"
            :disabled="saveWholesaleGridLoading"
            :loading="saveWholesaleGridLoading"
            class="ma-0 pa-0"
            height="2rem"
            single-line
            @update:error="newGridDescriptionInvalid = $event"
          >
            <template v-slot:prepend>
              <span class="title-label"> {{ $t('wholesale.gridTable.title') }}: </span>
            </template>
          </v-text-field>
        </div>
        <ag-grid-vue
          style="width: 100%; height: 400px;"
          class="ag-theme-custom ag-wholesale-grid"
          :grid-options="gridOptions"
          :column-defs="columnDefs"
          :row-data="rowData"
          :default-col-def="defaultColDef"
          :components="components"
          :suppress-row-transform="true"
          @grid-ready="onGridReady"
        />
      </v-card-text>

      <v-divider />

      <v-card-actions>
        <v-spacer />
        <v-row no-gutters class="full-width">
          <v-col cols="4" class="d-flex justify-space-around">
            <wholesale-grid-upload
              :is-new-grid="isNewGrid"
              :grid-id="gridDetails.gridId"
              @onUploaded="updateRowData"
            />
            <v-btn
              color="primary"
              small
              class="mr-3 pa-0"
              depressed
              :disabled="wholesaleGridLoading || saveWholesaleGridLoading || isNewGrid"
              @click="exportGrid"
            >
              {{ $t('actions.download') }}
              <v-icon small>$export</v-icon>
            </v-btn>
          </v-col>
          <v-col cols="8" class="d-flex justify-end">
            <v-btn
              color="success"
              small
              class="save"
              depressed
              :loading="saveWholesaleGridLoading"
              :disabled="isSaveDisabled || wholesaleGridLoading || saveWholesaleGridLoading"
              @click="saveWholesaleGrid"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-col>
        </v-row>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import _ from 'lodash';
/* eslint-disable-next-line */
import { AgGridVue } from 'ag-grid-vue';
import { mapActions, mapState, mapGetters } from 'vuex';
import numberFormats from '@enums/number-formats';
import { descending } from '@enums/sort-direction';
import agGridUtils from '../../../utils/ag-grid-utils';
import { getExportFileName, exportGrid } from '@sharedModules/data/utils/export-utils';
import { getCompareInsideToolStoreGroupFunc } from '../../../utils/sort-store-groups-util';
import agGridChevronIcon from '../../../components/ag-grid-cell-renderers/ag-grid-chevron-icon.vue';

const columnType = {
  offsetColumn: 'customOffsetColumn',
  gridValueColumn: 'gridValueColumn',
};

export default {
  components: {
    AgGridVue,
    // eslint thinks that it's not used, but headerComponent points to it
    // eslint-disable-next-line vue/no-unused-components
    agGridChevronIcon,
  },
  props: {
    gridDetails: {
      type: Object,
      required: true,
    },
    isOpen: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      gridOptions: null,
      gridApi: null,
      gridColumnApi: null,
      columnApi: null,
      columnDefs: null,
      storeGroupGrids: null,
      rowData: null,
      defaultColDef: null,
      components: null,
      errors: {},
      updates: {},
      expandedMainPricingStoreGroups: new Set(),
      gridDescriptionRules: [this.gridDescriptionRequired, this.isUniqueGridDescription],
      newGridDescription: '',
      newGridDescriptionInvalid: true,
      isNewGrid: null,
    };
  },
  computed: {
    ...mapState('wholesaleGrid', ['wholesaleGridLoading', 'saveWholesaleGridLoading']),
    ...mapState('wholesale', ['gridIdDescriptionMap']),
    ...mapState('workpackages', ['selectedWorkpackageToolStoreGroups']),
    ...mapState('clientConfig', ['wholesaleConfig', 'storeGroupOrderConfig', 'exportConfigs']),
    ...mapState('storeGroupRelationships', ['storeGroupRelationships']),
    ...mapGetters('storeGroupRelationships', [
      'hardcodedStoreGroups',
      'hackedSelectedWorkpackageToolStoreGroups',
    ]),
    ...mapGetters('clientConfig', ['getHardcodedStoreGroupOrder']),

    existingGridDescriptions() {
      return _.values(this.gridIdDescriptionMap);
    },

    isSaveDisabled() {
      return (
        !_.isEmpty(this.errors) ||
        (!this.isNewGrid && _.isEmpty(this.updates)) ||
        (this.isNewGrid && this.newGridDescriptionInvalid)
      );
    },

    // PRICE-2315 tmp hack for dzh.
    // TODO: remove these and revert to original values provided by vuex
    hackedSelectedWorkpackageToolStoreGroups() {
      if (_.isEmpty(this.hardcodedStoreGroups)) return this.selectedWorkpackageToolStoreGroups;

      return this.hardcodedStoreGroups.map(v => {
        return {
          description: v.toolStoreGroupDescription,
          key: v.toolStoreGroupKey,
          parentPricingStoreGroupKey: v.parentPricingStoreGroupKey,
        };
      });
    },
    hackedStoreGroupRelationships() {
      if (_.isEmpty(this.hardcodedStoreGroups)) return this.storeGroupRelationships;
      return this.hardcodedStoreGroups;
    },
  },

  watch: {
    wholesaleGridLoading() {
      if (!this.gridOptions.api) return;
      if (this.loading) {
        this.gridOptions.api.showLoadingOverlay();
      } else {
        this.gridOptions.api.hideOverlay();
      }
    },
  },
  beforeMount() {
    this.gridOptions = {
      suppressContextMenu: true,
      tooltipShowDelay: 500,
      suppressMovableColumns: true,
      suppressFieldDotNotation: true,
      suppressMenuHide: true,
      suppressRowDrag: true,
      singleClickEdit: true,
      stopEditingWhenCellsLoseFocus: true,
      suppressPropertyNamesCheck: true,
      rowHeight: 30,
      headerHeight: 30,
      // register vue components
      columnTypes: {
        [columnType.offsetColumn]: {
          ...this.getDefaultNumericConfigDef(this.$t('wholesale.gridTable.tooltips.invalidOffset')),
          valueSetter: this.syncValidateGridValueSetter(agGridUtils.validations.valueIsNotNegative),
        },
        [columnType.gridValueColumn]: {
          ...this.getDefaultNumericConfigDef(this.$t('wholesale.gridTable.tooltips.invalidValue')),
          valueSetter: this.syncValidateGridValueSetter(agGridUtils.validations.valueIsNotNumeric),
          headerClass: 'aligned-center borders',
          ...agGridUtils.getStrictWidthColumn(70),
          editable: true,
        },
      },
      rowClassRules: {
        'main-tool-store-group-row': 'data.parentPricingStoreGroupKey === null',
      },
      getRowClass: params => params.data.cssClasses,
      doesExternalFilterPass: this.isRowVisible,
      // external filter this.isRowVisible is always turned on
      isExternalFilterPresent: () => true,
    };
    this.columnDefs = [
      {
        headerName: '',
        ...agGridUtils.getStrictWidthColumn(30),
        rowSpan: this.getMergedCellRowSpanNumber,
        cellClass: 'default-padding chevron-icon-cell',
        cellClassRules: { 'show-cell': params => params.data.offset !== undefined },
        pinned: 'left',
        editable: false,
        customExport: {
          exported: false,
        },
        cellRendererSelector: params => {
          if (params.data.parentPricingStoreGroupKey === null && params.data.offset !== undefined) {
            return {
              component: 'agGridChevronIcon',
            };
          }
          return null;
        },
        cellRendererParams: params => {
          return {
            clickHandler: () => this.toggleExpandIcon(params),
            classes: 'expand-icon',
          };
        },
      },
      {
        field: 'toolStoreGroupDescription',
        headerName: this.$t('wholesale.gridTable.toolStoreGroup'),
        ...agGridUtils.getStrictWidthColumn(140),
        rowSpan: this.getMergedCellRowSpanNumber,
        cellClass: 'default-padding justified-right-cell tool-store-group-column',
        cellClassRules: { 'show-cell': params => params.data.offset !== undefined },
        pinned: 'left',
        editable: false,
        customExport: {
          exported: true,
          rowSpan: () => this.numberOfDisplayedAttributeValues,
        },
        ...agGridUtils.getTruncatedValueGetters({ truncationLength: 25 }),
      },
      {
        field: 'offset',
        headerName: this.$t('wholesale.gridTable.offset'),
        ...agGridUtils.getStrictWidthColumn(65),
        type: columnType.offsetColumn,
        cellRendererSelector: params => {
          if (params.data.parentPricingStoreGroupKey === null) {
            return {
              component: 'BaselineCellRenderer',
            };
          }
          return null;
        },
        rowSpan: this.getMergedCellRowSpanNumber,
        cellClass: 'offset-cell justified-right-cell justified-right-editor-cell',
        cellClassRules: {
          'show-cell default-padding': 'value !== undefined',
          'baseline-offset-cell': 'data.parentPricingStoreGroupKey === null',
        },
        pinned: 'left',
        customExport: {
          exported: true,
          field: 'offsetInitial',
          rowSpan: () => this.numberOfDisplayedAttributeValues,
        },
        editable: params => {
          return (
            params.data.parentPricingStoreGroupKey !== null && params.data.offset !== undefined
          );
        },
      },
      {
        field: 'ownAttributeValue',
        headerName: '',
        cellClass: 'default-padding',
        ...agGridUtils.getStrictWidthColumn(100),
        pinned: 'left',
        editable: false,
        customExport: {
          exported: true,
          headerNameGetter: () => this.ownBrandAttributeMetadata.displayDescription,
        },
        ...agGridUtils.getTruncatedValueGetters({ truncationLength: 15 }),
      },
      ...this.generateGridRangeColumns(),
    ];
    this.defaultColDef = {
      suppressMenu: true,
      resizable: false,
      sortable: false,
      suppressMovable: true,
      valueParser: agGridUtils.defaultParser,
    };
    this.components = {
      BaselineCellRenderer: window.createBaselineCellRenderer(
        this.$t('wholesale.gridTable.baseline')
      ),
    };
  },
  created() {
    this.isNewGrid = !this.gridDetails.gridId;
  },
  async mounted() {
    this.ownBrandAttributeMetadata = await this.getOwnBrandAttributeValues();

    if (!this.isNewGrid) {
      this.updateRowData();
      return;
    }

    this.rowData = this.prepareNewGridRowData();
    this.numberOfDisplayedAttributeValues = this.ownBrandAttributeMetadata.values.length;
  },

  methods: {
    ...mapActions('wholesaleGrid', [
      'fetchWholesaleGrid',
      'updateWholesaleGrid',
      'createWholesaleGrid',
      'getOwnBrandAttributeValues',
      'resetState',
    ]),

    gridDescriptionRequired(description) {
      const errorMessage = this.$t('wholesale.gridTable.gridDescriptionRequired');
      if (!description) {
        return errorMessage;
      }
      const trimed = description.trim();
      return !!trimed || errorMessage;
    },

    isUniqueGridDescription(description) {
      const trimed = description.trim();
      return (
        !this.existingGridDescriptions.includes(trimed) ||
        this.$t('wholesale.gridTable.gridDescriptionAlreadyExists', { description })
      );
    },

    isRowVisible(node) {
      return (
        node.data.parentPricingStoreGroupKey === null ||
        this.expandedMainPricingStoreGroups.has(node.data.parentPricingStoreGroupKey)
      );
    },

    async getStoreGroupGrids() {
      this.storeGroupGrids = await this.fetchWholesaleGrid({ gridId: this.gridDetails.gridId });
    },

    async updateRowData() {
      await this.getStoreGroupGrids();
      if (_.isEmpty(this.storeGroupGrids)) {
        this.numberOfDisplayedAttributeValues = 0;
        this.rowData = [];
        return;
      }
      this.numberOfDisplayedAttributeValues = this.countNumberOfDisplayedAttributeValues(
        this.storeGroupGrids
      );
      this.rowData = this.prepareExistingGridRowData(this.storeGroupGrids);
    },

    generateDefaultGridValues() {
      return this.columnDefs.reduce((gridValues, { field, type }) => {
        if (type === columnType.gridValueColumn) {
          gridValues[field] = this.wholesaleConfig.defaultGridValue;
          gridValues[`${field}Initial`] = this.wholesaleConfig.defaultGridValue;
        }
        return gridValues;
      }, {});
    },

    generateNewGridRowData({ toolStoreGroupToConfigMap }) {
      const rows = [];
      const defaultGridValues = this.generateDefaultGridValues();
      const toolStoreGroupToOffsetMap = {};
      this.hackedSelectedWorkpackageToolStoreGroups.forEach(storeGroup => {
        const toolStoreGroupConfig = toolStoreGroupToConfigMap[storeGroup.key];
        toolStoreGroupToOffsetMap[storeGroup.key] = this.wholesaleConfig.defaultOffsetValue;
        this.ownBrandAttributeMetadata.values.forEach(attributeValue => {
          rows.push({
            toolStoreGroupKey: storeGroup.key,
            ...toolStoreGroupConfig,
            ownAttributeValue: attributeValue,
            ...defaultGridValues,
          });
        });
      });
      return { rows, toolStoreGroupToOffsetMap };
    },

    prepareNewGridRowData() {
      const toolStoreGroupToConfigMap = this.getToolStoreGroupKeyToConfigMap();
      const { rows, toolStoreGroupToOffsetMap } = this.generateNewGridRowData({
        toolStoreGroupToConfigMap,
      });
      const compareFunction = getCompareInsideToolStoreGroupFunc({
        sortByField: 'ownAttributeValue',
        sortDirection: descending,
        dataType: this.ownBrandAttributeMetadata.dataType,
        storeGroupOrderConfig: this.getHardcodedStoreGroupOrder, // TODO: PRICE-2315 remove this hack
      });
      rows.sort(compareFunction);
      this.addAddtionalDataToRow({
        sortedRowData: rows,
        toolStoreGroupToOffsetMap,
        toolStoreGroupToConfigMap,
      });
      return rows;
    },

    getMergedCellRowSpanNumber(params) {
      return _.isUndefined(params.data.offset) ? 1 : this.numberOfDisplayedAttributeValues || 1;
    },

    mapExistingGridToRowData({ wholesaleGrids, toolStoreGroupToConfigMap, isForExport }) {
      const rows = [];
      const toolStoreGroupToOffsetMap = {};
      _.forEach(wholesaleGrids, (storeGroup, key) => {
        const { offset, grid } = storeGroup;
        const toolStoreGroupConfig = toolStoreGroupToConfigMap[key];
        toolStoreGroupToOffsetMap[key] = offset;
        _.forEach(grid, (gridValuesArray, ownAttributeValue) => {
          const gridValues = gridValuesArray.reduce((acc, { upperLimit, value }) => {
            // this.gridOptions.suppressFieldDotNotation is enabled, hence ag-grid treats "0.01" as a field name (not a path ["0", "01"])
            const fieldName = `${upperLimit}`;
            const fieldValue = isForExport ? value + offset : value;
            acc[fieldName] = fieldValue;
            acc[`${fieldName}Initial`] = fieldValue;
            return acc;
          }, {});
          const item = {
            toolStoreGroupKey: key,
            ...toolStoreGroupConfig,
            ownAttributeValue,
            ...gridValues,
          };
          rows.push(item);
        });
      });
      return { rows, toolStoreGroupToOffsetMap };
    },

    addAddtionalDataToRow({ sortedRowData, toolStoreGroupToOffsetMap }) {
      const toolStoreGroupToOffsetMapCopy = { ...toolStoreGroupToOffsetMap };
      sortedRowData.forEach(row => {
        const { toolStoreGroupKey } = row;
        // add offset to first tool store group row
        if (_.has(toolStoreGroupToOffsetMapCopy, toolStoreGroupKey)) {
          row.offset = toolStoreGroupToOffsetMapCopy[toolStoreGroupKey];
          row.offsetInitial = row.offset;
          delete toolStoreGroupToOffsetMapCopy[toolStoreGroupKey];
        }
      });
    },

    getToolStoreGroupKeyToConfigMap() {
      const toolStoreGroupKeyToRelationshipMap = this.hackedStoreGroupRelationships.reduce(
        (storeGroupKeyToRelationshipMap, relationship) => {
          storeGroupKeyToRelationshipMap[relationship.toolStoreGroupKey] = relationship;
          return storeGroupKeyToRelationshipMap;
        },
        {}
      );

      return this.hackedSelectedWorkpackageToolStoreGroups.reduce((map, toolStoreGroup, index) => {
        const { key, description } = toolStoreGroup;
        const relationships = toolStoreGroupKeyToRelationshipMap[key];
        const config = {
          cssClasses: index % 2 ? 'ag-row-custom-odd' : 'ag-row-custom-even',
          parentPricingStoreGroupKey: relationships.parentPricingStoreGroupKey,
          pricingStoreGroupKey: relationships.pricingStoreGroupKey,
          toolStoreGroupDescription: description,
        };
        map[key] = config;
        return map;
      }, {});
    },

    countNumberOfDisplayedAttributeValues(wholesaleGrids) {
      const firstToolStoreGroup = _.values(wholesaleGrids)[0];
      return _.keys(firstToolStoreGroup.grid).length;
    },

    prepareExistingGridRowData(wholesaleGrids, isForExport = false) {
      const toolStoreGroupToConfigMap = this.getToolStoreGroupKeyToConfigMap();
      const { rows, toolStoreGroupToOffsetMap } = this.mapExistingGridToRowData({
        wholesaleGrids,
        toolStoreGroupToConfigMap,
        isForExport,
      });
      const compareFunction = getCompareInsideToolStoreGroupFunc({
        sortByField: 'ownAttributeValue',
        sortDirection: descending,
        dataType: this.ownBrandAttributeMetadata.dataType,
        storeGroupOrderConfig: this.getHardcodedStoreGroupOrder, // TODO: PRICE-2315 remove this hack
      });
      rows.sort(compareFunction);
      this.addAddtionalDataToRow({
        sortedRowData: rows,
        toolStoreGroupToOffsetMap,
        toolStoreGroupToConfigMap,
      });
      // PRICE-2315 hack for now. getting 2 extra rows which results in 1 blank storegroup in export.
      // causes re-import to fail.
      if (isForExport) return rows.filter(row => row.cssClasses);
      return rows;
    },

    updateErrorsAndUpdatesOnSuccessfullValidation(data, field, newValue) {
      const key = this.getRowKey(data, field);
      const valueChanged = data[`${field}Initial`] !== newValue;

      if (valueChanged) {
        this.$set(this.updates, key, newValue);
      }

      if (!valueChanged && _.has(this.updates, key)) {
        this.$delete(this.updates, key);
      }

      if (_.has(this.errors, key)) {
        this.$delete(this.errors, key);
      }
    },

    getRowKey(data, field) {
      return field === 'offset'
        ? `${data.toolStoreGroupKey}-${field}`
        : `${data.toolStoreGroupKey}-${data.ownAttributeValue}-${field}`;
    },

    updateErrorsAndUpdatesOnValidationFailure(data, field, newValue) {
      const key = this.getRowKey(data, field);
      const valueChanged = data[`${field}Initial`] !== newValue;

      if (valueChanged) {
        this.$set(this.updates, key, newValue);
      }

      if (!valueChanged && _.has(this.updates, key)) {
        this.$delete(this.updates, key);
      }

      this.$set(this.errors, key, true);
    },

    setGridValueWhenValidationPassed(params) {
      return () => {
        const data = params.data;
        const field = params.colDef.field;
        this.updateErrorsAndUpdatesOnSuccessfullValidation(data, field, params.newValue);
        data[field] = params.newValue;
        params.api.applyTransaction({ update: [data] });
      };
    },

    setGridValueWhenValidationFailed(params) {
      return () => {
        const data = params.data;
        const field = params.colDef.field;
        this.updateErrorsAndUpdatesOnValidationFailure(data, field, params.newValue);
        data[field] = params.newValue;
        params.api.applyTransaction({ update: [data] });
      };
    },

    syncValidateGridValueSetter(validateFn) {
      return params => {
        // Side Effect that handles validation and updating the grid
        this.syncValidator(
          params.newValue,
          validateFn,
          this.setGridValueWhenValidationPassed(params),
          this.setGridValueWhenValidationFailed(params)
        );

        return false;
      };
    },

    syncValidator(newValue, validateFn, onSuccess, onFail) {
      if (validateFn({ value: newValue })) {
        onFail();
        return;
      }
      onSuccess();
    },

    onGridReady(params) {
      this.gridApi = params.api;
      this.gridColumnApi = params.columnApi;
    },

    generateGridRangeColumns() {
      return this.wholesaleConfig.gridValuesRanges.map(({ upperLimit, displayedText }) => {
        return {
          field: `${upperLimit}`,
          headerName: displayedText,
          type: columnType.gridValueColumn,
          customExport: {
            exported: true,
            field: `${upperLimit}Initial`,
          },
        };
      });
    },

    getDefaultNumericConfigDef(invalidValueTooltip) {
      return {
        ...agGridUtils.getNumericColumnCustomType({
          formatter: this.formatNumber,
          formatterParams: {
            format: numberFormats.percentWithoutUnitOfMeasurement,
          },
          stringToNumberFormatter: this.formatStringToNumber,
        }),
        cellClass: 'default-padding justified-right-cell justified-right-editor-cell',
        cellClassRules: {
          'invalid-numeric': params => {
            const { colDef, data } = params;
            const field = colDef.field;
            const key = this.getRowKey(data, field);
            return _.has(this.errors, key);
          },
          'updated-field': params => {
            const { colDef, data } = params;
            const field = colDef.field;
            const key = this.getRowKey(data, field);
            return _.has(this.updates, key);
          },
        },
        tooltipValueGetter: params => {
          const { colDef, data } = params;
          const field = colDef.field;
          const key = this.getRowKey(data, field);
          const updated = _.has(this.updates, key);
          const hasErrors = _.has(this.errors, key);
          const initialValueField = `${field}Initial`;
          const tooltipMessages = [];
          if (hasErrors) {
            tooltipMessages.push(invalidValueTooltip);
          }
          if (updated) {
            tooltipMessages.push(
              this.$t('wholesale.gridTable.tooltips.previousValue', {
                value: data[initialValueField],
              })
            );
          }
          return tooltipMessages.join('. ');
        },
      };
    },

    formatUpdates() {
      const sharedFields = [
        'toolStoreGroupKey',
        'toolStoreGroupDescription',
        'ownAttributeValue',
        'offset',
      ];
      const fieldNames = _.map(this.columnDefs, 'field');
      const gridFields = fieldNames.filter(
        fieldName => fieldName && !sharedFields.includes(fieldName)
      );

      return this.rowData.reduce((result, row) => {
        const { toolStoreGroupKey, ownAttributeValue } = row;
        const grid = gridFields.map(fieldName => ({
          upperLimit: +fieldName,
          value: row[fieldName],
        }));
        const toolStoreGroupExists = _.has(result, toolStoreGroupKey);
        if (!toolStoreGroupExists) {
          result[toolStoreGroupKey] = { grid: {} };
        }
        if (_.has(row, 'offset')) {
          result[toolStoreGroupKey].offset = row.offset;
        }
        result[toolStoreGroupKey].grid[ownAttributeValue] = grid;
        return result;
      }, {});
    },

    updateExistingWholesaleGrid() {
      const updates = {
        storeGroupGrids: this.formatUpdates(),
      };
      return this.updateWholesaleGrid({
        gridId: this.gridDetails.gridId,
        updates,
      });
    },

    saveNewWholesaleGrid() {
      const grid = {
        storeGroupGrids: this.formatUpdates(),
        gridDescription: this.newGridDescription,
      };
      return this.createWholesaleGrid({
        grid,
      });
    },

    async saveWholesaleGrid() {
      const request = this.isNewGrid
        ? this.saveNewWholesaleGrid()
        : this.updateExistingWholesaleGrid();
      const { failed, gridId } = await request;

      if (this.isNewGrid && !failed) {
        this.stopEditing({ newGridId: gridId, newGridDescription: this.newGridDescription });
        return;
      }

      if (!failed) {
        this.updates = {};
        this.errors = {};
        this.updateRowData();
      }
    },

    stopEditing({ newGridId, newGridDescription } = {}) {
      this.$emit('closeDialog', { newGridId, newGridDescription });
      this.resetState();
    },

    toggleExpandIcon(params) {
      const data = params.data;
      data.isExpanded = !data.isExpanded;
      params.api.applyTransaction({ update: [data] });

      if (data.isExpanded) {
        this.expandedMainPricingStoreGroups.add(data.pricingStoreGroupKey);
      } else {
        this.expandedMainPricingStoreGroups.delete(data.pricingStoreGroupKey);
      }

      this.gridApi.onFilterChanged();
    },

    exportGrid() {
      const gridName = this.isNewGrid ? '' : this.gridDetails.gridDescription;
      exportGrid({
        columnDefs: this.columnDefs,
        rows: this.prepareExistingGridRowData(this.storeGroupGrids, true),
        fileName: getExportFileName(this.exportConfigs.wholesaleGrid, gridName),
      });
    },
  },
};

window.createBaselineCellRenderer = function createBaselineCellRenderer(baselineText) {
  function BaselineCellRenderer() {}
  BaselineCellRenderer.prototype.init = function() {
    this.ui = document.createElement('span');
    this.ui.innerHTML = baselineText;
  };
  BaselineCellRenderer.prototype.getGui = function() {
    return this.ui;
  };
  return BaselineCellRenderer;
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
@import '@style/mixins/_general.scss';
@import '@style/wholesale-dialogs.scss';

$title-label-color: rgba(0, 0, 0, 0.85);

.cancel-button {
  @include icon-size(1.5rem);
  color: $icon-colour;
}

.new-grid-description-wrapper {
  width: 40rem;
}

.title-label {
  font-size: $custom-ag-grid-font-size;
  color: $title-label-color;
}
</style>

<style lang="scss">
@import '@style/base/_variables.scss';

.ag-wholesale-grid {
  $baseline-offset-cell-color: #8ba4b7;
  $offset-cell-color: #d3dee7;
  $main-tool-store-group-row-color: #cfe4f1;
  $tool-store-group-font-color: #6d7278;

  .ag-root-wrapper {
    border: none;
  }

  .expand-icon {
    transform: scale(2);
    color: $icon-colour;
  }

  .ag-cell.show-cell {
    background: $pricing-white;
    border-bottom: 1px solid $custom-ag-grid-secondary-border-color;

    &.offset-cell.baseline-offset-cell {
      background-color: $baseline-offset-cell-color;
      color: $pricing-white;
      justify-content: left;
    }

    &.offset-cell {
      background-color: $offset-cell-color;
      border-left: 1px solid $custom-ag-grid-secondary-border-color;
      border-right: 1px solid $custom-ag-grid-secondary-border-color;
    }
  }

  .show-cell.tool-store-group-column {
    color: $tool-store-group-font-color;
    font-weight: 600;
    letter-spacing: 0;
  }

  @mixin colored-cell-border($border-color) {
    border-radius: 3px;
    border: 2px solid;
    border-color: $border-color;
  }

  .ag-cell.ag-cell-value.updated-field {
    @include colored-cell-border($pricing-light-blue);
  }

  .ag-cell.ag-cell-value.invalid-numeric {
    @include colored-cell-border($pricing-red);
  }

  .main-tool-store-group-row {
    background: $main-tool-store-group-row-color;

    .show-cell {
      background-color: $main-tool-store-group-row-color;
    }
  }
}
</style>
