const numeral = require('numeral');
const { isNil } = require('lodash');

class CustomNumberCellEditor {
  init(params) {
    this.params = params;

    const currentLocale = numeral.options.currentLocale;
    this.decimalSeparator = numeral.locales[currentLocale].delimiters.decimal;

    this.eInput = document.createElement('input');
    this.eInput.type = 'text'; // The input is text to allow localised separators
    this.eInput.className = 'ag-input';
    this.eInput.style.width = '100%';

    // Convert the value to a string with the correct decimal separator
    this.eInput.value = this.formatValue(params.value);

    // Event listener to restrict input to only valid characters
    this.eInput.addEventListener('keypress', this.restrictInput.bind(this));
  }

  getGui() {
    return this.eInput;
  }

  afterGuiAttached() {
    this.eInput.focus();
  }

  getValue() {
    // Convert the string back to a number for sorting/filtering
    return this.parseValue(this.eInput.value);
  }

  formatValue(value) {
    if (isNil(value)) return '';

    if (this.decimalSeparator === ',') {
      return value.toString().replace('.', ',');
    }
    return value.toString();
  }

  parseValue(value) {
    if (value === '') {
      return null;
    }

    if (this.decimalSeparator === ',') {
      return parseFloat(value.replace(',', '.'));
    }

    return parseFloat(value);
  }

  restrictInput(event) {
    // no duplicate decimals
    if (event.key === this.decimalSeparator && this.eInput.value.includes(this.decimalSeparator)) {
      event.preventDefault();
    }

    // only allow minus character if it's inserting at the beginning
    if (event.key === '-' && this.eInput.selectionStart !== 0) {
      event.preventDefault();
    }

    let digitRegex = '[0-9]|-';
    const periodRegex = '|\\.';
    const commaRegex = '|,';

    digitRegex += this.decimalSeparator === '.' ? periodRegex : commaRegex;

    const regex = new RegExp(digitRegex);

    // Allow numeric and control characters
    if (!event.key.match(regex)) {
      event.preventDefault();
    }
  }
}

export default CustomNumberCellEditor;
