<template>
  <v-btn
    color="success"
    class="save ml-2 align-self-center"
    small
    depressed
    :disabled="gridsAndL6NodesExporting"
    :loading="gridsAndL6NodesExporting"
    @click="initExportGridsAndL6Nodes"
  >
    {{ $t('wholesale.downloadGridsAndNodes') }}
  </v-btn>
</template>

<script>
import { isEmpty } from 'lodash';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import {
  makeCDTNodesTable,
  reduceGridsToRowsForExport,
  writeTablesToXLSX,
  getExportFileName,
} from '@sharedModules/data/utils/export-utils';
import sortStoreGroups from '../../../utils/sort-store-groups-util';

export default {
  data() {
    return {
      gridsAndL6NodesExporting: false,
    };
  },

  computed: {
    ...mapState('clientConfig', ['wholesaleConfig']),
    ...mapState('wholesale', ['wholesaleHierarchyIdGridMap', 'wholesaleHierarchyTree']),
    ...mapState('storeGroupRelationships', ['storeGroupRelationships']),
    ...mapGetters('clientConfig', ['getHardcodedStoreGroupOrder']),
    ...mapGetters('storeGroupRelationships', [
      'toolStoreGroupsDescriptionMap',
      'hackedSelectedWorkpackageToolStoreGroups',
    ]),
  },

  methods: {
    ...mapActions('wholesaleGrid', ['fetchWholesaleGridList', 'fetchMarginSplits']),
    ...mapMutations('notifications', ['addNotification']),

    async initExportGridsAndL6Nodes() {
      this.gridsAndL6NodesExporting = true;
      this.addNotification({
        id: `${new Date()}gridExport`,
        jobStatus: 'starting',
        baseTranslation: 'notifications.export_grids_and_nodes',
      });

      try {
        if (isEmpty(this.wholesaleHierarchyIdGridMap) || isEmpty(this.wholesaleHierarchyTree)) {
          await this.fetchMarginSplits();
        }

        const gridList = await this.fetchWholesaleGridList();

        const cdtNodes = makeCDTNodesTable({
          wholesaleHierarchyIdGridMap: this.wholesaleHierarchyIdGridMap,
          rootNode: this.wholesaleHierarchyTree[0],
        });

        const gridRowsForExport = reduceGridsToRowsForExport({
          gridList,
          defaultGridValue: this.wholesaleConfig.defaultGridValue,
          gridValuesRanges: this.wholesaleConfig.gridValuesRanges,
          toolStoreGroupsDescriptionMap: this.toolStoreGroupsDescriptionMap,
          hardcodedStoreGroupOrder: this.getHardcodedStoreGroupOrder,
          sortStoreGroupsFn: sortStoreGroups,
        });

        const fileName = getExportFileName({
          timestamp: 'YYYYMMDD_HHmmss',
          fileName: {
            entityNameWordSeparator: '-',
            defaultEntityName: 'Exported Grids',
            pattern: '<entityName>-<timestamp>',
            extension: 'xlsx',
          },
        });

        writeTablesToXLSX(fileName, [
          {
            pageName: 'CDT Nodes',
            table: cdtNodes,
          },
          {
            pageName: 'Grids',
            table: gridRowsForExport,
          },
        ]);

        this.addNotification({
          id: `${new Date()}gridExport`,
          jobStatus: 'finished',
          baseTranslation: 'notifications.export_grids_and_nodes',
        });
      } catch (e) {
        console.error(e);
        this.addNotification({
          id: `${new Date()}gridExport`,
          jobStatus: 'failed',
          baseTranslation: 'notifications.export_grids_and_nodes',
        });
      }
      this.gridsAndL6NodesExporting = false;
    },
  },
};
</script>
