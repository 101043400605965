<template>
  <span>
    <v-dialog v-model="showDialog" :width="850" persistent>
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('workPackages.incorrectMappings') }}
        </v-card-title>

        <v-icon class="close-button" color="grey" @click="closeDialog">close</v-icon>

        <div>
          <v-card-text>
            <div class="d-flex">
              <v-simple-table class="flex-fill" height="40rem">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th colspan="2">
                        {{ $t('workPackages.userWorkpackageMapping') }} -
                        {{ userWorkpackageDescription }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('pricing.toolStoreGroup') }}</th>
                      <th class="text-left">{{ $t('pricing.pricingStoreGroup') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(psg, ix) in userWpMappings" :key="psg.pricingStoreGroupKey">
                      <td>
                        {{
                          showIfDifferent(
                            psg.toolStoreGroupDescription,
                            'toolStoreGroupDescription',
                            ix,
                            userWpMappings
                          )
                        }}
                      </td>
                      <td>
                        {{
                          showIfDifferent(
                            psg.pricingStoreGroupDescription,
                            'pricingStoreGroupDescription',
                            ix,
                            userWpMappings
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
              <v-simple-table class="flex-fill" height="40rem">
                <template v-slot:default>
                  <thead>
                    <tr>
                      <th colspan="2">
                        {{ $t('workPackages.masterWorkpackageMapping') }} -
                        {{ masterWorkpackageDescription }}
                      </th>
                    </tr>
                    <tr>
                      <th class="text-left">{{ $t('pricing.toolStoreGroup') }}</th>
                      <th class="text-left">{{ $t('pricing.pricingStoreGroup') }}</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr v-for="(psg, ix) in masterWpMappings" :key="psg.pricingStoreGroupKey">
                      <td>
                        {{
                          showIfDifferent(
                            psg.toolStoreGroupDescription,
                            'toolStoreGroupDescription',
                            ix,
                            masterWpMappings
                          )
                        }}
                      </td>
                      <td>
                        {{
                          showIfDifferent(
                            psg.pricingStoreGroupDescription,
                            'pricingStoreGroupDescription',
                            ix,
                            masterWpMappings
                          )
                        }}
                      </td>
                    </tr>
                  </tbody>
                </template>
              </v-simple-table>
            </div>
          </v-card-text>
          <v-divider />
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
import { find, get, sortBy } from 'lodash';
import showIfDifferentMixin from '../../../mixins/showIfDifferent';

export default {
  mixins: [showIfDifferentMixin],

  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
  },

  computed: {
    ...mapState('workpackages', [
      'userWorkpackageStoreGroup',
      'masterWorkpackageStoreGroup',
      'workpackages',
    ]),
    ...mapGetters('workpackages', ['masterWorkpackages']),

    masterWorkpackageDescription() {
      return get(
        find(this.masterWorkpackages, { _id: this.masterWorkpackageStoreGroup.workpackageId }),
        'description'
      );
    },

    userWorkpackageDescription() {
      return get(
        find(this.workpackages, { _id: this.userWorkpackageStoreGroup.workpackageId }),
        'description'
      );
    },

    userWpMappings() {
      return sortBy(this.userWorkpackageStoreGroup.pricingStoreGroups, [
        'toolStoreGroupDescription',
        'pricingStoreGroupDescription',
      ]);
    },

    masterWpMappings() {
      return sortBy(this.masterWorkpackageStoreGroup.pricingStoreGroups, [
        'toolStoreGroupDescription',
        'pricingStoreGroupDescription',
      ]);
    },
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>

<style lang="scss" scoped></style>
