<template>
  <v-container ma-0 pa-2 pb-0>
    <v-layout>
      <v-flex xs9>
        <h3>
          {{ $t('pricing.priceHistory') }}<span>{{ $t('pricing.priceHistorySub') }}</span>
        </h3>
      </v-flex>
    </v-layout>
    <v-layout row>
      <v-flex xs9>
        <highcharts ref="highcharts" :options="chartOptions" />
      </v-flex>
      <v-flex class="legend" xs3 align-self-end>
        <v-checkbox
          v-for="(value, index) in historiesToPlot"
          :key="value"
          :value="!selectedPriceLines.includes(value)"
          :input-value="selectedPriceLines.includes(value)"
          column
          color="light-blue darken-3"
          height="0.1rem"
          @change="updateSelectedPriceLines($event, value)"
        >
          <template v-slot:label>
            <tooltip :disabled="tooltipExcludeLines.includes(value)" :value="getTooltip(value)">
              <div :style="{ color: getPriceHistoryLegendColour(index) }">
                {{ getLegendLabel(value) }}
              </div>
            </tooltip>
          </template>
        </v-checkbox>
      </v-flex>
    </v-layout>
  </v-container>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import {
  size,
  xor,
  get,
  some,
  includes,
  isEmpty,
  isNumber,
  range,
  last,
  maxBy,
  minBy,
  sortBy,
  merge,
  startCase,
  cloneDeep,
  pick,
  isUndefined,
  reduce,
  min,
  max,
  filter,
} from 'lodash';
import moment from 'moment';
// import minified versions of all locales to avoid moment falling back to default en locale
import 'moment/min/locales';
import defaultOptions from '../../../default-chart-options';
import expandedProductViewChartOptions from '../../../expanded-product-view-chart-options';
import colours from '../../../ow-colors';
import createFeatureAwareFactory from '@sharedModules/feature-toggles/feature-factory';
import numberFormats from '@enums/number-formats';

const priceHistoryChartColours = [
  colours.pricingBluePrimary,
  colours.pricingRedPrimary,
  colours.pricingYellowPrimary,
  colours.pricingGreenPrimary,
  colours.pricingTealPrimary,
  ...colours.orderedChartColors,
];

export default {
  props: {
    priceHistory: {
      type: Array,
      required: true,
    },
    scenarioPrice: {
      type: Object,
      required: true,
    },
    scenarioCost: {
      type: Object,
      required: true,
    },
    costHistory: {
      type: Array,
      required: true,
    },
    competitors: {
      type: Array,
      required: true,
    },
    competitorNamesMap: {
      type: Object,
      default: () => {},
    },
  },

  data() {
    return {
      selectedPriceLines: [],
      chartOptions: {},
      allPricePoints: null,
      priceChartData: null,
      costChartData: null,
      series: null,
      tooltipData: {},
      tooltipExcludeLines: ['cost', 'price'],
      numberFormats,
    };
  },

  computed: {
    ...mapGetters('gridView', ['selectedCompetitorDisplayDescriptions']),
    ...mapState('clientConfig', ['toggleLogic', 'dateFormats', 'toolStoreGroupConfig']),

    mappedCompetitors() {
      if (!this.competitors) return [];
      return this.competitors.map(competitor => ({
        ...competitor,
        competitorDisplayDescription:
          this.competitorNamesMap[competitor.competitorDescription] ||
          competitor.competitorDescription,
      }));
    },

    priceHistoryExists() {
      return some([this.priceHistory, this.scenarioPrice].map(size));
    },

    costHistoryExists() {
      return !!this.costHistory.length;
    },

    competitorsWithPriceHistory() {
      return sortBy(
        this.mappedCompetitors.filter(c => c.competitorHistoryWeek.length),
        'competitorDisplayDescription'
      );
    },

    historiesToPlot() {
      return [
        ...(this.priceHistoryExists ? ['price'] : []),
        ...(this.costHistoryExists ? ['cost'] : []),
        ...this.competitorsWithPriceHistory.map(c => c.competitorDisplayDescription),
      ];
    },

    labelLookup() {
      return {
        price: `${this.$t('clientName')} ${startCase(this.$t('pricing.price'))}`,
        cost: `${this.$t('clientName')} ${startCase(this.$t('pricing.cost'))}`,
        ...this.mappedCompetitors.reduce((obj, competitor) => {
          obj[competitor.competitorDisplayDescription] = competitor.competitorDisplayDescription;
          return obj;
        }, {}),
      };
    },

    priceLookup() {
      const formattedPrices = this.mappedCompetitors.map(competitor => {
        const { priceHistoryCompetitorLabel } = createFeatureAwareFactory(
          this.toggleLogic,
          this.toolStoreGroupConfig
        );

        // FEATURE_FLAG: the competitor checkbox label will include the live competitor index if
        // toggle-logic.addIndexToProductInfo returns true
        return {
          [competitor.competitorDisplayDescription]: priceHistoryCompetitorLabel(
            competitor,
            this.formatNumber
          ),
        };
      });

      return Object.assign({}, ...formattedPrices);
    },

    yAxisExtremes() {
      const filteredData = this.series.filter(({ name }) => this.selectedPriceLines.includes(name));

      return size(filteredData)
        ? {
            minY: minBy(filteredData.map(({ data }) => minBy(data, 'y')), 'y').y * 0.95,
            maxY: maxBy(filteredData.map(({ data }) => maxBy(data, 'y')), 'y').y * 1.05,
          }
        : {
            minY: null,
            maxY: null,
          };
    },

    plotBands() {
      // live band = current date to go-live date
      const currentTimestamp = moment().unix();
      // scenario band = go-live date (i.e. effectiveDate of scenario price and cost)
      // to one week later (i.e. expiry of scenario price and cost)
      const scenarioCostEffectivetimestamp =
        this.scenarioPrice.effectiveTimestamp || this.scenarioCost.effectiveTimestamp;
      const scenarioCostExpirytimestamp =
        this.scenarioPrice.expiryTimestamp || this.scenarioCost.expiryTimestamp;
      return [
        {
          color: colours.pricingGreyLight,
          from: currentTimestamp,
          to: scenarioCostEffectivetimestamp,
        },
        {
          color: colours.pricingBlueLight,
          from: scenarioCostEffectivetimestamp,
          to: scenarioCostExpirytimestamp,
        },
      ];
    },

    maxFutureTimeStampToDisplay() {
      const { maxFutureTimeStampToDisplay } = createFeatureAwareFactory(
        this.toggleLogic,
        this.toolStoreGroupConfig
      );
      return maxFutureTimeStampToDisplay();
    },
  },

  created() {
    // initially only display competitors selected in grid view:
    this.selectedPriceLines = [
      ...(this.priceHistoryExists ? ['price'] : []),
      ...(this.costHistoryExists ? ['cost'] : []),
      ...this.competitorsWithPriceHistory
        .map(c => c.competitorDisplayDescription)
        .filter(competitor => includes(this.selectedCompetitorDisplayDescriptions, competitor)),
    ];
    this.setInitialData();
    this.createChartOptions();
    this.createTooltipData();
  },

  methods: {
    setInitialData() {
      this.lastHistoricalCost = last(this.costHistory);
      this.allPricePoints = this.getAllPriceData();
      this.priceChartData = this.formatData([...this.allPricePoints]).formattedHistory;
      this.costChartData = this.formatData(this.getAllCostData()).formattedHistory;
      this.series = this.createSeriesData();
    },

    addWeekNumberToHistory(priceHistory) {
      const weekNumberLocale = this.dateFormats.momentWeekNumberLocale;
      return priceHistory.map(price => {
        price.weekNumber = parseInt(
          moment(price.effectiveDate)
            .locale(weekNumberLocale)
            .format('w'),
          10
        );
        return price;
      });
    },

    fillGapInDataPoints(pricePoints) {
      const pricesWithWeekNumber = this.addWeekNumberToHistory(pricePoints);
      const sortedDataPoints = sortBy(pricesWithWeekNumber, 'effectiveTimestamp');
      // fill the gap between pre-sorted data points, so that we have at least one tooltip per week
      const dataPointsInBetween = [];
      sortedDataPoints.forEach((priceObj, i) => {
        // skip the last data point
        if (i < pricePoints.length - 1) {
          const priceMoment = moment.unix(priceObj.effectiveTimestamp);
          const nextPriceMoment = moment.unix(sortedDataPoints[i + 1].effectiveTimestamp);
          const weekDiff = nextPriceMoment.diff(priceMoment, 'weeks');
          if (weekDiff > 1) {
            const newPoints = range(1, weekDiff).map(weekOffset => {
              return this.createPricePointObject(
                priceObj.effectiveTimestamp,
                weekOffset,
                priceObj.price,
                get(priceObj, 'competitorIndex', null)
              );
            });
            dataPointsInBetween.push(...this.addWeekNumberToHistory(newPoints));
          }
        }
      });
      return sortBy([...pricePoints, ...dataPointsInBetween], 'effectiveTimestamp');
    },

    getAllPriceData() {
      let allPrices = [...this.priceHistory];
      if (get(this.scenarioPrice, 'price')) {
        const scenarioPrice = Object.assign(this.scenarioPrice, {
          x: this.scenarioPrice.effectiveTimestamp, // unix timestamp
          y: this.scenarioPrice.price,
        });
        const extraPrices = this.createFuturePriceBasedOnScenarioPrice(scenarioPrice, allPrices);
        // filter out any prices that may interfere with scenario value
        allPrices = filter(
          allPrices,
          priceWeek =>
            priceWeek.effectiveTimestamp !== extraPrices[extraPrices.length - 1].effectiveTimestamp
        );
        allPrices.push(...extraPrices);
      }

      allPrices = sortBy(allPrices, 'effectiveTimestamp').filter(
        c => !isUndefined(c.effectiveTimestamp)
      );
      const lastPriceObj = this.createPricePointBasedOnMaxFutureDate(allPrices);
      allPrices.push(lastPriceObj);
      return this.fillGapInDataPoints(allPrices);
    },

    createFuturePriceBasedOnScenarioPrice(scenarioPrice, allPrices) {
      const pricesToAdd = [scenarioPrice];
      const lastPriceHistoryPriceBeforeScenario = get(
        last(
          filter(
            allPrices,
            priceWeek => priceWeek.effectiveTimestamp < scenarioPrice.effectiveTimestamp
          )
        ),
        'price',
        get(last(allPrices), 'price', null)
      );

      if (get(last(allPrices), 'expiryTimestamp', null) > this.scenarioPrice.expiryTimestamp) {
        // create a future band if the last priceHistory price covers scenario price band
        const lastPrice = last(allPrices);
        const futurePricePrice =
          get(lastPrice, 'price', null) !== lastPriceHistoryPriceBeforeScenario
            ? get(lastPrice, 'price', null)
            : scenarioPrice.price;

        // if there is a future price effective after the scenario price, retain the effective date
        // otherwise make it effective at scenario expiry
        let effectiveDate;
        let effectiveTimestamp;
        if (lastPrice.effectiveTimestamp > scenarioPrice.effectiveTimestamp) {
          effectiveDate = lastPrice.effectiveDate;
          effectiveTimestamp = lastPrice.effectiveTimestamp;
        } else {
          effectiveDate = scenarioPrice.expiryDate;
          effectiveTimestamp = scenarioPrice.expiryTimestamp + 1;
        }
        const futurePrice = {
          effectiveDate,
          effectiveTimestamp, // make it greater than scenarioPrice Timestamp
          expiryDate: lastPrice.expiryDate,
          expiryTimestamp: lastPrice.expiryTimestamp,
          price: futurePricePrice,
          x: effectiveTimestamp, // unix timestamp
          y: futurePricePrice,
        };
        pricesToAdd.push(futurePrice);
      } else {
        // No need to create an extra data point to display scenarioPrice
        // if a futurePrice that effects on scenaroi expiry date has already been created
        pricesToAdd.push(
          this.createPricePointObject(
            scenarioPrice.effectiveTimestamp,
            1,
            scenarioPrice.price,
            null,
            false
          )
        );
      }
      return pricesToAdd;
    },

    createPricePointBasedOnMaxFutureDate(allPrices) {
      // Check expiry date, if less than maxFutureDate, add last price point. Otherwise, create a price point based on maxFutureDate
      const maxFutureEffTimestamp = moment(this.maxFutureTimeStampToDisplay) / 1000;
      if (isEmpty(allPrices)) return {};
      const lastPriceObj = this.createPricePointObject(
        last(allPrices).expiryTimestamp > maxFutureEffTimestamp
          ? maxFutureEffTimestamp
          : last(allPrices).effectiveTimestamp,
        1,
        last(allPrices).price,
        get(last(allPrices), 'competitorIndex', null),
        true
      );
      return lastPriceObj;
    },

    getAllCostData() {
      let allCosts = [...this.costHistory];
      if (get(this.scenarioCost, 'price')) {
        const scenarioCost = Object.assign(this.scenarioCost, {
          x: this.scenarioCost.effectiveTimestamp, // unix timestamp
          y: this.scenarioCost.price,
        });
        const extraCosts = this.createFuturePriceBasedOnScenarioPrice(scenarioCost, allCosts);
        // filter out any prices that may interfere with scenario value
        allCosts = filter(
          allCosts,
          priceWeek =>
            priceWeek.effectiveTimestamp !== extraCosts[extraCosts.length - 1].effectiveTimestamp
        );
        allCosts.push(...extraCosts);
      }

      allCosts = sortBy(allCosts, 'effectiveTimestamp').filter(
        c => !isUndefined(c.effectiveTimestamp)
      );
      const lastCostObj = this.createPricePointBasedOnMaxFutureDate(allCosts);
      allCosts.push(lastCostObj);
      return this.fillGapInDataPoints(allCosts);
    },

    createTooltipData() {
      this.tooltipData = reduce(
        this.mappedCompetitors,
        (result, competitor) => {
          const competitorDesc = competitor.competitorDisplayDescription;
          const productInfo = pick(competitor, [
            'competitorProductDescription',
            'contentValue',
            'contentUnitOfMeasure',
          ]);
          result[competitorDesc] = productInfo;
          return result;
        },
        {}
      );
    },

    getTooltip(lineName) {
      const data = this.tooltipData[lineName];
      if (!data) return '';
      return {
        [this.$t('tooltip.competitorDescription')]: data.competitorProductDescription || '-',
        [this.$t('tooltip.competitorSize')]: this.formatNumber({
          number: data.contentValue,
          format: numberFormats.priceFormat,
          nullAsDash: true,
        }),
        [this.$t('tooltip.competitorUnitOfMeasure')]: data.contentUnitOfMeasure || '-',
      };
    },

    createPricePointObject(timestamp, offset, price, competitorIndex, hideTooltip = false) {
      const nextWeek = moment.unix(timestamp).add(offset, 'weeks');
      return {
        effectiveDate: nextWeek.toISOString(),
        effectiveTimestamp: nextWeek.unix(),
        x: nextWeek.unix(),
        y: price,
        hideTooltip,
        competitorIndex,
        price,
      };
    },

    formatData(history) {
      if (history.length === 0) return [{ formattedHistory: [], finalPricePoint: [] }];
      const historyWeekNumber = this.addWeekNumberToHistory(history);
      const sortedHistory = sortBy(historyWeekNumber, 'effectiveTimestamp');
      const formattedHistory = sortedHistory.map(entry => ({
        ...entry,
        x: entry.effectiveTimestamp || entry.x,
        y: entry.price || entry.y,
      }));

      const lastDate = moment.unix(last(formattedHistory).effectiveTimestamp);
      const nextWeek = lastDate.add(1, 'weeks');
      // bit of a hack to have the latest price here twice
      // it would only be a point, not a line spanning a week otherwise
      const finalPricePoint = {
        x: nextWeek.unix(),
        y: last(formattedHistory).y,
        hideTooltip: true,
      };

      return { formattedHistory, finalPricePoint };
    },

    createSeriesData() {
      const costData = this.costChartData
        ? [
            {
              name: 'cost',
              data: this.costChartData,
              visible: this.selectedPriceLines.includes('cost'),
            },
          ]
        : [];
      return [
        {
          name: 'price',
          data: this.priceChartData,
          visible: this.selectedPriceLines.includes('price'),
        },
        ...costData,
        ...this.getCompetitorChartData(),
      ].map(series => ({
        ...series,
        // ensure every price point has a valid x and y
        data: series.data.filter(pricePoint => isNumber(pricePoint.x) && isNumber(pricePoint.y)),
      }));
    },

    getCompetitorChartData() {
      const cloneCompetitorsData = this.competitorsWithPriceHistory.map(cloneDeep);
      const allCompetitorsData = cloneCompetitorsData.map(c => {
        const lastCompObj = this.createPricePointBasedOnMaxFutureDate(c.competitorHistoryWeek);
        c.competitorHistoryWeek.push(lastCompObj);
        return c;
      });
      return allCompetitorsData.map(competitor => {
        const { formattedHistory } = this.formatData(get(competitor, 'competitorHistoryWeek', []));
        return {
          name: competitor.competitorDisplayDescription,
          data: this.fillGapInDataPoints([...formattedHistory]),
          visible: this.selectedPriceLines.includes(competitor.competitorDisplayDescription),
        };
      });
    },

    getEarliestAndLatestDates() {
      const costData = this.getAllCostData();
      // competitor prices earliest dates
      const earliestDates = this.competitorsWithPriceHistory.map(c =>
        get(c.competitorHistoryWeek, [0, 'effectiveTimestamp'])
      );
      earliestDates.push(
        get(this.allPricePoints, [0, 'x']) || get(this.allPricePoints, [0, 'effectiveTimestamp'])
      );
      earliestDates.push(get(costData, [0, 'x']) || get(costData, [0, 'effectiveTimestamp']));
      const earliestDate = moment.unix(min(earliestDates));

      // competitor prices latest dates
      const latestDates = this.competitorsWithPriceHistory.map(c =>
        get(last(c.competitorHistoryWeek), 'effectiveTimestamp')
      );
      latestDates.push(get(last(this.allPricePoints), 'x'));
      latestDates.push(get(last(costData), 'x') || get(last(costData), 'effectiveTimestamp'));
      const latestDate = moment.unix(max(latestDates));
      return [earliestDate, latestDate];
    },

    // generate tick positions at the start of every month in the range of the data
    getTickPositions() {
      const [earliestDate, latestDate] = this.getEarliestAndLatestDates();
      // get the first day of the month
      const monthStart = moment(earliestDate);
      // go to the same time of day to prevent duplicate months in the axis labels
      monthStart.startOf('month').add({ hours: earliestDate.hour() });
      const tickPositions = [];
      // add a tick at the start of each month in the range of the data points
      while (monthStart <= latestDate) {
        tickPositions.push(monthStart.unix());
        monthStart.add({ months: 1 });
      }
      return tickPositions;
    },

    createChartOptions() {
      const vue = this; // vue instance for access in the axis labels formatter

      this.chartOptions = merge({}, defaultOptions, expandedProductViewChartOptions, {
        series: this.series,
        chart: {
          height: 230,
        },
        xAxis: {
          type: 'datetime',
          labels: {
            enabled: true,
            formatter() {
              return moment(this.value, 'X').format('MMM');
            },
          },
          max: this.getEarliestAndLatestDates()[1] / 1000,
          tickPositions: this.getTickPositions(),
          tickColor: 'transparent',
          gridLineWidth: 1,
          lineColor: colours.pricingGreyDark,
          lineWidth: 2,
          startOnTick: false,
          minPadding: -0.01,
          plotBands: this.plotBands,
        },
        yAxis: {
          lineColor: colours.pricingGreyDark,
          lineWidth: vue.lineWidth,
          min: this.yAxisExtremes.minY,
          max: this.yAxisExtremes.maxY,
          labels: {
            enabled: true,
            formatter() {
              return vue.formatNumber({
                number: this.value,
                format: vue.numberFormats.priceFormat,
              });
            },
            style: {
              fontSize: '1rem',
            },
          },
        },
        tooltip: {
          enabled: true,
          formatter() {
            if (this.point.hideTooltip) return false;
            const baseTooltip = `${vue.$t('weekAbbreviation')}${
              this.point.weekNumber
            }: <b>${vue.formatNumber({
              number: this.y,
              format: vue.numberFormats.priceFormat,
            })}</b>`;
            const competitorIndexData = `<b>${vue.formatNumber({
              number: this.point.competitorIndex,
              format: vue.numberFormats.percent,
            })}</b>`;

            // FEATURE_FLAG: the competitorIndex field will only be present in competitor history if
            // toggle-logic.addIndexToProductInfo returns true
            const tooltip =
              this.point.competitorIndex || this.point.competitorIndex === 0
                ? `${baseTooltip} / ${competitorIndexData}`
                : baseTooltip;

            // Add competitor name to competitor price lines.
            // Sometimes not all competitor price point have competitorIndexData, so need a separate condition
            return ['price', 'cost'].includes(this.series.name)
              ? tooltip
              : `${this.series.name}<br/> ${tooltip}`;
          },
          borderWidth: 2,
        },
        colors: priceHistoryChartColours,
        plotOptions: {
          series: {
            lineWidth: 2,
          },
        },
      });
    },

    getPriceHistoryLegendColour(index) {
      // Make the legend checkbox label the same colour as the line
      // Rotates through the colours as Highcharts does
      return get(priceHistoryChartColours, index % priceHistoryChartColours.length, '#000');
    },

    getLegendLabel(value) {
      if (['price', 'cost'].includes(value)) {
        return this.labelLookup[value];
      }

      return `${this.labelLookup[value]}: ${this.priceLookup[value]}`;
    },

    updateSelectedPriceLines(event, value) {
      this.selectedPriceLines = xor(this.selectedPriceLines, [value]); // toggle inclusion of value in array
      this.updateChartSeriesAndZoom();
    },

    updateChartSeriesAndZoom() {
      this.series = this.createSeriesData();

      // mutate the existing charts data
      this.$refs.highcharts.chart.update({
        series: this.series,
      });

      // mutate the axes so that we're zooming in and out appropriately to the displayed data
      // we use setExtremes here for the animation, regular update just resizes immediately.
      this.$refs.highcharts.chart.yAxis[0].setExtremes(
        this.yAxisExtremes.minY,
        this.yAxisExtremes.maxY
      );
    },
  },
};
</script>

<style scoped lang="scss">
@import '@style/base/_variables.scss';

.legend {
  padding: 0 0 24px 50px;

  label div {
    font-size: 1.2rem;
    font-weight: 600;
  }
}

h3 {
  span {
    font-weight: 200;
    color: $pricing-grey;
  }
}

.v-input--selection-controls {
  margin-top: 0;
  padding-top: 0;
}
</style>
