<template>
  <div>
    <v-row class="line-pricing-group-container">
      <v-col class="line-pricing-group-header pr-0 pb-0 pt-0">
        <span>{{ $t('settings.architecture.linePricingGroupHeader') }}</span>
      </v-col>
    </v-row>
    <v-row>
      <v-col cols="10">
        <v-expansion-panels :value="value" @change="onSelectActiveLinePricingGroup">
          <line-pricing-group
            v-for="(linePricingGroup, index) in linePricingGroups"
            :key="index"
            :line-pricing-group="linePricingGroup"
            :disabled="disabled"
            @deleteLinePricingGroup="onDeleteLinePricingGroup"
            @deleteLinePricedProduct="onDeleteLinePricedProduct"
          />
        </v-expansion-panels>
        <v-btn
          class="add-btn"
          color="primary"
          :disabled="disabled"
          small
          text
          icon
          depressed
          @click="addNewLinePricingGroup()"
        >
          <v-icon :disabled="disabled">add_box</v-icon>
        </v-btn>
      </v-col>
    </v-row>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
import { isNil, get } from 'lodash';

export default {
  props: {
    linePricingGroups: {
      type: Array,
      required: true,
    },

    activeLinePricingGroup: {
      type: Object,
      defaut: () => {},
    },

    architectureGroupId: {
      type: String,
      required: true,
    },

    scenarioKey: {
      type: String,
      required: true,
    },

    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      value: null,
    };
  },

  async created() {
    const newTabActiveLinePricingGroupIndex = await this.consumeNewTabActiveLinePricingGroupIndex();
    if (!isNil(newTabActiveLinePricingGroupIndex)) {
      this.onSelectActiveLinePricingGroup(newTabActiveLinePricingGroupIndex);
    }
  },

  methods: {
    ...mapActions('architectureGroup', ['consumeNewTabActiveLinePricingGroupIndex']),
    addNewLinePricingGroup() {
      const newLinePricingGroup = {
        _id: null,
        linePricedGroupDescription: null,
        architectureGroupId: this.architectureGroupId,
        scenarioKey: this.scenarioKey,
        products: [],
      };

      this.linePricingGroups.push(newLinePricingGroup);
    },

    onDeleteLinePricingGroup({ linePricingGroup }) {
      const linePricingGroupKey = get(linePricingGroup, 'linePricingGroupKey');
      if (!linePricingGroupKey) {
        const index = this.linePricingGroups.indexOf(linePricingGroup);

        this.linePricingGroups.splice(index, 1);
      } else {
        this.$emit('deleteLinePricingGroup', linePricingGroupKey);
      }
    },

    onDeleteLinePricedProduct({ linePricedProduct }) {
      if (linePricedProduct.linePricingGroupKey) {
        this.$emit('deleteLinePricedProduct', { linePricedProduct });
      }
      this.activeLinePricingGroup.products = this.activeLinePricingGroup.products.filter(
        p => p.productToolStoreGroupKey !== linePricedProduct.productToolStoreGroupKey
      );
    },

    onSelectActiveLinePricingGroup(activeLinePricingGroupIndex) {
      this.value = activeLinePricingGroupIndex;
      this.$emit('setActiveLinePricingGroupIndex', { activeLinePricingGroupIndex });
    },
  },
};
</script>

<style lang="scss">
@import '@style/base/_variables.scss';

.line-pricing-group-container {
  .v-expansion-panel {
    box-shadow: none;
  }

  .line-pricing-group-header {
    span {
      font-size: 1.4rem;
      font-weight: 600;
    }
  }

  .save-btn.v-btn {
    margin-right: 0;
    text-transform: capitalize;
  }

  .add-btn {
    margin-left: 0;
  }
}
</style>
