import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSimpleTable } from 'vuetify/lib/components/VDataTable';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "span",
    [
      _c(
        VDialog,
        {
          attrs: { width: 800, persistent: "" },
          model: {
            value: _vm.showDialog,
            callback: function ($$v) {
              _vm.showDialog = $$v
            },
            expression: "showDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                {
                  staticClass: "headline lighten-2",
                  attrs: { "primary-title": "" },
                },
                [
                  _vm._v(
                    "\n        " +
                      _vm._s(_vm.$t("alerts.failedRunModalTitle")) +
                      "\n      "
                  ),
                ]
              ),
              _vm._v(" "),
              _c(
                VIcon,
                {
                  staticClass: "close-button",
                  attrs: { color: "grey" },
                  on: { click: _vm.closeDialog },
                },
                [_vm._v("close")]
              ),
              _vm._v(" "),
              _c(
                "div",
                [
                  _c(
                    VCardText,
                    [
                      _c(VSimpleTable, {
                        attrs: { height: "40rem" },
                        scopedSlots: _vm._u([
                          {
                            key: "default",
                            fn: function () {
                              return [
                                _c("thead", [
                                  _c("tr", { staticClass: "header" }, [
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("pricing.category"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("pricing.pricingGroup"))
                                      ),
                                    ]),
                                    _vm._v(" "),
                                    _c("th", { staticClass: "text-left" }, [
                                      _vm._v(
                                        _vm._s(_vm.$t("pricing.scenario"))
                                      ),
                                    ]),
                                  ]),
                                ]),
                                _vm._v(" "),
                                _c(
                                  "tbody",
                                  _vm._l(
                                    _vm.failedScenarios,
                                    function (run, ix) {
                                      return _c(
                                        "tr",
                                        {
                                          key: run.scenarioDescription,
                                          staticClass: "run-row",
                                        },
                                        [
                                          _c(
                                            "td",
                                            { staticClass: "pt-1 pb-1" },
                                            [
                                              _vm._v(
                                                "\n                    " +
                                                  _vm._s(
                                                    _vm.showIfDifferent(
                                                      run.category,
                                                      "category",
                                                      ix,
                                                      _vm.failedScenarios
                                                    )
                                                  ) +
                                                  "\n                  "
                                              ),
                                            ]
                                          ),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              "\n                    " +
                                                _vm._s(
                                                  _vm.showIfDifferent(
                                                    run.pricingGroup,
                                                    "pricingGroup",
                                                    ix,
                                                    _vm.failedScenarios
                                                  )
                                                ) +
                                                "\n                  "
                                            ),
                                          ]),
                                          _vm._v(" "),
                                          _c("td", [
                                            _vm._v(
                                              _vm._s(run.scenarioDescription)
                                            ),
                                          ]),
                                        ]
                                      )
                                    }
                                  ),
                                  0
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(VDivider),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }