'use strict';

module.exports = {
  testUsers: [
    {
      username: 'admin@pricing.com',
      password: 'password',
      roles: ['admin'],
    },
    {
      username: 'unit1@pricing.com',
      password: 'password',
      roles: ['unit-manager'],
    },
    {
      username: 'unit2@pricing.com',
      password: 'password',
      roles: ['unit-manager-admin'],
    },
    {
      username: 'category1@pricing.com',
      password: 'password',
      roles: ['category-manager'],
    },
    {
      username: 'category2@pricing.com',
      password: 'password',
      roles: ['category-manager-admin'],
    },
    {
      username: 'pricingmanager1@pricing.com',
      password: 'password',
      roles: ['pricing-manager'],
    },
    {
      username: 'pricingmanager2@pricing.com',
      password: 'password',
      roles: ['pricing-manager-admin'],
    },
    {
      username: 'pricing1@pricing.com',
      password: 'password',
      roles: ['pricing-specialist'],
    },
    {
      username: 'pricing2@pricing.com',
      password: 'password',
      roles: ['pricing-specialist-admin'],
    },
    {
      username: 'wholesale@pricing.com',
      password: 'password',
      roles: ['wholesale-pricing-analyst'],
    },
    {
      username: 'wholesalemanager@pricing.com',
      password: 'password',
      roles: ['wholesale-pricing-manager'],
    },
    {
      username: 'unitandcategorymanager@pricing.com',
      password: 'password',
      roles: ['unit-manager', 'category-manager'],
    },
  ],
};
