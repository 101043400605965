<!--
Important to note here that we cannot use the <feature-toggle> component.
This is beacuse we also depend on the existence of normWeight to render the tooltip.
Therefore it's safest to just check the toggleLogic explicitly in the displayTooltip computed.
-->
<template>
  <div
    class="d-flex"
    :custom-title-msg="tooltipValue"
    :custom-title-hidden="!displayTooltip"
    custom-title-position="right"
  >
    {{ displayedValue }}
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { truncate } from 'lodash';
import isTooltipDisabled from '../utils/tooltip-util';
import { includeNormWeight } from '@enums/feature-flags';
import numberFormats from '@enums/number-formats';
import tooltipFormatterMixin from '../mixins/tooltipFormatter';

export default {
  mixins: [tooltipFormatterMixin],
  props: {
    value: {
      required: true,
    },
    normWeight: {
      required: true,
    },
    normWeightUoM: {
      required: true,
    },
  },
  data() {
    return {};
  },

  computed: {
    ...mapState('clientConfig', ['toggleLogic']),
    displayTruncatedValue() {
      return !isTooltipDisabled(this.formattedValue, this.truncationLength);
    },
    displayNormWeight() {
      return !!this.normWeight && this.isIncludeNormWeightFeatureFlagEnabled;
    },
    displayTooltip() {
      return this.displayNormWeight || this.displayTruncatedValue;
    },
    formattedValue() {
      return this.formatMetricContainedUnitOfMeasurement(this.value, numberFormats.weight);
    },
    displayedValue() {
      return this.displayTruncatedValue
        ? truncate(this.formattedValue, { length: this.truncationLength })
        : this.formattedValue;
    },
    formattedNormWeight() {
      return this.formatWeight(this.normWeight);
    },
    tooltipValue() {
      if (this.displayTruncatedValue && this.displayNormWeight) {
        return this.formatTooltipMessage({
          [this.$t('pricing.productSizeType')]: this.formattedValue,
          [this.$t('gridView.priceToggles.pricePerNormWeight')]: `${this.formattedNormWeight} ${
            this.normWeightUoM
          }`,
        });
      }
      if (this.displayTruncatedValue) {
        return this.formattedValue;
      }
      if (this.displayNormWeight) {
        return this.formatTooltipMessage({
          [this.$t('gridView.priceToggles.pricePerNormWeight')]: `${this.formattedNormWeight} ${
            this.normWeightUoM
          }`,
        });
      }
      return '';
    },
  },

  created() {
    this.truncationLength = 10;
    this.isIncludeNormWeightFeatureFlagEnabled = this.toggleLogic[includeNormWeight];
  },

  methods: {
    formatWeight(value) {
      return this.formatNumber({
        number: value,
        format: numberFormats.weight,
        useZeroAsDash: true,
      });
    },
  },
};
</script>
