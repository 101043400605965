import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VRow } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VMenu,
    {
      attrs: {
        top: "",
        "close-on-content-click": false,
        "max-height": "15rem",
        "max-width": "30rem",
        "min-width": "30rem",
        "offset-y": "",
      },
      scopedSlots: _vm._u([
        {
          key: "activator",
          fn: function ({ on }) {
            return [
              _c(
                "div",
                _vm._g(
                  {
                    staticClass: "icons-container",
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                      },
                    },
                  },
                  on
                ),
                [
                  _vm.numAlerts > 1
                    ? _c(
                        VAvatar,
                        {
                          staticClass: "pa-2 alerts-count-badge",
                          attrs: { size: "10" },
                        },
                        [
                          _c("span", { staticClass: "white--text" }, [
                            _vm._v(_vm._s(_vm.numAlerts)),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm._v(" "),
                  _vm._m(0),
                ],
                1
              ),
            ]
          },
        },
      ]),
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _vm._v(" "),
      _c(
        VCard,
        { staticClass: "scrolling-card", attrs: { height: "15rem" } },
        [
          _c(
            VCardText,
            { staticClass: "px-0 py-0" },
            _vm._l(_vm.sortedAndFilteredAlerts, function (alert, index) {
              return _c(
                VRow,
                { key: index, staticClass: "border-bottom" },
                [
                  _c(VCol, { staticClass: "px-4 py-1" }, [
                    _c("span", { staticClass: "alerts-text" }, [
                      _c("strong", [_vm._v(_vm._s(alert.metadata))]),
                    ]),
                  ]),
                ],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c(
      VIcon,
      {
        staticClass: "alerts-icon",
        attrs: { size: "2.5rem", color: _vm.alertsIconColor },
      },
      [_vm._v("\n        " + _vm._s(_vm.alertIcon) + "\n      ")]
    )
  },
]
render._withStripped = true

export { render, staticRenderFns }