import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VExpansionPanel } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelContent } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanelHeader } from 'vuetify/lib/components/VExpansionPanel';
import { VExpansionPanels } from 'vuetify/lib/components/VExpansionPanel';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "user-roles elevation-1" },
    [
      _c(
        VDialog,
        {
          attrs: {
            value: _vm.editDialogOpen,
            persistent: "",
            "max-width": "40rem",
            scrollable: "",
          },
        },
        [
          _c(
            VCard,
            { key: _vm.editedUser._id },
            [
              _c(VCardTitle, { staticClass: "headline font-weight-bold" }, [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("userRoles.editor.selectRoles")) +
                    "\n      "
                ),
              ]),
              _vm._v(" "),
              _c(
                VCardText,
                [
                  _c(
                    VExpansionPanels,
                    _vm._l(_vm.treeViewHierarchies, function (storeFormat) {
                      return _c(
                        VExpansionPanel,
                        {
                          key: storeFormat.levelEntryDescription,
                          attrs: { focusable: "" },
                        },
                        [
                          _c(
                            VExpansionPanelHeader,
                            {
                              scopedSlots: _vm._u(
                                [
                                  {
                                    key: "actions",
                                    fn: function () {
                                      return [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { color: "indigo darken-2" },
                                          },
                                          [_vm._v("expand_more")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ],
                                null,
                                true
                              ),
                            },
                            [
                              _c("div", { staticClass: "hierarchy-item" }, [
                                _vm._v(
                                  "\n                " +
                                    _vm._s(storeFormat.levelEntryDescription) +
                                    "\n              "
                                ),
                              ]),
                            ]
                          ),
                          _vm._v(" "),
                          _c(
                            VExpansionPanelContent,
                            [
                              _c(VTreeview, {
                                staticClass: "hierarchy-item",
                                attrs: {
                                  selectable: "",
                                  hoverable: "",
                                  dense: "",
                                  "item-key": "levelEntryKey",
                                  "item-text": "levelEntryDescription",
                                  "open-on-click": "",
                                  "selection-type": "independent",
                                  items: storeFormat.children,
                                },
                                model: {
                                  value:
                                    _vm.accessByStoreFormat[
                                      storeFormat.levelEntryDescription
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.accessByStoreFormat,
                                      storeFormat.levelEntryDescription,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "accessByStoreFormat[storeFormat.levelEntryDescription]",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCardActions,
                { staticClass: "btn-group" },
                [
                  _c(VSpacer),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "cancel-btn",
                      attrs: { text: "", outlined: "", depressed: "" },
                      on: { click: _vm.closeEditDialog },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "save-btn save",
                      attrs: { color: "success", depressed: "" },
                      on: { click: _vm.updateUser },
                    },
                    [_vm._v(_vm._s(_vm.$t("actions.save")))]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VRow,
        { staticClass: "search-box" },
        [
          _c(VTextField, {
            attrs: {
              value: _vm.search,
              "append-icon": "search",
              label: _vm.$t("actions.search"),
              "single-line": "",
              "hide-details": "",
              dense: "",
            },
            on: { input: _vm.debounceSearchUpdate },
          }),
        ],
        1
      ),
      _vm._v(" "),
      _c(
        VDataTable,
        {
          staticClass: "pricing-table elevation-1",
          attrs: {
            items: _vm.search ? _vm.searchResults : _vm.categoryAccessList,
            headers: _vm.headers,
            loading: _vm.loading,
            options: _vm.pagination,
            "sort-desc": _vm.pagination.descending,
            dense: "",
            "item-key": "userid",
            "hide-default-header": "",
            "hide-default-footer": "",
          },
          on: {
            "update:options": function ($event) {
              _vm.pagination = $event
            },
            "update:sortDesc": function ($event) {
              return _vm.$set(_vm.pagination, "descending", $event)
            },
            "update:sort-desc": function ($event) {
              return _vm.$set(_vm.pagination, "descending", $event)
            },
          },
          nativeOn: {
            mousewheel: function ($event) {
              return _vm.preventPageNavigationOnHorizontalScroll(
                $event,
                ".v-data-table"
              )
            },
          },
          scopedSlots: _vm._u([
            {
              key: "header",
              fn: function ({ props }) {
                return [
                  _c(
                    "tr",
                    { staticClass: "main-header v-data-table-header" },
                    _vm._l(props.headers, function (header) {
                      return _c(
                        "th",
                        {
                          key: header.value,
                          staticClass: "sortable py-2",
                          class: [
                            _vm.pagination.descending ? "desc" : "asc",
                            header.value === _vm.pagination.sortBy
                              ? "active"
                              : "",
                            header.class,
                          ],
                          on: {
                            click: function ($event) {
                              return _vm.changeSort(header)
                            },
                          },
                        },
                        [
                          _c("span", [_vm._v(_vm._s(_vm.$t(header.text)))]),
                          _vm._v(" "),
                          header.sortable
                            ? _c(
                                VIcon,
                                {
                                  staticClass: "v-data-table-header__icon",
                                  attrs: { size: "1.4rem" },
                                },
                                [
                                  _vm._v(
                                    "\n            arrow_upward\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                ]
              },
            },
            {
              key: "item",
              fn: function (props) {
                return [
                  _c("tr", { class: { "even-rows": props.index % 2 } }, [
                    _c("td", { staticClass: "text-xs table-cell" }, [
                      _vm._v(_vm._s(props.item.userid)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs table-cell" }, [
                      _vm._v(_vm._s(props.item.email)),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs table-cell" }, [
                      _vm._v(
                        _vm._s(
                          _vm.formatUserRoles(
                            _vm.getUserRoles(props.item.roles)
                          )
                        )
                      ),
                    ]),
                    _vm._v(" "),
                    _c("td", { staticClass: "text-xs table-cell" }, [
                      _c("span", { staticClass: "mr-1" }, [
                        _c(
                          "i",
                          {
                            staticClass:
                              "v-icon notranslate mr-2 v-icon--link material-icons",
                            on: {
                              click: function ($event) {
                                return _vm.editUser(props.item)
                              },
                            },
                          },
                          [_vm._v("\n              edit\n            ")]
                        ),
                      ]),
                      _vm._v(" "),
                      _c("span", [
                        _vm._v(
                          "\n            " +
                            _vm._s(
                              _vm._f("truncate")(
                                _vm.formatExistingAccess(
                                  props.item.hierarchyAccess
                                ),
                                _vm.accessTruncationLength
                              )
                            ) +
                            "\n          "
                        ),
                      ]),
                    ]),
                    _vm._v(" "),
                    _c(
                      "td",
                      { staticClass: "text-xs table-cell" },
                      [
                        _c(
                          "tooltip",
                          {
                            attrs: {
                              value: _vm.$t("userRoles.editor.disabled"),
                            },
                          },
                          [
                            _c(VCheckbox, {
                              staticClass: "mt-0",
                              attrs: {
                                dense: "",
                                "hide-details": "",
                                disabled: "",
                                "input-value": _vm.isAdmin(props.item.roles),
                              },
                            }),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                  ]),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                VRow,
                [
                  _c(
                    VCol,
                    { attrs: { md: "3", "offset-md": "8" } },
                    [
                      _c(VPagination, {
                        attrs: { length: _vm.pages },
                        on: { input: _vm.loadUserProfiles },
                        model: {
                          value: _vm.pagination.page,
                          callback: function ($$v) {
                            _vm.$set(_vm.pagination, "page", $$v)
                          },
                          expression: "pagination.page",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }