var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "pricing-tooltip" },
    [
      _vm._t("default"),
      _vm._v(" "),
      !_vm.disabled
        ? _c("div", { class: _vm.setClass }, [
            _vm.valueIsStringOrNumber(_vm.value)
              ? _c("span", {
                  staticClass: "tooltip-text",
                  domProps: {
                    innerHTML: _vm._s(_vm.formatValueForLineBreaks(_vm.value)),
                  },
                })
              : _vm._e(),
            _vm._v(" "),
            _vm.valueIsObject(_vm.value)
              ? _c(
                  "div",
                  { staticClass: "tooltip-list" },
                  _vm._l(_vm.value, function (val, propName) {
                    return _c(
                      "div",
                      { key: propName, staticClass: "tooltip-list-item" },
                      [
                        _c("span", [_vm._v(_vm._s(propName) + ":")]),
                        _vm._v(" "),
                        _c("span", [_vm._v(_vm._s(val))]),
                      ]
                    )
                  }),
                  0
                )
              : _vm._e(),
          ])
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }