'use strict';

const moment = require('moment');

const DATE_PARTS = {
  YEAR: 'year',
  MONTH: 'month',
  DAY: 'day',
};

/** time zone shift in minutes */
const timeZonesShiftInMinutes =
  (new Date('Dec 31, 1900 00:00:00').getTime() -
    new Date(`Dec 31, 1900 00:00:00 GMT${moment().format('Z')}`).getTime()) /
  60000;
/** time corrections for XLSX library */
const timeCorrections =
  Number((60 * (timeZonesShiftInMinutes - Number(timeZonesShiftInMinutes.toFixed(0)))).toFixed(0)) *
  1000;

/**
 * SheetJs doesn't process timezones properly (https://github.com/SheetJS/sheetjs/issues/1212).
 * Several seconds are added / subtracted from the date depending on the time zone. Adding / subtracting time corrections compensates for these changes.
 * @example
 * Timezone: +02:00
 * SheetJs saves 00:04 instead of 00:00
 * @param {Moment} date
 */
const prepareDateForXLSX = date => {
  return new Date(date.valueOf() + timeCorrections);
};

/**
 * Returns start of day depending on timezone, daylight saving time.
 * The returned date (after saving) will be displayed in Excel without regard to time.
 * @param {Moment} utcDate
 * @param {boolean} isDaylightSavingTime
 */
const getStartOfDayForExport = (utcDate, isDaylightSavingTime) => {
  const startOfDay = utcDate.local().startOf('day');
  if (isDaylightSavingTime) {
    startOfDay.add(1, 'hours');
  }
  return startOfDay;
};

const doesDateMeetRequirements = isDatePartCorrect => {
  return (
    isDatePartCorrect(DATE_PARTS.YEAR) &&
    isDatePartCorrect(DATE_PARTS.MONTH) &&
    isDatePartCorrect(DATE_PARTS.DAY)
  );
};

/**
 * Checks if a moment is after or the same as another moment. Compares moments by date parts (year, month, day).
 * Warning! Comparison without regard to time and time zone.
 * @param date1 moment that compares to date 2
 * @param date2 moment
 * @return {boolean}
 */
const isSameOrAfter = (date1, date2) => {
  if (!date1 || !date2) {
    return false;
  }
  const isDatePartCorrect = part => date1.isSameOrAfter(date2, part);
  return doesDateMeetRequirements(isDatePartCorrect);
};

module.exports = {
  isSameOrAfter,
  getStartOfDayForExport,
  prepareDateForXLSX,
};
