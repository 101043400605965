<template>
  <span>
    <v-btn
      color="primary"
      small
      depressed
      class="pa-0"
      :loading="busyImportingCompetitor"
      @click.stop="initiateModal()"
    >
      {{ $t('competitorMatches.upload.uploadButton') }}
      <v-icon small>$import</v-icon>
    </v-btn>
    <v-dialog v-model="uploadDialog" width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('competitorMatches.upload.dialogHeading') }}
        </v-card-title>
        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          source="inputs"
          @save="save"
        />
        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    {{ $t(competitorsUploadedMessage) }}:
                    <b>{{ counts.articlesReceived }}</b>
                    <br />
                    {{ $t('attributes.upload.confirmUpload') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" outlined @click="closeModal()">{{ $t('actions.cancel') }}</v-btn>
            <v-btn
              class="primary"
              :disabled="isUploading"
              :loading="busyImportingCompetitor"
              @click="confirm()"
            >
              {{ $t('actions.upload') }}
            </v-btn>
          </v-card-actions>
        </div>
        <div v-if="isUploading">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">
                    {{ $t('attributes.upload.uploadingState') }}
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>
        <div v-if="isFailed && uploadError">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div v-if="uploadError.noCompetitors" class="flex xs12">
                  <div class="v-input">
                    {{ $t(noCompetitorsErrorMessage) }}
                  </div>
                </div>
                <div v-if="uploadError.noPrimaryColumn" class="flex xs12">
                  <div class="v-input">
                    {{ $t(`competitorMatches.upload.noPrimaryColumn`) }}
                  </div>
                </div>
                <div v-if="uploadError.brokenValidations" class="flex xs12">
                  <div class="v-input">
                    {{ $t(`competitorMatches.upload.brokenValidationsError`) }}
                  </div>
                </div>
                <div v-if="uploadError.tooManyRows" class="flex xs12">
                  <div class="v-input">
                    {{ $t(`competitorMatches.upload.tooManyRows`, { maxRows }) }}
                  </div>
                </div>
                <div class="flex xs12">
                  <upload-error-card
                    v-if="hasProductKeysWithInvalidPriceError"
                    :error-message="$t('competitorMatches.upload.productKeysWithInvalidPrice')"
                    :details="uploadError.productKeysWithInvalidPrice"
                    unique-key-prefix="productKeysWithInvalidPrice"
                  />
                  <upload-error-card
                    v-if="hasProductKeysWithInvalidEnabledError"
                    :error-message="$t('competitorMatches.upload.productKeysWithInvalidEnabled')"
                    :details="uploadError.productKeysWithInvalidEnabled"
                    unique-key-prefix="productKeysWithInvalidEnabled"
                  />
                </div>
                <!-- FEATURE_FLAG: display zones-related validation errors -->
                <feature-toggle :toggle="useZones">
                  <upload-error-card
                    v-if="hasConflictedToolStoreGroupKeysError"
                    :error-message="$t('competitorMatches.upload.conflictedToolStoreGroupKeys')"
                    :details="uploadError.conflictedToolStoreGroupKeys"
                    unique-key-prefix="conflictToolStoreGroupKeys"
                  />
                  <upload-error-card
                    v-if="hasIncorrectTSGsError"
                    :error-message="$t('competitorMatches.upload.incorrectToolStoreGroups')"
                    :details="uploadError.productKeysWithIncorrectTSGs"
                    unique-key-prefix="incorrectToolStoreGroups"
                  />
                </feature-toggle>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeModal()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import { get, isEmpty, invert } from 'lodash';
import states from '@enums/upload-states';
import { useZones } from '@enums/feature-flags';

export default {
  props: {
    params: {
      type: Object,
      default: () => {},
    },
    enabledValueTranslations: {
      type: Object,
      default: () => {},
    },
    isMasterWorkpackage: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      uploadDialog: false,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
      useZones,
    };
  },

  computed: {
    ...mapState('attributes', ['counts', 'busyImportingCompetitor']),
    ...mapState('clientConfig', ['uploadConfig']),

    maxRows() {
      return this.uploadConfig.attributeUpload.maxRows;
    },
    isInitial() {
      return this.currentStatus === states.initial;
    },
    isSaving() {
      return this.currentStatus === states.saving;
    },
    isValidated() {
      return this.currentStatus === states.validated;
    },
    isFailed() {
      return this.currentStatus === states.failed;
    },
    isUploading() {
      return this.currentStatus === states.uploading;
    },
    hasConflictedToolStoreGroupKeysError() {
      return !isEmpty(get(this.uploadError, 'conflictedToolStoreGroupKeys', []));
    },
    hasIncorrectTSGsError() {
      return !isEmpty(get(this.uploadError, 'productKeysWithIncorrectTSGs', []));
    },
    hasProductKeysWithInvalidPriceError() {
      return !isEmpty(get(this.uploadError, 'productKeysWithInvalidPrice', []));
    },
    hasProductKeysWithInvalidEnabledError() {
      return !isEmpty(get(this.uploadError, 'productKeysWithInvalidEnabled', []));
    },
  },

  created() {
    const pathToTranslations = 'competitorMatches.upload';
    this.competitorsUploadedMessage = this.isMasterWorkpackage
      ? `${pathToTranslations}.manualCompetitorsUploaded`
      : `${pathToTranslations}.competitorsUploaded`;
    this.noCompetitorsErrorMessage = this.isMasterWorkpackage
      ? `${pathToTranslations}.noManualCompetitors`
      : `${pathToTranslations}.noCompetitors`;
  },

  methods: {
    ...mapActions('attributes', ['uploadCompetitors', 'confirmCompetitorUpload']),

    initiateModal() {
      this.uploadDialog = true;
      this.reset();
    },
    closeModal() {
      this.uploadDialog = false;
      this.reset();
    },
    save(formData) {
      // send localised column headers
      formData.append('competitorNameColumn', this.$t('competitorMatches.editor.competitorName'));
      formData.append('competitorPriceColumn', this.$t('competitorMatches.editor.price'));
      formData.append('competitorEnabledColumn', this.$t('competitorMatches.editor.enabled'));
      formData.append(
        'enabledValueTranslations',
        JSON.stringify(invert(this.enabledValueTranslations))
      );
      this.currentStatus = states.saving;
      return this.uploadCompetitors({ dataFile: formData, params: this.params })
        .then(() => {
          this.currentStatus = states.validated;
        })
        .catch(err => {
          this.uploadError = err;
          this.currentStatus = states.failed;
        });
    },
    confirm() {
      this.currentStatus = states.uploading;
      return this.confirmCompetitorUpload({ params: this.params })
        .then(() => {
          this.closeModal();
          this.reset();
        })
        .catch(() => {
          this.currentStatus = states.failed;
        });
    },
    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}
</style>
