<template>
  <i
    class="mdi"
    :class="[
      isExpanded
        ? params.expandedStateIcon || 'mdi-chevron-down'
        : params.collapsedStateIcon || 'mdi-chevron-right',
      ...params.classes,
    ]"
    v-on="isLink ? { click: onClick } : {}"
  />
</template>

<script>
import _ from 'lodash';
import Vue from 'vue';

export default Vue.extend({
  computed: {
    isLink() {
      return _.has(this.params, 'clickHandler');
    },

    isExpanded() {
      return _.has(this.params, 'isExpanded')
        ? this.params.isExpanded()
        : this.params.data[this.params.fieldName || 'isExpanded'];
    },
  },

  methods: {
    onClick() {
      this.params.clickHandler(this.params.data);
    },
  },
});
</script>
