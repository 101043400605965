<template>
  <div class="align-self-center h-fit-content">
    <input
      ref="gridsUploadInput"
      type="file"
      accept="application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,application/vnd.ms-excel"
      hidden
      @change="onFileInputChange"
    />
    <v-btn
      color="success"
      class="save ml-2"
      small
      depressed
      :disabled="disabled"
      :loading="isInProgress"
      @click="initImportGrids"
    >
      {{ $t('wholesale.importGrids') }}
    </v-btn>

    <v-dialog :value="isDialogOpen" persistent width="500">
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('wholesale.import.dialogHeading') }}
        </v-card-title>

        <div>
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <upload-error-card
                    v-if="error"
                    :error-message="error.header"
                    :details="error.text"
                    unique-key-prefix="importGridsError"
                  />
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="reset()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </div>
</template>

<script>
import { isEmpty, has } from 'lodash';
import { mapActions, mapState, mapMutations, mapGetters } from 'vuex';
import { reduceGridsToRowsForExport } from '@sharedModules/data/utils/export-utils';
import {
  readXLSXSheetFromArrayBuffer,
  makeGridDescriptionToIdMap,
  reduceGridRowsToMap,
  validateNewGridRowsMap,
  getRowsMapsDiff,
} from '@sharedModules/data/utils/import-utils';
import * as VALIDATION_ERROR from '@enums/wholesale-import-validation-error';

const Stage = {
  Default: 'Default',
  InProgress: 'InProgress',
  Failed: 'Failed',
};

export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      stage: Stage.Default,
      error: null,
    };
  },

  computed: {
    ...mapState('clientConfig', ['wholesaleConfig']),
    ...mapState('wholesale', ['wholesaleHierarchyIdGridMap', 'wholesaleHierarchyTree']),
    ...mapGetters('storeGroupRelationships', ['toolStoreGroupsDescriptionMap']),

    isDialogOpen() {
      return this.stage === Stage.Failed;
    },

    isInProgress() {
      return this.stage === Stage.InProgress;
    },

    isFailed() {
      return this.stage === Stage.Failed;
    },
  },

  methods: {
    ...mapActions('wholesaleGrid', [
      'fetchWholesaleGridList',
      'fetchMarginSplits',
      'applyWholesaleGridByDiff',
    ]),
    ...mapMutations('notifications', ['addNotification']),

    mapValidationError(validationError) {
      switch (validationError.type) {
        case VALIDATION_ERROR.nanOrNegativeInRowForKey:
          return {
            header: this.$t('wholesale.import.error.nanOrNegativeInRowForKey.header'),
            text: validationError.value.badColumns.map(item => {
              return this.$t('wholesale.import.error.nanOrNegativeInRowForKey.text', {
                value: item.cellValue,
                column: this.wholesaleConfig.gridValuesRanges[item.column].displayedText,
              });
            }),
          };

        default:
          return {
            text: [
              this.$t(`wholesale.import.error.${validationError.type}`, {
                fullKey: validationError.value.join(' '),
              }),
            ],
            header: this.$t('wholesale.import.errorHeading'),
          };
      }
    },

    setValidationError(validationError) {
      this.stage = Stage.Failed;
      this.error = this.mapValidationError(validationError);
    },

    setError({ errorKey = '', headerFullKey = 'wholesale.import.errorHeading' }) {
      this.stage = Stage.Failed;

      if (errorKey) {
        this.error = {
          text: [this.$t(`wholesale.import.error.${errorKey}`)],
          header: this.$t(headerFullKey),
        };
      } else {
        this.error = {
          text: [this.$t(headerFullKey)],
          header: '',
        };
      }
    },

    setInProgress() {
      this.stage = Stage.InProgress;
    },

    setSuccess() {
      this.showNotification('finished');
      this.reset();
    },

    reset() {
      this.stage = Stage.Default;
      this.errorText = '';
      this.$refs.gridsUploadInput.value = '';
    },

    async prepareCurrentGridRowsMap() {
      if (isEmpty(this.wholesaleHierarchyIdGridMap) || isEmpty(this.wholesaleHierarchyTree)) {
        await this.fetchMarginSplits();
      }

      const gridList = await this.fetchWholesaleGridList();
      const gridDescriptionToIdMap = makeGridDescriptionToIdMap(gridList);

      const gridRows = reduceGridsToRowsForExport({
        gridList,
        gridValuesRanges: this.wholesaleConfig.gridValuesRanges,
        defaultGridValue: this.wholesaleConfig.defaultGridValue,
        toolStoreGroupsDescriptionMap: this.toolStoreGroupsDescriptionMap,
        skipHeader: true,
      });

      const gridRowsMap = reduceGridRowsToMap(gridRows);

      return {
        gridRowsMap,
        gridDescriptionToIdMap,
      };
    },

    readGridFromFile(fileInfo) {
      return new Promise((resolve, reject) => {
        if (!fileInfo) {
          reject(null);
          return;
        }

        const fileReader = new FileReader();
        fileReader.onload = loadResult => resolve(loadResult.target.result);
        fileReader.onerror = error => reject(error);
        fileReader.readAsArrayBuffer(fileInfo);
      });
    },

    showNotification(status, notificationPayload = null) {
      this.addNotification({
        id: `${new Date()}gridImport`,
        jobStatus: status,
        baseTranslation: 'notifications.import_grids',
        notificationPayload: notificationPayload || this.$t('wholesale.import.error.unknown'),
      });
    },

    async onFileInputChange(event) {
      this.setInProgress();

      let newGridRaw;
      try {
        // to correctly wrap the loading lock.
        newGridRaw = await this.readGridFromFile(event.target.files[0]);
      } catch (error) {
        this.setError({ errorKey: 'fileRead' });
        return;
      }

      if (!newGridRaw) {
        // suppress on cancel
        this.reset();
        return;
      }

      this.importGrids(newGridRaw);
    },

    async initImportGrids() {
      // trigger file input
      this.$refs.gridsUploadInput.click();
    },

    async importGrids(newGridRaw) {
      let newGridRowsMap;
      try {
        const newGridRows = readXLSXSheetFromArrayBuffer(
          newGridRaw,
          'Grids',
          this.wholesaleConfig.gridValuesRanges
        );
        newGridRowsMap = reduceGridRowsToMap(newGridRows);
      } catch (e) {
        if (has(e, 'message') && has(VALIDATION_ERROR, e.message)) {
          this.setError({ errorKey: e.message });
          return;
        }

        this.setError({ errorKey: 'fileRead' });
        return;
      }

      try {
        const { gridRowsMap, gridDescriptionToIdMap } = await this.prepareCurrentGridRowsMap();

        const error = validateNewGridRowsMap(gridRowsMap, newGridRowsMap);
        if (error) {
          this.setValidationError(error);
          return;
        }

        const gridListDiff = getRowsMapsDiff({
          gridDescriptionToIdMap,
          toolStoreGroupsDescriptionMap: this.toolStoreGroupsDescriptionMap,
          // Warning: in case of different upperLimits will override them.
          gridValuesRanges: this.wholesaleConfig.gridValuesRanges,
        })({
          srcGridRowsMap: gridRowsMap,
          newGridRowsMap,
        });

        if (isEmpty(gridListDiff)) {
          this.setError({
            headerFullKey: 'wholesale.import.error.noDiff',
          });
          return;
        }

        await this.applyWholesaleGridByDiff({ gridListDiff });
      } catch (e) {
        this.setError({ errorKey: 'diffFlow' });
      }

      this.setSuccess();
    },
  },
};
</script>

<style lang="scss">
.h-fit-content {
  height: fit-content;
}

/* Due to: https://github.com/vuetifyjs/vuetify/issues/12731  */
.v-progress-circular--indeterminate[role='progressbar'] > svg {
  animation-play-state: running !important;
}
</style>
