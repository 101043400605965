<template>
  <v-btn
    color="success"
    class="align-self-center"
    small
    depressed
    :loading="storeGroupsUpdating"
    @click="initUpdate"
  >
    {{ $t('wholesale.update_grid_storegroups.button') }}
  </v-btn>
</template>

<script>
import { mapActions, mapState, mapGetters, mapMutations } from 'vuex';
import { isEmpty, flatten } from 'lodash';
import to from 'await-to-js';
import { findNewStoreGroups } from '@sharedModules/data/utils/import-utils';

export default {
  data() {
    return {
      storeGroupsUpdating: false,
    };
  },

  computed: {
    ...mapState('clientConfig', ['wholesaleConfig']),
    ...mapGetters('storeGroupRelationships', ['hardcodedStoreGroups', 'allHardcodedStoreGroups']),

    ...mapGetters('workpackages', ['masterWorkpackages']),
  },

  methods: {
    ...mapActions('workpackages', ['fetchWorkpackages']),
    ...mapActions('wholesaleGrid', ['fetchWholesaleGridList', 'updateGridsStoreGroups']),
    ...mapMutations('notifications', ['addNotification']),

    async initUpdate() {
      this.storeGroupsUpdating = true;
      await this.updateStoreGroups();
      this.storeGroupsUpdating = false;
    },

    async updateStoreGroups() {
      this.showNotification('starting');

      if (!this.masterWorkpackages.length) {
        const [wpFetchError] = await to(this.fetchWorkpackages());

        if (wpFetchError) {
          this.notifyFailed('fetchWorkpackages');
          return;
        }
      }

      const gridListsFetch = this.masterWorkpackages.map(async wp => {
        return this.fetchWholesaleGridList(wp._id);
      });
      const [gridListFetchErr, gridLists] = await to(Promise.all(gridListsFetch));
      if (gridListFetchErr) {
        this.notifyFailed('fetchGridList');
        return;
      }

      let newStoreGroupsDataMap;

      try {
        newStoreGroupsDataMap = findNewStoreGroups({
          masterWorkpackagesInfoList: this.masterWorkpackages,
          allHardcodedStoreGroups: this.allHardcodedStoreGroups,
          gridList: flatten(gridLists),
        });
      } catch (e) {
        this.notifyFailed('findNewStoreGroups');
        return;
      }

      if (isEmpty(newStoreGroupsDataMap)) {
        this.notifyFailed('noNewStoreGroups');
        return;
      }

      const [updateGridsErr] = await to(this.updateGridsStoreGroups({ newStoreGroupsDataMap }));
      if (updateGridsErr) {
        this.notifyFailed('updateGridsStoreGroups');
        return;
      }

      this.showNotification('finished');
    },

    showNotification(status, notificationPayload = null) {
      this.addNotification({
        id: `${new Date()}gridsStoreGroupsUpdate`,
        jobStatus: status,
        baseTranslation: 'notifications.update_grid_storegroups',
        notificationPayload:
          notificationPayload || this.$t('wholesale.update_grid_storegroups.error.unknown'),
      });
    },

    notifyFailed(errorKey) {
      this.showNotification(
        'failed',
        this.$t(`wholesale.update_grid_storegroups.error.${errorKey}`)
      );
    },
  },
};
</script>
