'use strict';

// Keep the same order as priceToggle

module.exports = {
  economicPenalty: 'economicPenalty',
  architecturePenalty: 'architecturePenalty',
  competitorPenalty: 'competitorPenalty',
  storeGroupPenalty: 'storeGroupPenalty',
  pricePointing: 'pricePointing',
};
