import axios from 'axios';
import to from 'await-to-js';
import { size, get } from 'lodash';
import downloadXlsxFile from '../utils/download-xlsx-file';
import { formatFilters } from '../utils/filters';
import { getCompareProductsFunc } from '../../utils/sort-store-groups-util';
import sortDirectionEnums from '@enums/sort-direction';
import { useZones } from '@enums/feature-flags';

const getInitialState = () => {
  return {
    priceOverrides: [],
    downloading: false,
    loading: false,
    count: 0,
    priceOverridesFetchParams: {},
    busyImportingPriceOverride: false,
    uploadError: {},
    uploadCounts: {},
  };
};

const store = {
  namespaced: true,

  state: getInitialState(),

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setPriceOverrides(state, priceOverrides) {
      state.priceOverrides = priceOverrides;
    },

    setDownloading(state, downloading) {
      state.downloading = downloading;
    },

    setLoading(state, loading) {
      state.loading = loading;
    },

    setBusyImportingPriceOverride(state, importing) {
      state.busyImportingPriceOverride = importing;
    },

    setPriceOverridesCount(state, count) {
      state.count = count;
    },

    setPriceOverridesFetchParams(state, params) {
      state.priceOverridesFetchParams = params;
    },

    setErrorState(state, error) {
      state.uploadError = error;
    },

    setUploadCounts(state, counts) {
      state.uploadCounts = counts;
    },
  },

  actions: {
    async downloadPriceOverrides(
      { rootState, commit },
      { translationMap = {}, where = [], columnFormatters = {} }
    ) {
      commit('setDownloading', true);
      const params = {
        formatForExport: true,
        translationMap,
        where: formatFilters({ where, rootState }),
        pick: [
          'productKey',
          'productKeyDisplay',
          'productDescription',
          'hierarchy',
          'toolStoreGroupDescription',
          'toolStoreGroupKey',
          'overridePrice',
          'optimizedPrice',
        ],
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/aggregated-scenario-results/workpackage/${workpackageId}`, {
          params,
        })
      );
      commit('setDownloading', false);
      if (err) throw new Error(err.message);
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, 'price_overrides.xlsx', {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
    },

    resetState({ commit }) {
      commit('resetState');
    },

    async fetchPriceOverrides(
      { rootState, commit, dispatch },
      { params = {}, sortingOnly = false } = {}
    ) {
      commit('setLoading', true);
      const useZonesFlag = rootState.clientConfig.toggleLogic[useZones];
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const storeGroupsNumber =
        rootState.workpackages.selectedWorkpackageToolStoreGroups.length || 1;
      const storeGroupOrderConfig = rootState.clientConfig.storeGroupOrderConfig;
      const isAdditionalSortingByToolStoreGroupRequired = useZonesFlag && storeGroupsNumber > 1;
      const numberOfAdditionalItemsToFetch = isAdditionalSortingByToolStoreGroupRequired
        ? storeGroupsNumber - 1
        : 0;
      const { where } = params;
      dispatch('filters/setInputScreensFilterRules', where, { root: true });
      params.where = formatFilters({ where, rootState });
      params.pick = [
        'productKey',
        'productKeyDisplay',
        'productDescription',
        'hierarchy',
        'toolStoreGroupDescription',
        'toolStoreGroupKey',
        'overridePrice',
        'optimizedPrice',
        'scenarioPrice',
      ];
      // don't use for count requests, don't save to filters/inputScreensFetchParams
      const modifiedParams =
        params && params.limit
          ? {
              ...params,
              limit: params.limit + numberOfAdditionalItemsToFetch,
            }
          : params;
      const queries = [
        to(
          axios.get(`/api/aggregated-scenario-results/workpackage/${workpackageId}`, {
            params: modifiedParams,
          })
        ),
      ];

      if (!sortingOnly) {
        // if fetching after a sort change, don't query the count
        queries.push(dispatch('getPriceOverridesCount', { params }));
      }

      const [[err, response]] = await Promise.all(queries);
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      }
      const { data: priceOverrides = [] } = response;
      const compareFunc = getCompareProductsFunc({
        sortByField: params.sortBy,
        sortDirection: params.sortDirection,
        dataType: params.sortDataType,
        storeGroupOrderConfig,
        compareByToolStoreGroup: true,
      });
      const sortedPriceOverrides = isAdditionalSortingByToolStoreGroupRequired
        ? priceOverrides.sort(compareFunc).slice(0, params.limit)
        : priceOverrides;
      // cannot sort by this on other pages
      if (get(params, 'sortBy', '').includes('overridePrice.')) {
        params.sortBy = 'productKeyDisplay';
        params.sortDirection = sortDirectionEnums.ascending;
        params.offset = 0;
      }
      params.sortBy = get(params, 'sortBy', '').replace('competitorPrices.', 'competitor.');
      commit('filters/setInputScreensFetchParams', params, { root: true });
      commit('setPriceOverrides', sortedPriceOverrides);
      commit('setLoading', false);
    },

    async getPriceOverridesCount({ rootState, commit }, { params = {} } = {}) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, { data }] = await to(
        axios.get(`/api/aggregated-scenario-results/workpackage/${workpackageId}/count`, { params })
      );
      if (err) throw new Error(err.message);
      commit('setPriceOverridesCount', data.count);
    },

    async postPriceOverridesUpload({ dispatch, state, commit }) {
      commit('setBusyImportingPriceOverride', false);
      // eslint-disable-next-line
      this._vm.$eventBus.$emit('inputsUploaded');
      // inputs v2 don't use priceOverridesFetchParams for filtering.
      // they will be updated with $emit('inputsUploaded')
      // TODO: deprecate fetchPriceOverrides after inputs v1 is removed
      if (!state.priceOverridesFetchParams.limit) {
        dispatch('fetchPriceOverrides', {
          params: state.priceOverridesFetchParams,
        });
      }
    },

    async uploadPriceOverrides(
      { rootState, commit },
      { dataFile, params = {}, translationMap = {} }
    ) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      params.pick = [
        'productKey',
        'productKeyDisplay',
        'productDescription',
        'hierarchy',
        'toolStoreGroupDescription',
        'toolStoreGroupKey',
        'overridePrice',
      ];
      commit('setPriceOverridesFetchParams', params);
      const patchParams = {
        translationMap,
      };
      const [err, res] = await to(
        axios.patch(
          `/api/aggregated-scenario-results/workpackage/${workpackageId}/import-price-overrides`,
          dataFile,
          {
            params: patchParams,
            headers: {
              // Now axios 1.x set the Content-Type to 'application/json' automatically so in order to have the value as 'FormData/HTMLForm' we need to set as undefined
              // You can read more about in the axios migration guide to v1.x on https://github.com/bmuenzenmeyer/axios-1.0.0-migration-guide?tab=readme-ov-file#multipart-form-data-is-no-longer-automatically-set
              'Content-Type': undefined,
            },
          }
        )
      );
      if (err) throw err.response.data;

      if (res.data.error) {
        commit('setErrorState', res.data.error);
      } else {
        commit('setUploadCounts', res.data.counts);
      }
    },

    async uploadPriceOverridesConfirm({ rootState, commit }) {
      commit('setBusyImportingPriceOverride', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err] = await to(
        axios.patch(
          `/api/aggregated-scenario-results/workpackage/${workpackageId}/import-price-overrides/confirm`
        )
      );
      if (err) throw new Error(err.message);
    },

    async updatePriceOverrides({ rootState, dispatch, commit }, { updates, params }) {
      if (!size(updates)) return;
      commit('setLoading', true);
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;

      const [err, data] = await to(
        axios.patch(`/api/aggregated-scenario-results/workpackage/${workpackageId}`, updates)
      );
      if (err) {
        commit('setLoading', false);
        throw new Error(err.message);
      } else {
        await dispatch('fetchPriceOverrides', {
          params,
        });
        commit('setLoading', false);
        return data;
      }
    },
  },
};

export default store;
