<template>
  <div v-show="hasSelectedWorkpackage" fliud>
    <div class="sticky-header-group pa-2">
      <div class="page-rule-heading pr-10 d-inline-flex">
        {{ $t('settings.penaltyRules.pageHeading') }}
      </div>
      <scenario-filter class="d-inline-flex" @selectScenario="selectScenario" />
      <div v-if="scenario" class="page-rule-copy d-inline-flex">
        {{ $t('settings.penaltyRules.marginLever') }}:
        <b v-if="marginLever" class="pl-2">
          {{ formatNumber({ number: marginLever, format: numberFormats.priceFormat }) }}%
        </b>
      </div>
    </div>
    <rulesets v-if="isRulesEnabled" />
  </div>
</template>

<script>
import { mapState, mapActions, mapGetters } from 'vuex';
import numberFormats from '@enums/number-formats';

export default {
  data() {
    return {
      marginLever: 0,
      numberFormats,
    };
  },

  computed: {
    ...mapState('filters', ['pricingGroup', 'scenario']),
    ...mapGetters('workpackages', ['hasSelectedWorkpackage']),

    isRulesEnabled() {
      return !!this.pricingGroup && !!this.scenario;
    },
  },

  async created() {
    if (this.scenario && this.hasSelectedWorkpackage) {
      this.setMarginLever();
    }
  },
  methods: {
    ...mapActions('rules', ['fetchRules']),
    ...mapActions('scenarioMetadata', ['getSelectedScenario']),

    async selectScenario({ filter }) {
      this.fetchRules(filter);

      if (this.scenario) {
        this.setMarginLever();
      }
    },
    async setMarginLever() {
      const selectedScenario = await this.getSelectedScenario();
      this.marginLever = selectedScenario.marginLever;
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.sticky-header-group {
  z-index: 80;
}
</style>
