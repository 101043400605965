var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c("work-packages", {
        attrs: {
          heading: "home.templateHeading",
          "filtered-workpackages": _vm.masterWorkpackages,
          "sort-desc": false,
          "allow-new": false,
          "search-workpackages": false,
          "e2e-slug": "panelTemplateWP",
        },
      }),
      _vm._v(" "),
      _c("work-packages", {
        attrs: {
          heading: "home.activeHeading",
          "filtered-workpackages": _vm.activeWorkpackages,
          "sort-desc": false,
          "allow-new": true,
          "e2e-slug": "panelActiveWP",
        },
      }),
      _vm._v(" "),
      _c("work-packages", {
        attrs: {
          heading: "home.archivedHeading",
          "filtered-workpackages": _vm.archivedWorkpackages,
          "sort-desc": true,
          "allow-new": false,
          expanded: false,
          "e2e-slug": "panelArchivedWP",
        },
        on: {
          expand: function ($event) {
            return _vm.getInactiveWorkpackages()
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }