<template>
  <v-menu
    v-model="menu"
    :close-on-content-click="false"
    transition="scale-transition"
    offset-y
    min-width="auto"
  >
    <template v-slot:activator="{ on, attrs }">
      <v-text-field
        v-model="internalValue"
        :label="label"
        prepend-icon="mdi-calendar"
        readonly
        v-bind="attrs"
        v-on="on"
      />
    </template>
    <v-date-picker v-model="internalValue" no-title scrollable @input="closeMenu" />
  </v-menu>
</template>

<script>
export default {
  name: 'AuditDatePicker',
  props: {
    value: {
      type: String,
      default: null,
    },
    label: {
      type: String,
      default: 'Select Date',
    },
  },
  data() {
    return {
      menu: false,
      internalValue: this.value,
    };
  },
  watch: {
    value(val) {
      this.internalValue = val;
    },
    internalValue(val) {
      this.$emit('input', val);
    },
  },
  methods: {
    closeMenu() {
      this.menu = false;
    },
  },
};
</script>
