import { VDatePicker } from 'vuetify/lib/components/VDatePicker';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c,
    _setup = _vm._self._setupProxy
  return _c(VDatePicker, {
    attrs: {
      "no-title": "",
      min: _vm.minDate,
      "first-day-of-week": _vm.params.firstDayOfWeek,
      locale: _vm.params.locale,
      "picker-date": _vm.pickerDate,
      "show-current": "",
    },
    on: {
      input: function ($event) {
        return _vm.params.api.stopEditing()
      },
    },
    model: {
      value: _vm.value,
      callback: function ($$v) {
        _vm.value = $$v
      },
      expression: "value",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }