import XLSX from 'xlsx';
import { chunk, round, forEach, isNumber, last, isArray, size, map, get, isNaN } from 'lodash';
import moment from 'moment';
import {
  isSameOrAfter,
  getStartOfDayForExport,
  prepareDateForXLSX,
} from '@sharedModules/data/utils/dates-utils';
import { maxDateTimestamp } from '@enums/dates';
import numberFormatTypes from '@enums/number-formats';
import dataTypes from '@enums/data-types';

const minDateColumnCharactersNumber = 15;
const minCurrencyColumnCharactersNumber = 15;
const defaultPrecision = 4;

function addDateSpecificData(ws, headers, dateColumnHeaders) {
  if (!dateColumnHeaders || !size(headers)) return;
  forEach(headers, h => {
    const dateColumnHeaderIndexes = dateColumnHeaders.map(dateHeader => h.indexOf(dateHeader));
    ws['!cols'] = h.map((header, index) =>
      // the date must be fully visible, otherwise ### will show up in some apps
      dateColumnHeaderIndexes.includes(index) ? { wch: minDateColumnCharactersNumber } : null
    );
  });
}

function addCurrencySpecificData(ws, data, currencyColumnHeaders, columnFormatters) {
  if (!currencyColumnHeaders || !size(data)) return;
  let headerCount = 0;
  let rowCount = 0;

  forEach(data, d => {
    if (!size(d.headers)) return;
    const headers = last(d.headers);
    // Get index and format information for each header
    const currencyColumns = currencyColumnHeaders.map(currencyHeader => ({
      index: headers.indexOf(currencyHeader),
      format: get(columnFormatters[currencyHeader], 'format', '#,##0.00'),
    }));
    const currencyColumnIndexes = currencyColumns.map(c => c.index);
    if (!size(currencyColumnIndexes)) return;

    // Set minimum width of currency columns
    ws['!cols'] = headers.map((header, index) =>
      // The value must be fully visible, otherwise ### will show up in some apps
      currencyColumnIndexes.includes(index) ? { wch: minCurrencyColumnCharactersNumber } : null
    );

    headerCount += size(d.headers);
    const startRowIndex = rowCount + headerCount;
    rowCount += size(d.rows);
    const endRowIndex = startRowIndex + rowCount;
    // Format worksheet cells
    for (let index = startRowIndex; index <= endRowIndex; index += 1) {
      forEach(currencyColumns, column => {
        const ref = XLSX.utils.encode_cell({ r: index, c: column.index });
        if (!ws[ref] || ws[ref].t !== 'n') return;
        ws[ref].z = column.format;
      });
    }
  });
}

function formatDecimal(value, precision) {
  // check that value is decimal and not an integer
  return value - Math.floor(value) !== 0 ? round(value, precision) : value;
}

function formatInteger(value, precision) {
  const numericValue = Number(value);
  return isNaN(numericValue) ? value : round(numericValue, precision);
}

function formatDate(data, value, key, dateFormat) {
  const maxMomentDate = moment.utc(maxDateTimestamp);
  const date = moment.utc(value, dateFormat, true);
  if (!date.isValid()) return;
  if (isSameOrAfter(date, maxMomentDate)) {
    data[key] = null;
    return;
  }
  const isDaylightSavingTime = moment(value, dateFormat, true).isDST();
  const startOfDay = getStartOfDayForExport(date, isDaylightSavingTime);
  return prepareDateForXLSX(startOfDay);
}

/**
 * Handles the formatting of values in a single row
 * @param {Object} data - row data
 * @param {Object} columnFormatters - formatting options for columns
 */
function formatDataRow(data, columnFormatters, numberFormatsConfig) {
  forEach(data, (value, key) => {
    if (!value) return;
    const formatterType = get(columnFormatters[key], 'type');
    const precision = get(
      numberFormatsConfig,
      [formatterType, 'maximumFractionDigits'],
      defaultPrecision
    );
    if (formatterType) {
      switch (formatterType) {
        case dataTypes.date:
          data[key] = formatDate(data, value, key, columnFormatters[key].format);
          break;
        case dataTypes.str:
          data[key] = value.toString();
          break;
        case numberFormatTypes.integer:
          data[key] = formatInteger(value, precision);
          break;
        default:
          data[key] = value;
          break;
      }
      return;
    }
    // Format decimal values
    if (isNumber(value)) {
      data[key] = formatDecimal(value, precision);
      return;
    }
    // Format each numeric value in an array
    if (isArray(value)) {
      forEach(value, (v, i) => {
        if (isNumber(v)) data[key][i] = formatDecimal(v, precision);
      });
    }
  });
}

/**
 * Downloads an Excel file.
 * @param {Object|Array} data - object or array of objects containing the headers and rows to export
 * @param {string} filename - name of the file that will be exported
 * @param {Object} RORO - the RORO wrapper
 * @param {number} RORO.rowsPerFile - max number of rows per file (to avoid browser crash for large exports)
 * @param {Object} RORO.columnFormatters - formatting options for columns
 * @param {Object} RORO.numberFormatsConfig - number formats for locale
 */
function downloadXlsxFile(
  data,
  filename,
  { rowsPerFile = 5000, columnFormatters = {}, numberFormatsConfig = {} } = {}
) {
  // Data should be an array
  data = isArray(data) ? data : [data];
  const headersByFormatter = {};
  forEach(columnFormatters, (formatter, column) => {
    if (!headersByFormatter[formatter.type]) headersByFormatter[formatter.type] = [];
    headersByFormatter[formatter.type].push(column);
  });
  const downloadData = [];
  const columnHeaders = [];

  forEach(data, d => {
    if (!size(d.headers)) return;
    downloadData.push(...d.headers);
    // Headers that will appear directly above data rows
    const lastHeaderRow = last(d.headers);
    columnHeaders.push(lastHeaderRow);

    const rows = map(d.rows, (obj, i) => {
      formatDataRow(obj, columnFormatters, numberFormatsConfig);
      // parse raw data from objects format to array of arrays
      return lastHeaderRow.map(key => {
        const value = isArray(obj[key]) ? obj[key][i] : obj[key];
        return value || value === 0 ? value : '';
      });
    });
    downloadData.push(...rows);
  });

  // PRICE-1356 tmp fix for large data crashing browser, chunk files
  forEach(chunk(downloadData.slice(1), rowsPerFile), (rows, i) => {
    const fileNameChunk = filename.split('.').join(`-${i + 1}.`);
    // first array in downloadData is headers
    const wsChunk = XLSX.utils.aoa_to_sheet([downloadData[0], ...rows]);
    addDateSpecificData(wsChunk, columnHeaders, headersByFormatter.date);
    addCurrencySpecificData(wsChunk, data, headersByFormatter.currency, columnFormatters);
    const wbChunk = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(wbChunk, wsChunk);
    XLSX.writeFile(wbChunk, fileNameChunk, { compression: true });
  });
}

export default downloadXlsxFile;
