<template>
  <div class="ag-menu-header">
    <v-menu offset-y>
      <template v-slot:activator="{ on }">
        <icon class="menu-icon" icon-name="menu-button-icon" v-on="on" />
      </template>

      <v-list>
        <v-list-item
          v-for="menuOption in params.menuOptions"
          :key="menuOption.text"
          @click="menuOption.itemClick(menuOption.params)"
        >
          <v-list-item-title>
            {{ menuOption.text }}
          </v-list-item-title>
        </v-list-item>
      </v-list>
    </v-menu>
    <div class="ag-cell-label-container" :class="`ag-sort-${sort}`">
      <span
        ref="menuButton"
        aria-hidden="true"
        class="ag-header-icon ag-header-cell-menu-button"
        @click="onMenuClicked"
      >
        <span role="presentation" :class="`ag-icon ag-icon-menu`" />
      </span>
      <span
        v-if="!params.unsortable && sort !== 'none'"
        aria-hidden="true"
        class="ag-header-icon ag-header-label-icon"
        :class="`ag-sort-${sort}-icon`"
      >
        <span role="presentation" :class="`ag-icon ag-icon-${sortShort}`" />
      </span>
      <div v-if="!params.unsortable && sort === 'none'" class="sort-none" />
      <span @click="toggleSort">{{ params.displayName }}</span>
    </div>
  </div>
</template>

<script>
// https://www.ag-grid.com/vue-grid/component-header/
import Vue from 'vue';

export default Vue.extend({
  data() {
    return {
      sort: 'none',
      sortOptions: ['none', 'ascending', 'descending'],
    };
  },
  computed: {
    sortShort() {
      return this.sort.replace('ending', '');
    },
  },
  mounted() {
    this.params.column.addEventListener('sortChanged', this.onSortChanged);
    this.onSortChanged();
  },
  methods: {
    onMenuClicked() {
      this.params.showColumnMenu(this.$refs.menuButton);
    },

    onSortChanged() {
      if (this.params.column.isSortAscending()) {
        this.sort = 'ascending';
      } else if (this.params.column.isSortDescending()) {
        this.sort = 'descending';
      } else {
        this.sort = 'none';
      }
    },

    toggleSort(event) {
      const currentSortIndex = this.sortOptions.indexOf(this.sort);
      const newSort = this.sortOptions[(currentSortIndex + 1) % this.sortOptions.length];
      this.sort = newSort;
      this.params.setSort(this.sortShort, event.shiftKey);
    },
  },
});
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.ag-menu-header {
  font-weight: normal;
  display: flex;
  background-color: $pricing-white;
  .ag-cell-label-container {
    display: flex;
    .sort-none {
      padding-left: 2.2rem;
    }
  }
  ::v-deep {
    .menu-icon {
      padding-right: 0.5rem;
    }
  }
}
</style>
