<template>
  <svg
    width="24px"
    height="24px"
    viewBox="0 0 24 24"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Price increasing too fast</title>
    <g
      id="price-increasing-too-fast-container"
      stroke="none"
      stroke-width="1"
      fill="none"
      fill-rule="evenodd"
    >
      <g id="price-increasing-too-fast-transform" transform="translate(-375.000000, -599.000000)">
        <g id="price-increasing-too-fast" transform="translate(375.000000, 599.000000)">
          <rect id="Rectangle" fill-rule="nonzero" x="0" y="0" width="24" height="24" />
          <path
            id="Combined-Shape"
            d="M16,8 C20.4183,8 24,11.5817 24,16 C24,20.4183 20.4183,24 16,24 C11.5817,24 8,20.4183 8,16 C8,11.5817 11.5817,8 16,8 Z M16,10 C12.6863,10 10,12.6863 10,16 C10,19.3137 12.6863,22 16,22 C19.3137,22 22,19.3137 22,16 C22,12.6863 19.3137,10 16,10 Z M16,11 C16.5523,11 17,11.4477 17,12 L17,12 L17,15 L19,15 C19.5523,15 20,15.4477 20,16 C20,16.5523 19.5523,17 19,17 L19,17 L16,17 C15.4477,17 15,16.5523 15,16 L15,16 L15,12 C15,11.4477 15.4477,11 16,11 Z M5,0 L10,5 L7,5 L7,11 C7,11.5523 6.55228,12 6,12 L4,12 C3.44772,12 3,11.5523 3,11 L3,5 L0,5 L5,0 Z"
            fill="#6D7278"
          />
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PriceIncreasingTooFast',
};
</script>
