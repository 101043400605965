import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VProgressCircular,
    {
      attrs: {
        size: 40,
        width: 4,
        color: "primary",
        value: _vm.progressValue,
        indeterminate: _vm.indeterminate,
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.progressText) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }