import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { row: "", "justify-center": "" } },
    [
      _c(
        VDialog,
        { attrs: { value: _vm.isOpen, persistent: "", "max-width": "40rem" } },
        [
          _c(
            VCard,
            [
              _c(
                VForm,
                {
                  ref: "form",
                  model: {
                    value: _vm.valid,
                    callback: function ($$v) {
                      _vm.valid = $$v
                    },
                    expression: "valid",
                  },
                },
                [
                  _c(VCardText, { staticClass: "header-text" }, [
                    _vm._v(
                      "\n          " + _vm._s(_vm.dialogTitle) + "\n        "
                    ),
                  ]),
                  _vm._v(" "),
                  _c(
                    VCardText,
                    { staticClass: "body" },
                    [
                      _vm.multipleHierarchyLevels
                        ? _c(VAutocomplete, {
                            staticClass: "ma-0 pt-0 mb-2",
                            attrs: {
                              dense: "",
                              "return-object": "",
                              flat: "",
                              items: _vm.existingGrandparentHierarchies,
                              "item-text": "levelEntryDescription",
                              "item-value": "levelEntryDescription",
                            },
                            on: { input: _vm.setSelectedGrandparentHierarchy },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-item-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.item.levelEntryDescription
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-label-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectHierarchyText(
                                                _vm.level - 2
                                              )
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              2366583454
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VAutocomplete, {
                        staticClass: "ma-0 pt-0 mb-2",
                        attrs: {
                          dense: "",
                          "return-object": "",
                          flat: "",
                          items: _vm.existingParentHierarchies,
                          "item-text": "levelEntryDescription",
                          "item-value": "levelEntryDescription",
                          disabled:
                            !_vm.selectedGrandparentHierarchy &&
                            _vm.multipleHierarchyLevels,
                        },
                        on: { input: _vm.setSelectedParentHierarchy },
                        scopedSlots: _vm._u([
                          {
                            key: "item",
                            fn: function (props) {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pg-dropdown-item-text" },
                                  [
                                    _vm._v(
                                      _vm._s(props.item.levelEntryDescription)
                                    ),
                                  ]
                                ),
                              ]
                            },
                          },
                          {
                            key: "label",
                            fn: function () {
                              return [
                                _c(
                                  "span",
                                  { staticClass: "pg-dropdown-label-text" },
                                  [
                                    _vm._v(
                                      _vm._s(
                                        _vm.selectHierarchyText(_vm.level - 1)
                                      )
                                    ),
                                  ]
                                ),
                              ]
                            },
                            proxy: true,
                          },
                        ]),
                      }),
                      _vm._v(" "),
                      _vm.isEditMode
                        ? _c(VAutocomplete, {
                            staticClass: "ma-0 pt-0 mb-2",
                            attrs: {
                              dense: "",
                              "return-object": "",
                              flat: "",
                              items: _vm.existingChildHierarchies,
                              "item-text": "levelEntryDescription",
                              "item-value": "levelEntryDescription",
                              disabled:
                                !_vm.selectedParentHierarchy &&
                                _vm.multipleHierarchyLevels,
                            },
                            on: { input: _vm.setSelectedChildHierarchy },
                            scopedSlots: _vm._u(
                              [
                                {
                                  key: "item",
                                  fn: function (props) {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-item-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              props.item.levelEntryDescription
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                },
                                {
                                  key: "label",
                                  fn: function () {
                                    return [
                                      _c(
                                        "span",
                                        {
                                          staticClass: "pg-dropdown-label-text",
                                        },
                                        [
                                          _vm._v(
                                            _vm._s(
                                              _vm.selectHierarchyText(_vm.level)
                                            )
                                          ),
                                        ]
                                      ),
                                    ]
                                  },
                                  proxy: true,
                                },
                              ],
                              null,
                              false,
                              3418026145
                            ),
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c(VTextField, {
                        attrs: {
                          label: _vm.$tc(
                            `hierarchy.hierarchyLevels.${_vm.level}`,
                            1
                          ),
                          disabled: _vm.isEditMode
                            ? !_vm.selectedChildHierarchy
                            : !_vm.selectedParentHierarchy,
                          rules: _vm.rules,
                          dense: "",
                        },
                        model: {
                          value: _vm.childHierarchyName,
                          callback: function ($$v) {
                            _vm.childHierarchyName = $$v
                          },
                          expression: "childHierarchyName",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VCardActions,
                    { staticClass: "btn-group" },
                    [
                      _c(VSpacer),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "cancel-btn",
                          attrs: { text: "", outlined: "", depressed: "" },
                          on: { click: _vm.cancel },
                        },
                        [_vm._v("Cancel")]
                      ),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "ok-btn",
                          attrs: { disabled: !_vm.valid, depressed: "" },
                          on: { click: _vm.submit },
                        },
                        [_vm._v("Save")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }