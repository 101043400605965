var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "24px",
        height: "24px",
        viewBox: "0 0 24 24",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c("title", [_vm._v("Missing engine input")]),
      _vm._v(" "),
      _c(
        "g",
        {
          attrs: {
            id: "missing-engine-input-container",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "missing-engine-input-transform",
                transform: "translate(-138.000000, -759.000000)",
                "fill-rule": "nonzero",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "missing-engine-input",
                    transform: "translate(138.000000, 759.000000)",
                  },
                },
                [
                  _c("rect", {
                    attrs: {
                      id: "Rectangle",
                      x: "0",
                      y: "0",
                      width: "24",
                      height: "24",
                    },
                  }),
                  _vm._v(" "),
                  _c("path", {
                    attrs: {
                      id: "Combined-Shape",
                      d: "M12,18 C13.1046,18 14,18.8954 14,20 L14,20 L14,22 C14,23.1046 13.1046,24 12,24 L12,24 L2,24 C0.89543,24 0,23.1046 0,22 L0,22 L0,20 C0,18.8954 0.895431,18 2,18 L2,18 Z M23,18.5 C23.2761,18.7761 23.2761,19.2239 23,19.5 L19.2071,23.2929 C18.8166,23.6834 18.1834,23.6834 17.7929,23.2929 L16.5,22 C16.2239,21.7239 16.2239,21.2761 16.5,21 C16.7761,20.7239 17.2239,20.7239 17.5,21 L18.5,22 L22,18.5 C22.2761,18.2239 22.7239,18.2239 23,18.5 Z M12,20 L2,20 L2,22 L12,22 L12,20 Z M12,9 C13.1045695,9 14,9.8954305 14,11 L14,13 C14,14.1045695 13.1045695,15 12,15 L2,15 C0.8954305,15 1.3527075e-16,14.1045695 0,13 L0,11 C-1.3527075e-16,9.8954305 0.8954305,9 2,9 L12,9 Z M21.5,9.50003 C21.7762,9.22389 22.2239,9.22389 22.5,9.50003 C22.7762,9.77617 22.7762,10.2239 22.5,10.5 L22.5,10.5 L21,12 L22.5,13.5 C22.7762,13.7761 22.7762,14.2239 22.5,14.5 C22.2239,14.7761 21.7762,14.7761 21.5,14.5 L21.5,14.5 L20,13 L18.5,14.5 C18.2239,14.7762 17.7762,14.7762 17.5,14.5 C17.2239,14.2239 17.2239,13.7762 17.5,13.5 L17.5,13.5 L19,12 L17.5,10.5 C17.2239,10.2239 17.2239,9.77614 17.5,9.5 C17.7762,9.22386 18.2239,9.22386 18.5,9.5 L18.5,9.5 L20,11 Z M12,0 C13.1046,0 14,0.895431 14,2 L14,2 L14,4 C14,5.10457 13.1046,6 12,6 L12,6 L2,6 C0.89543,6 0,5.10457 0,4 L0,4 L0,2 C0,0.89543 0.895431,0 2,0 L2,0 Z M23,0.5 C23.2761,0.776142 23.2761,1.22386 23,1.5 L19.2071,5.2928935 C18.8166,5.68342 18.1834,5.68342 17.7929,5.2928935 L16.5,4 C16.2239,3.72386 16.2239,3.27614 16.5,3 C16.7761,2.72386 17.2239,2.72386 17.5,3 L18.5,4 L22,0.5 C22.2761,0.223858 22.7239,0.223858 23,0.5 Z M12,2 L2,2 L2,4 L12,4 L12,2 Z",
                      fill: "#6D7278",
                    },
                  }),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }