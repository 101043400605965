import { VAlert } from 'vuetify/lib/components/VAlert';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDataTable } from 'vuetify/lib/components/VDataTable';
import { VDatePicker } from 'vuetify/lib/components/VDatePicker';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VPagination } from 'vuetify/lib/components/VPagination';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      directives: [
        {
          name: "show",
          rawName: "v-show",
          value: _vm.hasSelectedWorkpackage,
          expression: "hasSelectedWorkpackage",
        },
      ],
      staticClass: "input-screen-page-wrapper",
    },
    [
      _c(
        VRow,
        { attrs: { "no-gutters": "" } },
        [
          _c(
            VCol,
            [
              _vm.canEditAttributes
                ? _c(
                    VMenu,
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        depressed: "",
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("actions.add")) +
                                        " "
                                    ),
                                    _c(VIcon, { attrs: { small: "" } }, [
                                      _vm._v("mdi-dots-vertical"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        1296135565
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        VList,
                        [
                          _c(
                            VListItem,
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openAttributeCreateUpdateDialog(
                                    _vm.dialogModes.create,
                                    _vm.attributeScope.global
                                  )
                                },
                              },
                            },
                            [
                              _c(VListItemTitle, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.startCase(
                                        _vm.$t(
                                          "attributes.actions.createGlobalAttribute"
                                        )
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItem,
                            {
                              on: {
                                click: function ($event) {
                                  return _vm.openAttributeCreateUpdateDialog(
                                    _vm.dialogModes.create,
                                    _vm.attributeScope.pricingGroup
                                  )
                                },
                              },
                            },
                            [
                              _c(VListItemTitle, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.startCase(
                                        _vm.$t(
                                          "attributes.actions.createPricingGroupAttribute"
                                        )
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
              _vm._v(" "),
              _vm.canEditAttributes
                ? _c(
                    VMenu,
                    {
                      attrs: { "offset-y": "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function ({ on }) {
                              return [
                                _c(
                                  VBtn,
                                  _vm._g(
                                    {
                                      staticClass: "pa-0",
                                      attrs: {
                                        color: "primary",
                                        small: "",
                                        depressed: "",
                                        loading:
                                          _vm.busyImportingAttributes ||
                                          _vm.busyImportingGroup,
                                      },
                                    },
                                    on
                                  ),
                                  [
                                    _vm._v(
                                      "\n            " +
                                        _vm._s(_vm.$t("actions.upload")) +
                                        "\n            "
                                    ),
                                    _c(VIcon, { attrs: { small: "" } }, [
                                      _vm._v("$import"),
                                    ]),
                                  ],
                                  1
                                ),
                              ]
                            },
                          },
                        ],
                        null,
                        false,
                        2322522422
                      ),
                    },
                    [
                      _vm._v(" "),
                      _c(
                        VList,
                        [
                          _c(
                            VListItem,
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showHierarchyUpload = true
                                },
                              },
                            },
                            [
                              _c(VListItemTitle, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.startCase(
                                        _vm.$t(
                                          "attributes.uploadHierarchy.uploadButton"
                                        )
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                          _vm._v(" "),
                          _c(
                            VListItem,
                            {
                              on: {
                                click: function ($event) {
                                  _vm.showAttributeUpload = true
                                },
                              },
                            },
                            [
                              _c(VListItemTitle, [
                                _vm._v(
                                  "\n              " +
                                    _vm._s(
                                      _vm.startCase(
                                        _vm.$t("attributes.upload.uploadButton")
                                      )
                                    ) +
                                    "\n            "
                                ),
                              ]),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
          _vm._v(" "),
          _c(
            VCol,
            { attrs: { cols: "3" } },
            [
              _c(VTextField, {
                staticClass: "search-field py-0",
                attrs: {
                  value: _vm.attributeEditorSearch,
                  "append-icon": "search",
                  label: _vm.$t("actions.search"),
                  "single-line": "",
                },
                on: { input: _vm.debounceSearchUpdate },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _vm._v(" "),
      _c("attribute-filter-panel", {
        attrs: {
          "title-localisation": "attributes.filters.filterByAttributes",
          "filter-count-localisation": "attributes.filters.numApplied",
          "enable-hierarchy-filters": "",
          "filter-rules": _vm.retailAttributesFilter,
        },
        on: { attributeFilterChange: _vm.setFilterRules },
      }),
      _vm._v(" "),
      _c(
        VDataTable,
        {
          staticClass:
            "pricing-table elevation-1 attributes-table striped-table",
          attrs: {
            items: _vm.items,
            loading: _vm.loadingData,
            headers: _vm.headers,
            height: _vm.dataTableHeight,
            "loading-text": _vm.$t("general.loadingMessage"),
            "disable-pagination": "",
            "disable-sort": "",
            dense: "",
            "fixed-header": "",
            "hide-default-header": "",
            "hide-default-footer": "",
          },
          nativeOn: {
            mousewheel: function ($event) {
              return _vm.preventPageNavigationOnHorizontalScroll(
                $event,
                ".v-data-table__wrapper"
              )
            },
          },
          scopedSlots: _vm._u([
            {
              key: "no-data",
              fn: function () {
                return [
                  !_vm.loadingData
                    ? _c(
                        VAlert,
                        {
                          attrs: {
                            value: true,
                            color: "error",
                            icon: "warning",
                            outlined: "",
                          },
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.$t(
                                  "attributes.editor.noAttributesDisplayMessage"
                                )
                              ) +
                              "\n      "
                          ),
                        ]
                      )
                    : _vm._e(),
                ]
              },
              proxy: true,
            },
            {
              key: "header",
              fn: function ({ props }) {
                return [
                  _c("thead", { staticClass: "v-data-table-header" }, [
                    _c("tr", [
                      _c("th", {
                        class: _vm.fixedColumnsClass,
                        attrs: { colspan: "1" },
                      }),
                      _vm._v(" "),
                      _vm.toolStoreGroupColumn
                        ? _c("th", {
                            class: _vm.fixedColumnsClass,
                            attrs: { colspan: "1" },
                          })
                        : _vm._e(),
                      _vm._v(" "),
                      _c("th", {
                        staticClass: "border-right",
                        class: _vm.fixedColumnsClass,
                        attrs: { colspan: "1" },
                      }),
                      _vm._v(" "),
                      _c(
                        "th",
                        {
                          staticClass: "border-right",
                          attrs: { colspan: "3" },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(
                                _vm.$t("attributes.editor.headers.hierarchy")
                              ) +
                              "\n          "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.formattedGlobalAttributeHeaders.length > 0
                        ? _c(
                            "th",
                            {
                              attrs: {
                                colspan:
                                  _vm.formattedGlobalAttributeHeaders.length,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "attributes.editor.headers.globalAttributes"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _vm.formattedPricingGroupAttributeHeaders.length > 0
                        ? _c(
                            "th",
                            {
                              staticClass: "border-left",
                              attrs: {
                                colspan:
                                  _vm.formattedPricingGroupAttributeHeaders
                                    .length,
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm.$t(
                                      "attributes.editor.headers.pricingGroupAttributes"
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          )
                        : _vm._e(),
                    ]),
                    _vm._v(" "),
                    _c(
                      "tr",
                      _vm._l(props.headers, function (header) {
                        return _c(
                          "th",
                          {
                            key: header.id,
                            staticClass: "sortable",
                            class: [
                              ..._vm.getHeaderClasses(header, _vm.pagination),
                              header.class,
                            ],
                          },
                          [
                            _c(
                              "span",
                              {
                                on: {
                                  click: function ($event) {
                                    return _vm.changeSort(header)
                                  },
                                },
                              },
                              [
                                _c(
                                  "tooltip",
                                  {
                                    attrs: {
                                      disabled: _vm.isTooltipDisabled(
                                        header.text,
                                        _vm.truncationLengthMedium
                                      ),
                                      value: header.text,
                                    },
                                  },
                                  [
                                    _vm._v(
                                      "\n                " +
                                        _vm._s(
                                          _vm._f("truncate")(
                                            header.text,
                                            _vm.truncationLengthMedium
                                          )
                                        ) +
                                        "\n              "
                                    ),
                                  ]
                                ),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VMenu,
                              {
                                attrs: { bottom: "", "offset-y": "" },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function ({ on }) {
                                        return [
                                          _vm.isDynamicAttributeHeader(
                                            header.value
                                          ) && _vm.canEditAttributes
                                            ? _c(
                                                VIcon,
                                                _vm._g(
                                                  {
                                                    staticClass: "actions-btn",
                                                  },
                                                  on
                                                ),
                                                [
                                                  _vm._v(
                                                    "\n                  more_horiz\n                "
                                                  ),
                                                ]
                                              )
                                            : _vm._e(),
                                        ]
                                      },
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              },
                              [
                                _vm._v(" "),
                                _vm.canEditAttributes
                                  ? _c(
                                      VList,
                                      [
                                        _vm.hasMenuOption(
                                          header.value,
                                          "editHierarchyLevel"
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openHierarchyUpdateDialog(
                                                      header
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.editor.actions.editHierarchyLevel"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasMenuOption(
                                          header.value,
                                          "addNewHierarchyLevel"
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openHierarchyCreateDialog(
                                                      header
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.editor.actions.addNewHierarchyLevel"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasMenuOption(
                                          header.value,
                                          "deleteHierarchyLevel"
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openHierarchyDeleteDialog(
                                                      header
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.editor.actions.deleteHierarchyLevel"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasMenuOption(
                                          header.value,
                                          _vm.dialogModes.edit
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openAttributeCreateUpdateDialog(
                                                      _vm.dialogModes.edit,
                                                      header.scope,
                                                      header
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.editor.actions.edit"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                        _vm._v(" "),
                                        _vm.hasMenuOption(
                                          header.value,
                                          _vm.dialogModes.delete
                                        )
                                          ? _c(
                                              VListItem,
                                              {
                                                on: {
                                                  click: function ($event) {
                                                    return _vm.openDeleteDialog(
                                                      header
                                                    )
                                                  },
                                                },
                                              },
                                              [
                                                _c(VListItemTitle, [
                                                  _vm._v(
                                                    "\n                    " +
                                                      _vm._s(
                                                        _vm.$t(
                                                          "attributes.editor.actions.delete"
                                                        )
                                                      ) +
                                                      "\n                  "
                                                  ),
                                                ]),
                                              ],
                                              1
                                            )
                                          : _vm._e(),
                                      ],
                                      1
                                    )
                                  : _vm._e(),
                              ],
                              1
                            ),
                            _vm._v(" "),
                            _c(
                              VIcon,
                              {
                                staticClass: "v-data-table-header__icon",
                                attrs: { size: "1.4rem" },
                                on: {
                                  click: function ($event) {
                                    return _vm.changeSort(header)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  "\n              arrow_upward\n            "
                                ),
                              ]
                            ),
                          ],
                          1
                        )
                      }),
                      0
                    ),
                  ]),
                ]
              },
            },
            {
              key: "item",
              fn: function (props) {
                return [
                  _c(
                    "tr",
                    { key: props.index },
                    [
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _vm._v(
                            "\n          " +
                              _vm._s(props.item.productKeyDisplay) +
                              "\n        "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _vm.toolStoreGroupColumn
                        ? _c(
                            "td",
                            {
                              staticClass: "text-xs table-cell",
                              class: _vm.fixedColumnsClass,
                            },
                            [
                              _c("tooltipped-truncated-field", {
                                staticClass: "tooltipped-truncated-field",
                                attrs: {
                                  text: props.item.toolStoreGroupDescription,
                                  "truncation-length":
                                    _vm.truncationLengthMedium,
                                },
                              }),
                            ],
                            1
                          )
                        : _vm._e(),
                      _vm._v(" "),
                      _c(
                        "td",
                        {
                          staticClass: "text-xs table-cell border-right",
                          class: _vm.fixedColumnsClass,
                        },
                        [
                          _c(
                            "tooltip",
                            {
                              attrs: {
                                value: props.item.productName,
                                disabled: _vm.isTooltipDisabled(
                                  props.item.productName,
                                  _vm.truncationLengthLong
                                ),
                              },
                            },
                            [
                              _vm._v(
                                "\n            " +
                                  _vm._s(
                                    _vm._f("truncate")(
                                      props.item.productName,
                                      _vm.truncationLengthLong
                                    )
                                  ) +
                                  "\n          "
                              ),
                            ]
                          ),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-xs table-cell" },
                        [
                          !_vm.dataReloading
                            ? _c(
                                "tooltip",
                                {
                                  attrs: {
                                    value: _vm.getHierarchyName(
                                      props.item,
                                      _vm.categoryLevel
                                    ),
                                    disabled: _vm.isTooltipDisabled(
                                      _vm.getHierarchyName(
                                        props.item,
                                        _vm.categoryLevel
                                      ),
                                      _vm.truncationLengthMedium
                                    ),
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n            " +
                                      _vm._s(
                                        _vm._f("truncate")(
                                          _vm.getHierarchyName(
                                            props.item,
                                            _vm.categoryLevel
                                          ),
                                          _vm.truncationLengthMedium
                                        )
                                      ) +
                                      "\n          "
                                  ),
                                ]
                              )
                            : _vm._e(),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-xs table-cell" },
                        [
                          !_vm.dataReloading
                            ? _c(VSelect, {
                                key: _vm.forceRefreshWhenUpdated(
                                  props.item,
                                  _vm.pricingGroupLevel
                                ),
                                attrs: {
                                  items: _vm.getSiblingHierarchies(
                                    props.item,
                                    _vm.pricingGroupLevel
                                  ),
                                  "item-text": "levelEntryDescription",
                                  label: _vm.getHierarchyName(
                                    props.item,
                                    _vm.pricingGroupLevel
                                  ),
                                  disabled: !_vm.canEditAttributes,
                                  "return-object": "",
                                  "single-line": "",
                                  dense: "",
                                  "hide-details": "",
                                  "menu-props": {
                                    closeOnClick: true,
                                    closeOnContentClick: true,
                                  },
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateWorkpackageProductHierarchy(
                                      props.item,
                                      $event
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.$set(
                                      props.item,
                                      "selected",
                                      true
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "dropdown-text" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getHierarchyName(
                                                      props.item,
                                                      _vm.pricingGroupLevel
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "dropdown-text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.item
                                                    .levelEntryDescription
                                                ) + " "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "append-item",
                                      fn: function () {
                                        return [
                                          _c("dropdown-list-item", {
                                            attrs: {
                                              divider: "",
                                              disabled:
                                                _vm.isHierarchyUnassigned(
                                                  props.item,
                                                  _vm.pricingGroupLevel
                                                ),
                                              title: _vm.$t(
                                                "attributes.actions.unassignPricingGroup"
                                              ),
                                              "icon-class": "close-circle",
                                            },
                                            on: {
                                              selectOption: function ($event) {
                                                return _vm.updateWorkpackageProductHierarchy(
                                                  props.item,
                                                  {
                                                    levelEntryKey:
                                                      _vm.pricingGroupUndefined,
                                                    level:
                                                      _vm.pricingGroupLevel,
                                                  }
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "no-data",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown-text no-data-slot d-block pb-2",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "attributes.editor.noHierarchyInDropdown"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _c(VSelect),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c(
                        "td",
                        { staticClass: "text-xs table-cell border-right" },
                        [
                          !_vm.dataReloading
                            ? _c(VSelect, {
                                key: _vm.forceRefreshWhenUpdated(
                                  props.item,
                                  _vm.architectureGroupLevel
                                ),
                                attrs: {
                                  items: _vm.getSiblingHierarchies(
                                    props.item,
                                    _vm.architectureGroupLevel
                                  ),
                                  "item-text": "levelEntryDescription",
                                  label: _vm.getHierarchyName(
                                    props.item,
                                    _vm.architectureGroupLevel
                                  ),
                                  disabled: !_vm.canEditArchitectureGroup(
                                    props.item
                                  ),
                                  "return-object": "",
                                  "single-line": "",
                                  dense: "",
                                  "hide-details": "",
                                  "menu-props": {
                                    closeOnClick: true,
                                    closeOnContentClick: true,
                                  },
                                },
                                on: {
                                  input: function ($event) {
                                    return _vm.updateWorkpackageProductHierarchy(
                                      props.item,
                                      $event
                                    )
                                  },
                                  focus: function ($event) {
                                    return _vm.$set(
                                      props.item,
                                      "selected",
                                      true
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "label",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "dropdown-text" },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.getHierarchyName(
                                                      props.item,
                                                      _vm.architectureGroupLevel
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "item",
                                      fn: function (props) {
                                        return [
                                          _c(
                                            "span",
                                            { staticClass: "dropdown-text" },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  props.item
                                                    .levelEntryDescription
                                                )
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                    },
                                    {
                                      key: "append-item",
                                      fn: function () {
                                        return [
                                          _c("dropdown-list-item", {
                                            attrs: {
                                              divider: "",
                                              disabled:
                                                _vm.isHierarchyUnassigned(
                                                  props.item,
                                                  _vm.architectureGroupLevel
                                                ),
                                              title: _vm.$t(
                                                "attributes.actions.unassignArchitectureGroup"
                                              ),
                                              "icon-class": "close-circle",
                                            },
                                            on: {
                                              selectOption: function ($event) {
                                                return _vm.updateWorkpackageProductHierarchy(
                                                  props.item,
                                                  {
                                                    levelEntryKey:
                                                      _vm.architectureGroupUndefined,
                                                    level:
                                                      _vm.architectureGroupLevel,
                                                  }
                                                )
                                              },
                                            },
                                          }),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                    {
                                      key: "no-data",
                                      fn: function () {
                                        return [
                                          _c(
                                            "span",
                                            {
                                              staticClass:
                                                "dropdown-text no-data-slot",
                                            },
                                            [
                                              _vm._v(
                                                "\n                " +
                                                  _vm._s(
                                                    _vm.$t(
                                                      "attributes.editor.noHierarchyInDropdown"
                                                    )
                                                  ) +
                                                  "\n              "
                                              ),
                                            ]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                              })
                            : _c(VSelect),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.formattedGlobalAttributeHeaders,
                        function (header) {
                          return _c(
                            "td",
                            {
                              key: props.item._id + header.id,
                              staticClass: "text-xs table-cell",
                            },
                            [
                              _vm.dateAttribute(header.value)
                                ? [
                                    _c(
                                      VMenu,
                                      {
                                        attrs: {
                                          disabled: !_vm.canEditAttributes,
                                          "nudge-left": 5,
                                          "offset-x": "",
                                          left: "",
                                          top:
                                            props.index >
                                            _vm.pagination.itemsPerPage / 2 - 1,
                                          "min-width": "50",
                                          transition: "scale-transition",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _vm.toggleLogic
                                                        .useManualProductGoLiveDate &&
                                                      _vm.productGoLiveDateAttributeKey ===
                                                        header.value
                                                        ? _c(VTextField, {
                                                            key: `${props.item._id}-${header.value}`,
                                                            staticClass:
                                                              "alt-go-live float-left",
                                                            attrs: {
                                                              disabled:
                                                                !_vm.canEditAttributes,
                                                              value:
                                                                _vm.getAttributeValue(
                                                                  props.item,
                                                                  header.value,
                                                                  true
                                                                ),
                                                              dense: "",
                                                              "hide-details":
                                                                "",
                                                              clearable: "",
                                                            },
                                                            on: {
                                                              "click:clear":
                                                                function (
                                                                  $event
                                                                ) {
                                                                  return _vm.recordChanges(
                                                                    null,
                                                                    header.value,
                                                                    props.item
                                                                  )
                                                                },
                                                            },
                                                          })
                                                        : _c(
                                                            "pricing-edit-text-field",
                                                            {
                                                              key: `${props.item._id}-${header.value}`,
                                                              staticClass:
                                                                "float-left",
                                                              attrs: {
                                                                disabled:
                                                                  !_vm.canEditAttributes,
                                                                "data-type":
                                                                  _vm.getAttributeDataType(
                                                                    header.value
                                                                  ),
                                                                value:
                                                                  _vm.getAttributeValue(
                                                                    props.item,
                                                                    header.value,
                                                                    true
                                                                  ),
                                                                "previous-value":
                                                                  _vm.getPreviousValue(
                                                                    props.item,
                                                                    header.value,
                                                                    true
                                                                  ),
                                                                "prevent-formatting": true,
                                                                error:
                                                                  _vm
                                                                    .updateErrors[
                                                                    props.item
                                                                      .keys[
                                                                      header
                                                                        .value
                                                                    ]
                                                                  ],
                                                                "cell-class":
                                                                  _vm.getAttributeEditFieldClass(
                                                                    props.item,
                                                                    header.value
                                                                  ),
                                                                "tooltip-position":
                                                                  props.index >
                                                                  _vm.pagination
                                                                    .itemsPerPage /
                                                                    2
                                                                    ? "top-left"
                                                                    : "bottom-left",
                                                                "previous-value-translation-key":
                                                                  "attributes.editor",
                                                              },
                                                            }
                                                          ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(VDatePicker, {
                                          attrs: {
                                            value: _vm.getAttributeValue(
                                              props.item,
                                              header.value
                                            ),
                                            "first-day-of-week":
                                              _vm.i18nconfig.firstDayOfTheWeek,
                                            locale:
                                              _vm.i18nconfig.fallbackLocale,
                                            min:
                                              _vm.productGoLiveDateAttributeKey ===
                                              header.value
                                                ? new Date()
                                                    .toISOString()
                                                    .substr(0, 10)
                                                : null,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.recordChanges(
                                                $event,
                                                header.value,
                                                props.item
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c("pricing-edit-text-field", {
                                      key: `${props.item._id}-${header.value}`,
                                      staticClass: "float-left",
                                      attrs: {
                                        disabled: !_vm.canEditAttributes,
                                        "data-type": _vm.getAttributeDataType(
                                          header.value
                                        ),
                                        "number-format":
                                          _vm.numberFormats.decimal,
                                        value: _vm.getAttributeValue(
                                          props.item,
                                          header.value
                                        ),
                                        "previous-value": _vm.getPreviousValue(
                                          props.item,
                                          header.value
                                        ),
                                        error:
                                          _vm.updateErrors[
                                            props.item.keys[header.value]
                                          ],
                                        "cell-class":
                                          _vm.getAttributeEditFieldClass(
                                            props.item,
                                            header.value
                                          ),
                                        "allow-negative": true,
                                        "tooltip-position":
                                          props.index >
                                          _vm.pagination.itemsPerPage / 2
                                            ? "top-left"
                                            : "bottom-left",
                                        "previous-value-translation-key":
                                          "attributes.editor",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.recordChanges(
                                            $event.target.value,
                                            header.value,
                                            props.item
                                          )
                                        },
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                      _vm._v(" "),
                      _vm._l(
                        _vm.formattedPricingGroupAttributeHeaders,
                        function (header, index) {
                          return _c(
                            "td",
                            {
                              key: props.item._id + header.id,
                              staticClass: "text-xs table-cell",
                              class: index === 0 ? "border-left" : "",
                            },
                            [
                              !_vm.allowProductAttributeEdit(props.item, header)
                                ? [
                                    _c("span", [
                                      _vm._v(
                                        " " + _vm._s(_vm.noValueFound) + " "
                                      ),
                                    ]),
                                  ]
                                : _vm.dateAttribute(header.value)
                                ? [
                                    _c(
                                      VMenu,
                                      {
                                        attrs: {
                                          disabled: !_vm.canEditAttributes,
                                          "nudge-left": 5,
                                          "offset-x": "",
                                          left: "",
                                          top:
                                            props.index >
                                            _vm.pagination.itemsPerPage / 2 - 1,
                                          "min-width": "50",
                                          transition: "scale-transition",
                                        },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "div",
                                                    _vm._g({}, on),
                                                    [
                                                      _c(
                                                        "pricing-edit-text-field",
                                                        {
                                                          key: `${props.item._id}-${header.value}`,
                                                          staticClass:
                                                            "float-left",
                                                          attrs: {
                                                            disabled:
                                                              !_vm.canEditAttributes,
                                                            "data-type":
                                                              _vm.getAttributeDataType(
                                                                header.value
                                                              ),
                                                            value:
                                                              _vm.getAttributeValue(
                                                                props.item,
                                                                header.value,
                                                                true
                                                              ),
                                                            "previous-value":
                                                              _vm.getPreviousValue(
                                                                props.item,
                                                                header.value,
                                                                true
                                                              ),
                                                            "prevent-formatting": true,
                                                            error:
                                                              _vm.updateErrors[
                                                                props.item.keys[
                                                                  header.value
                                                                ]
                                                              ],
                                                            "cell-class":
                                                              _vm.getAttributeEditFieldClass(
                                                                props.item,
                                                                header.value
                                                              ),
                                                            "tooltip-position":
                                                              props.index >
                                                              _vm.pagination
                                                                .itemsPerPage /
                                                                2
                                                                ? "top-left"
                                                                : "bottom-left",
                                                            "previous-value-translation-key":
                                                              "attributes.editor",
                                                          },
                                                        }
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _vm._v(" "),
                                        _c(VDatePicker, {
                                          attrs: {
                                            value: _vm.getAttributeValue(
                                              props.item,
                                              header.value
                                            ),
                                            "first-day-of-week":
                                              _vm.i18nconfig.firstDayOfTheWeek,
                                            locale:
                                              _vm.i18nconfig.fallbackLocale,
                                          },
                                          on: {
                                            input: function ($event) {
                                              return _vm.recordChanges(
                                                $event,
                                                header.value,
                                                props.item
                                              )
                                            },
                                          },
                                        }),
                                      ],
                                      1
                                    ),
                                  ]
                                : [
                                    _c("pricing-edit-text-field", {
                                      key: `${props.item._id}-${header.value}`,
                                      staticClass: "float-left",
                                      attrs: {
                                        disabled: !_vm.canEditAttributes,
                                        "data-type": _vm.getAttributeDataType(
                                          header.value
                                        ),
                                        "number-format":
                                          _vm.numberFormats.decimal,
                                        value: _vm.getAttributeValue(
                                          props.item,
                                          header.value
                                        ),
                                        "previous-value": _vm.getPreviousValue(
                                          props.item,
                                          header.value
                                        ),
                                        error:
                                          _vm.updateErrors[
                                            props.item.keys[header.value]
                                          ],
                                        "cell-class":
                                          _vm.getAttributeEditFieldClass(
                                            props.item,
                                            header.value
                                          ),
                                        "allow-negative": true,
                                        "tooltip-position":
                                          props.index >
                                          _vm.pagination.itemsPerPage / 2
                                            ? "top-left"
                                            : "bottom-left",
                                        "previous-value-translation-key":
                                          "attributes.editor",
                                      },
                                      on: {
                                        change: function ($event) {
                                          return _vm.recordChanges(
                                            $event.target.value,
                                            header.value,
                                            props.item
                                          )
                                        },
                                      },
                                    }),
                                  ],
                            ],
                            2
                          )
                        }
                      ),
                    ],
                    2
                  ),
                ]
              },
            },
          ]),
        },
        [
          _vm._v(" "),
          _vm._v(" "),
          _vm._v(" "),
          _c(
            "template",
            { slot: "footer" },
            [
              _c(
                VFlex,
                { staticClass: "input-screen-sticky-table-footer" },
                [
                  _c(
                    VRow,
                    { attrs: { justify: "end" } },
                    [
                      _c(
                        VCol,
                        { attrs: { md: "3" } },
                        [
                          _c(VPagination, {
                            attrs: { length: _vm.pages, depressed: "" },
                            on: { input: _vm.loadData },
                            model: {
                              value: _vm.pagination.page,
                              callback: function ($$v) {
                                _vm.$set(_vm.pagination, "page", $$v)
                              },
                              expression: "pagination.page",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _vm._v(" "),
                  _c(
                    VRow,
                    {
                      staticClass: "pr-6 pb-2",
                      attrs: { justify: "end", align: "center" },
                    },
                    [
                      _c(
                        VBtn,
                        {
                          staticClass: "mr-3",
                          attrs: {
                            color: "primary",
                            small: "",
                            loading: _vm.downloadingItems,
                            depressed: "",
                          },
                          on: { click: _vm.downloadAttributes },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.download")) +
                              "\n            "
                          ),
                          _c(VIcon, { attrs: { small: "" } }, [
                            _vm._v("$export"),
                          ]),
                        ],
                        1
                      ),
                      _vm._v(" "),
                      _c("span", { staticClass: "mr-3 btn-divider" }),
                      _vm._v(" "),
                      _c(
                        VBtn,
                        {
                          staticClass: "save",
                          attrs: {
                            disabled:
                              !_vm.canEditAttributes ||
                              !_vm.hasUnsavedUpdates ||
                              _vm.hasInvalidUpdates,
                            color: "success",
                            loading: _vm.showSpinner,
                            small: "",
                            depressed: "",
                          },
                          on: { click: _vm.persistUpdates },
                        },
                        [
                          _vm._v(
                            "\n            " +
                              _vm._s(_vm.$t("actions.save")) +
                              "\n          "
                          ),
                        ]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
      _vm._v(" "),
      _c("alert-dialog", {
        attrs: {
          "is-open": _vm.isDeleteDialogOpen,
          "ok-btn-text": _vm.$t("actions.delete"),
          "disable-ok-button":
            _vm.checkingIfAttributeCanBeDeleted ||
            !_vm.attributeCanBeDeleted ||
            _vm.deletingAttribute,
        },
        on: {
          onOk: function ($event) {
            return _vm.deleteAttributeHandler(true)
          },
          onCancel: function ($event) {
            return _vm.deleteAttributeHandler(false)
          },
        },
        scopedSlots: _vm._u([
          {
            key: "header",
            fn: function () {
              return [_vm._v(_vm._s(_vm.$t("dialogs.deleteDialog.header")))]
            },
            proxy: true,
          },
          {
            key: "body",
            fn: function () {
              return [
                !_vm.checkingIfAttributeCanBeDeleted &&
                _vm.attributeCanBeDeleted
                  ? _c("div", [
                      _vm._v(
                        "\n        " +
                          _vm._s(_vm.$t("dialogs.deleteDialog.body")) +
                          "\n        "
                      ),
                      _c("strong", [
                        _vm._v(
                          _vm._s(
                            _vm.attributeToDelete
                              ? _vm.attributeToDelete.text
                              : ""
                          )
                        ),
                      ]),
                    ])
                  : _vm.checkingIfAttributeCanBeDeleted &&
                    _vm.attributeUses.length === 0
                  ? _c(
                      "div",
                      [
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "attributes.editor.deleteMessages.checkingIfTheAttribute"
                              )
                            ) +
                            "\n        "
                        ),
                        _c("strong", [
                          _vm._v(
                            _vm._s(
                              _vm.attributeToDelete
                                ? _vm.attributeToDelete.text
                                : ""
                            )
                          ),
                        ]),
                        _vm._v(
                          "\n        " +
                            _vm._s(
                              _vm.$t(
                                "attributes.editor.deleteMessages.canBeDeleted"
                              )
                            ) +
                            "\n        "
                        ),
                        _c(VProgressCircular, {
                          attrs: {
                            indeterminate: "",
                            color: "primary",
                            size: "20",
                          },
                        }),
                      ],
                      1
                    )
                  : _c(
                      "div",
                      [
                        _c("div", { staticClass: "pb-1" }, [
                          _c("strong", [
                            _vm._v(
                              _vm._s(
                                _vm.attributeToDelete
                                  ? _vm.attributeToDelete.text
                                  : ""
                              )
                            ),
                          ]),
                          _vm._v(
                            "\n          " +
                              _vm._s(
                                _vm.$t(
                                  "attributes.editor.deleteMessages.attributeCannotBeDeleted"
                                )
                              ) +
                              ":\n        "
                          ),
                        ]),
                        _vm._v(" "),
                        _vm._l(
                          _vm.attributeUses,
                          function (attributeUse, index) {
                            return _c("div", { key: index }, [
                              attributeUse.exists
                                ? _c(
                                    "div",
                                    { staticClass: "pl-2" },
                                    [
                                      _c(
                                        VIcon,
                                        {
                                          staticClass: "pb-1",
                                          attrs: {
                                            size: "1.7rem",
                                            color: _vm.alertsIconColor,
                                          },
                                        },
                                        [_vm._v("warning")]
                                      ),
                                      _vm._v(
                                        "\n            " +
                                          _vm._s(
                                            _vm.$t(
                                              `attributes.editor.deleteMessages.${attributeUse.translationKey}`,
                                              attributeUse.translationParams
                                            )
                                          ) +
                                          "\n          "
                                      ),
                                    ],
                                    1
                                  )
                                : _vm._e(),
                            ])
                          }
                        ),
                      ],
                      2
                    ),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._v(" "),
      !_vm.loadingHierarchy && _vm.isHierarchyDialogOpen
        ? _c("add-new-hierarchy-level-dialog", {
            attrs: {
              "is-open": _vm.isHierarchyDialogOpen,
              level: _vm.level,
              mode: _vm.mode,
              params: _vm.getBaseParams(),
            },
            on: { closeHierarchyDialog: _vm.closeHierarchyDialog },
          })
        : _vm._e(),
      _vm._v(" "),
      !_vm.loadingHierarchy && _vm.isHierarchyDeleteDialogOpen
        ? _c("delete-hierarchy-level-dialog", {
            attrs: {
              "is-open": _vm.isHierarchyDeleteDialogOpen,
              level: _vm.level,
            },
            on: { childHierarchyDeleted: _vm.closeHierarchyDeleteDialog },
          })
        : _vm._e(),
      _vm._v(" "),
      _c("attribute-create-update-dialog", {
        key: _vm.get(
          _vm.attributeToEdit,
          "attributeKey",
          _vm.dialogModes.create
        ),
        attrs: {
          "is-open": _vm.isAttributeCreateUpdateDialogOpen,
          scope: _vm.attributeCreateUpdateScope,
          mode: _vm.mode,
          attribute: _vm.attributeToEdit,
          params: _vm.getBaseParams(),
        },
      }),
      _vm._v(" "),
      _c("hierarchy-upload-data", {
        attrs: {
          "show-dialog": _vm.showHierarchyUpload,
          params: _vm.getBaseParams(),
        },
        on: {
          closeDialog: function ($event) {
            _vm.showHierarchyUpload = false
          },
        },
      }),
      _vm._v(" "),
      _c("attribute-upload-data", {
        attrs: {
          "show-dialog": _vm.showAttributeUpload,
          params: _vm.getBaseParams(),
        },
        on: {
          closeDialog: function ($event) {
            _vm.showAttributeUpload = false
          },
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }