const keyPartSeparator = '::';

const mixin = {
  methods: {
    parseInputUpdateKey(key) {
      return key.split(keyPartSeparator);
    },
    getInputUpdateKey(...arr) {
      return arr.map(value => value || '').join(keyPartSeparator);
    },
  },
};

export default mixin;
