var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _vm.isExpanded
    ? _c(
        "div",
        { staticStyle: { display: "contents" } },
        [
          _vm.storeGroupAggregations.loading
            ? _c(
                "tr",
                { staticClass: "hierarchy__row hierarchy-level-item__main" },
                [
                  _c("td"),
                  _vm._v(" "),
                  _c(
                    "td",
                    { staticClass: "spinner-cell", attrs: { colspan: "16" } },
                    [_c("basic-spinner")],
                    1
                  ),
                  _vm._v(" "),
                  _c("td", { staticClass: "alert-cell" }),
                ]
              )
            : [
                !_vm.storeGroupAggregations.data.length
                  ? _c(
                      "tr",
                      {
                        staticClass:
                          "hierarchy__row hierarchy-level-item__main",
                      },
                      [
                        _c(
                          "td",
                          {
                            staticClass:
                              "store-group-heading hierarchy-level-item__group-end",
                            attrs: { colspan: "16" },
                          },
                          [
                            _vm._v(
                              "\n        " +
                                _vm._s(
                                  _vm.$t("gridView.noStoreGroupAggregations")
                                ) +
                                "\n      "
                            ),
                          ]
                        ),
                        _vm._v(" "),
                        _c("td"),
                        _vm._v(" "),
                        _c("td", { staticClass: "alert-cell" }),
                      ]
                    )
                  : _vm._e(),
                _vm._v(" "),
                _vm._l(_vm.storeGroupAggregations.data, function (storeGroup) {
                  return _c(
                    "tr",
                    {
                      key: storeGroup._id,
                      staticClass: "hierarchy__row hierarchy-level-item__main",
                    },
                    [
                      _c(
                        "td",
                        {
                          staticClass:
                            "store-group-heading hierarchy-level-item__heading hierarchy-level-item__group-end border-right",
                        },
                        [
                          _vm._v(
                            "\n        " +
                              _vm._s(
                                _vm.storegroupKeyNameMap[
                                  storeGroup.toolStoreGroupKey
                                ]
                              ) +
                              "\n      "
                          ),
                        ]
                      ),
                      _vm._v(" "),
                      _c("aggregations-row", {
                        attrs: {
                          "is-store-group-aggregation": "",
                          level: 0,
                          "sales-m-a-t": storeGroup.salesMAT,
                          "promo-share": storeGroup.promoShare,
                          "sales-impact-m-a-t": storeGroup.salesImpactMAT,
                          "regular-sales-impact": storeGroup.regularSalesImpact,
                          "total-impact-in-year": storeGroup.totalImpactInYear,
                          "regular-impact-in-year":
                            storeGroup.regularImpactInYear,
                          "total-cost-impact": storeGroup.totalCostImpact,
                          "cost-impact-in-year": storeGroup.costImpactInYear,
                          "regular-cost-impact-in-year":
                            storeGroup.regularCostImpactInYear,
                          "regular-cost-impact": storeGroup.regularCostImpact,
                          "margin-proposed": storeGroup.marginProposed,
                          "margin-delta": storeGroup.marginDelta,
                          "economic-tension": storeGroup.economicTension,
                          "competitor-tension": storeGroup.competitorTension,
                          "architecture-tension":
                            storeGroup.architectureTension,
                          "store-group-tension": storeGroup.storeGroupTension,
                          "competitor1-live-distance":
                            storeGroup.competitor1LiveDistance,
                          "competitor1-proposed-distance":
                            storeGroup.competitor1ProposedDistance,
                          "competitor1-delta-vs-prev":
                            storeGroup.competitor1DeltaVsPrev,
                          "competitor2-live-distance":
                            storeGroup.competitor2LiveDistance,
                          "competitor2-proposed-distance":
                            storeGroup.competitor2ProposedDistance,
                          "competitor2-delta-vs-prev":
                            storeGroup.competitor2DeltaVsPrev,
                          "competitor1-live-distance-price":
                            storeGroup.competitor1LiveDistancePrice,
                          "competitor1-proposed-distance-price":
                            storeGroup.competitor1ProposedDistancePrice,
                          "competitor1-delta-vs-prev-price":
                            storeGroup.competitor1DeltaVsPrevPrice,
                          "competitor2-live-distance-price":
                            storeGroup.competitor2LiveDistancePrice,
                          "competitor2-proposed-distance-price":
                            storeGroup.competitor2ProposedDistancePrice,
                          "competitor2-delta-vs-prev-price":
                            storeGroup.competitor2DeltaVsPrevPrice,
                          "competitor1-live-distance-volume":
                            storeGroup.competitor1LiveDistanceVolume,
                          "competitor1-proposed-distance-volume":
                            storeGroup.competitor1ProposedDistanceVolume,
                          "competitor1-delta-vs-prev-volume":
                            storeGroup.competitor1DeltaVsPrevVolume,
                          "competitor2-live-distance-volume":
                            storeGroup.competitor2LiveDistanceVolume,
                          "competitor2-proposed-distance-volume":
                            storeGroup.competitor2ProposedDistanceVolume,
                          "competitor2-delta-vs-prev-volume":
                            storeGroup.competitor2DeltaVsPrevVolume,
                          "num-price-changes": storeGroup.numPriceChanges,
                        },
                      }),
                    ],
                    1
                  )
                }),
              ],
        ],
        2
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }