<template>
  <svg
    width="16px"
    height="16px"
    viewBox="0 0 16 16"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g
        id="whole---mar-splits-export-–-05-A"
        transform="translate(-884.000000, -494.000000)"
        fill="#2F477C"
      >
        <g id="Group-61" transform="translate(530.000000, 484.000000)">
          <g id="Icon-/-Clear" transform="translate(354.000000, 10.000000)">
            <path
              id="Colour"
              d="M7.99597765,15.92 C3.65988827,15.92 0.08,12.3401117 0.08,8.00402235 C0.08,3.65988827 3.65184358,0.08 7.99597765,0.08 C12.332067,0.08 15.92,3.65988827 15.92,8.00402235 C15.92,12.3401117 12.3401117,15.92 7.99597765,15.92 Z M5.3975419,11.3667039 C5.6227933,11.3667039 5.80782123,11.286257 5.9526257,11.1414525 L7.99597765,9.09810056 L10.0393296,11.1414525 C10.1841341,11.2782123 10.369162,11.3667039 10.5944134,11.3667039 C11.0288268,11.3667039 11.3667039,11.0207821 11.3667039,10.5944134 C11.3667039,10.3852514 11.2782123,10.2002235 11.1334078,10.055419 L9.08201117,8.00402235 L11.1414525,5.94458101 C11.2943017,5.79173184 11.3667039,5.6227933 11.3667039,5.41363128 C11.3667039,4.98726257 11.0288268,4.64938547 10.6024581,4.64938547 C10.3852514,4.64938547 10.2163128,4.72178771 10.055419,4.87463687 L7.99597765,6.92603352 L5.94458101,4.88268156 C5.79977654,4.73787709 5.6227933,4.65743017 5.3975419,4.65743017 C4.97117318,4.65743017 4.63329609,4.98726257 4.63329609,5.42167598 C4.63329609,5.6227933 4.71374302,5.81586592 4.85854749,5.9526257 L6.90994413,8.00402235 L4.85854749,10.0634637 C4.71374302,10.2082682 4.63329609,10.3932961 4.63329609,10.5944134 C4.63329609,11.0207821 4.97117318,11.3667039 5.3975419,11.3667039 Z"
            />
          </g>
        </g>
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  name: 'Close',
};
</script>
