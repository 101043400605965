<template>
  <svg
    width="15px"
    height="20px"
    viewBox="0 0 15 20"
    version="1.1"
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
  >
    <title>Combined Shape</title>
    <g id="Page-1" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <path
        id="Combined-Shape"
        d="M7.5,0 C10.0156252,0 12.0704573,2.01646091 12.1826736,4.54403864 L12.1875001,4.76190476 L12.1875001,6.66666667 L13.1250001,6.66666667 C14.1071429,6.66666667 14.9192177,7.44412051 14.9943311,8.42313645 L15,8.57142857 L15,18.0952381 C15,19.0929705 14.2346939,19.9179354 13.2709751,19.9942411 L13.1250001,20 L1.87500015,20 C0.892857335,20 0.0807823309,19.2225462 0.00566893552,18.2435302 L0,18.0952381 L0,8.57142857 C0,7.57369615 0.765306299,6.74873124 1.7290251,6.67242558 L1.87500015,6.66666667 L2.8125001,6.66666667 L2.8125001,4.76190476 C2.8125001,2.13333333 4.91250047,0 7.5,0 Z M7.50000035,11.4285714 C6.4687504,11.4285714 5.62500044,12.2857143 5.62500044,13.3333333 C5.62500044,14.3809524 6.4687504,15.2380952 7.50000035,15.2380952 C8.5312503,15.2380952 9.37500026,14.3809524 9.37500026,13.3333333 C9.37500026,12.2857143 8.5312503,11.4285714 7.50000035,11.4285714 Z M7.5,1.80952381 C5.95412988,1.80952381 4.68721349,3.04046404 4.59869124,4.58862757 L4.59375049,4.76190476 L4.59375049,6.66666667 L10.4062502,6.66666667 L10.4062502,4.76190476 C10.4062502,3.13333333 9.10312528,1.80952381 7.5,1.80952381 Z"
        fill="#EA9B66"
      />
    </g>
  </svg>
</template>

<script>
export default {
  name: 'PromoLock',
};
</script>
