<template>
  <span>
    <v-dialog v-model="showDialog" :width="800" persistent>
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('alerts.failedRunModalTitle') }}
        </v-card-title>

        <v-icon class="close-button" color="grey" @click="closeDialog">close</v-icon>

        <div>
          <v-card-text>
            <v-simple-table height="40rem">
              <template v-slot:default>
                <thead>
                  <tr class="header">
                    <th class="text-left">{{ $t('pricing.category') }}</th>
                    <th class="text-left">{{ $t('pricing.pricingGroup') }}</th>
                    <th class="text-left">{{ $t('pricing.scenario') }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr
                    v-for="(run, ix) in failedScenarios"
                    :key="run.scenarioDescription"
                    class="run-row"
                  >
                    <td class="pt-1 pb-1">
                      {{ showIfDifferent(run.category, 'category', ix, failedScenarios) }}
                    </td>
                    <td>
                      {{ showIfDifferent(run.pricingGroup, 'pricingGroup', ix, failedScenarios) }}
                    </td>
                    <td>{{ run.scenarioDescription }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
          </v-card-text>
          <v-divider />
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapGetters } from 'vuex';
import { sortBy } from 'lodash';
import { categoryLevel, pricingGroupLevel } from '@enums/hierarchy';
import showIfDifferentMixin from '../mixins/showIfDifferent';

export default {
  mixins: [showIfDifferentMixin],
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },

    runs: {
      type: Array,
      default: () => [],
    },
  },

  computed: {
    ...mapGetters('hierarchy', ['getHierarchy']),

    failedScenarios() {
      // Get the hierarchy info and arrange into key: obj map for easy access
      const [catMap, pgMap] = [categoryLevel, pricingGroupLevel].map(level =>
        this.getHierarchy({ level }).reduce(
          (keyMap, hierarchy) => ({ ...keyMap, [hierarchy.levelEntryKey]: hierarchy }),
          {}
        )
      );

      // Add PG/ Cat descriptions to the run object
      const runsWithHierarchies = this.runs.map(run => {
        const { levelEntryDescription: pricingGroup, parentId: catId } = pgMap[run.hierarchyId];
        const { levelEntryDescription: category } = catMap[catId];
        return {
          ...run,
          pricingGroup,
          category,
        };
      });

      // Sort on all fields
      return sortBy(runsWithHierarchies, ['category', 'pricingGroup', 'scenarioDescription']);
    },
  },

  methods: {
    closeDialog() {
      this.$emit('closeDialog');
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';

.dialog-copy {
  font-size: 1.2rem;
  line-height: 3rem;
  font-weight: 300;
}

.upload-error {
  font-size: 1.2rem;
}

.upload-error-header {
  font-size: 2rem;
  font-weight: bold;
}
.upload-error-name {
  font-size: 1.2rem;
  font-weight: bold;
}

.header {
  > th {
    color: black !important;
    font-size: 1.2rem !important;
  }
}

.run-row {
  font-size: 1.2rem !important;
  > td {
    border-bottom: 0.1rem solid $table-border-color !important;
    > span {
      font-size: 1.2rem !important;
    }
  }
}
</style>
