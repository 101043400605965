import { VContainer } from 'vuetify/lib/components/VGrid';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VContainer, { attrs: { fluid: "" } }, [
    _c("div", { attrs: { id: "okta-login-container" } }),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }