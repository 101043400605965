'use strict';

module.exports = {
  '13WeeksLookBack': '13_weeks_look_back',
  '4WeeksLookBack': '4_weeks_look_back',
  '2WeeksLookBack': '2_weeks_look_back',
  '1WeeksLookBack': '1_weeks_look_back',
  '13WeeksLookForward': '13_weeks_look_forward',
  '4WeeksLookForward': '4_weeks_look_forward',
  '2WeeksLookForward': '2_weeks_look_forward',
  '1WeeksLookForward': '1_weeks_look_forward',
};
