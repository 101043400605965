import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabItem } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c("div", { staticClass: "py-2" }, [
      _c("span", { staticClass: "headline" }, [
        _vm._v(
          "\n      " +
            _vm._s(_vm.$t("settings.setArchitectureRules.overview")) +
            "\n    "
        ),
      ]),
    ]),
    _vm._v(" "),
    _c(
      "div",
      { staticClass: "border-left-blue" },
      [
        _c(
          VTabs,
          {
            staticClass: "pricing-tabs",
            attrs: {
              height: "4rem",
              "background-color": "transparent",
              color: "white",
              "active-class": "pricing-tabs__tab--active",
              "hide-slider": "",
            },
          },
          [
            _c(
              VTab,
              {
                staticClass: "pricing-tabs__tab pricing-tabs__tab--first",
                on: {
                  change: function ($event) {
                    return _vm.changeTab({ isGraphTab: false })
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.setArchitectureRules.tabs.list")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              VTab,
              {
                staticClass: "pricing-tabs__tab",
                on: {
                  change: function ($event) {
                    return _vm.changeTab({ isGraphTab: true })
                  },
                },
              },
              [
                _vm._v(
                  "\n        " +
                    _vm._s(_vm.$t("settings.setArchitectureRules.tabs.graph")) +
                    "\n      "
                ),
              ]
            ),
            _vm._v(" "),
            _c(
              "div",
              { staticClass: "pricing-tabs__filler" },
              [_c("norm-weight-toggle", { staticClass: "float-right" })],
              1
            ),
            _vm._v(" "),
            _c(
              VTabItem,
              [
                _c(
                  "product-impact-list",
                  {
                    attrs: {
                      "architecture-products":
                        _vm.filteredArchitectureProductList,
                      disabled: _vm.disabled,
                    },
                  },
                  [
                    _c("store-group-filter", {
                      attrs: {
                        "selected-tool-store-groups":
                          _vm.selectedToolStoreGroups,
                      },
                      on: { "selection-changed": _vm.setSelectedStoreGroups },
                    }),
                  ],
                  1
                ),
              ],
              1
            ),
            _vm._v(" "),
            _c(
              VTabItem,
              [
                _vm.isGraphDataLoading
                  ? _c("basic-spinner", { style: _vm.spinnerWrapperStyles })
                  : _vm._e(),
                _vm._v(" "),
                !_vm.isGraphDataLoading && _vm.architectureDriversReady
                  ? _c(
                      "div",
                      { staticClass: "d-flex flex-column" },
                      [
                        _c("store-group-filter", {
                          attrs: {
                            "selected-tool-store-groups":
                              _vm.selectedToolStoreGroups,
                          },
                          on: {
                            "selection-changed": _vm.setSelectedStoreGroups,
                          },
                        }),
                        _vm._v(" "),
                        _c("architecture-drivers-graph", {
                          attrs: {
                            height: _vm.graphHeight,
                            "architecture-products":
                              _vm.filteredArchitectureProductList,
                          },
                        }),
                      ],
                      1
                    )
                  : _vm._e(),
              ],
              1
            ),
          ],
          1
        ),
      ],
      1
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }