<template>
  <span>
    <v-dialog v-model="showDialog" width="500" persistent>
      <v-card>
        <v-card-title class="headline lighten-2" primary-title>
          {{ $t('attributes.uploadHierarchy.dialogHeading') }}
        </v-card-title>

        <v-icon class="close-button" color="grey" @click="closeDialog">close</v-icon>

        <file-upload
          v-if="isInitial"
          :upload-field-name="uploadFieldName"
          :is-saving="isSaving"
          :tool-store-group-independent="true"
          source="inputs"
          @save="save"
        />

        <div v-if="isSaving">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="v-input">{{ $t('attributes.uploadHierarchy.uploadingState') }}</div>
                </div>
              </div>
            </div>
          </v-card-text>
        </div>

        <div v-if="isValidated">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div class="dialog-copy">
                    <v-icon color="green darken-2">check_circle</v-icon>
                    {{ $t('attributes.uploadHierarchy.validationSuccessful') }}
                    <v-flex>{{ $t('attributes.uploadHierarchy.confirmUpload') }}</v-flex>
                  </div>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn color="primary" class="dialog-button" outlined depressed @click="reset()">{{
              $t('actions.cancel')
            }}</v-btn>
            <v-btn
              class="primary dialog-button"
              depressed
              :loading="busyImportingGroup"
              @click="confirm()"
              >{{ $t('actions.upload') }}</v-btn
            >
          </v-card-actions>
        </div>

        <div v-if="isFailed">
          <v-card-text>
            <div class="container grid-list-md">
              <div class="layout wrap">
                <div class="flex xs12">
                  <div v-if="uploadError.tooManyRows" class="flex xs12">
                    <div class="v-input">
                      {{ $t('attributes.uploadHierarchy.tooManyRows', { maxRows }) }}
                    </div>
                  </div>
                  <upload-error-card
                    v-if="uploadError.rowsWithMissingProductKey.length"
                    :error-message="$t('attributes.uploadHierarchy.missingProductKey')"
                    unique-key-prefix="missingProductKey"
                  />
                  <upload-error-card
                    v-if="uploadError.productKeysWithoutData.length"
                    :error-message="$t('attributes.uploadHierarchy.missingData')"
                    :details="uploadError.productKeysWithoutData"
                    unique-key-prefix="missingDataFor"
                  />
                  <upload-error-card
                    v-if="uploadError.uploadedProductKeysNotPresent.length"
                    :error-message="$t('attributes.uploadHierarchy.invalidProductKey')"
                    :details="uploadError.uploadedProductKeysNotPresent"
                    unique-key-prefix="invalidProductKey"
                  />
                  <upload-error-card
                    v-if="uploadError.duplicateProductKeys.length"
                    :error-message="$t('attributes.uploadHierarchy.duplicateProductKey')"
                    :details="uploadError.duplicateProductKeys"
                    unique-key-prefix="duplicateProductKey"
                  />
                  <upload-error-card
                    v-if="uploadError.productsWithIncorrectCategories.length"
                    :error-message="$t('attributes.uploadHierarchy.inconsistentCategory')"
                    :details="uploadError.productsWithIncorrectCategories"
                    unique-key-prefix="inconsistentCategory"
                  />
                  <upload-error-card
                    v-if="uploadError.uploadedCategoriesNotPresent.length"
                    :error-message="$t('attributes.uploadHierarchy.invalidCategory')"
                    :details="uploadError.uploadedCategoriesNotPresent"
                    unique-key-prefix="invalidCategory"
                  />
                  <upload-error-card
                    v-if="multipleParentsErrors.length"
                    :error-message="$t('attributes.uploadHierarchy.multipleHierarchiesAssigned')"
                    :details="multipleParentsErrors"
                    unique-key-prefix="multipleParent"
                  >
                    <template v-slot:row="{ item }">
                      <strong>{{ item.key }}</strong
                      >: {{ item.values }}
                    </template>
                  </upload-error-card>
                </div>
              </div>
            </div>
          </v-card-text>
          <v-divider />
          <v-card-actions>
            <v-spacer />
            <v-btn class="primary" @click="closeDialog()">{{ $t('actions.close') }}</v-btn>
          </v-card-actions>
        </div>
      </v-card>
    </v-dialog>
  </span>
</template>

<script>
import { mapActions, mapState, mapGetters } from 'vuex';
import { size } from 'lodash';
import to from 'await-to-js';
import states from '@enums/upload-states';

export default {
  props: {
    showDialog: {
      type: Boolean,
      default: false,
    },
    params: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      size,
      uploadedFiles: [],
      uploadError: null,
      currentStatus: states.initial,
      uploadFieldName: 'file',
    };
  },

  computed: {
    ...mapState('attributes', ['counts', 'uploadData']),
    ...mapState('clientConfig', ['uploadConfig']),
    ...mapState('hierarchy', ['busyImportingGroup']),
    ...mapGetters('workpackages', ['getSelectedWorkpackage']),

    maxRows() {
      return this.uploadConfig.hierarchyUpload.maxRows;
    },

    isInitial() {
      return this.currentStatus === states.initial;
    },

    isSaving() {
      return this.currentStatus === states.saving;
    },

    isValidated() {
      return this.currentStatus === states.validated;
    },

    isFailed() {
      return this.currentStatus === states.failed;
    },

    multipleParentsErrors() {
      if (
        !this.uploadError ||
        !this.uploadError.multipleParent ||
        !size(this.uploadError.multipleParent)
      ) {
        return [];
      }
      return Object.keys(this.uploadError.multipleParent).map(key => ({
        key,
        values: this.uploadError.multipleParent[key].join(', '),
      }));
    },
  },

  mounted() {
    this.reset();
  },

  methods: {
    ...mapActions('hierarchy', [
      'uploadDataFile',
      'confirmUpload',
      'doesProductExistWithIncompleteHierarchy',
    ]),

    closeDialog() {
      this.$emit('closeDialog');
      this.reset();
    },

    async save(formData) {
      this.currentStatus = states.saving;
      const [err] = await to(this.uploadDataFile(formData));

      if (err) {
        this.uploadError = err;
        this.currentStatus = states.failed;
      } else {
        this.currentStatus = states.validated;
        this.doesProductExistWithIncompleteHierarchy();
      }
    },

    async confirm() {
      const [err] = await to(
        this.confirmUpload({ params: this.params, primaryColumn: this.$t('pricing.productKey') })
      );

      if (err) {
        this.uploadError = err.response;
        this.currentStatus = states.failed;
      }

      this.closeDialog();
    },

    reset() {
      this.currentStatus = states.initial;
      this.uploadedFiles = [];
      this.uploadError = null;
    },
  },
};
</script>

<style lang="scss" scoped>
.dialog-copy {
  font-size: 1.6rem;
}
</style>
