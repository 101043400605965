<!-- Uses the generic pricing-filter component to set pricing group and scenario filters -->

<template>
  <v-layout>
    <pricing-filter
      v-if="!hidePricingGroupSelection"
      :filter="pricingGroupFilter"
      :loading="hierarchyLoading"
      :dense="dense"
      :solo="solo"
      :hide-label="hideLabel"
      @filterSelected="selectPricingGroup"
    />
    <pricing-filter
      :filter="scenarioFilters"
      :loading="scenarioLoading"
      :hide-label="hidePricingGroupSelection"
      :dense="dense"
      :solo="solo"
      @filterSelected="selectScenario"
    />
  </v-layout>
</template>

<script>
import { mapActions, mapGetters, mapState } from 'vuex';
import { isEmpty } from 'lodash';
import { ascending } from '@enums/sort-direction';
import { pricingGroupLevel } from '@enums/hierarchy';
import { candidate } from '@enums/scenario-version';

const { sortBy, toLower } = require('lodash');

export default {
  props: {
    hidePricingGroupSelection: {
      type: Boolean,
      default: false,
    },
    dense: { type: Boolean, default: true },
    solo: { type: Boolean, default: true },
    hideLabel: { type: Boolean, default: false },
  },
  data() {
    return {
      pricingGroups: [],
    };
  },

  computed: {
    ...mapState('scenarioMetadata', {
      scenarioMetadata: state => state.scenarioMetadata,
      scenarioLoading: state => state.loading,
    }),
    ...mapState('hierarchy', {
      hierarchy: state => state.hierarchy,
      hierarchyLoading: state => state.loading,
    }),
    ...mapState('filters', ['scenario', 'pricingGroup']),
    ...mapGetters('hierarchy', ['getHierarchy']),

    pricingGroupFilter() {
      return {
        name: this.$t('filters.pricingGroup'),
        state: 'pricingGroup',
        values: sortBy(this.pricingGroups, pg => toLower(pg.levelEntryDescription)),
        label: 'levelEntryDescription',
        itemValue: 'levelEntryKey',
      };
    },

    shouldSelectDefaultScenario() {
      return this.pricingGroup && !this.scenario;
    },
    sortedScenarios() {
      return sortBy(this.scenarioMetadata, s => toLower(s.scenarioDescription));
    },
    scenarioFilters() {
      return {
        name: this.$t('filters.scenario'),
        itemValue: 'scenarioKey',
        state: 'scenario',
        values: this.sortedScenarios,
        label: 'scenarioDescription',
      };
    },
  },

  async created() {
    // if hidePricingGroupSelection is true, we don't ever use pricingGroupFilter, so we don't need to fetch or filter pricingGroups.
    if (isEmpty(this.pricingGroups) && !this.hidePricingGroupSelection) {
      if (isEmpty(this.getHierarchy({ level: pricingGroupLevel }))) {
        await this.fetchHierarchy({
          params: {
            where: { level: pricingGroupLevel },
            sortBy: 'levelEntryDescription',
            sortDirection: ascending,
          },
        });
      }
      // hierarchy loaded, use scenarios from selected value
      this.pricingGroups = await this.hierarchiesWithProducts({ level: pricingGroupLevel });
    }
    if (this.pricingGroup) {
      // In the UI, when a user selects a pricing group, we want to unset scenario to ensure consistency.
      // When first loading the component, we don't want to unset the scenario filter if it already exists (comes from the newTab state).
      this.selectPricingGroup({
        filterValue: this.pricingGroup,
        unsetFilter: !this.scenario,
      });
    }
  },

  methods: {
    ...mapActions('hierarchy', ['fetchHierarchy', 'hierarchiesWithProducts']),
    ...mapActions('scenarioMetadata', ['fetchScenarioMetadata']),
    ...mapActions('filters', ['resetFilter', 'setSelectedFilter']),

    async selectPricingGroup({ filterValue, unsetFilter = true }) {
      const params = {
        where: { hierarchyId: filterValue },
        sortBy: 'scenarioDescription',
        sortDirection: ascending,
      };
      await this.fetchScenarioMetadata({ params, unsetFilter });

      let selectedScenario;
      if (!this.pricingGroup) {
        selectedScenario = null;
      } else if (this.shouldSelectDefaultScenario) {
        const candidateScenario = this.scenarioMetadata.filter(s => s.version === candidate)[0];
        if (candidateScenario) {
          this.setSelectedFilter({
            filterName: 'scenario',
            filterValue: candidateScenario.scenarioKey,
          });
          selectedScenario = candidateScenario.scenarioKey;
        }
      } else {
        // Get scenario from filters, if PG and scenario have already stored
        selectedScenario = this.scenario;
      }
      this.$emit('selectScenario', { filter: { filterValue: selectedScenario } });
      this.globalEmit('pricing-group-selected', { pricingGroupFilter: filterValue });
      this.globalEmit('architecture-rules-filter-updated', null);
    },

    selectScenario(filter) {
      this.$emit('selectScenario', { filter });
      this.globalEmit('architecture-rules-filter-updated', null);
    },
  },
};
</script>

<style scoped lang="scss"></style>
