import _ from 'lodash';
import { shouldTruncateString, truncateString } from './truncate-string-util';
import { isNumeric } from '../../../../shared-modules/data/utils/validation-utils';

const valueIsFalsy = ({ value }) => {
  return _.isNil(value) || value.toString().trim() === '';
};

/* All validations here return true in case value didn't pass validation,
 ** so that we don't need to negate checks everywhere. For example,
 ** validateValueExists will return false if value exists and true if not
 */

const validateValueExists = params => {
  const { value, colDef } = params;
  return colDef.required && colDef.editable && valueIsFalsy({ value });
};

const validations = {
  valueIsFalsy,
  validateValueExists,
  valueIsNotNegative: ({ value }) => {
    return !_.isNumber(value) || _.toNumber(value) < 0;
  },
  valueIsNotNumeric: ({ value }) => !isNumeric(value),
};

/**
 * Default parser for ag-grid table cell values
 * @param params - ValueParserParams object from ag-grid that contains the new value of the cell
 * @returns {string} - Processed string
 */
const defaultParser = params => {
  if (!params.newValue) return;
  // trim the new value so that no odd spaces are saved
  const inputValue = params.newValue;

  return inputValue.toString().trim();
};

const getNumericParser = stringToNumberFormatter => {
  /**
   * Numeric parser for ag-grid table cell values
   * @param {object} params - Params object from ag-grid that contains the new value of the cell
   * @returns {(number | string)} - ag-grid returns strings by default,
   * if a value is castable to a number, return that value, otherwise, return whatever was passed
   */
  return params => {
    const inputValue = defaultParser(params);
    if (['', undefined].includes(inputValue)) return '';

    // needs to be symmetric with formatNumber
    const valueAfterCoercion = stringToNumberFormatter(inputValue);
    return !_.isNaN(valueAfterCoercion) ? valueAfterCoercion : inputValue;
  };
};

const getNumberFormatter = ({ formatter, formatterParams, divideBy }) => {
  return params => {
    const value = _.get(params.data, params.colDef.field);
    const mappedValue = divideBy ? value / divideBy : value;
    return _.isFinite(mappedValue)
      ? formatter({ number: mappedValue, ...formatterParams })
      : mappedValue || '-';
  };
};

const getNumericColumnCustomType = ({
  formatter,
  formatterParams,
  stringToNumberFormatter,
  divideBy,
}) => ({
  valueParser: getNumericParser(stringToNumberFormatter),
  // required to show localized number when editing cell
  valueGetter: getNumberFormatter({ formatter, formatterParams, divideBy }),
  valueFormatter: getNumberFormatter({ formatter, formatterParams, divideBy }),
});

const getTruncatedValueGetters = ({ truncationLength, ignoreRegistry }) => ({
  valueGetter: params => {
    const value = _.get(params.data, params.colDef.field);
    const { wasTruncated, truncatedValue } = truncateString({
      text: value,
      truncationLength,
      ignoreRegistry,
    });
    return wasTruncated ? truncatedValue : value;
  },
  tooltipValueGetter: params => {
    const value = _.get(params.data, params.colDef.field);
    const { shouldTruncate } = shouldTruncateString({
      text: value,
      truncationLength,
      ignoreRegistry,
    });
    return shouldTruncate ? value : null;
  },
});

const checkboxRenderer = (params, eventHandlerMethod) => {
  const checkbox = document.createElement('input');
  checkbox.type = 'checkbox';
  checkbox.disabled = _.get(params, 'disabled');

  const pathForBox = params.colDef.field;
  const valueForBox = _.get(params.data, pathForBox);
  checkbox.checked = valueForBox;
  checkbox.addEventListener('change', () => eventHandlerMethod(params));
  return checkbox;
};

const utils = {
  checkboxRenderer,
};

const getStrictWidthColumn = width => ({
  minWidth: width,
  maxWidth: width,
});

export default {
  defaultParser,
  getNumericColumnCustomType,
  validations,
  utils,
  getTruncatedValueGetters,
  getStrictWidthColumn,
};
