<template>
  <div class="pricing-tooltip">
    <slot />
    <div v-if="!disabled" :class="setClass">
      <span
        v-if="valueIsStringOrNumber(value)"
        class="tooltip-text"
        v-html="formatValueForLineBreaks(value)"
      />
      <div v-if="valueIsObject(value)" class="tooltip-list">
        <div v-for="(val, propName) in value" :key="propName" class="tooltip-list-item">
          <span>{{ propName }}:</span>
          <span>{{ val }}</span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { isObject, isString, isNumber } from 'lodash';

export default {
  props: {
    value: {
      type: [String, Object, Number],
      default: null,
    },

    disabled: {
      type: Boolean,
      default: false,
    },

    isError: {
      type: [Boolean, Function],
      default: null,
    },

    position: {
      type: String,
      default: 'top-right',
      // Options are ['top-left', 'top-middle', 'top-right', 'bottom-left', 'bottom-middle', 'bottom-right', 'top', 'bottom']
    },
  },

  computed: {
    setClass() {
      let defaultClass = 'tooltip-container';

      if (this.isError) {
        defaultClass += ' tooltip-error';
      }

      if (this.position) {
        defaultClass += ` ${this.position}`;
      }

      return defaultClass;
    },
  },

  methods: {
    valueIsObject(value) {
      return isObject(value);
    },

    valueIsStringOrNumber(value) {
      return isString(value) || isNumber(value);
    },

    formatValueForLineBreaks(value) {
      return value.toString().replace('\n', '<br/>');
    },
  },
};
</script>

<style lang="scss" scoped>
.pricing-tooltip {
  position: relative;
  display: flex;

  .tooltip-container {
    display: flex;
    position: absolute;
    z-index: 10000;
    visibility: hidden;
    width: auto;
    background-color: rgba(97, 97, 97, 0.9);
    color: #fff;
    text-align: center;
    border-radius: 0.4rem;
    padding: 0.5rem 1.6rem;
    text-transform: initial;

    &.top {
      bottom: 110%;
      &-left {
        bottom: 110%;
        right: 110%;
      }
      &-middle {
        bottom: 110%;
        left: 55%;
      }
      &-right {
        bottom: 110%;
        left: 110%;
      }
    }
    &.bottom {
      top: 110%;
      &-left {
        top: 110%;
        right: 110%;
      }
      &-middle {
        top: 110%;
        left: 55%;
      }
      &-right {
        top: 110%;
        left: 110%;
      }
    }

    &.tooltip-error {
      background-color: rgba(208, 2, 27, 0.9);
    }

    .tooltip-text {
      display: flex;
      width: max-content;
    }

    .tooltip-list {
      display: flex;
      flex-direction: column;
      width: max-content;

      &-item {
        display: flex;

        span {
          display: flex;
          padding: 0.2rem;
          text-align: left;
        }
      }
    }
  }

  &:hover {
    .tooltip-container {
      visibility: visible;
    }
  }
}
</style>
