<template>
  <v-row>
    <v-col md="4" class="pr-3">
      <!-- List of global attributes available for use in architecture drivers/sub-groups -->
      <div class="py-2">
        <span class="headline">
          {{ $t('settings.setArchitectureRules.globalAttributes') }}
        </span>
      </div>
      <basic-spinner v-if="architectureDataLoading" class="spinner-wrapper" />
      <draggable
        v-show="!architectureDataLoading"
        :group="{ name: 'draggable-attribute-lists', put: false }"
        :list="globalAttributes"
        :disabled="disabled"
        class="draggable-element scroll"
        handle=".draggable-attribute-icon"
      >
        <v-card
          v-for="attribute in globalAttributes"
          :key="attribute._id"
          flat
          height="2.5rem"
          class="panel my-1"
        >
          <v-card-text class="py-1 px-1 attribute-list">
            <v-layout>
              <v-flex xs11 class="text-truncate">
                <span :class="disabled ? '' : 'black--text'">
                  {{ attribute.displayDescription }}
                </span>
              </v-flex>

              <v-flex xs1 class="draggable-attribute-icon">
                <v-icon
                  class="drag-icon"
                  :class="disabled ? 'disabled-drag-icon' : ''"
                  text-xs-right
                >
                  open_with
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </draggable>

      <!-- List of PG attributes available for use in architecture drivers/sub-groups -->
      <div class="py-2">
        <span class="headline">
          {{ $t('settings.setArchitectureRules.pricingGroupAttributes') }}
        </span>
      </div>
      <basic-spinner v-if="architectureDataLoading" class="spinner-wrapper" />
      <draggable
        v-show="!architectureDataLoading"
        :group="{ name: 'draggable-attribute-lists', put: false }"
        :list="pricingGroupAttributes"
        :disabled="disabled"
        class="draggable-element scroll"
        handle=".draggable-attribute-icon"
      >
        <v-card
          v-for="attribute in pricingGroupAttributes"
          :key="attribute._id"
          flat
          height="2.5rem"
          class="panel my-1"
        >
          <v-card-text class="py-1 px-1 attribute-list">
            <v-layout>
              <v-flex xs11 class="text-truncate">
                <span :class="disabled ? '' : 'black--text'">
                  {{ attribute.displayDescription }}
                </span>
              </v-flex>

              <v-flex xs1 class="draggable-attribute-icon">
                <v-icon
                  class="drag-icon"
                  :class="disabled ? 'disabled-drag-icon' : ''"
                  text-xs-right
                >
                  open_with
                </v-icon>
              </v-flex>
            </v-layout>
          </v-card-text>
        </v-card>
      </draggable>
    </v-col>

    <v-col md="8">
      <!-- Sub-group splitting -->
      <div class="py-2">
        <span class="item-text font-weight-bold">
          {{ $t('settings.setArchitectureRules.subGroupSplittingAttributes') }}
        </span>
      </div>
      <draggable
        group="draggable-attribute-lists"
        :list="subGroupSplittingAttributes"
        class="architecture-drivers"
        handle=".draggable-attribute-icon"
        @change="handleMoveToSubGroup"
      >
        <basic-spinner v-if="architectureDataLoading" class="spinner-wrapper" />
        <template v-else>
          <architecture-sub-group
            v-for="attribute in subGroupSplittingAttributes"
            :key="attribute.attributeKey"
            :attribute="attribute"
            :disabled="disabled"
            @removeFromSubGroupSplittingAttributes="removeFromSubGroupSplittingAttributes"
          />
          <v-layout class="drag-zone">
            <v-flex xs11 grow class="pr-1">
              <span class="item-text font-weight-bold">
                {{ $t('settings.setArchitectureRules.draggableSubGroupMessage') }}
              </span>
            </v-flex>
          </v-layout>
        </template>
      </draggable>

      <!-- Architecture drivers -->
      <div class="item-text py-2">
        <span class="font-weight-bold">
          {{ $t('settings.setArchitectureRules.architectureDrivers') }}
        </span>
        <span class="font-weight-light">
          {{ $t('settings.setArchitectureRules.appliedToAllSubGroups') }}
        </span>
      </div>
      <draggable
        group="draggable-attribute-lists"
        :list="currentArchitectureDrivers"
        :disabled="disabled"
        class="draggable-element architecture-drivers"
        handle=".draggable-attribute-icon"
        @change="handleDragDriverChange"
      >
        <basic-spinner v-if="architectureDataLoading" class="spinner-wrapper" />
        <template v-else>
          <architecture-driver
            v-for="architectureDriverAttribute in currentArchitectureDrivers"
            :key="architectureDriverAttribute.attributeKey"
            :driver-attribute="architectureDriverAttribute"
            :disabled="disabled"
            @removeFromAttributesDrivers="removeFromAttributesDrivers"
            @updateArchitectureDriver="createUpdate"
          />
          <v-layout class="drag-zone">
            <v-flex xs11 grow class="pr-1">
              <span class="item-text font-weight-bold">
                {{ $t('settings.setArchitectureRules.draggableDriverMessage') }}
              </span>
            </v-flex>
          </v-layout>
        </template>
        <v-layout justify-end class="mt-2">
          <v-flex xs2 grow>
            <v-btn
              :disabled="isSaveDisabled || architectureDataLoading"
              color="success"
              class="save"
              small
              depressed
              @click="saveUpdates"
            >
              {{ $t('actions.save') }}
            </v-btn>
          </v-flex>
        </v-layout>
      </draggable>
    </v-col>
  </v-row>
</template>

<script>
import { mapState, mapGetters, mapActions } from 'vuex';
import { filter, find, forEach, get, groupBy, omit, some, sortBy, pick, findIndex } from 'lodash';
import attributeScope from '@enums/attribute-scope';

export default {
  props: {
    disabled: {
      type: Boolean,
      required: true,
    },
  },

  data() {
    return {
      availableAttributes: [], // attributes not currently used in drivers or sub-group splitting
      currentArchitectureDrivers: [],
      subGroupSplittingAttributes: [],
      updates: {},
      inititalSavePerformed: false,
      deletions: [], // array of _ids to delete from mongo
      architectureDataLoading: false,
    };
  },

  computed: {
    ...mapState('architectureDrivers', {
      architectureDrivers: 'architectureDrivers',
      architectureDriversLoading: 'loading',
    }),
    ...mapState('filters', ['pricingGroup', 'scenario', 'architectureGroup']),
    ...mapState('scenarioMetadata', ['scenarioMetadata']),
    ...mapState('workpackages', ['selectedWorkpackage']),
    ...mapGetters('attributes', ['attributesByArchitectureGroup']),

    globalAttributes() {
      return sortBy(get(this.availableAttributes, attributeScope.global), 'displayDescription');
    },

    pricingGroupAttributes() {
      return sortBy(
        get(this.availableAttributes, attributeScope.pricingGroup),
        'displayDescription'
      );
    },

    // TODO: move updates and deletions to store so graph can react
    hasUnsavedUpdates() {
      const scenario = find(this.scenarioMetadata, {
        scenarioKey: this.scenario,
        workpackageId: this.selectedWorkpackage._id,
      });
      const hasSubGroupSplittingAttributesUpdates =
        this.subGroupSplittingAttributes.length !==
        get(scenario, ['subGroupSplittingAttributes', this.architectureGroup], []).length;
      const hasUpdates =
        Object.keys(this.updates).length > 0 ||
        this.deletions.length > 0 ||
        hasSubGroupSplittingAttributesUpdates;
      this.globalEmit('architecture-driver-has-updates', hasUpdates);
      return hasUpdates;
    },

    isSaveDisabled() {
      return this.disabled || (!this.hasUnsavedUpdates && this.inititalSavePerformed);
    },
  },

  events: {
    async onArchitectureRulesFilterUpdated() {
      await this.refreshDriversAndSubGroups();
    },
  },

  async created() {
    await this.refreshDriversAndSubGroups();
  },

  methods: {
    ...mapActions('architectureDrivers', ['fetchArchitectureDrivers', 'updateArchitectureDrivers']),
    ...mapActions('hierarchy', ['updateHierarchy', 'fetchHierarchy']),
    ...mapActions('scenarioMetadata', ['updateScenarioMetadata']),

    async refreshDriversAndSubGroups() {
      // Set attributes list, sub-groups and arch drivers from vuex
      if (this.scenario && this.pricingGroup && this.architectureGroup) {
        this.architectureDataLoading = true;
        await Promise.all([
          this.fetchArchitectureDrivers(),
          this.fetchHierarchy({ where: { levelEntryKey: this.architectureGroup } }),
        ]);

        this.setArchitectureDrivers();
        this.setSubGroupSplittingAttributes();
        this.updateAvailableAttributes();

        this.updates = {};
        this.deletions = [];
        this.architectureDataLoading = false;
      }
    },

    setArchitectureDrivers() {
      // retrieved drivers that are already saved in Mongo
      this.currentArchitectureDrivers = [...this.architectureDrivers];
    },

    setSubGroupSplittingAttributes() {
      const scenario = find(this.scenarioMetadata, {
        scenarioKey: this.scenario,
        workpackageId: this.selectedWorkpackage._id,
      });
      this.subGroupSplittingAttributes = [
        ...get(scenario, ['subGroupSplittingAttributes', this.architectureGroup], []),
      ];
    },

    updateAvailableAttributes() {
      const allPricingGroupAttributes = this.attributesByArchitectureGroup(
        this.architectureGroup
      ).map(attribute => {
        return {
          displayDescription: attribute.displayDescription,
          attributeKey: attribute.attributeKey,
          architectureGroupId: this.architectureGroup,
          scope: attribute.scope,
          dataType: attribute.dataType,
        };
      });

      // get a list of used attributeKeys to match against
      const architectureDriverIds = this.currentArchitectureDrivers.map(
        attribute => attribute.attributeKey
      );

      // get a list of used attributeKeys to match against
      const subGroupSplittingAttributesIds = this.subGroupSplittingAttributes.map(
        ({ attributeKey }) => attributeKey
      );

      // filter out the attributes already used attributes
      this.availableAttributes = groupBy(
        filter(
          allPricingGroupAttributes,
          a =>
            !architectureDriverIds.includes(a.attributeKey) &&
            !subGroupSplittingAttributesIds.includes(a.attributeKey)
        ),
        'scope'
      );
    },

    removeFromAttributesDrivers({ attributeKey }) {
      // Remove the attribute from the displayed architecture drivers
      this.currentArchitectureDrivers = filter(
        this.currentArchitectureDrivers,
        driver => driver.attributeKey !== attributeKey
      );

      // Prepare for deletion from db
      this.deletions.push({
        architectureGroupId: this.architectureGroup,
        scenarioKey: this.scenario,
        attributeKey,
      });

      // Remove any unsaved changes made to this driver
      this.updates = omit(this.updates, attributeKey);

      // Add the attribute back to the list of available attributes
      this.updateAvailableAttributes();
    },

    removeFromSubGroupSplittingAttributes({ attributeKey }) {
      // Remove the attribute from the displayed sub-groups
      this.subGroupSplittingAttributes = filter(
        this.subGroupSplittingAttributes,
        subGroup => subGroup.attributeKey !== attributeKey
      );

      // Add the attribute back to the list of available attributes
      this.updateAvailableAttributes();
    },

    handleDragDriverChange({ added = {}, moved = {}, removed = {} }) {
      // attribute moved into architecture drivers/sub-groups
      if (some(added)) {
        this.updateAvailableAttributes();
      }

      // drivers reordered
      if (some(moved)) {
        this.updateDriverPriorities();
      }
      if (some(removed)) {
        // If an item was removed we need to add it to the deletions
        this.deletions.push({
          architectureGroupId: this.architectureGroup,
          scenarioKey: this.scenario,
          attributeKey: removed.element.attributeKey,
        });
      }
    },

    handleMoveToSubGroup({ added }) {
      if (some(added)) {
        // If an item was added we only want to take the default attributes as if it
        // was added by dragging from drivers it may have extra information and cause
        // bugs with the graph updates.
        const subGroupIndex = findIndex(this.subGroupSplittingAttributes, {
          attributeKey: added.element.attributeKey,
        });
        this.subGroupSplittingAttributes[subGroupIndex] = pick(
          this.subGroupSplittingAttributes[subGroupIndex],
          ['attributeKey', 'displayDescription', 'architectureGroupId', 'dataType']
        );
      }
    },

    updateDriverPriorities() {
      forEach(this.currentArchitectureDrivers, (driver, priority) => {
        const architectureDriver = pick(driver, [
          'attributeKey',
          'architectureGroupId',
          'driverCalculationType',
          'categoricalImpact',
          'linearImpact',
        ]);
        architectureDriver.priority = priority;
        this.$set(this.updates, [driver.attributeKey], architectureDriver);
      });
    },

    createUpdate(attributeDriver) {
      const { attributeKey } = attributeDriver;
      this.updates = { ...this.updates, [attributeKey]: attributeDriver };
      this.updateDriverPriorities(); // reorder after other updates
    },

    async saveUpdates() {
      this.inititalSavePerformed = true;
      const scenarioToUpdate = find(this.scenarioMetadata, {
        scenarioKey: this.scenario,
        workpackageId: this.selectedWorkpackage._id,
      });
      const hierarchyUpdates = {
        subGroupSplittingAttributes: {
          ...(scenarioToUpdate.subGroupSplittingAttributes || {}),
          [this.architectureGroup]: this.subGroupSplittingAttributes.map(attribute =>
            pick(attribute, ['attributeKey', 'displayDescription', 'dataType'])
          ),
        },
      };
      await Promise.all([
        this.updateArchitectureDrivers({ updates: this.updates, deletions: this.deletions }),
        this.updateScenarioMetadata({ updates: hierarchyUpdates, id: scenarioToUpdate._id }),
      ]);

      this.updates = {};
      this.deletions = [];
    },
  },
};
</script>

<style lang="scss" scoped>
@import '@style/base/_variables.scss';
.drag-zone {
  padding-right: 1rem;
  padding-left: 2rem;
  color: $pricing-grey;
  margin-right: auto;
  margin-left: unset;
  margin-top: 1rem;
  width: 93%;
}

.attribute-list,
.architecture-drivers {
  font-size: 1.2rem;
}

.v-expansion-panel {
  box-shadow: none;
}

.text-truncate {
  line-height: unset !important;
}

.panel {
  background-color: $settingsTableRowColor !important;
}

.delete-icon {
  color: $rulesetDarkBlue;
  padding-top: 10px !important;
}

.delete-icon,
.disabled-drag-icon,
.drag-icon {
  font-size: 1.5rem;
}

.disabled-drag-icon {
  color: $pricing-background !important;
  cursor: unset;
}

.scroll {
  max-height: 30rem;
  overflow: scroll;
}

.spinner-wrapper {
  justify-content: center;
}
</style>
