var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "svg",
    {
      attrs: {
        width: "12px",
        height: "14px",
        viewBox: "0 0 12 14",
        version: "1.1",
        xmlns: "http://www.w3.org/2000/svg",
        "xmlns:xlink": "http://www.w3.org/1999/xlink",
      },
    },
    [
      _c(
        "g",
        {
          attrs: {
            id: "Buttons-–-updated",
            stroke: "none",
            "stroke-width": "1",
            fill: "none",
            "fill-rule": "evenodd",
            opacity: "0.997706214",
          },
        },
        [
          _c(
            "g",
            {
              attrs: {
                id: "Group-11",
                transform: "translate(-354.000000, -53.000000)",
                fill: "#fff",
              },
            },
            [
              _c(
                "g",
                {
                  attrs: {
                    id: "Group-10",
                    transform: "translate(282.000000, 24.000000)",
                  },
                },
                [
                  _c(
                    "g",
                    {
                      attrs: {
                        id: "Icon-/-Import-blu",
                        transform: "translate(70.000000, 28.000000)",
                      },
                    },
                    [
                      _c("path", {
                        attrs: {
                          id: "Combined-Shape",
                          d: "M3.62939103,10.8636364 L3.62939103,12.8302583 C3.62939103,13.4049815 3.93431527,13.6761993 4.49113345,13.6761993 L11.504391,13.6761993 C12.0612092,13.6761993 12.3661334,13.4049815 12.3661334,12.8302583 L12.3661334,10.8636364 L13.725035,10.8636364 L13.725035,12.9658672 C13.725035,14.2961255 12.9958683,15 11.6170804,15 L4.38507284,15 C3.00628497,15 2.27048951,14.2961255 2.27048951,12.9658672 L2.27048951,10.8636364 L3.62939103,10.8636364 Z M8.00439103,1 C8.35571678,1 8.63412587,1.27767528 8.63412587,1.60055351 L8.63412587,7.65129151 L8.58109557,8.55535055 L8.95230769,8.0904059 L10.4286713,6.58635693 C10.5347319,6.47012076 10.6962092,6.40817511 10.8486713,6.40817511 C11.1535956,6.40817511 11.3959441,6.62648776 11.3959441,6.92999329 C11.3959441,7.09143241 11.3431789,7.20849044 11.2304895,7.31181147 L8.48829254,9.91143911 C8.32920163,10.0728782 8.17011072,10.1245387 8.00439103,10.1245387 C7.83867133,10.1245387 7.68620921,10.0728782 7.5271183,9.91143911 L4.79048951,7.31181147 C4.67780012,7.20849044 4.61230769,7.09143241 4.61230769,6.92999329 C4.61230769,6.62648776 4.87401224,6.40817511 5.17230769,6.40817511 C5.3313986,6.40817511 5.47351981,6.47012076 5.57958042,6.58635693 L7.06310315,8.0904059 L7.43431527,8.54889299 L7.38128497,7.65129151 L7.38128497,1.60055351 C7.38128497,1.27767528 7.65969406,1 8.00439103,1 Z",
                        },
                      }),
                    ]
                  ),
                ]
              ),
            ]
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }