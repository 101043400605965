const negativeSymbol = '-';

const isDigit = key => key >= '0' && key <= '9';

// Ensures the user can only use a comma separator once
const isValidComma = (key, value, decimalPoint) => {
  return key === decimalPoint && value.indexOf(decimalPoint) === -1;
};

// Ensures only one negative symbol can be inputted at position 0
const isValidNegative = (key, value, selectionStart) => {
  return key === negativeSymbol && value.indexOf(negativeSymbol) === -1 && selectionStart === 0;
};

const spliceString = ({ str, index, count, add }) => {
  if (index < 0) {
    index = str.length + index;
    if (index < 0) index = 0;
  }
  return str.slice(0, index) + (add || '') + str.slice(index + count);
};

const getValidationRegExp = (allowNegative, decimalSeparator, thousandsSeparator) => {
  const pattern = `^${
    allowNegative ? '-?' : ''
  }(\\d+[\\${thousandsSeparator}])*(\\d+[\\${decimalSeparator}])?\\d+$`;
  return new RegExp(pattern);
};

const mixin = {
  methods: {
    ensureInteger(evt, allowNegative) {
      const key = evt.key;
      const { value, selectionStart } = evt.target;
      const thousandsSeparator = this.$t('number.thousandsSeparator');
      const isValid =
        isDigit(key) ||
        key === thousandsSeparator ||
        (isValidNegative(key, value, selectionStart) && allowNegative);
      if (!isValid) evt.preventDefault();
    },
    ensureDecimal(evt, allowNegative = false) {
      const key = evt.key;
      const { value, selectionStart } = evt.target;
      const decimalPoint = this.$t('number.decimalPoint');
      const thousandsSeparator = this.$t('number.thousandsSeparator');
      const isValid =
        isDigit(key) ||
        isValidComma(key, value, decimalPoint) ||
        key === thousandsSeparator ||
        (isValidNegative(key, value, selectionStart) && allowNegative);
      if (!isValid) evt.preventDefault();
    },
    ensurePastedDecimal(event, allowNegative) {
      const data = (event.clipboardData || window.clipboardData).getData('text');
      if (!data) return;
      const { selectionStart, selectionEnd, value } = event.target;
      const decimalPoint = this.$t('number.decimalPoint');
      const thousandsSeparator = this.$t('number.thousandsSeparator');
      const validationRegExp = getValidationRegExp(allowNegative, decimalPoint, thousandsSeparator);
      const newValue = value
        ? spliceString({
            str: value,
            index: selectionStart,
            count: selectionEnd - selectionStart,
            add: data,
          })
        : data;
      if (!validationRegExp.test(newValue)) event.preventDefault();
    },
  },
};

export default mixin;
