var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    {
      staticClass: "d-flex",
      attrs: {
        "custom-title-msg": _vm.tooltipValue,
        "custom-title-hidden": !_vm.displayTooltip,
        "custom-title-position": "right",
      },
    },
    [_vm._v("\n  " + _vm._s(_vm.displayedValue) + "\n")]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }