import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VForm } from 'vuetify/lib/components/VForm';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VDialog,
    {
      attrs: { width: "40rem" },
      on: { "click:outside": _vm.closeDialog },
      model: {
        value: _vm.open,
        callback: function ($$v) {
          _vm.open = $$v
        },
        expression: "open",
      },
    },
    [
      _c(
        VCard,
        [
          _c(
            VCardTitle,
            {
              staticClass: "headline lighten-2",
              attrs: { "primary-title": "" },
            },
            [
              _vm._v(
                "\n      " +
                  _vm._s(_vm.$t("gridView.addScenario.modalTitle")) +
                  "\n    "
              ),
            ]
          ),
          _vm._v(" "),
          _c(
            VForm,
            {
              attrs: { "lazy-validation": "" },
              on: {
                submit: function ($event) {
                  $event.preventDefault()
                  return _vm.submit.apply(null, arguments)
                },
              },
            },
            [
              _c(
                VCardText,
                [
                  _c(VTextField, {
                    attrs: {
                      rules: _vm.rules,
                      label: _vm.$t("gridView.addScenario.scenarioName"),
                    },
                    model: {
                      value: _vm.form.scenarioName,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "scenarioName", $$v)
                      },
                      expression: "form.scenarioName",
                    },
                  }),
                  _vm._v(" "),
                  _c(VSelect, {
                    attrs: {
                      items: _vm.scenarioOptions,
                      rules: _vm.rules,
                      label: _vm.$t("gridView.addScenario.basedOn"),
                    },
                    model: {
                      value: _vm.form.selectedCopyScenario,
                      callback: function ($$v) {
                        _vm.$set(_vm.form, "selectedCopyScenario", $$v)
                      },
                      expression: "form.selectedCopyScenario",
                    },
                  }),
                  _vm._v(" "),
                  _c("div", { staticClass: "info-text mt-2 mx-2" }, [
                    _c("small", [
                      _vm._v(
                        _vm._s(_vm.$t("gridView.addScenario.modalWarning"))
                      ),
                    ]),
                  ]),
                ],
                1
              ),
              _vm._v(" "),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "cancel-btn",
                      attrs: { small: "", text: "", outlined: "" },
                      on: { click: _vm.closeDialog },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.cancel")) +
                          "\n        "
                      ),
                    ]
                  ),
                  _vm._v(" "),
                  _c(
                    VBtn,
                    {
                      staticClass: "save",
                      attrs: {
                        disabled: !_vm.canSave,
                        small: "",
                        color: "success",
                      },
                      on: { click: _vm.addScenario },
                    },
                    [
                      _vm._v(
                        "\n          " +
                          _vm._s(_vm.$t("actions.save")) +
                          "\n        "
                      ),
                    ]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }