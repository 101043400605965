export default {
  chart: {
    backgroundColor: 'transparent',
    borderWidth: 0,
    type: 'area',
    style: {
      overflow: 'visible',
      fontFamily: 'Arial, Verdana, Helvetica, "Lucida Grande", "Lucida Sans Unicode", serif',
    },
    skipClone: true,
  },
  title: {
    text: '',
  },
  credits: {
    enabled: false,
  },
  xAxis: {
    labels: {
      enabled: false,
      style: {
        fontSize: '1.4rem',
      },
    },
    title: {
      text: null,
    },
    startOnTick: false,
    endOnTick: false,
    tickPositions: [],
    lineColor: 'transparent',
  },
  yAxis: {
    endOnTick: false,
    startOnTick: false,
    labels: {
      enabled: false,
      style: {
        fontSize: '1.4rem',
      },
    },
    title: {
      text: null,
    },
    gridLineColor: 'transparent',
  },
  legend: {
    enabled: false,
  },
  tooltip: {
    enabled: false,
  },
  colors: ['black'],
  plotOptions: {
    series: {
      animation: false,
      lineWidth: 1,
      shadow: false,
      states: {
        hover: {
          enabled: false,
          lineWidthPlus: 0,
        },
        inactive: {
          // inactive lines should have the same opacity as active
          opacity: 1,
        },
      },
      marker: {
        enabled: false,
      },
      fillOpacity: 0,
    },
    area: {
      step: 'left',
    },
  },
};
