import axios from 'axios';
import _ from 'lodash';
import moment from 'moment';
import to from 'await-to-js';
import {
  rootHierarchyLevel,
  unitLevel,
  pricingGroupLevel,
  wholesaleHierarchyLevels,
} from '@enums/hierarchy';
import { hoursMinutesDayMonthYearFormat } from '@enums/date-formats';
import { makeHierarchyKey } from '../../utils/wholesale-product-results-utils';
import { formatFilters } from '../utils/filters';
import downloadXlsxFile from '../utils/download-xlsx-file';

const {
  categoryLevel,
  subcategoryLevel,
  segmentLevel,
  subsegmentLevel,
  microsegmentLevel,
} = wholesaleHierarchyLevels;
const allHierarchyLevels = [rootHierarchyLevel, unitLevel, categoryLevel, pricingGroupLevel];

const defaultExpandedHierarchyLevelItems = rootHierarchyLevelItemId => [
  rootHierarchyLevelItemId,
  rootHierarchyLevelItemId,
  null,
];

const getHierarchyLevelAggregationRequestKey = ({ level, aggregateStoreGroups }) =>
  aggregateStoreGroups ? `${level}-store-group` : level;

const defaultHierarchyLevelAggregationLoadings = () => {
  const loadings = {};
  allHierarchyLevels.forEach(level => {
    // we always load aggregations for the root level
    loadings[getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups: false })] =
      level === rootHierarchyLevel;
    loadings[getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups: true })] = false;
  });
  return loadings;
};

const getInitialState = () => {
  return {
    hierarchy: {},
    hierarchyIdMap: {},
    rootHierarchyLevelItemId: '',
    aggregationLoadings: defaultHierarchyLevelAggregationLoadings(),
    aggregationRequestsCanceller: {},
    expandedHierarchyLevelItems: [],
    expandedStoreGroupAggregations: [],
    loadingStoreGroupAggregations: false,
    selectedAttributeFilters: [],
    loadingMonthlyAggregations: false,
    monthlyAggregations: [],
    selectedHistoricalPeriod: null,
    downloadingMonthlyAggregations: false,
  };
};

const getExtraFetchHierarchyItemAggregationParams = (
  state,
  { aggregationLevel, parentId, aggregateStoreGroups } = {}
) => {
  const basicParams = {
    parentId: parentId || state.rootHierarchyLevelItemId,
    parentLevel: Math.max(0, aggregationLevel - 1),
    aggregationLevel,
  };
  if (aggregateStoreGroups) {
    basicParams.aggregateStoreGroups = true;
  }
  return basicParams;
};

const store = {
  namespaced: true,

  state: getInitialState(),

  getters: {
    anyAggregationsLoading(state) {
      return Object.values(state.aggregationLoadings).some(v => v === true);
    },
  },

  mutations: {
    resetState(state) {
      Object.assign(state, getInitialState());
    },

    setRootHierarchyLevelItemId(state, { rootHierarchyLevelItem }) {
      state.rootHierarchyLevelItemId = rootHierarchyLevelItem.levelEntryKey;
    },

    setExpandedHierarchyLevelItems(state, expandedHierarchyLevelItems) {
      state.expandedHierarchyLevelItems = expandedHierarchyLevelItems;
    },

    initExpandedHierarchyLevelItems(state) {
      // set state.expandedHierarchyLevelItems to default if hierarchy wasn't expanded
      state.expandedHierarchyLevelItems = state.expandedHierarchyLevelItems.length
        ? state.expandedHierarchyLevelItems
        : defaultExpandedHierarchyLevelItems(state.rootHierarchyLevelItemId);
    },

    setAggregationLoadings(state, { level, aggregateStoreGroups, isLoading }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      state.aggregationLoadings = {
        ...state.aggregationLoadings,
        [key]: isLoading,
      };
    },

    resetAggregationLoadings(state) {
      state.aggregationLoadings = defaultHierarchyLevelAggregationLoadings();
    },

    setAggregationRequestsCanceller(state, { level, aggregateStoreGroups, canceller }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      state.aggregationRequestsCanceller[key] = canceller;
    },

    removeAggregationRequestsCanceller(state, { level, aggregateStoreGroups }) {
      const key = getHierarchyLevelAggregationRequestKey({ level, aggregateStoreGroups });
      delete state.aggregationRequestsCanceller[key];
    },

    resetAggregationRequestsCanceller(state) {
      state.aggregationRequestsCanceller = {};
    },

    setHierarchyLevelItems(
      state,
      { items, aggregationLevel, parentId, levelEntryKey, aggregateStoreGroups }
    ) {
      if (aggregateStoreGroups) {
        // Nest store group aggregations as an extra field in the hierarchy aggregations
        const hierarchyKey = makeHierarchyKey(aggregationLevel - 1, parentId);
        const hierarchyAggregations = [..._.get(state.hierarchy, hierarchyKey, [])];
        const storeGroupParent = _.findIndex(hierarchyAggregations, { levelEntryKey });
        hierarchyAggregations[storeGroupParent].storeGroupAggregations = _.map(items, i => {
          i.name = i._id; // set zone name as _id for description column
          i.isStoreGroupAggregation = true;
          return i;
        });
        state.hierarchy = {
          ...state.hierarchy,
          [hierarchyKey]: hierarchyAggregations,
        };
      } else {
        const hierarchyKey = makeHierarchyKey(aggregationLevel, parentId);
        state.hierarchy = {
          ...state.hierarchy,
          [hierarchyKey]: items,
        };
      }
    },

    addExpandedStoreGroupAggregation(state, { parentId }) {
      state.expandedStoreGroupAggregations = [...state.expandedStoreGroupAggregations, parentId];
    },

    setLoadingStoreGroupAggregations(state, loadingStoreGroupAggregations) {
      state.loadingStoreGroupAggregations = loadingStoreGroupAggregations;
    },

    removeExpandedStoreGroupAggregation(state, { parentId }) {
      const aggIndex = _.findIndex(state.expandedStoreGroupAggregations, x => x === parentId);
      if (aggIndex === -1) {
        return;
      }
      state.expandedStoreGroupAggregations.splice(aggIndex, 1);
    },

    setAttributeFilters(state, { attributeFilters }) {
      state.selectedAttributeFilters = attributeFilters;
    },

    setLoadingMonthlyAggregations(state, loadingMonthlyAggregations) {
      state.loadingMonthlyAggregations = loadingMonthlyAggregations;
    },

    setMonthlyAggregations(state, monthlyAggregations) {
      state.monthlyAggregations = monthlyAggregations;
    },

    setSelectedHistoricalPeriod(state, historicalPeriod) {
      state.selectedHistoricalPeriod = historicalPeriod;
    },

    setDownloadingMonthlyAggregations(state, downloading) {
      state.downloadingMonthlyAggregations = downloading;
    },

    setHierarchyIdMap(state, hierarchyIdMap) {
      state.hierarchyIdMap = hierarchyIdMap;
    },
  },

  actions: {
    resetState({ commit }) {
      commit('resetState');
    },

    setExpandedHierarchyLevelItems({ commit }, { newExpandedHierarchy }) {
      if (!newExpandedHierarchy) {
        commit('initExpandedHierarchyLevelItems');
        return;
      }
      commit('setExpandedHierarchyLevelItems', newExpandedHierarchy);
    },

    async fetchRootHierarchyLevelItemId({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.get(`/api/hierarchy/workpackage/${workpackageId}/root-hierarchy-item`, {
          params: { wholesale: true },
        })
      );
      if (err) {
        throw new Error(err.message);
      }
      const { data: rootHierarchyLevelItem } = res;
      commit('setRootHierarchyLevelItemId', { rootHierarchyLevelItem });
    },

    async fetchAggregatedHierarchyLevelItems(
      { commit, state, rootState, rootGetters },
      {
        aggregationLevel = 0,
        parentId,
        aggregateStoreGroups = false,
        levelEntryKey = null,
        storeGroupParentId = null,
        historicalPeriod,
      } = {}
    ) {
      const extraParams = getExtraFetchHierarchyItemAggregationParams(state, {
        aggregationLevel,
        parentId,
        aggregateStoreGroups,
      });
      let formattedAttributeFilters = {};
      if (state.selectedAttributeFilters.length) {
        formattedAttributeFilters = formatFilters({
          where: state.selectedAttributeFilters,
          rootState,
        });
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const params = {
        workpackageId,
        historicalPeriod,
        attributeFilters: formattedAttributeFilters,
        ...extraParams,
      };
      const basicMutationParams = {
        level: params.aggregationLevel,
        aggregateStoreGroups: params.aggregateStoreGroups,
      };
      const previousRequestCancellerKey = getHierarchyLevelAggregationRequestKey(
        basicMutationParams
      );
      if (_.has(state.aggregationRequestsCanceller, previousRequestCancellerKey)) {
        state.aggregationRequestsCanceller[previousRequestCancellerKey].cancel();
      }
      const axiosSource = axios.CancelToken.source();
      commit('setAggregationLoadings', {
        ...basicMutationParams,
        isLoading: true,
      });
      commit('setAggregationRequestsCanceller', {
        ...basicMutationParams,
        canceller: axiosSource,
      });

      // TODO: PRICE-2315 return pricingStoreGroupKey too and use that for union instead, descriptions may change.
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/results/aggregated`, {
          params,
          cancelToken: axiosSource.token,
        })
      );

      commit('setAggregationLoadings', {
        level: aggregationLevel,
        aggregateStoreGroups,
        isLoading: false,
      });

      if (err) {
        commit('removeAggregationRequestsCanceller', {
          ...basicMutationParams,
        });
        if (axios.isCancel(err)) {
          return;
        }
        throw new Error(err.message);
      }

      let { data: items } = response;
      commit('removeAggregationRequestsCanceller', {
        ...basicMutationParams,
      });
      // TODO: PRICE-2315 remove this hack
      if (aggregateStoreGroups) {
        const fieldsToFake = [
          'wholesaleMarginCurrent',
          'wholesaleMarginProposed',
          'wholesaleMarginDelta',
          'affiliateMarginCurrent',
          'affiliateMarginProposed',
          'affiliateMarginDelta',
          'retailMarginCurrentPercent',
          'dllSplitCurrentPercent',
          'dllSplitProposedPercent',
          'wholesaleMarginCurrentPercent',
          'wholesaleMarginProposedPercent',
          'affiliateMarginCurrentPercent',
          'affiliateMarginProposedPercent',
          'wholesaleMarginDeltaPercent',
          'affiliateMarginDeltaPercent',
          'dllSplitDeltaPercent',
        ];
        const hackedStoreGroupDescriptions = [];
        const hackedStoreGroupAggregations = rootGetters[
          'storeGroupRelationships/hardcodedStoreGroups'
        ].map(storeGroup => {
          hackedStoreGroupDescriptions.push(storeGroup.toolStoreGroupDescription);
          return {
            _id: storeGroup.toolStoreGroupDescription,
            ...storeGroup,
            ...fieldsToFake.reduce((acc, key) => Object.assign(acc, { [key]: 0 }), {}),
          };
        });
        if (!_.isEmpty(hackedStoreGroupAggregations)) {
          const mergedItems = _.unionBy(items, hackedStoreGroupAggregations, '_id');
          // only keep storeGroups from hardcoded list
          items = mergedItems.filter(sg => hackedStoreGroupDescriptions.includes(sg._id));
        }
      }
      items.map(item => {
        const name = item.name;
        const hierarchyIdDisplay = _.get(state.hierarchyIdMap, item.levelEntryKey);
        item.name = _.isEmpty(hierarchyIdDisplay) ? name : [hierarchyIdDisplay, name].join(' - ');
        return item;
      });
      commit('setHierarchyLevelItems', {
        items,
        aggregateStoreGroups,
        aggregationLevel,
        parentId: storeGroupParentId || params.parentId,
        levelEntryKey,
      });
      return items;
    },

    async fetchHierarchyKeyDisplays({ rootState, commit }) {
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, res] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/hierarchy-key-displays`, {})
      );
      if (err) {
        throw new Error(err.message);
      }
      const { data: hierarchyIdMap } = res;
      commit('setHierarchyIdMap', hierarchyIdMap);
    },

    async fetchAggregations({ state, dispatch }) {
      const historicalPeriod = state.selectedHistoricalPeriod;

      // cancel aggregation requests
      dispatch('cancelAggregationRequests');
      // fetch the aggregated results:
      const aggregations = [
        dispatch('fetchAggregatedHierarchyLevelItems', { historicalPeriod }),
        dispatch('fetchAggregatedHierarchyLevelItems', {
          aggregationLevel: unitLevel,
          historicalPeriod,
        }),
      ];
      _.forEach(
        [categoryLevel, subcategoryLevel, segmentLevel, subsegmentLevel, microsegmentLevel],
        aggregationLevel => {
          const parentId = state.expandedHierarchyLevelItems[aggregationLevel];
          if (parentId) {
            aggregations.push(
              dispatch('fetchAggregatedHierarchyLevelItems', {
                aggregationLevel,
                parentId,
                historicalPeriod,
              })
            );
          }
        }
      );

      await Promise.all(aggregations);
    },

    cancelAggregationRequests({ commit, state }) {
      Object.values(state.aggregationRequestsCanceller).forEach(canceller => {
        canceller.cancel();
      });
      commit('resetAggregationRequestsCanceller');
      commit('resetAggregationLoadings');
    },

    async toggleStoreGroupAggregations(
      { commit, dispatch },
      { level, parentId, levelEntryKey, beingExpanded }
    ) {
      const storeGroupAggregation = {
        level,
        parentId,
        levelEntryKey,
      };
      if (!beingExpanded) {
        commit('removeExpandedStoreGroupAggregation', storeGroupAggregation);
        return;
      }
      commit('addExpandedStoreGroupAggregation', storeGroupAggregation);
      return dispatch('fetchStoreGroupAggregations', storeGroupAggregation);
    },

    async fetchStoreGroupAggregations(
      { commit, dispatch, state },
      { level, parentId, levelEntryKey }
    ) {
      commit('setLoadingStoreGroupAggregations', true);
      const historicalPeriod = state.selectedHistoricalPeriod;
      const params = {
        aggregateStoreGroups: true,
        aggregationLevel: level + 1,
        parentId: levelEntryKey,
        levelEntryKey,
        storeGroupParentId: parentId,
        historicalPeriod,
      };
      const [err, results] = await to(dispatch('fetchAggregatedHierarchyLevelItems', params));
      if (err) {
        commit('setLoadingStoreGroupAggregations', false);
        return Promise.reject(err);
      }
      commit('setLoadingStoreGroupAggregations', false);
      return results;
    },

    setAttributeFilters({ commit }, { attributeFilters }) {
      commit('setAttributeFilters', { attributeFilters });
    },

    async fetchMonthlyAggregations({ commit, state, rootState }) {
      commit('setLoadingMonthlyAggregations', true);
      let formattedAttributeFilters = {};
      if (state.selectedAttributeFilters.length) {
        formattedAttributeFilters = formatFilters({
          where: state.selectedAttributeFilters,
          rootState,
        });
      }
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const params = {
        attributeFilters: formattedAttributeFilters,
      };
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/results/monthly-aggregated`, {
          params,
        })
      );

      if (err) {
        commit('setLoadingMonthlyAggregations', false);
        Promise.reject(err);
      }

      const { data: aggregations } = response;
      commit('setMonthlyAggregations', aggregations);
      commit('setLoadingMonthlyAggregations', false);
    },

    setSelectedHistoricalPeriod({ commit }, historicalPeriod) {
      commit('setSelectedHistoricalPeriod', historicalPeriod);
    },

    async downloadMonthlyAggregations(
      { commit, state, rootState },
      { translationMap = {}, aggregationLevel = 0, columnFormatters = {} }
    ) {
      commit('setDownloadingMonthlyAggregations', true);

      let formattedAttributeFilters = {};
      if (state.selectedAttributeFilters.length) {
        formattedAttributeFilters = formatFilters({
          where: state.selectedAttributeFilters,
          rootState,
        });
      }

      const params = {
        translationMap,
        aggregationLevel,
        attributeFilters: formattedAttributeFilters,
        formatForExport: true,
      };
      const workpackageId = rootState.workpackages.selectedWorkpackage._id;
      const [err, response] = await to(
        axios.get(`/api/wholesale/workpackage/${workpackageId}/results/monthly-aggregated`, {
          params,
        })
      );
      if (err) {
        commit('setDownloadingMonthlyAggregations', false);
        throw new Error(err.message);
      }
      const exportTime = moment().format(hoursMinutesDayMonthYearFormat);
      const { numberFormats, i18nconfig, exportConfigs } = rootState.clientConfig;
      const { overrideNumberFormat } = i18nconfig;
      const { rowsPerFile } = exportConfigs.exportToExcel;
      downloadXlsxFile(response.data, `Extract - ${exportTime}.xlsx`, {
        rowsPerFile,
        columnFormatters,
        numberFormatsConfig: numberFormats[overrideNumberFormat],
      });
      commit('setDownloadingMonthlyAggregations', false);
    },
  },
};

export default store;
