<!-- Uses the generic pricing-filter component to set the selected competitor -->

<template>
  <v-layout>
    <v-flex>
      <pricing-filter
        :filter="competitorFilter"
        :loading="loading"
        :hide-label="true"
        :override-value="selectedCompetitor._id"
        :dense="false"
        :solo="false"
        @filterSelected="selectCompetitor"
      />
    </v-flex>
  </v-layout>
</template>

<script>
import { find } from 'lodash';
import { createNamespacedHelpers } from 'vuex';

const { mapState, mapGetters } = createNamespacedHelpers('competitorMetadata');

export default {
  props: {
    selectedCompetitor: {
      type: Object,
      required: true,
    },
  },

  computed: {
    ...mapState(['loading']),
    ...mapGetters(['nonStoreGroupCompetitors']),

    competitorFilter() {
      return {
        name: this.$t('filters.competitor'),
        state: null, // selected competitor is not saved in the filters store
        values: this.nonStoreGroupCompetitors,
        label: 'competitorDisplayDescription',
      };
    },
  },

  methods: {
    selectCompetitor({ filterValue }) {
      this.$emit(
        'selectCompetitor',
        find(this.nonStoreGroupCompetitors, competitor => competitor._id === filterValue)
      );
    },
  },
};
</script>

<style scoped lang="scss"></style>
