import exportToSAPFields from '@enums/export-to-sap-fields';

const { forEach, get } = require('lodash');
const { mapState } = require('vuex');

const mixin = {
  computed: {
    ...mapState('clientConfig', ['exportConfigs']),
  },
  methods: {
    getSapExportFields() {
      const translationPath = 'workpackage.exports.sap';
      const {
        dateFormat,
        targetFieldRename,
        targetType,
        targetValueLength,
      } = this.exportConfigs.exportToSAP;
      const newFields = {};

      forEach(exportToSAPFields, (fieldConfig, key) => {
        const label = this.$t(`${translationPath}.${key}`);
        const targetFieldOverride = get(targetFieldRename, key);
        const targetValueLengthOverride = get(targetValueLength, key);
        const targetTypeOverride = get(targetType, key);

        newFields[targetFieldOverride || key] = {
          ...fieldConfig,
          label,
          targetType: targetTypeOverride || fieldConfig.targetType,
          targetValueLength: targetValueLengthOverride || fieldConfig.targetValueLength,
        };
      });
      // Override date format with value from export config
      newFields.date.format = dateFormat;
      return newFields;
    },
  },
};

export default mixin;
